@import "../../../_common/style/core/variables";

#evidence-page-details-view {
  .info-banner-line {
    min-height: 0;

    .info-banner-actions {
      .button {
        color: unset;

        &:hover {
          cursor: pointer;
        }

        div {
          display: flex;
          flex-direction: row;
          color: unset;

          .cr-icon-arrow-right {
            color: unset;
            font-size: $font-size-larger;
            font-weight: $font-weight-bolder;
          }
        }
      }
    }
  }

  .evidence-page-details-card {
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $grey500;
      font-size: 14px;

      .title {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;

        .pill-label {
          margin-left: 10px;
        }
      }

      button {
        margin-left: 16px;
      }
    }

    .content {
      padding-right: 32px;
      padding-left: 32px;

      .info-table {
        td {
          padding-top: 24px;
          padding-bottom: 24px;
        }

        .info-table-row {
          height: 70px;
        }

        .row-label {
          font-weight: $font-weight-bold;
          min-width: 160px;
          text-align: right;
          line-height: 26px;
        }

        .link-row {
          .row-value {
            word-break: break-all;
          }
        }

        .comments-row {
          .row-label {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .cr-icon-info {
              margin-left: 5px;
              line-height: 27px;
            }
          }
        }

        .row-value {
          font-weight: $font-weight-regular;

          .rendered-comments {
            display: flex;
            flex-direction: column;

            p {
              color: $grey600;
            }

            p:first-of-type {
              margin-top: 5px;
            }
            p:last-of-type {
              margin-bottom: 5px;
            }
          }

          .category-selector {
            max-width: 300px;
          }

          .link-field {
            width: 100%;

            .text-field-additional {
              min-height: 0;
            }
          }

          .rich-text-edit-v2 {
            width: 100%;
          }

          .comments {
            color: $grey600;
            margin-top: 0;
            margin-bottom: 0;
          }

          .avatar-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $grey600;

            .logo-base {
              width: 24px;
              height: 24px;

              .circle-logo {
                margin-top: -6px;
                border: 2px solid $light-grey;
                box-sizing: border-box;
              }
            }

            .company-label {
              color: $grey600;
              margin-right: 5px;
              padding-left: 14px;
            }

            .user-avatar {
              margin-right: 5px;

              .avatar {
                margin-top: -6px;
                width: 35px;
                height: 35px;
              }
            }
          }

          .locked-text {
            height: 32px;
          }

          .locked-category {
            width: 300px;
          }

          .locked-url {
            width: 100%;
          }
        }
      }
    }
  }

  .empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: $font-weight-regular;
    color: #95989b;
    font-style: italic;
    font-size: 1.2rem;
    line-height: 1.7rem;
    padding: 30px;

    .text {
      margin-bottom: 30px;
    }
  }

  .evidence-page-details-card-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $card-border-radius;
    border: 1px solid $light-grey;
    box-shadow: $box-shadow-4dp;
    min-height: 500px;
  }
}
