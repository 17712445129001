@use "../core/variables";

.survey-details-progress-card {
  .header {
    justify-content: space-between;

    span {
      margin-right: 10px;
    }

    .header-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    button,
    a {
      margin-left: 20px;
    }

    button {
      white-space: nowrap;

      .cr-icon-arrow-right {
        font-size: 18px;
        margin-right: 0;
        margin-left: 5px;
      }
    }

    .survey-details-status-dropdown {
      margin-left: auto;
      .popup-target {
        white-space: nowrap;
      }
    }
  }

  .vertical-progress-wrapper {
    padding: 20px 32px;
  }
}
