@import "../../../_common/style/core/variables";

.select-vendor-card {
  .header {
    .btn {
      margin-left: auto;
    }
  }

  .search {
    margin: 24px 32px 0 32px;
  }

  .vendor-cell {
    color: $grey900;
    font-weight: $font-weight-bold;
    font-size: 14px;
    line-height: 16px;
  }

  .domain-cell {
    color: $grey900;
    font-size: 14px;
    line-height: 150%;
  }

  .score-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;

    .color-grade {
      align-self: center;
      margin: 8px;
    }
  }

  .scan-button {
    margin-left: 12px;
  }

  .empty-search-subtext,
  .scan-error,
  .progress-bar-container .msg {
    color: $grey600 !important;
  }
}
