@import "../../_common/style/core/variables";

.gpt-suggestion-box {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 4px;
  background-color: $grey50;
  width: min-content;
  margin-left: 8px;
  margin-top: 18px;
  height: min-content;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    gap: 4px;
    padding: 4px;
    border-radius: 4px;

    .header-text {
      color: $grey800;
      font-weight: $font-weight-bold;
      letter-spacing: 1px;
      font-size: $font-size-smaller;
      text-transform: uppercase;
    }

    i {
      color: $grey800;
    }

    &:hover {
      background-color: $blue100;
      cursor: pointer;

      .header-text,
      i {
        color: $blue500;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;

    .btn:not(:last-of-type) {
      margin-right: 8px;
    }
  }
}
