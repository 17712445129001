@import "../../_common/style/core/variables";

.fake-text-box {
  border: 1px solid $grey200;
  border-radius: 4px;
  padding: 12px;
  margin-top: 12px;

  .red {
    background-color: rgba($red500, 0.3);
  }

  .green {
    background-color: rgba($green500, 0.3);
  }

  &.multiline {
    min-height: 6em;
  }
}

.input-action-icon-popup {
  max-width: 200px;
}
