@import "../core/variables";

.styled-select {
  position: relative;
  display: inline-block;

  select {
    appearance: none;
    padding-right: 25px;

    &:focus {
      + .icon-chevron {
        color: $grey900;
      }
    }
  }

  .icon-chevron {
    position: absolute;
    pointer-events: none;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotateX(180deg);
    color: $grey300;

    transition-property: color;
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;
  }
}
