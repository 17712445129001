@use "../../../../_common/style/core/variables";

.vendor-assessment-autofill-classifications-summary-card {
  .header {
    font-size: variables.$font-size-extra-large;
    font-weight: variables.$font-weight-bold;
    line-height: 28px;
    text-align: left;
  }

  .section-toggle {
    display: flex;
    align-items: center;

    .section-toggle-label {
      font-size: variables.$font-size;
      font-weight: variables.$font-weight-regular;
      line-height: 22px;
      text-align: left;
      margin-left: 8px;
    }
  }

  .vendor-assessment-autofill-classifications-summary-content {
    .intro-text {
      font-size: variables.$font-size;
      font-weight: variables.$font-weight-regular;
      line-height: 22px;
      padding: 24px 0 0 24px;
      text-align: left;
    }

    .ratings-header {
      color: variables.$grey600;
      font-size: variables.$font-size-smaller;
      font-weight: variables.$font-weight-bold;
      letter-spacing: 1px;
      line-height: 16px;
      text-align: left;
    }

    .ratings-container {
      border-bottom: 1px solid variables.$light-grey;
      display: flex;
      gap: 16px;
      flex-grow: 1;
      padding-bottom: 24px;
      padding-top: 24px;
      margin-left: 24px;
      margin-right: 24px;

      .ratings-description {
        color: variables.$grey800;
        font-size: variables.$font-size;
        font-weight: variables.$font-weight-regular;
        line-height: 22px;
        text-align: left;
      }

      .ratings-item {
        background: variables.$lighter-blue;
        border-radius: 8px;
        display: flex;
        flex-basis: 0;
        flex-direction: column;
        flex-grow: 1;
        gap: 12px;
        padding: 24px;
      }

      .overall-rating-score {
        align-items: center;
        display: flex;
        gap: 12px;
        opacity: 0.8;

        .score {
          white-space: nowrap;

          .cstar-text {
            font-size: 42px;

            .score-text {
              min-width: 0;
            }
          }
        }
      }

      .individual-ratings-container {
        .headers {
          display: flex;

          .ratings-header {
            width: 100%;
          }
        }

        .ratings {
          display: flex;

          .individual-rating {
            display: flex;
            gap: 12px;
            width: 100%;
          }
        }

        .waived-risk-text {
          color: variables.$grey600;
          font-size: variables.$font-size-smaller;
          font-weight: variables.$font-weight-regular;
          line-height: 18px;
          text-align: left;
        }
      }
    }

    .sections {
      .header {
        border: unset;
        padding: unset;
      }

      padding-left: 24px;
      padding-right: 24px;

      .risk-classification-section {
        border-bottom: 1px solid variables.$light-grey;
        display: flex;
        width: 100%;

        .risk-classification-section-card {
          width: 100%;

          .vendor-assessment-text-card {
            margin-bottom: 24px;

            .header {
              font-size: variables.$font-size-larger;
              font-weight: variables.$font-weight-bold;
              line-height: 24px;
              text-align: left;
            }

            .contents {
              margin: unset;
            }
          }
        }

        .risk-classification-side-section {
          margin-left: auto;
          padding: 24px 0 24px 24px;

          .risk-classification-risk-breakdown {
            border: 1px solid variables.$grey100;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 12px;

            .risk-totals {
              display: flex;
              gap: 12px;

              .risk-total {
                display: flex;
                flex-direction: column;
                flex-basis: 0;
                flex-grow: 1;
                gap: 8px;
                min-width: 52px;

                .risk-total-header {
                  color: variables.$grey900;
                  font-size: variables.$font-size;
                  font-weight: variables.$font-weight-regular;
                  line-height: 22px;
                  text-align: left;
                }

                .risk-severity {
                  align-items: center;
                  display: flex;
                }
              }
            }
          }
        }
      }
    }
  }
}
