@use "../../../_common/style/core/variables";

.org-permissions-table {
  .search-and-count {
    padding: variables.$space-lg variables.$space-xl 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: variables.$space-xl;

    .search-box-input-container {
      flex: 1;
      padding: unset;
    }
  }
  .description-with-subtext {
    line-height: 1.3;

    .description-maintext {
      font-size: variables.$font-size;
    }

    .description-subtext {
      font-size: variables.$font-size-smaller;
      color: variables.$grey500;
    }
  }

  // Modify the padding on the expanded cell so we don't have
  // a gap between the expanded content
  .report-card {
    td.expanded-content-cell {
      padding: 0;
    }
  }
}
