@use "../../_common/style/core/variables";
@use "../../_common/style/core/cr_icons_constants";

.ellipsised-url {
  min-width: 0px;

  * {
    color: variables.$blue;
    &:hover {
      text-decoration: underline;
    }
  }

  .ellipsised-url-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ellipsized-text-container {
      min-width: 0px;
      cursor: pointer;
      color: variables.$blue;
      margin-right: 2px;

      .dismissable-popup-click-capture {
        min-width: 0px;
      }
    }

    .ext-icon {
      flex: 1 0 auto;
    }
  }
}
