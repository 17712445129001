@import "../core/variables";

.dragdropupload {
  width: 100%;
  border-radius: $card-border-radius !important;

  .drop-target {
    border-radius: $card-border-radius !important;

    &:hover,
    &:focus {
      border: 1px solid $blue500 !important;
      background-color: $blue50 !important;
    }
    cursor: pointer;
  }

  .desc-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 10px 0;
    transition: all 200ms ease-in-out;
    line-height: 24px;

    .desc-text {
      color: $grey500;
      font-weight: $font-weight-regular;
    }

    .link-style {
      color: $blue !important;
      margin-right: 5px;
      cursor: pointer;
      transition: all 200ms ease-in-out;
      font-weight: $font-weight-bold;
    }

    .cr-icon-q-builder-attachment {
      transition: all 200ms ease-in-out;
      font-size: 20px;
      margin-right: 6px;
    }
  }

  &.disabled {
    pointer-events: none;

    .desc-text {
      color: $grey300;

      .cr-icon-paperclip {
        color: $grey300;
      }

      .link-style {
        color: $grey300 !important;
      }
    }
  }

  .additional-text {
    margin-top: 8px;
    text-align: center;
    color: $grey700;
  }
}

.multi-file-upload-modal {
  padding: 0 !important;
  width: 600px;

  .files-uploading-table {
    /* override the <tbody> styling to allow natural scrollbar with full-width */
    tbody {
      display: block;
      max-height: 470px;
      overflow-y: auto;
      margin-right: -32px;

      tr:first-of-type {
        td {
          border-top: none;
        }
      }
    }

    .file-cell {
      min-width: 300px;

      .file-name-size-container {
        &.error {
          .file-name {
            color: $grey400;
          }
        }

        &.done {
          .file-name {
            color: $grey600;
          }
        }
      }
    }
    .progress-cell {
      min-width: 205px;
      max-width: 205px;

      .progress-bar {
        min-width: 100%;
      }

      .error-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .error-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 210px;

      .error-content {
        display: flex;
        align-items: center;
        color: $red500;
        margin-right: 8px;

        i {
          margin-right: 6px;
        }
      }
    }
    .multi-error-container {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}

.multi-file-upload-modal-footer {
  .footer-left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center !important;

    .num-completed {
      font-size: 12px;
      font-weight: $font-weight-bold;
      line-height: 16px;
      letter-spacing: 1px;
      color: $grey700;
    }

    .num-completed + .num-completed {
      margin-top: 10px;
    }
  }
}
