@use "../core/variables";

.slide-panel-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10001;
  pointer-events: none;

  .dimmer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(44, 50, 56, 0.6);
    pointer-events: all;
  }

  .slide-panel {
    position: absolute;
    pointer-events: all;
    top: 0;
    bottom: 0;
    right: 0;
    min-width: 200px;
    background-color: variables.$white;
    z-index: 10001;

    display: flex;
    flex-direction: column;
    padding: 40px;

    box-shadow: 0 -2px 23px 0 rgba(69, 69, 69, 0.1);

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: variables.$space-xs;
      margin-bottom: 30px;

      h2 {
        margin-right: variables.$global-padding * 2;
        padding-bottom: 0;

        &.clickable {
          cursor: pointer;
          &:hover {
            color: variables.$blue;
          }
        }
      }

      .close {
        margin-left: auto;
        cursor: pointer;
        font-size: 20px;

        &:hover {
          color: variables.$blue;
        }
      }
    }

    .close-icon {
      text-align: right;
      margin-bottom: 20px;
      span {
        cursor: pointer;
        font-size: 20px;
      }
    }

    .action {
      font-size: 0.7rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        color: variables.$blue;
      }
    }

    .button-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 136px;
      padding: 24px 40px 80px 40px;
      border-top: 1px solid variables.$grey100;
      background-color: rgba(variables.$blue, 0.025);

      & > button {
        flex: 1;

        &:not(:first-of-type) {
          margin-left: 8px;
        }

        &:not(:last-of-type) {
          margin-right: 8px;
        }
      }
    }

    &.new-styles {
      padding: 0;
      width: 376px;

      .title {
        padding: 26px 40px;
        min-height: 78px;
        border-bottom: 1px solid variables.$light-grey;
        margin-bottom: 0;

        h2 {
          margin-right: 40px;
        }
      }

      h2 {
        font-size: 18px;
      }

      h3 {
        font-size: 16px;
        font-weight: variables.$font-weight-bold;
        margin: 0;
      }

      * + h3 {
        margin-top: 40px;
      }

      & > .container {
        padding: 0;
        min-height: 1px;
      }
    }
  }
}

.slide-left-enter {
  .slide-panel {
    transform: translateX(100%);
  }
  .dimmer {
    background-color: rgba(44, 50, 56, 0);
  }
}
.slide-left-enter.slide-left-enter-active {
  .slide-panel {
    transform: translateX(0);
    transition: transform 250ms ease-in-out;
  }
  .dimmer {
    background-color: rgba(44, 50, 56, 0.6);
    transition: background-color 250ms ease-in-out;
  }
}
.slide-left-exit {
  .slide-panel {
    transform: translateX(0);
  }
  .dimmer {
    background-color: rgba(44, 50, 56, 0.6);
  }
}
.slide-left-exit.slide-left-exit-active {
  .slide-panel {
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;
  }
  .dimmer {
    background-color: rgba(44, 50, 56, 0);
    transition: background-color 250ms ease-in-out;
  }
}
