@use "../../../../_common/style/core/variables";

.select-risk-table {
  .col-severity {
    width: 60px;
  }

  .col-related-assets {
    width: 180px;
  }

  .expanded-content-cell.expanded {
    padding-top: 0px;
  }

  .risk-name-cell {
    .risk-name-cell-title {
      font-weight: variables.$font-weight-bold;
    }

    .risk-name-cell-desc {
      margin-top: 8px;
      color: variables.$grey500;
      font-weight: variables.$font-weight-regular;
    }
  }

  .asset-display {
    font-weight: variables.$font-weight-bold;
  }

  .risk-table-expand-content {
    display: flex;
    margin-left: 86px;
    padding-right: 20px;
    padding-top: 20px;
    border-top: 1px solid variables.$grey100;

    .risk-description {
      max-width: 400px;
      padding-right: 24px;
    }

    h4 {
      font-size: 10px;
      color: variables.$grey500;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: variables.$font-weight-bold;
      margin-bottom: 14px;
    }

    p,
    .risk-text {
      font-size: 14px;
      line-height: 1.5;
    }

    .asset-selector {
      display: flex;
      flex-direction: row;

      .all-assets-selection {
        margin-left: 24px;
      }

      .asset-selection {
        margin-left: 24px;
      }
    }
  }

  .selectable {
    .risk-table-expand-content {
      margin-left: 136px;
    }
  }
}

.questionnaire-selection-container {
  margin-left: auto;
  margin-right: 32px;

  .questionnaire-selection {
    margin-top: 1em;
  }
}
