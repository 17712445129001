@import "../../../../_common/style/core/variables";

.risk-name {
  display: flex;
  align-items: center;

  .adjusted-severity-icon {
    margin-right: 8px;
  }

  .name-container {
    .name {
      color: $grey900;
      font-weight: $font-weight-bold;
    }

    .category-group-name {
      margin-top: 8px;
      color: $grey800;
      font-weight: $font-weight-regular;
      line-height: 100%;
    }
  }

  &.waived {
    .name-container {
      .name,
      .category-group-name {
        font-style: italic;
        color: $grey400;
      }
    }
  }
}
