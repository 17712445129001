@use "../../core/variables";

.product-specific-navigation {
  display: flex;
  gap: 24px;
  flex-direction: column;

  .product-name {
    color: variables.$grey900;
    height: 68px;
    padding: 20px 16px;
    gap: 10px;
    font-size: variables.$font-size-extra-large;
    font-weight: variables.$font-weight-bold;
    line-height: 28px;
    text-align: left;
  }

  .product-navigation-items {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .section-header {
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
    }

    .section-divider {
      padding: 0 16px;

      hr {
        height: 1px;
        background-color: variables.$grey200;
      }
    }

    .section-header-text,
    .vendor-name {
      color: variables.$grey500;
      flex: 1 0 0;
      font-size: variables.$font-size-smaller;
      font-weight: variables.$font-weight-bold;
      line-height: variables.$line-height-small;
    }

    .nav-link {
      display: flex;
      width: variables.$product-specific-navigation-width;
      min-height: 28px;
      height: auto;
      padding: 4px 4px 4px 16px;
      align-items: center;
      gap: 8px;

      span {
        color: variables.$grey700;
        font-size: variables.$font-size;
        font-weight: variables.$font-weight-regular;
        line-height: 16.94px;
        text-align: left;
        display: flex;
        align-items: center;

        .beta-label {
          margin-left: variables.$space-sm;
        }
      }

      &.active {
        padding-left: 12px;
        border-left: 4px solid variables.$blue500;

        span {
          color: variables.$grey900;
          font-weight: variables.$font-weight-bold;
          line-height: 16.94px;
          text-align: left;
        }
      }

      &:hover {
        background: #1757c20d;
        text-decoration: unset;
      }

      .sub-menu-icon {
        padding: 0.5px 0 4.5px 6px;
        border-bottom: 1px solid variables.$black;
        border-left: 1px solid variables.$black;
        border-color: variables.$grey300;
        margin-bottom: 4.5px;
      }

      .nav-link-alert-popup {
        width: 100%;
      }

      .nav-link-in-app-message {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 9px;

        .unread-dot {
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background-color: variables.$teal500;
        }

        .alert-dot {
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background-color: variables.$orange;
        }
      }
    }

    .nav-sub-section {
      border-right: 1px solid variables.$grey100;
      background: variables.$grey50;
      padding: 8px 0 12px 0;

      .header-section-name {
        display: flex;
        padding: 8px 12px 8px 28px;

        .icon-x {
          color: variables.$grey400;
          cursor: pointer;
          font-size: 12px;
          padding-top: 3px;
        }
      }

      .nav-link {
        padding-left: 28px;

        &.active {
          padding-left: 24px;
        }
      }
    }
  }
}
