@use "../../../../_common/style/core/variables";
@use "../../../../_common/style/core/cr_icons_constants";

#report_template_create {
  .multi-steps + * {
    margin-top: 24px;
  }

  .no-top-margin {
    margin-top: 0px !important;
  }

  .action-bar {
    .left-content,
    .right-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .input-header {
    display: block;

    input {
      font-size: inherit !important;
    }

    .editable-template-name,
    .editable-template-desc {
      max-width: 510px;

      input,
      textarea {
        text-decoration: underline dotted variables.$grey400;
        text-underline-offset: 6px;
      }

      &.unedited {
        input:not(:focus),
        textarea:not(:focus) {
          font-style: italic;
        }
      }
    }

    .editable-template-name {
      font-size: 18px;
      font-weight: variables.$font-weight-bold;
    }

    .editable-template-desc {
      margin-top: 5px;
      font-size: variables.$font-size;
    }
  }

  .report-type-selector {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-auto-rows: 1fr;

    @media (max-width: 1800px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    .report-type-badge {
      margin: 0;
      .rhs:not(.short) img {
        height: 150px;
      }
    }
  }

  .config-opts {
    padding: 24px 32px;
  }
}
