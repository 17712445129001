@import "../../core/variables";

.info-bar-section {
  width: 100%;
  background-color: $blue500;

  .info-bar-section-inner {
    width: 100%;
    margin: auto;
    max-width: $max-page-width;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info-bar,
    * {
      font-size: 14px;
      line-height: 20px;
      font-weight: $font-weight-bold;
      color: $white;
      text-align: center;
    }
  }

  &.info {
    background-color: $blue500;
  }

  &.warning {
    background-color: $orange-mid500;
  }

  &.error {
    background-color: $red500;
  }

  &.success {
    background-color: $green500;
  }
}
