@use "../../core/variables";

.top-level-navigation {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .logo-container {
    height: 40px;
    padding-left: 20px;
    padding-bottom: 8px;
    padding-top: 8px;

    .ug-logo {
      &:not(.open) {
        width: 17.5px;
      }

      &.open {
        width: 90px;
      }
    }
  }

  .navlinks-container {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    gap: 8px;

    hr {
      background-color: variables.$grey800;
      height: 1px;
      margin: 8px;
    }

    .nav-link {
      align-items: center;
      border-radius: 8px;
      max-height: 40px;
      display: flex;

      position: relative;

      .nav-icon {
        font-size: 24px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .nav-icon-container {
        position: relative;
      }

      .badge-parent {
        .badge {
          position: absolute;
          right: -4px;
          top: 0;
          display: inline-flex;
          padding: 0 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 40px;
          background: variables.$red500;
          text-align: center;
          font-feature-settings: "liga" off;
          font-size: 12px;
          font-style: normal;
          font-weight: variables.$font-weight-bold;
          line-height: 16px;
          min-width: 16px;
        }
      }

      span {
        color: variables.$white !important;
      }

      &:hover {
        text-decoration: unset;

        .nav-icon {
          font-size: 30px;
          transition: font-size 100ms ease;
        }
      }

      &.active,
      &:hover {
        background-color: variables.$grey800;
      }

      &.reports-exports-nav-item {
        .badge {
          background-color: variables.$teal500;
        }
      }
    }
  }
}

.top-level-navigation-popup.dismissable-popup.right {
  left: calc(100% + 16px);
  padding-top: 8px;
  padding-bottom: 8px;

  // Smaller triangle for nav menu tooltips
  &:after {
    width: 10px;
    height: 10px;
  }

  &.reports-processing-popup {
    display: flex;
    justify-content: space-between;

    .main-text {
      cursor: pointer;
    }

    .x-area {
      .icon-x {
        line-height: variables.$line-height;
      }
    }

    .reports-processing-popup-content {
      display: flex;
      gap: 8px;

      .loading-tight {
        max-width: 20px;
      }

      .reports-processing-popup-text {
        text-wrap: nowrap;
      }
    }
  }

  &.reports-complete-popup {
    background-color: variables.$blue500;
    display: flex;
    justify-content: space-between;

    .main-text {
      cursor: pointer;
    }

    &::after {
      background-color: variables.$blue500;
    }

    .reports-complete-popup-content {
      display: flex;
      flex-direction: column;
    }
  }
}
