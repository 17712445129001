@import "../core/variables";

// Once the old navigation is retired, we can remove these styles
.nav-link:not(.unstyled) {
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  padding: 6px 30px;
  transition: color 100ms ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: flex;
  color: #c0c1c3 !important;

  .badge-parent {
    .badge {
      position: absolute;
      padding: 2px;
      font-size: 0.8em;
      color: $white !important;
      border-radius: 10px;
      background-color: $red;
      height: 20px;
      width: 20px;
      min-width: 20px;
      text-align: center;
      top: -7px;
      left: 37px;
      margin-right: -18px;
    }
  }

  &,
  span,
  .nav-link-in-app-message {
    transition: color 100ms ease-in-out;
    color: #c0c1c3 !important;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.active:before {
    content: "";
    position: absolute;
    width: 3px;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
  }

  &.active,
  &.clickable:hover {
    text-decoration: none !important;
    color: $white !important;

    span:not(.icon-x),
    .nav-link-in-app-message {
      color: $white !important;
    }

    .nav-icon {
      color: rgba(255, 255, 255, 1);
    }

    .pill-label.bordered {
      color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, 1);
    }
  }

  .nav-icon {
    color: #c0c1c3;
    transition: color 100ms ease-in-out;
    font-size: 16px;
    margin-right: 10px;
    vertical-align: -2px;
  }

  // Icons with built-in padding need some compensation to look right
  .cr-icon-file-search {
    font-size: 20px;
    margin: -2px 8px -2px -2px;
  }

  .pill-label {
    padding: 2px 7px;
    margin-left: 10px;
    font-size: 10px;
    min-width: 22px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.bordered {
      background: none;
      color: rgba(255, 255, 255, 0.5);
      border-color: rgba(255, 255, 255, 0.5);
    }
  }

  .sub-menu-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: -3px;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &:before {
      left: 50%;
      right: -6px;
      top: 50%;
      height: 1px;
    }

    &:after {
      top: -5px;
      left: 50%;
      bottom: 50%;
      width: 1px;
    }
  }

  .nav-link-in-app-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;

    .unread-dot {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: $teal500;
    }

    .alert-dot {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: $orange;
    }
  }
}

.new-feature-popup-content {
  .description {
    padding: 5px 0;
  }

  .learn-more-link .cr-icon-arrow-right {
    display: inline;
    vertical-align: -1px;
  }
}
