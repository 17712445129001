@use "../../../_common/style/core/variables";

.ip-info,
.ip-range-info {
  margin-bottom: 20px;

  .ip-info-row {
    display: flex;
    border-top: 1px solid variables.$grey100;
    padding: 10px 0;

    .ip-info-label {
      color: variables.$grey400;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
      width: 200px;
    }

    .ip-info-value {
      width: 400px;

      a:hover {
        text-decoration: none !important;
      }

      .cr-icon-arrow-right {
        margin: 0 10px;
        vertical-align: -4px;
        color: variables.$grey300;
        font-size: 22px;
        line-height: 10px;
      }

      .pill-label {
        margin: 2px 2px 2px 0;
      }
    }
  }
}
