@use "../../_common/style/core/variables";

.modal-content-container:has(.manage-security-rating-modal) {
  .modal-header p,
  .modal-content p {
    margin-bottom: 0 !important;
  }

  .modal-content {
    padding: 0 variables.$space-xl variables.$space-lg !important;
  }
}

.manage-security-rating-modal {
  .manage-security-rating-inner {
    display: flex;
    justify-content: space-between;
    gap: variables.$space-xl;

    .section {
      display: flex;
      flex-direction: column;
      gap: variables.$space-sm;
      width: 100%;
    }

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: variables.$grey900;
      font-size: variables.$font-size;
      font-weight: variables.$font-weight-bold;
      line-height: variables.$line-height;
    }

    .section-content {
      display: flex;
      flex-direction: column;
      gap: variables.$space-md;
      color: variables.$grey600;
    }

    .controls {
      gap: variables.$space-md;
      max-width: 300px;
    }

    .preview {
      gap: variables.$space-md;

      .preview-content {
        min-height: 250px;
        width: 100%;
        padding: variables.$space-lg;
        justify-content: center;
        align-items: center;
        border: 1px solid variables.$grey100;

        .grade-with-score-card {
          max-width: 300px;

          .score-text {
            font-size: 48px;
          }
          .score-out-of {
            font-size: 14px;
          }
        }
      }
    }
  }
}
