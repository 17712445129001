@use "../../../../_common/style/core/variables";

.reports-library-table {
  .main-cell {
    display: flex;
    flex-direction: row;
    min-width: 250px;

    .report-thumb {
      width: 48px;
      min-width: 48px;
      height: 48px;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      margin-right: 12px;

      .item-bg {
        position: absolute;
        width: 80%;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);

        &.landscape {
          width: 90%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .report-type-and-name {
      height: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .report-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: variables.$grey800;
        font-size: variables.$font-size-smaller;

        .module-container {
          width: 20px;

          .module-i {
            font-size: 13px;
          }
        }

        .report-type-type {
          white-space: nowrap;
        }
      }

      .report-name {
        font-weight: variables.$font-weight-bold;
        margin-top: 6px;
        line-height: 150%;
      }
    }
  }

  .user-avatar-and-name {
    vertical-align: -5px; // Hacky fix to alignment issue I couldn't work out
  }

  .report-type-cell {
    width: 315px;
  }

  .created-at-cell {
    width: 150px;
  }

  .actions-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > * + * {
      margin-left: 12px;
    }

    .main-btn {
      opacity: 0;
      pointer-events: none;
    }

    .star {
      &:not(.filled) {
        opacity: 0;
        pointer-events: none;
      }

      .cr-icon-star-filled {
        color: variables.$blue500;
      }
    }

    .actions-dropdown {
      opacity: 0;
      pointer-events: none;
    }
  }

  tr:hover,
  tr.hovered {
    cursor: unset !important;

    .actions-cell {
      .main-btn,
      .star,
      .actions-dropdown.visible {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
