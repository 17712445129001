@import "../../../_common/style/core/variables";

.userbase-users {
  i.cr-icon-accepted {
    font-size: 18px;
  }

  i.cr-icon-minus-circle {
    font-size: 20px;
  }

  .tab-buttons.styling-flat {
    gap: 20px;
    padding-left: 32px;
    margin-top: 24px;
    margin-bottom: 0;

    .tab {
      padding: 0;
      &:hover {
        background-color: $blue50;
      }
    }

    .monitored-users-tab,
    .unmonitored-users-tab {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }

    .tab.active {
      .cr-icon-accepted,
      .cr-icon-minus-circle,
      .cr-icon-risk,
      .tab-text {
        color: $blue500;
      }

      border-bottom: 2px solid $blue500;
    }
  }

  .bulk-action-bar {
    .container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }

    .bulk-actions {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 100%;

      .num-selected {
        align-self: center;
        min-width: 200px;
        margin-right: auto;
      }

      .bulk-action-buttons {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        margin-left: auto;
      }
    }
  }
}
