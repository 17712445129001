@import "../../../_common/style/core/variables";

.trend-score {
  display: flex;
  flex-direction: row;
  align-items: center;

  .trend-score-icon {
    width: 15px;
    height: auto;
    fill: $medium-grey;
    margin-right: $global-padding * 0.5;

    &.green {
      fill: $cstar-excellent;
    }

    &.red {
      fill: $cstar-low;
    }
  }
}
