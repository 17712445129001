@import "../../_common/style/core/variables";

.tm-result-date {
  font-size: 14px;
  color: $grey900;
  .time {
    font-size: 12px;
    color: $grey500;
    padding-left: 8px;
  }
}

.tm-feed-datetime {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  .dpart {
    margin-right: 4px;
  }
  .tpart {
    color: $grey500;
    font-size: 12px;
    font-weight: 400;
  }
}
