@use "../core/variables";

.empty-card-with-action {
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.filled-styling {
    margin: 20px;
    background-color: variables.$blue50;
    border-radius: 8px;
  }

  > * {
    max-width: 400px;
  }

  .empty-card-icon {
    margin-top: 10px;
  }

  .empty-text {
    margin-top: variables.$space-lg;
    font-weight: variables.$font-weight-bold;
    font-size: 14px;
    line-height: 16px;
  }

  .empty-subtext {
    margin-top: variables.$space-md;
    font-size: variables.$font-size;
    line-height: 21px;
    color: variables.$grey500;
    margin-bottom: variables.$space-md;

    p {
      color: variables.$grey500;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .btn-container {
    margin: 24px 0px;
    display: flex;

    .btn {
      min-height: 32px;
      height: unset;

      .icon-x {
        margin-right: 5px;
        margin-left: 0px;
        transform: rotate(45deg);
      }
    }

    .btn + .btn {
      margin-left: variables.$space-md;
    }
  }

  .support-link {
    margin-bottom: 30px;
    color: variables.$grey500 !important;
    font-size: variables.$font-size-smaller;
    line-height: 20px;
    letter-spacing: 1px;
    font-weight: variables.$font-weight-bold;

    .cr-icon-arrow-right {
      font-size: variables.$font-size-extra-large;
      display: inline-block;
      vertical-align: text-top;
      margin-left: 6px;
      margin-top: -2px;
      color: variables.$grey500;
    }
  }

  .error-card-content,
  .info-card-content {
    .btn-contact-support {
      margin-top: variables.$space-lg;
      display: block;
    }
  }
}

.report-card > .empty-card-with-action .btn-container:empty {
  margin-bottom: 0;
}
