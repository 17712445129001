@import "../../../_common/style/core/variables";

.label-choice-set {
  max-height: 470px;
  overflow: auto;
  margin-bottom: 18px;

  .color-checkbox {
    margin-bottom: 10px;

    .pill-label-content {
      font-size: 12px;
      line-height: 20px;
    }
  }

  p {
    margin-bottom: 18px !important;
    margin-left: 30px;
    color: $grey500;
  }
}
