@import "../../../../_common/style/core/variables";

.compensating-controls-info {
  display: flex;
  flex-direction: column;
  background-color: $blue50;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;

  .content {
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 150%;
    color: $grey900;
    font-size: $font-size;
  }

  .header-container {
    display: flex;

    .detail-container {
      display: flex;
      align-items: center;

      .avatar-container {
        margin-right: 8px;

        .user-avatar {
          max-height: unset;

          .avatar {
            width: 32px;
            height: 32px;
            margin: 0;
          }
        }
      }

      .title-container {
        display: flex;
        flex-direction: column;

        .name {
          color: $grey900;
          font-size: $font-size;
        }

        .date {
          color: $grey500;
          font-size: $font-size-smaller;
        }
      }
    }

    .link-container {
      display: flex;
      align-items: center;
      vertical-align: middle;
      margin-left: auto;
      margin-right: 0px;
      font-size: $font-size-smaller;
      font-weight: $font-weight-bold;
      color: $blue500;

      .cr-icon-external-link {
        margin-left: 3px;
        color: $blue500;
        font-size: $font-size-larger;
      }
    }
  }
}
