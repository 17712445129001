@use "../core/variables";

.beta-label {
  font-size: variables.$font-size;

  .beta-label-inner {
    padding: 2px 6px;
    border-radius: variables.$component-border-radius;
  }
}
