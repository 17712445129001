@import "../../../../_common/style/core/variables";

.color-grade {
  &.small {
    width: $small-color-grade-size;
    height: $small-color-grade-size;
    font-size: 10px;
  }

  &.medium {
    width: $medium-color-grade-size;
    height: $medium-color-grade-size;
    font-size: 24px;
  }
}
