@use "../../../../_common/style/core/variables";

.continue-edits-modal-header {
  h2 {
    padding-bottom: 0;
  }
}

.continue-edits-modal {
  padding-top: 16px;
  padding-bottom: 37px;

  p {
    color: variables.$grey600;
    margin-bottom: 0;
    max-width: 500px;
    margin-right: 50px;
  }

  p:not(:last-child) {
    margin-bottom: 10px;
  }
}

.publish-edits-modal {
  padding-top: 16px;
  padding-bottom: 24px;
  width: 600px;

  p {
    color: variables.$grey600 !important;
    margin-bottom: 0;
    max-width: 500px;
    margin-right: 50px;
  }

  ul {
    color: variables.$grey600;
    margin: 0;
    padding-left: 24px;
    margin-bottom: 20px;
  }

  p:not(:last-child) {
    margin-bottom: 4px;
  }

  .info-banner-actions {
    display: none !important;
  }

  .automation-list {
    overflow-y: auto;
    max-height: 300px;
    margin-bottom: 10px;
    th,
    td {
      padding-left: 0;
      padding-top: 0;
    }
    .enable-td {
      width: 200px;
      .enable {
        height: 42px;
        label {
          margin: 0px !important;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 10px;
        padding-right: 20px;
      }
    }
    .title-td {
      width: 100%;
      .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 5px;
        .name {
          color: variables.$grey600;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
        .action {
          color: variables.$grey600;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}
