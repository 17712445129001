@import "../core/variables";
@import "../core/cr_icons_constants";

.history-back-button {
  .back-btn {
    width: 24px;
    height: 24px;
    position: relative;
    border: 1px solid $grey200;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      border-color: $grey500;
    }

    &:after {
      content: $cr-icon-arrow-right;
      font-family: $icomoon-font-family;
      color: $grey500;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotateY(180deg);
      height: 15px; // Centre
    }
  }
}
