@use "../../../_common/style/core/variables";

.title-and-stat {
  display: flex;
  flex-direction: row;
  align-items: center;

  .ub-single-stat {
    line-height: variables.$line-height-small;
  }
}
