@use "../../../_common/style/core/variables";

.risk-assets-panel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.risk-assets-panel {
  .assets-flex {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    opacity: 1;
    max-width: 100%;

    &.hidden {
      width: 0px;
      opacity: 0;
      overflow: hidden;
    }
  }

  h2 {
    padding-bottom: 0px;
    max-width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3 {
    padding-top: 0px;
    padding-bottom: 8px;
    max-width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title-and-button {
    padding: 0 40px 24px 40px;

    .risk-severity {
      align-items: center;
      display: flex;
    }

    .button-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      min-width: 270px;
      width: 270px;
      margin: 0px;
      margin-right: 5px;
      height: 20px;
      position: relative;
    }

    display: flex;
    flex-direction: row;
    min-height: 40px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .assets-section {
    .scrollable-div {
      .scrollable-content {
        padding-bottom: 80px;
      }
    }
  }
}
