@use "../../../../_common/style/core/variables";

.define-advanced-weights-step {
  .report-card {
    margin-top: 20px;
    padding: 0px;

    .header {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 32px !important;
      justify-content: center;
      margin-bottom: 0px;
      .header-inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        .header-left {
          flex: 1;
        }
        .header-right {
          flex: 0;
        }
      }
      .info-section {
        margin-top: 20px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: variables.$grey500;
      }
    }

    .top-section {
      padding: 30px;
      border-bottom: 1px solid variables.$grey200;
      .title-bar {
        display: flex;
        flex-direction: row;
        width: 100%;
        .help-link {
          flex: 0;
          display: flex;
          flex-direction: row;
          .excel-button {
            margin-right: 10px;
          }
        }
        h1 {
          flex: 1;
          font-weight: 500;
          font-size: 16px;
        }
      }
      p {
        font-weight: 400;
        font-size: 14px;
      }
    }
    .body {
      padding: 30px;
      input {
        width: 100%;
        max-width: 100%;
        background-color: variables.$white;
        transition: background-color 100ms ease-in-out;
        resize: none;
        line-height: 1.4;
        color: variables.$grey900;
        border: 1px solid variables.$grey900;
        border-radius: 4px;
        height: 32px;
        &:disabled {
          background-color: variables.$canvas;
        }
        font-weight: 400;
        padding-left: 5px;
        padding-right: 5px;
      }
      .error {
        border: 1px solid variables.$red !important;
        color: variables.$red !important;
      }
      td {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .id-cell {
        vertical-align: top;
        padding-right: 0px;
        .id {
          width: 100px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 0px;

          input {
            width: 80px;
          }
          .cr-icon-redo {
            height: 10px;
            margin-left: 5px;
            cursor: pointer;
            color: variables.$grey400;
            &:hover {
              color: variables.$blue;
            }
          }
          .disabled {
            cursor: default;
            color: variables.$grey200;
            &:hover {
              color: variables.$grey200;
            }
          }
        }
      }
      .columnular-cell {
        display: flex;
        flex-direction: column;
      }
      .value-cell {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }

        margin-bottom: 8px;
      }
      .with-top-gap {
        margin-top: 8px;
      }
      .with-fn-top-gap {
        margin-top: 22px;
      }
      .formula-text {
        font-size: 10px;
      }
      .func-select {
        justify-content: flex-end;
      }
      .clickable {
        cursor: pointer;
      }
      .text {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
      .qn-num {
        margin-right: 10px;
        background-color: variables.$blue50;
        padding: 5px;
        i {
          margin-right: 10px;
        }
      }
      .label {
        margin-top: 6px;
      }
      .empty {
        padding-right: 0px !important;
        margin-right: 0px !important;
      }
      .weights-cell {
        vertical-align: top;
        width: 80px;
        max-width: 80px;
      }
      .answers-cell,
      .name-cell,
      .type-cell {
        vertical-align: top;
        min-width: 100px;
      }

      .section-row,
      .section-cell {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-bottom: 10px;
        background-color: variables.$blue50;
        height: 40px !important;
        max-height: 40px;
        vertical-align: middle;
        font-weight: 500;
      }

      .weight-row,
      .last-row,
      .data-cell {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        height: unset !important;
        border: 0px solid transparent;
      }
      .last-row {
        .data-cell {
          padding-bottom: 10px !important;
        }
        border-bottom: 1px solid variables.$grey100;
      }
      .first-row {
        .data-cell {
          padding-top: 20px !important;
        }
      }
    }
  }
}
