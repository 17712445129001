@import "../../../_common/style/core/variables";

.skeleton-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $space-md;

  .skeleton-text-row {
    width: 100%;
    height: 12px;
    border-radius: $space-xs;

    background-color: $light-grey;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
}
