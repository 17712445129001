@use "../../_common/style/core/variables";

.panel-header {
  color: variables.$grey900;
  font-size: variables.$font-size-larger;
  font-weight: variables.$font-weight-bold;
  line-height: 150%;
  letter-spacing: -0.176px;
  padding-bottom: variables.$space-lg;
}

.panel-inner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  flex: 1 1 auto;
  gap: variables.$space-md;

  > * {
    border-bottom: 1px solid variables.$grey100;
  }
}
