@use "../../../_common/style/core/variables";

.ub-single-stat {
  .large-stat {
    .header {
      justify-content: space-between;
    }
    .content {
      padding: 20px 32px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .count {
        font-size: variables.$font-size-xx-large;
        padding-bottom: variables.$space-sm;
      }

      .subtext {
        font-size: variables.$font-size-larger;
        color: variables.$label-color;
        padding-bottom: variables.$space-lg;
      }

      .link-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: variables.$font-size-larger;
        color: variables.$label-color;
      }
    }
  }

  .small-stat {
    padding-left: variables.$space-md;
    padding-right: variables.$space-md;
  }
}
