@import "../styles/variables";

.feed-content.remediating-feed {
  .x-table.threat-monitoring-table table tr {
    height: 60px;
  }

  th.severity {
    width: calc(
      28px + $tm-feedtable-inner-padding + $tm-feedtable-outer-padding
    );
  }
  th.date {
    width: calc(150px + $tm-feedtable-inner-padding);
  }
}
