@use "../core/variables";

.progress-bar {
  display: flex;
  flex-direction: column;

  .top-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    .right-text {
      margin-left: auto;
      color: variables.$grey500;
    }
  }

  .bar {
    background-color: variables.$grey200;
    height: 6px;
    width: 100%;
    border-radius: 6px;
    position: relative;

    .bar-inner {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 6px;
      background-color: variables.$blue;
      transition: width 100ms ease-in-out;

      &.has-secondary {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .orange {
      background-color: variables.$orange;
    }
    .red {
      background-color: variables.$red;
    }

    .secondary-bar-inner {
      position: absolute;
      top: 0;
      bottom: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: variables.$orange;
      transition: width 100ms ease-in-out;
    }
  }
}
