@use "../../../_common/style/core/variables";

.tree-table-windowed-container {
  .tree-table-col-headers {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 32px 0 12px 0;
    border-bottom: 1px solid variables.$grey100;

    .col-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      &,
      .icon-arrow {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1px;
        color: variables.$grey500;
      }

      .icon-arrow {
        margin-left: 5px;
        visibility: hidden;
        transition: transform 100ms ease-in-out;
      }

      &:hover,
      &.sort-active {
        .icon-arrow {
          visibility: visible;
        }
      }

      &.clickable {
        cursor: pointer;
      }

      &.main {
        flex: 1;
        padding-left: 32px;
      }

      & + .col-header {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 20px;
      }
    }
  }

  .selection-col,
  .selection-box {
    width: 75px;
    min-width: 75px;

    & + .node-cell.main,
    & + .col-header.main {
      padding-left: 0;
    }
  }
}

.tree-table-windowed {
  $row-height: 72px;
  $circle-width: 20px;

  overflow: visible !important;

  .tree-node {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $row-height;

    & + .tree-node {
      border-top: 1px solid variables.$grey50;
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        color: variables.$blue;
        background-color: variables.$blue50;

        .node-cell.main:after {
          background: linear-gradient(
            to right,
            rgba(variables.$blue50, 0),
            rgba(variables.$blue50, 1)
          );
        }
      }
    }

    &.selected {
      background-color: variables.$blue25;

      .node-cell.main:after {
        background: linear-gradient(
          to right,
          rgba(variables.$blue25, 0),
          rgba(variables.$blue25, 1)
        );
      }
    }
  }

  .node-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: inherit;

    &.main {
      position: relative;
      flex: 1;
      min-width: 0;
      padding-left: 32px;

      .tree-toggle {
        white-space: nowrap;
      }

      & > div:not(.tree-toggle) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:after {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        bottom: 0;
        width: 20px;
        background: linear-gradient(
          to right,
          rgba(variables.$white, 0),
          rgba(variables.$white, 1)
        );
      }

      & + .node-cell {
        margin-left: 5px;
      }
    }

    & + .node-cell {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 20px;
    }
  }

  .indent-blank,
  .indent-line {
    display: inline-block;
    width: $circle-width;
    height: $circle-width;

    &.half {
      width: $circle-width * 0.5;
    }
  }

  .indent-line {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      border-bottom: 1px solid variables.$grey100;
      width: 100%;
      top: 50%;
    }
  }

  .toggle-circle {
    position: relative;
    z-index: 1;
    display: inline-block;

    $clickable-area-size: 15px;
    width: $circle-width + ($clickable-area-size * 2);
    height: $circle-width + ($clickable-area-size * 2);
    padding: $clickable-area-size;
    margin: -$clickable-area-size;

    &.clickable:hover {
      cursor: pointer;

      .toggle-circle-circle {
        transform: scale(1.2);
        border-color: variables.$blue;
        color: variables.$blue;
      }

      .toggle-circle-text {
        transition: color 100ms ease-in-out;
        color: variables.$blue;
      }
    }

    .toggle-circle-circle {
      position: absolute;
      width: $circle-width;
      height: $circle-width;
      border-radius: 50%;
      border: 1px solid variables.$grey500;
      background-color: variables.$white;
      z-index: 1;
      pointer-events: none;

      transition-property: transform, border-color, color;
      transition-duration: 100ms;
      transition-timing-function: ease-in-out;
    }

    .toggle-circle-text {
      position: absolute;
      top: calc(50% - 1px); // Shave off 1px to account for the border
      left: 50%;
      transform: translate(-50%, -50%);
      color: variables.$grey500;
    }

    .line-container {
      left: 50%;
    }
  }

  .tree-toggle {
    margin-right: 10px;
    margin-left: 2px;
    height: 20px;
  }

  .tree-toggle > .line-container {
    position: relative;
    display: inline-block;
    height: $circle-width;
  }

  .line-container {
    position: absolute;
    top: 0;
    bottom: 0;

    .upper-line,
    .lower-line {
      position: absolute;
      width: 1px;
      background-color: variables.$grey100;
      left: 50%;
      z-index: 0;
      height: ($row-height * 0.5) + 2px;
    }

    .upper-line {
      bottom: 50%;
    }

    .lower-line {
      top: 50%;
    }
  }

  .selection-box {
    padding-left: 20px;

    & + .node-cell.main {
      padding-left: 0;
    }

    .selection-dropdown {
      position: relative;
      padding: 4px 17px 4px 4px;
      margin: -4px -3px -4px -4px;
      cursor: pointer;
      border-radius: 2px;
      pointer-events: auto;

      &:hover {
        // background-color: rgba(76,199,238,0.1);
        box-shadow: 0px 0px 0px 1px variables.$blue;
      }

      .icon-chevron {
        position: absolute;
        color: variables.$medium-grey;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        right: 1px;
      }
    }

    .dropdown-menu.open {
      pointer-events: all;
      z-index: 1000;
    }
  }
}
