@use "../core/variables";

.tips-modal {
  width: 600px;
  padding-top: 0 !important;

  .intro-text {
    color: variables.$grey600;
    font-size: variables.$font-size;
    line-height: 22px;
    padding-bottom: 28px;
  }

  .tip-section {
    padding: 24px 0;
    border-top: 1px solid variables.$grey100;

    .expandable-item-header-link {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        color: variables.$blue500;
        font-size: 24px;
        margin-right: 8px;
        margin-bottom: auto;
        margin-top: 2px;
      }

      .cr-icon-accepted,
      .cr-icon-chat-messages {
        font-size: 20px;
      }

      .title {
        font-size: variables.$font-size-larger;
        font-weight: variables.$font-weight-bold;
        color: variables.$grey900;
        line-height: 24px;
        margin-right: auto;
      }
    }

    .tip-content {
      color: variables.$grey800;
      font-size: variables.$font-size;
      line-height: 22px;
      padding-left: 30px;
      margin-top: 8px;

      ol {
        padding-left: 24px;
      }

      .tips-image {
        width: 100%;
        margin-top: 16px;
      }

      li {
        &:not(:first-of-type) {
          margin-top: 16px;
        }
      }
    }
  }

  .tips-footer {
    color: variables.$grey800;
    font-size: variables.$font-size;
    line-height: 22px;
    border-top: 1px solid variables.$grey100;
    padding-top: 24px;
  }
}
