@import "../core/variables";

.empty-state-circle {
  width: 345px;
  height: 345px;
  background-color: $grey50;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 0;
  position: relative;

  .empty-state-img {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 175px;
  }

  .content-library-img {
    top: 36%;
    width: 225px;
  }

  .imported-surveys-img {
    width: 225px;
    transform: translate(25px, -50%);
  }

  h5 {
    margin: 65% 36px 0 36px;
    color: $grey900;
    font-size: $font-size-larger;
    line-height: 150%;
  }

  .subtext {
    margin: 5px 60px 0 60px;
    color: $grey600;
    font-size: $font-size-smaller;
    line-height: 150%;
  }
}

.report-card > .empty-state-circle {
  align-self: center;
  margin: 60px 0;
}
