@import "../../../_common/style/core/variables";

.user-display {
  display: flex;
  background-color: white;

  size: 14px;
  line-height: 21px;

  .avatar-container {
    margin: 0px 16px 0px 0px;
    display: flex;
    align-items: center;

    .user-avatar {
      width: 27px;
      height: 25px;
    }

    &.large-avatar {
      margin: 0px 8px 0px 0px;

      .user-avatar {
        width: 32px;
        height: 32px;
      }
    }
  }

  .detail-container {
    display: flex;
    flex-direction: column;

    .name {
      color: $grey900;
      font-weight: $font-weight-bold;
    }

    .email {
      color: $grey500;
      font-weight: $font-weight-regular;
      word-break: break-all;
    }
  }
}
