@use "../../_common/style/core/variables" as core-variables;
@use "../styles/variables";

.threat-monitoring-tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  height: variables.$threat-monitoring-view-header-height;

  justify-content: flex-start;
  background-color: transparent;

  padding: 8px 24px 0 24px;
  border-bottom: 1px solid core-variables.$grey100;

  .tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    cursor: pointer;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 1px;

    &:hover {
      background-color: core-variables.$blue50;
      color: core-variables.$blue500 !important;
      .label {
        color: core-variables.$blue500 !important;
      }
      border-bottom: 1px solid core-variables.$grey100;
    }

    div {
      color: inherit;
    }

    .label {
      font-size: 14px;
      color: core-variables.$grey900;
    }

    .bracR {
      margin-right: 8px;
    }

    .cr-icon-bookmark {
      font-size: 18px;
      margin-right: 8px;
    }

    .cr-icon-spanner-outline {
      font-size: 18px;
      margin-right: 8px;
    }

    .cr-icon-cancel {
      font-size: 18px;
      margin-right: 8px;
    }
  }
  .tab.selected {
    color: core-variables.$blue500;
    font-weight: 500;
    cursor: default;
    padding-bottom: 0px;
    border-bottom: 2px solid core-variables.$blue;
    .label {
      color: core-variables.$blue500;
    }
  }
  .sep {
    width: 24px;
    min-width: 16px;
  }
  .all {
    flex: 1;
    width: 100%;
  }
}
