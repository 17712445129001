@import "../../core/variables";
.height-adjustable {
  position: relative;
  overflow: hidden;
  height: 100%;

  // Hax to make any scrollbars invisible
  .height-adjustable-inner {
    height: 100%;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    overflow-y: scroll;

    &::-webkit-scrollbar {
      background: transparent; /* Chrome/Safari/Webkit */
      width: 0;
    }
  }

  .height-adjustable-watcher {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .height-adjustable-bottom {
    margin-top: auto;
    padding-bottom: 20px;
  }

  .scroll-fader:not(.disabled) {
    position: absolute;
    left: 0;
    right: 0;
    height: 80px;
    z-index: 1;
    pointer-events: none;

    &.top-fader {
      top: 0;
      background: linear-gradient(
        to top,
        rgba($primary-black, 0) 0%,
        darken($primary-black, 7) 100%
      );
    }

    &.bottom-fader {
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba($primary-black, 0) 0%,
        darken($primary-black, 5) 100%
      );
    }
  }
}
