@use "../../_common/style/core/variables";

.survey-builder-help-guide {
  h2 {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 0;
  }

  h3 {
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 0;
  }

  * + h2,
  * + h3 {
    margin-top: 10px;
  }

  p {
    line-height: 150%;
    color: variables.$grey500;
    margin: 16px 0;
  }

  ul,
  ol {
    margin-top: 0;
    li {
      color: variables.$grey500;
      line-height: 150%;
      & + li {
        margin-top: 5px;
      }
    }
  }
}
