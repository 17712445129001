@import "../../../_common/style/core/variables";

.userbase-permission-header {
  .name-widget {
    margin-left: 16px;
    background-color: violet;
    div.score {
      margin-left: 8px;
    }
  }

  .header-metadata-section {
    .metadata-widget {
      align-items: center;

      .header-icon {
        margin-right: 8px;
      }

      .metadata-header-text {
        padding-right: 4px;
        font-weight: $font-weight-bold;
        color: $grey600;
        line-height: $line-height;
      }
    }
  }
}
