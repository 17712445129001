@import "../core/variables";

.search-empty-card {
  padding: 30px 30px 48px;
  text-align: center;

  .search-empty-icon {
    margin-top: 10px;
  }

  .empty-search-text {
    margin-top: 24px;
    font-weight: $font-weight-bold;
    font-size: 14px;
    line-height: 16px;
  }

  .empty-search-subtext {
    margin-top: 16px;
    line-height: 150%;
    color: $grey700;
  }

  .btn {
    margin-top: 24px !important;
  }

  .progress-bar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin-top: 32px;
    height: 100px;

    .progress-bar {
      margin-top: 10px;
      max-width: 400px;
      width: 400px;
    }
    .msg {
      margin-top: 20px;
      margin-bottom: 20px;
      max-width: 500px;
      width: 500px;
      color: $grey500;
    }

    .btn {
      margin-top: 24px !important;
    }
  }
  .scan-error {
    margin-top: 32px !important;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $grey500;
  }
}
