@use "../../../../_common/style/core/variables";

.userbase-user-permission-apps-slide-panel {
  // Userbase specific style to remove bottom border from report card
  border-top: unset;
  box-shadow: unset;

  // Adjust the report card header to unset the bottom border and adjust padding
  // for this specific component. new-styles is required as this is what report card
  // component is setting
  &.new-styles {
    .header {
      justify-content: space-between;
      border-bottom: unset;
      padding: 0 variables.$space-xl variables.$space-lg variables.$space-xl;
    }
  }

  .content {
    .score-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
    }
  }
  .section-title {
    font-size: variables.$font-size-larger;
  }

  .apps-table {
    // Adjust the padding for the table when in slide panel
    .section-body {
      padding: 0px variables.$space-sm 0px variables.$space-xl;
    }
  }
}
