@import "../../../_common/style/core/variables";

.contact-select {
  display: flex;
  flex-direction: column;

  .add-contacts {
    display: flex;
    flex-direction: row;

    .custom-select {
      margin-right: 16px;
      flex-grow: 1;

      .option {
        .user-display {
          .email {
            max-width: 250px;
          }
        }
      }

      .input-container {
        min-width: 220px;
        flex-grow: 1;
      }
    }

    .new-recipient-container {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .surface {
        min-width: 350px;
      }
    }
  }

  .custom-select {
    .options-container {
      .search-empty-card {
        max-width: unset;
      }
    }
  }

  .selected-contacts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;

    .selected-contact {
      margin-bottom: 8px;
      margin-right: 8px;
      padding: 8px;
      background-color: $blue50;
      border-radius: 8px;
      display: flex;
      align-items: center;

      > * {
        background-color: $blue50;
      }

      &.invalid {
        border-color: $red400;
        background-color: $red50;

        .user-display {
          background-color: $red50;
        }

        > * {
          background-color: $red50;
        }
      }

      .user-display {
        margin-left: 6px;
      }

      .btn-icon {
        .icon-x {
          font-size: 16px;
        }
      }
    }
  }
}
