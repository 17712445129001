@use "../../_common/style/core/variables";

.create-threat-remediation-modal-header {
  border-bottom: 1px solid variables.$light-grey;
  background-color: variables.$grey50;
  width: 1000px;
  padding-top: 24px !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  word-wrap: break-word;
}

.create-threat-remediation-modal-footer {
  width: 1000px;
}

.create-threat-remediation-modal {
  width: 1000px;

  .report-card-sections {
    display: flex;
    padding-top: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .report-card-section {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      &:not(:last-child) {
        border-bottom: 1px solid variables.$grey100;
      }

      .section-label {
        width: 152px;
        font-weight: 500;
      }
      .section-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;

        input {
          max-width: 100%;
        }
      }
    }
    p,
    .description {
      font-size: 14px;
      font-weight: variables.$font-weight-regular;
      line-height: 150%;
      color: variables.$grey500;
    }

    .description-editor {
      width: 100%;
      &.rich-text-edit-v2 {
        max-width: 100% !important;
      }
    }

    .request-name-section {
      padding-bottom: 16px;
      .text-field {
        width: 100%;
      }
    }

    .form-section {
      padding: 0;
    }

    .severity-section {
      margin-bottom: 24px;

      .severity-selector {
        width: 100%;
      }
    }

    .schedule-section {
      .indent {
        margin-top: 8px;
        margin-left: 30px;
      }

      .upper-indent {
        margin-top: 8px;
        margin-left: 30px;
        margin-bottom: 0px;
      }

      .lower-indent {
        margin-top: 0px !important;
        margin-left: 30px;
        margin-bottom: 0px;
      }

      .due-date {
        + * {
          margin-top: 8px;
          margin-bottom: 24px;
        }

        .due-date-picker {
          margin-top: 14px;
        }
      }

      .reminder-inner {
        .color-checkbox:not(:last-of-type) {
          margin-bottom: 8px;
        }

        .reminder-picker {
          margin-top: 28px;
          margin-left: 30px;
        }
      }
    }

    .comments-section {
      .indent {
        margin-top: 8px;
        margin-left: 30px;
      }
    }

    .contact-select-v2-container {
      width: 100%;
    }
  }

  .input-separated {
    margin-top: 20px;
  }

  .label {
    text-align: left;
    font-weight: variables.$font-weight-bold;
    margin-bottom: 4px;

    &:not(:first-of-type) {
      padding-top: 8px;
    }
  }

  .caption {
    color: variables.$grey500;
  }
}
