@use "../core/variables";

.survey-details-options-card {
  .options-content {
    padding: 20px 32px 32px;
    display: flex;
    flex-direction: column;
    gap: variables.$space-md;
  }

  .add-to-shared-profile-option {
    .option-header {
      display: flex;
      align-content: center;
      gap: variables.$space-md;
      color: variables.$grey900;
      font-size: variables.$font-size;
      font-weight: variables.$font-weight-bold;
      line-height: variables.$line-height;
    }

    .option-sub-header {
      display: flex;
      align-content: center;
      gap: variables.$space-xs;
      padding: variables.$space-xs 0;
      color: variables.$grey700;
      font-size: variables.$font-size;
      font-weight: variables.$font-weight-regular;
      line-height: variables.$line-height;

      a {
        align-self: center;
      }

      .view-copy-link {
        align-self: center;
        color: variables.$grey700;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    & + .option-toggle {
      padding-top: variables.$space-md;
      border-top: 1px solid variables.$grey100;
    }
  }

  .option-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 variables.$space-md;

    & + .option-toggle {
      margin-top: 16px;
    }

    .toggle-switch {
      margin-right: 10px;
    }

    a {
      text-decoration: none !important;
      color: variables.$grey500;
      margin-left: 5px;

      &:hover {
        color: variables.$font-color;
      }

      .cr-icon-info {
        color: inherit;
      }
    }
  }
}
