@import "../core/variables";

.survey-details-remediation-card {
  .info-banner-container {
    padding: 16px 32px 0 32px;
  }

  .expanded-risk-details {
    padding: 0 32px;
    color: $grey500;
  }

  .sev-cell {
    text-transform: capitalize;
    min-width: 128px;
  }

  td.clickable:hover {
    color: $blue;
  }
}
