@use "../../_common/style/core/variables";

.empty-card-with-action {
  .circled-icon {
    width: 48px;
    height: 48px;
  }
}

.trust-page-documentation-table {
  // Last updated and actions columns are fixed width
  td:nth-of-type(2) {
    width: 150px;
  }
  th:nth-of-type(2) {
    width: 150px;
  }
  td:nth-of-type(3) {
    width: 40px;
  }
  th:nth-of-type(3) {
    width: 40px;
  }

  .doc-row {
    vertical-align: top;
    td {
      border-top: 1px solid variables.$grey100;
      padding-top: variables.$space-md;
      padding-bottom: variables.$space-md;
    }

    .cell-top-content {
      min-height: 40px;
      padding-top: 10px;
    }

    .doc-cell {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .file-type-icon {
        min-width: 40px;
        width: 40px;
        margin-right: 20px;
      }

      .name-and-desc {
        font-weight: variables.$font-weight-regular;
        line-height: 150%;
        flex-grow: 1;
        align-self: center;

        .desc {
          margin-top: variables.$space-md;
          border-radius: variables.$component-border-radius;
          border-left: 4px solid variables.$grey200;
          background: variables.$grey50;
          padding: variables.$space-xs 12px;
          color: variables.$grey600;
          font-size: variables.$font-size-smaller;
          font-style: normal;
          line-height: variables.$line-height-small;
          white-space: pre-wrap;
        }
      }
    }

    .dropdown-item {
      height: 66px;

      &.dropdown-action-meatball {
        display: flex;
        flex-direction: column;
        gap: 7px;

        height: 100%;

        &:first-of-type {
          border-bottom: 1px solid variables.$grey100;
        }

        .action-label {
          display: flex;
          width: 100%;
          color: variables.$grey900;
          font-size: variables.$font-size;
        }
      }
    }
  }
}
