@import "../../../_common/style/core/variables";

.app-vendor-monitored {
  display: flex;
  flex-direction: row;
  align-items: center;

  .filled-icon {
    height: 20px;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
  }

  .monitored {
    .filled-icon {
      background-color: $green100;
      [class^="cr-icon-"],
      [class*=" cr-icon-"],
      [class^="icon-"],
      [class*=" icon-"] {
        line-height: 20px;
        font-size: 8px;
        color: $green600;
      }
    }
  }

  .not-monitored {
    .filled-icon {
      background-color: $grey100;
      [class^="cr-icon-"],
      [class*=" cr-icon-"],
      [class^="icon-"],
      [class*=" icon-"] {
        line-height: 20px;
        font-size: 8px;
        color: $grey500;
      }
    }
  }

  .btn {
    margin-left: $space-sm;
  }
}
