@use "../../../../_common/style/core/variables";

.filter-panel {
  .title-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    h2 {
      margin-right: auto;
      padding-bottom: 0;
    }

    button + button {
      margin-left: 16px;
    }
  }

  p {
    color: variables.$grey400;
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .explanation-text {
    margin-top: 0;
    font-size: 14px;
    line-height: 16px;
  }

  h3 + .explanation-text {
    margin-top: -15px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .vendor-tier-badge-display img {
    margin-bottom: 0px;
  }

  .manage-labels {
    cursor: pointer;
    margin-bottom: 20px;

    &,
    span {
      color: variables.$blue;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    span {
      margin-right: 10px;
    }
  }

  .label-filter-checkbox {
    display: flex;
  }

  .filter-date-options {
    display: flex;
    justify-content: space-between;

    .date-picker-container {
      &.double {
        max-width: 48%;
      }

      &.single {
        width: 100%;
      }

      input[type="number"] {
        width: 100%;
      }

      input[inputMode="numeric"] {
        width: 100%;
      }

      .date-picker {
        width: 100%;
      }
    }
  }

  .no-labels {
    font-style: italic;
    color: variables.$medium-grey;
  }

  .filter-selection-lists {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .filter-selection-list {
      width: 50%;

      &:not(:first-of-type) {
        padding-left: 10px;
      }
    }
  }

  .filter-selection-list {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;

    .color-checkbox {
      margin-bottom: 15px;
    }

    .color-checkbox:last-of-type {
      margin-bottom: 10px;
    }
  }

  .range-slider-container {
    margin-bottom: 40px;
  }

  .filter-heading {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .not-applicable-label {
      margin-left: 20px;
      border-radius: 2px;
    }
  }

  .filter-subheading {
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .slide-panel-section {
    &.not-applicable {
      opacity: 0.5;
    }
  }

  .filter-label-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: -3px;
    margin-bottom: -3px;

    .pill-label {
      margin-top: 3px;
      margin-bottom: 3px;
      margin-left: 0;
      height: 22px;

      &:not(.bordered) {
        border: 1px solid transparent;
      }

      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }
  }

  .range-slider-container {
    margin-bottom: 20px;
  }

  .divider {
    border-bottom: 1px solid variables.$light-grey;
    margin: 32px 0;

    &:first-of-type {
      margin: 28px 0 32px 0;
    }
  }

  .section-body {
    .label-list {
      display: flex;
      flex-wrap: wrap;
      max-width: 296px;

      .pill-label {
        margin-bottom: 12px;

        // CM: These labels are in a delicate balancing act, this should work all
        // the time but fingers crossed...
        &:nth-child(odd) {
          margin-right: 12px;
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 136px;
    padding: 24px 40px 80px 40px;
    border-top: 1px solid variables.$grey100;
    background-color: rgba(variables.$blue, 0.025);

    & > button {
      flex: 1;

      &:not(:first-of-type) {
        margin-left: 8px;
      }

      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }
}

.range-slider-container {
  .num-displays {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    margin-top: 5px;

    .reset-link {
      position: absolute;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      color: variables.$blue;
    }

    .num-display {
      color: variables.$primary-black;
    }
  }

  .range-slider {
    display: block;
    position: relative;
    width: 100%;
    height: 20px;

    .bg-line {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% - 2px);
      height: 2px;
      background-color: variables.$blue;

      &:has(+ .highlight) {
        background-color: variables.$canvas;
      }

      &.highlight {
        background-color: variables.$blue;
      }
    }

    .hover-values-container {
      position: absolute;
      left: 10px;
      right: 10px;
      pointer-events: none;
    }

    .hover-value {
      position: absolute;
      top: -18px;
      font-size: 11px;
      padding: 2px;
      box-shadow: 0 0 3px 0 variables.$light-grey;
      transition: opacity 100ms ease-in-out;
      opacity: 0;

      &.visible {
        opacity: 1;
      }

      &.left {
        transform: translateX(-50%);
      }

      &.right {
        transform: translateX(50%);
      }
    }

    input[type="range"] {
      position: absolute;
      width: 100%;
      height: 20px;

      // Begin hacky styling of range sliders...

      pointer-events: none;
      -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
      background: transparent; /* Otherwise white in Chrome */

      &:focus {
        outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
      }

      &::-ms-track {
        width: 100%;
        cursor: pointer;

        /* Hides the slider so custom styles can be added */
        background: transparent;
        border-color: transparent;
        color: transparent;
      }

      @mixin thumb {
        border: 1px solid variables.$medium-grey;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: variables.$white;
        pointer-events: all;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 0 1px variables.$light-grey;
        }
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        @include thumb;
      }

      &::-moz-range-thumb {
        @include thumb;
      }

      &::-ms-thumb {
        @include thumb;
      }
    }
  }
}

#filter_view_frame {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: variables.$filter-bar-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: variables.$light-blue;
  box-shadow: 0 1px 0 0 variables.$blue;
  // make sure the z-index of the filter bar is below the side navigation and its popups
  z-index: variables.$user-action-bar-z-index - 1;
  overflow: hidden;

  opacity: 0;
  pointer-events: none;
  transform: translateY(-(variables.$filter-bar-height));
  transition: all variables.$animation-timing ease-in-out;

  .regular-text,
  .hover-text {
    position: absolute;
    transition: transform 100ms ease-in-out;
    color: variables.$blue;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .regular-text {
    transform: translateY(0);
  }

  .hover-text {
    transform: translateY(variables.$filter-bar-height);
  }

  &:hover {
    background-color: variables.$blue;

    .regular-text,
    .hover-text {
      color: variables.$white;
    }

    .regular-text {
      transform: translateY(-(variables.$filter-bar-height));
    }

    .hover-text {
      transform: translateY(0);
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
}
