@import "../../../_common/style/core/variables";

.domains-ips-panel-group .slide-panel {
  width: 700px !important;
  padding-bottom: 0;

  .title {
    border-bottom: none !important;
  }
}
