@use "../../../../_common/style/core/variables";

.create-template-after-generate-modal {
  width: 620px;
}

.create-template-after-generate-modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}
