@use "../../_common/style/core/variables";

.contact-details-fields {
  .field-label {
    color: variables.$grey900;
    font-weight: variables.$font-weight-bold;
    line-height: variables.$line-height;
    padding: variables.$space-sm 0;
  }
}
