@use "../core/variables";

.survey-details-overview-card {
  .overview-table {
    .status-row {
      .popup-target {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .cr-icon-typosquatting-1 {
          color: variables.$grey500;
        }

        &:hover .cr-icon-typosquatting-1 {
          color: variables.$blue;
        }

        & > * + * {
          margin-left: 10px;
        }
      }
    }

    .questionnaire-score-container {
      .dismissable-popup-click-capture {
        display: inline-flex;

        .score-text {
          min-width: 0px;
        }

        .score-out-of {
          font-size: 12px;
          vertical-align: top;
        }
      }
    }

    .row-value {
      .no-score {
        margin-left: 20px;
        color: #d03801;
      }
    }

    .sent-to-list {
      .row-value {
        max-height: unset;
        flex-direction: column;
        align-items: flex-start;
      }

      .shared-with-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &.clickable {
          cursor: pointer;
        }

        > * + * {
          margin-top: 16px;
        }

        .invite {
          line-height: 150%;
          .invited-text {
            color: variables.$grey500;
            font-weight: variables.$font-weight-regular;
          }
        }

        .user-avatar-and-name {
          &:last-of-type {
            margin-bottom: 16px;
          }
        }
      }

      .btn {
        white-space: nowrap;
      }
    }
  }

  .imported-doc-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: variables.$font-weight-regular;

    > * + * {
      margin-left: 5px;
    }

    > div {
      color: variables.$blue500;
    }

    .cr-icon-export-thin {
      color: variables.$grey500;
      font-size: 18px;
    }

    &:hover {
      > div {
        cursor: pointer;
        color: variables.$blue600;
      }
    }
  }

  .clickable-value {
    display: flex;
    font-weight: variables.$font-weight-bold;
    flex-direction: row;
    align-items: center;

    .cr-icon-pencil {
      margin-left: 10px;
      color: variables.$grey300;
    }

    .cr-icon-chevron {
      margin-left: 10px;
    }

    .not-set {
      color: variables.$grey500;
    }

    &:hover {
      cursor: pointer;

      &,
      .cr-icon-pencil,
      span {
        color: variables.$blue;
      }
    }
  }

  .due-date-selector {
  }

  .survey-sections-header,
  .survey-section {
    padding: 0 16px;
    margin: 12px 0;
    white-space: nowrap;
  }

  .survey-sections-header {
    color: variables.$grey500;
    letter-spacing: 1px;
    font-size: 12px;
  }
}
