@use "../../../_common/style/core/variables";

.breachsight-waiver-confirm-step {
  .risk-to-waive {
    .category-title {
      color: variables.$grey600;
      white-space: pre;
    }
  }
}
