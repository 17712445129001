@use "../../../_common/style/core/variables";

.percentage-bars {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  color: variables.$black;
  background-color: transparent;
  height: 15px;

  font-size: 10px;
  font-weight: variables.$font-weight-bold;
  text-transform: uppercase;

  .pock {
    background-color: variables.$light-blue;
    border: 0.5px solid variables.$blue;
    border-radius: 4px;
    height: 15px;
    width: 15px;
    margin-left: 0px;
    margin-right: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .greyed {
    background-color: variables.$white !important;
    border: 0.5px solid variables.$dark-grey !important;
  }

  .pct-label {
    letter-spacing: 1px;
    color: variables.$medium-grey;
    margin-left: 3px;
  }
}

.questionnaire-resend-alert {
  &.summary-category-card.critical {
    margin-bottom: 10px !important;
  }

  .card-content {
    overflow: visible !important;
  }

  .survey-list-table {
    padding: 10px !important;
  }
}

.survey-list-card {
  .header {
    .search-box-input-container {
      margin-left: auto;
    }
  }

  .tab-buttons-wrapper {
    margin: 20px 0 0 20px;
  }

  .info-banner-wrapper {
    padding: 20px;

    .info-banner {
      margin-bottom: 0;
    }
  }

  .assessment-blank-state {
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      font-style: normal;
      display: block;
    }

    button {
      font-style: normal;
      font-weight: variables.$font-weight-regular;
      margin-bottom: 20px;
    }

    img {
      max-width: variables.$max-page-width;
      width: 100%;
      margin: 40px 0;
      display: block;
    }
  }

  .help-step-imgs {
    width: 100%;
    max-width: variables.$max-page-width;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;

    & > div {
      text-align: left;

      p {
        height: 50px;
        line-height: 25px;
        margin-bottom: 0;
      }

      img {
        max-width: 350px;
        margin-left: -2.8%;
        margin-top: 0;
      }
    }
  }

  .empty-card {
    background: none;
    box-shadow: none;
  }
}

.survey-list-table {
  width: 100%;

  tr {
    height: unset !important;

    td {
      padding-top: variables.$space-md;
      padding-bottom: variables.$space-md;
    }
  }

  .view-link-cell {
    &:hover a {
      text-decoration: underline;
    }

    a {
      letter-spacing: 1px !important;
      font-weight: variables.$font-weight-bold;
      font-size: 11px !important;
      text-transform: uppercase !important;
      transition: color variables.$animation-timing ease-in-out;
    }

    &.disabled {
      pointer-events: none;

      a {
        color: variables.$medium-grey;
      }
    }
  }

  .survey-status {
    .pill-label:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  tr.clickable:hover {
    .date-time-format {
      color: variables.$blue;
    }
  }

  // Column widths
  .status-text {
    width: 160px;
  }

  .vendor-name {
    width: 160px;
  }

  .date-created,
  .date-completed,
  .date-due {
    width: 160px;

    em {
      color: variables.$medium-grey;
      font-weight: variables.$font-weight-regular;
    }
  }

  .shared-with {
    width: 200px;
  }

  .risks-detected {
    width: 150px;
  }

  .attachments {
    width: 25px;
    text-align: center;

    img {
      width: 15px;
    }
  }

  .activity {
    width: 200px;

    .unread-circle {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
      vertical-align: -1px;
      background-color: variables.$blue;
      border-radius: 50%;
    }
  }

  .cstar-score-cell {
    width: 100px;

    .grade-with-score > div {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;

      .color-grade {
        margin-right: 5px;
      }

      .no-score {
        color: variables.$grey500;
      }
    }
  }

  .org-name-cell {
    .org-name-contents {
      display: flex;
      flex-direction: column;

      .pill-label {
        margin-top: 8px;
        display: inline-block;
        width: fit-content;
      }
    }
  }

  .messages {
    text-align: right;
    width: 0.1%;
  }

  .icons {
    .icons-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & > div:not(:last-of-type) {
        margin-right: 5px;
      }

      .cr-icon-q-builder-attachment {
        font-size: 20px;
        color: variables.$grey500;
      }
    }

    img {
      width: 15px;
      vertical-align: -5px;
    }
  }

  .shared-column {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 160px;
  }

  .actions-cell {
    white-space: nowrap;
    width: 0.1%;
  }

  .disabled-row {
    background-color: variables.$grey25;

    &,
    span,
    .org-name {
      color: variables.$grey300;
    }
  }
}

.archived-surveys {
  margin-top: 30px;

  .toggle {
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
      &,
      .icon-chevron {
        color: variables.$blue;
      }
    }
  }
}
