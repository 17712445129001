@use "../../_common/style/core/variables";

.survey-builder {
  .tab-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    .tab-buttons-wrapper {
      flex: 1;
      align-items: center;
    }
    .header-right {
      margin-left: auto;
      display: flex;
      align-items: center;
      flex-basis: auto !important;

      & > div,
      & > button,
      & > a {
        margin-left: 16px;
      }
    }
  }

  .editor-report-card {
    z-index: 1;

    & > .header {
      position: sticky;
      top: -8px;
      z-index: 20;
      padding: 0;
      min-height: 0;
      height: 80px;

      .header-inner {
        background-color: variables.$white;
        padding: 20px 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        margin-top: 8px;
        .header-left {
          flex: 1;
        }
      }

      button {
        white-space: nowrap;
      }
    }

    .cr-icon-redo {
      margin-left: 8px;
    }
  }

  .editor-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .editor-left-pane {
      flex: 2;
      min-width: 0;
      max-width: variables.$survey-editor-max-width;
      border-right: 1px solid variables.$grey100;
      padding-bottom: 200px;

      &.locked {
        opacity: 0.7;
      }
    }

    .editor-right-pane {
      flex: 1;
      padding: 40px;
      background-color: #f9f9fb;
    }

    .survey-builder-help-guide {
      position: sticky;
      top: 112px;
    }
  }

  .preview-button {
    .cr-icon-eye {
      font-size: 20px;
    }
  }

  .survey-locked-message {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: variables.$grey500;
    flex: 1;

    .cr-icon-lock {
      font-size: 14px;
      color: inherit;
      margin-right: 10px;
    }
  }
}

.publish-survey-modal {
  width: 600px;
  max-width: 600px;
}
.revert-survey-modal {
  width: 600px;
  max-width: 600px;
}
