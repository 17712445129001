@import "../core/variables";

.remediation-request-details-v2 {
  .tabs-and-info {
    margin-bottom: 24px;

    .info-banner-block {
      a {
        text-decoration: underline;
      }
    }
  }

  .two-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    & > div + div {
      margin-top: 0;
    }

    & + div {
      margin-top: 20px;
    }

    &.no-stretch {
      align-items: flex-start;
    }
  }

  .remediation-details-progress-card {
    .header {
      .pill-label {
        margin-left: 12px;
      }
    }
  }

  .progress-wrapper {
    margin: 32px 32px 0 32px;
  }

  .timeline-card {
    width: 50%;

    .risks-added {
      h3 {
        font-size: 1em;
        font-weight: $font-weight-bold;
      }
    }
  }

  .messages-panel-container {
    .slide-panel {
      width: 600px;

      .container {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }

  .remediation-details-projection-card {
    .score-projection {
      margin-top: 25px;
      margin-bottom: 20px;

      & > div {
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }
}

.confirmation-modal.submit-message h4 {
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 8px;
}
