@use "../../../_common/style/core/variables";

.vendor-lookups-modal {
  margin: 60px auto 0 auto;
  max-width: 1200px;
  padding: 0 20px;
  text-align: center;

  h2 {
    color: variables.$dark-grey;
    margin-bottom: 10px;
  }

  .instant-reports-usage {
    height: 50px;
    width: 100%;
    margin: 10px 0;
    display: flex;
    margin-bottom: 20px;

    div,
    span {
      color: variables.$medium-grey;
      font-size: 11px;
      font-weight: variables.$font-weight-bold;
      letter-spacing: 1px;
      position: relative;

      &.hide {
        display: none;
      }
    }

    .instant-reports-active {
      display: flex;
      justify-content: flex-start;
      width: 50%;
    }

    .instant-reports-remaining {
      display: flex;
      justify-content: flex-end;
      width: 50%;

      .instant-reports-renewal-date {
        margin-left: 20px;
      }
    }

    .red {
      color: variables.$red;
    }

    .focus-number {
      font-size: 1.7rem;
      font-weight: variables.$font-weight-regular;
    }
  }

  .vendor-list {
    padding: 20px;
  }

  .days-left-cell {
    width: 170px;
    padding-left: 20px;
    color: variables.$medium-grey;
    font-weight: variables.$font-weight-regular;
  }
}
