@use "../../style/core/variables";

.vertical-steps {
  width: 100%;

  .vertical-step {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    .vertical-step-label-and-line {
      display: flex;
      flex-direction: column;

      .vertical-step-label {
        background: variables.$blue500;
        border-radius: 4px;
        min-width: 64px;
        min-height: 30px;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;

        > span {
          font-size: 14px;
          line-height: 14px;
          color: variables.$white;
          //font-weight: $font-weight-bold;
        }
      }

      .vertical-step-line {
        margin-top: 8px;
        align-self: center;
        flex-grow: 1;
        border-right: 1px solid variables.$grey200;
      }
    }

    .vertical-step-content-container {
      flex-grow: 1;
      margin-left: 24px;

      .name {
        font-size: 18px;
        line-height: 24px;
        color: variables.$grey900;
        font-weight: variables.$font-weight-bold;
        margin-bottom: 4px;
      }

      .description {
        font-size: 14px;
        line-height: 21px;
        color: variables.$grey500;
        margin-bottom: 24px;
      }

      .vertical-step-content {
        margin-bottom: 40px;
      }
    }
  }
}
