@import "../../../_common/style/core/variables";

.webscan-tree-view {
  .score-cell,
  .score-header {
    width: 100px;
  }

  .date-scanned-cell,
  .date-scanned-header {
    width: 140px;
  }

  .portfolio-cell,
  .portfolio-header {
    width: 175px;
  }

  .date-scanned-cell span {
    color: inherit;
  }

  .score-cell div {
    color: inherit;
  }

  .url-cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    &,
    .url {
      color: inherit;
    }

    .url {
      line-height: 125%;
      /*
      max-width here works to help limit the URL content area
      so that it can be ellipsized correctly.
      Once the text-overflow: ellipsis is able to apply correctly,
      we generally can use the EllipsizedText component to show a
      properly styled popover with the full content
      */
      max-width: 100%;
    }

    .num-subdomains {
      color: $grey500;
      font-size: 12px;
    }

    .domain-source-label-list {
      margin-left: $space-xs;
    }

    .url + .pill-label,
    .url + .domain-source-label-list {
      margin-left: 10px;
    }
  }

  .labels-cell,
  .labels-header {
    width: 15%;
  }

  .icons-cell,
  .icons-header {
    width: 20px;
  }

  .icons-cell {
    text-align: center;

    .loader-icon {
      width: 20px;
      height: 20px;
    }
  }

  .options-icons-cell,
  .options-icons-header {
    width: 70px;
    justify-content: flex-end !important;
  }

  @media (max-width: 1600px) {
    .portfolio-cell,
    .portfolio-header {
      width: 100px;
    }

    .labels-cell,
    .labels-header {
      width: 10%;
    }
  }

  .clickable > .show-hide-children-cell:hover {
    .url {
      color: $blue;
    }
  }

  .inactive {
    color: $medium-grey;
  }

  tr.inactive {
    & > td .url {
      color: $medium-grey;
    }
  }
}

.ellipsized-treeview-url {
  max-width: 50vw;
}
