@use "../../_common/style/core/variables";

$import-questionnaire-modal-width: 600px;

.import-questionnaire-modal-upload {
  width: $import-questionnaire-modal-width;

  .dragdropupload + * {
    margin-top: 20px;
  }
}

.import-questionnaire-modal-header {
  max-width: $import-questionnaire-modal-width !important;
}

.import-questionnaire-modal-configure-loading {
  width: $import-questionnaire-modal-width;
  padding-top: 0 !important;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * + * {
      margin-top: 24px;
    }

    h4 {
      font-size: variables.$font-size-extra-large;
      font-weight: variables.$font-weight-bold;
    }

    .loading-sub {
      font-size: variables.$font-size-smaller;
      color: variables.$grey400;
      text-align: center;
    }
  }
}

.import-questionnaire-modal-configure {
  width: 1000px;
  background-color: unset !important;

  &-header {
    p {
      max-width: 700px !important;
    }
  }
}
