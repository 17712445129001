@use "../../../_common/style/core/variables";

.update-vendor-tiers-modal {
  max-width: 600px !important;

  .info-banner {
    margin-top: 16px;
  }

  a {
    margin-bottom: 20px;
    text-transform: none !important;
  }
}
