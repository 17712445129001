@use "../../../../_common/style/core/variables";

.color-grade {
  &.small {
    width: variables.$small-color-grade-size;
    height: variables.$small-color-grade-size;
    font-size: 10px;
  }

  &.medium {
    width: variables.$medium-color-grade-size;
    height: variables.$medium-color-grade-size;
    font-size: 24px;
  }
}
