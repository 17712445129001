@import "../core/variables";

.dragdropupload-v2 {
  .inner-container {
    border-radius: $component-border-radius;
    border: 1.5px dashed $grey200;
    width: 100%;
    transition: all 200ms ease-in-out;

    cursor: pointer;

    .drag-drop-zone {
      min-height: 44px; // Maintain height to remove jank when showing loading state
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .desc-text-container {
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      i {
        margin-right: 8px;
        color: $grey500;
      }

      .desc-text {
        line-height: 24px;
        color: $grey500;
      }
    }

    &.drag_active,
    &:hover,
    &:focus {
      background: $blue50;
      border-color: $blue500;

      .desc-text-container {
        i,
        .desc-text {
          color: $blue500;
        }
      }
    }

    &.disabled {
      border-color: $grey100;
      pointer-events: none;

      .desc-text,
      i {
        color: $grey300;
      }
    }
  }

  .upload-error {
    font-size: $font-size-smaller;
    line-height: 18px;
    color: $red400;
    font-weight: $font-weight-bold;
    margin-top: 8px;

    .cr-icon-risk {
      margin-right: 8px;
    }
  }
}
