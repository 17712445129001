@use "../../_common/style/core/variables";

.node-type-icon {
  background-color: variables.$blue50;
  mix-blend-mode: multiply;
  color: variables.$blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  border-radius: 4px;

  & > div {
    color: inherit;
    margin-right: 5px;
    font-size: 16px;
  }

  .minus-marg {
    margin-left: -5px;
    margin-right: 0;
  }

  .severity-icon {
    display: flex;
    align-items: center;
    img {
      height: 18px;
    }
  }
}
