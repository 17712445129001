@import "../../../_common/style/core/variables";

#questionnaires_view {
  h3 {
    font-weight: $font-weight-bold;
    padding: 0;
    margin-left: 0;
    font-size: 18px;
    line-height: 18px;
  }

  .vert-spacer {
    height: 25px;
    min-height: 10px;
    width: 100%;
  }

  .horiz-line {
    height: 1px;
    width: 100%;
    margin-top: 40px;
    border-bottom: 1px solid $light-grey;
  }

  .pivot-tabs {
    .pivot-tabs-buttons-wrapper {
      margin-top: 0px;
      margin-bottom: 12px;
    }

    .pivot-tab-desc {
      p:first-of-type {
        margin-top: 0;
      }
    }
  }
}
