@use "../../../_common/style/core/variables";

form.add-custom-cloudscan-modal {
  .info-banner-block {
    * {
      text-decoration: underline;
      font-weight: 500;
      &,
      &:hover {
        color: inherit !important;
      }
    }
  }
}
