@import "../../../../_common/style/core/variables";

.editable-portfolio-list {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.clickable {
    cursor: pointer;

    &:hover {
      &,
      .cr-icon-pencil {
        color: $blue;
      }
    }
  }

  .cr-icon-pencil {
    margin-left: 10px;
    color: $grey400;
  }
}
