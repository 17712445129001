@use "../../core/variables";

.loader-icon {
  animation: spin 1s linear infinite;
  stroke: variables.$blue;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  &.blue {
    stroke: variables.$blue;
  }

  &.green {
    stroke: variables.$green;
  }

  &.grey {
    stroke: variables.$medium-grey;
  }

  &.dark-grey {
    stroke: variables.$dark-grey;
  }

  &.red {
    stroke: variables.$red;
  }

  &.black {
    stroke: variables.$black;
  }

  &.white {
    stroke: variables.$white;
  }
}
