@use "../../_common/style/core/variables";

.doughnut-chart-and-breakdown {
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: center;
  justify-content: center;

  .chart {
    height: 190px;
    position: relative;

    .total-permissions {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      flex-direction: column;
      align-items: center;

      span.number {
        color: variables.$grey900;
        font-size: variables.$font-size-xx-large;
        font-weight: variables.$font-weight-bold;
      }

      span.label {
        color: variables.$grey600;
        font-size: variables.$font-size-smaller;
        font-weight: variables.$font-weight-regular;
      }
    }
  }

  .breakdown {
    display: flex;
    flex-direction: column;
    gap: 8px;

    div {
      color: variables.$grey600;
      font-size: variables.$font-size-smaller;
      font-weight: variables.$font-weight-bold;
      line-height: variables.$line-height-small;
      display: flex;
      flex-direction: row;
      align-items: center;

      .square {
        height: 16px;
        width: 16px;
        background-color: hsl(0, 0%, 80%);
        border-radius: 2px;
        display: inline-block;
        margin-right: 4px;
      }
    }
  }
}
