@use "../../../_common/style/core/variables";

.user-apps {
  .search-box-input-container {
    padding: 24px 32px 0;
  }

  .user-apps-slide-panel {
    .slide-panel {
      // Important is required to enforce the width
      width: 700px !important;

      // TODO: UserBase specific style to remove bottom border from slide panel title - may change
      .title {
        border-bottom: unset;
      }
    }
  }

  .user-apps-table {
    table {
      th:first-of-type,
      td:first-of-type {
        padding-left: 20px;
      }

      tbody tr {
        background-color: unset;
      }
    }
  }
}
