@import "../../../_common/style/core/variables";

.cloudscan-panel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.cloudscan-panel {
  .slide-panel {
    width: 700px !important;
    padding-bottom: 0;

    .title {
      border-bottom: none !important;
    }
  }

  .container {
    display: flex;
    flex-grow: 1;
  }

  .cloudscan-flex {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    opacity: 1;
    max-width: 100%;

    &.hidden {
      width: 0px;
      opacity: 0;
      overflow: hidden;
    }
  }

  .scrollable-div {
    .scrollable-content {
      padding-bottom: 80px;
    }
  }

  .title-and-button {
    padding: 0 40px 24px 40px;

    a {
      text-transform: none !important;

      &:hover {
        color: $blue !important;
      }

      h2 {
        padding-bottom: 0px;
        max-width: 340px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 24px !important;
      }
    }

    .button-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      min-width: 270px;
      width: 270px;
      margin: 0px;
      margin-right: 5px;
      height: 20px;
      position: relative;
    }

    display: flex;
    flex-direction: row;
    min-height: 40px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .bordered-box {
    width: 100%;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;

    .color-grade {
      max-height: 25px;
      max-width: 25px;
      font-size: 14px;
      margin-right: 0px;
    }

    .score-text {
      font-size: 16px;
      min-width: auto;
      padding-right: 5px;
      line-height: 100%;
    }

    .cstar-text {
      min-width: 100px;
      max-width: 100px;
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      margin-left: 5px;
    }

    .ip-info .ip-info-row {
      border-top: 1px solid $grey100;
    }

    .rating-table-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      min-height: 45px;
      padding: 10px 0;

      border-top: 1px solid $grey100;

      &.align-top {
        align-items: flex-start;

        .rating-table-title {
          padding-top: 3px;
        }
      }

      .rating-table-title {
        width: 200px;
        min-width: 200px;
        color: $grey400;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .rating-table-data {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.labels {
          display: block;
        }
      }
    }
  }

  .progress-limiter {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 50px;
    align-items: center;
    background-color: $red;
  }

  .ipAddress {
    margin-bottom: 5px;
  }

  .meta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 56px;
    margin-bottom: 24px;
    padding-left: 40px;
    padding-right: 40px;

    .stat {
      display: inline-block;
      font-size: $font-size-extra-large;
      line-height: 133%;

      .description {
        margin-top: 8px;
        color: $grey500;
        font-size: $font-size;
        line-height: 150%;
      }
    }

    .score {
      display: inline-block;
      margin-left: $global-padding * 2;

      .score-text {
        min-width: 0;
      }

      .score-out-of {
        font-size: 11px;
        font-weight: $font-weight-bold;
        letter-spacing: 1px;
      }
    }
  }

  .rescan-transition {
    position: relative;
    margin-left: 10px;
    height: 20px;
  }

  .scan-meta-container,
  .rescan-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $medium-grey;
    font-size: 11px;
    white-space: nowrap;
    height: 30px;

    .date-time-format {
      color: $dark-grey;
      letter-spacing: 1px;
      margin-left: -10px;
    }

    .loader-icon {
      width: 15px;
      height: 15px;
      margin-right: $global-padding;
    }
  }

  .rescan-loading {
    margin: -5px -10px;
    transform: translateX(-1px);
    padding: 5px 10px;
    border-radius: 2px;
    box-shadow: 0px 0 0 1px $light-grey;
    color: $dark-grey;

    .percent-background {
      position: absolute;
      border-radius: 2px;
      top: 0;
      left: 0;
      bottom: 0;
      opacity: 0.2;
      transition: width $animation-timing ease-in-out;
      background-color: $blue;
    }
  }

  .rescan-link {
    margin-left: auto;
    color: $blue;
    font-size: 11px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .info-header {
    padding-left: 40px;
    padding-right: 40px;
  }

  .info-banner {
    margin-left: 40px;
    width: 620px;
  }

  .webscan-cert {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 20px !important;
    }
  }

  .ip-addresses .ip-address-row {
    display: flex;
    border-bottom: 1px solid $grey100;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    &.clickable {
      &:hover {
        div,
        i {
          color: $blue500;
        }

        cursor: pointer;
      }
    }

    .ip-address-and-domains {
      flex-grow: 1;
      display: flex;
      line-height: 22px;

      .ip-address {
        display: flex;
        min-width: 112px;
        padding-top: 5px;
      }

      .ip-domain-labels {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;

        .pill-label,
        .pill-label-content {
          white-space: unset;
          word-break: break-all;
        }
      }

      .ip-domain-label {
        margin: 5px;
        display: flex;
        flex-direction: column;
      }
    }

    i {
      font-size: 10px;
      color: $grey300;
      margin: 0 20px;
    }
  }

  .check-item {
    @keyframes flash-in {
      0% {
        background: $light-grey;
      }
      100% {
        background: none;
      }
    }

    &.highlight {
      animation: flash-in 2s;
    }

    .section-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: inherit;
      font-weight: inherit;

      .dismissible-popup-container {
        margin-left: auto;
        margin-right: 8px;
      }
    }

    .slide-panel-section {
      border-top: 1px solid $grey50;

      .section-header {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      .section-header h3 {
        font-size: 15px;
        font-weight: $font-weight-bold;

        .severity-icon {
          margin-right: 16px;
        }
      }

      .section-body {
        padding-left: 80px;
        padding-bottom: 24px;

        > :not(:last-child) {
          margin-bottom: 24px;
        }

        .label {
          margin-bottom: 8px;
        }
      }

      .check-source {
        color: $grey900;
      }

      .check-description {
        color: $grey500;
        margin-bottom: 24px;
        line-height: 150%;
      }

      .label {
        margin-bottom: 8px;
        color: $grey500;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 100%;
        font-weight: $font-weight-bold;
        letter-spacing: 1px;
      }

      .expected-actual {
        .label {
          margin-bottom: 8px;
          color: $grey500;
          text-transform: uppercase;
          font-size: 10px;
          line-height: 100%;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .content {
          padding: 12px 16px;
          margin-bottom: 24px;
          font-size: 12px;
          font-family: "Andale Mono", AndaleMono, monospace;
          background-color: $canvas;
          color: $grey900;
          max-width: unset;
          overflow: auto; // ensure long descriptions can scroll horizontally.

          > div {
            color: $grey500;
            padding-bottom: 7px;
          }
        }

        p {
          margin-bottom: 24px;
        }
      }

      .cve-link {
        margin-bottom: 16px;

        a {
          text-transform: none;
        }

        .cr-icon-arrow-right {
          vertical-align: -4px;
          color: $blue;
          font-size: 20px;
        }
      }

      .check-source {
        padding-bottom: 5px;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 150%;
      color: $grey900;
    }

    .section-header h3 {
      width: 100%;
    }
  }

  .no-changes {
    font-style: italic;
    padding: 0 40px;
  }

  .cloud-connections {
    .info-table {
      margin-top: 20px;

      .row-value-cell {
        padding-left: $space-xs;

        .provider-logo {
          padding-right: $space-xs;
        }
      }
    }

    .slide-panel-section {
      .info-table {
        border-top: 1px solid $grey50;
        margin-top: 0;
      }
    }
  }
}
