@import "../../_common/style/core/variables";

.tm-thread-entries {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.tm-thread-entry {
  font-family: "Courier";
  font-size: 12px;

  .attribution {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-weight: $font-weight-bolder;

    .date {
      padding-left: 8px;
      color: $red500;
    }

    mark {
      background-color: rgba(255, 219, 0, 0.4);
      font-weight: 600;
      display: inline;
    }
  }

  &.in-feed .attribution {
    gap: 4px;
  }

  .text {
    color: $grey900;

    mark {
      background-color: rgba(255, 219, 0, 0.4);
      font-weight: 600;
      display: inline;
    }
  }

  // Loop over each color pair to generate the CSS for each
  @each $name, $color in $label-colors {
    .#{$name} {
      &,
      * {
        color: $color;
      }
    }
  }
}
