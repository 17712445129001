@use "../../_common/style/core/variables";

.modal-content-container:has(.edit-security-links-modal) {
  .modal-header p,
  .modal-content p {
    margin-bottom: 0 !important;
  }

  .modal-content {
    padding: 0 variables.$space-xl variables.$space-lg !important;
  }
}

.full-page-modal.v2 .modal-content-container {
  .edit-security-links-modal {
    .description {
      margin-bottom: variables.$space-md;
      line-height: 22px;
    }

    .edit-grid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: variables.$space-md;
      padding-top: variables.$space-xl;

      .edit-grid-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: variables.$space-md;

        &.header-row {
          div {
            color: variables.$grey500;
            font-weight: variables.$font-weight-bold;
            font-size: variables.$font-size-smaller;
            padding-bottom: variables.$space-sm;
            line-height: variables.$line-height-smaller;
            letter-spacing: 1px;
            border-bottom: 1px solid variables.$grey100;
          }

          .actions-col {
            align-self: flex-end;
          }
        }

        .title-col {
          $category-col-width: 190px;

          width: $category-col-width;
          flex-grow: 0;

          .ug-select {
            width: $category-col-width - 16px;

            .ug-select__control {
              min-width: $category-col-width - 16px;
            }
          }
        }

        .url-col {
          flex-grow: 1;

          .url-text-field {
            margin-bottom: 0;

            .text-field-additional {
              min-height: 0;
            }
          }
        }

        .actions-col {
          width: 30px;
          flex-grow: 0;
        }

        .add-evidence-btn {
          width: 100%;

          .add-evidence {
            background-color: unset;

            .btn-inner {
              color: variables.$blue500;

              span {
                color: variables.$blue500;
              }
            }
          }

          button {
            width: 100%;
            border: 1.5px dashed variables.$grey200;
          }
        }
      }
    }
  }
}
