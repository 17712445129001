@use "../../core/variables";

.btn {
  display: inline-block;
  font-weight: variables.$font-weight-bold;
  background-color: variables.$white;
  border: 1px solid variables.$blue;
  border-radius: variables.$component-border-radius;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  padding: 4px 12px;
  height: 32px;
  position: relative;

  outline: none;

  &.tight {
    padding: 0 4px;
    font-size: variables.$font-size-extra-small;
    height: 24px;
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px variables.$blue100;
    border-color: variables.$blue300;
    border-radius: 2px;
  }

  .btn-inner,
  .btn-inner * {
    font-weight: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: variables.$blue;
    fill: variables.$blue;

    .btn-arrow {
      margin-right: 0px;
      margin-left: 6px;
      font-size: 14px;
    }

    .btn-arrow-left {
      margin-top: -2px;
      margin-right: 6px;
      margin-left: 0px;
      font-size: 14px;
    }
  }

  .btn-inner {
    white-space: nowrap;
  }

  &.btn-rounded {
    border-radius: 100px;
  }

  transition: all 100ms ease-in-out;

  &:hover {
    background-color: variables.$blue50;
  }

  &.btn-active {
    background-color: variables.$blue50;
  }

  .sep {
    display: inline-block;
    width: 1px;
    height: 18px;
    vertical-align: -4px;
    margin-left: 10px;
    margin-right: 5px;
    background-color: variables.$blue;
  }

  .loading-mask {
    display: none;
  }

  &.loading {
    pointer-events: none;
    cursor: default;
    position: relative;

    .btn-inner {
      visibility: hidden;
    }

    .loading-mask {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .loader-icon {
        $icon-size: variables.$global-padding * 2;
        width: $icon-size;
        height: $icon-size;
      }
    }
  }

  .progress-bg {
    display: none;
  }

  &.progress {
    background-color: transparent !important;
    position: relative;

    .progress-bg {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(variables.$blue, 0.05);
      transition: width variables.$animation-timing ease-in-out;
    }
  }

  &.btn-primary {
    background-color: variables.$blue500;

    .btn-inner,
    .btn-inner * {
      color: #ffffff;
    }

    &:disabled {
      background-color: variables.$blue300;
      border-color: variables.$blue300;

      .btn-inner,
      .btn-inner * {
        color: #fff;
        fill: #fff;
      }
    }

    &:hover {
      background-color: variables.$blue600;
    }

    &.pulse {
      animation: animate-pulse-blue 3s linear infinite;

      @keyframes animate-pulse-blue {
        0% {
          box-shadow:
            0 0 0 0 rgba(variables.$blue500, 0.7),
            0 0 0 0 rgba(variables.$blue500, 0.7);
        }
        40% {
          box-shadow:
            0 0 0 20px rgba(variables.$blue500, 0),
            0 0 0 0 rgba(variables.$blue500, 0.7);
        }
        80% {
          box-shadow:
            0 0 0 20px rgba(variables.$blue500, 0),
            0 0 0 10px rgba(variables.$blue500, 0);
        }
        100% {
          box-shadow:
            0 0 0 0 rgba(variables.$blue500, 0),
            0 0 0 10px rgba(variables.$blue500, 0);
        }
      }
    }
  }

  &.btn-filled-secondary {
    background-color: variables.$blue50;
    border-color: variables.$blue50;

    .btn-inner,
    .btn-inner * {
      color: variables.$blue500;
    }
  }

  &.btn-danger {
    .btn-inner,
    .btn-inner * {
      color: variables.$red500;
      fill: variables.$red500;
    }

    border-color: variables.$red500;

    &:hover {
      background-color: variables.$red100;
    }

    .sep {
      background-color: variables.$red500;
    }

    &.btn-primary {
      background-color: variables.$red50;

      &:hover {
        background-color: variables.$red100;
      }
    }
  }

  &.btn-filled-danger {
    color: white;
    background-color: variables.$red500;
    border-color: variables.$red500;

    .btn-inner,
    .btn-inner * {
      color: white;
      fill: white;
    }

    &:hover {
      background-color: variables.$red700;
      border-color: variables.$red700;
    }
  }

  &.btn-tertiary {
    .btn-inner,
    .btn-inner * {
      color: variables.$grey500;
      fill: variables.$grey500;
    }

    border-color: transparent;
    background: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 6px;
    padding-bottom: 6px;

    .loading-mask {
      background: none;
    }

    &:hover {
      background: none;

      .btn-inner,
      .btn-inner * {
        color: variables.$blue500;
        fill: variables.$blue500;
      }
    }
  }

  &.btn-tertiary-filled {
    font-weight: variables.$font-weight-bold;

    .btn-inner,
    .btn-inner * {
      color: variables.$grey25;
      fill: variables.$grey25;
    }

    border-color: transparent;
    background: variables.$grey800;

    .loading-mask {
      background: none;
    }

    transition-timing-function: ease-in-out;
    transition-duration: 100ms;

    &:hover {
      background: variables.$grey900;
      mix-blend-mode: multiply;
    }
  }

  &.btn-tertiary-secondary {
    font-weight: variables.$font-weight-bold;

    .btn-inner,
    .btn-inner * {
      color: variables.$grey600;
      fill: variables.$grey600;
    }

    border-color: transparent;
    background: variables.$white;

    transition-timing-function: ease-in-out;
    transition-duration: 100ms;

    &:hover {
      background: variables.$grey100;
      mix-blend-mode: multiply;
    }
  }

  &.btn-tertiary-danger {
    font-weight: variables.$font-weight-bold;

    .btn-inner,
    .btn-inner * {
      color: variables.$red500;
      fill: variables.$red500;
    }

    border-color: transparent;
    background: variables.$white;

    transition-timing-function: ease-in-out;
    transition-duration: 100ms;

    &:hover {
      background: variables.$red100;
      mix-blend-mode: multiply;
    }
  }

  &.btn-link {
    .btn-inner,
    .btn-inner * {
      color: variables.$blue500;
      fill: variables.$blue500;
    }

    border: none transparent;
    background: none;
    padding-left: 0;
    padding-right: 0;

    .loading-mask {
      background: none;
    }

    &:hover {
      background: none;

      .btn-inner,
      .btn-inner * {
        color: variables.$blue800;
        fill: variables.$blue800;
      }
    }
  }

  &.btn-green {
    .btn-inner,
    .btn-inner * {
      color: variables.$green600;
      fill: variables.$green600;
    }

    border: 1px solid variables.$green600;
    background: variables.$green25;

    .loading-mask {
      background: none;
    }

    &:hover {
      background-color: variables.$green100;
    }
  }

  &:disabled {
    .btn-inner,
    .btn-inner * {
      color: variables.$grey200;
      fill: variables.$grey200;
    }

    border-color: variables.$grey200;
    background-color: transparent;
    cursor: default;
    pointer-events: none;

    &.btn-tertiary {
      border-color: transparent;

      //.btn-inner, .btn-inner * {
      //  color: $light-grey;
      //  fill: $light-grey;
      //}
    }

    .sep {
      background-color: variables.$grey200;
    }
  }

  &.btn-add {
    border-style: dashed;
    &:disabled {
      background-color: variables.$grey50;
    }
  }

  [class^="cr-icon-"],
  [class*=" cr-icon-"],
  [class^="icon-"],
  [class*=" icon-"] {
    font-size: 12px;
    line-height: 16px;
    display: inline;
    margin-right: 4px;
  }

  &.btn-icon-only {
    [class^="cr-icon-"],
    [class*=" cr-icon-"],
    [class^="icon-"],
    [class*=" icon-"] {
      margin-right: 0;
    }
  }

  .cr-icon-trash,
  .cr-icon-eye,
  .cr-icon-noeye,
  .cr-icon-lock,
  .cr-icon-save,
  .cr-icon-plus,
  .cr-icon-arrow-right,
  .cr-icon-help,
  .cr-icon-lightning,
  .cr-icon-lock,
  .cr-icon-share,
  .cr-icon-export-thin {
    font-size: 18px;
  }

  .cr-icon-tool,
  .cr-icon-bulb,
  .cr-icon-archive {
    font-size: 20px;
  }

  .cr-icon-arrow-right {
    margin-right: 0;
    margin-left: 6px;
  }

  .sep + [class^="cr-icon-"],
  .sep + [class*=" cr-icon-"],
  .sep + [class^="icon-"],
  .sep + [class*=" icon-"] {
    margin-right: 0px;
    margin-left: 4px;
  }

  .svg-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  strong {
    margin-left: 5px;
  }

  .unread-circle {
    position: absolute;
    top: 0;
    right: 0;
    background-color: variables.$red;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transform: translate(50%, -50%);

    .count {
      color: variables.$white;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 11px;
      transform: translate(-50%, -50%);
    }
  }

  i.expander {
    transition: all 100ms ease-in-out;
  }
}

.rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tooltip-button {
  display: inline-block;
}
