@use "../../../../_common/style/core/variables";

.shared-profile-access-control-pill {
  background-color: variables.$green200 !important;

  .pill-label-content {
    text-transform: uppercase;
    color: variables.$green600;

    i {
      color: variables.$green600;
      margin-left: 8px;
    }
  }
}

.shared-profile-access-control-pill-popup {
  font-size: 14px;
  line-height: 150%;
  min-width: 400px !important;
}
