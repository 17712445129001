@use "../../_common/style/core/variables";

.trust-page-logo-card {
  --card-size-large: 220px;
  --card-size-medium: 140px;
  --card-size-small: 64px;
  --card-size-x-small: 32px;

  --card-size: var(--card-size-medium);
  --spacing: #{variables.$space-md};
  --border-radius: 16px;

  max-width: var(--card-size);
  max-height: var(--card-size);
  aspect-ratio: 1 / 1;
  display: grid;
  place-content: center;
  border-radius: var(--border-radius);

  &.large {
    --card-size: var(--card-size-large);
    --spacing: #{variables.$space-lg};
  }

  &.medium {
    --card-size: var(--card-size-medium);
    --spacing: #{variables.$space-md};
  }

  &.small {
    --card-size: var(--card-size-small);
    --spacing: #{variables.$space-sm};
    --border-radius: 8px;
  }

  &.x-small {
    --card-size: var(--card-size-x-small);
    --spacing: #{variables.$space-xs};
    --border-radius: 4px;
  }

  .trust-page-logo {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: var(--spacing);

    object-fit: contain;
    object-position: center;
  }
}
