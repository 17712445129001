@use "../../../_common/style/core/variables";

.permission-app-users-slide-panel {
  .report-card {
    // Userbase specific style to remove bottom border from report card
    border: unset;
    box-shadow: unset;
  }

  // Adjust the report card header to unset the bottom border and adjust padding
  // for this specific component. new-styles is required as this is what report card
  // component is setting
  &.new-styles {
    .header {
      justify-content: space-between;
      border-bottom: unset;
      padding: 0 variables.$space-xl variables.$space-lg variables.$space-xl;
    }
  }

  .slide-panel.new-styles {
    border-top: unset;
    box-shadow: unset;
    // Important is required to enforce the width
    width: 700px !important;

    .title {
      border-bottom: unset;
    }
    .header {
      justify-content: space-between;
      border-bottom: unset;
      padding: 0 variables.$space-xl variables.$space-lg variables.$space-xl;
      width: 700px;
      flex-direction: row;
      align-items: center;
    }
  }
  .loading-icon-container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .content {
    .score-container {
      display: flex;
      flex-direction: row;
      gap: variables.$space-sm;
      align-items: center;
    }
  }

  .section-title {
    font-size: variables.$font-size-larger;
  }
}
