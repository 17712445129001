@import "../../../_common/style/core/variables";

.info-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: $blue;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 8px;
  padding: 16px 16px 16px 16px;

  .btn-inner:hover > .icon-x,
  button:hover > * > .icon-x,
  a:hover {
    color: $blue700 !important;
  }

  .vert-space {
    margin-top: 20px;
  }

  .info-banner-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0px 32px 0px 36px;
    color: inherit;
    line-height: 21px;
    margin-top: 2px;

    ul,
    ol {
      margin: 0px;
      padding-left: 20px;
    }

    p {
      margin-bottom: 0px;
      margin-top: 0px;
      color: inherit;
    }

    p:not(:last-of-type) {
      margin-bottom: 8px;
    }

    strong {
      font-weight: $font-weight-bold;
    }
  }

  .no-margin {
    margin: 0px !important;
  }

  .info-banner-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px;
    color: inherit;
    min-height: 16px;

    .cr-icon-info,
    .cr-icon-risk,
    .cr-icon-chat-messages,
    .cr-icon-accepted,
    .cr-icon-question-alert {
      color: inherit;
      font-size: 20px;
      margin-right: 16px;
      align-self: flex-start;
      margin-top: 1px;
    }

    .dismiss {
      button,
      .btn-inner,
      .icon-x {
        font-size: 17px;
        color: $grey400 !important;
        //margin-right: 0;
      }

      &:hover {
        button,
        .btn-inner,
        .icon-x {
          color: $grey600 !important;
        }
      }

      &.btn {
        padding: 0;
        height: auto;
      }
    }

    .message {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: inherit;
      margin-right: 10px;
      line-height: 21px;
      font-weight: $font-weight-bold;
      font-size: 14px;
      align-self: flex-start;
      overflow: hidden;

      > p {
        margin: 0;
        color: inherit;
      }

      a {
        text-transform: none;
        text-decoration: underline;
        font-weight: $font-weight-bolder;

        &,
        &:hover {
          color: inherit !important;
        }
      }
    }

    .info-banner-actions {
      color: inherit;
      display: flex;
      flex-direction: row;
      margin-left: 50px;
      align-self: flex-start;

      .link {
        flex: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: fit-content;
        font-size: 14px;
        color: inherit;
        line-height: 16px;
        margin-right: 4px;

        a {
          color: inherit;
          text-transform: none;

          &:hover {
            color: inherit !important;
            text-decoration: underline !important;
            cursor: pointer !important;
          }

          .icon-arrow {
            color: inherit;
            margin-left: 7px;
            font-size: 16px;
            padding-left: 3px;
          }
        }
      }

      .dismiss {
        button {
          margin-left: 20px;
          color: inherit;
          width: 32px;

          &:hover {
            mix-blend-mode: multiply;
          }

          .btn-inner {
            color: inherit;

            > * {
              font-size: 16px;
              color: inherit !important;
              margin-right: 0px;
            }
          }
        }
      }

      .btn {
        margin-left: 16px !important;
      }
    }
  }

  &.border {
    border: 1px solid $blue300;
  }

  &.info {
    background-color: $blue50;
    color: $blue500;
    border-color: $blue300;

    button:hover {
      background-color: $blue50;
    }

    .btn-icon-popup {
      color: $blue500;
      .btn-icon {
        color: $blue500;
        & > * {
          color: $blue500;
        }
      }
    }

    .dismiss {
      .icon-x {
        font-size: 17px;
        color: $blue500 !important;
      }
      &:hover .icon-x {
        color: $blue700 !important;
      }
    }
  }

  &.warning,
  &.question {
    background-color: $orange-mid50;
    color: $orange-dark500;
    border-color: $orange-light500;

    button:hover {
      background-color: $orange-mid50;
    }

    .btn-icon-popup {
      color: $orange-dark500;
      .btn-icon {
        color: $orange-dark500;
        & > * {
          color: $orange-dark500;
        }
      }
    }

    .dismiss {
      .icon-x {
        font-size: 17px;
        color: $orange-dark500 !important;
      }
      &:hover .icon-x {
        color: $orange600 !important;
      }
    }
  }

  &.error {
    background-color: $red50;
    color: $red500;
    border-color: $red300;

    button:hover {
      background-color: $red50;
    }

    .btn-icon-popup {
      color: $red500;
      .btn-icon {
        color: $red500;
        & > * {
          color: $red500;
        }
      }
    }

    .dismiss {
      .icon-x {
        font-size: 17px;
        color: $red500 !important;
      }
      &:hover .icon-x {
        color: $red700 !important;
      }
    }
  }

  &.success {
    background-color: $green50;
    color: $green600;
    border-color: $green400;

    button:hover {
      background-color: $green50;
    }

    .btn-icon-popup {
      color: $green600;
      .btn-icon {
        color: $green600;
        & > * {
          color: $green600;
        }
      }
    }

    .dismiss {
      .icon-x {
        font-size: 17px;
        color: $green600 !important;
      }
      &:hover .icon-x {
        color: $green600 !important;
      }
    }
  }

  &.chat {
    background-color: $grey100;
    color: $grey800;
    border-color: $grey100;

    .info-banner-block {
      font-style: italic;
    }
  }

  .msg-with-header {
    color: inherit;
    line-height: inherit;
  }

  &.centered {
    .info-banner-line {
      justify-content: center;
      align-items: center;
      .message {
        flex: unset;
        align-self: unset;
      }
      [class*="cr-icon"] {
        text-align: center;
        align-self: unset;
      }
    }
  }
}
