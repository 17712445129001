@import "../../../../_common/style/core/variables";
@import "../../../../_common/style/components/CustomSelect.scss";

.nominated-approver-input {
  .custom-select .options-container .option.approver-option {
    padding: 0;
  }

  .approver-option .user-display {
    background-color: inherit;
    padding: $custom-select-option-padding;
  }
  .approver-option-disabled {
    opacity: 0.5;
  }
}
