@import "../../../../_common/style/core/variables";

.risk-management-tile {
  width: 100%;
  margin-bottom: 24px;

  .container {
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $grey900;
      height: 80px;
      padding: 32px;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $light-grey;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      font-weight: 500;
      font-size: 18px;

      .left {
        flex: 1;
      }

      .right {
        font-size: 18px;
        flex: 0;

        .cr-icon-chevron {
          margin-left: 8px;
        }
      }
    }

    .body {
      padding: 24px 32px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .badges {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 16px;
        grid-column-gap: 16px;
        grid-auto-rows: 1fr;

        .badge {
          display: flex;
          flex-direction: row;
          min-width: 300px;
          padding: 16px;
          border: 2px solid #ecedf3;
          border-radius: 8px;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: $blue50;
          }

          .badge-icon {
            color: $blue;
            font-size: 24px;
            font-weight: 500;
            line-height: 21px;
            margin-right: 16px;
          }

          .cr-icon-chevron {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            margin-left: 20px;
            color: $grey400;
          }

          .details {
            flex: 1;
            display: flex;
            flex-direction: column;

            .title {
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              letter-spacing: 0;

              .meta-info-popup {
                display: inline-block;
                margin-left: 6px;
                line-height: 16px;
                vertical-align: middle;
              }
            }

            .stats {
              display: flex;
              flex-direction: row;
              align-items: center;

              .stat {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 16px;

                .ball {
                  margin-right: 5px;
                  width: 10px;
                  height: 10px;
                  border-radius: 5px;
                }

                .green {
                  border: 1px solid $green;
                  background-color: $green;
                }

                .blue {
                  border: 1px solid $blue;
                  background-color: $blue;
                }

                .orange {
                  border: 1px solid $orange;
                  background-color: $orange;
                }

                .grey {
                  border: 1px solid $grey200;
                  background-color: $grey200;
                }

                .text {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                  letter-spacing: 0px;
                }

                .cr-icon-chevron {
                  margin-left: 8px;
                  font-size: 12px;
                  color: $grey400;
                }

                &.clickable {
                  cursor: pointer;
                  margin: -2px -5px;
                  padding: 2px 5px;
                  border-radius: 2px;

                  &:hover {
                    background-color: $blue50 !important;
                  }
                }

                & + .stat.clickable {
                  margin-left: 11px;
                }
              }

              .dismissible-popup-container > div {
                display: flex;
                flex-direction: row;
                align-items: center;

                .cr-icon-chat-messages {
                  margin-top: 2px;
                  color: $grey300;
                }

                .num-messages {
                  color: $grey600;
                  margin-top: 2px;
                  padding-left: 5px;
                }
              }
            }
          }

          .caret {
            font-size: 24px;
            font-weight: 500;
            line-height: 21px;
          }
        }

        .nohover {
          cursor: default;

          &:hover {
            background-color: $white !important;
          }
        }
      }

      @media (max-width: 1800px) {
        .badges {
          grid-template-columns: 1fr 1fr;
        }
      }
      @media (max-width: 800px) {
        .badges {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .vendor-assessment-upguard-published-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    background-color: $grey50;
    border-radius: $card-border-radius;
    position: relative;
    overflow: hidden;

    & + * {
      margin-top: 24px;
    }

    & + &,
    & + .vendor-assessment-summary-info-block-multi {
      margin-top: 8px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 6px;
      background-color: $blue500;
    }

    .assessment-title {
      font-size: $font-size-larger;
      line-height: 150%;
      color: $grey900;
      font-weight: $font-weight-bold;

      span {
        font-weight: $font-weight-regular;
        color: $grey600;
        margin-left: 16px;
        font-size: $font-size;
      }
    }

    .assessment-title-sub {
      margin-top: 7px;
      font-size: $font-size-smaller;
      color: $grey600;
      line-height: 150%;
    }

    .block-right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      > * + * {
        margin-left: 12px;
      }

      .pill-label-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        .cr-icon-info {
          margin-left: 5px;
        }
      }

      .btn-icon:not(:hover) {
        .cr-icon-export-thin {
          color: $grey400;
        }
      }
    }
  }
}

.vendor-assessment-summary-info-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  .clickable {
    cursor: pointer;

    &:hover {
      background-color: $blue100 !important;
    }
  }

  .top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    padding-right: 10px;

    .img-circle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid $blue100;
      border-radius: 50%;
      background-color: $blue100;
      height: 48px;
      width: 48px;

      img {
        height: 28px;
        width: 28px;
      }
    }
  }

  .cr-icon-chevron {
    margin-left: 10px;
    margin-right: 16px;
    color: $grey400;
    cursor: pointer;
  }

  .detail {
    flex: 1;
    margin-left: 18px;
    margin-right: 24px;
    height: 100%;

    .title {
      color: $grey900;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      //height: 48px;
    }

    .pill-label.red {
      margin-left: 15px;
    }

    .text {
      color: $grey600;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;
    }

    .version-history {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $grey400;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      cursor: pointer;

      .cr-icon-timer {
        font-size: 15px;
        color: inherit;
        margin-right: 4px;
      }

      img {
        padding-right: 5px;
      }

      &:hover {
        color: $blue !important;
      }
    }

    .multi {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .multi-header {
      display: flex;
      flex-direction: row;
    }

    .date-tile {
      display: flex;
      margin-top: 3px;
      flex-direction: column;
      justify-contents: center;
      margin-left: 70px;
    }

    .reassess-tile {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-contents: flex-start;
      margin-top: 3px;
      margin-left: 100px;
    }

    .head {
      display: block;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 1px;
      font-size: 12px;
      line-height: 18px;
      color: $grey400;
    }

    .date {
      flex: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $grey900;
      max-width: 140px;
    }

    .date-clickable {
      &:hover {
        cursor: pointer;
        color: $blue !important;

        .cr-icon-pencil {
          cursor: pointer;
          color: $blue !important;
        }

        .cr-icon-chevron {
          cursor: pointer;
          color: $blue !important;
        }
      }
    }

    .red {
      color: $red;
    }

    .link {
      padding: 0px;
      height: 24px !important;
      width: 80px;
      max-width: 80px;

      &:hover {
        color: $blue !important;
      }
    }

    .cr-icon-chevron {
      font-size: 10px;
      padding-left: 8px;
      margin-left: 0px;
      color: $grey400;
    }

    .cr-icon-pencil {
      padding-left: 8px;
      color: $grey400;
    }
  }
}

.vendor-assessment-summary-info-block-multi {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  .assessment-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    background-color: $grey50;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      background-color: $grey100;
    }

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    .left-side {
      .left-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;

        .assessment-name {
          color: $grey900;
          font-size: $font-size-larger;
          font-weight: $font-weight-bold;
          line-height: 24px;
        }

        .assessment-scope {
          margin-left: 16px;

          .pill-label {
            &:not(:first-of-type) {
              margin-left: 8px;
            }
          }

          &.whole-vendor {
            color: $grey400;
            font-size: $font-size;
            font-weight: $font-weight-regular;
            line-height: 22px;
          }
        }
      }

      .left-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;

        .assessment-version,
        .last-assessed {
          color: $grey900;
          font-size: $font-size-smaller;
          font-weight: $font-weight-regular;
          line-height: 18px;
        }

        .last-assessed {
          margin-left: 4px;
        }

        .assessment-version {
          display: flex;
          flex-direction: row;
          align-items: center;

          &.clickable:hover {
            color: $blue500;
            background-color: $blue200;
          }

          i {
            margin-left: 4px;
          }
        }
      }
    }

    .right-side {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-content: center;
    }
  }
}
