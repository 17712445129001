@use "../core/variables";

.tour-highlight {
  &.flexible {
    display: inline-flex;
    flex: 1;
  }

  &.select-vendor-card {
    height: 70px;
    align-content: center;
    display: inline-flex;
    flex-wrap: wrap;
  }
  &.popup-top {
    margin-bottom: 20px;
  }

  &.popup-bottom {
    margin-top: 20px;
  }

  &.popup-left {
    margin-right: 20px;
  }

  &.popup-right {
    margin-left: 20px;
  }

  &.more-left {
    margin-right: 50px !important;
  }

  .bottom-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2px;

    .progress-dots {
      margin-right: auto;

      .progress-dot {
        height: 13px;
        width: 13px;
        border-radius: 50%;
        display: inline-block;
        margin-left: 10px;

        &.dark-dot {
          background-color: variables.$grey600;
        }

        &.light-dot {
          background-color: variables.$grey200;
        }
      }
    }
  }

  .dismissible-popup-container {
    width: 100%;
  }
  .dismissible-popup-click-capture {
    width: 100%;
  }
  .dismissable-popup {
    .main-area {
      .title {
        font-size: 16px;
        padding-bottom: 6px;
      }
    }
  }
  .highlight-outline {
    outline: 3px variables.$blue100 solid;
    border-radius: 4px;
    display: inline-flex;
    width: 100%;
  }
}
