@use "../../../_common/style/core/variables";

.userbase-identity-breaches {
  .search-box-input-container {
    padding: 20px 32px;
  }
  .identity-breaches-container {
    .severity-col {
      width: 110px;
    }
    .breach-date-col {
      width: 140px;
    }
    .published-date-col {
      width: 140px;
    }
    .breach-type-col {
      width: 140px;
    }
    .data-classes-col {
      width: 270px;
    }
    .status-col {
      width: 100px;
    }
  }
}
