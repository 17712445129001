@use "../../_common/style/core/variables";

.trust-page-content {
  display: flex;
  width: 100%;
  gap: variables.$space-xl;
  margin: variables.$space-xl 0;

  .left-col {
    display: flex;
    flex-direction: column;
    gap: variables.$space-xl;
    min-width: 400px;
    max-width: 400px;

    &.empty {
      display: contents;
    }
  }

  .right-col {
    display: flex;
    flex-direction: column;
    gap: variables.$space-xl;
    flex: 1 1 auto;
  }

  .report-card {
    border-radius: 16px;
    .header {
      color: variables.$grey900;
      min-height: unset;
      padding: variables.$space-md variables.$space-xl;
      line-height: 28px;
    }

    & + .report-card {
      margin-top: 0;
    }
  }

  .card-content {
    padding: variables.$space-xl;
    display: flex;
    flex-direction: column;
  }

  @media all and (max-width: 1024px) {
    .left-col {
      min-width: 300px;
      max-width: 300px;
    }
  }

  @media all and (max-width: 800px) {
    flex-direction: column;
    align-items: center;

    .right-col,
    .left-col {
      display: contents;
    }

    .report-card {
      .header {
        padding: 12px 16px;
      }
    }

    .card-content {
      padding: variables.$space-md;
    }

    .report-card {
      width: 100%;
      min-width: 300px;
    }

    .security-links-card {
      order: 4;
    }
  }

  .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    margin-top: variables.$space-xl;
    gap: variables.$space-xl;
    height: 50vh;

    > span {
      color: variables.$grey600;
      font-size: variables.$font-size-larger;
    }
  }
}

.security-and-compliance-card {
  .security-and-compliance-inner {
    align-items: center;
    flex: 1 1 auto;
    gap: variables.$space-lg;

    .grade-with-score-card {
      max-width: unset;
      border-radius: 16px;
    }

    .badges-container {
      display: flex;
      flex-direction: column;
      gap: variables.$space-lg;
      padding: variables.$space-lg;
      width: 100%;
      min-width: 245px;
      border-radius: 16px;
      background-color: variables.$grey50;
    }
  }
}

.about-card {
  .about-card-inner {
    color: variables.$grey600;
    font-weight: 400;
    line-height: 22px;

    .editor-inner {
      min-height: unset;
      .content-editable {
        min-height: unset;
      }
    }
  }
}

.trust-page-resources-inner {
  flex-wrap: nowrap;
  gap: variables.$space-xl;
}

.security-links-card-inner {
  align-items: flex-start;
  align-self: stretch;
  gap: variables.$space-md;

  .security-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1 1 auto;
    color: variables.$blue500;

    font-size: variables.$font-size;
    font-weight: variables.$font-weight-bold;
    line-height: 22px;

    .actions-col {
      width: 30px;
      flex-grow: 0;

      .cr-icon-external-link {
        margin: auto;
        color: variables.$blue500;
        font-size: variables.$font-size-extra-large;
        font-weight: variables.$font-weight-bold;
      }
    }
  }

  .clickable {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
