.ip-source-label {
  .pill-label-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i {
    margin-left: 2px;
  }
}
