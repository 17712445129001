@import "../../../../_common/style/core/variables";

.report-exports-list {
  tbody tr:not(.pagination-row):hover {
    background-color: $blue50;
  }

  .read-cell {
    width: 6px;

    .unread-dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $blue;
      display: inline-block;
      margin: 0 0 1px 0;
    }
  }

  .filename-cell {
    max-width: 500px;

    .filename-cell-content {
      display: flex;
      flex-direction: row;

      .filename {
        align-self: center;
        margin-right: 10px;
        color: $blue;
        text-decoration: underline;

        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }

      .pill-label {
        margin: 4px 4px 4px 0;
      }
    }

    &.clickable:hover .filename {
      color: $blue;
    }
  }

  .filename-error {
    .filename {
      margin-right: 10px;
      color: $primary_black !important;
      text-decoration: none;
    }
    cursor: default !important;
  }
  .filename-pending {
    cursor: default !important;
    .filename {
      margin-right: 10px;
      color: $primary_black !important;
      text-decoration: none;
    }
  }

  .filetype-icon-cell {
    width: 32px;
    img {
      width: 32px;
      max-width: 32px;
      margin-right: 0px;
    }
  }

  .filetype-cell {
    width: 0.1%;
    white-space: nowrap;
    padding-left: 20px;

    img {
      width: 30px;
      vertical-align: -2px;
    }
  }

  .module-cell {
    .module-container {
      display: flex;
      align-items: center;
      white-space: nowrap;

      i {
        padding-right: 6px;
      }
    }
  }
  .pill-label {
    .pill-label-content {
      display: flex;
      align-items: center;
      i {
        margin-right: 4px;
      }
    }
  }

  .report-type-popup {
    white-space: nowrap;
    color: $blue;
    position: relative;
    display: inline-block;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      border-bottom: 1px dotted $blue;
    }
  }
}
