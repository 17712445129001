@import "../../_common/style/core/variables";

.move-domains-modal {
  .label {
    white-space: normal !important;
  }
  .input-group {
    margin-bottom: 20px;

    .color-checkbox {
      margin-bottom: 2px;
    }

    .unselected {
      opacity: 0.5;
    }
  }
}
