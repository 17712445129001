@use "../core/variables";

.score-projection {
  display: flex;
  flex-direction: column;
  padding: 0px;

  & > div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .scores-row {
    display: flex;
    flex-direction: row;
  }

  .scores-row-stacked {
    display: flex;
    flex-direction: column;
  }

  .score {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 15px;

    .description {
      display: flex;
      flex-direction: row;
      flex: 1;
      color: variables.$grey900;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      align-items: center;

      .dismissible-popup-container {
        margin-top: 2px;
        margin-left: 5px;
      }

      .cr-icon-info {
        font-size: 13px;
        color: inherit;
        padding-bottom: 70px !important;
      }
    }

    .score-value {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      height: 64px !important;
      margin-top: 5px;

      .score {
        margin: 0px !important;
      }

      .arrow {
        color: variables.$grey300;
        font-size: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      span.score-text {
        font-size: 36px;
        line-height: 44px;
        min-width: 70px;
      }

      .score-text-addendum {
        font-size: 36px;
        line-height: 44px;
        color: variables.$grey300;
      }
    }
  }

  .description-row {
    color: variables.$grey900;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    border-top: 1px solid variables.$grey200;

    .heading {
      color: variables.$grey900;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-top: 20px;
    }
    .body {
      margin-top: 20px;
      font-weight: normal;
      color: variables.$grey500;
      font-size: 14px;
      line-height: 150%;
    }
    .body-link {
      margin-top: 20px;
      font-weight: normal;
      color: variables.$grey500;
      font-size: 14px;
      line-height: 150%;
      padding-bottom: 20px;

      a {
        color: variables.$grey500 !important;
      }
    }
  }
}

.score-projection-request-remediation {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: 20px;
  max-height: 660px;
  flex: 0;

  .title {
    text-align: center;
    border-bottom: 1px solid variables.$grey200;
    padding: 30px;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    width: 100%;

    .boxes-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .small-box {
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
        border: 1px solid variables.$grey200;
        margin-left: 10px;
        margin-right: 10px;
        width: 150px;
        border-radius: 8px;

        .label {
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          line-height: 16px;
        }
        .value {
          font-weight: 400;
          font-style: normal;
          font-size: 32px;
          line-height: 48px;
        }
      }
    }

    .impact {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      max-width: 320px;

      .results-title {
        padding: 20px;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
      }
      .results {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid variables.$grey200;
        border-radius: 8px;
        padding: 20px;
        width: 320px;
        height: 320px;

        .heading {
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          line-height: 16px;
        }
        .new-score {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 50px;
          height: 64px !important;

          span.score-text {
            font-size: 36px;
            line-height: 44px;
            min-width: 70px;
          }

          .cstar-text {
            display: flex;
            align-items: center !important;
            justify-content: center !important;
            padding-left: 10px;
          }

          .score-text-addendum {
            font-size: 36px;
            line-height: 44px;
            color: variables.$grey300;
          }
        }
        .description {
          font-weight: 400;
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          color: variables.$grey500;
          text-align: center;

          &:not(:last-of-type) {
            margin-bottom: 8px;
          }
        }
      }

      .results-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #8babe1;
        border-radius: 8px;
        background-color: #f3f7fc;
        padding: 20px;
        width: 320px;
        height: 320px;
        color: #8babe1;

        img.spin {
          animation: spin 1s linear infinite;
          stroke: variables.$blue;

          @keyframes spin {
            100% {
              transform: rotate(360deg);
            }
          }
        }

        img.up {
          margin-top: -64px;
        }

        .heading {
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          line-height: 16px;
          color: #1757c2;
          margin-top: 30px;
        }
        .description {
          font-weight: 400;
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          color: variables.$grey300;
          text-align: center;
          color: #1757c2;
          padding-top: 10px;
          min-height: 85px;
          height: 85px;
        }
      }
    }
  }
}
