@import "../../../_common/style/core/variables";

.bulk-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 16px;

  & > * + * {
    margin-left: 40px;
  }

  .num-selected {
    color: $medium-grey;
    align-self: center;
  }

  .link {
    position: relative;

    &,
    [class^="icon-"],
    [class^="cr-icon-"] {
      cursor: pointer;
    }

    &:hover:after,
    &.active:after {
      content: "";
      position: absolute;
      top: calc(100% + 5px);
      width: 100%;
      height: 2px;
      display: block;
      background-color: $blue;
    }

    [class^="icon-"],
    [class^="cr-icon-"] {
      display: inline-block;
      margin-right: $global-padding * 0.5;
      transition: transform $animation-timing ease-in-out;
    }

    .label-selection-popup {
      position: absolute;
      bottom: 40px;
    }
  }

  .close {
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;
  }
}
