@use "../core/variables";

.text-field-performant {
  position: relative;
  width: 100%;

  /* Enforce layout constraints to avoid large layout re-calcs */
  &.strict {
    contain: strict;

    /* Allow space for focus border */
    padding: 3px;
    margin-left: -3px;
    margin-bottom: -3px;
    width: calc(100% + 6px);

    textarea,
    input {
      /* Allow space for focus border */
      margin: 3px;
      width: calc(100% - 6px);
    }
  }

  textarea,
  input {
    width: 100%;
    position: absolute !important;
    resize: none !important;
    top: 0;
    left: 0;

    &.orange {
      background-color: variables.$orange20;
    }
  }
}
