@use "../../../../_common/style/core/variables";

.name-automation-step {
  .report-card {
    margin-top: 20px;

    .header {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 32px !important;
      justify-content: center;
    }
    .body {
      padding: 30px;
    }

    .field:not(:last-of-type) {
      margin-bottom: 20px;
    }
    .field {
      width: 100%;
      .field-desc {
        font-weight: 500;
        color: variables.$grey900;
        font-size: 1em;
        margin-bottom: 8px;
      }
      .field-input {
        font-weight: 400;
        color: variables.$grey900;
        font-size: 1em;
        width: 100% !important;

        .dismissible-popup-container {
          width: 100%;
        }

        textarea,
        input {
          width: 100%;
          max-width: 100%;
          background-color: variables.$white;
          transition: background-color 100ms ease-in-out;
          resize: none;
          line-height: 1.4;
          color: variables.$grey900;

          &:disabled {
            background-color: variables.$canvas;
          }
        }
        textarea {
          padding-top: 10px !important;
          padding-bottom: 20px;
          resize: vertical;
        }
        .error {
          border: 1px solid variables.$red !important;
          color: variables.$red !important;
        }
      }
      .bordered {
        display: flex;
        flex-direction: row;
        border: 1px solid variables.$grey200;
        padding: 12px;
        height: 56px;
        margin-bottom: 10px;
        border-radius: 4px;

        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          flex: 1;
        }
        .right {
          flex: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          .popup-content {
            z-index: 20;
          }
        }
        .cta {
          color: variables.$grey300;
          white-space: nowrap;
        }
      }
      .bordered:not(:last-of-type) {
        margin-bottom: 10px;
      }
      .clickable {
        cursor: pointer;
      }
      .selected {
        background-color: variables.$blue50;
        border: 2px solid variables.$blue300;
      }
    }
  }
}
