@use "../../_common/style/core/variables";

.get-findings-modal {
  h2 {
    margin-bottom: 20px;
  }

  .search-name {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100%;

    input {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .search-type-keywords {
    margin-bottom: 20px;
    width: 100%;

    .color-checkbox {
      margin-bottom: 5px;
    }

    input {
      width: 100%;
    }
  }
}
