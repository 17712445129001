@use "../../../_common/style/core/variables";

.edit-report-schedule-modal-footer {
  .delete-button {
    margin-right: auto;
  }
}

.edit-report-schedule-modal {
  width: 750px;

  .form-grid {
    display: grid;
    grid-template-columns: 300px auto;
    grid-row-gap: 24px;

    .left {
      padding-right: 80px;
    }

    .grid-section {
      padding-bottom: 32px;
      border-bottom: 1px solid variables.$grey100;
    }

    .grid-section:nth-last-of-type(-n + 2) {
      padding-bottom: unset;
      border-bottom: unset;
    }

    h3 {
      //styleName: db h3;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      margin-bottom: 8px;
    }
  }

  .interval-select {
    max-width: 250px;
    margin-bottom: 16px;
  }

  .date-time-select {
    display: flex;
    align-items: baseline;

    .date-time-select-sub {
      display: flex;
      align-items: baseline;
    }

    p {
      margin-right: 16px;
    }

    .date-day-select {
      width: 120px;
      margin-right: 16px;
      align-self: flex-start;
    }

    .time-select {
      width: 120px;
    }

    .ug-select__control {
      min-width: 120px;
    }
  }

  .color-checkbox {
    padding-bottom: 15px;
  }

  .severity-icons {
    display: flex;
    flex-direction: row;

    .severity-icon {
      display: flex;
      align-items: center;
      padding-right: 6px;

      img {
        height: 20px;
        width: 20px;
      }
    }
  }

  .label-list {
    margin-bottom: 8px;

    .pill-label {
      text-transform: none;
    }
  }
}
