@import "../../../../_common/style/core/variables";

.risk-hostname-selector {
  display: flex;
  flex-grow: 1;

  .color-checkbox {
    margin-top: 6px;
  }

  .items {
    padding-left: 64px;
    min-width: 350px;
    margin-left: auto;

    ul {
      list-style: none;
      margin-top: 0px;
      padding-left: 0px;

      li {
        color: $grey500;
        margin-top: 6px;
      }
    }
  }

  .all-websites-selection-container {
    display: flex;
    flex-direction: row;
    max-width: 360px;

    .color-checkbox {
      width: auto;
      margin-right: 10px;
    }
  }

  .all-websites-selection-description {
    font-style: italic;
    margin-top: 8px;
    margin-bottom: 8px;
    max-width: 360px;
  }

  .future-chk {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
