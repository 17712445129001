@use "../../../_common/style/core/variables";

.share-shared-profile-modal {
  width: 600px;

  .tab-buttons-wrapper {
    margin-bottom: 24px;
  }

  .modal-form {
    p {
      margin-bottom: 10px;
    }
  }

  .form-section-desc {
    min-width: 40%;
    max-width: 40%;
  }

  pre {
    white-space: break-spaces;
    background-color: variables.$grey50;
    border-radius: 4px;
    padding: 12px;
    color: variables.$grey500;
    margin-bottom: 16px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-right: 16px;
    }

    a.btn {
      color: variables.$blue500;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }

  .textarea-with-pills {
    width: 100%;
    height: 100px;
    margin-bottom: 12px;
  }

  .theme-selector {
    margin-top: 24px;

    .label {
      font-weight: variables.$font-weight-regular;
      color: variables.$grey900;
      margin-bottom: 12px;
    }

    .color-checkbox-inner {
      margin-bottom: 12px;
      .label {
        margin-bottom: 0;
        font-weight: variables.$font-weight-regular;
        color: variables.$grey600;
      }
    }
  }

  .score-badge-content {
    margin-top: 24px;

    .label {
      font-weight: variables.$font-weight-bold;
      margin-bottom: 8px;
      color: variables.$grey900;
    }

    .score-badge-themes {
      display: flex;
      flex-direction: row;

      .label {
        font-weight: variables.$font-weight-bold;
        color: variables.$grey900;
        margin-right: 12px;
      }

      .theme {
        height: 20px;
        width: 20px;
        border-radius: 10px;
        margin-right: 12px;

        &:hover {
          outline: variables.$blue700 solid 1px;
          outline-offset: 2px;
        }
      }

      .light {
        background-color: variables.$grey50;
        border: 1px solid variables.$grey200;
      }

      .dark {
        background-color: variables.$grey800;
        border: 1px solid variables.$grey900;
      }

      .active {
        outline: variables.$blue500 solid 1px;
        outline-offset: 2px;
      }
    }

    .score-badge-preview {
      width: 100%;
      background-color: variables.$grey100;
      border-radius: 4px;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
    }

    .score-badge-code {
      width: 100%;
      background-color: variables.$grey50;
      border-radius: 4px;
      padding: 16px 12px;

      pre {
        margin: 0;
        color: variables.$grey500;
        white-space: pre-wrap;
      }
    }
  }
}

.full-page-modal.v2 .modal-content-container .share-shared-profile-modal {
  padding-top: 0;

  .score-badge-description {
    color: variables.$grey500;

    a {
      color: variables.$grey500;
      text-decoration: underline;
    }
  }
}

.full-page-modal.v2
  .modal-content-container
  .share-shared-profile-modal-footer {
  padding-bottom: 24px;

  .cr-icon-copy {
    font-size: 16px;
  }

  .cr-icon-message {
    font-size: 16px;
  }
}
