@import "../../_common/style/core/variables";

.migrate-survey-attachments-modal-container {
  width: 1000px;
}

.migrate-survey-attachments-modal {
  padding: 0 !important;

  .migrate-table-container {
    max-height: 60vh;
    overflow-y: scroll;
  }

  tr {
    height: unset !important;

    td {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .document-cell {
    .document-cell-inner {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;

      .file-type {
        width: 40px;
        margin-right: 8px;
      }

      .doc-name-meta {
        flex: 1;

        .doc-name {
          font-weight: $font-weight-bold;
          line-height: 150%;
        }

        .doc-meta {
          margin-top: 8px;
          font-size: 11px;
          line-height: 150%;

          span {
            color: $grey600;
          }
        }
      }
    }
  }

  .actions-cell {
    > * + * {
      margin-left: 8px;
    }
  }

  .name-cell {
    width: 40%;
  }
}

.migrate-survey-attachments-modal-footer {
  align-items: center;

  > * + * {
    margin-left: 16px;
  }

  .num-docs {
    margin-right: auto;
    font-size: $font-size;
    color: $grey600;
  }
}
