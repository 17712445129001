@import "../core/variables";

.flowing-external-link {
  color: $blue500;
}

.external-link {
  display: flex;
  width: fit-content;

  color: $blue500;

  div {
    margin-left: 6px;
    color: $blue500;
  }
}
