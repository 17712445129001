@import "../core/variables";

.onboarding-steps-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $white;
  display: flex;
  flex-direction: row;
  z-index: 1;

  .onboarding-steps-nav {
    background: $white;
    display: flex;
    flex-direction: column;
    width: 280px;
    height: auto;
    padding-left: 40px;
    padding-top: 48px;
    padding-right: 32px;
    border-right: 1px solid $grey100;

    .logo {
      margin-bottom: 80px;
      img {
        height: 40px;
      }
    }

    .steps {
      .onboarding-nav-entry {
        .onboarding-nav-entry-container {
          height: 48px;
          width: 200px;
          background: $grey25;
          border: 1px solid $grey100;
          box-sizing: border-box;
          border-radius: 8px;
          //margin-bottom: 24px;
          padding: 8px;
          display: flex;
          flex-direction: row;

          .nav-entry {
            align-self: center;
            font-weight: $font-weight-bold;
            font-size: 16px;
            line-height: 24px;
            color: $grey900;

            .nav-entry-icon {
              padding-right: 8px;
              font-weight: $font-weight-bold;
              font-size: 16px;
              line-height: 24px;
              color: $grey900;
            }
          }

          &.selected {
            background: $white;
            box-shadow: $box-shadow-md;
            .nav-entry-text,
            .nav-entry-icon {
              color: $blue500;
            }
          }

          &.clickable {
            cursor: pointer;
            &:hover {
              // only do hover if clickable
              background: $white;
              box-shadow: $box-shadow-md;

              .nav-entry-text,
              .nav-entry-icon {
                color: $blue500;
              }
            }
          }
        }

        .nav-number {
          display: flex;
          align-content: center;
          position: relative;
          left: 185px;
          bottom: 60px;
          font-size: 14px;
          font-weight: $font-weight-bolder;
          line-height: 150%;
          height: 28px;
          width: 28px;
          background: $grey50;
          border-radius: 16px;
          box-sizing: border-box;
          border: 1px solid $grey100;
          align-items: center;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $grey500;
          }

          &.selected {
            background: $blue500;
            span {
              color: $white;
            }
          }

          &.completed {
            background: $green500;
            span {
              color: $white;
            }
          }
        }
      }
    }
  }

  .onboarding-steps-nav + .onboarding-steps {
    padding-left: 62px;
  }

  .onboarding-steps {
    display: flex;
    padding-left: 140px;
    scroll-behavior: auto;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .onboarding-step {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 480px;
      padding: 20px 0;
      max-width: 480px;
      margin-top: 140px;

      .top-text {
        margin-bottom: 48px;
        display: flex;
        flex-direction: column;

        h3 {
          color: $grey900;
          font-size: 16px;
          margin-bottom: 36px;
          display: flex;
          align-items: center;

          span {
            font-size: 20px;
            margin-right: 8px;
          }
        }
      }

      .onboarding-step-extra {
        margin-bottom: 48px;
      }

      .onboaring-help-section {
        margin-bottom: 48px;

        a {
          color: $blue500;
          font-size: 16px;
          line-height: 125%;
          font-weight: $font-weight-bold;

          &::after {
            content: " →";
          }
        }

        p {
          color: $grey500;
          font-size: 16px;
          line-height: 150%;
        }
      }

      .onboarding-buttons {
        padding-bottom: 48px;

        .btn:not(:last-of-type) {
          margin-right: 12px;
        }
      }

      &.centred {
        align-items: center;
        max-width: 350px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0;

        h1,
        h3,
        p {
          text-align: center;
        }

        .btn {
          width: 100%;

          & + .btn {
            margin-top: 10px;
          }
        }
      }

      .onboarding-icon {
        background-color: $blue100;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        position: relative;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 22px;
          transform: translate(-50%, -50%);
        }
      }

      h1 {
        font-size: 24px;
        line-height: 150%;
      }

      * + h1 {
        margin-top: 16px;
      }

      p {
        color: $grey500;
        line-height: 150%;
        margin: 0;
      }

      * + p {
        margin-top: 16px;
      }

      form {
        width: 100%;
      }

      * + form {
        margin-top: 30px;
      }

      * + h3 {
        margin-top: 16px;
      }

      .checkmark-list {
        div {
          color: inherit;
        }

        & > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $grey500;

          & + div {
            margin-top: 10px;
          }
        }

        .check-container {
          background-color: $blue100;
          border-radius: 50%;
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          position: relative;
          margin-right: 16px;

          .cr-icon-check {
            color: $blue;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      * + .checkmark-list {
        margin-top: 16px;
      }

      .btn-group {
        display: flex;
        flex-direction: row;
        align-items: center;

        .btn + .btn {
          margin-left: 16px;
        }
      }

      * + .btn-group {
        margin-top: 32px;
      }

      .text-field,
      .color-checkbox {
        + * {
          margin-top: 12px;
        }
      }
    }

    .onboarding-quickstart-container {
      display: flex;
      flex-direction: row;
    }

    .onboarding-quickstart-video-container {
      @media (max-width: 1100px) {
        display: none;
      }

      img {
        border: 1px solid #ecedf3;
        box-sizing: border-box;
        border-radius: 12px;
        width: 100%;

        filter: drop-shadow(0px 10px 15px rgba(64, 70, 104, 0.1))
          drop-shadow(0px 4px 6px rgba(64, 70, 104, 0.05));
      }
    }

    .invite-users-to-org-video-container {
      margin-top: 115px;

      @media (min-width: 1200px) {
        position: absolute;
        right: 0;
      }
    }
  }
}

.set-first-last-name-step-org {
  .name-text {
    margin-bottom: 18px;
    //width: 360px;
  }
  form {
    label {
      color: $grey900;
      margin-bottom: 16px;
      font-weight: $font-weight-bold;
    }
    max-width: 320px;
  }
}

.onboarding-step-securityratings {
  .weights-display-content {
    #score-chart {
      margin-right: 40px;
    }
  }
}

.onboarding-step-breachsight {
  .top-text {
    span {
      margin-right: 8px;
    }
  }
}

.invite-users-to-org-step {
  .top-text {
    margin-bottom: 12px !important;
  }

  .add-more-btn {
    margin-bottom: 32px;

    .btn-inner {
      justify-content: flex-start;
    }
  }

  .action-button {
    margin-left: 110px;
    margin-bottom: 32px;
    width: 100%;
    text-align: center;
    color: $blue500;
    padding: 6px 0;
    background-color: #fff;
    border: 1.5px dashed #cccfe0;
    max-width: 350px;
    cursor: pointer;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 16px;
    height: fit-content;
    align-items: center;

    .ug-select {
      div {
        cursor: pointer !important;
      }
    }

    .input-label {
      color: $grey900;
      font-weight: $font-weight-bold;
      line-height: 22px;
      min-width: 94px;
      margin-right: 16px;
      cursor: default;
    }

    .text-field {
      flex: 1;
      max-width: 350px;
      height: 32px;
    }
  }

  form {
    max-width: 460px;
  }
}

.set-first-last-name-video-container {
  margin: 120px 20px 0 20px;
  position: relative;
  flex: 1 1 0;

  #rating-time {
    margin-left: 10%;
    width: 85%;
  }

  #webscanner-rating {
    position: absolute;
    top: 20%;
    left: 0px;
    width: 40%;
    filter: drop-shadow(20px 20px 32px rgba(64, 70, 104, 0.06));
  }
}

.securityratings-video-container {
  margin: 120px 20px 0 20px;
}

.breachsight-video-container {
  margin: 120px 20px 0 20px;
}

.vendorrisk-video-container {
  margin: 120px 20px 0 20px;
}
