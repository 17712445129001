.risk-status-counts {
  display: flex;
  flex-direction: column;

  .pill-label {
    margin-right: auto;
  }

  .pill-label + .pill-label {
    margin-top: 10px;
  }
}
