@import "../../../../_common/style/core/variables";

.submitted-modal-content {
  padding-top: 0 !important;
  width: 600px;

  > p {
    margin-bottom: 16px !important;
  }

  .expandable-item-header-link {
    display: flex;
    align-items: center;

    .icon-chevron {
      margin-left: auto !important;
    }
  }

  .promo {
    padding: 24px;
    border-radius: 8px;
    background-color: $blue50;

    .promo-header {
      font-size: 16px;
      line-height: 24px;
      font-weight: $font-weight-bold;
      display: flex;
    }

    .promo-blurb {
      margin-top: 8px;
      line-height: 22px;

      > div {
        color: $grey700;
        margin-bottom: 8px;

        i {
          margin: 0 6px;
          color: $blue500;
        }

        &.extra-gap {
          margin-top: 20px;
        }
      }

      .btn-filled-primary {
        margin-top: 16px;
      }
    }
  }

  .promo-footer {
    margin: 24px auto 0 auto;
    font-size: 12px;
    color: $grey600;
    line-height: 18px;

    a {
      font-weight: $font-weight-regular;
      color: $grey600;
      text-decoration: underline;

      &:hover {
        color: $grey400;
      }
    }
  }
}
