@import "../../../_common/style/core/variables";

.detected-products-mini-table {
  .title-header {
    padding-left: 0px !important;
    font-weight: $font-weight-regular;
    color: $primary-black;
    font-size: $font-size-extra-large;
    line-height: 133%;
    letter-spacing: normal;
  }

  .product-cell {
    padding-left: 0px !important;
  }

  .chevron-cell {
    text-align: right;
    width: 64px;

    i {
      text-align: right;
      font-size: 10px;
      color: $grey300;
    }
  }

  .vendor-cell {
    .vendor-name-wrapper {
      .vendor-hostname {
        color: $grey600;
      }

      .vendor-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $blue500;
      }
    }
  }
}
