@import "../core/variables";

.back-arrow {
  .back-arrow-icon {
    color: $medium-grey !important;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0;
    margin: 0;
    line-height: 25px;

    width: 25px;
    height: 25px;
    border: 1px $light-grey solid;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
    transition: border-color $animation-timing ease-in-out;
  }

  &:hover {
    .back-arrow-icon {
      border-color: $medium-grey;
    }

    .popup {
      opacity: 1;
    }
  }
}
