@use "../../../_common/style/core/variables";

.userbase-unmonitored-users-list {
  .users-filters {
    padding: variables.$space-lg variables.$space-xl 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: variables.$space-xl;

    .search-box-input-container {
      flex: 1;
    }
  }

  i.cr-icon-accepted {
    font-size: 18px;
  }

  i.cr-icon-minus-circle {
    font-size: 20px;
  }

  i.cr-icon-risk {
    font-size: 20px;
  }

  .dimmed {
    color: variables.$grey400;
  }
}
