@use "../../_common/style/core/variables";

.userbase-dashboard {
  .header {
    justify-content: space-between;
  }

  // Top dashboard stats
  .three-grid {
    padding-top: variables.$space-md;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;

    & > div + div {
      margin-top: 0;
    }

    & + div {
      margin-top: 20px;
    }

    &.no-stretch {
      align-items: flex-start;
    }
  }

  // Detailed stats at bottom of dashboard
  .two-grid {
    padding-top: variables.$space-md;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    & > div + div {
      margin-top: 0;
    }

    & + div {
      margin-top: 20px;
    }

    &.no-stretch {
      align-items: flex-start;
    }
  }
}
