@use "../../_common/style/core/variables";

.select-node-answers {
  display: flex;
  flex-direction: column;
  border-top: 1px solid variables.$grey100;
  border-bottom: 1px solid variables.$grey100;

  .no-select-answers {
    padding: 16px;
    color: variables.$grey300;
  }

  .select-node-answer {
    display: flex;
    flex-direction: row;
    padding: 24px 0;

    & + .select-node-answer {
      border-top: 1px solid variables.$grey100;
    }

    .letter {
      width: 20px;
      color: variables.$grey500;
    }

    textarea {
      flex: 1;
    }

    .actions {
      white-space: nowrap;
      margin-left: 10px;
    }
  }
}

.select-node-allow-notes {
  display: flex;
  border-top: 1px solid variables.$grey100;
  padding-top: 16px;
}
