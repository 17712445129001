@use "../../../_common/style/core/variables";

.saas-users-container {
  .saas-user-link {
    display: inline-block;
    color: variables.$blue;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }

  table th {
    padding-top: 0px;
  }

  table th:first-of-type,
  table td:first-of-type {
    padding-left: 0px;
  }
}
