@use "../../_common/style/core/variables";

.content-library-list {
  .content-library-list-main {
    display: flex;
    flex-direction: column;

    .empty-state-circle {
      align-self: center;
      margin: 60px 0;
    }

    .search-box-input-container {
      margin: 20px 32px 0 32px;
    }

    .info-card {
      margin: 16px 32px 16px 32px;
    }
  }

  .name-cell {
    .name-cell-inner {
      display: flex;
      flex-direction: row;
      align-items: center;

      .file-type {
        width: 32px;
        margin-right: 15px;
      }
    }
  }

  .doc-type-cell,
  .updated-cell {
    width: 20%;
  }

  .doc-type-cell {
    white-space: nowrap;
  }

  .updated-cell {
    &,
    div {
      color: variables.$grey500;
    }
  }

  .i-shared-profile {
    color: variables.$grey500;
    background-color: variables.$grey50;
    border-radius: variables.$component-border-radius;
    font-size: 20px;
  }
}
