@import "../../../_common/style/core/variables";

.set-notification-conditions {
  .any-all-option {
    .popup-target {
      span {
        cursor: pointer;
        color: $blue;
        text-decoration: underline;
        text-decoration-style: dotted;
        text-underline-offset: 3px;
      }

      &:hover span {
        color: $blue700;
      }
    }

    .popup-content {
      .disabled {
        opacity: 0.5;

        &:hover {
          background-color: unset;
          color: unset;
        }
      }
    }
  }

  .conditions-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-column-gap: 8px;
    grid-row-gap: 16px;
    align-items: center;
    margin: 16px 0;

    .matches.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .tier-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    .vendor-tier-badge-container,
    .vendor-tier-badge-display,
    .vendor-tier-badge {
      min-width: 16px;
      min-height: 16px;
    }

    .vendor-tier-badge-container {
      margin-right: 8px;
    }
  }

  .between-condition {
    display: flex;
    align-items: center;

    input[type="text"] {
      margin: 0;

      &:first-of-type {
        margin-right: 5px;
      }

      &:last-of-type {
        margin-left: 5px;
      }
    }
  }

  .attribute-condition {
    .label {
      max-width: 200px;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  .ug-select {
    max-width: 300px;
  }
}
