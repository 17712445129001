@use "../core/variables";

.check-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;

  > .ambivalent {
    color: variables.$blue500;
    font-size: 18px;
  }

  &.filled {
    background-color: variables.$blue50;
    border: 1px solid variables.$blue500;

    &.checked {
      background-color: variables.$blue500;
      > .cr-icon-check {
        color: white;
        font-size: 12px;
      }
    }
  }

  &.checked {
    > .cr-icon-check {
      color: variables.$grey500;
      font-size: 12px;
    }
  }

  &.disabled {
    background-color: white;
    border: 1px solid variables.$grey200;
  }
}
