@import "../../../_common/style/core/variables";

.userbase-user-app-permissions-panel {
  .title-and-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 $space-xxl $space-lg;

    .saas-user-title {
      margin-right: auto;
      font-size: $font-size-extra-large;
      font-weight: $font-weight-bold;
    }

    .saas-user-title-buttons {
      margin-left: auto;
    }
  }

  // As this is an expanded list we make this grey and indent everything
  .permission-list {
    th:first-of-type,
    td:first-of-type {
      padding-left: 20px;
    }

    .permission-row {
      .risk-level {
        width: 30%;
      }

      .description-with-subtext {
        line-height: 1.3;

        .description-main-text {
          font-size: $font-size;
        }

        .description-subtext {
          font-size: $font-size-smaller;
          color: $grey600;
        }
      }
    }
  }
}
