@import "../../../../_common/style/core/variables";

.portfolio-dropdown {
  .popup-content {
    z-index: 20;

    .no-portfolios {
      width: 250px;
      padding: 12px 16px;
      line-height: 150%;
      color: $grey500;

      .learn-more-link {
        display: block;
        margin-top: 16px;
      }
    }

    hr {
      background-color: $grey200;
      height: 1px;
    }

    .dropdown-item {
      position: relative;

      &.spaced {
        justify-content: space-between;
      }

      &.selected {
        color: inherit;
        font-weight: $font-weight-bold;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 4px;
          background-color: $blue;
        }
      }

      &:hover {
        [class^="cr-icon-"],
        [class*=" cr-icon-"] {
          color: $blue;
        }
      }

      .pill-label {
        margin-left: 16px;
      }

      .icon-wrap {
        width: 20px;
        text-align: center;
        margin-right: 10px;
      }

      .cr-icon-cog2 {
        font-size: 18px;
      }
    }
  }
}
