@import "../core/variables";

.loading-overlay-container {
  position: relative;
  display: inline-block;

  .loading-overlay-inner {
    opacity: 0;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity $animation-timing;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
