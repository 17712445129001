@import "../../../_common/style/core/variables";

.vendor-shared-assets-request-modal {
  .desc-section {
    p,
    ul {
      color: $grey500;
      margin-bottom: 0;
    }

    * + p,
    * + ul {
      margin-top: 20px !important;
    }

    ul {
      margin-top: 0;
      li {
        line-height: 150%;
      }
    }

    a {
      text-transform: none !important;
    }
  }
}
