@use "../styles/variables";

.feed-content.investigating-feed {
  .x-table.threat-monitoring-table table tr {
    min-height: 56px;
    height: 76px;
  }

  th.selection-box {
    width: calc(
      36px + variables.$tm-feedtable-inner-padding +
        variables.$tm-feedtable-outer-padding
    );
  }
  th.severity {
    width: calc(28px + variables.$tm-feedtable-inner-padding);
  }
  th.date {
    width: calc(140px + variables.$tm-feedtable-inner-padding);
  }
}
