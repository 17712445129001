@use "../core/variables";

.shared-profile-survey-details {
  .survey-details-risks-card {
    .tabs-and-search {
      .search-box-input-container {
        flex: 1;
      }
    }
  }

  .include-survey-toggle {
    display: flex;
    flex-direction: row;
    padding-right: 15px;
    align-items: center;

    .toggle-text {
      color: #2c3238;
      font-size: 14px;
      line-height: 150%;
      padding-right: 10px;
    }
  }

  .overview-table {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .survey-score {
    .questionnaire-score-container {
      display: flex;

      .score-text {
        min-width: 0px;
      }

      .score-out-of {
        font-size: 12px;
        vertical-align: top;
      }

      .dismissable-popup-click-capture {
        display: inline-flex;
      }
    }
  }

  .taller-row {
    .row-value {
      max-height: unset;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .survey-progress {
    display: flex;
    flex-direction: column;

    .progress-bar {
      margin-bottom: 10px;
    }
  }

  .unanswered-question-link {
    color: variables.$blue500;

    &:hover {
      cursor: pointer;
    }
  }

  .info-table td {
    &.row-label {
      min-width: 250px;
    }

    &.row-value {
      font-weight: 400;
    }
  }
}
