@use "../../../_common/style/core/variables";
@use "../../../_common/style/core/typography";

.userbase-about-user-directory {
  .directory-table {
    width: 100%;
    font-family: typography.$font-family;
    font-size: variables.$font-size;

    border-collapse: separate;
    border-spacing: 0;

    td {
      padding: 12px 0px;
    }

    tr:not(:last-child) td {
      /* Add bottom border to all rows except the last */
      border-bottom: 1px solid variables.$lighter-grey;
    }

    .number {
      font-weight: variables.$font-weight-bold;
      color: variables.$grey700;
      text-align: right;
    }
    .label {
      color: variables.$grey700;
    }
  }
}
