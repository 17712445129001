.waiver-details-step {
  .severity-selector-container {
    display: flex;
    align-items: center;

    .severity-selector {
      width: auto;
      flex-grow: 1;
    }
  }
}
