@use "../../../_common/style/core/variables";

.userbase-identity-breach-about {
  .breach-paste {
    .row-value-cell .row-value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
}
