@import "../../_common/style/core/variables";

.remediate-threat-modal-header {
  border-bottom: 1px solid $light-grey;
  background-color: $grey50;
  width: 800px;
  padding-top: 24px !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.remediate-threat-modal-footer {
  width: 800px;
}

.remediate-threat-modal {
  width: 800px;

  .report-card-sections {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .report-card-section {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      &:not(:last-child) {
        border-bottom: 1px solid $grey100;
      }

      .section-label {
        width: 299px;
        font-weight: 500;

        .required:after {
          content: "*";
          color: $red500;
        }

        .subtitle {
          padding-top: 4px;
          font-weight: 400;
          color: $grey400;
        }
      }
      .section-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        padding-bottom: 24px;

        .input {
          width: 100%;
        }

        .subtitle {
          font-weight: 400;
          color: $grey400;
          padding-left: 30px;
        }
      }

      .risk-section {
        margin-bottom: 24px;
        width: 100%;

        .risk-summary {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border-radius: 8px;
          border: 1px solid $grey100;

          .risk-header {
            display: flex;
            padding: 16px;
            gap: 8px;

            .risk-title {
              font-weight: $font-weight-bold;
            }
            .severity-icon img {
              height: 24px;
            }
            .risk-details {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              line-height: 150%;
              margin-top: 4px;
              .risk-description {
                color: $grey500;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }

  .input-separated {
    margin-top: 20px;
  }
}
