@import "../core/variables";

.feature-intro-modal-container {
  overflow: hidden;
  border: none !important;
}

.feature-intro-modal {
  width: 600px;
  padding: 0 !important;

  .feature-intro-description {
    padding: 32px;

    h3 {
      font-size: 24px;
      line-height: 150%;

      + * {
        margin-top: 16px !important;
      }
    }

    p {
      margin: 0;
      font-size: 16px !important;
      line-height: 150% !important;

      + p {
        margin-top: 16px !important;
      }
    }
  }
}

.feature-intro-modal-header {
  padding: 0 !important;

  .feature-header-img {
    width: 100%;
  }
}

.feature-intro-modal-footer {
  .step-dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: auto;

    .step-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $grey200;

      &.active,
      &:hover {
        cursor: pointer;
        background-color: $grey600;
      }

      & + .step-dot {
        margin-left: 16px;
      }
    }
  }
}
