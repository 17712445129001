@use "../../../../_common/style/core/variables";

.breach-history-panel-container {
  > .slide-panel {
    width: unset !important;

    > .container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

.breach-history-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  min-height: 1px;

  .activity-feed {
    flex-direction: column;
    overflow-y: auto;
    flex: 1 1 auto;
    height: 0px; // Hack to get the scrolling working on a flex item

    padding: 24px 32px;

    // Hack in a pseudo element to force the top element to keep its top margin, which I just couldn't get to work any other way
    &:after {
      content: "⠀"; // This is not a space, but a blank character (space didn't work)
      display: block;
      visibility: hidden;
      height: 0;
    }

    .activity-feed-pre {
      > div {
        color: variables.$grey500;
        line-height: 16px;

        + div {
          margin-top: 4px;
        }
      }
    }
  }

  .message-input-section {
    background-color: variables.$blue25;
    padding: 24px 32px;
    border-top: 1px solid variables.$grey100;

    .text-field {
      textarea {
        max-height: 400px !important; // Override the TextareaAutosize max-height sizing
      }
    }

    .buttons-left-content {
      flex: 1;
      padding-right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .user-avatar {
        max-height: unset;

        .avatar {
          width: 28px;
          height: 28px;
          margin: 0;
        }

        + .user-avatar {
          margin-left: 4px;
        }
      }

      .notify-users-text {
        margin-left: auto;
        color: variables.$grey500;
      }
    }
  }
}
