@use "../core/variables";

.hide {
  display: none;
}

.company-logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  .circle-logo {
    border-radius: 50%;
    width: 35px;
    min-width: 35px;
    height: 35px;
    background-color: variables.$white;
    background-size: contain;
    background-position: center;
    display: block;
    background-repeat: no-repeat;

    &.medium {
      width: 32px;
      min-width: 32px;
      height: 32px;

      &.company-name {
        line-height: 32px;
        font-size: 10px;
      }
    }

    &.small {
      height: 24px;
      width: 24px;
      min-width: 24px;

      &.company-name {
        line-height: 24px;
        font-size: 8px;
      }
    }
  }

  .company-name {
    font-size: 0.7rem;
    color: variables.$medium-grey;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .company-name-block {
    padding-left: variables.$space-sm;
    display: flex;
    flex-direction: column;

    .company-label {
      &.link {
        color: variables.$blue600;
      }
    }

    .company-category {
      padding-top: 4px;
      color: variables.$grey600;
    }

    .company-domain {
      margin-top: 4px;
      color: variables.$grey600;
      font-weight: variables.$font-weight-regular;
    }
  }

  .text-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .hover-shadow {
    &:hover {
      box-shadow: 0 0 5px 3px variables.$light-grey !important;
    }
  }

  .coloured-bg {
    background-color: variables.$blue;
    color: variables.$white;
    height: 35px;
  }

  .grey-bg {
    background-color: variables.$light-grey;
    color: variables.$white;
    height: 35px;
  }

  .base {
    width: 35px;
    height: 35px;
  }
}
