$font-path: "./fonts" !default;
$icon-font-path: $font-path !default;
$animation-timing: 250ms;
$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
$standard-transition: 200ms ease-in-out;

// Spacing
$space-xxxl: 48px;
$space-xxl: 40px;
$space-xl: 32px;
$space-lg: 24px;
$space-md: 16px;
$space-sm: 8px;
$space-xs: 4px;

// Colors

$blue900: #081f44;
$blue800: #0c2f69;
$blue700: #113f8d;
$blue600: #144ca9;
$blue500: #1757c2;
$blue400: #5d89d4;
$blue300: #8babe1;
$blue200: #cad9f1;
$blue100: #e8eef9;
$blue50: #f3f7fc;
$blue25: #f9fbfd;

$mid-blue: #1757c2;

$grey900: #131520;
$grey800: #30354f;
$grey700: #404668;
$grey600: #4a5078;
$grey500: #656ea7;
$grey400: #7a81ae;
$grey300: #a6abc9;
$grey200: #cccfe0;
$grey100: #ecedf3;
$grey50: #f9f9fb;
$grey25: #fcfcfd;

$red700: #ce1d1d;
$red500: #e02424;
$red400: #f15f5f;
$red300: #f7abab;
$red200: #fbd0d0;
$red100: #fde8e8;
$red50: #fdf2f2;
$red25: #fefbfb;
$red10: #fcebeb;

$orange600: #d03801;
$orange500: #ff6b01;
$orange400: #ff9901;
$orange100: #f28f1c;
$orange70: #f6b160;
$orange50: #f8c78d;
$orange30: #fbddbb;
$orange20: #fff2de;
$orange10: #fef4e8;
$orange5: #fef9f4;

$green600: #188145;
$green500: #20af5d;
$green400: #63c78e;
$green300: #8fd7ae;
$green200: #bce7ce;
$green100: #e9f7ef;
$green50: #f4fbf7;
$green25: #fbfefc;

$purple500: #895593;

$orange-light500: #fdc106;
$orange-light50: #fffcf3;
$orange-light25: #fffefa;

$orange-mid500: #ff9901;
$orange-mid50: #fffbf5;
$orange-mid25: #fffdfa;

$orange-dark500: #ff6b01;
$orange-dark50: #fffaf2;
$orange-dark25: #fffdfa;

$yellow100: #ffd500;
$yellow70: #ffe14d;
$yellow50: #ffea80;
$yellow30: #fff2b3;
$yellow10: #fffbe6;
$yellow5: #fffdf2;

$green500: #20af5d;

$green-light500: #90ca2f;
$green-light50: #f9fcf5;
$green-light25: #fdfefb;

$teal500: #47b7ff;
$teal400: #93caed;
$teal50: #e9f0f5;

// Frequently used shorthand for other colors
$primary-black: #2c3238;
$blue: $blue500;
$green: $green500;
$orange: $orange-mid500;
$red: #ef1700;
$white: #ffffff;
$yellow: $orange-light500;

// Shades
$black: #191c28;
$light-blue: #e8eef9;
$lighter-blue: #f3f7fc;
$light-green: #90ca2f;
$lighter-grey: #f6f6f6;
$light-grey: #eaeaeb;
$medium-grey: #95989b;
$dark-grey: #595c62;
$border-grey: $medium-grey;
$border-color: $border-grey;
$canvas: #fafafa;
$header-blue: $black;
$hover-bg: $canvas;
$dark-yellow: #e7c403;
$opaque-white: rgba($white, 0.5);

// CSTAR Colors
$cstar-unknown: $medium-grey;
$cstar-low: $red500;
$cstar-average: $orange-dark500;
$cstar-fair: $orange-light500;
$cstar-good: $green-light500;
$cstar-excellent: $green500;

// Label colors
// Important: If you ever add, remove or modify this list of colors, make sure you also
// update the styles for Vendor Report PDF generation, in pdf/templates/styles.css.

$label-colors: (
  ("lightgrey", $light-grey),
  ("mediumgrey", $medium-grey),
  ("grey", $dark-grey),
  ("blue", $blue),
  ("lightblue", #47b7ff),
  ("green", #20af5d),
  ("red", #ef1700),
  ("yellow", #d68100),
  ("orange", $orange),
  ("gimblet", #b26b00),
  ("persiangreen", #14703c),
  ("violet", #5f3285),
  ("fuchsia", #a02c80),
  ("darkgrey", #6b6f74),
  ("trendypink", #874aba),
  ("pictonblue", #0d316d),
  ("mountainmeadow", #14666c),
  ("bahia", #1c8e96),
  ("black", $primary-black),
  ("grey500", $grey500)
);

// Button colors
$primary-cta-disabled: rgba(17, 154, 228, 0.5);
$primary-cta-hover: #0378be;
$primary-cta: $blue;
$secondary-cta-border: $primary-black;
$secondary-cta-color: $primary-black;
$secondary-cta-hover: rgba(255, 255, 255, 0.1);
$secondary-cta: transparent;

// Font
$font-color: $primary-black;

$font-size-extra-small: 10px;
$font-size-smaller: 12px;
$font-size: 14px;
$font-size-larger: 16px;
$font-size-extra-large: 18px;
$font-size-xx-large: 24px;

$font-weight-bolder: 600; // Inter-mediumer
$font-weight-bold: 500; // Inter-medium
$font-weight-regular: 400; // Inter-regular

$label-color: #6b6f74;

$line-height-xsmall: 16px;
$line-height-small: 18px;
$line-height-smaller: 16px;
$line-height: 22px;
$line-height-large: 24px;
$line-height-larger: 28px;

// Box shadows
/* grey / 2dp */
$box-shadow-2dp: 0px 2px 10px rgba(44, 50, 56, 0.07);
/* grey / 4dp */
$box-shadow-4dp: 0px 4px 14px rgba(44, 50, 56, 0.1);
/* grey / 8dp */
$box-shadow-8dp: 0px 8px 16px rgba(44, 50, 56, 0.13);
/* grey / 16dp */
$box-shadow-16dp: 0px 16px 32px rgba(44, 50, 56, 0.16);
/* grey / 24dp */
$box-shadow-24dp: 0px 24px 48px rgba(44, 50, 56, 0.19);
/* Shadow/md */
$box-shadow-md:
  0px 2px 4px rgba(64, 70, 104, 0.06),
  0px 4px 6px rgba(64, 70, 104, 0.1);

$shadow-level-0:
  0px 1px 3px 0px rgba(64, 70, 104, 0.1),
  0px 1px 2px 0px rgba(64, 70, 104, 0.06);
$shadow-level-1:
  0px 4px 6px 0px rgba(64, 70, 104, 0.1),
  0px 2px 4px 0px rgba(64, 70, 104, 0.06);
$shadow-level-2:
  0px 10px 10px 0px rgba(64, 70, 104, 0.04),
  0px 20px 20px 0px rgba(64, 70, 104, 0.1);
$shadow-level-3: 0px 24px 48px 0px rgba(64, 70, 104, 0.25);

// Border Radius
$card-border-radius: 8px;
$component-border-radius: 4px;

// OLD STUFF
$border-radius: 2px;
$boundary-min-width: 900px;
$boundary-padding: 30px;
$card-padding: 20px;
$action-col-width: 50px;
$checkbox-col-width: 36px;
$dropdown-width: 200px;
$form-width: 500px;
$global-padding: 10px;
$gravatar-dimensions: 26px;
$header-min-height: 50px;
$input-padding: 8px;
$list-item-height-small: 30px;
$list-item-height: 40px;
$medallion-dimensions: 27px;
$min-button-width: 107px;
$dropdown-margin-top: 24px;
$dropdown-line-height: 26px;

$profile-image-height: 26px;
$quadrant-min-height: 380px;
$sub-header-min-height: 30px;
$page-search-height: 43px;

$small-color-grade-size: 24px;
$medium-color-grade-size: 48px;

// Modal
$modal-width: 660px;

// Sparkline dimensions
$simple-sparkline-height: 30px;
$simple-sparkline-width: 200px;

// Other
$animation-timing: 250ms;
$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
$nav-width: 260px; // do not change this without changing the corresponding value in VendorListCard.tsx
$nav-width-closed: 20px;
$changes-blue: #008ee3;
$changes-box-shadow-width: 7px;

// Multi-product Navigation
$left-hand-navigation-width: 181px;
$left-hand-navigation-width-closed: 56px;
$product-specific-navigation-width: 204px;
$product-specific-navigation-width-closed: 56px;
$user-action-bar-height: 56px;

$survey-editor-max-width: 800px;

// Do not change this value without updating the width constants in ActionBar.tsx
// and VendorListCard.tsx
$max-page-width: 1600px;

// colors used for risk matrix
$risk-colors: (
  "0": #ce1d1d,
  "1": #ff6b01,
  "2": #fdc106,
  "3": #90ca2f,
  "4": #20af5d,
);

@mixin risk-matrix-color() {
  @each $level, $color in $risk-colors {
    @for $tier from 1 through 5 {
      &level-#{$level}-tier-#{$tier} {
        background-color: rgb($color, calc(1 - ($tier - 1)));
      }
    }
  }
}

$side-navigation-z-index: 15;

$form-input-max-width: 38.5em;
