@import "../../../_common/style/core/variables";

.add-typosquat-modal {
  width: 1000px;
  max-width: 1000px;
  min-width: 400px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 14px rgba(44, 50, 56, 0.1);

  .x-table {
    margin: 10px 0;
    width: 100% !important;
  }

  .center-text {
    text-align: center;
  }

  .spacer {
    height: 5px;
    min-height: 5px;
  }

  .url-cell {
    vertical-align: middle !important;
    width: 100% !important;
    padding: 10px;
    padding-left: 30px !important;
    max-width: 300px;

    .url {
      padding: 0px !important;
      word-break: break-word;
    }
  }

  .score-cell {
    min-width: 90px !important;
    padding: 0px !important;
    vertical-align: middle !important;

    .score {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      padding: 0px !important;
      .color-grade {
        margin-right: 10px;
      }
    }
  }

  .labels-cell {
    vertical-align: middle !important;
    width: 300px !important;
    min-width: 200px;
    padding: 0px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .button-cell {
    vertical-align: middle !important;
    width: 190px !important;
    max-width: 190px !important;
    min-width: 190px !important;
    padding: 0px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    text-align: right;
  }
}
