@use "../core/variables";

.update-due-date-modal {
  width: 720px;

  .color-checkbox {
    margin-bottom: 10px;
  }

  .flatpickr-input {
    margin-left: 30px;
    margin-right: 48px;
    width: 80%;
  }
}
