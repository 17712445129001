@use "../core/variables";

.surface {
  position: absolute;
  z-index: 11;

  max-height: 600px;
  overflow-y: auto;

  border: 1px solid variables.$grey100;
  border-radius: 8px;
  background-color: white;
  box-shadow:
    0px 10px 15px rgba(64, 70, 104, 0.1),
    0px 4px 6px rgba(64, 70, 104, 0.05);

  &.top {
    bottom: 40px;
  }
  &.right {
    left: 40px;
  }
  &.bottom {
    top: 40px;
  }
  &.left {
    right: 40px;
  }

  /* Default styling */
  .surface-title {
    margin: 24px;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 1px;
    color: variables.$grey500;
    text-transform: uppercase;
    font-weight: variables.$font-weight-bold;
  }

  .surface-content {
    margin: 24px 24px 0 24px;
  }

  .surface-btn-group {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px 24px 24px;

    button + button {
      margin-left: 16px;
    }
  }
}
