@import "../../../_common/style/core/variables";

.expandable-item {
  .expandable-item-header {
    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: black;

      &:hover {
        color: $grey700;
        text-decoration: none;
      }

      .icon-chevron {
        margin-left: 6px;
        transition: transform 150ms ease-out;
      }
    }
  }
}
