@use "../../../_common/style/core/variables";

.user-display-v2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  gap: variables.$space-md;

  .user-avatar {
    flex: 0 0 auto;
  }

  .name-fields {
    flex: 0 1 auto;
    width: 160px;
    // allow the flex child to shrink below its defined width
    min-width: 0;
  }

  .email-fields {
    flex: 1 1 auto;
    width: 200px;
    // allow the flex child to shrink below its defined width
    // especially important here to ellipsize and show popup
    min-width: 0;
  }

  .user-name {
    color: variables.$grey900;
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-bold;
    line-height: 22px;
  }

  .light {
    color: variables.$grey600;
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-regular;
    line-height: 22px;
  }

  .btn-icon-popup {
    margin-left: auto;
  }

  .btn-icon {
    margin-top: 0;
  }
}
