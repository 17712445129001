@import "../core/variables";

footer {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  pointer-events: none;

  &.navbar-offset {
    margin-left: $nav-width;
  }

  #footer-inner {
    margin: $global-padding 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > span,
    > a {
      text-transform: uppercase;
      color: $medium-grey;
      font-size: 0.7rem;
      pointer-events: all;
    }

    > a:hover {
      color: $font-color;
      text-decoration: none;
    }

    > span:not(:last-child),
    > a:not(:last-child) {
      margin-right: $global-padding;
    }
  }
}
