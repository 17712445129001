@import "../../../_common/style/core/variables";

.vendor-overlay {
  background-image: -webkit-gradient(
    linear,
    0% 0%,
    0% 70%,
    from(rgba(0, 0, 0, 0)),
    to(rgba(247, 247, 247, 1))
  );
  position: fixed;
  top: 194px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.vendor-report-confirmation-modal {
  width: 450px;
}
