@import "../core/variables";

.free-trial-meeting-modal-header {
  padding-top: 4px !important;
}

.free-trial-meeting-modal {
  width: 800px;
  max-height: 775px;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;

  h4 {
    font-weight: $font-weight-bold;
    font-size: 18px;
    line-height: 28px;
  }

  .description {
    margin-top: 24px;
    font-size: 14px;
    color: $grey600;
    padding-left: 0px;
    padding-right: 0px;
  }

  .calendar-container {
    position: relative;
  }
}

.free-trial-meeting-modal-shrunk {
  height: 275px !important;
}
