@import "../../../../_common/style/core/variables";

.vendor-assessment-v3-risk-card {
  .empty-card-with-action.empty-risks-card {
    .empty-text {
      margin-top: 0;
    }
  }

  .empty-card-with-action.empty-key-risks-card {
    .empty-subtext {
      max-width: 320px;
    }
  }
}
