@import "../../_common/style/core/variables";

.threat-monitoring-failed-feed,
.threat-monitoring-empty-feed {
  background-color: $grey50;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px !important;
  border-radius: 8px;
  margin-bottom: 32px;
  .btn {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 24px;
    .cr-icon-graph {
      font-size: 20px;
    }
  }
  img {
    margin-bottom: 16px;
  }
  .primary {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
  .sub {
    color: $grey900;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  .subsub {
    color: $grey500;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }
  .contact-support {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
    color: $grey500;

    .text {
      color: inherit;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .cr-icon-arrow-right {
      font-size: 1.3em;
      color: inherit;
    }
  }
}
