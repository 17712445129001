.textarea-wrapper {
  position: relative;

  .textarea-with-pills {
    display: flex;
    border: 1px solid #cccfe0;
    height: 210px;
    padding: 5px;
    width: 600px;
    max-width: 1000px;
    flex-wrap: wrap;
    align-content: flex-start;
    border-radius: 4px;
    overflow-y: scroll;

    .token {
      align-self: flex-start;
      margin-bottom: 10px;
      margin-right: 10px;
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      align-self: flex-start;

      &:focus {
        outline: none;
        border: none;
        box-shadow: unset;
      }
    }

    .pill-label.removeable {
      margin-top: 1px;
    }
  }
}
