@use "../../core/variables";

#main-content-area .ug-page-header-outer {
  margin-top: -30px;
}

.ug-page-header {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-section {
    width: 100%;
    background-color: variables.$white;
    border-bottom: 1px solid variables.$grey100;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header-section-inner {
      width: 100%;
      max-width: variables.$max-page-width + 80px;
      padding: 16px 40px;
      display: flex;
      flex-direction: column;
    }
  }

  .header-icon-item {
    & > div,
    .dismissable-popup-click-capture > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: -4px;
      padding: 4px;
      border-radius: 4px;

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: variables.$grey50;
        }
      }
    }

    .header-icon {
      width: 24px;
      height: 24px;
      background-color: variables.$blue100;
      border-radius: 4px;
      position: relative;
      margin-right: 10px;

      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: variables.$blue500;
        font-size: 16px;
      }
    }

    .cr-icon-shared-assessment:before {
      font-size: 20px;
      top: calc(50%);
      left: calc(50% + 1px);
    }

    .header-label {
      color: variables.$grey600;
      font-size: variables.$font-size;
      line-height: 150%;
      font-weight: variables.$font-weight-bold;
      white-space: nowrap;
    }
  }
}
