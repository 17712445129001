@import "../../_common/style/core/variables";
@import "../styles/variables";

.threat-monitoring-view {
  .page-title .cr-icon-settings {
    font-size: 22px;
  }
  .report-card {
    background-color: $white !important;
    box-shadow: none !important;

    display: flex;
    flex-direction: column;
    border: 1px solid $grey100;
    border-radius: $threat-monitoring-view-report-card-border-radius;

    .feed-container {
      flex-grow: 1;

      .feed-content {
        display: flex;
        flex-direction: row;
      }

      .content-separator {
        border-bottom: 1px solid $grey100;
        margin: 16px 0;
      }
    }
  }
}
