@import "../../_common/style/core/variables";

.question-answer-node {
  max-width: 900px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .question-container,
  .attachment-groups-container {
    display: flex;
    border-bottom: 1px solid $grey100;
    padding: 48px 20px;
    transition: all 100ms ease-out;

    .heading-and-content {
      flex-grow: 1;
    }

    .top-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .node-name {
        margin-bottom: 0;
      }
    }

    .lower-row {
      display: flex;
      flex-direction: row;
      margin-left: 110px;
    }

    .survey-viewer-icon {
      background-color: $blue50;
      mix-blend-mode: multiply;
      align-self: flex-start;
      margin-right: 22px;
      width: min-content;

      .icon {
        i {
          color: $blue500;
        }
      }

      .display-id {
        color: $blue500;
      }

      &.has-suggestion {
        .icon {
          i {
            color: $orange500;
          }
        }

        .display-id {
          color: $orange500;
        }
      }
    }

    .node-name {
      font-weight: $font-weight-bold;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 8px;

      // If node name has HTML inside it, provide some default styling
      p {
        color: $grey600;
      }

      a {
        text-decoration: underline;
        text-transform: none;
      }

      p:first-child {
        margin-top: 0px;
      }

      &.section-node-name {
        font-weight: $font-weight-bold;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }

    .desc {
      color: $grey600;
      font-size: 14px;
      line-height: 21px;

      // If desc has HTML inside it, provide some default styling
      p {
        color: $grey600;
      }

      a {
        text-decoration: underline;
        text-transform: none;
      }

      p:first-child {
        margin-top: 0px;
      }

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    .side-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;

      .survey-viewer-icon-button-container {
        margin-left: 0;
      }
    }
  }

  &.manual-risk-warning {
    .question-container {
      padding-top: 24px;
    }

    .info-banner {
      &.manual-risk-banner {
        margin-top: 48px !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .diff-hidden {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    span {
      font-style: italic;
      font-weight: $font-weight-bold;
      font-size: 14px;
      line-height: 150%;
      color: $grey400;
      text-align: center;
      flex-grow: 1;
    }
  }

  .question-answer-node-content {
    padding-top: 5px;
    flex-grow: 1;

    .answer-section {
      display: flex;
      flex-direction: row;

      .info-banner {
        margin-left: 0 !important;
      }
    }

    .text-field-with-action,
    .answers {
      flex-grow: 2;
    }

    .text-field,
    .text-field-performant {
      margin-top: 18px;
    }

    textarea {
      height: 150px;
    }

    input:disabled,
    textarea:disabled {
      color: $grey600;
      cursor: text;
    }

    .not-opted-in {
      .btn-inner {
        div {
          color: $grey300;
        }
        span {
          color: $grey300;
        }
      }
    }
  }

  &.target-node,
  &.is-right-panel-target-question {
    .question-container {
      background-color: $blue50;
    }
  }

  &.risk-node {
    .risk-response {
      margin-top: 12px;
    }
  }

  &.select-node {
    .answers {
      margin-top: 16px;
    }

    .notes {
      margin-top: 16px;
      margin-left: 42px;
    }
  }

  &.upload-node {
    .file-answer {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .dragdropupload {
      margin-top: 16px;
    }
  }

  &:last-of-type {
    .question-container {
      border-bottom: none; // TODO fix this (last-of-type doesn't work)
    }
  }

  .no-answer {
    color: $grey600;
  }

  .filtered {
    padding: 44px 16px !important;
    margin: 4px 4px !important;
    background-color: $blue50 !important;
  }

  &.diff {
    max-width: unset;
    padding: 0 80px 0 52px;

    .question-container {
      display: flex;
      flex-direction: row;
      padding: unset;

      .heading-div {
        width: 33%;
        min-width: 33%;
        display: flex;
        flex-direction: row;
        padding: 48px 36px 48px 0;
        border-right: 1px solid $grey100;

        .node-heading {
          .node-name {
            margin-bottom: 0;
          }

          .desc {
            margin-bottom: 0;
            margin-top: 0;
          }
        }

        &.answers-hidden {
          width: 100%;
          border-right: unset;

          .left {
            display: flex;
            flex-direction: row;
          }
        }

        .survey-viewer-icon {
          margin-bottom: 16px;
        }

        .right {
          margin-left: auto;
        }
      }

      .old-answer {
        border-right: 1px solid $grey100;
        width: 33%;
        min-width: 33%;
      }

      .new-answer {
        width: 33%;
        min-width: 33%;
      }

      .question-answer-node-content {
        padding: 48px 32px;

        .answers {
          margin-top: unset;
        }
      }
    }
  }
}
