@import "../core/variables";

$triangle-size: 16px;
$animate-distance: 10px;

.dismissible-popup-container {
  &.inline {
    display: inline-block;
  }
}

.dismissable-popup-portal {
  position: fixed;
  pointer-events: none;
  z-index: 999997; // One less than the modal z-index

  &.in-modal {
    z-index: 999999; // One more than the modal z-index
  }
}

.dismissable-popup {
  position: absolute;
  pointer-events: all;
  background-color: $grey900;
  border-radius: $card-border-radius;
  display: flex;
  flex-direction: row;
  padding: 12px;

  filter: drop-shadow(0px 10px 15px rgba(64, 70, 104, 0.1))
    drop-shadow(0px 4px 6px rgba(64, 70, 104, 0.05));

  &,
  * {
    color: $white;
  }

  &.no-wrap {
    .title,
    .main-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .main-area {
    font-weight: $font-weight-regular;
    line-height: 150%;
    width: 100%;

    .title {
      font-weight: $font-weight-bolder;
      line-height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .pill-label {
        margin-left: 10px;
      }
    }

    strong {
      font-weight: $font-weight-bolder;
    }

    /* Default styling for links with icons in the dismissible popup */
    a {
      color: $white !important;
      text-decoration: underline !important;
      line-height: 22px;

      i:not(.cr-icon-arrow-right) {
        position: relative;
        color: $white !important;
        margin-left: 3px;
        padding-left: 3px;
      }

      i.cr-icon-arrow-right {
        vertical-align: middle;
      }

      &:hover {
        color: $grey200 !important;

        i {
          color: $grey200 !important;
        }
      }
    }
  }

  &.dismissed {
    &:not(.hoverable) {
      pointer-events: none;
    }

    .x-area {
      visibility: hidden;
    }
  }

  .x-area {
    line-height: 18px;

    &.with-title {
      line-height: 24px;
    }

    .icon-x {
      font-size: 18px;
      cursor: pointer;
      line-height: inherit;

      &:hover {
        color: $grey100;
      }
    }
  }

  // Draw the triangle
  &:not(.micro):after {
    position: absolute;
    content: "";
    display: block;
    width: $triangle-size;
    height: $triangle-size;
    background-color: $grey900;
    transform: translate(-50%, -50%) rotateZ(45deg);
  }

  &.top {
    bottom: calc(100% + ($triangle-size / 2) + 6px);
    left: 50%;
    transform: translateX(-50%);

    transition-property: opacity, bottom;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
    opacity: 1;

    &.appear,
    &.enter,
    &.exit-active {
      bottom: calc(100% + ($triangle-size / 2) + 6px + $animate-distance);
      opacity: 0;
    }

    &:after {
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(45deg);
    }
  }

  &.bottom {
    top: calc(100% + ($triangle-size / 2) + 6px);
    left: 50%;
    transform: translateX(-50%);

    transition-property: opacity, top;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
    opacity: 1;

    &.appear,
    &.enter,
    &.exit-active {
      top: calc(100% + ($triangle-size / 2) + 6px + $animate-distance);
      opacity: 0;
    }

    &:after {
      top: 0;
      left: 50%;
    }
  }

  &.right {
    top: 50%;
    left: calc(100% + ($triangle-size / 2) + 6px);
    transform: translateY(-50%);

    transition-property: opacity, left;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
    opacity: 1;

    &.appear,
    &.enter,
    &.exit-active {
      left: calc(100% + ($triangle-size / 2) + 6px + $animate-distance);
      opacity: 0;
    }

    &:after {
      top: 50%;
      left: 0;
    }
  }

  &.left {
    top: 50%;
    right: calc(100% + ($triangle-size / 2) + 6px);
    transform: translateY(-50%);

    transition-property: opacity, right;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;
    opacity: 1;

    &.appear,
    &.enter,
    &.exit-active {
      right: calc(100% + ($triangle-size / 2) + 6px + $animate-distance);
      opacity: 0;
    }

    &:after {
      top: 50%;
      left: 100%;
    }
  }

  &.dismissable .main-area {
    padding-right: 6px;
  }

  &.micro {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 150%;

    &.top {
      bottom: calc(100% + 4px);
    }

    &.bottom {
      top: calc(100% + 4px);
    }

    &.right {
      left: calc(100% + 4px);
    }

    &.left {
      right: calc(100% + 4px);
    }
  }

  /* Styling for light variation */
  &.light {
    background-color: $white;

    &,
    *:not(.pill-label-content) {
      color: $font-color;
    }

    .main-area {
      a {
        color: $font-color !important;

        i {
          color: $font-color !important;
        }

        &:hover {
          color: $grey500 !important;

          i {
            color: $grey500 !important;
          }
        }
      }
    }

    &:after {
      background-color: $white;
    }
  }
}
