@import "../../../_common/style/core/variables";

.app-users {
  .table-filters {
    .search-box-input-container {
      padding: 24px 32px 0;
    }
  }

  .app-users-score-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .score {
      white-space: nowrap;

      .cstar-text {
        .score-text {
          min-width: 0;
        }
      }
    }
  }

  .app-users-app-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;

    padding-bottom: 8px;

    .app-users-app-users {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    .app-users-app-risk {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }

    .app-users-detail {
      padding: 2px 12px;
      text-align: center;
      border-right: 2px solid hsl(0, 0%, 55%);
    }

    .app-users-detail:last-child {
      border: none;
    }
  }

  .app-users-cell-with-subtext {
    line-height: 1.3;

    .app-users-maintext {
      font-size: 14px;
    }

    .app-users-subtext {
      padding-top: 4px;
      font-size: $font-size-smaller;
      color: $grey600;
    }
  }

  .app-users-users-list {
    .expanded-content-cell {
      padding: 0;
    }

    .name-col {
      width: 22%;
    }

    @media (max-width: 1400px) {
      .name-col {
        width: 27%;
      }
    }

    .teams-col {
      width: 13%;
    }

    .roles-col {
      width: 18%;
    }
  }

  .app-users-slide-panel {
    .slide-panel.new-styles .title {
      border-bottom: none;
    }

    .slide-panel {
      // Important is required to enforce the width
      width: 700px !important;
    }
  }
}
