@use "../core/variables";

$vendor_summary_report: #81c3d8;
$vendor_detailed_report: #bce7ce;
$vendor_assessment_report: #8babe1;
$vendor_custom_report: #93caed;
$breachsight_custom_report: #f7abab;
$breachsight_summary_report: #ffbfbf;
$breachsight_detailed_report: #ffe69a;
$disabled: variables.$white;

.vendor-summary-report-modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  .modal-buttons {
    .progress-message {
      color: variables.$grey500;
    }
    .next .rotate-90 {
      margin-right: 0px !important;
      margin-left: 5px !important;
    }
  }
}

.vendor-summary-report-modal {
  width: 620px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin: 0px;

  .simple-modal-form {
    padding-top: 17px;
    padding-bottom: 17px;

    #report-vendor-selector {
      .tier-cell {
        padding-right: 8px !important;
      }
    }
    .report-type-badge {
      .rhs {
        img {
          height: 160px !important;
        }
      }
      .rhs.disabled {
        border: 0px !important;
        border-left: 1px solid #cccfe0 !important;
      }
    }
  }

  .tabled {
    padding-top: 0px;
    margin-left: -32px;
    margin-right: -32px;

    .search {
      padding: 0 32px;
    }

    .no-portfolios {
      padding: 0 32px 0 32px;
    }
  }

  .padded {
    padding-bottom: 30px;
  }

  .expando-h3 {
    display: flex;
    flex-direction: row;
    span {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 20px;
      margin-left: 10px;
    }
  }

  h3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px;

    .loader-icon {
      margin-left: 10px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .title {
    color: variables.$grey900;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;

    .color-checkbox {
      flex: 0;
    }

    .color-checkbox .color-checkbox-inner.disabled {
      border: none !important;
    }

    .label {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: 3px;

      .major {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: variables.$grey900;
        cursor: pointer;
      }
      .minor {
        color: variables.$grey500;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-top: 5px;
        cursor: pointer;
      }
      .greyed {
        color: variables.$grey200 !important;
      }
      .subs {
        display: flex;
        flex-direction: row;
        color: variables.$grey900;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-top: 10px;
        .color-checkbox {
          margin-right: 10px;
        }
        .disabled {
          background-color: transparent !important;
          border: 0px;
          span {
            color: variables.$grey200 !important;
          }
        }
      }
    }
  }

  .report-button {
    display: flex;
    flex-directiom: row;
    padding: 0px;
    border: solid 2px variables.$grey100;
    border-radius: 8px;
    width: 560px;
    cursor: pointer;
    background-color: variables.$white;
    margin-top: 15px;
    margin-bottom: 15px;

    .lhs {
      display: flex;
      flex-direction: column;
      padding: 20px;
      background-color: transparent;
      flex: 1;

      .report-title {
        color: variables.$grey900;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 15px;
      }
      .report-subtext {
        color: variables.$grey500;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        margin-bottom: 30px;
      }
      .disabled {
        color: variables.$grey200 !important;
        border: 0px;
      }
    }
    .rhs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      width: 260px;
      img {
        height: 150px;
      }
    }
    .disabled:not(.color-checkbox-inner) {
      background-color: variables.$grey50 !important;
      border: solid 0px variables.$grey200;
      border-left: solid 1px variables.$grey200;
      cursor: default;
      img {
        opacity: 40%;
      }
    }
    .vsr {
      background-color: $vendor_summary_report;
      img {
        margin-bottom: -10px;
      }
    }
    .vdr {
      background-color: $vendor_detailed_report;
    }
    .var {
      background-color: $vendor_assessment_report;
    }
    .vcur {
      background-color: $vendor_custom_report;
    }
    .bcur {
      background-color: $breachsight_custom_report;
    }
    .bsr {
      background-color: $breachsight_summary_report;
      img {
        margin-bottom: -10px;
      }
    }
    .bdr {
      background-color: $breachsight_detailed_report;
    }
    .disabled {
      background-color: $disabled;
    }
  }

  .hoverable {
    &:hover {
      border: solid 2px variables.$blue500;
    }
  }

  .selected {
    background-color: #f3f7fc !important;
    border: solid 2px variables.$blue500;
    cursor: default;
  }

  .disabled:not(.color-checkbox-inner) {
    background-color: variables.$grey50 !important;
    border: solid 1px variables.$grey200;
    cursor: default;
  }

  .delivery-form-grid {
    display: grid;
    grid-template-columns: 250px auto;
    grid-row-gap: 24px;

    .left {
      padding-right: 30px;
    }

    .grid-section {
      padding-bottom: 32px;
      border-bottom: 1px solid variables.$grey100;

      .no-portfolios {
        margin-bottom: 20px;
      }

      .color-checkbox {
        margin-bottom: 10px;
      }

      .selectable {
        padding: 0px !important;
      }
      th.selection-box {
        padding-left: 0px;
      }
      td.selection-box {
        padding-left: 0px;
      }
      th {
        padding-top: 0px;
        .box {
          margin-top: 5px;
          margin-bottom: -5px;
        }
      }
      tr td {
        border: 0px solid #000000;
        vertical-align: middle !important;
        .box {
          margin-bottom: -10px;
        }
      }
      tr > td:last-of-type {
        padding-right: 0px;
        text-align: right;
      }
      tr > th:last-of-type {
        padding-right: 0px;
        text-align: right;
      }
      tr.clickable {
        height: 36px !important;
      }
    }

    .grid-section:nth-last-of-type(-n + 2) {
      padding-bottom: unset;
      border-bottom: unset;
    }

    h3 {
      //styleName: db h3;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      margin-bottom: 8px;
    }

    .label-list {
      margin-top: 10px;
    }

    .ug-select {
      width: 120px !important;
      min-width: 120px !important;
    }

    .right {
      .ug-select__control {
        margin-top: 20px;
      }

      .date-time-select {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-top: 10px;

        .date-time-select-sub {
          display: flex;
          flex-direction: column;
          display: flex !important;
          flex-direction: row !important;
          align-items: center !important;
          justify-content: center !important;
          text-align: center !important;

          p {
            margin-top: 5px;
          }
        }

        p {
          margin-right: 16px;
          margin-bottom: 10px;
        }

        .date-day-select {
          width: 130px;
          margin-right: 16px;
          align-self: flex-start;
          .ug-select__control {
            min-width: 130px;
            margin-top: 0px !important;
          }
        }

        .time-select {
          width: 120px;
          .ug-select__control {
            min-width: 120px;
            margin-top: 0px !important;
          }
        }
      }

      .email-select {
        width: 250px !important;
        min-width: 250px;
      }
    }

    .time-select-div {
      margin-top: -5px;
      width: 120px !important;
      .ug-select {
        width: 120px !important;
        min-width: 120px !important;
        .ug-select__control {
          min-width: 120px !important;
          width: 120px !important;
        }
      }
    }
  }

  .x-table table tbody tr.selected {
    border: 0px !important;
  }

  .portfolios-list {
    color: variables.$grey900;
    font-weight: 500;
    table tbody tr {
      height: 55px;
    }
    .vendors-cell {
      padding-right: 40px;
    }
    .portfolio-cell {
      width: 100%;
    }
  }

  .portfolios-assessment-list {
    th {
      padding-bottom: 0px !important;
    }
    .portfolio-cell {
      // force long portfolio names with no space to
      // break and wrap to avoid overflow
      overflow-wrap: anywhere;
    }
    .selected {
      background-color: variables.$white !important;
      td {
        // override the table row borders to make it transparent but keep
        // it at 1px to avoid slight jump in size from hover action
        border-bottom: 1px solid transparent !important;
      }
      &:hover {
        background-color: variables.$blue50 !important;
      }
    }
  }
}
