@use "../../_common/style/core/variables";

.review-publish-modal {
  margin: 0px; // 20px 20px 20px 20px;
  background-color: variables.$white;
  display: flex;
  flex-direction: row;
  padding: 60px 20px;
  height: 100%; // calc(100% - 40px);

  .left-hand {
    width: auto;
    flex-direction: column;
    display: flex;
    height: 100%;
    flex: 1;

    .findings-scrolling-block {
      flex-direction: column;
      display: flex;
      height: 100%;
      display: block;
      overflow: auto;
    }
  }

  .right-hand {
    width: 300px;
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-left: 10px;
    border-left: 0.5px solid variables.$light-grey;
    padding-left: 10px;
    padding-right: 0px;
    height: 100%;

    .button-header {
      font-size: 18px;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
    }

    button {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  h2 {
    margin-bottom: 30px;
  }
}
