@use "../../../_common/style/core/variables";

#integration-details-modal {
  .paddedInputRow {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .radiolist {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;

    span.label {
      text-align: left;
    }
  }

  .attrib-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .attribute-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 0px;
      margin-bottom: 0px;
      height: 35px;
      width: 100%;

      .attrib-name {
        width: 40%;
        height: 30px;
        margin-right: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
      }
      .attrib-value {
        width: 60%;
        height: 30px;
        margin-right: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
      }
    }
  }

  .webhook-area {
    margin-top: 30px;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-top: 0.5px solid variables.$light-grey;
    border-bottom: 0.5px solid variables.$light-grey;
  }

  .test-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .webhook-btn {
      margin-top: 20px;
      width: 150px;
    }
  }

  .results-area {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: variables.$black;
    width: 100%;
    text-align: left;
    pre {
      color: variables.$green;
      white-space: pre-wrap; /* CSS3 */
      white-space: -moz-pre-wrap; /* Firefox */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word; /* IE */
    }
  }

  .notification-selector-table {
    margin-top: 10px;
    border: 1px solid variables.$dark-grey;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;

    .notification-row {
      padding: 0px;
      width: 100%;
      border: 0px solid variables.$dark-grey;
      border-bottom: 0.5px solid variables.$light-grey;
    }

    .notification-cell {
      text-align: left;
      vertical-align: center;
      border: 0px solid variables.$dark-grey;
      border-bottom: 0.5px solid variables.$light-grey;
    }
    .toggle-cell {
      margin-left: 10px;
      vertical-align: center;
      border: 0px solid variables.$dark-grey;
      border-bottom: 0.5px solid variables.$light-grey;
    }

    .toggle-cell label {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 20px;
    }
  }

  .toggle-switch {
    padding: 0px;
    margin: 0px;
  }

  .toggle-switch label {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .zapier-text {
    background-color: variables.$light-grey;
    color: variables.$black;
    border: 1px solid variables.$dark-grey;
  }

  .no-zapier-text {
    background-color: variables.$light-grey;
    color: variables.$red;
    border: 1px solid variables.$dark-grey;
  }

  .warning {
    padding: 15px;
    background-color: variables.$red;
    border: 0.5px solid variables.$white;
    color: variables.$white;
  }

  .spacer {
    width: 20px;
  }
  .vertspacer {
    height: 20px;
  }
}
