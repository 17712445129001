@use "variables";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

$font-family: Inter, "Inter-Fallback", Helvetica, Arial, sans-serif;
variables.$line-height: 1.32em;
$letter-spacing: 0;

@font-face {
  font-family: "Inter-Fallback";
  src: url("#{variables.$font-path}/Inter-Regular-slnt=0.woff2");
  font-weight: variables.$font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Fallback";
  src: url("#{variables.$font-path}/Inter-Medium-slnt=0.woff2");
  font-weight: variables.$font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Fallback";
  src: url("#{variables.$font-path}/Inter-SemiBold-slnt=0.woff2");
  font-weight: variables.$font-weight-bolder;
  font-style: normal;
}

// FONT OVERRIDES
body {
  font-family: $font-family;
  font-size: variables.$font-size;
  letter-spacing: $letter-spacing;
  font-weight: variables.$font-weight-regular;
}

h1,
h2,
h3,
h4,
h5 {
  display: inline-block;
  color: variables.$font-color;
  font-weight: variables.$font-weight-regular;
  letter-spacing: $letter-spacing * 2;
  margin-top: 0;
  margin-bottom: 0;
  font-family: $font-family;
}

.secondary-text {
  color: variables.$medium-grey;
}

h1 {
  font-size: 3.25em; /* ~50px */
}

h2 {
  font-size: 2.1em; /* ~50px */
  line-height: variables.$line-height;
}

h3 {
  font-size: 1.5em; /* ~50px */
  line-height: variables.$line-height;
}

p,
span,
div,
pre,
code,
button,
input {
  color: variables.$font-color;
  font-size: 1em;

  &.secondary-text {
    color: variables.$medium-grey;
  }
}

h1,
h2,
h3,
h4,
h5,
p,
span,
div,
pre,
code,
button,
input {
  &.center {
    text-align: center;
  }
}

// Define how links and href items will look
.a-tag,
a:not(.tab-item):not(.btn) {
  color: variables.$blue;
  cursor: pointer;
  text-decoration: none;

  &:visited,
  &:focus,
  &:hover {
    color: variables.$blue;
  }

  &:hover {
    text-decoration: underline;
  }

  &.no-href {
    color: variables.$font-color;
    text-decoration: none;
  }
}

.label-text {
  color: variables.$font-color;
  font-size: 1em;
  text-transform: uppercase;

  &.secondary-text {
    color: variables.$medium-grey;
  }
}

.error-text {
  color: variables.$red;
  font-style: italic;
  letter-spacing: 0.5px;
}

.arrow-link {
  white-space: nowrap;
  text-transform: none !important;
  color: variables.$grey700 !important;
  &:hover,
  &:hover .icon-arrow {
    text-decoration: none !important;
    color: variables.$blue !important;
  }
  .icon-arrow {
    color: variables.$grey700;
    font-size: 13px;
    margin-left: 5px;
    vertical-align: -1px;
  }
}
