@use "../../../_common/style/core/variables";

.modal-content-container:has(.add-qn-shared-profile-modal) {
  .modal-header p,
  .modal-content p {
    margin-bottom: 0 !important;
  }

  .modal-content {
    padding: 0 variables.$space-xl variables.$space-lg !important;
  }
}

.add-qn-shared-profile-modal {
  width: 800px;

  .checkbox-and-link {
    display: flex;
    flex-direction: row;
    align-items: center;

    .color-checkbox {
      width: unset;

      .label {
        white-space: normal;
      }
    }

    .btn-tertiary {
      margin-left: auto;
      white-space: nowrap;
    }
  }

  .q-select-item {
    .desc-text {
      color: variables.$grey500;
      padding-left: 30px;
      margin-bottom: 5px;

      .disabled-text {
        color: variables.$grey500;
      }
    }
  }

  .type-select-item {
    .sections-select {
      padding-left: 30px;
      margin-bottom: 10px;
    }
  }
}
