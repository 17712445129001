@use "../../../_common/style/core/variables";

#my_shared_profile {
  .my-shared-profile-page-header-title {
    display: flex;
    align-items: center;

    .shared-profile-access-control-pill {
      margin-left: 12px;
    }
  }

  .tab-buttons-wrapper {
    margin-bottom: 20px;
  }

  .settings-btn {
    .pipe {
      width: 1px;
      background-color: variables.$blue;
      height: 16px;
      margin: 0 7px;
    }

    &:disabled .pipe {
      background-color: variables.$grey200;
    }

    .cr-icon-cog2 {
      font-size: 16px;
    }
  }

  .my-shared-profile-progress-card {
    margin-bottom: 32px;

    .header {
      .progress-bar {
        width: 200px;
        margin-left: auto;
      }

      .progress-extra {
        font-size: variables.$font-size;
        font-weight: variables.$font-weight-regular;
        margin-left: 10px;
      }

      .cr-icon-chevron {
        margin-left: 32px;
        font-size: variables.$font-size;
      }

      &:hover {
        cursor: pointer;

        .cr-icon-chevron {
          color: variables.$blue;
        }
      }
    }

    .main-content {
      padding: 20px 32px;
    }

    .intro {
      .intro-header,
      p {
        max-width: 800px;
      }

      .intro-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        color: variables.$grey900;
        font-weight: variables.$font-weight-bold;

        .btn {
          margin-left: auto;
        }
      }

      p {
        line-height: 150%;
        color: variables.$grey500;
      }
    }

    .vertical-progress {
      margin-top: 32px;
    }
  }
}
