@import "../../../../_common/style/core/variables";

.vendor-portfolio-selection-modal {
  width: 600px !important;

  .create-portfolio-btn {
    margin-top: 16px !important;
    align-self: flex-start;

    .btn-inner {
      color: $blue;
    }

    &:hover .btn-inner {
      color: $blue700;
    }
  }
}

.vendor-portfolio-selection-modal-foot {
  .btn-align-left {
    margin-right: auto;
  }
}
