@import "../../core/variables";

$page-button-size: 32px;

.ug-paginate {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  .dir-link,
  .cr-icon-arrow-right,
  .page-number {
    font-size: 12px;
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    color: $medium-grey;
    cursor: pointer;
  }

  .page-number {
    &:hover {
      color: $blue;
      background-color: $blue200;
    }
    &.current {
      color: $blue;
      background-color: $blue100;
    }

    width: $page-button-size;
    min-width: $page-button-size;
    height: $page-button-size;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
    margin-right: 2px;

    border-radius: 2px;
  }
  .elipsis {
    width: $page-button-size;
    min-width: $page-button-size;
    height: $page-button-size;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $medium-grey;
    border-radius: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .dir-link {
    width: $page-button-size;
    min-width: $page-button-size;
    height: $page-button-size;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
    margin-right: 2px;

    .icon-chevron {
      color: $medium-grey;
      vertical-align: -2px;
      display: inline-block;
      font-size: 16px !important;
    }

    &:hover {
      &,
      .icon-chevron {
        color: $blue;
        background-color: $blue200;
      }
    }

    &.point-left {
      margin-right: 2px;
    }

    &.point-right {
      margin-left: 2px;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    &.hidden {
      visibility: hidden;
    }
  }

  //.page-number {
  //  color: $medium-grey;
  //  cursor: pointer;
  //  display: inline-block;
  //  font-size: .7rem;
  //  margin-left: $global-padding;
  //
  //  &.current {
  //    color: $font-color;
  //    cursor: default;
  //  }
  //}
}
