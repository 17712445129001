@import "../../../_common/style/core/variables";

.userrisk-risk-user-selection {
  .risk {
    padding: 20px 32px;
    background-color: $grey50;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .adjusted-severity-icon {
      width: 24px;
    }
    .name {
      font-weight: $font-weight-bold;
    }
    .category {
      color: $grey600;
      font-weight: $font-weight-bold;
    }
    .occurrences {
      margin-left: auto;
      font-weight: $font-weight-bold;
    }
  }
}
