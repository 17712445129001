@use "../../_common/style/core/variables";

.threat-source {
  display: flex;
  flex-direction: row;
  align-items: center;

  .sources {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .source {
      font-size: 14px;
      color: variables.$grey900;
    }
    .module {
      font-size: 12px;
      color: variables.$grey500;
      padding-left: 8px;
    }
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
