@use "../../../_common/style/core/variables";

.app-status {
  display: flex;
  flex-direction: row;
  align-items: center;

  .app-status-icon {
    margin-right: 5px;
  }
}

.partially-approved-container {
  display: flex;
  flex-direction: column;
  gap: variables.$space-sm;

  .popup-section {
    display: flex;
    flex-direction: column;
    gap: variables.$space-sm;
    padding-bottom: variables.$space-md;

    &:not(:last-child) {
      border-bottom: 1px solid variables.$grey100;
    }

    .popup-header {
      text-transform: uppercase;
      font-size: variables.$font-size-extra-small;
      font-weight: variables.$font-weight-bold;
      line-height: variables.$line-height-xsmall;
      letter-spacing: 1px;
      color: variables.$grey500;
    }

    .item-list > .item-pill {
      margin: 2px;
    }
  }
}
