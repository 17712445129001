@use "../../../../_common/style/core/variables";

.remediation-request-review-card {
  .recipient-email {
    margin-top: 5px;
    color: variables.$grey500;
  }
  .section-action > div {
    top: -8px;
  }

  .risk-summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid variables.$grey100;

    .risk-header {
      display: flex;
      padding: 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .risk-title {
        font-weight: variables.$font-weight-bold;
      }
      .severity-icon img {
        height: 24px;
      }
      .risk-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        line-height: 150%;
        .risk-description {
          color: variables.$grey600;
        }
      }
    }
    .expandable-item {
      width: 100%;
      font-size: 12px;
      .expandable-item-header {
        border-top: 1px solid variables.$grey100;
        padding: 8px 16px;
        .expandable-item-header-link {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 4px;
          color: variables.$grey600;
          line-height: 150%;
          font-size: 12px;
          .icon-chevron {
            color: variables.$grey600;
          }
        }
      }
      .expandable-item-content {
        ul {
          border-top: 1px solid variables.$grey100;
          margin-block-start: 0;
          margin-block-end: 0;
          padding: 8px 16px 8px 36px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          color: variables.$grey600;
          line-height: 150%;
        }
      }
    }
  }

  .rich-text-edit-v2.readonly .editor-inner {
    padding: 0;
  }
}
