@import "../../../_common/style/core/variables";

.remediation-request-card-v2 {
  .tab-row {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;

    .search-box-input-container {
      margin-left: 32px;
      margin-right: 32px;
      min-width: 300px;
    }

    .tab-buttons-wrapper {
      margin-left: 32px;
      margin-bottom: 20px;

      .tab-buttons {
        position: unset;
      }
    }
  }

  .remediation-requests-table {
    .vendor-cell {
      padding-top: 16px;

      h3 {
        font-size: 14px;
        line-height: 16px;
        color: $grey900;
        font-weight: $font-weight-regular;
        overflow: hidden;
        //white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        color: $grey500;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        margin-top: 2px;
        overflow: hidden;
        //white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .org-cell {
      white-space: nowrap;
      padding-right: 40px;
    }

    .title-cell {
      padding-top: 16px;
      width: 99%;

      h3 {
        font-size: 14px;
        line-height: 16px;
        color: $grey900;
        font-weight: $font-weight-regular;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        color: $grey500;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        margin-top: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .assets-cell {
      color: $grey900;
      font-size: 14px;
      line-height: 150%;
      width: 130px;
      min-width: 130px;
      max-width: 130px;
      white-space: nowrap;

      .assets-div {
        display: flex;
        flex-direction: column;
      }

      .dismissible-popup-container {
        display: inline-block;
      }

      .dismissible-popup-container > span {
        position: relative;
      }

      .dismissable-popup-click-capture {
        position: relative;
        display: inline-block;
        & > span:after {
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          bottom: 0;
          border-bottom: 1px dotted $medium-grey;
        }
      }
    }

    .progress-cell {
      min-width: 100px;
    }

    .risk-cell {
      color: $grey900;
      font-size: 14px;
      line-height: 150%;
      width: 110px;
      min-width: 110px;
      max-width: 110px;
      white-space: nowrap;

      .dismissible-popup-container {
        display: inline-block;
      }

      .dismissible-popup-container > span {
        position: relative;
      }

      .dismissable-popup-click-capture {
        position: relative;
        display: inline-block;
        & > span:after {
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          bottom: 0;
          border-bottom: 1px dotted $medium-grey;
        }
      }
    }

    .updated-cell,
    .due-cell {
      color: $grey900;
      font-size: 14px;
      line-height: 150%;
      width: 130px;
      min-width: 130px;
      max-width: 130px;
      white-space: nowrap;
    }

    .status-cell {
      width: 140px;
      min-width: 140px;
      max-width: 140px;
      white-space: nowrap;
    }

    .messages-cell {
      text-align: right;
    }
  }

  .remediation-actions {
    .container {
      display: flex;
      flex-direction: row;

      .selected-text {
        color: $grey900;
        align-self: center;
      }

      .cancel-button {
        margin-left: auto;
      }

      .btn + .btn {
        margin-left: 16px;
      }
    }
  }
}
