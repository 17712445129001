@use "../../_common/style/core/variables";

.cond-description {
  padding: 16px;
  background-color: variables.$blue25;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  mix-blend-mode: multiply;

  &.error {
    background-color: variables.$red10;
    border: 1px solid variables.$red300;
    mix-blend-mode: normal;
  }

  .cond-link {
    color: variables.$blue;
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor: pointer;
  }

  .actions {
    margin-left: auto;

    div + div {
      margin-left: 5px;
    }
  }
}
