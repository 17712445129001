@use "../core/variables";

.report-card {
  display: flex;
  flex-direction: column;
  background: variables.$white;
  border-radius: variables.$card-border-radius;
  border: 1px solid variables.$light-grey;
  box-shadow: variables.$box-shadow-4dp;
  position: relative;

  &.new-styles {
    .header {
      padding: 20px 32px;
      font-size: 18px;
      min-height: 80px;

      input {
        font-size: 14px;
      }

      img:not(.search-icon) {
        width: 24px;
        margin-right: 15px;
      }

      .description {
        font-size: 14px;
        color: variables.$grey500;
        margin-top: variables.$space-sm;
        line-height: 150%;
      }

      .toggle-label {
        font-size: 14px;
      }
    }

    // Some default styles for content
    .report-card-content {
      padding: 24px 32px;
    }

    .report-card-btn-group {
      background-color: variables.$blue25;
      border-bottom-right-radius: variables.$card-border-radius;
      border-bottom-left-radius: variables.$card-border-radius;
      padding: 32px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid variables.$grey100;

      button {
        margin: 0px;
        margin-left: 16px;
      }
    }
  }

  &.expandable {
    .header-container {
      border-bottom: 1px solid variables.$light-grey;

      &.interactive {
        cursor: pointer;
      }
    }

    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .header {
        border-bottom: none;
        flex-grow: 1;
      }
    }

    .expand-toggle {
      padding: 0 12px 0 4px;
      margin-right: 6px;

      .icon-chevron {
        font-size: 20px;
        transition: transform 250ms ease-out;
      }
    }

    &.sticky.expanded .header-container {
      position: sticky;
      background-color: variables.$white;
      border-top-left-radius: variables.$card-border-radius;
      border-top-right-radius: variables.$card-border-radius;
      top: 0;
      z-index: 3;
    }
  }

  .loading-overlay {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
    z-index: 15;
  }

  &.loading .loading-overlay {
    display: flex;
  }

  .header {
    padding: 20px;
    border-bottom: 1px solid variables.$light-grey;
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-icon {
      font-size: 18px;
      margin-right: 10px;
    }

    img {
      margin-right: 10px;
      width: 20px;
    }

    .header-title {
      font-size: 18px;
    }

    .header-right {
      margin-left: auto;
      display: flex;
      align-items: center;

      & > div,
      & > button,
      & > a {
        margin-left: 16px;
      }
    }

    .stat {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .val {
        color: variables.$medium-grey;
        font-size: 20px;
        line-height: 20px;
        margin-right: 5px;
      }

      .unit {
        color: variables.$medium-grey;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    .header-sub {
      font-size: 14px;
      margin-top: 10px;
      color: variables.$grey500;
      line-height: 150%;
    }

    .back-arrow {
      position: relative;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .report-content {
    position: relative;

    & > .loading-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 1;

      .loading-large {
        padding: 0;
      }
    }
  }

  .filter-empty {
    padding: 20px;
  }

  .loading-tight {
    padding: 20px;
  }

  .report-card {
    border: 1px solid variables.$light-grey;

    .header {
      background-color: variables.$lighter-blue;
      font-size: 14px;
      padding: 20px;
      min-height: unset;

      img:not(.search-icon) {
        margin-right: 10px;
        width: 20px;
      }
    }
  }

  & + .report-card {
    margin-top: 20px;
  }

  &.tight {
    .report-card {
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: none;

      & + .report-card {
        margin-top: 0;
        border-top: 1px solid variables.$light-grey;
      }
    }
  }

  .chart-container {
    padding: 20px 20px 10px 20px;
  }

  .bottom-left {
    border-bottom-left-radius: variables.$card-border-radius;
  }

  .bottom-right {
    border-bottom-right-radius: variables.$card-border-radius;
  }

  .x-table.sticky {
    margin-top: 8px;
  }

  .no-results {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 35px;
    box-shadow: none;

    & > [class^="cr-icon-"],
    & > [class*=" cr-icon-"] {
      font-size: 30px;
      color: variables.$grey500;
    }

    .empty-title {
      text-align: center;
      font-weight: variables.$font-weight-bold;
      font-size: 16px;
      padding: 0px;
      margin: 0px;
      margin-top: 10px;
    }

    .empty-text {
      text-align: center;
      color: variables.$grey500;
      padding: 0px;
      margin: 0px;
      margin-top: 10px;
    }

    .empty-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      color: inherit;

      .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;

        .cr-icon-arrow-right {
          font-size: 20px;
          color: inherit;
          margin-left: 5px;
        }

        &:hover {
          color: variables.$blue;
          cursor: pointer;
        }
      }
    }
  }

  .report-card-sections {
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .report-card-section {
      display: flex;
      padding-bottom: 24px;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      &:not(:last-child) {
        border-bottom: 1px solid variables.$grey100;
      }

      .section-label {
        width: 152px;
        font-weight: 500;
      }
      .section-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
      }
    }
  }
}
