@import "../../../_common/style/core/variables";

.x-table.risk-vendor-addtional-evidences {
  table th:first-of-type,
  table td:first-of-type {
    padding-left: 0;
  }

  table tbody tr:not(.expanded-content-row) {
    height: 48px;
  }

  .a-tag,
  a:not(.tab-item):not(.btn) {
    color: $blue500;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &.waived {
      &:hover {
        color: $grey400 !important;
      }

      font-style: normal !important;
    }
  }

  .cr-icon-external-link {
    margin-left: 3px;
    font-size: $font-size-larger;
    color: $blue500;
  }

  .link-container {
    display: flex;
    align-items: center;
  }

  th {
    padding-top: 0;
    vertical-align: middle;
  }
}
