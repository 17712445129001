@use "../../../_common/style/core/variables";

.ip-range-panel-content {
  padding: 0 40px 24px 40px;

  .ip-range-header {
    display: flex;

    .ip-range-title {
      font-size: 32px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;

      .ip-range-connector {
        margin-left: 15px;
        width: 8px;
        height: 36px;
        border: 1px solid variables.$grey200;
        border-left: none;
      }
    }

    .ip-range-actions {
      flex-grow: 1;
      display: flex;
      flex-direction: row-reverse;

      .btn {
        margin-left: 16px;
      }
    }
  }

  .section-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .tab-buttons-wrapper {
    margin-bottom: 20px;
  }
}
