.modal-content.edit-developer-toggles-modal {
  max-width: 800px;

  .toggle {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    & > * {
      flex: 1;
    }
  }

  h4 {
    text-align: right;
  }

  .toggle-switch {
    label {
      margin-top: 0px;
      margin-left: 25px;
    }
  }
}
