@use "../../_common/style/core/variables";

.send-questionnaire-to-vendor-modal {
  padding-top: 10px !important;

  .multi-steps {
    margin-bottom: 24px;
  }
  .info-banner {
    margin-bottom: 24px;
  }

  .progress-bar {
    max-width: 50%;
  }

  .color-checkbox {
    margin-bottom: 16px;
  }

  .prompt {
    margin-bottom: 24px;
    color: variables.$grey700;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .date-ctrl {
    width: 310px;
    height: 100px;
    position: relative;
    .date-picker {
      max-width: 310px;
    }
    .calicon {
      width: 26px;
      height: 26px;
      position: absolute;
      top: 3px;
      left: 280px;
      cursor: pointer;
      pointer-events: none;
    }
  }

  .contact {
    .input-container {
      input {
        max-width: unset !important;
      }
    }
  }

  .unanswered-questions {
    .expando-h3 {
      display: flex;
      flex-direction: row;
      border-top: 1px solid variables.$grey100;
      margin-top: 32px;
      margin-bottom: 0px !important;
      padding-top: 24px;
      padding-right: 24px;
      cursor: pointer;
      .icon-chevron {
        height: 24px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
      }
      .title {
        width: 100% !important;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 20px;
      }
    }

    .cal {
      margin-top: -20px !important;
    }

    .question-table {
      tbody {
        td {
          padding-top: 16px !important;
          padding-bottom: 16px !important;
        }
      }

      .unanswered-question-row {
        padding: 16px 0;

        cursor: pointer;

        &:hover {
          background-color: variables.$blue50;
        }

        .item-icon-cell {
          width: min-content !important;
          padding-right: 10px !important;
        }

        td {
          width: unset !important;
          vertical-align: middle !important;
        }

        border-top: 1px solid variables.$grey50;

        .survey-viewer-icon {
          display: inline-flex;
          margin-right: 12px;
          background-color: variables.$blue50;
          mix-blend-mode: multiply;
          align-self: flex-start;

          .icon {
            i {
              color: variables.$blue500;
            }
          }

          .display-id {
            color: variables.$blue500;
          }
        }

        .node-name-cell {
          width: 100% !important;
          .node-name {
            flex-grow: 1;

            // If node name has HTML inside it, provide some default styling
            p {
              color: variables.$grey600;
            }

            a {
              text-decoration: underline;
            }

            p:first-of-type {
              margin-top: 0px;
            }
          }
        }

        i.cr-icon-chevron {
          margin-left: 24px;
          margin-right: 24px;
        }
      }

      :last-of-type(.unanswered-question-row) {
        border-bottom: 1px solid variables.$grey50;
      }
    }

    .extra-question-count {
      margin-top: 14px !important;
      margin-bottom: 14px !important;
      margin-left: 40px;
    }
  }

  .email-fields {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
    }
    .message {
      width: 610px;
      .rich-text-edit-v2 {
        max-width: 610px !important;
      }
    }
    .subject {
      width: 100%;
      input {
        max-width: unset !important;
      }
    }
    .template-prompt {
      margin-top: 18px;
      .info-banner {
        max-width: 610px;
        margin-bottom: 0px;
        div {
          color: inherit;
        }
      }
    }
  }
}
