@use "../../../_common/style/core/variables";

.shared-profile-deny-access-modal {
  max-width: 600px !important;
}

.org-and-hostname {
  display: flex;
  flex-direction: row;
  align-items: center;

  .company-logo {
    margin-right: 10px;

    .circle-logo {
      border: 1px solid variables.$grey50;
    }
  }

  .hostname {
    color: variables.$grey500;
  }
}

.shared-profile-access-requests-table {
  .action-cell {
    & > * + * {
      margin-left: 16px;
    }
  }
}

.organisations-with-access-table,
.shared-profile-access-requests-table {
  .nda-cell-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .btn-tertiary {
      padding: 0 6px 0 6px;
    }
  }
}
