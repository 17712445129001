@use "../core/variables";

$dot-size: 6px;

.cr-dot {
  display: inline-block;
  position: relative;
  width: $dot-size;
  height: $dot-size;
  border-radius: 50%;
  vertical-align: middle;

  &.green {
    background-color: variables.$green500;
  }

  &.orange {
    background-color: variables.$orange500;
  }

  &.red {
    background-color: variables.$red500;
  }

  &.blue {
    background-color: variables.$blue500;
  }

  &.grey {
    background-color: variables.$grey500;
  }

  & + * {
    margin-left: 6px;
  }

  * + & {
    margin-left: 6px;
  }
}
