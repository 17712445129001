@import "../../../_common/style/core/variables";

.changes {
  .report-card.new-styles .header {
    img {
      margin-right: 8px;
    }

    .cr-icon-risk {
      margin-right: 12px;
      margin-bottom: 2px;
      color: $grey500;
    }
  }

  .grade-and-score {
    display: flex;
    .score {
      align-self: center;
      margin-left: 8px;
    }
  }

  .changes-help {
    background-color: $white;
    padding: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      max-width: 355px;
      text-align: center;

      img {
        width: 100%;
      }

      .item-text {
        font-size: 14px;
        font-style: italic;
        color: $medium-grey;
      }

      &:not(:last-of-type) {
        margin-right: 80px;
      }
    }
  }

  .no-changes-text {
    text-align: center;
    font-size: 20px;
    font-style: italic;
    color: $medium-grey;
    padding: 20px;
  }

  h2 {
    font-size: 16px;
    margin-top: 20px;
    padding-bottom: 10px;
  }

  .changes-category-card {
    min-height: 0 !important;

    .title .icon-info {
      &,
      &:hover {
        display: inline-block;
        color: $medium-grey !important;
        font-size: 22px !important;
        margin-right: 10px;
        margin-left: 0 !important;
        cursor: auto !important;
      }
    }

    .summary-item {
      max-width: 100%;

      .title {
        width: 350px;
      }
    }

    .cloudscan-diff-table {
      width: 100%;

      th,
      td {
        width: 25%;
        padding-bottom: 5px;
        vertical-align: top;
      }

      thead th {
        text-align: left;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1px;
        color: $medium-grey;
      }

      tr.cloudscan-row {
        cursor: pointer;

        &:hover .hostname-col {
          color: $blue;
        }
      }

      td.diff-col {
        padding-right: 10px;

        .diff {
          padding: 5px;
          min-height: 23px;
          background-color: $canvas;
          color: $dark-grey;
          font-size: 12px;
          font-family: "Andale Mono", AndaleMono, monospace;
          border-radius: 2px;
        }
      }
    }
  }

  .vendor-list {
    margin-bottom: 0 !important;
  }

  .hostname-list {
    .hostname-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      padding: 24px 32px;

      &:hover {
        &,
        .hostname {
          color: $blue;
        }
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $grey100;
      }

      .cstar-icon {
        margin-right: 5px;
      }

      .hostname {
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 10px;
      }
    }
  }

  .accepted-risk-change {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .dismissible-popup-container {
      position: relative;
      display: inline-block;
      cursor: pointer;

      &:after {
        position: absolute;
        content: "";
        top: 100%;
        left: 0;
        right: 0;
        border-bottom: 1px dotted $medium-grey;
      }
    }
  }

  .shared-questionnaire {
    margin-left: 10px;
  }
}

#filter_view_frame.active + #changes_view_frame {
  .changes-view-frame {
    top: 20px;
  }
}

.changes-view-frame {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: $nav-width;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 0 $changes-box-shadow-width $changes-blue;
  z-index: 10;

  .changes-view-tab {
    pointer-events: all;
    cursor: pointer;
    font-size: 11px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    padding: 10px 20px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $changes-blue;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .icon-x {
      color: #ffffff;
      font-weight: $font-weight-bold;
      margin-left: 5px;
      font-size: 11px;
      vertical-align: -1px;
    }
  }

  &.for-survey {
    left: 0;
    z-index: 20;

    .changes-view-tab {
      top: calc(100% - 32px);
      left: 200px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}

.changes-view-anim-enter {
  box-shadow: inset 0 0 0 0px $changes-blue;

  .changes-view-tab {
    transform: translate(-50%, -100%);
  }
}

.changes-view-anim-enter.changes-view-anim-enter-active {
  box-shadow: inset 0 0 0 $changes-box-shadow-width $changes-blue;
  transition: box-shadow 250ms ease-in-out;

  .changes-view-tab {
    transform: translate(-50%, 0);
    transition: transform 250ms ease-in-out;
  }
}

.changes-view-anim-exit {
  box-shadow: inset 0 0 0 $changes-box-shadow-width $changes-blue;

  .changes-view-tab {
    transform: translate(-50%, 0);
  }
}

.changes-view-anim-exit.changes-view-anim-exit-active {
  box-shadow: inset 0 0 0 0px $changes-blue;
  transition: box-shadow 250ms ease-in-out;

  .changes-view-tab {
    transform: translate(-50%, -100%);
    transition: transform 250ms ease-in-out;
  }
}

.changes-mode-disabled {
  opacity: 0.5;
  transition: opacity $animation-timing ease-in-out;
  cursor: default !important;
  pointer-events: none;
}
