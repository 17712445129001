@use "../../../_common/style/core/variables";

.exclaim-label {
  display: inline-block;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;

  .exclaim {
    border-radius: 30px;
    color: variables.$white;
    font-size: 12px;
    font-weight: variables.$font-weight-bold;
    margin-right: 4px;
    padding: 1px 6px;

    @media print {
      color: variables.$white !important;
      -webkit-print-color-adjust: exact;
      text-align: right;
    }
  }

  .exclaim-triangle {
    margin-right: 4px;
    $triangle-width: 20px;
    background-color: transparent;
    height: $triangle-width;
    width: $triangle-width;

    path {
      stroke-linejoin: round;
      stroke-width: 8px;
      transform: translateY(8px);
    }

    text {
      fill: variables.$white;
      font-family: Inter, "Inter-Fallback", Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: variables.$font-weight-bold;
    }
  }

  &.yellow {
    color: variables.$yellow;
    .exclaim {
      background-color: variables.$yellow;

      @media print {
        background-color: variables.$yellow !important;
      }
    }

    .exclaim-triangle path {
      fill: variables.$yellow;
      stroke: variables.$yellow;
    }

    &.inverted {
      .exclaim {
        background-color: variables.$white;
        color: variables.$yellow !important;
        box-shadow: 0 0 0 1px variables.$yellow;

        @media print {
          background-color: variables.$white !important;
        }
      }

      .exclaim-triangle path {
        fill: variables.$white;
        stroke: variables.$white;
      }

      .exclaim-triangle text {
        fill: variables.$yellow;
      }
    }
  }

  &.orange {
    color: variables.$orange;
    .exclaim {
      background-color: variables.$orange;

      @media print {
        background-color: variables.$orange !important;
      }
    }

    .exclaim-triangle path {
      fill: variables.$orange;
      stroke: variables.$orange;
    }

    &.inverted {
      .exclaim {
        background-color: variables.$white;
        color: variables.$orange !important;
        box-shadow: 0 0 0 1px variables.$orange;

        @media print {
          background-color: variables.$white !important;
        }
      }

      .exclaim-triangle path {
        fill: variables.$white;
        stroke: variables.$white;
      }

      .exclaim-triangle text {
        fill: variables.$orange;
      }
    }
  }

  &.red {
    color: variables.$red;
    .exclaim {
      background-color: variables.$red;

      @media print {
        background-color: variables.$red !important;
      }
    }

    .exclaim-triangle path {
      fill: variables.$red;
      stroke: variables.$red;
    }

    &.inverted {
      .exclaim {
        background-color: variables.$white;
        color: variables.$red !important;
        box-shadow: 0 0 0 1px variables.$red;

        @media print {
          background-color: variables.$white !important;
        }
      }

      .exclaim-triangle path {
        fill: variables.$white;
        stroke: variables.$white;
      }

      .exclaim-triangle text {
        fill: variables.$red;
      }
    }
  }
}

.page-header-layer .exclaim-label {
  margin-right: variables.$global-padding * 2;
}
