@use "../../../../_common/style/core/variables";

.edit-vendor-risk-waiver-modal,
.create-vendor-risk-waiver-modal {
  .two-column-display {
    > div {
      padding: 0;
    }

    .two-column-display-row {
      margin-top: 24px;
      padding-bottom: 24px;
      grid-template-columns: minmax(200px, 1fr) minmax(
          250px,
          variables.$form-input-max-width
        );

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      .color-checkbox {
        margin-bottom: 8px;

        .color-checkbox-inner .label {
          white-space: normal;
        }
      }

      .label-container {
        .pill-label {
          margin-top: 8px;
        }

        p {
          margin-top: 0;
        }
      }
    }
  }

  h4 {
    font-size: 10px;
    color: variables.$grey500;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: variables.$font-weight-bold;
    margin-bottom: 4px;
  }

  .risk-header {
    display: flex;
    border: 2px solid variables.$grey100;
    border-radius: 8px;
    padding: 16px;
    margin-top: 24px;
    align-items: center;
    margin-bottom: 24px;

    .adjusted-severity-icon {
      margin-right: 16px;
    }

    .risk-text-container {
      padding-right: 24px;

      .risk-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        font-weight: variables.$font-weight-bold;
        margin-bottom: 4px;
      }

      .risk-cat-title {
        color: variables.$grey500;
        max-width: 400px;
      }
    }

    .asset-count {
      margin-left: auto;
      position: relative;
      font-weight: variables.$font-weight-bold;
    }
  }

  .risk-hostname-selector {
    flex-direction: column;

    .all-websites-selection-description {
      max-width: 460px;
    }

    .items {
      margin-left: 0;
      padding-left: 0;

      ul {
        margin-left: 0;
        border: 2px solid variables.$grey100;
        border-radius: 8px;
        padding: 8px;
      }
    }
  }

  .questionnaire-selection-container {
    margin: 0;
    .questionnaire-selection {
      margin-top: 0;
    }
  }

  .info-table {
    tr:last-of-type {
      td {
        padding-bottom: 0;
      }
    }
  }
}

.edit-vendor-risk-waiver-modal-footer,
.create-vendor-risk-waiver-modal-footer {
  .btn-group + .btn-group {
    margin-left: auto;
  }
}
