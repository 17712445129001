@use "../../_common/style/core/variables";

.survey-viewer-icon {
  display: flex;
  align-items: center;
  background-color: variables.$grey50;
  mix-blend-mode: multiply;
  padding: 5px 8px 5px 8px;
  border-radius: 4px;
  transition: all 250ms ease-in-out;

  .icon {
    display: flex;
    font-size: 21px;
    i {
      color: variables.$grey500;
      transition: all 250ms ease-in-out;
    }

    .severity-icon {
      display: flex;
      i {
        color: variables.$grey500;
        transition: all 250ms ease-in-out;
      }
    }
  }

  .display-id {
    font-size: 14px;
    line-height: 21px;
    color: variables.$grey500;
    white-space: nowrap;
    transition: all 250ms ease-in-out;
  }

  .icon + .display-id {
    margin-left: 10px;
  }
}
