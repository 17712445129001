@import "../../../_common/style/core/variables";

.breachsight-message-modal {
  .apply-button {
    width: 100px;
  }

  p {
    text-align: left;
    margin-bottom: 15px !important;
  }
  .center-text {
    text-align: left;
    margin-bottom: 30px !important;
    padding-top: 8px;
    font-size: 18px;
  }

  button {
    float: right;
    margin-top: 20px !important;
  }
}
