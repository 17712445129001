@import "../core/variables";

.calendar-day-container {
  width: 100%;
  height: 100%;

  .container-header {
    width: 100%;
    height: 20.83%;
    background: $red500;
    border-radius: 4px 4px 0px 0px;
  }

  .container-content {
    min-height: 79.17%;
    font-size: 14px;
    text-align: center;
    border: 1.5px solid $grey200;
    border-top: none;
    background-color: #fff;
    border-radius: 0px 0px 4px 4px !important;
  }
}
