@import "../../../../_common/style/core/variables";

.export-sections-config {
  display: grid;
  grid-template-columns: 3fr 5fr;

  & > div + div {
    border-left: 1px solid $grey100;
  }

  .pane-head {
    padding: 24px 24px 10px 0;
    margin-left: 32px;
    color: $grey400;
    font-size: 12px;
    line-height: 150%;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid $grey100;
  }

  .sections-pane {
    display: flex;
    flex-direction: column;

    .sections-list {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: $grey50;

      .sections-list-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14px 10px 14px 8px;
        position: relative;

        &.clickable {
          cursor: pointer;
        }

        &.selected {
          background-color: $white;

          .section-title {
            font-weight: $font-weight-bold;
          }

          .btn-icon .cr-icon-chevron {
            color: $blue;
          }

          &:before {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            content: "";
            background-color: $blue500;
          }

          &:after {
            position: absolute;
            left: calc(100% - 2px);
            top: 0;
            bottom: 0;
            width: 4px;
            content: "";
            background-color: $white;
          }
        }

        &:hover {
          &:not(.selected) {
            background-color: $blue50;
          }

          .btn-icon {
            background-color: $blue50;
            mix-blend-mode: multiply;

            .cr-icon-chevron {
              color: $blue;
            }
          }

          .drag-handle .cr-icon-drag-handle {
            color: $grey600;
          }
        }

        & + .sections-list-section {
          border-top: 1px solid $grey100;
        }

        .drag-handle {
          width: 24px;
          margin-right: 8px;
          text-align: center;

          &.movable {
            cursor: grab;
          }

          .cr-icon-drag-handle {
            color: $grey300;
          }
        }

        .color-checkbox {
          width: unset;
        }

        .section-title {
          flex: 1;
          padding-right: 10px;
        }
      }
    }
  }

  .content-pane {
    .content-pane-content {
      padding: 24px 24px 24px 32px;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .content-title {
        font-size: 16px;
        line-height: 150%;
        color: $grey900;
        font-weight: $font-weight-bold;
      }

      .content-description {
        margin-top: 6px;
        line-height: 150%;
        color: $grey600;
      }

      .content-sample-img {
        width: 100%;
        margin-top: 32px;
      }

      .content-sample-img-caption {
        color: $grey600;
        margin-top: 10px;
      }

      .export-config-options {
        margin-top: 32px;
      }
    }
  }
}

.export-config-options {
  .export-config-option {
    display: flex;
    flex-direction: row;

    > div:last-child {
      flex: 1;
    }

    .title-desc {
      width: 35%;
      min-width: 200px;
      max-width: 320px;
      padding-right: 16px;

      font-weight: $font-weight-bold;
      line-height: 150%;

      .desc {
        font-weight: $font-weight-regular;
        color: $grey600;
        margin-bottom: 20px;
      }
    }

    .text-field-container {
      flex: 1;
      position: relative;

      .text-field {
        flex: 1;
      }

      .merge-tags-link {
        font-size: 11px;
        text-decoration: underline;
        color: $grey400;

        &:hover {
          color: $grey500;
          cursor: pointer;
        }
      }
    }

    .checkbox-radio-opt-container {
      .checkbox-radio-opt {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        line-height: 24px;

        &.clickable:hover {
          cursor: pointer;
          .title {
            color: $blue;
          }
        }

        &.disabled .checkbox-label {
          opacity: 0.8;
        }

        .color-checkbox {
          width: unset;
        }

        .desc {
          color: $grey600;
        }
      }

      .export-config-option-children {
        margin-top: 6px;
        margin-left: 32px;
      }
    }

    .select-list-container {
      max-width: 400px;
      padding-left: 32px;
    }

    & + .export-config-option {
      margin-top: 12px;
    }

    .date-time-select {
      p {
        margin-top: 12px !important;
        margin-bottom: 4px;
      }
    }

    .label-list {
      margin-top: 10px;
    }
  }

  & > .export-config-option + .export-config-option {
    margin-top: 20px;
  }
}

.report-card {
  .export-sections-config .sections-pane .sections-list {
    &,
    .sections-list-section:last-child {
      border-bottom-left-radius: $card-border-radius;
    }
  }
}
