@import "../../_common/style/core/variables";

.userbase-event-timeline {
  .header {
    justify-content: space-between;
  }

  .event-timeline-table {
    tr {
      height: unset !important;

      td {
        padding-top: $space-md;
        padding-bottom: $space-md;
        font-size: $font-size;
        line-height: $line-height;
      }
    }

    .date-and-time-col {
      width: 250px;
    }
  }
}
