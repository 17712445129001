@import "../core/variables";

.survey-details-unanswered-questions-card {
  .header {
    button,
    a {
      margin-left: auto;
    }

    button {
      .cr-icon-arrow-right {
        font-size: 18px;
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }

  .top-section {
    display: flex;
    padding: 32px;
    flex-direction: row;
    align-items: flex-start;

    .info-text {
      padding-right: 30px;
      color: $grey500;
      line-height: 150%;
      flex: 1;
      max-width: 650px;
    }

    .progress-bar {
      width: 300px;
      min-width: 300px;
      margin-left: auto;
    }
  }

  .question-cell {
    .question-cell-content {
      display: flex;

      .node-type-icon {
        display: inline-flex;
        align-self: baseline;
        margin-right: 5px;
      }

      .question-text {
        display: inline-block;
        line-height: 24px;

        // If node name has HTML inside it, provide some default styling
        a {
          text-decoration: underline;
          text-transform: none;
        }

        p:first-child {
          margin-top: 0px;
        }
      }
    }
  }

  .question-type-cell {
    color: $grey500;
  }

  tr:not(.pagination-row) {
    height: unset !important;

    td {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
