@use "../../../_common/style/core/variables";

.userbase-applications {
  // Force the company logo to be 32px
  .company-logo {
    .circle-logo {
      width: 32px;
      min-width: 32px;
      height: 32px;
    }
  }

  .search-and-count {
    padding: variables.$space-lg variables.$space-xl 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: variables.$space-xl;

    .search-box-input-container {
      flex: 1;
      padding: unset;
    }
  }
  // Make the company category text match the permission table subtext
  .company-category {
    font-size: variables.$font-size-smaller;
    color: variables.$grey500;
  }
  .search-box-input-container {
    padding: 24px 32px 0px 32px;
  }
  .actions-cell {
    min-width: 150px;
  }

  .application-score-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .apps-container {
    .current-users-col {
      width: 130px;
    }
    .security-rating-col {
      width: 130px;
    }
    .permissions-col {
      width: 130px;
    }
    .status-col {
      width: 230px;
    }
    .vendor-monitored-col {
      width: 130px;
    }
    .unapproved-users-col {
      width: 130px;
    }
    .actions-col {
      width: 170px;
    }
  }
}
