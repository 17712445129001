@import "../../../_common/style/core/variables";

.monitor-vendor-modal-larger {
  height: 280px !important;
  max-height: 280px !important;
}
.monitor-vendor-modal {
  width: 800px !important;
  align-items: center;
  height: 170px;
  max-height: 170px;
  padding-bottom: 0px !important;
  margin-bottom: 40px !important;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  img {
    width: 48px;
    height: 48px;
    display: flex;
    margin-bottom: 24px;
    margin-top: -24px;
  }

  h2 {
    font-size: 18px !important;
    line-height: 24px !important;
    color: $grey900 !important;
    text-align: center !important;
  }

  p {
    text-align: left;
    margin-bottom: 20px !important;
  }

  .butt-support {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grey500;

    .cr-icon-arrow-right {
      font-size: 18px;
      margin-left: 3px;
      color: $grey500;
    }
    a {
      color: $grey500 !important;
      font-weight: 500 !important;
    }

    &:hover {
      color: $blue !important;
      text-decoration: none;
      a {
        color: $blue !important;
        text-decoration: none !important;
      }
      .cr-icon-arrow-right {
        color: $blue !important;
      }
    }
  }
}

.monitor-vendor-modal-footer {
  padding-bottom: 24px !important;
}

.monitor-vendor-modal-header {
  height: 0px !important;
  padding: 0px !important;
  padding-top: 10px !important;
}
