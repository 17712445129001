@import "../../../_common/style/core/variables";

.modal-form {
  .form-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 0;

    p {
      margin-bottom: 0;
    }

    * + p {
      margin-top: 10px !important;
    }

    input,
    textarea,
    .text-field,
    .ug-select {
      flex: 1;
    }

    textarea {
      resize: vertical;
    }

    & + .form-section {
      border-top: 1px solid $grey100;
    }

    .form-section-desc {
      width: 40%;
      padding-right: 30px;

      p {
        color: $grey500;
      }

      span {
        font-weight: $font-weight-bold;
      }
    }

    .form-section-input {
      flex: 1;
      display: flex;
      flex-direction: column;

      .color-checkbox + .color-checkbox {
        margin-top: 5px;
      }
    }

    a {
      text-transform: none !important;
      color: $grey500;
    }
  }
}
