@use "../../../_common/style/core/variables";

.trust-page-custom-domains-card {
  .header {
    .header-title {
      display: flex;
      align-items: center;

      .pill-label {
        margin-left: variables.$space-sm;
      }
    }
  }
}
