.new-vendor-choice-modal-header {
  h2 {
    padding-bottom: 10px;
  }
  p {
    margin: 0;
  }
}

.new-vendor-choice-modal {
  width: 500px;

  .color-checkbox {
    .box {
      align-self: flex-start;
    }

    .label {
      .pill-label {
        margin-left: 10px;
      }

      p {
        white-space: break-spaces;
      }
    }
  }
}
