.debug-menu {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1000000;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &.closed {
    cursor: pointer;
    padding: 10px;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background: #f5f5f5;
    padding: 10px;

    .x {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  .content {
    padding: 10px;

    display: flex;
    flex-direction: column;
  }
}
