@use "../../../../_common/style/core/variables";

.vendor-assessment-text-card {
  .section-toggle {
    display: flex;
    align-items: center;

    .section-toggle-label {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      margin-left: 8px;
    }
  }

  .bottom-section {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  .contents {
    margin: 32px;

    .rich-text-edit-v2 {
      &.readonly {
        .editor-inner {
          min-height: unset;
          padding-bottom: 0;

          .content-editable {
            min-height: unset;
          }
        }
      }
    }

    .buttons {
      color: variables.$grey600;
      font-size: variables.$font-size-smaller;
      font-weight: variables.$font-weight-regular;
      line-height: 18px;
      margin-right: 4px;

      .underline {
        text-decoration: underline;
        cursor: pointer;
        color: variables.$grey600;
      }
    }
  }

  .remaining {
    margin-left: auto;
    color: variables.$grey600;
    font-weight: variables.$font-weight-regular;
    font-size: variables.$font-size-smaller;
    line-height: 18px;
  }
}
