@use "../../../../_common/style/core/variables";

.control-panel {
  .excluded {
    opacity: 0.4;
  }

  .control-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: variables.$space-md;
    padding: variables.$space-lg variables.$space-xl;

    h2 {
      text-wrap: wrap;
      padding-bottom: unset;
    }
  }

  .slide-panel {
    width: 700px !important;

    .title {
      padding-right: variables.$space-lg;
      border-bottom: none !important;
    }
  }

  .check-item {
    .slide-panel-section {
      border-top: 0;
      margin-left: 0;

      .section-header {
        padding: variables.$space-lg variables.$space-xl;

        .title {
          padding: 0;
        }
      }
    }

    .section-body {
      padding: 0;
      margin-left: 0;
    }
  }

  .detail-table {
    margin-top: 0;
    margin-bottom: variables.$space-lg;
    margin-left: variables.$space-lg;
    margin-right: variables.$space-lg !important;

    .detail-row {
      border-bottom: 1px solid variables.$grey100;
    }

    .row-label {
      font-size: variables.$font-size-extra-small;
      font-weight: variables.$font-weight-regular;
      color: variables.$grey500;
      line-height: variables.$line-height;
    }

    .row-value {
      margin-left: variables.$space-xl;
      font-weight: variables.$font-weight-regular;
      line-height: variables.$line-height;
      color: variables.$grey900;
    }

    .status-value {
      font-weight: variables.$font-weight-bold;
    }

    td {
      padding-left: variables.$space-sm;
      padding-right: 0;
      padding-top: variables.$space-md;
      padding-bottom: variables.$space-md;
    }
  }

  .check-table {
    line-height: variables.$line-height;

    .pill-label {
      margin-top: variables.$space-sm;
    }

    .check-result-line {
      padding: 16px 32px;

      &:not(:last-of-type) {
        border-bottom: 1px solid variables.$grey100;
      }
    }
  }

  .back-arrow {
    top: 25px;
    left: 40px;

    .popup {
      left: 30px;
      top: 0;
    }
  }

  .loading-overlay {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    bottom: -200px;
  }
}
