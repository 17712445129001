@use "../core/variables";

.flowing-external-link {
  color: variables.$blue500;
}

.external-link {
  display: flex;
  width: fit-content;

  color: variables.$blue500;

  div {
    margin-left: 6px;
    color: variables.$blue500;
  }
}
