@import "../core/variables";

.user-avatar-list {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .user-avatar-list-item {
    img,
    .icon-user {
      box-shadow: 0 0 0 2px $white;
    }

    + .user-avatar-list-item {
      margin-left: -4px;
    }
  }

  .more-text {
    margin-left: 10px;
    font-weight: $font-weight-regular;
  }
}
