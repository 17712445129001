@use "../../../_common/style/core/variables";

.full-page-modal.v2 .modal-content-container {
  .shared-profile-edit-evidence-modal {
    width: 700px;
    padding-top: 10px;

    .description {
      p:last-of-type {
        margin-bottom: 12px;
      }

      button {
        margin-bottom: 22px;
      }
    }

    .edit-grid {
      display: flex;
      flex-direction: column;

      .edit-grid-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 8px;
        padding-bottom: 8px;
        min-height: 48px;

        &.header-row {
          div {
            color: variables.$grey500;
            font-weight: variables.$font-weight-bold;
            font-size: variables.$font-size-smaller;
            padding-bottom: 14px;
            border-bottom: 1px solid variables.$grey100;
            min-height: 36px;
          }
        }

        .category-col {
          $category-col-width: 190px;

          width: $category-col-width;
          flex-grow: 0;

          .ug-select {
            width: $category-col-width - 16px;

            .ug-select__control {
              min-width: $category-col-width - 16px;
            }
          }
        }

        .url-col {
          flex-grow: 1;

          .url-text-field {
            margin-bottom: 0;
            margin-right: 16px;

            .text-field-additional {
              min-height: 0;
            }
          }
        }

        .actions-col {
          width: 30px;
          flex-grow: 0;
        }

        .add-evidence-btn {
          width: 100%;

          button {
            width: 100%;
            border: 1.5px dashed variables.$grey200;
          }
        }
      }
    }
  }
}
