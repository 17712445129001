@use "../core/variables";

.new-feature-popup-content {
  .description {
    padding: 5px 0;
  }

  .learn-more-link .cr-icon-arrow-right {
    display: inline;
    vertical-align: -1px;
  }
}
