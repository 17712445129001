@import "../../../_common/style/core/variables";

.risk-mini {
  .risk-header {
    display: flex;
    border: 2px solid $grey100;
    border-radius: 8px;
    padding: 16px;
    margin-top: 24px;
    align-items: center;
    margin-bottom: 24px;

    .adjusted-severity-icon {
      margin-right: 16px;
    }

    .risk-text-container {
      padding-right: 24px;

      .risk-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        font-weight: $font-weight-bold;
        margin-bottom: 4px;
      }

      .risk-cat-title {
        color: $grey500;
        max-width: 400px;
      }
    }

    .asset-count {
      margin-left: auto;
      position: relative;
      font-weight: $font-weight-bold;
    }
  }
}
