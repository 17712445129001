@use "../core/variables";

.timeline-card {
  tr {
    height: unset !important;
  }

  .timeline-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 0;

    .event-type {
      margin-left: 8px;
      margin-top: 2px;
    }

    .time {
      white-space: nowrap;
      width: 75px;
      min-width: 75px;
      margin-right: 10px;
      padding-top: 4px;
    }

    .item-data {
      display: flex;
      flex-direction: column;
      line-height: 150%;

      & > * + * {
        margin-top: 10px;
      }

      .user-avatar {
        display: inline;
        vertical-align: -7px;
        margin-right: 5px;
      }

      .status-update {
        display: flex;
        flex-direction: column;
        .status {
          display: flex;
          flex-direction: row;
          align-items: center;

          > * + * {
            margin-left: 10px;
          }

          .cr-icon-arrow-right {
            color: variables.$grey500;
          }
        }
        .justification {
          margin-top: 10px;
          span {
          }
        }
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        color: variables.$grey500;

        li {
          display: flex;
          gap: 5px;
        }

        li + li {
          margin-top: 10px;
        }
      }
    }
  }
}
