@import "../../../_common/style/core/variables";

.risk-summary-table {
  .chevron-cell {
    padding-left: $space-xl;
  }

  .table-filters {
    display: flex;
    align-items: center;
    padding: 24px 24px 0 24px;

    .search-box-input-container {
      flex-grow: 1;
      margin-right: 16px;
    }

    .toggle-with-label-outer + .toggle-with-label-outer {
      margin-left: 16px;
    }

    .multi-selection-button.options {
      z-index: 11; // 1 more than ActionBar
    }
  }

  .expanded-content-cell {
    background-color: unset !important;
  }

  &.changes-mode {
    .description-cell {
      padding-right: 0;

      .description-text {
        padding-right: 20px;
      }
    }
  }

  th {
    color: $grey500 !important;
    text-wrap: nowrap;
  }

  .header {
    .toggle-switch {
      margin-left: 15px;
    }

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;

      font-weight: normal;
      font-size: 18px !important;
      flex: 1;

      .toggle-label {
        font-size: 14px;
        line-height: 150%;
      }
    }

    .btn + .btn,
    .btn + .manage-risk-button {
      margin-left: 16px;
    }

    .tab-buttons-wrapper {
      min-width: 265px;

      .tab-buttons-scroller {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .remediation-summary {
    align-items: center;
    background: $lighter-blue;
    border-radius: 8px;
    column-gap: 20px;
    display: flex;
    height: 48px;
    margin: 20px 32px 0;
    padding: 0 20px;

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
    }

    .risk-counts {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      margin-left: auto;
      text-align: left;
    }

    .navigation {
      .btn-icon {
        &:not(:hover) {
          .cr-icon-chevron {
            color: $grey300;
          }
        }
      }
    }
  }

  .key-risk-cell {
    width: 74px;
    text-align: left;

    .btn-icon {
      &.disabled {
        opacity: 1;
      }
    }

    .cr-icon-shortlist-active {
      fill: $blue500;
      color: $blue500;
    }
  }

  .severity-cell {
    width: 48px;
    text-align: left;
  }

  .finding-cell {
    min-width: 160px;
    padding-top: 10px;
    padding-bottom: 10px;

    .finding {
      font-weight: normal;
      display: flex;
      flex-direction: row;
      align-items: center;

      .pill-label {
        margin-left: 10px;
      }
    }
  }

  .category-cell {
    width: 260px;
    white-space: nowrap;

    .category-cell-labels {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  $sites-cell-width: 128px;
  $toggle-cell-width: 60px;

  .risk-cell {
    color: $grey500;
  }

  .sites-cell {
    width: $sites-cell-width;

    .sites-cell-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;
      white-space: nowrap;

      &.waived {
        font-style: italic;
        color: $grey400;
      }
    }

    .pill-label {
      margin-left: 10px;
    }
  }

  .detected-cell {
    width: 0;
    white-space: nowrap;
    padding-right: 30px !important;

    &.waived {
      font-style: italic;
      color: $grey400;
    }
  }

  th:first-of-type.severity-col {
    width: 115px;
  }

  .expanded-content-cell {
    padding-left: 115px !important;
    padding-right: 40px !important;
    background-color: $canvas;
    line-height: 150%;

    .risk-expanded-content {
      display: flex;
      justify-content: space-between;

      .risk-detail {
        padding-right: 60px;

        .failed-entities-container {
          margin-bottom: 24px;

          .questionnaire-selection {
            max-width: 700px;
          }
        }
      }

      .risk-actions {
        padding-top: 21px;
      }
    }
  }

  .clickable:hover .icon-chevron {
    color: $blue;
  }

  tr.passed td {
    opacity: 0.5;
  }

  .no-risks {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-style: italic;
    color: $medium-grey;
  }

  .risk-assessment-comment-readonly {
    margin-left: 80px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .risk-assessment-comment-readonly.with-key-risks {
    margin-left: 95px;
  }
}
