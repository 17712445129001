@use "../core/variables";
@use "../core/cr_icons_constants";

.password-revealer {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  border-radius: 4px;
  padding-left: 4px;

  &:hover {
    background-color: variables.$blue100;
  }

  .eye-btn {
    padding-left: 2px;
    width: 24px;
    height: 24px;
    font-size: 18px;

    display: flex;
    align-items: center;

    color: variables.$grey500;
  }
}
