@import "../../core/variables";

.remediation-request-details-v2 {
  .remediation-details-summary-card {
    .header {
      .text-field {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 0;
        input {
          font-size: 18px;
          &:not(:hover):not(:focus) {
            border: 1px solid #fff;
          }
        }
      }
      &.editable-title {
        padding-left: 21px;
      }
    }

    .header-right {
      .dropdown-v2 {
        margin-left: 0 !important;
      }
    }
    .info-table {
      td {
        padding-top: 16px;
        padding-bottom: 0;
      }
      tr:first-of-type td {
        padding-top: 24px;
      }
      tr:last-of-type td {
        padding-bottom: 24px;
      }
      td.row-value-cell .row-value {
        font-weight: 400;
      }
      .edit-date-button .btn-icon {
        width: 16px;
        height: 16px;
        .cr-icon-pencil-2 {
          font-size: 20px;
        }
      }
    }
    .content {
      display: flex;
      align-items: flex-start;
      gap: 16px;
    }
    .shared-with-row {
      .row-value {
        max-height: unset;
        flex-direction: column;
        align-items: flex-start;
      }

      .shared-with-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .user-avatar-and-name:not(:first-of-type) {
          margin-top: 16px;
        }
      }

      .btn {
        margin-top: 16px;
        white-space: nowrap;
      }
    }

    .shared-documents .row-value {
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }

    .btn-icon-popup {
      padding-left: 8px;
    }

    .info-banner {
      margin: 0 24px 24px;
      width: unset;
    }

    .assessment-info {
      color: inherit;
      font-weight: $font-weight-regular;
      line-height: 21px;
      font-size: $font-size;
      margin: unset;
      cursor: pointer;

      .bold {
        color: inherit;
        font-weight: $font-weight-bold;
        line-height: 21px;
        font-size: $font-size;
      }

      .icon-arrow {
        color: inherit;
        font-weight: $font-weight-bold;
        margin-left: 8px;
      }
    }

    .info-banner-wrapper {
      padding: 24px 32px 0;
      .info-banner {
        margin: 0;
      }
    }
  }
}
