@use "../core/variables";

.survey-reminder-modal {
  min-width: 600px;
  max-width: 800px !important;

  .reminder {
    display: flex;
    flex-direction: row;
    align-items: center;

    & + .reminder {
      margin-top: 10px;
    }

    .reminder-date {
      width: 135px;
    }

    .pill-label {
      margin-left: 16px;
    }

    .btn {
      margin-left: auto;
    }
  }

  .schedule-section {
    align-items: flex-start;

    > * + * {
      margin-top: 16px !important;
    }
  }
}
