@use "../../core/variables";

.dropdown-v2 {
  $border-radius: 6px;

  display: inline-block;
  position: relative;
  font-size: variables.$font-size;
  font-weight: variables.$font-weight-regular;

  .popup-target {
    .btn {
      .cr-icon-chevron {
        // Special styles for a dropdown button
        transform: rotate(90deg) translateX(1px);
        margin-left: 10px;
        margin-right: 0;
        vertical-align: 2px;
      }
    }
  }

  &:not(.disabled) .popup-target .pointer {
    cursor: pointer;
  }

  .popup-content {
    position: absolute;
    z-index: 2;
    background-color: variables.$white;
    box-shadow:
      0px 10px 15px rgba(64, 70, 104, 0.1),
      0px 4px 6px rgba(64, 70, 104, 0.05);
    border-radius: $border-radius;

    top: calc(100% + 10px);
    right: 0;

    &.left {
      right: unset;
      left: 0;
    }

    &.bottom {
      top: unset;
      bottom: calc(100% + 10px);
    }

    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    h3 {
      display: block;
      padding: 12px 16px 6px 16px;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: variables.$grey500;
      white-space: nowrap;
    }
  }

  .dropdown-item {
    white-space: nowrap;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;

    [class^="cr-icon-"],
    [class*=" cr-icon-"] {
      font-size: 20px;
      width: 20px;
      margin-right: 10px;
      color: variables.$grey400;
    }

    .cr-icon-archive {
      font-size: 22px;
    }

    .cr-icon-info {
      font-size: 18px;
      transform: translateX(-1px);
    }

    &:hover {
      cursor: pointer;
      background-color: variables.$blue50;
    }

    &:hover,
    &.selected {
      &,
      & > * {
        color: variables.$blue500;
      }
    }
    &.disabled {
      color: variables.$grey200 !important;
      cursor: default;

      div {
        color: variables.$grey200 !important;
      }

      [class^="cr-icon-"],
      [class*=" cr-icon-"] {
        color: variables.$grey200;
      }
    }
  }

  .popup-content > :first-child {
    &,
    .dropdown-item {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  .popup-content > :last-child {
    &,
    .dropdown-item {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .dropdown-separator {
    margin: 0;
    padding: 0;
    background-color: variables.$grey100;
    height: 1px;
  }

  .sub-menu-item {
    position: relative;
    justify-content: space-between;

    .cr-icon-chevron {
      font-size: 12px;
      margin-left: 20px;
      margin-right: 0;
      width: auto;
    }

    .popup-content {
      top: 0;
      left: 100%;
      right: unset;

      &.left {
        left: unset;
        right: 100%;
      }
    }
  }

  .dropdown-autoscroll {
    overflow-y: auto;
    overflow-x: visible;
  }
}

.dropdown-button {
  /* Styling for icon button */
  &:hover,
  &.dropdown-active {
    .btn-icon {
      background-color: variables.$blue100;
      color: variables.$blue500;

      * {
        color: variables.$blue500;
      }
    }
  }

  /* Styling for tertiary button */
  &.btn-tertiary {
    padding: 0 6px 0 6px;

    &:hover,
    &.dropdown-active {
      background-color: variables.$blue100;

      .btn-inner {
        color: variables.$blue500;

        * {
          color: variables.$blue500;
        }
      }
    }
  }

  /* styling for primary button */
  &.btn-primary {
    &:hover,
    &.dropdown-active {
      background-color: variables.$blue600;

      .btn-inner {
        color: variables.$white;

        * {
          color: variables.$white;
        }
      }
    }
  }

  .icon-chevron {
    margin-left: 2px;
    transition: all ease-in-out 150ms;
  }
}
