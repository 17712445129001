@use "../../_common/style/core/variables";

.modal-content-container:has(.update-compliance-badges-modal) {
  .modal-header p,
  .modal-content p {
    margin-bottom: 0 !important;
  }

  .modal-content {
    padding: 0 variables.$space-xl variables.$space-lg !important;
  }
}

.update-compliance-badges-modal {
  gap: variables.$space-lg;

  .sub-title {
    color: variables.$grey600;
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-regular;
    line-height: variables.$line-height;
  }

  .checkbox-big-icon {
    .checkbox-big-icon-circle {
      border-radius: unset;
      background-color: unset;

      > img {
        width: 40px;
      }
    }
  }

  .compliance-badges {
    max-width: 800px !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-column-gap: variables.$space-md;
    grid-row-gap: variables.$space-md;
    align-content: start;
    padding-right: variables.$space-md;

    height: 600px;
    overflow-x: hidden;

    .checkbox-big {
      width: 160px;
      height: 130px;

      &:has(.checked) {
        border: 2px solid variables.$blue500;
      }
    }
  }
}
