@use "../../../_common/style/core/variables";

.pivot-tabs {
  .pivot-tabs-buttons-wrapper,
  > .tab-buttons {
    margin: 20px 0px;
  }

  .pivot-tab-panel {
    &:not(.active) {
      display: none;
    }

    .pivot-tab-desc {
      margin-bottom: 30px;
      p {
        color: variables.$grey500;
        line-height: 21px;
      }
    }
  }
}
