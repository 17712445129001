@use "../../_common/style/core/variables";

.threat-monitoring-bulk-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .num-selected {
    color: variables.$grey900;
    font-size: 14px;
    font-weight: normal;
  }

  .btn-group {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .btn {
      margin-left: 14px;
    }
  }
  .cr-icon-add-bookmark {
    font-size: 22px;
  }
  .cr-icon-cancel {
    font-size: 20px;
  }
}
