@use "../../../_common/style/core/variables";

.generic-add-risk-modal {
  .expandable-question-node {
    border-radius: 4px;
    border: 1px solid variables.$grey100;

    .question-inner {
      background: variables.$grey50;
      max-height: 160px;
      overflow: hidden;

      &.expanded {
        max-height: unset;
      }
    }

    .expander {
      height: 32px;
      text-align: center;
      line-height: 32px;
      color: variables.$grey500;
      letter-spacing: 1px;
      font-size: variables.$font-size-smaller;
      cursor: pointer;
      border-top: 1px solid variables.$grey100;

      i {
        line-height: 32px;
        margin-left: 4px;
        font-size: variables.$font-size-smaller;
        text-align: center;
      }

      &:hover {
        background: variables.$blue50;
      }
    }

    .question-container {
      padding: 16px;
    }
  }
}
