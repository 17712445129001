@use "../../_common/style/core/variables";

#free_trial_register {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: variables.$white;

  .trial-sub-header {
    margin-top: variables.$space-sm;
    margin-bottom: variables.$space-lg;
    font-size: variables.$font-size-larger;
  }

  .free-trial-left {
    height: 100vh;
    width: 400px;
    max-width: 400px;
    min-width: 400px;
    color: variables.$white;
    background-color: variables.$blue900;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;

    .img-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 300px;
      margin-left: 20px;
      margin-right: 20px;

      align-items: flex-start;
      border-bottom: 1px solid variables.$grey500;
      padding-bottom: 30px;
    }

    .free-trial-nav {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0px;

      .container-header {
        flex: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 125%;
        color: variables.$white;
      }

      .container-footer {
        flex: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: variables.$grey300;
      }

      .container-middle {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .container-content {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-top: 16px;

          p {
            margin-top: 0px !important;
            margin-bottom: 24px !important;
          }

          div,
          p {
            color: variables.$white;
          }

          .checklist {
            & > div {
              before: unset;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              margin-left: 0px;
              position: relative;
              font-size: 16px;
              color: variables.$grey300;
              line-height: 24px;
              margin-top: 0px;

              img {
                margin-right: 10px;
              }

              span {
                padding-top: 5px;
                color: inherit;
              }

              & + div {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }

  .free-trial-right {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;

    .free-trial-main {
      height: 100%;
      max-height: 80vh;
      width: 100%;
      padding: 0 30px;
      margin: auto;

      display: flex;
      flex-direction: column;
      align-items: center;

      // .free-trial-main-content {
      //   max-width: 500px;
      // }

      .signup {
        max-width: 500px;
        margin: auto;

        input {
          border-radius: 4px;
        }

        .ug-select {
          input[type="text"]:focus {
            box-shadow: none !important;
          }
        }
      }

      .hidden {
        height: inherit;
      }

      .booking {
        max-width: 560px;
        height: 100%;
      }

      .ug-logo {
        width: 150px;
      }

      h2 {
        font-size: variables.$font-size-xx-large;
        line-height: 30px;
        font-weight: variables.$font-weight-bold;
        padding: 0;
        text-align: center;
      }

      a {
        text-transform: none;
      }

      .body-copy {
        margin-top: 12px;
        text-align: center;
      }

      .thankyou {
        max-width: 500px;
        margin: auto;
        text-align: center;
      }

      & > p {
        margin-top: variables.$space-xl;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 150%;
      }

      .calendar-container {
        height: 80%;

        iframe {
          height: 100%;
          width: 100%;
          border: 0;
          overflow: hidden;
        }
      }

      .button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: variables.$space-xl;

        .next-button {
          margin: 0px 0px 0px auto;
          width: 120px;
        }

        .submit-button {
          margin: 0px;
          width: 160px;
        }

        .skip-button {
          width: 120px;
          margin: 0;
        }

        .back-button {
          margin: 0px auto 0px 0px;
          width: 120px;

          .cr-icon-arrow-right {
            margin-left: 0px;
            margin-right: 6px;
          }
        }
      }

      .description {
        font-size: 16px;
        color: variables.$grey500;
        line-height: 125%;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      .field-label {
        font-size: 14px;
        color: variables.$grey500;
        line-height: 125%;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      .other-title {
        font-size: 14px;
        color: variables.$grey500;
        line-height: 125%;
        margin-bottom: 10px;
        margin-top: 20px;
      }

      .field-with-icon {
        position: relative;

        img {
          width: 20px;
          position: absolute;
          z-index: 1;
          left: 14px;
          top: 10px;
        }

        input {
          font-size: 16px;
          height: 40px;
          padding-left: 45px;
          border-radius: 6px;
        }
      }

      .info-banner {
        margin-top: 20px;
      }

      .ug-select {
        margin-bottom: 20px;
      }

      button {
        height: 40px;
        font-size: 16px;

        .cr-icon-arrow-right {
          font-size: 22px;
        }
      }

      .button-bar {
        padding: 0px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: variables.$space-xl;

        .email-button {
          width: 180px;
          height: 44px;
          padding: 6px 12px 6px 10px;
          margin-top: 0px;
          border: 1px solid variables.$blue500;
          border-radius: 4px;
          color: variables.$blue500;
          font-size: variables.$font-size;
          font-weight: variables.$font-weight-bold;
          line-height: variables.$line-height;

          &:hover {
            background-color: variables.$blue50;
          }

          img {
            margin-right: 10px;
          }
        }

        .email-button-right {
          margin-left: 20px;
        }
      }
    }

    .trial-footer {
      margin-top: 30px;
      text-align: flex-start;
      line-height: 150%;
      font-size: variables.$font-size;
      font-weight: 500;
      color: variables.$grey600;
    }
  }

  // GT: this is for later finessing of the display for handheld...
  @media (orientation: portrait) {
    .free-trial-left {
      display: none;
    }

    .trial-footer {
      margin-left: 0px !important;
    }
  }

  @media (orientation: landscape) {
    .free-trial-right {
      .ug-logo {
        display: none;
      }
    }
  }
}
