@use "../../../_common/style/core/variables";

.risk-panel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.risk-panel {
  .risk-flex {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    opacity: 1;
    max-width: 100%;

    &.hidden {
      width: 0px;
      opacity: 0;
      overflow: hidden;
    }
  }

  .title-and-button {
    padding: 0 40px 24px 40px;

    .risk-severity {
      align-items: center;
      display: flex;
    }

    h2 {
      padding-bottom: 0px;
      max-width: 340px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px !important;
    }

    .button-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      min-width: 270px;
      width: 270px;
      margin: 0px;
      margin-right: 5px;
      height: 20px;
      position: relative;
    }

    display: flex;
    flex-direction: row;
    min-height: 40px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .scrollable-div {
    .scrollable-content {
      padding-bottom: 80px;
    }
  }

  .check-item {
    @keyframes flash-in {
      0% {
        background: variables.$light-grey;
      }
      100% {
        background: none;
      }
    }

    .section-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: inherit;
      font-weight: inherit;

      .dismissable-popup-container {
        margin-left: 10px;
      }
    }

    .slide-panel-section {
      .section-body {
        padding-left: 40px;
        padding-bottom: 0;
        padding-bottom: 24px !important;

        p {
          margin-top: 0;
          margin-bottom: 0;
          line-height: 150%;
          color: variables.$grey900;
        }

        .saas-risk-remediation {
          & > p:first-of-type {
            margin-bottom: variables.$space-sm;
          }
        }
      }

      .check-description {
        color: variables.$grey500;
        margin-bottom: 24px;
        line-height: 150%;
      }
    }
  }
}
