@use "../../../_common/style/core/variables";

.scrollable-div {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: -10px;
  margin-right: -10px;

  &.new-styles {
    margin: 0;
  }

  .scrollable-shadow {
    position: absolute;
    left: -10px;
    top: 0;
    right: -10px;
    height: 30px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(251, 249, 249, 0) 100%
    );
    box-shadow: 0px -1px 0 0px rgba(69, 69, 69, 0.1);
    pointer-events: none;

    &.new-styles {
      left: 0;
    }
  }

  .scrollable-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 80px;

    &.new-styles {
      padding: 0;
    }
  }
}
