@import "../core/variables";

.free-trial-meeting-modal {
  width: 600px;
  min-height: 850px !important;
  padding-bottom: 0px !important;

  h2 {
    width: 100%;
    text-align: center !important;
  }

  .thankyou {
    margin-top: 12px;
    text-align: center;
  }

  .description {
    padding-left: 73px;
    padding-right: 73px;
    font-size: 14px;
    color: $grey500;
  }

  & > p {
    margin-top: 32px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 150%;
  }

  .calendar-container {
    height: 610px;
    padding-top: 0px;

    .meetings-iframe-container {
      height: 580px;
    }
    .completed {
      margin-top: 20px !important;
      height: 430px !important;
    }
  }
}

.free-trial-meeting-modal-shrunk {
  height: 275px !important;
}
