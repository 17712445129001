@use "../../../_common/style/core/variables";

.view-vendor-risk-waiver-panel {
  .subsidiary-label-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .subsidiary-name {
    font-weight: variables.$font-weight-bold;
  }
}
