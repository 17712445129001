@import "../../../../_common/style/core/variables";

.score-type-selector {
  .dropdown-item {
    height: unset;
    width: 350px;
    padding: 16px;
  }
  .score-type-dropdown-item-content {
    display: flex;
    flex-direction: column;

    .color-checkbox {
      .label {
        font-weight: $font-weight-bold;
      }
    }

    p {
      margin: 0 10px 0 30px;
      font-size: 14px;
      color: $grey500;
      line-height: 150%;
      white-space: normal;
    }
  }
}
