@import "../../_common/style/core/variables";

.userbase-scores-chart {
  .top-container {
    padding: 35px 20px 15px 20px;
    display: flex;
    flex-direction: row;
    min-height: 48px;
    justify-content: space-between;

    .score-container {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      .date {
        position: absolute;
        top: -25px;
        left: 0;
        white-space: nowrap;
      }

      .score {
        margin-left: 10px;
      }

      .cstar-text {
        font-size: 40px;

        .score-text {
          min-width: 0;
        }
      }
    }

    .legend-container {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      font-size: 12px;
      min-width: 130px;

      & > div + div {
        margin-top: 5px;
      }

      .legend-box {
        border-bottom-width: 2px;
        border-bottom-style: solid;
        display: inline-block;
        margin-right: 5px;
        width: 15px;
        transform: translateY(-4px);
      }
    }
  }
}
