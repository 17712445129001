@import "../../../../_common/style/core/variables";

.evidence-used-card {
  .main-section {
    padding: 24px 32px;

    .evidence-section {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      width: 100%;

      .evidence-header {
        color: $grey900;
        font-weight: $font-weight-bold;
        font-size: $font-size-larger;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .scanning-inner {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        .right-section {
          margin-left: auto;
          margin-right: $space-sm;
        }
      }

      .blue-section {
        background: $blue50;
        border-radius: 8px;

        .x-table {
          tr {
            height: 60px;
          }

          thead {
            tr {
              th {
                border-bottom: 1px solid $blue200;

                &:first-of-type {
                  padding-left: 24px;
                }

                &:last-of-type {
                  padding-right: 24px;
                }
              }
            }
          }

          tbody {
            border-top: unset;
          }

          tr:not(:last-of-type) {
            border-bottom: 1px solid $blue200;
          }

          td {
            border-top: unset;

            &:first-of-type {
              padding-left: 24px;
            }

            &:last-of-type {
              padding-right: 24px;
            }
          }
        }
      }
    }

    .top-section {
      display: flex;
      flex-direction: row;
      width: 100%;

      .evidence-section:not(:last-of-type) {
        margin-right: 24px;
      }

      .blue-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 24px;
        min-height: 48px;
        height: 48px;

        .dismissible-popup-container {
          margin-left: auto;
        }
      }
    }
  }
}
