@import "../../_common/style/core/variables";

.threat-timeline {
  padding: 8px 0;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .date {
    font-weight: $font-weight-bold;
    font-size: $font-size-smaller;
    color: $grey400;

    padding: 16px 32px;
    border-bottom: 1px solid $grey100;

    border-top: 1px solid $grey100;
  }

  .day.first {
    .date {
      border-top: none;
    }
  }

  .threat-event-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    padding: 16px 32px;
    gap: 16px;

    font-weight: $font-weight-regular;
    font-size: $font-size;

    .occurred-at {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80px;

      /* try to line up with the user avatar */
      padding-top: 7px;
    }

    .action {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tm-user-and-avatar {
        flex: none;
        flex-direction: row;
        gap: 0;

        * {
          font-weight: $font-weight-bolder;
        }

        &:after {
          content: "\00a0";
        }
      }

      .user-avatar-and-name {
        &:after {
          content: "\00a0";
        }
        .user-name {
          .name-and-info {
            font-weight: $font-weight-bolder;
          }
        }
      }

      .col {
        display: flex;
        flex-direction: column;

        gap: 8px;
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .action {
          display: inline;
        }

        &.state-change {
          gap: 4px;

          .pill-label div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2px;

            font-weight: $font-weight-regular;

            .icon {
              width: 16px;
              height: 16px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              .chevron {
                font-size: 8px;
              }
            }
          }

          .icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            width: 24px;
            height: 24px;
            .cr-icon-arrow-right {
              color: $grey900;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}
