@use "../../_common/style/core/variables" as core-variables;
@use "../styles/variables";

.tm-feed-table-ellipsised {
  min-width: max-content;
}

.tm-feed-with-table {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 16px;

  &.hidden {
    display: none;
  }

  .x-table.threat-monitoring-table table {
    table-layout: fixed;
    width: 100%;

    tr {
      background-color: transparent;
      height: unset;
    }

    thead {
      height: variables.$threat-monitoring-view-header-height;
      max-height: variables.$threat-monitoring-view-header-height;
    }

    th {
      height: variables.$threat-monitoring-view-header-height;
      max-height: variables.$threat-monitoring-view-header-height;
      padding-top: 16px;
      padding-bottom: 8px;
    }

    td {
      border-top: 1px solid core-variables.$grey100;
    }

    th,
    td {
      &:first-of-type {
        padding-left: 24px;
      }
    }
    th,
    td {
      &:last-of-type {
        padding-right: 24px;
      }

      &:not(:last-of-type) {
        padding-right: 16px;
      }
    }

    .hover-border {
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: core-variables.$blue50;
        color: core-variables.$blue500;
      }
    }

    /* common columns */
    th.severity {
      /*
       * This is an eye-balled hack to deal with the severity column being too narrow to accommodate the arrow.
       */
      .icon-arrow {
        right: 2px;
        top: 50%;
        margin-top: -4px;
      }
    }
    th.comments {
      width: calc(66px + variables.$tm-feedtable-inner-padding);
    }
    th.actions {
      width: calc(60px + variables.$tm-feedtable-outer-padding);
    }

    .comments-cell {
      font-family: inherit;
      text-align: left;

      /*
       * override the existing styling of the CorrespondenceButton.
       * We reset padding and margin of the children and
       * use flex with gap: 4px instead.
       */
      .correspondence-icon-btn button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 4px;
        gap: 4px;

        * {
          padding: 0;
          margin: 0;
        }
        .cr-icon-chat-messages {
          width: 16px;
          height: 16px;
          font-size: 16px;
        }

        .attention-icon {
          width: 8px;
          height: 8px;
          max-width: 8px;
          max-height: 8px;
          border-radius: 4px;
          border: 4px solid core-variables.$red400;
        }

        .btn-text {
          font-size: 12px;
        }
      }
    }

    .actor-cell {
      .user-avatar-and-name {
        width: 100%;
        min-width: 100px;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .user-name {
          flex: 0 1 max-content;
          overflow: hidden;

          display: flex;
          flex-direction: column;

          .name-and-info {
            flex: 0 1 max-content;
            min-width: 0px;
            display: inline;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .system-avatar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .circled-icon {
          background-color: core-variables.$white;

          min-width: 24px;
          max-width: 24px;
          width: 24px;

          height: 24px;
          min-height: 24px;
          max-height: 24px;

          margin-right: 6px;

          div {
            color: core-variables.$grey900;
            border-color: core-variables.$grey900;
            border: 1px;
            border-radius: 50%;
            border-style: solid;
            padding: 4px;
          }
          .cr-icon-upguard-logo-pick {
            font-size: 16px;
          }
        }
        .cr-icon-upguard-logo-pick {
          color: core-variables.$grey900;
          background-color: core-variables.$white;
        }
      }
    }

    .date-cell {
    }

    .action-cell {
      cursor: pointer;
      text-align: right;
      .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .cr-icon-undo1 {
          font-size: 22px;
          padding-top: 4px;
          padding-bottom: 4px;
          margin-bottom: 2px;
          color: core-variables.$grey500 !important;
        }
        .empty-action {
          width: 24px;
          min-width: 24px;
        }
        .loader-icon {
          margin-top: 2px;
          margin-right: 8px;
        }

        .chevron-container {
          min-width: 28px !important;
          min-height: 28px !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          color: core-variables.$grey500;

          .cr-icon-chevron {
            font-size: 14px !important;
            align-self: center;
            color: inherit;
          }
        }
      }
    }
  }
}
