@import "../../_common/style/core/variables";

.userbase-admin {
  .two-grid {
    padding-top: $space-md;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    & > div + div {
      margin-top: 0;
    }

    & + div {
      margin-top: 20px;
    }

    &.no-stretch {
      align-items: flex-start;
    }
  }

  .report-card {
    margin-bottom: 20px;
  }
  button {
    margin-right: 10px;
  }

  .buttons-row {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;

    .dismissible-popup-container {
      display: inline-block;
    }
  }
}
