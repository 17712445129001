@import "../../../_common/style/core/variables";

.document-link {
  width: 100%;
  min-height: 50px;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid $grey100;
  background-color: #fff;

  .color-checkbox {
    width: min-content;
  }

  .filename {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 8px;
  }
}
