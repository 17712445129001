@use "../../../_common/style/core/variables";

.full-page-modal.v2 .modal-content-container {
  .app-approval-modal {
    width: 800px;
    padding: 0 variables.$space-xl variables.$space-lg;
    gap: 24px;
    max-width: inherit;
    margin: 0;
    line-height: 150%;

    .description {
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: variables.$grey500;

      p {
        margin-bottom: 0;
      }
    }

    .header-desc {
      color: variables.$grey900;
      font-weight: 500;
    }

    .header-subdesc {
      color: variables.$grey500;
      font-weight: 400;
    }

    .approve {
      padding-top: 32px;
      padding-bottom: 32px;
      border-top: 1px solid variables.$grey100;
      border-bottom: 1px solid variables.$grey100;

      display: flex;
      flex-direction: row;
      gap: 24px;

      .header {
        min-width: 250px;
        max-width: 250px;
        padding-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .options {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex-grow: 1;
        min-width: 462px;
        max-width: 462px;

        .partially-approve-box {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .partially-approve-subbox {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .approve-multiselect-box {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }

        .approve-box-indent {
          margin-left: 28px;
        }
      }

      .teams {
        margin-left: 28px;
        max-height: 250px;
        overflow-y: scroll;

        .color-checkbox:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }

      .approval-summary {
        display: flex;
        flex-direction: row;
        gap: 8px;

        border: 1px solid variables.$blue500;
        padding: 8px 12px;
        background-color: variables.$blue50;
        border-radius: 4px;

        .approval-popup-link {
          position: relative;

          span {
            color: variables.$blue500;
            font-weight: 700;
          }

          span:after {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px dotted variables.$medium-grey;
          }
        }

        .summary-icon {
          color: variables.$blue500;
          font-size: 20px;
        }

        .summary-text {
          display: flex;
          flex-direction: row;
          gap: 4px;
          color: variables.$blue500;
        }
      }
    }

    .do-not-approve {
      white-space: pre;
      margin-top: -12px;
      margin-bottom: 0;

      .impacted {
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .two-col-note {
      display: flex;
      flex-direction: row;
      gap: 24px;

      .header {
        width: 250px;
        padding-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .note-input {
        flex-grow: 1;
      }
    }

    .one-col-note {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .header {
        width: 250px;
        display: flex;
        flex-direction: column;
      }

      .note-input {
        flex-grow: 1;
      }
    }
  }
}

.full-page-modal.v2 .modal-content-container .app-approval-modal-footer {
  .btn-align-left {
    margin-right: auto;
  }
}
