@use "../../_common/style/core/variables";
@use "../../_common/style/core/cr_icons_constants";

.full-page-modal.v2 .modal-content-container {
  &.welcome-modal-keywords {
    max-width: 600px;
    min-width: 600px;

    .modal-header {
      border-bottom: 0.75px variables.$grey200 solid;
    }
    .modal-close {
      display: none;
    }
    .modal-content {
      /* compensate for the left/right border
       * The design doesn't consider the width, and we're short on space.
       */
      padding: 24px 31px;
    }
    .body {
      min-height: 244px;
      width: 100%;

      .info-banner.keywords-info {
        .info-banner-line {
          gap: 8px;

          .cr-icon-info {
            margin-right: 0px;
          }

          .message {
            flex-direction: column;
            align-items: flex-start;
            margin-right: 0;

            * {
              color: variables.$blue500;
            }

            .sub {
              font-weight: variables.$font-weight-regular;
            }
          }

          .info-banner-actions {
            display: none;
          }
        }
      }

      .keywords {
        display: flex;
        flex-direction: column;

        .keywords-table {
          min-width: 0;
          flex: 1 1 0;

          table {
            min-width: 0;
            flex: 1 1 0;

            table-layout: fixed;

            th,
            td {
              padding-left: 0;
              padding-right: 0;
            }

            th:first-of-type,
            td:first-of-type {
              padding-left: 0;
            }

            th:not(:last-of-type),
            td:not(:last-of-type) {
              padding-right: 16px;
            }
            th.keyword {
              width: calc(230px + 16px);
            }

            .pill-label {
              font-weight: variables.$font-weight-regular;
              letter-spacing: 0;
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      flex-direction: row;
      width: 100%;
      .buttons {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
