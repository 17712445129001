@use "../../_common/style/core/variables";

.slide-panel-kv-table {
  .slide-panel-table-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    min-height: 45px;
    padding: 16px 0;
    border-bottom: 1px solid variables.$grey100;

    &:last-child {
      border-bottom: none;
    }

    .slide-panel-table-title {
      width: 200px;
      min-width: 200px;
      color: variables.$grey400;
      font-size: 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}
