@use "sass:list";
@use "../../../_common/style/core/variables";

.color-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 2px;

  .dismissable-popup-click-capture {
    display: flex;
    align-items: center;
  }

  .color-checkbox-inner {
    display: flex;
    align-items: center;
    margin-right: 8px;

    outline: none;
    &:focus-visible {
      box-shadow: 0 0 0 3px variables.$blue100;
      border-color: variables.$blue300;
      border-radius: 2px;
    }

    &.clickable {
      cursor: pointer;

      &:hover .label {
        color: variables.$blue;
      }
    }

    &.disabled {
      cursor: not-allowed;

      .box {
        opacity: 0.5;
        background-color: variables.$light-grey;
      }

      &:hover .box {
        background-color: variables.$light-grey;
        box-shadow: inset 0 0 0 1px variables.$medium-grey;
      }
    }

    .box {
      min-width: 20px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: variables.$white;
      position: relative;
      box-shadow: inset 0 0 0 1px variables.$light-grey;
      transition: all 250ms ease-in-out;
    }

    &:hover,
    &.checked,
    &.indeterminate {
      .box {
        background-color: rgba(variables.$blue, 0.05);
        box-shadow: inset 0 0 0 1px variables.$blue;
      }
    }

    &:hover .box {
      background-color: rgba(variables.$blue, 0.15);
    }

    @each $pair in variables.$label-colors {
      $name: list.nth($pair, 1);
      $color: list.nth($pair, 2);
      &.#{$name} {
        .box {
          box-shadow: inset 0 0 0 1px $color;
        }

        &:hover .box {
          background-color: rgba($color, 0.15) !important;
          box-shadow: inset 0 0 0 1px $color !important;
        }

        &.radio .box {
          box-shadow: inset 0 0 0 1px variables.$light-grey;
        }

        &.radio:hover .box {
          box-shadow: inset 0 0 0 1px $color;
        }

        &.checked,
        &.indeterminate {
          &.radio {
            .box {
              box-shadow: inset 0 0 0 1px $color !important;
              background-color: variables.$white;
            }

            .box:before {
              background-color: $color;
            }
          }

          .box {
            background-color: rgba($color, 0.05);
            box-shadow: inset 0 0 0 1px $color !important;
          }
        }

        &.checked .box:after {
          color: $color;
        }

        &.indeterminate .box:after {
          background-color: $color;
        }
      }
    }

    &.checked .box:after {
      content: "\E902";
      font-family: "ug_icons" !important;
      color: variables.$blue;
      position: absolute;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &.indeterminate .box:after {
      content: "";
      position: absolute;
      display: block;
      background-color: variables.$blue;
      top: 9px;
      bottom: 9px;
      left: 4px;
      right: 4px;
      border-radius: 2px;
    }

    &.small {
      .box {
        min-width: 15px;
        width: 15px;
        height: 15px;
      }

      &.checked .box:after {
        font-size: 12px;
        line-height: 17px;
      }

      &.indeterminate .box:after {
        top: 6px;
        bottom: 6px;
      }
    }

    .label {
      white-space: nowrap;
      flex: 1;
      margin-left: 8px;
    }

    .ellipsis .label {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.radio {
      .box {
        border-radius: 50%;
      }

      &.checked .box:after {
        display: none;
      }

      &.checked .box:before {
        content: "";
        position: absolute;
        left: 5px;
        right: 5px;
        bottom: 5px;
        top: 5px;
        border-radius: 50%;
        background-color: variables.$blue;
      }
    }
    &.round {
      .box {
        border-radius: 50%;
        &:hover {
          background-color: variables.$white;
          box-shadow: inset 0 0 0 1px variables.$light-grey;
        }
      }

      &.checked .box:after {
        display: none;
      }

      &.checked .box:before {
        content: "\E902";
        font-family: "ug_icons" !important;
        color: variables.$white;
        background-color: variables.$blue;
        border-radius: 50%;
        position: absolute;
        font-size: 12px;
        line-height: 21px;
        text-align: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

/* We'll apply this by default if width not specified for popup content */
.checkbox-popup-content-auto-width {
  width: max-content !important;
  max-width: 150px;
}
