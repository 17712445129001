@use "../../_common/style/core/variables";

#content_library_document {
  .content-library-document-card {
    .content-library-document-card-main {
      display: flex;
      flex-direction: column;
    }

    .header {
      .header-title {
        display: flex;
        flex-direction: row;
        align-items: center;

        .pill-label {
          margin-left: 12px;
        }
      }

      .header-icon {
        width: 32px;
      }
    }

    .main-panes {
      display: grid;
      grid-template-columns: 1fr 1fr;

      > div {
        padding: 30px;

        + div {
          border-left: 1px solid variables.$grey100;
        }
      }

      .info-table-row {
        .row-value {
          .text-field,
          .date-picker {
            flex: 1;
          }
        }
      }
    }
  }

  .btn-inner {
    .cr-icon-export-thin {
      font-size: 18px;
      margin-right: 8px;
    }

    .cr-icon-upload {
      font-size: 15px;
      margin-right: 8px;
    }
  }
}
