@use "../core/variables";

.info-table {
  width: 100%;
  border-collapse: collapse;

  .no-max-height > td {
    max-height: unset;
  }

  td {
    padding: 20px;
    max-height: 56px;

    &.row-label {
      padding-left: 32px;
      vertical-align: top;
      width: 1px;
      white-space: nowrap;
    }

    &.row-value-cell {
      padding-right: 32px;
      .row-value {
        font-weight: variables.$font-weight-bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        &.middle {
          justify-content: center !important;
          height: 40px;
        }
      }

      input:not(.ug-select *) {
        width: 100%;
      }

      &.middle {
        align-items: center !important;
      }
    }

    &.third-col {
      width: 1px;
      white-space: nowrap;

      a {
        text-transform: none !important;
        text-decoration: underline;

        &:hover {
          color: variables.$blue700;
        }
      }
    }

    .row-chevron {
      width: 1px;
      white-space: nowrap;
    }

    &:empty {
      padding: 0;
    }

    &.single-col {
      padding: 20px 0;

      .row-label {
        margin-bottom: 8px;
      }
    }
  }

  &.bordered tr:not(:first-child) {
    border-top: 1px solid variables.$grey100;
  }

  &.bordered-first-row tr:first-child {
    border-top: 1px solid variables.$grey100;
  }

  tr {
    &.clickable:hover {
      background-color: variables.$lighter-blue;
    }
    &.label-middle {
      .row-label {
        vertical-align: middle;
      }
    }

    &.has-text-field {
      .row-label {
        padding-top: 28px;
        padding-bottom: 12px;
      }

      .text-field {
        flex-grow: 1;
      }
    }
  }

  .color-grade {
    margin-right: 5px;
  }

  .label-list-container {
    z-index: 13;
    font-weight: variables.$font-weight-regular;
  }

  .ug-select {
    width: 100%;

    &.static-editable {
      // Try to line up icons with other items in the table
      width: calc(100% - 9px);
    }
  }

  .ug-select__menu {
    z-index: 14;
  }

  .ug-select__control {
    min-width: 0;
  }

  input:not(.ug-select *) {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .light {
    font-weight: variables.$font-weight-regular;
    color: variables.$grey500;
  }

  &.new-styling {
    .info-table-row {
      .row-label {
        padding-left: 0;
        color: variables.$grey500;
        font-weight: variables.$font-weight-bold;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 200%;
        letter-spacing: 1px;
      }

      .row-value {
        padding-right: 0;
        font-size: 14px;
        line-height: 150%;
        font-weight: variables.$font-weight-regular;
      }
    }
  }

  &.bold-labels {
    .info-table-row {
      &:first-of-type td {
        padding-top: 0;
      }

      td {
        padding: 12px 0;
        line-height: 150%;
      }

      .row-label {
        font-weight: variables.$font-weight-bold;
      }

      .row-value {
        margin-left: 30px;
        font-weight: unset;
      }
    }
  }

  .info-table-additional {
    td {
      padding: 0px 20px 10px 30px !important;
      div {
        font-size: 13px !important;
      }
      .progress-bar {
        margin-top: 5px;
        margin-bottom: 15px;
      }
    }
    width: 100%;
    border: none !important;
    margin: none;
  }
}
