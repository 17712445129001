@use "../../../_common/style/core/variables";

.shared-profile-logo {
  .company-logo {
    margin-right: 10px;
  }

  .circle-logo {
    border: 1px solid variables.$grey50;
  }

  .custom-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 16px;

    .circle-logo {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      background-color: variables.$white;
      background-size: contain;
      background-position: center;
      display: block;
      background-repeat: no-repeat;
    }
  }

  .company-label {
    padding-left: 10px;
  }
}
