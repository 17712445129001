@import "../../../../_common/style/core/variables";

.new-assessment-stream-modal {
  width: 600px;

  .field-label {
    color: $grey900;
    font-size: $font-size;
    font-weight: $font-weight-bold;
    line-height: 22px;

    &:after {
      content: " *";
      vertical-align: super;
      font-size: 80%;
      color: $red400;
    }
  }

  .text-field {
    margin-bottom: 16px !important;
  }

  .desc {
    color: $grey800;
    font-size: $font-size;
    font-weight: $font-weight-regular;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .title {
    .field-label {
      margin-bottom: 8px;
    }
  }
}

.scope-selection {
  max-width: 600px;

  .title {
    .field-label {
      margin-bottom: 8px;
    }
  }

  .field-label {
    color: $grey900;
    font-size: $font-size;
    font-weight: $font-weight-bold;
    line-height: 22px;

    &:after {
      content: " *";
      vertical-align: super;
      font-size: 80%;
      color: $red400;
    }
  }

  .desc {
    color: $grey800;
    font-size: $font-size;
    font-weight: $font-weight-regular;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .tag-selection {
    margin-top: 16px;

    .textarea-with-pills {
      height: 5em;
      width: unset;
    }

    .instructions {
      color: $grey600;
      font-size: $font-size-smaller;
      font-weight: $font-weight-regular;
      line-height: 18px;
      margin-top: 8px;
    }
  }

  .selection-label {
    i {
      color: $blue500;
      line-height: 24px;
      font-size: 24px;
    }
  }

  .input-with-icon {
    border: solid 1px $grey200;
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;

    &.selected {
      border-radius: 8px;
      border: 1px solid $blue300;
      background: $blue50;
    }

    .color-checkbox .color-checkbox-inner {
      width: 100%;
      .label {
        white-space: unset;
        display: flex;
        flex-direction: row;
        margin-left: 12px;
      }
    }

    .selection-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;

      .text-label {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        flex-grow: 1;

        .label-top {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .label-right {
            color: $blue500;
            font-size: $font-size-extra-small;
            font-weight: $font-weight-bold;
            line-height: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
          }

          .label-title {
            color: $grey900;
            font-size: $font-size;
            font-weight: $font-weight-bold;
            line-height: 22px;
          }
        }

        .sub {
          color: $grey800;
          font-size: $font-size;
          font-weight: $font-weight-regular;
          line-height: 22px;
        }
      }
    }
  }
}
