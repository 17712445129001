@use "../../../../_common/style/core/variables";

.shared-assessment-side-panel {
  .slide-panel.new-styles {
    width: 480px;
  }

  .shared-assessment-table {
    .author-cell-div {
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        margin-right: 16px;
      }

      .company-logo > .company-label {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .access-cell {
      color: variables.$grey500;
    }
  }
}
