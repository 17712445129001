@use "../../_common/style/core/variables";

#impersonate-org-modal {
  form {
    label,
    .select-user,
    .select-reason,
    .custom-reason {
      display: block;
      width: 100%;
      margin-top: variables.$space-sm;
    }

    label ~ label {
      margin-top: variables.$space-lg;
    }
  }

  .btn-group {
    margin-top: variables.$space-xl;
  }
}
