@import "../../../_common/style/core/variables";

.userbase-users-table {
  .table-filters {
    .search-box-input-container {
      padding: 24px 32px 0;
    }
  }

  .score-container {
    display: flex;
    align-items: center;

    .color-grade {
      margin-right: 10px;
    }

    .score {
      white-space: nowrap;

      .cstar-text {
        .score-text {
          min-width: 0;
        }
      }
    }
  }

  .slide-panel {
    width: 700px !important;
  }

  // Unset the background for the slide panel
  .unset-background {
    background-color: unset;
  }

  .users-list {
    .user-name-col {
      width: 40%;
    }

    .user-role-col {
      width: 20%;
    }
    .user-team-col {
      width: 20%;
    }

    .user-rating-col {
      width: 10%;
    }
  }

  .user-apps-slide-panel {
    .slide-panel.new-styles .title {
      border-bottom: none;
    }
  }
}
