@use "../../../_common/style/core/variables";

.trend-score {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .trend-score-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .negative-trend {
    color: variables.$red500;
  }

  .positive-trend {
    color: variables.$green500;
  }

  .no-change-trend {
    color: variables.$grey500;
  }
}
