@use "../../../_common/style/core/variables";

.new-user-modal,
.edit-user-modal {
  .roleText {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  label[for="roles"] {
    margin-bottom: 20px !important;
  }

  .roleOption {
    margin-top: 10px;
    display: flex;
    flex-direction: row;

    .label {
      text-align: left;
    }
  }

  .gapBelow {
    margin-bottom: 20px;
  }

  .input-action-container {
    display: flex;

    label {
      width: 100px;
    }
  }

  .roleDesc {
    margin-left: 20px;
    font-style: italic;
    text-align: left;
    width: 500px;
  }

  .disabled {
    opacity: 0.5;
  }
}
