@import "../core/variables";

$dot-size: 6px;

.cr-dot {
  display: inline-block;
  position: relative;
  width: $dot-size;
  height: $dot-size;
  border-radius: 50%;
  vertical-align: middle;

  &.green {
    background-color: $green500;
  }

  &.orange {
    background-color: $orange500;
  }

  &.red {
    background-color: $red500;
  }

  &.blue {
    background-color: $blue500;
  }

  &.grey {
    background-color: $grey500;
  }

  & + * {
    margin-left: 6px;
  }

  * + & {
    margin-left: 6px;
  }
}
