@import "../core/variables";

.button-group {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  & > .btn:first-child,
  & > div:first-child .btn {
    border-top-left-radius: $component-border-radius;
    border-bottom-left-radius: $component-border-radius;
  }

  & > .btn:last-child,
  & > div:last-child .btn {
    border-top-right-radius: $component-border-radius;
    border-bottom-right-radius: $component-border-radius;
  }

  & > * + .btn,
  & > * + div .btn {
    margin-left: -1px;

    &:disabled {
      border-left: none;
    }
  }

  .btn {
    border-radius: 0px;
  }

  .btn-filled-primary {
    border-left: 1px solid $blue700;
  }

  & > .btn-filled-primary:first-child,
  & > div:first-child .btn-filled-primary {
    border-left: none;
  }

  .popup-target .btn .cr-icon-chevron {
    margin-left: 0;
  }
}
