@use "../core/variables";

.add-recipients-modal {
  width: 800px;

  // Fix a class conflict between the form grid and the IconButton
  .form-grid {
    grid-column-gap: 80px;

    .left {
      padding-right: unset;
    }
  }

  .original-emails {
    .pill-label {
      margin-bottom: 8px;
      text-transform: lowercase;
    }
  }

  .label-list {
    margin-bottom: 32px;
  }

  .new-recipients {
    .contact-selector {
      .form-section {
        padding: 0;
        .new-contacts-section {
          margin-top: 0;
        }
      }
    }
  }

  .add-recipient {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .form-contents {
      flex: 1;
      max-width: 500px;

      .name-fields {
        display: flex;
      }

      .text-field {
        width: 100%;
        margin-top: 0;
      }

      .half-input {
        display: inline-block;
        width: calc(50% - 5px);

        + .half-input {
          margin-left: 10px;
        }
      }
    }

    .btn-icon {
      margin-left: 10px;
    }

    & + * {
      margin-top: 15px;
    }
  }

  .add-vendor-contacts {
    .ug-select__menu {
      z-index: 20;
    }
  }
}
