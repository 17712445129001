@import "../core/variables";

.activity-feed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.reversed {
    flex-direction: column-reverse;
  }

  .activity-feed-pre {
    padding: 16px 0;
  }

  .activity-feed-group {
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    border-top: 1px solid $grey100;

    .avatar-col {
      width: 32px;
      margin-right: 12px;

      .user-avatar {
        max-height: unset;

        .avatar {
          width: 32px;
          height: 32px;
          margin: 0;
        }
      }
    }

    .items-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-self: center;

      .activity-item {
        + .activity-item {
          margin-top: 12px;
        }

        .activity-item-head {
          line-height: 125%;

          .datetime {
            display: inline-block;
            margin-left: 12px;

            span {
              font-size: 12px;
              color: $grey500;
            }
          }

          + * {
            margin-top: 8px;
          }
        }

        .activity-item-comment {
          color: $grey500;
          white-space: pre-line;
          line-height: 125%;
        }

        .activity-feed-item-status-change {
          display: flex;
          flex-direction: row;
          align-items: center;

          > * + * {
            margin-left: 8px;
          }

          .cr-icon-arrow-right {
            font-size: 20px;
            color: $grey300;
          }
        }
      }
    }
  }
}
