@import "../../../_common/style/core/variables";

.toggle-with-label-outer:not(.greyed) {
  cursor: pointer;
}

.toggle-with-label {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover .toggle-switch:not(.disabled) > label {
    border: 1px solid $blue;
  }

  .toggle-switch {
    pointer-events: none;
    margin-right: $global-padding * 0.5;
    min-width: 34px;
  }

  .toggle-label {
    color: $grey900;
    &.disabled {
      color: $grey300;
    }
  }
}
