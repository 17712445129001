@import "../core/variables";

.check-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;

  > .ambivalent {
    color: $blue500;
    font-size: 18px;
  }

  &.filled {
    background-color: $blue50;
    border: 1px solid $blue500;

    &.checked {
      background-color: $blue500;
      > .cr-icon-check {
        color: white;
        font-size: 12px;
      }
    }
  }

  &.checked {
    > .cr-icon-check {
      color: $grey500;
      font-size: 12px;
    }
  }

  &.disabled {
    background-color: white;
    border: 1px solid $grey200;
  }
}
