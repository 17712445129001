@import "../../core/variables";

.x-table {
  position: relative;
  width: 100%;

  .loading-spinner {
    opacity: 0;
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: $card-border-radius;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.loading > .loading-spinner {
    opacity: 1;
    pointer-events: all;
  }

  &.sticky {
    z-index: 0;

    /* NOTE:
       We don't want these styles to apply to a non-.sticky XTable within a .sticky XTable
    */
    & > table > thead > tr > th {
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: 2;

      &.selection-box {
        position: sticky;
      }

      &:after {
        content: "";
        pointer-events: none;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        height: 1px;
        background: $light-grey;
      }
    }

    .pagination-row {
      border-bottom: 0;
    }

    .pagination-row > td {
      position: sticky;
      bottom: 0;
      background-color: $white;
      z-index: 1;

      &:after {
        content: "";
        pointer-events: none;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        height: 1px;
        background: $light-grey;
      }
    }
  }

  table {
    width: 100%;
    padding-left: $global-padding;
    border-collapse: collapse;

    th {
      border-bottom: 1px solid $light-grey;
    }

    td {
      border-top: 1px solid $lighter-grey;
      padding-top: 0;
      padding-bottom: 0;

      div.placeholder-value {
        font-weight: 400;
        color: #5d6598;
      }
    }

    tr:last-of-type {
      // Avoid 1px jump whenever the last row is hovered over
      border-bottom: 1px solid transparent;
    }

    th,
    td {
      text-align: left;

      &.sortable {
        cursor: pointer;

        .icon-arrow {
          visibility: hidden;
        }

        &.left-align {
          text-align: left !important;
        }

        &:hover,
        &.sort-active {
          .icon-arrow {
            visibility: visible;
          }
        }
      }

      &:first-of-type {
        padding-left: 32px;

        &.loading:after {
          left: 32px;
        }
      }

      &:not(:last-of-type) {
        padding-right: $global-padding;

        &.sortable {
          padding-right: $global-padding + 15px;
        }

        &.with-help-text {
          padding-right: $global-padding + 30px;
        }
      }

      &:last-of-type {
        padding-right: 32px;
      }
    }

    th {
      position: relative;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      font-weight: $font-weight-bold;
      color: $medium-grey;
      padding-top: 32px;
      padding-bottom: 12px;

      .dismissible-popup-container {
        margin-left: 5px;
        display: inline-block;
        text-transform: none;

        span {
          color: $medium-grey;
        }

        .dismissable-popup {
          cursor: auto;
          z-index: 1;

          i {
            visibility: visible !important;
          }
        }
      }

      .icon-arrow {
        position: absolute;
        margin-left: 5px;
        color: $medium-grey;
        font-weight: $font-weight-bold;

        transition: transform ($animation-timing * 0.5) ease-in-out;
      }

      &.with-help-text .icon-arrow {
        margin-left: 20px;
      }
    }

    tbody tr {
      &:not(.expanded-content-row) {
        height: 72px;
      }

      &.expanded-content-row {
        border-bottom: 0px;
      }

      &.extra-content-row {
        border-bottom: 0px;
      }

      &.always-show-cell-content > td.show-content-on-hover,
      &:hover > td.show-content-on-hover {
        opacity: 1;
      }

      td.expand-cell {
        width: 60px;
        padding: 0;
        text-align: center;

        &:not(.disabled) {
          cursor: pointer;
          border-left: 1px solid #fafafa;
        }

        &:hover:not(.disabled) {
          background-color: rgba($blue, 0.05);
        }

        .icon-chevron {
          transition: all 0.25s;
        }
      }

      td.expanded-content-cell {
        padding: 20px 0;
        transition: padding 250ms ease-in-out;

        &.collapsed {
          padding: 0;
        }

        border-top: none;
      }

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: rgba($blue, 0.05);

          td {
            border-top: 1px solid rgba($blue, 0.05);
          }
        }

        &:hover + .extra-content-row {
          background-color: rgba($blue, 0.05);
        }
      }

      td.extra-content-cell {
        padding-bottom: 24px;
        border-top: none;
      }

      td.show-content-on-hover {
        transition: opacity 100ms ease-in-out;
        opacity: 0;
      }

      &.selected {
        background-color: $blue25;

        &.clickable:hover {
          background-color: rgba($blue, 0.1);
        }

        td {
          border-top: 1px solid rgba($blue, 0.05);
        }

        &:not(.expanded) td {
          border-bottom: 1px solid rgba($blue, 0.05);
        }
      }
    }

    &.selectable {
      .selection-box {
        position: relative;
        width: 0.1%;

        .color-checkbox {
          pointer-events: auto;

          .box {
            margin-right: 0;
          }
        }

        .selection-dropdown {
          position: relative;
          padding: 4px 17px 4px 4px;
          margin: -4px -3px -4px -4px;
          cursor: pointer;
          border-radius: 2px;
          pointer-events: auto;

          &:hover {
            // background-color: rgba(76,199,238,0.1);
            box-shadow: 0px 0px 0px 1px $blue;
          }

          .icon-chevron {
            position: absolute;
            color: $medium-grey;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            right: 5px;
          }
        }

        .dropdown-menu.open {
          pointer-events: all;
        }
      }

      &.anySelected .selection-box,
      tr:hover .selection-box,
      .selection-box:hover {
        .checkbox-container {
          opacity: 1;
        }
      }

      .meatball-cell {
        padding-right: $global-padding * 2 !important;
      }
    }

    .meatball-cell {
      $meatball-size: 32px;
      width: $meatball-size;

      .icon-meatball {
        width: $meatball-size;
        height: $meatball-size;
        text-align: center;
        line-height: $meatball-size;
        transition: box-shadow ($animation-timing * 0.5) ease-in-out;

        &:hover {
          border-radius: 2px;
          box-shadow: inset 0 0 0 1px $medium-grey;
        }
      }
    }

    .options-icons-cell {
      width: 0.1%;
      white-space: nowrap;
      text-align: right;
    }

    td.loading {
      position: relative;

      &:first-of-type:before {
        left: 20px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 20px;
        left: 0;
        right: 20px;
        bottom: 20px;
        background-color: $light-grey;
        animation-name: color;
        animation-duration: 2s;
        animation-iteration-count: infinite;

        @keyframes color {
          0% {
            background-color: $light-grey;
          }
          50% {
            background-color: $canvas;
          }
          100% {
            background-color: $light-grey;
          }
        }
      }
    }
  }

  // Apply this class to any cell that should shrink as small as possible without wrapping text.
  .shrink-cell {
    white-space: nowrap;
    width: 1px;

    &:not(:last-child) {
      padding-right: 20px !important;
    }
  }

  &.compact {
    table {
      thead {
        th:first-of-type {
          padding-left: 8px;
        }
        th:last-of-type {
          padding-right: 0;
        }
      }

      tbody tr {
        &:not(.expanded-content-row) {
          height: 48px;
        }
      }

      td:first-of-type {
        padding-left: 8px;
      }

      td:last-of-type {
        padding-right: 0;
      }
    }
  }

  // To get text overflow ellipsis working, apply this class to a cell that has a fixed width (in px or %)
  // and put any text in a span in the cell.
  .ellipsis-cell {
    position: relative;

    &:before {
      content: "\a0"; // Non-breaking space
      visibility: hidden;
    }

    & > span,
    & > div {
      position: absolute;
      display: inline-block;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
    }
  }
}
