@use "../../../_common/style/core/variables";

.edit-custom-notification-modal-footer {
  .delete-button {
    margin-right: auto;
  }
}

.edit-custom-notification-modal {
  width: 700px;

  .selected-type {
    margin-bottom: 16px;
  }

  .params-entry-container {
    .params-entry {
      display: flex;
      flex-direction: row;
      align-items: center;

      .number-field {
        width: 5em;
        margin-right: 1em;
      }

      * + .number-field {
        margin-left: 1em;
      }
    }

    .number-error {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: variables.$red500;
      line-height: 14px;
      font-size: 11px;
      font-weight: variables.$font-weight-regular;

      .number-error-message {
        color: variables.$red500;
        font-size: 11px;

        &::first-letter {
          text-transform: capitalize;
        }
      }

      i {
        margin-right: 0.2em;
      }
    }
  }

  .severity-select-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-top: 16px;

    .ug-select__control {
      min-width: unset;
    }

    .ug-select__indicator-separator {
      display: none;
    }

    .ug-select {
      margin-left: 2em;
      margin-right: 2em;
      width: 200px;
    }
  }

  .set-notification-conditions {
    margin-top: 16px;
  }
}
