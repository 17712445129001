@import "../../../_common/style/core/variables";

.app-permissions {
  .search-box-input-container {
    padding: 24px 32px 0;
  }

  .description-with-subtext {
    line-height: 1.3;

    .description-main-text {
      font-size: $font-size;
    }

    .description-subtext {
      font-size: $font-size-smaller;
      color: $grey500;
    }
  }

  .x-table {
    td.expanded-content-cell {
      padding: 0;
    }
  }
}
