@use "../../../_common/style/core/variables";

.vuln-assets-affected-container {
  display: flex;
  flex-direction: column;
  padding-top: variables.$space-sm;
  padding-bottom: variables.$space-sm;
  background-color: variables.$white;

  .x-table table thead tr th {
    padding-left: 0px;
    position: unset !important;
  }

  .x-table tbody > tr:last-child > td {
    border-bottom: 0px !important;
  }

  .x-table table tbody tr:last-of-type {
    border-bottom: 0px !important;
  }

  .x-table table {
    border-bottom: 0px !important;
  }

  .x-table table tbody {
    border-bottom: 0px !important;
  }

  .x-table table th:first-of-type,
  .x-table table td:first-of-type {
    padding-left: 0px !important;
  }

  .x-table table th:last-of-type,
  .x-table table td:last-of-type {
    padding-right: 0px !important;
  }

  .x-table table tbody tr:not(.expanded-content-row) {
    height: 48px;
  }

  .x-table th {
    padding-top: 0;
    vertical-align: middle;
  }

  .clickable:not(.pill-label) {
    padding-left: 0px !important;
  }

  .asset-cell {
    color: variables.$blue500;
    text-decoration: underline;

    &:hover {
      color: variables.$blue !important;
      text-decoration: none;
    }
  }

  .right {
    margin-left: 20px;
  }
}
