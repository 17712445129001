@import "../../../_common/style/core/variables";

.userbase-about-user {
  .content {
    min-height: 270px;

    .info-table {
      tbody {
        tr > td {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }

      .row-label {
        padding-right: 60px;
        vertical-align: middle;
        font-weight: $font-weight-bold;
      }

      .row-value-cell {
        .row-value {
          font-weight: $font-weight-regular;
        }
      }

      .section-header {
        .row-label {
          font-size: $font-size-larger;
          padding-top: 20px;
        }
      }

      .bottom-border {
        border-bottom: $grey100 1px solid;
      }
    }
  }
}
