@use "../../core/variables";

.card-row {
  display: flex;
  width: 100%;

  .card {
    flex-basis: 0;
    flex-grow: 1;
  }

  .card:not(:last-child) {
    margin-right: variables.$global-padding * 2;
  }
}

.card {
  background-color: variables.$white;
  border-radius: variables.$card-border-radius;
  margin-bottom: variables.$global-padding * 2;

  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  .header {
    padding: variables.$card-padding;

    a {
      float: right;
      text-decoration: none;
      font-size: 0.6rem;
      letter-spacing: 0.2px;
      line-height: 1rem;
      text-transform: uppercase;
    }
  }

  .card-content {
    flex-grow: 1;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      border-top: 1px solid variables.$light-grey;
    }

    & > div {
      padding: variables.$card-padding;

      &:not(:first-of-type) {
        border-left: 1px solid variables.$light-grey;
      }
    }

    &.bare {
      border-top: none;

      & > div {
        border: none !important;
      }
    }
  }

  .loading-image {
    width: 100%;
    margin: auto;
    max-height: 120px;

    animation: pulse 3s infinite;

    @keyframes pulse {
      0% {
        opacity: 0.7;
      }
      50% {
        opacity: 0.2;
      }
      100% {
        opacity: 0.7;
      }
    }
  }

  .loading-overlay {
    opacity: 0;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity variables.$animation-timing;

    &.show {
      opacity: 1;
      pointer-events: all;
    }

    .loading-tight {
      height: 100%;
    }
  }

  .card-error {
    .card-content {
      flex-direction: column;
      justify-content: space-around;
      padding: variables.$card-padding;

      & > div {
        padding: variables.$global-padding 0;
        text-align: center;
        color: variables.$medium-grey;
        font-size: 1.2rem;
        font-weight: variables.$font-weight-regular;
        font-style: italic;
      }

      & > button {
        margin: variables.$global-padding;
      }
    }
  }
}
