@import "../../_common/style/core/variables";

/* Override some global container styles when on this page */

#main-content-area-inner:has(> .public-trust-page-preview-view) {
  padding: 0 !important;
  max-width: unset !important;
}

#main-content-area:has(.public-trust-page-preview-view) {
  padding: 0 !important;
  border-top-left-radius: 0 !important;
}

.public-trust-page-preview-view {
  .public-trust-page {
    max-width: 1600px;
  }

  /* Disable action buttons when public trust page content is shown this way */
  .request-access-btn {
    pointer-events: none;
  }

  .qn-btn .btn-icon {
    pointer-events: none;
  }
}
