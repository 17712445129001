@use "../../../_common/style/core/variables";

.modify-resend-date-modal {
  max-width: 700px !important;

  .color-checkbox {
    margin-bottom: 20px;
  }
}

.resend-date-modal {
  padding-left: 100px !important;
  padding-right: 100px !important;
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;

  .descriptionText {
    text-align: left;
    margin-bottom: 40px;
    margin-top: 20px;
    width: 100%;
  }

  .scheduleText {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .color-checkbox {
    .label {
      text-align: left;
    }
  }

  .date-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .intervalOption {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  }

  .input-action-container {
    display: flex;
    label {
      width: 100px;
    }
  }

  .top-gap {
    margin-top: 40px;
  }

  .bottom-gap {
    margin-bottom: 20px;
  }

  .date-width {
    width: 40px;
  }

  .disabled {
    opacity: 0.5;
  }

  .intervalDesc {
    display: flex;
    margin-left: 20px;
    font-style: italic;
  }
}
