@use "../../../_common/style/core/variables";

.displaying-users {
  display: flex;
  flex-direction: row;
  gap: variables.$space-sm;
  color: variables.$grey400;
  line-height: variables.$line-height;

  .cr-icon-users-empty,
  .cr-icon-grid {
    color: inherit;
    line-height: inherit;
    font-size: variables.$line-height;
  }
}
