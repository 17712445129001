@import "../../../_common/style/core/variables";

.breachsight-create-risk-waiver-flow {
  .report-card {
    margin-top: 24px;
  }

  .action-bar {
    .actions {
      max-width: $max-page-width;
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      align-items: center;

      button + button {
        margin-left: 10px;
      }
    }
  }

  .info-table {
    .pill-label {
      margin-right: 10px;
    }
  }

  .date-picker-container {
    margin-top: 24px;

    label {
      padding-bottom: 8px;
      color: $grey900;
      font-weight: 500;
    }
  }

  .public-disable-text {
    color: $grey500;
    font-style: italic;
    margin-left: 30px;
    margin-top: 4px;
  }
}
