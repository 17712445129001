@use "../../_common/style/core/variables";

.add-new-questionnaire {
  display: flex;
  flex-direction: column;
  padding-top: variables.$space-md;
  border-top: 1px solid variables.$grey100;

  p {
    line-height: 22px !important;
    padding: variables.$space-md 0;
  }

  .questionnaire-type-selector {
    padding: unset unset variables.$space-sm;

    .survey-sections {
      margin-top: variables.$space-md;
      padding: variables.$space-md variables.$space-md;
      background-color: variables.$grey50;
      border-radius: variables.$card-border-radius;
      border: 1px solid variables.$grey100;

      .name-preview {
        display: flex;
        align-items: center;

        .btn {
          margin-left: auto;
        }
      }

      &.no-sections p {
        margin-bottom: unset;
      }

      .color-checkbox-inner {
        flex-grow: 1;
      }

      .survey-section {
        display: flex;
        align-items: center;

        .btn {
          margin-left: auto;
        }
      }
    }
  }

  .new-questionnaire-details {
    display: flex;
    flex-direction: column;
    gap: variables.$space-md;

    .text-field {
      > input,
      > textarea {
        max-width: unset !important;
      }
    }
  }

  .label {
    font-weight: variables.$font-weight-bold;
    line-height: variables.$line-height;

    .required:after {
      content: "*";
      color: variables.$red500;
    }
  }
}
