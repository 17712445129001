@import "../../../_common/style/core/variables";

.breach-type-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .breach-type-icon {
    min-width: 35px;
    min-height: 35px;
  }
}
