@import "../../../../_common/style/core/variables";

#report_generate {
  .main-content {
    margin-top: 32px;
  }

  .report-delivery-step {
    margin: 24px 32px;

    > .export-config-option + .export-config-option {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $grey100;
    }
  }

  .config-options-card {
    padding: 24px 32px;
  }

  .action-bar {
    .left-side,
    .right-side {
      > * + * {
        margin-left: 16px;
      }
    }

    .left-side {
      margin-right: auto;
    }

    .right-side {
      .btn:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }
}
