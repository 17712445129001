@import "../../../_common/style/core/variables";

.risk-summary-details {
  .risk-expanded-content {
    display: flex;
    flex-direction: column;
    margin-right: 60px;

    .failed-entities-container {
      margin-bottom: 24px;

      .questionnaire-selection {
        max-width: 700px;
      }
    }

    .risk-top-line {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    .risk-detail {
      flex: 1;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .risk-detail-rest {
      width: 100%;
    }

    .risk-actions {
      flex: 0;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
      padding-top: 0px !important;

      .waive-btn {
        min-width: 130px;
      }
      .remediate-btn {
        min-width: 180px;
        margin-left: 16px;
      }
    }
  }

  .description-text {
    color: $medium-grey;
    padding-right: 20px;
  }

  .remediation-link {
    margin-bottom: 20px;
  }

  .cve-link {
    margin-bottom: 10px;
  }

  .cve-mini-title {
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 1em;
    line-height: 20px;
    letter-spacing: 0;
  }

  .description-header {
    font-weight: 500;
    letter-spacing: 0;
    font-size: 1em;
    line-height: 20px;
    //color: $grey900;
    margin-right: 10px;
  }
  .description-content {
    letter-spacing: 0;
    font-size: 1em;
    line-height: 20px;
    font-weight: normal;
    //color: inherit;
  }

  .clickable:hover .icon-chevron {
    color: $blue;
  }

  .failed-entities-list {
    & > div {
      &.clickable {
        cursor: pointer;
        display: flex;
        max-width: 350px;
        justify-content: space-between;
        align-items: center;

        &:hover .entity {
          color: $blue;
        }

        .entity {
          text-decoration: underline;
          text-decoration-style: dotted;
        }
      }

      .entity {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  tr.passed td {
    opacity: 0.5;
  }

  .changes-table {
    width: 100%;
    margin-top: 20px;

    &,
    th,
    th:before,
    th:after {
      background: none !important;
    }

    th:first-of-type,
    td:first-of-type {
      padding-left: 0;
    }

    tr {
      height: auto;

      td,
      th {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      th {
        white-space: nowrap;
      }

      td {
        vertical-align: top;
      }
    }

    thead tr th {
      position: initial !important;

      .cell-relative {
        padding: 0;
      }
    }

    tr.clickable:hover .pre {
      color: inherit;
    }

    .pre {
      font-family: "Andale Mono", AndaleMono, monospace;
      font-size: 11px;
    }
  }
}

.risk-description-details {
  line-height: $line-height;

  .saas-app-link {
    display: inline-block;
    color: $blue;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
}
