@import "../core/variables";

.two-column-display {
  display: flex;
  flex-direction: column;

  > div {
    padding: 24px 0px;
    border-bottom: 1px solid $light-grey;

    > div {
    }
  }

  > div:not(.single-column) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 24px;
  }

  > div.single-column {
  }

  > div:first-of-type {
    padding-top: 0;
  }

  > div:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  .two-column-display-row {
    &.no-bottom-border {
      border-bottom: none;
      padding-bottom: 0;
    }

    .label-container {
      .label {
        .label-content {
          color: $grey900;
          font-weight: $font-weight-bold;
          font-size: 14px;
          line-height: 150%;
          margin-right: 5px;
          display: inline-block;
        }

        .pill-label {
          margin-bottom: 8px;
        }
      }

      p {
        color: $grey500;
        line-height: 1.5;
        margin-bottom: 0px;

        &.no-label {
          margin-block-start: 0;
        }
      }

      p + .btn {
        margin-top: 8px;
      }
    }

    &.single-column {
      .label-container {
        p {
          margin-top: 6px;
          margin-bottom: 18px;
        }
      }
    }
  }
}
