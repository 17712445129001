@use "../core/variables";

.circle-image {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-right: 10px;
  box-shadow: 0 0 0 1px variables.$grey100;
}
