@import "../core/variables";

.hide {
  display: none;
}

.company-logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  .circle-logo {
    border-radius: 50%;
    width: 35px;
    min-width: 35px;
    height: 35px;
    background-color: $white;
    background-size: contain;
    background-position: center;
    display: block;
    background-repeat: no-repeat;
  }

  .company-name {
    font-size: 0.7rem;
    color: $medium-grey;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .company-name-block {
    padding-left: 10px;
    display: flex;
    flex-direction: column;

    .company-category {
      padding-top: 4px;
      color: $label-color;
    }
  }

  .text-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .hover-shadow {
    &:hover {
      box-shadow: 0 0 5px 3px $light-grey !important;
    }
  }

  .coloured-bg {
    background-color: $blue;
    color: $white;
    height: 35px;
  }

  .grey-bg {
    background-color: $light-grey;
    color: $white;
    height: 35px;
  }

  .base {
    width: 35px;
    height: 35px;
  }
}
