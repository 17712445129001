@import "../../../../_common/style/core/variables";

.filter-label {
  .filter-text {
    display: inline-block;
    max-width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
