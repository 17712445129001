@use "../../../_common/style/core/variables";

.filled-icon {
  width: 48px;
  height: 48px;
  background-color: variables.$blue100;
  border-radius: 24px;
  display: flex;
  justify-content: center;

  [class^="cr-icon-"],
  [class*=" cr-icon-"],
  [class^="icon-"],
  [class*=" icon-"] {
    line-height: 48px;
    font-size: 22px;
    color: variables.$blue500;
  }
}
