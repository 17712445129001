@use "../../_common/style/core/variables";

.conditional-visibility-editor {
  width: 700px;
  margin-top: -16px;

  .show-node {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 0;
    border-top: 1px solid variables.$grey100;
    border-bottom: 1px solid variables.$grey100;

    .node-summary-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 12px;
      background-color: variables.$blue50;
      padding: 8px;
      border-radius: 4px;
      color: variables.$blue;
      min-width: 0;

      .node-type-icon {
        margin-right: 12px;
        flex: 0;
        white-space: nowrap;
      }

      .truncate-text {
        color: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
    }
  }

  .ug-select .ug-select__control {
    min-width: unset;
    width: 100%;
  }

  .expr-wrapper {
    padding: 24px 0;
    border-bottom: 1px solid variables.$grey100;
    display: flex;
    flex-direction: row;

    & > .op {
      width: 95px;
      padding-right: 20px;
    }
  }

  .second-lvl-exprs {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > .btn-tertiary {
      margin-top: 16px;
      align-self: flex-start;
    }
  }

  .second-expr-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 24px;
    border-bottom: 1px solid variables.$grey100;

    .delete-icon {
      margin-left: 20px;

      &.top-margin {
        margin-top: 48px;
      }
    }

    & + .second-expr-wrapper {
      margin-top: 24px;
    }

    .second-expr {
      flex: 1;
      display: flex;
      flex-direction: column;

      & > .op > div {
        flex: none !important;
        width: 80px !important;
      }

      & > div {
        display: flex;
        flex-direction: row;

        & + div {
          margin-top: 16px;
        }

        & > div:first-child {
          width: 70%;
          margin-right: 20px;
        }

        & > div:last-child {
          flex: 1;
        }
      }
    }
  }

  .q-selector-opt {
    display: flex;
    flex-direction: row;
    min-width: 0;
    align-items: center;

    .node-type-icon {
      flex-shrink: 0;
      margin-right: 10px;
    }

    .main-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 24px;
    }

    &.selected {
      .main-text {
        color: variables.$white;
      }

      .node-type-icon {
        mix-blend-mode: normal;
      }
    }
  }

  .q-selector {
    .ug-select__single-value {
      top: 50%;
      left: 1px;
    }

    .ug-select__option {
      padding: 4px;
    }
  }
}
