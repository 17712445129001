@import "../../_common/style/core/variables";

#edit_organisation_system_labels_modal {
  .color-checkbox {
    margin-bottom: 5px;
  }

  .btn-primary {
    margin-top: 20px;
  }
}
