@import "../../../_common/style/core/variables";

$mid-blue: #d6e3f5;
$light-red: #fef5f5;
$very-light-grey: #f4f5f5;

.email-exposures {
  display: flex;
  flex-direction: column;
  justify-content: flex-top;
  width: 100%;

  a {
    text-transform: none !important;
  }

  .back-from-breach {
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    color: $blue;

    span {
      color: $blue;
    }
  }

  .loading-breach-details {
    height: 50px !important;
  }

  tr.clickable:hover {
    .date-time-format {
      color: $blue;
    }
  }

  .min-width-table {
    width: 100%;
  }

  // Accounts table columns
  tr {
    .account-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.expanded-breach-row {
      height: auto;

      & > td {
        background-color: $canvas;

        div {
          color: $medium-grey;
        }
      }
    }

    .expanded-breach-name {
      padding-top: 20px;
      padding-bottom: 20px;
      vertical-align: top;
      white-space: nowrap;

      .company-name {
        min-width: 24px;
        width: 24px;
        height: 24px;
        font-size: 7px;
        color: $white;
        line-height: 150%;
      }
    }

    .expanded-breach-detail {
      padding-top: 24px;
      padding-bottom: 24px;

      .expanded-breach-detail-container {
        display: flex;

        .expanded-breach-description {
          flex-grow: 1;
          padding-right: 40px;
          font-size: 12px;
          line-height: 150%;

          a {
            text-decoration: underline;
            color: $medium-grey;
          }
        }

        .expanded-breach-link {
          .btn-inner {
            color: $blue;
          }
        }
      }
    }

    .expand-row-button {
      width: 60px;
      padding: 0 !important;
      text-align: center;
      border-left: 1px solid #fafafa;
    }

    &.archived-breach-row {
      .breach-domain,
      .breach-severity,
      .breach-date,
      .publish-date,
      .breach-published,
      .notified-date,
      .num-involved,
      .breach-type-label {
        opacity: 0.6;
      }

      .breach-data-classes .label-list > .pill-label {
        opacity: 0.6;
      }

      .label-rest-container > .pill-label {
        color: $light-grey;
      }
    }

    &.ignored-account-row {
      td:not(.selection-box) {
        color: $grey300;
      }
    }
  }

  .email-exposures-top-level-filters {
    padding-bottom: 20px;
  }

  .selection-active .checkbox-container {
    opacity: 1 !important;
  }

  .pill-label {
    text-transform: none;
    font-weight: normal;
    font-size: 11px;

    &.ignored-pill-label,
    &.archived-pill-label {
      background-color: $very-light-grey;
      border: 1px solid $very-light-grey;
    }

    &.active-pill-label {
      background-color: $lighter-blue;
      border: 1px solid $lighter-blue;
      color: $blue;
    }
  }

  .notification-sending-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
    top: -1px;
  }

  .notified-icon {
    border-radius: 50%;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
    position: relative;
    top: -1px;

    &.is-notified {
      border: 1px solid $blue;

      span {
        color: $blue;
      }
    }

    &.not-notified {
      border: 1px solid $red;

      span {
        color: $red;
      }
    }

    span {
      line-height: 1.4;
    }
  }

  .exposures-card-container {
    min-height: inherit;
  }

  .selection-action-bar {
    display: flex;
    align-items: center;

    > div {
      padding: 0 10px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    .action-bar-description {
      display: flex;
      flex-direction: column;

      .action-bar-description-main {
        font-weight: $font-weight-bold;

        .action-bar-select-all {
          margin-left: 20px;
        }
      }

      .action-bar-description-detail {
        margin-top: 10px;
      }
    }

    .action-bar-selection-counter {
      flex-grow: 1;
      color: $blue;
      white-space: nowrap;
    }

    .action-bar-buttons {
      display: flex;
    }

    .btn {
      margin-right: 15px;
    }

    .action-bar-close {
      cursor: pointer;
    }
  }

  tr {
    .breach-severity,
    .breach-date,
    .publish-date,
    .breach-published,
    .notified-date,
    .breach-risk,
    .account-date {
      white-space: nowrap;
    }

    .notified-date,
    .account-domain {
      color: $grey500;
    }

    .breach-date,
    .publish-date,
    .account-date {
      span {
        color: $grey500;
      }
    }

    .breach-risk {
      width: 8%;
      max-width: 8%;
    }

    .account-name {
      width: 15%;
      max-width: 15%;
    }

    .account-vip {
      width: 8%;
      max-width: 8%;
    }

    .account-date {
      width: 10%;
      max-width: 10%;
    }

    .web-address-col {
      //font-family: monospace;
      line-height: 150%;
      padding: 12px 0;

      .expand-items {
        position: relative;
        cursor: pointer;

        &:hover {
          color: $blue;

          &:after {
            border-bottom-color: $blue;
          }
        }

        &:after {
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          bottom: -3px;
          border-bottom: 1px dotted $medium-grey;
        }
      }
    }

    .breaches-cell {
      width: 18%;
      max-width: 18%;
    }

    .comments-icon-num {
      white-space: nowrap;
      color: $grey500;

      .cr-icon-chat-messages {
        display: inline;
        margin-left: 4px;
        font-size: 16px;
        vertical-align: -1px;
      }
    }
  }

  .card-title-row {
    border-bottom: 1px solid $light-grey;
    display: flex;
    padding: 20px;

    > div {
      height: 35px;
      align-items: center;
      display: flex;
    }

    .card-title {
      flex-grow: 1;
      font-size: 18px;

      .tab-counter {
        margin-left: 10px;
        height: 32px;
        min-width: 32px;
        border-radius: 40%;
        font-size: 12px;
        letter-spacing: 1px;
        text-align: center;
        padding: 9px 10px;
        background-color: $lighter-blue;
        color: $blue;
        position: relative;
        top: 2px;
      }
    }

    .btn {
      font-size: 12px;
      margin-left: 20px;
    }
  }

  .breach-details-card {
    > div + div {
      border-top: 1px solid $light-grey;
    }

    .header {
      display: flex;

      & + div {
        border-top: none;
      }

      .breach-detail-title {
        flex-grow: 1;

        .company-label {
          font-size: $font-size-extra-large;
          font-weight: $font-weight-bold;
        }
      }

      .comments-icon-btn {
        .cr-icon-chat-messages {
          font-size: 20px;
        }

        .cr-icon-chat-messages,
        .btn-text {
          color: $grey500;
        }

        &:hover {
          .cr-icon-chat-messages,
          .btn-text {
            color: $blue;
          }
        }
      }
    }

    .breach-detail-title-row-info-banner {
      padding: 20px 32px;

      .info-banner {
        margin-bottom: 0;
      }
    }

    .breach-detail-info-row {
      display: flex;
      padding: 0;

      > div {
        padding: 20px 32px;
      }

      .breach-detail-description {
        flex: 1;
        padding-right: 40px;
        line-height: 150%;

        h4 {
          font-size: $font-size-larger;
          font-weight: $font-weight-bold;
          margin-bottom: 8px;
        }
      }

      .breach-detail-info {
        padding-left: 20px;
        border-left: 1px solid $light-grey;
      }

      table {
        width: 100%;

        td {
          padding: 7px 40px 7px 0;
          height: 50px;
          vertical-align: middle;
          width: 1px;
          white-space: nowrap;
          width: 50%;

          &:nth-child(odd) {
            font-weight: $font-weight-bold;
          }

          &:last-child {
            width: unset;
            padding-right: 0;
          }

          .label-list .pill-label {
            margin-bottom: 3px;
          }

          &.breach-detail-info-status {
            padding: 3px 0;
          }
        }

        .assignee-cell {
          padding-top: 0;
          padding-bottom: 0;
        }

        .top-align {
          vertical-align: top;
        }

        // Below is for collapsing table cells 3+ into a new row
        .small-width-only {
          display: none;
        }

        @media screen and (max-width: 1500px) {
          tr.small-width-only {
            display: table-row;
          }

          td:nth-child(n + 3) {
            display: none;
          }
        }
      }
    }

    .breach-detail-search-row .multi-btn {
      flex-grow: 1;
    }
  }

  .search-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-content: center;

    width: 100%;
    padding: 20px 32px 0 32px;
    z-index: 3;

    > div:first-child {
      flex-grow: 1;
    }

    .open-selector-button {
      margin-left: 20px;
    }

    .filter-label,
    .multi-selection-button {
      margin-left: 16px;
    }
  }

  .search-text {
    width: 100%;

    &.disabled {
      color: $red !important;
    }
  }

  .row-right-align {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 20px;
  }

  .row-left-align {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;
  }

  .spreader {
    margin-left: 10px;
  }

  .breach-count-row {
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
  }

  .count-td {
    width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
  }

  .chart-radio {
    margin-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover .radio-circle {
      border-color: $blue;
    }

    .radio-circle {
      position: relative;
      width: 20px;
      height: 20px;
      border: 1px solid $light-grey;
      border-radius: 50%;
      margin-right: 5px;

      &.selected {
        border-color: $blue;
      }

      &.selected:after {
        content: "";
        position: absolute;
        left: 4px;
        top: 4px;
        right: 4px;
        bottom: 4px;
        border-radius: 50%;
        background-color: $blue;
      }
    }
  }

  .paged-table-card {
    box-shadow: none !important;
  }
}

.email-domain-filter {
  margin-left: 10px !important;

  .color-checkbox {
    display: flex;
    flex-direction: row;
  }
}
