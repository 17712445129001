@use "../../_common/style/core/variables";

.survey-viewer-right-panel {
  display: flex;
  flex-direction: column;
  border-left: 1px solid variables.$grey100;
}

.survey-viewer-right-panel-header {
  border-bottom: 1px solid variables.$grey100;
  padding: 24px 32px;
  align-items: center;

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-x {
      font-size: 18px;
    }

    .title-content {
      color: variables.$grey900;
      font-weight: variables.$font-weight-regular;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .beta-label {
        margin-left: 10px;
      }

      .group-back {
        display: flex;
        align-items: center;

        font-size: 14px;
        line-height: 150%;
        padding: 0;
        font-weight: variables.$font-weight-bold;
        color: variables.$grey500;

        button {
          font-size: 14px;
          line-height: 150%;
          padding: 0;
          height: unset;

          .btn-inner {
            letter-spacing: 0;

            i {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .group-title {
    .btn-inner {
      font-size: 16px;
      line-height: 133%;
      white-space: normal;
      text-align: left;

      > div {
        // Limit to 2 lines, despite the browser-specific extensions, should work in all major browsers
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 42px;
        letter-spacing: initial;
        color: variables.$grey900;

        // If node name has HTML inside it, provide some default styling
        p {
          color: variables.$grey600;
        }

        a {
          text-decoration: underline;
          text-transform: none;
        }

        p:first-child {
          margin-top: 0px;
        }
      }

      &:hover {
        > div {
          color: variables.$blue;
        }
      }
    }
  }
}
