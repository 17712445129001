@import "../../../_common/style/core/variables";

.breachsight-close-reason-modal {
  .cols {
    display: flex;
    flex-direction: row;

    .update-radio {
      width: 400px;

      .color-checkbox {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;

        .color-checkbox-inner {
          align-items: flex-start;
        }

        .label {
          display: block;
          word-wrap: break-word;
          white-space: normal;
          text-align: start;
          margin-left: 10px;
        }
      }

      .pill-label-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .pill-desc {
        margin-top: 5px;
      }

      .apply-button {
        width: 100px;
      }
    }
  }

  &.modal-content {
    p {
      text-align: left;
      margin-bottom: 20px;
    }
  }

  .center-text {
    text-align: left;
    font-size: 18px;
    padding-top: 8px;
    margin-bottom: 20px !important;
  }

  button {
    float: right;
    margin-top: 20px !important;
  }

  .button-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 100%;

    .button-cell {
      width: 180px !important;
      min-width: 180px !important;
      max-width: 180px !important;
    }

    .desc-cell {
      margin-left: 10px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: start;
    }
  }
}
