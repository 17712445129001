@use "../../../../_common/style/core/variables";

.reports-library-modal {
  width: 700px;
  padding-bottom: 40px !important;

  @media (max-height: 800px) {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  &.select-portfolio-modal {
    padding: 0 0 24px 0 !important;
  }

  .report-type-selector {
    .report-type-badge {
      .rhs img {
        height: 160px !important;
      }
    }
  }

  .vendor-cell {
    max-width: 256px !important;
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  #report-vendor-selector {
    margin-left: -32px;
    margin-right: -32px;

    @media (max-height: 800px) {
      margin-top: 24px;
    }

    .search {
      margin-top: 0;
      padding-left: 32px;
      padding-right: 32px;
    }

    td:first-child,
    th:first-child {
      padding-left: 32px !important;
    }

    td:last-child,
    th:last-child {
      padding-right: 32px !important;
    }
  }

  .report-delivery-step {
    > .export-config-option + .export-config-option {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid variables.$grey100;
    }
  }
}

.reports-library-modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  padding-bottom: 24px !important;
}
