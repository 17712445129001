@import "../../../_common/style/core/variables";

.monitor-new-vendor-modal-header {
  p {
    margin-bottom: 0px;
  }
}

.monitor-new-vendor-modal {
  width: 500px;
  height: 610px;

  a {
    font-weight: 500 !important;
    color: $grey500 !important;
  }

  h2 {
    font-size: 18px;
    padding-bottom: 32px;
  }

  p {
    color: $grey600 !important;
  }

  .tab-buttons-wrapper {
    margin-top: 0px;
  }
  .search-box-input-container {
    margin-bottom: 18px;
  }

  .vendor-tier-selection {
    border-top: 1px solid $grey100;

    label {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .vendor-tier-selector {
      .ug-select__option {
        display: flex;
      }

      .ug-select__control {
        height: 40px;
      }

      .ug-select__value-container {
        height: 32px;
      }

      .ug-select__single-value {
        top: 50%;
      }
    }
  }
}

.ug-select__option--is-selected .vendor-tier-badge-display .name {
  color: #fff;
}
