@import "../../core/variables";
@import "../../core/cr_icons_constants";

.ug-page-header {
  .page-title-section {
    width: 100%;
    max-width: $max-page-width + 80px;
    padding: 24px 40px;
    display: flex;
    flex-direction: column;

    .breadcrumbs {
      margin-bottom: 10px;
    }

    .page-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      h2 {
        padding: 0 !important;
        font-weight: $font-weight-bold;
        font-size: 24px;
        line-height: 32px; // Same as button height, so it's consistent with right-aligned buttons
      }

      .page-title-right {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;

        & > * + * {
          margin-left: 16px;
        }
      }

      .btn-separator {
        height: 32px;
        width: 0;
        border-left: 1px solid $grey200;
        margin-right: -1px;
      }

      * + .btn-separator {
        margin-left: 16px;
      }

      .btn-separator + * {
        margin-left: 16px;
      }

      .info-button {
        padding-left: 8px;
        padding-right: 8px;

        .cr-icon-info {
          margin-right: 0;
          font-size: 18px;
        }
      }
    }

    .info-section {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding-bottom: 16px;
      border-bottom: 1px solid $grey100;

      .info-desc {
        &,
        p {
          color: $grey500;
          line-height: 150%;
        }
      }

      .btns-and-control {
        display: flex;
        flex-direction: row;
        align-items: center;

        > * + * {
          margin-left: 16px;
        }

        .collapse-control {
          margin-left: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          &,
          .cr-icon-chevron {
            color: $grey300;
          }

          &:hover {
            &,
            .cr-icon-chevron {
              color: $grey500;
            }
          }

          .cr-icon-chevron {
            margin-left: 16px;
            font-size: 12px;
          }
        }
      }
    }

    .back-btn {
      width: 24px;
      height: 24px;
      position: relative;
      border: 1px solid $grey200;
      border-radius: 50%;
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        border-color: $grey500;
      }

      &:after {
        content: $cr-icon-arrow-right;
        font-family: $icomoon-font-family;
        color: $grey500;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotateY(180deg);
        height: 15px; // Centre
      }
    }
  }
}
