@use "../../_common/style/core/variables";

$toolbar-padding: 20px;

.validation-toolbar {
  position: fixed;
  bottom: $toolbar-padding;
  right: $toolbar-padding;
  left: variables.$nav-width;
  margin-left: $toolbar-padding;
  background-color: variables.$red50;
  display: flex;
  flex-direction: row;
  padding: 20px 28px;
  z-index: 10;
  border: 1px solid variables.$red500;
  border-radius: variables.$card-border-radius;
  color: variables.$red500;

  max-width: variables.$survey-editor-max-width + ($toolbar-padding * 2);

  .cr-icon-risk {
    color: inherit;
    font-size: 18px;
    margin-right: 20px;
    margin-top: 1px;
  }

  .val-main {
    flex: 1;
    color: inherit;

    div {
      color: inherit;
      line-height: 150%;
    }

    .val-header {
      font-weight: variables.$font-weight-bold;
      margin-bottom: 5px;
    }
  }

  .btn-icon {
    & > * {
      color: variables.$red500;
    }

    &:hover {
      background-color: variables.$red50;

      & > * {
        color: variables.$red500;
      }
    }
  }
}
