@use "../../../_common/style/core/variables";

.named-role-invite-modal-header {
  display: flex;
  flex-direction: column;

  & > h2 {
    padding-bottom: 5px;
  }

  & > p {
    color: variables.$grey500;
  }

  .header-note {
    width: 600px;
    margin-top: 20px !important;
  }
}

.role-bases-invite-modal {
  .emails-label {
    color: variables.$grey900;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .dismissible-popup-container {
    margin-left: 8px;
  }

  .info-banner {
    margin-top: 16px;
    width: 600px;

    .message {
      p {
        color: inherit;
        span {
          color: inherit;
          font-weight: variables.$font-weight-bold;
          cursor: pointer;
        }
      }
    }
  }
}
