@use "../../../_common/style/core/variables";

#create_questionnaire_bulk {
  .section-step {
    padding: unset;
  }

  .report-card {
    .content {
      padding: 20px;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .select-vendor-card {
    .header {
      flex-direction: row;
      align-items: center;
    }
  }

  .modal-section-box {
    border: 1px solid variables.$grey100;
    border-radius: 8px;
    padding: 24px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  .title-and-message,
  .select-survey-step,
  .schedule-card {
    h3 {
      font-size: 14px;
      line-height: 150%;
      font-weight: variables.$font-weight-bold;
      color: variables.$grey900;
      margin-bottom: 16px;
    }
  }

  .send-questionnaires-bar {
    .vendors-div {
      line-height: 32px;

      .grey {
        color: variables.$grey500;
      }

      .vendor-select-all-btn {
        &:not(:first-child) {
          margin-left: 8px;
        }

        .btn-inner {
          font-size: 14px;
          font-weight: variables.$font-weight-regular;
          letter-spacing: 0px;
        }
      }
    }

    .btn-group {
      margin-left: auto;

      .btn:not(:last-of-type) {
        margin-right: 16px;
      }
    }

    .back-button {
      margin-right: 8px;
    }
  }

  .review-resend {
    .resend-review-field {
      display: flex;
      flex-direction: row;
      margin: 5px 0px;
      line-height: 20px;

      & > :first-child {
        width: 80px;
        justify-content: flex-start;
        text-align: right;
        font-size: 11px;
        color: variables.$medium-grey;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: variables.$font-weight-bold;
        margin-right: 14px;
      }
    }
  }

  .assign-recipients-single {
    .recipients-section {
      padding: 20px 32px;
    }
  }

  .select-survey-card {
    .header {
      justify-content: center;
    }
  }

  // NOTE: duplicated in CreateVendorRelationshipQuestionnaire, combine when old security send removed
  .select-survey-step {
    .ug-select__menu {
      z-index: 11;
    }

    .survey-sections {
      margin-top: 16px;
      background-color: variables.$grey50;

      .name-preview {
        display: flex;
        flex-direction: row;
        align-items: center;

        h3 {
          margin-bottom: 0;
        }

        .btn {
          margin-left: auto;
        }
      }

      &.no-sections p {
        margin-bottom: unset;
      }

      .horz-line {
        border-top: solid 1px variables.$grey100;
        width: 100%;
        margin: 18px 0;
      }

      .color-checkbox-inner {
        flex-grow: 1;
      }

      .survey-section {
        display: flex;
        flex-direction: row;
        //align-content: center;
        align-items: center;

        h3 {
          margin-bottom: unset;
        }

        .btn {
          margin-left: auto;
        }
      }
    }

    .color-checkbox:not(:last-of-type) {
      margin-bottom: 8px;
    }

    .survey-type-select {
      h3 {
        display: flex;
        flex-direction: row;

        div {
          margin-left: 8px;
        }
      }
    }

    .new-survey-select {
      .btn {
        padding: 0;
        height: 16px;
      }

      p {
        line-height: 150%;

        a {
          .btn {
            font: inherit;
            letter-spacing: inherit;
          }
        }
      }
    }
  }

  .resend-survey-select {
    .color-checkbox-inner {
      flex-grow: 1;

      .box {
        margin-top: 2px;
        margin-bottom: auto;
      }
    }

    .resendable-survey {
      display: flex;
      flex-direction: row;

      h3 {
        margin-bottom: unset;
      }

      .btn {
        margin-left: auto;
      }

      p {
        margin-bottom: unset;
      }
    }
  }

  .modal-section-box {
    max-width: 666px;
  }

  .assign-recipients {
    .tab-section {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
      padding: 0 20px;

      .tab-buttons-wrapper {
        flex-grow: 1;
        width: max-content;
      }

      .search-box-input-container {
        margin-left: auto;
      }
    }

    .button-cell {
      text-align: right;
    }
  }

  .survey-due-date {
    p {
      margin-top: unset;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 21px;
      color: variables.$grey500;
    }

    .color-checkbox {
      align-items: flex-start;

      .color-checkbox-inner {
        .box {
          margin-top: 2px;
          margin-bottom: auto;
        }

        .label {
          white-space: unset;
        }
      }
    }

    .date-picker {
      width: 100%;

      & + * {
        margin-top: 24px;
      }
    }

    .date-picker-indent {
      margin-left: 30px;
      width: calc(100% - 30px);
    }
  }

  .title-and-message {
    label {
      margin-top: 8px;
      cursor: unset;
    }

    h3 {
      margin-bottom: 4px;
    }

    div + h3 {
      margin-top: 16px;
    }

    .label {
      font-size: 14px;
      margin-bottom: 8px;
      color: variables.$grey500;
      line-height: 150%;
    }
  }

  .review-step {
    line-height: 21px;

    .content {
      padding: 0 32px;
    }

    .label {
      color: variables.$grey900;
      font-size: 14px;
      font-weight: variables.$font-weight-bold;
      line-height: 21px;
      text-align: left;
    }

    .grid {
      display: grid;
      grid-column: 2;
      grid-template-columns: 128px auto;
      row-gap: 16px;
    }

    .date,
    .title {
      color: variables.$grey500;
      font-weight: variables.$font-weight-bold;
      size: 14px;
      line-height: 21px;
    }

    .message {
      color: variables.$grey500;
      size: 14px;
      line-height: 21px;

      p {
        margin-top: unset;
      }
    }

    .recipients-table {
      th {
        padding-top: unset;
        text-transform: unset;
      }
    }

    .review-content {
      grid-template-columns: 196px auto;
      row-gap: unset;

      & > div {
        padding: 32px 0;

        &:nth-last-child(n + 3) {
          border-bottom: variables.$grey100 solid 1px;
        }
      }
    }
  }
}

.qn-contact-modal {
  width: 640px;
}
