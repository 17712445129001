@use "../../../../_common/style/core/variables";

.x-table.survey-risk-table {
  table {
    tr,
    td {
      height: unset;
      border: unset;
    }

    td {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    td.selection-box {
      padding-left: 129px !important;
      padding-right: 0px;
      vertical-align: top;
    }

    div.color-checkbox-inner {
      margin-right: 6px;
    }

    tbody {
      tr:not(.expanded-content-row) {
        height: 30px;
      }

      tr.clickable:hover {
        td {
          border: 0;
        }
      }

      tr.selected td {
        border: 0;
      }
    }
  }

  .survey-container {
    display: flex;
    flex-direction: row;

    .survey-name-and-type {
      justify-content: center;
    }

    .pill-label {
      margin-left: 4px;
    }
  }

  padding-bottom: 14px;
}
