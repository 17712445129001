@import "../../core/variables";

.loader-icon {
  animation: spin 1s linear infinite;
  stroke: $blue;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  &.blue {
    stroke: $blue;
  }

  &.green {
    stroke: $green;
  }

  &.grey {
    stroke: $medium-grey;
  }

  &.dark-grey {
    stroke: $dark-grey;
  }

  &.red {
    stroke: $red;
  }

  &.black {
    stroke: $black;
  }

  &.white {
    stroke: $white;
  }
}
