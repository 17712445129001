@import "../../../_common/style/core/variables";

.userbase-unmonitored-users-list {
  .users-filters {
    padding: $space-lg $space-xl 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $space-xl;

    .search-box-input-container {
      flex: 1;
    }
  }

  i.cr-icon-accepted {
    font-size: 18px;
  }

  i.cr-icon-minus-circle {
    font-size: 20px;
  }

  i.cr-icon-risk {
    font-size: 20px;
  }

  .dimmed {
    color: $grey400;
  }
}
