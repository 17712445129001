@use "../core/variables";

.actions-btn {
  margin-left: auto;
  white-space: nowrap;

  .icon-chevron {
    margin-left: 4px;
    margin-right: 0px;
    vertical-align: -1px;
  }

  .dropdown-item.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.actions {
  .dropdown-button-dropdown-item-content {
    flex-direction: row;
    display: flex;
  }
}
