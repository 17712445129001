@use "../../../_common/style/core/variables";

.add-ip-addresses-modal {
  width: 510px;

  .modal-content {
    padding-top: 20px !important;
  }

  p,
  .mode-option .label {
    color: variables.$grey500 !important;
  }

  .mode-option {
    margin-bottom: 10px;
  }

  h4 {
    color: variables.$grey900;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .validating input.invalid {
    background-color: variables.$red50;
    border: 1px solid variables.$red200;
  }

  .validation-container {
    flex-grow: 1;

    .info-banner {
      padding: 5px;
      width: fit-content;
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}

.ip-range-input {
  .ip-range-input-start,
  .ip-range-input-end {
    display: flex;
    margin-bottom: 20px;

    .ip-range-input-label {
      width: 80px;
      padding-top: 9px;
    }

    .ip-range-octet {
      padding-right: 15px;
      display: flex;
      position: relative;

      &:not(:last-of-type):after {
        content: ".";
        font-weight: 600;
        position: absolute;
        top: 11px;
        right: 5px;
        color: variables.$grey200;
      }

      input {
        width: 80px;

        &:disabled {
          background: variables.$grey50 !important;
        }
      }
    }
  }
}
