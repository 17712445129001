@use "../../../../_common/style/core/variables";

.edit-risk-waiver-modal {
  h4 {
    margin-bottom: 8px;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 150%;
    color: #5d6598;
    font-weight: 500;
  }

  .risk-header {
    border: 2px solid variables.$grey100;
    border-radius: 8px;
    padding: 16px;
    margin-top: 24px;
    margin-bottom: 24px;

    display: flex;
    align-items: center;

    .risk-cat-title {
      color: variables.$grey500;
    }

    .severity-icon {
      margin-right: 16px;
    }

    .risk-text-container {
      .risk-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        font-weight: variables.$font-weight-bold;
        margin-bottom: 4px;
      }
    }

    .num-assets {
      margin-left: auto;
      position: relative;
      font-weight: variables.$font-weight-bold;
    }
  }

  .breachsight-waiver-details-step {
    margin-top: 24px;
  }

  .breachsight-domain-selection {
    flex-direction: column;

    .domain-selection {
      margin-top: 24px;
      margin-left: unset;

      .show-more {
        margin-left: 8px;
        margin-bottom: 4px;
      }

      ul {
        border: 1px solid variables.$grey100;
        border-radius: 4px;
        padding-top: 2px;
        max-height: 400px;
        overflow-y: auto;

        li {
          margin-left: 8px;
        }
      }
    }
  }

  .asset-selector {
    .all-assets-selection {
      margin-bottom: 24px;
    }
  }

  .empty-risk-card {
    margin-top: 24px;
  }
}

.edit-risk-waiver-modal-footer {
  .btn-group + .btn-group {
    margin-left: auto;
  }
}

.edit-risk-waiver-modal-header {
  h2 {
    padding-bottom: 0;
  }
}
