.nda-signed-modal {
}

.nda-signed-modal-footer {
  justify-content: space-between !important;
  flex-direction: column-reverse;

  .btn-group {
    padding: 0 !important;
  }

  .info-banner {
    margin-top: 12px;
  }
}
