@import "../../core/variables";

.multi-product-user-menu {
  h3 {
    text-transform: none !important;
  }

  .user-info {
    align-items: unset;
    display: flex;
    flex-direction: column;
    height: unset;
    padding: 12px 16px;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      color: $grey700;
    }

    .user-name {
      font-size: $font-size;
      font-weight: $font-weight-bold;
      line-height: $line-height;
    }

    .user-email {
      font-size: $font-size-smaller;
      font-weight: $font-weight-regular;
      line-height: $line-height-small;
    }
  }

  .org-list-dropdown-item {
    display: flex;
    gap: 8px;
    overflow: hidden;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .active-check {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 0;
      min-height: 24px;
      min-width: 24px;
    }
  }
}
