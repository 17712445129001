@import "../../../../_common/style/core/variables";

.confirm-new-assessment-modal {
  width: 600px;

  .count-text {
    color: $grey600;
    font-size: $font-size;
    font-weight: $font-weight-regular;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .color-checkbox .color-checkbox-inner {
    width: 100%;
    .label {
      margin-left: 12px;
    }
  }

  .draft-assessment-block {
    border-radius: 8px;
    border: 1px solid $grey200;
    padding: 12px 16px;
    margin-top: 8px;

    &.selected {
      border-radius: 8px;
      border: 1px solid $blue300;
      background: $blue50;
    }

    &.single {
      .color-checkbox-inner {
        cursor: unset;
      }
      .box {
        display: none;
      }
    }

    .assessment-label {
      .assessment-title {
        color: $grey900;
        font-size: $font-size;
        font-weight: $font-weight-bold;
        line-height: 22px;
      }

      .assessment-author {
        color: $grey900;
        font-size: $font-size-smaller;
        font-weight: $font-weight-regular;
        line-height: 18px;
      }
    }
  }
}
