@import "../../core/variables";

.remediation-request-details-v2 {
  .remediation-risks-table * .chevron-cell {
    padding: 0 32px;
  }

  .additional-evidence-table {
    th {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }

    td {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    th:first-of-type,
    td:first-of-type {
      padding-left: 0;
    }

    .evidences-cell {
      display: flex;
      flex-direction: column;
      margin-right: 15px;
      min-width: 325px;
      max-width: 325px;
    }

    .justification-cell {
      vertical-align: top;
      width: 100%;

      .user-and-date {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 9px;

        .date {
          padding-left: 8px;
          color: $grey400;
        }
      }

      .comment {
        color: $grey800;
      }
    }
  }

  .evidence-with-shared-documents {
    .expandable-item-header-link {
      display: flex;
      align-items: center;
      .icon-chevron {
        color: $grey500;
      }
    }
    .evidence-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      gap: 32px;
      .evidence-name {
        color: $blue500;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 165px;
      }
      .shared-status {
        flex-grow: 1;
        text-align: right;
        color: $grey500;
      }
    }
    .document-link {
      margin-top: 13px;
    }
    &:not(:first-of-type) {
      padding-top: 12px;
    }
    &:not(:last-of-type) {
      padding-bottom: 12px;
      border-bottom: 1px solid $grey100;
    }
  }

  .risk-summary-details {
    .risk-detail {
      display: flex;
      flex-direction: column;
      padding-left: 68px;
      padding-right: 40px;

      .overview-section {
        display: flex;
        flex-direction: row;
        align-items: center;

        .description-content {
          margin-right: 16px;
        }

        .btn {
          margin-left: auto;
        }
      }

      .description-header {
        font-weight: $font-weight-bold;
        font-size: 12px;
        line-height: 150%;
        margin-right: 10px;
        color: $grey400;
        letter-spacing: 1px;
      }

      .description-content {
        margin-top: 14px;
        line-height: 20px;
        color: $grey500;
      }

      .additional-evidence-risk {
        justify-content: flex-end;
        .btn {
          margin-left: 16px;
        }
      }
    }

    .questionnaires-table {
      td {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .questionnaire-cell {
        padding-top: 16px;
        padding-bottom: 0;

        .questionnaire-title {
          .title-sent {
            //display: flex;
            //flex-direction: column;
            &:hover {
              cursor: pointer;
            }

            .title {
              padding: 0;
              margin-bottom: 4px;
              color: $blue500;
              font-weight: $font-weight-regular;
              text-decoration: underline;
              font-size: $font-size;
              min-height: unset;
              display: block;

              &:hover {
                text-decoration: none;
              }
            }

            .sent {
              color: $grey500;
              font-size: $font-size-smaller;
              display: block;
            }
          }

          .pill-label {
            margin-left: 10px;
          }

          .btn {
            margin: 8px 0;
          }
        }

        .info-banner {
          margin-bottom: 16px;
        }
      }

      .status-cell {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 16px;

        .pill-label {
          margin-top: 4px;
        }
      }
    }

    .users-table {
      td {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    .threat-table {
      .threat-cell {
        .threat {
          color: $blue500;
          text-decoration: underline;
          cursor: pointer;
          width: fit-content;
        }
        .threat-no-link {
          width: fit-content;
        }
      }
    }
  }

  .check-item {
    * > .x-table table {
      th {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
      }

      tr {
        &:hover {
          background-color: unset;
        }
      }

      td:first-of-type {
        padding-left: 0;
      }

      .domains-cell {
        color: $blue500;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .section-body {
    .x-table table th.with-help-text .icon-arrow {
      margin-left: 4px;
    }

    .x-table table tbody tr:not(.expanded-content-row) {
      height: 48px;
    }
  }

  .search-filters {
    display: flex;
    padding: 12px 32px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    .search-box-input-container {
      flex-grow: 1;
    }
    .filters {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
  }

  .additional-evidence-documents-list {
    table {
      td {
        .document-link {
          margin-top: 8px;
        }
        .document-links-container {
          display: inline-block;
        }
      }
    }
  }
}
