@use "../../../_common/style/core/variables";

.known-exploited-vuln {
  margin: 0px;
  padding: 0px;
  width: 230px;

  .link {
    margin-top: 10px;
  }
}

.known-exploited-vuln-popup {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  max-height: 120px;
}
