@import "../../../../_common/style/core/variables";

.pick-domains-modal {
  width: 900px;

  .loader-icon {
    margin: 48px auto;
  }

  .sub-title {
    margin-bottom: 16px;
  }

  .filter-section {
    display: flex;
    flex-direction: column;

    .top-row {
      display: flex;
      flex-direction: row;

      .cr-icon-search {
        font-size: 16px;
      }

      .dropdown-v2 {
        .popup-content {
          max-height: 500px;
          overflow: auto;
        }
      }

      .search-btn {
        margin-left: auto;
        img {
          fill: $grey200;
          margin-right: 8px;
        }
      }

      .btn {
        margin-right: 8px;
      }
    }

    .bottom-row {
      padding: 16px;
    }
  }

  .info-banner {
    margin-top: 16px;
    margin-bottom: 0;

    .message {
      display: block;
    }
  }

  .main-table {
    max-height: 600px;
    .tree-table-inner-container {
      max-height: 450px;
      overflow: auto;
    }

    .score-cell,
    .score-header {
      width: 100px;

      .grade-with-score {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;

        .color-grade {
          margin-right: 5px;
        }
      }
    }

    .date-scanned-cell,
    .date-scanned-header {
      width: 140px;
    }

    .labels-cell,
    .labels-header {
      width: 20%;
    }
  }
}

.pick-domains-footer {
  align-items: center;
  input + label {
    margin-top: 0;
    margin-bottom: 0;
  }
}
