@use "../../../_common/style/core/variables";

#additional_evidence_view {
  h3 {
    font-weight: variables.$font-weight-bold;
    padding: 0;
    margin-left: 0;
    font-size: 18px;
    line-height: 18px;
  }

  .vert-spacer {
    height: 20px;
  }

  .dragdropbutton-working {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: variables.$grey100; // $blue50;
    border-radius: 4px;
    height: 30px;
    color: variables.$grey500;
    font-size: 12px;
    font-weight: variables.$font-weight-bold;
    letter-spacing: 1px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid variables.$grey500;

    .loader-icon {
      height: 16px;
      width: 16px;
      margin-right: 5px;
      stroke: variables.$grey500 !important;
    }
  }

  .cr-icon-upload {
    margin-right: 5px;
  }

  .cr-icon-export {
    margin-right: 5px;
  }

  .evidence-card {
    border-radius: variables.$card-border-radius;
    border: 1px solid variables.$light-grey;
    box-shadow: variables.$box-shadow-4dp;

    .pill {
      text-transform: none !important;
      padding-left: 10px;
      padding-right: 10px;
    }

    .evidence-list-table {
      .name-cell {
        height: 70px;
        width: 300px;
        max-width: 300px;

        .name-cell-content {
          display: flex;
          flex-direction: row;
          align-items: center;

          .icon-placeholder {
            width: 32px;
            height: 32px;
            margin-right: 10px;

            img {
              width: 32px;
              height: 32px;
              max-width: 32px;
            }
          }

          .cr-icon-bug {
            margin-left: 10px;
          }

          .name-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .scanning {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 16px;
        color: variables.$grey500;

        .loader-icon {
          height: 12px;
          width: 12px;
          min-height: 12px;
          min-width: 12px;
          margin-right: 5px;
        }
      }

      .virus-detected {
      }

      .expired {
        color: variables.$red500;
      }
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .title {
        flex: 1;
      }
    }

    .empty-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-weight: variables.$font-weight-regular;
      color: #95989b;
      font-style: italic;
      font-size: 1.2rem;
      line-height: 1.7rem;
      padding: 30px;

      .text {
        margin-bottom: 30px;
      }
    }

    .upload-working {
      display: flex;
      flex-direction: row;
      margin: 30px;
      height: 88px;
      align-items: center;
      justify-content: center;
      cursor: default;
      border: 1px variables.$blue500 !important;
      border-radius: variables.$card-border-radius !important;

      & > .loader-icon {
        width: 30px;
        height: 30px;
      }
    }

    .upload-target {
      display: flex;
      flex-direction: row;
      margin: 30px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        color: variables.$grey500;
      }

      .blue {
        color: variables.$blue;
        margin-right: 5px;
      }

      .cr-icon-paperclip {
        margin-right: 5px;
      }
    }

    .tabs {
      margin-left: 30px;
    }
  }

  .disabled-row {
    td:not(.options-icons-cell) {
      opacity: 50%;
    }
    background-color: variables.$grey25;
  }

  .request-btn {
    white-space: nowrap;
  }
}
