@import "../core/variables";

.verified-badge {
  display: flex;
  flex-direction: row;
  margin: 0 5px 0 8px;
  padding: 0;
  align-content: center;
  align-items: center;
  justify-content: center;

  .raise {
    z-index: 1000;
  }
}

.verified-badge-nopopup {
  display: flex;
  flex-direction: row;
  margin: 0 5px 0 8px;
  padding: 0px;
  align-content: center;
  align-items: center;
  justify-content: center;
}
