@import "../core/variables";
@import "../core/shadows";

.ug-select {
  .ug-select__control {
    font-size: 14px;
    line-height: 14px;
    min-width: 280px;
    border-radius: 4px;

    &:focus-within {
      box-shadow: 0 0 0 3px $blue100;
      border-color: $blue300;
    }

    .ug-select__placeholder {
      color: $grey300 !important;
    }

    .ug-select__indicators {
      div {
        padding: 4px;
      }
    }

    .ug-select__single-value {
      min-height: 16px;
      top: 56%;
    }

    input {
      height: unset;
      box-shadow: none !important;
    }
  }

  .ug-select__control--is-disabled {
    background-color: $grey50;

    .ug-select__placeholder {
      color: $grey200 !important;
    }
  }

  &.static-editable {
    position: relative;

    &:hover {
      .ug-select__control:not(.ug-select__control--is-focused) {
        background-color: $blue50;
        cursor: pointer;
      }
    }

    .ug-select__control {
      margin: -6px 0 -6px -10px;
      border-color: transparent;

      .ug-select__value-container {
        padding: 4px - 6px 10px;
      }

      .ug-select__indicator-separator {
        visibility: hidden;
      }

      &.ug-select__control--is-focused {
        border-color: $grey200;
      }
    }

    .ug-select__menu {
      width: calc(100% + 10px);
      transform: translateX(-10px);
    }
  }
}

.ug-select__menu {
  box-shadow: $box-shadow-blue-2dp;

  .ug-select__menu-list {
    z-index: 2;
  }
}

.ug-select__menu .create-hint {
  padding: 4px 12px;
  background-color: $grey50;
  color: $grey500;
  font-size: $font-size-extra-small;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
