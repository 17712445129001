@import "../core/variables";

.circled-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: $blue100;
  position: relative;

  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $blue;
    font-size: 20px;
  }

  &.green {
    background-color: $green100;

    & > div {
      color: $green600;
    }
  }
}
