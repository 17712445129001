@import "../core/variables";

.info-card {
  display: flex;
  flex-direction: row;
  padding: 24px 32px;
  border: 1px solid $grey100;
  border-radius: $card-border-radius;
  box-shadow: $shadow-level-1;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    background-color: $blue500;
  }

  > * + * {
    margin-left: 16px;
  }

  .header-desc {
    flex: 1;

    h5 {
      font-size: 16px;
      font-weight: $font-weight-bold;
      line-height: 150%;
    }

    .desc {
      margin-top: 8px;
      color: $grey600;
    }
  }

  .dismiss-icn {
    display: block;
    font-size: 16px;
    margin-top: 7px;
    color: $grey300;
    cursor: pointer;

    &:hover {
      color: $grey900;
    }
  }
}
