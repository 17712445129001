@use "../core/variables";

.user-avatar {
  max-height: 25px;
  position: relative;

  .more_pill {
    border: 1px solid variables.$dark-grey;
    border-radius: 12px;
    background-color: variables.$white;
    width: 40px;
    height: 25px;
    margin: 0px 1px 0px 5px;
    vertical-align: middle;
    text-align: center;
    font-size: 11px;
    color: variables.$dark-grey;
    text-transform: uppercase;
    line-height: 23px;
    letter-spacing: 1px;
    display: inline-block;
  }

  .invites_pill {
    border: 1px solid variables.$dark-grey;
    border-radius: 12px;
    background-color: variables.$white;
    width: 90px;
    height: 25px;
    margin: 0px 1px 0px 5px;
    vertical-align: middle;
    text-align: center;
    font-size: 11px;
    color: variables.$dark-grey;
    text-transform: uppercase;
    line-height: 23px;
    letter-spacing: 1px;
    display: inline-block;
  }

  .avatar {
    width: 25px;
    height: 25px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0px 1px;
    color: variables.$white;
  }

  .icon-user {
    margin: 0px 1px;
    border-radius: 100%;
    color: variables.$white;
    font-size: 16px;
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    width: 25px;
    line-height: 25px;
    background-color: variables.$light-grey;
  }

  .hover-popup {
    position: absolute;
    opacity: 0;
    transition: opacity variables.$animation-timing ease-in-out;
    pointer-events: none;
    font-size: 12px;
    text-align: left;
    background-color: variables.$white;
    left: -5px;
    bottom: calc(100% + 5px);
    padding: 5px;
    box-shadow: 0px 0px 20px 0px rgba(69, 69, 69, 0.1);
    border-radius: 5px;
    z-index: 2;

    i {
      font-weight: variables.$font-weight-regular;
    }
  }

  &:hover .hover-popup {
    opacity: 1;
  }

  &.large {
    width: 32px;
    height: 32px;
    max-height: 32px;

    img {
      width: 32px;
      height: 32px;
    }
  }
}

.user-avatar-and-name {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .name-and-info {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  .user-avatar {
    margin-right: 10px;
    margin-top: 4px;
    margin-bottom: auto;
  }

  &.circle-style {
    .user-avatar {
      background-color: variables.$grey500;
      border-radius: 64px;
      height: 28px;
      max-height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 20px;
        width: 20px;
      }
    }
  }

  .user-email,
  .title {
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: variables.$grey500;
    font-weight: variables.$font-weight-regular;
  }
}
