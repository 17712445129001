$icomoon-font-family: "cyberrisk-icons" !default;
$icomoon-font-path: "fonts" !default;

$cr-icon-list-protected: unquote('"\\e9b5"');
$cr-icon-shield-protected-circle: unquote('"\\e9b4"');
$cr-icon-hourglass: unquote('"\\e9b3"');
$cr-icon-link-2: unquote('"\\e9b1"');
$cr-icon-account-circle: unquote('"\\e9b0"');
$cr-icon-envelope: unquote('"\\e9af"');
$cr-icon-cal: unquote('"\\e9ae"');
$cr-icon-resize: unquote('"\\e9ad"');
$cr-icon-users-one: unquote('"\\e9ac"');
$cr-icon-graph: unquote('"\\e9ab"');
$cr-icon-add-bookmark: unquote('"\\e9aa"');
$cr-icon-bookmark: unquote('"\\e9a9"');
$cr-icon-carousel-dot: unquote('"\\e913"');
$cr-icon-partialaccepted: unquote('"\\e9a8"');
$cr-icon-expander: unquote('"\\e9a7"');
$cr-icon-threat-monitoring: unquote('"\\e9a6"');
$cr-icon-incidents-and-news-outline: unquote('"\\e992"');
$cr-icon-message-question-checkmark: unquote('"\\e993"');
$cr-icon-settings: unquote('"\\e994"');
$cr-icon-notification: unquote('"\\e995"');
$cr-icon-single-user-add-plus: unquote('"\\e996"');
$cr-icon-home-fill: unquote('"\\e997"');
$cr-icon-breachsight-fill: unquote('"\\e998"');
$cr-icon-vendor-risk-outline: unquote('"\\e999"');
$cr-icon-user-risk-outline: unquote('"\\e99a"');
$cr-icon-reports-fill: unquote('"\\e99b"');
$cr-icon-incidents-and-news-fill: unquote('"\\e99c"');
$cr-icon-trust-center-outline: unquote('"\\e99d"');
$cr-icon-reports-outline: unquote('"\\e99e"');
$cr-icon-breachsight-outline: unquote('"\\e99f"');
$cr-icon-user-risk-fill: unquote('"\\e9a0"');
$cr-icon-vendor-risk-fill: unquote('"\\e9a1"');
$cr-icon-analyst-portal-outline: unquote('"\\e9a2"');
$cr-icon-trust-center-fill: unquote('"\\e9a3"');
$cr-icon-analyst-portal-fill: unquote('"\\e9a4"');
$cr-icon-home-outline: unquote('"\\e9a5"');
$cr-icon-code2cloud: unquote('"\\e991"');
$cr-icon-users-empty: unquote('"\\e990"');
$cr-icon-add-risk: unquote('"\\e98f"');
$cr-icon-managed-assessment: unquote('"\\e900"');
$cr-icon-logo-outline: unquote('"\\e98d"');
$cr-icon-full-assessment: unquote('"\\e98a"');
$cr-icon-partial-assessment: unquote('"\\e98b"');
$cr-icon-rename: unquote('"\\e98c"');
$cr-icon-file-search: unquote('"\\e988"');
$cr-icon-upload-cloud: unquote('"\\e989"');
$cr-icon-cancel: unquote('"\\e987"');
$cr-icon-search: unquote('"\\e985"');
$cr-icon-radar: unquote('"\\e984"');
$cr-icon-radar1: unquote('"\\e986"');
$cr-icon-shortlist-active: unquote('"\\e982"');
$cr-icon-shortlist: unquote('"\\e983"');
$cr-icon-list-bullet: unquote('"\\e97a"');
$cr-icon-link-broken: unquote('"\\e97b"');
$cr-icon-link: unquote('"\\e97c"');
$cr-icon-list-number: unquote('"\\e97d"');
$cr-icon-undo1: unquote('"\\e97e"');
$cr-icon-italic: unquote('"\\e97f"');
$cr-icon-bold: unquote('"\\e980"');
$cr-icon-equals: unquote('"\\e901"');
$cr-icon-notequal: unquote('"\\e902"');
$cr-icon-prefix: unquote('"\\e903"');
$cr-icon-suffix: unquote('"\\e904"');
$cr-icon-contains: unquote('"\\e905"');
$cr-icon-export-thin: unquote('"\\e906"');
$cr-icon-coffee: unquote('"\\e907"');
$cr-icon-autofill: unquote('"\\e970"');
$cr-icon-question-alert: unquote('"\\e971"');
$cr-icon-tool: unquote('"\\e972"');
$cr-icon-bulb: unquote('"\\e96f"');
$cr-icon-star-filled: unquote('"\\e96d"');
$cr-icon-star-outline: unquote('"\\e96e"');
$cr-icon-grid: unquote('"\\e96b"');
$cr-icon-list: unquote('"\\e96c"');
$cr-icon-stars: unquote('"\\e96a"');
$cr-icon-convert: unquote('"\\e969"');
$cr-icon-autofill-processing: unquote('"\\e968"');
$cr-icon-undo: unquote('"\\e967"');
$cr-icon-magic-wand: unquote('"\\e966"');
$cr-icon-external-link: unquote('"\\e908"');
$cr-icon-compare: unquote('"\\e964"');
$cr-icon-shared-assessment-no-pad: unquote('"\\e963"');
$cr-icon-share: unquote('"\\e962"');
$cr-icon-timer: unquote('"\\e961"');
$cr-icon-mail: unquote('"\\e95e"');
$cr-icon-documents: unquote('"\\e95d"');
$cr-icon-wireframe-globe: unquote('"\\e95f"');
$cr-icon-tools: unquote('"\\e960"');
$cr-icon-expand-collapse: unquote('"\\e95c"');
$cr-icon-book-checkmark: unquote('"\\e958"');
$cr-icon-book-question: unquote('"\\e959"');
$cr-icon-book-sparkle: unquote('"\\e95a"');
$cr-icon-book-x: unquote('"\\e95b"');
$cr-icon-folder: unquote('"\\e957"');
$cr-icon-chat-messages: unquote('"\\e956"');
$cr-icon-q-builder-document: unquote('"\\e955"');
$cr-icon-dots-menu: unquote('"\\e954"');
$cr-icon-lightning: unquote('"\\e953"');
$cr-icon-help: unquote('"\\e952"');
$cr-icon-play: unquote('"\\e951"');
$cr-icon-archive: unquote('"\\e950"');
$cr-icon-trash-2: unquote('"\\e94f"');
$cr-icon-questionnaire: unquote('"\\e94e"');
$cr-icon-broken-link: unquote('"\\e94c"');
$cr-icon-plus: unquote('"\\e94d"');
$cr-icon-reply: unquote('"\\e94b"');
$cr-icon-minus-circle: unquote('"\\e94a"');
$cr-icon-home: unquote('"\\e948"');
$cr-icon-cog2: unquote('"\\e949"');
$cr-icon-cyberrisk: unquote('"\\e947"');
$cr-icon-save: unquote('"\\e946"');
$cr-icon-copy: unquote('"\\e945"');
$cr-icon-sort: unquote('"\\e944"');
$cr-icon-drag-handle: unquote('"\\e943"');
$cr-icon-q-builder-attachment: unquote('"\\e93e"');
$cr-icon-q-builder-checkbox: unquote('"\\e93f"');
$cr-icon-q-builder-document-attachment: unquote('"\\e940"');
$cr-icon-q-builder-radio: unquote('"\\e941"');
$cr-icon-q-builder-flag: unquote('"\\e942"');
$cr-icon-subsidiaries: unquote('"\\e93d"');
$cr-icon-audit-log: unquote('"\\e93c"');
$cr-icon-noeye: unquote('"\\e938"');
$cr-icon-edit-doc: unquote('"\\e939"');
$cr-icon-eye: unquote('"\\e93a"');
$cr-icon-key: unquote('"\\e93b"');
$cr-icon-chevron: unquote('"\\e936"');
$cr-icon-magnifying-glass: unquote('"\\e937"');
$cr-icon-info: unquote('"\\e935"');
$cr-icon-pencil: unquote('"\\e934"');
$cr-icon-pencil-2: unquote('"\\e981"');
$cr-icon-upload: unquote('"\\e933"');
$cr-icon-archive-old: unquote('"\\e932"');
$cr-icon-document: unquote('"\\e931"');
$cr-icon-redo: unquote('"\\e92f"');
$cr-icon-message: unquote('"\\e930"');
$cr-icon-trash: unquote('"\\e92e"');
$cr-icon-export: unquote('"\\e92d"');
$cr-icon-risk-assessment: unquote('"\\e92c"');
$cr-icon-risk-waivers: unquote('"\\e92b"');
$cr-icon-openexternal: unquote('"\\e92a"');
$cr-icon-paperclip: unquote('"\\e929"');
$cr-icon-chat1: unquote('"\\e926"');
$cr-icon-arrow-right: unquote('"\\e927"');
$cr-icon-padlock: unquote('"\\e928"');
$cr-icon-download: unquote('"\\e925"');
$cr-icon-reporting: unquote('"\\e924"');
$cr-icon-vendor-security-profile: unquote('"\\e923"');
$cr-icon-vendor-summary: unquote('"\\e923"');
$cr-icon-upgrade: unquote('"\\e922"');
$cr-icon-shared-assessment: unquote('"\\e921"');
$cr-icon-vendor-risk: unquote('"\\e920"');
$cr-icon-concentration-risk: unquote('"\\e909"');
$cr-icon-analytics: unquote('"\\e90a"');
$cr-icon-chat: unquote('"\\e90b"');
$cr-icon-checklist: unquote('"\\e90c"');
$cr-icon-code: unquote('"\\e90d"');
$cr-icon-cog: unquote('"\\e90e"');
$cr-icon-focus: unquote('"\\e90f"');
$cr-icon-hyperlink: unquote('"\\e910"');
$cr-icon-paragraph: unquote('"\\e911"');
$cr-icon-people: unquote('"\\e912"');
$cr-icon-warning: unquote('"\\e913"');
$cr-icon-breachsight: unquote('"\\e914"');
$cr-icon-email: unquote('"\\e915"');
$cr-icon-filter: unquote('"\\e916"');
$cr-icon-globe: unquote('"\\e917"');
$cr-icon-spanner-outline: unquote('"\\e918"');
$cr-icon-spanner: unquote('"\\e919"');
$cr-icon-accepted: unquote('"\\e91a"');
$cr-icon-owned: unquote('"\\e91b"');
$cr-icon-risk: unquote('"\\e91c"');
$cr-icon-typosquatting-1: unquote('"\\e91d"');
$cr-icon-unclassified: unquote('"\\e91e"');
$cr-icon-check: unquote('"\\e91f"');
$cr-icon-exclamation: unquote('"\\e965"');
$cr-icon-lock: unquote('"\\e973"');
$cr-icon-question: unquote('"\\e974"');
$cr-icon-typosquatting: unquote('"\\e975"');
$cr-icon-clock: unquote('"\\e976"');
$cr-icon-bug: unquote('"\\e977"');
$cr-icon-table: unquote('"\\e978"');
$cr-icon-tree: unquote('"\\e979"');
$cr-icon-upguard-logo-pick: unquote('"\\e98e"');
