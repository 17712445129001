@use "../../../_common/style/core/variables";

.label-list {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: -5px;

  & > .pill-label,
  & > .label-rest-container {
    margin-top: 5px;
  }

  .label-rest-container {
    display: inline;
    position: relative;
    margin-left: 2px;
    margin-right: 2px;
  }
}

.label-list-container {
  position: relative;
  z-index: 14;

  .label-selection-popup {
    position: absolute;
    right: -20px;
    top: calc(100% + 10px);
  }
}

.label-rest-popup {
  width: 200px;

  .pill-label {
    display: inline-block;
    margin-bottom: 5px;

    .pill-label-content {
      word-break: break-word;
      white-space: normal;
    }
  }
}
