.loading-large,
.loading-tight {
  width: 100%;
  text-align: center;
  font-size: 3rem;
  padding: 100px;
  flex-grow: 1;
}

.loading-tight {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
