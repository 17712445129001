@use "../../../_common/style/core/variables";

.scope-risk-level-display {
  font-size: variables.$font-size;
  line-height: variables.$line-height;
  display: flex;
  flex-direction: row;
  align-items: center;

  .dot {
    height: 12px;
    width: 12px;
    background-color: hsl(0, 0%, 80%);
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }

  &.elevated {
    color: variables.$grey900;
    .dot {
      background-color: variables.$red500;
    }
  }
  &.basic {
    color: variables.$grey900;
    .dot {
      background-color: variables.$grey200;
    }
  }
}
