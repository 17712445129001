@use "../../_common/style/core/variables";

.clibrary-document-versions-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 21px 32px;
  color: variables.$grey500;
  cursor: pointer;
  border-top: 1px solid variables.$grey100;

  > div {
    color: inherit;
  }

  &:hover {
    > div {
      color: variables.$blue;
    }
  }
}

.clibrary-document-versions-section {
  border-top: 1px solid variables.$grey100;
  background-color: variables.$grey50;
  border-bottom-left-radius: variables.$card-border-radius;
  border-bottom-right-radius: variables.$card-border-radius;

  .versions-table {
    .document-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: variables.$white;
      padding: 12px 42px 12px 12px;
      border: 1px solid variables.$grey100;
      border-radius: variables.$card-border-radius;
      position: relative;

      &:not(.disabled) {
        cursor: pointer;

        &:hover {
          div,
          .cr-icon-export-thin {
            color: variables.$blue;
          }
        }
      }

      > * + * {
        margin-left: 8px;
      }

      .file-type-icon {
        width: 24px;
      }

      .i-container {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);

        .loader-icon {
          width: 24px;
          height: 24px;
          margin-top: 2px;
        }

        .cr-icon-export-thin {
          //margin-left: auto;
          color: variables.$grey500;
          font-size: 20px;
        }
      }
    }
  }
}
