@import "../../../_common/style/core/variables";

.exclaim-label {
  display: inline-block;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;

  .exclaim {
    border-radius: 30px;
    color: $white;
    font-size: 12px;
    font-weight: $font-weight-bold;
    margin-right: 4px;
    padding: 1px 6px;

    @media print {
      color: $white !important;
      -webkit-print-color-adjust: exact;
      text-align: right;
    }
  }

  .exclaim-triangle {
    margin-right: 4px;
    $triangle-width: 20px;
    background-color: transparent;
    height: $triangle-width;
    width: $triangle-width;

    path {
      stroke-linejoin: round;
      stroke-width: 8px;
      transform: translateY(8px);
    }

    text {
      fill: $white;
      font-family: Inter, "Inter-Fallback", Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: $font-weight-bold;
    }
  }

  &.yellow {
    color: $yellow;
    .exclaim {
      background-color: $yellow;

      @media print {
        background-color: $yellow !important;
      }
    }

    .exclaim-triangle path {
      fill: $yellow;
      stroke: $yellow;
    }

    &.inverted {
      .exclaim {
        background-color: $white;
        color: $yellow !important;
        box-shadow: 0 0 0 1px $yellow;

        @media print {
          background-color: $white !important;
        }
      }

      .exclaim-triangle path {
        fill: $white;
        stroke: $white;
      }

      .exclaim-triangle text {
        fill: $yellow;
      }
    }
  }

  &.orange {
    color: $orange;
    .exclaim {
      background-color: $orange;

      @media print {
        background-color: $orange !important;
      }
    }

    .exclaim-triangle path {
      fill: $orange;
      stroke: $orange;
    }

    &.inverted {
      .exclaim {
        background-color: $white;
        color: $orange !important;
        box-shadow: 0 0 0 1px $orange;

        @media print {
          background-color: $white !important;
        }
      }

      .exclaim-triangle path {
        fill: $white;
        stroke: $white;
      }

      .exclaim-triangle text {
        fill: $orange;
      }
    }
  }

  &.red {
    color: $red;
    .exclaim {
      background-color: $red;

      @media print {
        background-color: $red !important;
      }
    }

    .exclaim-triangle path {
      fill: $red;
      stroke: $red;
    }

    &.inverted {
      .exclaim {
        background-color: $white;
        color: $red !important;
        box-shadow: 0 0 0 1px $red;

        @media print {
          background-color: $white !important;
        }
      }

      .exclaim-triangle path {
        fill: $white;
        stroke: $white;
      }

      .exclaim-triangle text {
        fill: $red;
      }
    }
  }
}

.page-header-layer .exclaim-label {
  margin-right: $global-padding * 2;
}
