@import "../../../_common/style/core/variables";

.app-approval-flow {
  h4 {
    font-weight: $font-weight-bold;
    font-size: 18px;
    color: $grey900;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $space-xs;

    .info-icon {
      width: $space-md;
      height: $space-md;
    }
  }

  // Remove the box shadow from this report card, because it looks terrible
  // with this background color.
  box-shadow: unset;
  background-color: $grey100;
  margin-bottom: 20px;
  padding: $space-md $space-lg;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-md;

  .approval-flow-heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .approval-hide {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      font-size: 12px;
      width: 60px;
      color: $grey400;

      .approval-hide-icon {
        font-size: 16px;
      }
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $space-sm;
  }

  button.approval-button:hover {
    border: 2px solid $blue500;
    transition: all 0.25s ease-in-out;
  }

  button.approval-button {
    border: 2px solid $grey100;
    transition: all 0.25s ease-in-out;
    border-radius: 8px;
    padding: 12px $space-md $space-sm;
    background-color: $white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    .approval-icon {
      width: $space-lg;
      font-size: 15pt;
    }

    .approved-icon {
      color: $green500;
    }

    .needs-approval-icon {
      color: $yellow100;
    }

    .not-approved-icon {
      color: $red400;
    }

    .approval-message {
      display: flex;
      flex-direction: column;
      gap: 2px;
      text-align: left;
      width: 100%;

      .approval-title {
        height: 22px;
        font-weight: $font-weight-bold;
      }

      .approval-description {
        height: 22px;
        font-weight: $font-weight-bold;
        font-weight: $font-weight-regular;
        color: $grey600;
      }
    }

    .approval-chevron {
      width: $space-lg;
      color: $grey400;
    }
  }
}
