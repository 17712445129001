@import "../../../../_common/style/core/variables";

.document-filter-panel {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  max-width: 420px;

  .ug-select .ug-select__control {
    min-width: unset;
  }

  input + label {
    margin: unset;
  }

  button + button {
    margin-left: unset !important;
  }

  .date-picker-container + .date-picker-container {
    margin-left: 16px;
  }

  .filter-grid {
    display: grid;
    grid-template-columns: 30% auto;
    row-gap: 24px;

    .selected-doc-types {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;

      .pill-label {
        align-self: flex-start;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }

  .bold {
    font-weight: $font-weight-bold;
  }

  .date-filter {
    .filter-date-options {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
    }

    .single {
      width: 100%;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.dates {
      align-items: flex-start;
    }

    .left {
      margin-right: auto;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 12px;
    }
  }
}
