@import "../../_common/style/core/_variables";

.tm-user-and-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 2px; /* 8px, but compensating for avatar->text difference */

  flex: 1 1 0;

  .name-row {
    flex: 1 1 max-content;
    min-width: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 8px;

    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      font-weight: $font-weight-bold;
      font-size: $font-size;
    }

    .user-avatar {
      width: 24px;
      height: 24px;
      img.avatar {
        width: 24px;
        height: 24px;
        margin: 0;
      }
    }

    .circled-icon {
      background-color: $white;
      min-width: 24px;
      width: 24px;
      height: 24px;
      margin: 0;
      div {
        width: 24px;
        height: 24px;
        font-size: 17px;

        color: $grey900;
        border-color: $grey900;
        border: 1px;
        border-radius: 50%;
        border-style: solid;
        padding: 3px;
      }
    }
  }
  .email,
  .extra {
    margin-left: calc(24px + 8px); /* compensate for the avatar */
    flex: 1 1 max-content;
    min-width: 0px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-weight: $font-weight-regular;
    color: $grey500;
  }
}
