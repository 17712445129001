@use "../../_common/style/core/variables";

.edit-risk-modal {
  width: 800px;

  .form-section-desc {
    width: 25% !important;
    padding-right: 40px;
  }

  .modal-form {
    margin-top: -40px;
  }

  .footer-left {
    justify-self: flex-start;
  }

  .form-section {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .form-section-input {
    .form-prompt {
      font-weight: variables.$font-weight-bold;
      padding-bottom: 5px;
    }
    .form-example {
      color: variables.$grey500;
      padding-bottom: 5px;
    }

    .em {
      font-style: italic;
    }
    .vspaced {
      margin-top: 30px;
    }
    .vspaced-half {
      margin-top: 15px;
    }
  }

  .compensating-controls {
    flex-direction: column;

    .color-checkbox {
      width: auto;
    }

    textarea {
      margin-top: 20px;
    }
  }
}
