@use "../core/variables";

.no-invitees-modal {
  width: 600px;
  .body-text {
    color: variables.$grey600;
  }
}

.input-error {
  border: 1px solid variables.$red700;
  border-radius: 4px;
  box-shadow: 0 0 0 3px variables.$red100;
}

#plg_register {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  flex-direction: column;
  background-color: variables.$light-grey;
  padding: 0;

  &.form-visible {
    display: flex;
  }

  .onboarding-complete {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: variables.$light-grey;
    z-index: 99;
  }

  .err-text {
    margin-top: 2px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    color: variables.$red700;
  }

  .plg-onboarding-header {
    display: flex;
    width: 100vw;
    height: 10vh;
    align-self: flex-start;
    justify-content: flex-start;
    align-items: center;
  }

  .plg-onboarding-logo-container {
    display: flex;
    position: absolute;
    left: 36px;
    top: 36px;
    justify-content: flex-start;

    img.ug-logo {
      width: 150px;
    }
  }

  .plg-onboarding-content-container {
    display: flex;
    height: calc(90vh - 100px);
    width: 100vw;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity variables.$animation-timing ease-in-out;
    &.hidden {
      opacity: 0;
    }
  }

  .plg-onboarding-question-container {
    align-content: center;
    min-width: 330px;
    max-width: 700px;
    height: 550px;
    z-index: 10;

    h3 {
      font-size: 24px;
      font-weight: 500;
    }
    .plg-onboarding-invite-container {
      width: 300px;
    }

    .plg-onboarding-question-body {
      margin-top: 8px;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 24px;
    }

    .ug-select__control {
      border-color: variables.$grey200;
    }

    .plg-onboarding-input-label {
      font-size: 14px;
      margin-bottom: 12px;
      margin-top: 16px;
      color: variables.$black;
    }

    .plg-onboarding-checkboxbig-container {
      display: flex;
      flex-direction: row;
    }

    .input-other-focus {
      width: 100%;
      margin-top: 4px;
    }

    .input-row {
      display: flex;
      justify-content: space-between;
      transform: scaleY(0);
      opacity: 0;
      transition: all variables.$animation-timing ease-in-out;
      flex-direction: row;

      &.form-visible {
        transform: scaleY(1);
        opacity: 1;
      }
    }

    .input-row-textfield {
      width: 100%;

      .textfield-disabled {
        background: variables.$white;
        opacity: 1;
      }
    }

    .input-row-button {
      display: flex;
      width: 50px;
      justify-content: center;
    }
  }

  .plg-onboarding-other-field-container {
    padding-top: 16px;
    opacity: 0;
    height: 40px;
    transition: opacity variables.$animation-timing;
    &.form-visible {
      opacity: 1;
    }
    .other-text-field {
      width: 100%;
    }
  }

  .plg-onboarding-other-focus-container {
    margin-top: 16px;
    padding-right: 20px;
    width: 550px;
    height: 80px;
    opacity: 0;
    transition: opacity variables.$animation-timing;
    display: flex;
    flex-direction: column;
    &.form-visible {
      opacity: 1;
    }
    .please-provide-more-information {
      color: variables.$grey900;
      margin-bottom: 4px;
    }
  }

  .plg-onboarding-question {
    &.h2 {
    }
  }

  .plg-onboarding-footer-container {
    width: 100vw;
    height: 100px;
    display: flex;
    flex-direction: column;
    transition: opacity variables.$animation-timing ease-in-out;
    opacity: 1;
    &.hidden {
      opacity: 0;
    }
  }

  .plg-onboarding-progressbar-container {
    width: 100vw;
    height: 8px;
    display: flex;
    flex-direction: row;
    gap: 2px;
  }

  .progressbar-segment {
    height: 100%;
    width: 100%;
    transition: background-color variables.$animation-timing ease-in-out;
    &.segment-empty {
      background-color: variables.$grey50;
    }
    &.segment-filled {
      background-color: variables.$blue;
    }
  }

  .leading-edge {
    border-end-end-radius: 4px;
    border-start-end-radius: 4px;
  }

  .plg-onboarding-footer {
    width: 100vw;
    display: flex;
    justify-content: space-between;
  }

  .plg-onboarding-footer-nav-container {
    width: 100vw;
    display: flex;
    justify-content: space-between;
  }

  .plg-onboarding-footer-nav-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 200px;
    padding-right: 24px;
  }

  .plg-previous-button {
    padding-left: 4px;
  }

  .plg-next-button {
    padding-right: 8px;
  }

  .plg-onboarding-footer-prev-button-internal {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .long-button {
    width: 100%;
  }

  .long-button-container {
    min-width: 350px;
    max-width: 500px;
  }

  .red-text {
    color: variables.$red;
  }

  .svg-filter-black {
    filter: invert(0%) sepia(100%) saturate(17%) hue-rotate(240deg)
      brightness(100%) contrast(100%);
  }

  .no-options-div {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    cursor: default;
    align-content: flex-start;
    &:hover {
      background-color: variables.$blue50;
    }
  }
  .no-options {
    text-align: left;
    padding: 8px 12px;
  }

  .no-options-body {
    justify-content: left;
    align-content: start;
    color: variables.$grey600;
  }
  .blurry-circle {
    position: fixed;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    filter: blur(50px);
    z-index: 0;
    pointer-events: none;
    -webkit-user-drag: none;

    &.circle1 {
      top: 60vh;
      left: 90vw;
      width: 40vw;
      height: 40vh;
      background: radial-gradient(
        circle at 50% 50%,
        rgba(32, 175, 93, 0.3),
        rgba(23, 87, 194, 0.1)
      );
    }

    &.circle2 {
      top: 90vh;
      left: 10vw;
      width: 60vw;
      height: 60vh;
      background: radial-gradient(
        circle at 50% 50%,
        rgba(49, 120, 240, 0.3),
        rgba(24, 129, 69, 0.1)
      );
    }

    &.circle3 {
      top: 1vh;
      left: 10vw;
      width: 70vw;
      height: 70vh;
      background: radial-gradient(
        circle at 50% 50%,
        rgba(49, 120, 240, 0.3),
        rgba(24, 129, 69, 0.1)
      );
    }

    &.circle4 {
      top: 70vh;
      left: 70vw;
      width: 90vw;
      height: 90vh;
      background: radial-gradient(
        circle at 50% 50%,
        rgba(32, 175, 93, 0.1),
        rgba(23, 87, 194, 0.1)
      );
    }
  }

  .z-index-up {
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  .coworkers {
    position: fixed;
    //min-width: 6vw;
    //min-height: 8vw;
    -webkit-user-drag: none;
    -webkit-filter: drop-shadow(3px 3px 6px rgba(64, 70, 104, 0.08));
    filter: drop-shadow(3px 3px 6px rgba(64, 70, 104, 0.08));
    opacity: 0;
    transition: opacity variables.$animation-timing ease-in-out;

    &.coworker-visible {
      opacity: 1;
      @media (max-width: 950px) {
        opacity: 0.5;
      }
      @media (max-width: 750px) {
        opacity: 0;
      }
    }

    //left side
    &.coworker-7 {
      width: 6vw;
      height: 6vw;
      top: 12vh;
      left: 1vw;
    }

    &.coworker-3 {
      width: 8vw;
      height: 8vw;
      top: 28vh;
      left: 13vw;
    }

    &.coworker-6 {
      width: 5vw;
      height: 6vw;
      top: 45vh;
      left: -1vw;
    }

    &.coworker-4 {
      width: 6vw;
      height: 6vw;
      top: 70vh;
      left: 9vw;
    }

    //right side
    &.coworker-1 {
      width: 5vw;
      height: 5vw;
      top: 6vh;
      left: 80vw;
    }

    &.coworker-8 {
      width: 3vw;
      height: 4vw;
      top: 25vh;
      left: 97vw;
    }

    &.coworker-2 {
      width: 8vw;
      height: 8vw;
      top: 45vh;
      left: 80vw;
    }

    &.coworker-5 {
      width: 4vw;
      height: 5vw;
      top: 70vh;
      left: 96vw;
    }
  }
  .ug-icon-transition {
    opacity: 0;
    transition: all variables.$animation-timing ease-in-out;
    &.visible {
      opacity: 1;
    }
  }
  .ug-icon-pulse {
    animation-name: grow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    @keyframes grow {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
