@import "../../../_common/style/core/variables";

.export-report-modal {
  width: 750px;

  .export-formats {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 100px;

    & > div {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;
      border-radius: $component-border-radius;
      border: 1px solid $grey300;
      color: $grey500;

      img {
        width: 28px;
        margin-bottom: 10px;
      }

      .active-img {
        display: none;
      }

      &:not(:first-of-type) {
        margin-left: 8px;
      }

      &:not(:last-of-type) {
        margin-right: 8px;
      }

      &:hover,
      &.active {
        cursor: pointer;
        color: $blue;
        border-color: $blue;
        background-color: rgba($blue, 0.05);

        .active-img {
          width: 32px;
          max-width: 32px;
          display: block;
        }

        .inactive-img {
          display: none;
        }
      }

      &.inactive {
        color: $grey500;
        border-color: $grey200;

        .active-img {
          width: 32px;
          max-width: 32px;
          display: block;
        }

        .inactive-img {
          display: none;
        }
      }

      &.disabled {
        img {
          opacity: 40%;
          width: 32px;
          max-width: 32px;
        }

        pointer-events: none;
        border-color: $light-grey;
        color: $light-grey;
      }
    }
  }

  .color-checkbox {
    padding-bottom: 15px;
  }

  .color-checkbox-subsection {
    padding-left: 20px;
  }

  .banner {
    padding: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 30px;
    background-color: $blue50;
    border-radius: 8px;
    color: #1757c2 !important;
    align-items: center;

    .cr-icon-info {
      color: inherit !important;
      font-size: 18px;
      margin-right: 8px;
    }

    .text {
      color: inherit !important;
      flex: 1;
    }
  }

  .form-grid {
    display: grid;
    grid-template-columns: 300px auto;
    grid-row-gap: 24px;

    .left {
      padding-right: 80px;
    }

    .grid-section {
      padding-bottom: 32px;
      border-bottom: 1px solid $grey100;
      .indented {
        margin-left: 30px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: -10px !important;
        margin-bottom: 0px;
      }
      .tuple {
        margin: 0px !important;
        margin-bottom: 0px !important;
      }
      .tuple:not(:last-child) {
        margin-bottom: 20px !important;
      }

      > p:last-child {
        margin-bottom: 0;
      }

      > .color-checkbox:last-child {
        padding-bottom: 0;
      }
    }

    .grid-section .grid-section:nth-last-of-type(-n + 2) {
      padding-bottom: unset;
      border-bottom: unset;
    }

    h3 {
      //styleName: db h3;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      margin-bottom: 8px;
    }
  }

  .interval-select {
    max-width: 250px;
    margin-bottom: 16px;
  }

  .date-time-select {
    display: flex;
    align-items: baseline;

    .date-time-select-sub {
      display: flex;
      align-items: baseline;
    }

    p {
      margin-right: 16px;
    }

    .date-day-select {
      width: 120px;
      margin-right: 16px;
      align-self: flex-start;
    }

    .time-select {
      width: 120px;
    }

    .ug-select__control {
      min-width: 120px;
    }
  }

  .label-list {
    margin-bottom: 8px;

    .pill-label {
      text-transform: none;
    }
  }
}
