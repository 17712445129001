@import "../../../../_common/style/core/variables";

.slide-panel-section.filter-severities {
  .color-checkbox {
    margin-bottom: $space-xs;
  }

  .severity-icon img {
    height: 20px;
    position: relative;
    left: $space-xs;
  }
}
