@import "../../../_common/style/core/variables";

.vendor-assessments-filter-choice-set {
  .vendor-assessment {
    margin-bottom: 5px;

    .color-checkbox .label {
      white-space: normal;
    }
  }
}
