@import "../../../../_common/style/core/variables";

.breachsight-domain-selection {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  .all-websites-selection-container {
    max-width: 360px;

    .all-websites-selection {
      display: flex;
      flex-direction: row;

      .color-checkbox {
        width: auto;
        margin-right: 10px;
      }
    }

    .all-websites-selection-description {
      font-style: italic;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .future-chk {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .domain-selection {
    margin-left: auto;
    margin-right: 100px;

    ul {
      list-style-type: none;
      padding-left: 0;
      margin-top: 5px;

      li {
        padding: 5px 0 5px 0;
        position: relative;
        min-height: 31px;
      }
    }
  }

  .overview-header {
    margin-bottom: 8px;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 150%;
    color: $grey500;
    font-weight: $font-weight-bold;
  }

  .show-more {
    margin-top: 5px;
    color: $blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
