@use "../../../_common/style/core/variables";

.skeleton-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: variables.$space-md;

  .skeleton-text-row {
    width: 100%;
    height: 12px;
    border-radius: variables.$space-xs;

    background-color: variables.$light-grey;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
}
