@use "../../_common/style/core/variables";

.map-to-framework {
  .ug-select {
    margin-left: 10px;
    margin-right: 20px;

    .ug-select__control {
      min-width: 200px;
    }

    .ug-select__indicator-separator,
    .ug-select__dropdown-indicator {
      display: none;
    }
  }
}
