@import "../core/variables";

.messages-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  min-height: 1px;

  // Lotsa css hacks follow...
  & > .correspondence {
    overflow-y: auto;
    flex: 1 1 auto;
    height: 0px; // Hack to get the scrolling working on a flex item
    //display: flex;
    //flex-direction: column-reverse; // Reverse the order of the messages while keeping scroll at the bottom
    //justify-content: flex-start;
    padding: 24px 32px;

    // Hack in a pseudo element to force the top element to keep its top margin, which I just couldn't get to work any other way
    &:after {
      content: "⠀"; // This is not a space, but a blank character (space didn't work)
      display: block;
      visibility: hidden;
      height: 0;
    }
  }

  .message-input-section {
    background-color: $blue25;
    padding: 24px 32px;
    border-top: 1px solid $grey100;

    .text-field {
      textarea {
        max-height: 400px !important; // Override the TextareaAutosize max-height sizing
      }
    }

    .message-input-buttons {
      flex-direction: row-reverse;
      button + button {
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }

  .message-groups {
    flex-grow: 1;
    min-height: 1px;
    overflow: auto;

    .message-group {
      margin: 16px 32px 16px 32px;
      border-radius: 8px;
      border: 1px solid $grey100;
      padding: 16px;
      transition: all 100ms ease-in-out;
      cursor: pointer;
      display: flex;
      flex-direction: column;

      &:first-of-type {
        margin-top: 32px;
      }

      &:hover {
        background-color: $blue50;
      }

      .message-group-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .message-group-action {
          display: flex;
          align-items: center;

          .message-group-total {
            margin-right: 10px;
            font-size: 14px;
            color: $grey400;
            display: flex;
            align-items: center;

            .cr-icon-chat-messages {
              color: $grey300;
              font-size: 18px;
              margin-right: 5px;
            }
          }

          .message-group-unread {
            margin-right: 10px;
            height: 20px;
            width: 20px;
            border-radius: 10px;
            color: white;
            background: $red500;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            line-height: 150%;
          }

          .cr-icon-chevron {
            color: $grey400;
          }
        }
      }

      .message-group-content {
        margin-top: 12px;
        font-size: 14px;
        line-height: 150%;
        font-weight: $font-weight-bold;
      }
    }
  }

  .empty-card-with-action {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
