@import "../core/variables";

.checkbox-big {
  width: 168px;
  height: 160px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey200;
  margin-right: 16px;

  &:hover {
    padding-top: 0;
    background-color: $blue50;
    border: 2px solid $blue500;
  }
  &:hover .checkbox-big-checkbox {
    margin-left: 1px;
    margin-top: 1px;
  }
  &:hover .box {
    background-color: $blue200 !important;
    box-shadow: inset 0 0 0 1px $blue500;
  }

  .box {
    transition: none !important;
  }

  .checkbox-big-checkbox {
    border-radius: 4px;
    margin-left: 2px;
    margin-top: 1px;
  }
  .checkbox-big-head {
    width: 100%;
    height: 30px;
    padding-left: 2px;
    padding-top: 6px;
  }

  .checkbox-big-icon {
    display: flex;
    width: 100%;
    height: 52px;
    align-content: center;
    justify-content: center;

    .checkbox-big-icon-circle {
      display: flex;
      width: 40px;
      height: 40px;
      background-color: $blue200;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      color: $blue;
      & img {
        width: 25px;
      }
    }
  }
  .checkbox-big-text {
    display: flex;
    width: 100%;
    height: 40px;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    color: $grey700;
  }
}

.checkbox-big-tooltip {
  position: absolute;
  background: $white;
  padding: 10px;
  margin-top: 16px;
  margin-left: 80px;
  max-width: 230px;
  text-align: center;
  line-height: 22px;
  box-shadow: 0 10px 15px 0 rgba(64, 70, 104, 0.12);
  transform: translateX(-50%);
  pointer-events: none;
  border-radius: 4px;
  z-index: 2;
  opacity: 0;
  transition: opacity $animation-timing ease-in-out;
  align-items: center;
  .triangle-up {
    position: absolute;
    left: calc(50% - 4px);
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $white;
  }
  &.visible {
    opacity: 1;
  }
}
