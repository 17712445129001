@use "../../_common/style/core/variables";

.add-edit-vendor-attribute-override-modal {
  .field {
    margin-bottom: 20px;

    select {
      width: 100%;
    }
  }
}
