@import "../../../_common/style/core/variables";

.risk-waivers-table {
  .no-data {
    font-size: 18px;
    color: $medium-grey;
    text-align: center;
    font-style: italic;
    padding: 20px;
  }

  .dismissible-popup-container {
    display: inline-block;
  }

  .dismissible-popup-container > span {
    position: relative;
  }

  .dismissible-popup-container > span:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: -3px;
    border-bottom: 1px dotted $medium-grey;
  }

  .risk-waivers-table-table {
    th {
      white-space: nowrap;
    }

    .actions-cell {
      width: 120px;
    }

    .assets-cell {
      width: 1px;
      min-width: 100px;
      white-space: nowrap;
      padding-right: 20px !important;
    }

    .date-cell {
      width: 1px;
      white-space: nowrap;
    }
  }

  .expires-soon {
    display: inline-block;
    margin-left: 10px;

    .cr-icon-clock {
      display: inline-block;
      color: $red;
      vertical-align: -1px;

      &:after {
        display: none;
      }

      &.bouncing {
        animation-name: clock-anim;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        @keyframes clock-anim {
          from {
            transform: scale(1);
          }

          to {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .risk-waivers-panel {
    .title-container {
      padding: 0 40px 24px 40px;
      display: flex;
      flex-direction: row;
      min-height: 40px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      h2.title {
        font-size: 18px;
        line-height: 24px;
        padding: 0;
        max-width: 340px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 24px;
        min-height: unset;
      }

      .button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        min-width: 270px;
        width: 270px;
        margin: 0px;
        margin-right: 5px;
        height: 20px;
        position: relative;

        .btn {
          margin-left: 8px;
        }
      }
    }

    .slide-panel {
      width: 700px;
      //overflow: auto;
    }

    .title {
      border-bottom: 0 !important;

      .btn-group {
        display: flex;

        .btn {
          margin-right: 10px;
        }

        .cr-icon-trash-2 {
          font-size: 18px;
        }

        .cr-icon-pencil {
          font-size: 14px;
        }
      }
    }

    .title-container > h2.title {
      line-height: 1.32em;
    }

    .risk-desc {
      margin: 16px 0px;
      color: $grey500;
      font-weight: $font-weight-regular;
      line-height: 1.5;
    }

    .info-table.bordered {
      .row-value-cell {
        padding-right: 0;
      }

      .row-value {
        color: $grey900;
      }

      .row-value p {
        margin: 0;
        color: inherit;
      }

      .row-value span {
        color: inherit;
      }

      .comment-display {
        width: 100%;
      }

      .row-label {
        width: 120px;
        max-width: 120px;
        text-wrap: wrap;
        font-weight: $font-weight-regular;
      }
    }

    .user-display {
      .email {
        max-width: 370px;
      }
    }

    .info-table {
      table-layout: fixed;
      max-width: 100%;
    }

    .expandable-item {
      max-width: 100%;
    }

    .expandable-item-content {
      ul.website-list {
        padding-left: 20px;

        li {
          text-overflow: ellipsis;
          overflow: hidden;
          list-style-position: inside;
          text-wrap: nowrap;
        }
      }
    }

    div.expandable-item-header {
      border-radius: 4px;
      padding: 0 4px;
      width: fit-content;

      color: $primary-black;

      &:hover {
        color: $blue500;
        background-color: $blue50;
      }

      a {
        color: inherit;
      }
    }
  }

  .search-box-input-container {
    padding: 20px 32px;
  }

  .pill-label + .pill-popup {
    margin-left: 10px;
  }
}

.full-page-modal.v2 {
  .modal-content-container {
    .delete-waiver-desc {
      max-width: 450px !important;
    }
  }
}
