@use "../../../../_common/style/core/variables";

.vendor-assessment-autofill-assessment-card {
  .header {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
  }

  .vendor-assessment-v3-risk-card {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
