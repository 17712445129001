@use "../core/variables";
@use "../core/cr_icons_constants";

.free-vendor-trial {
  padding: 4px 12px !important;
  color: variables.$grey500;

  &:not(:disabled):hover {
    color: variables.$blue;
  }

  & > * {
    color: inherit !important;
  }

  .cr-icon-mail {
    font-size: 18px;
  }
}

.full-page-modal.v2 .modal-content-container {
  .vendor-free-trial-modal {
    max-width: 700px;

    .label {
      font-weight: variables.$font-weight-bold;
      color: variables.$grey900;
      margin-bottom: 8px;
    }

    .introduction {
      color: variables.$grey700;
      margin-bottom: 24px;

      p {
        margin-bottom: 0;
      }
    }

    .recipient {
      margin-bottom: 24px;
    }

    .message {
      .preview-button {
        display: inline-block;
        margin-top: 16px;
      }
    }

    .rich-text-edit-v2 {
      max-width: unset;
    }
  }
}

.full-page-modal.v2 .modal-content-container {
  .vendor-free-trial-modal-head {
    max-width: 700px;
  }

  .vendor-free-trial-modal-foot {
    .send-button {
      line-height: 20px;

      .btn-inner {
        line-height: 20px;
      }

      .cr-icon-message {
        margin-left: 6px;
        margin-right: 0;
        line-height: 20px;
      }
    }
  }
}
