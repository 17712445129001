@import "../../../../_common/style/core/variables";

.survey-tools-modal {
  width: 600px;
  padding-top: 0 !important;

  .tools-section {
    display: flex;
    flex-direction: row;
    padding: 16px;
    border: 1.5px solid $grey100;
    border-radius: 4px;

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    .left-side {
      margin-right: 16px;
      margin-top: 7px;
      font-size: 24px;
    }

    .right-side {
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .header-text {
          color: $grey900;
          font-size: $font-size;
          font-weight: $font-weight-bold;
          line-height: 22px;
        }
      }

      .main-text {
        color: $grey800;
        font-size: $font-size;
        font-weight: $font-weight-regular;
        line-height: 22px;
      }

      .buttons {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .info {
          margin-left: 8px;
        }
      }
    }
  }
}
