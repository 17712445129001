@import "../../../_common/style/core/variables";

.questionnaires-container {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: $white;

  .compensating-controls-cell {
    padding: 0px !important;

    &.waived {
      font-style: unset !important;
    }
  }

  .x-table table thead tr th {
    padding-left: 0px;
    position: unset !important;
  }

  .x-table table tbody tr:not(.expanded-content-row) {
    height: unset;
  }

  .x-table td.extra-content-cell {
    padding: 0;
  }

  .x-table th {
    padding-top: 0;
    vertical-align: middle;
  }

  .x-table th.options-icons-cell {
    padding-bottom: 12px;
  }

  .questionnaire-cell {
    padding-left: 0px !important;
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: middle;

    &.waived {
      opacity: 0.65;
      font-style: unset !important;
    }
  }

  .sent-by-text {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;

    .sent {
      flex: 1;
      font-size: $font-size-smaller;
      color: $grey500;
      line-height: 150%;
    }
  }

  .questionnaire-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      color: $blue;
    }

    .shared-questionnaire-name {
      margin-right: 14px !important;
    }

    span {
      color: $blue500;
      font-weight: $font-weight-regular;
      text-decoration: underline;
      font-size: $font-size;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .info-banner.warning.border {
    margin-bottom: 0px;
  }

  .status-cell {
    padding: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    width: 0;
    white-space: nowrap;
    vertical-align: middle;

    .links {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      color: $grey500;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }

    .pill-label + .pill-label {
      margin-left: 8px;
    }
  }

  .buttons-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    .waive-btn {
      margin-right: 10px;
    }
    .remediate-btn {
      margin-left: 9px;
    }
    .seperator-div {
      width: 1px;
      border-left: 1px solid $grey100;
      height: 25px;
    }
  }

  .right {
    margin-left: 20px;
  }

  .all-waived {
    font-style: italic;
  }

  .options-icons-cell {
    padding-right: 0 !important;
    vertical-align: middle;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
  }
}
