@import "../../../_common/style/core/variables";

.webscan-table {
  td.clickable .url-cell-wrapper:hover {
    &,
    .url {
      color: $blue;
    }
  }

  .url-cell {
    .url-cell-wrapper {
      position: relative;
      width: 100%;
      height: 72px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .pill-label {
        margin-top: 2px;
      }

      .pill-label + .domain-source-label-list {
        margin-left: $space-xs;
      }
    }

    .url {
      padding-right: 8px;
      /*
        max-width here works to help limit the URL content area
        so that it can be ellipsized correctly.
        Once the text-overflow: ellipsis is able to apply correctly,
        we generally can use the EllipsizedText component to show a
        properly styled popover with the full content
        */
      max-width: 35vw;
    }

    .star-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      left: -20px;
      top: 0;
      fill: $yellow;
      margin: 20px 0;

      @media print {
        display: none;
      }
    }

    @media print {
      width: 15cm !important;
      position: static !important; // without this wont appear in print
    }
  }

  .selectable {
    .url-cell {
      .star-icon {
        left: -20px;
      }

      .url {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  td .caps-time {
    margin-right: 10px;
  }

  .score-cell,
  .date-scanned-cell {
    width: 150px;

    .date-time-format {
      font-size: 14px;
      line-height: 16px;
      color: $grey500;
    }
  }

  .labels-cell {
    width: 300px;
  }

  .add-label {
    opacity: 1;
    transition: opacity 100ms ease-in-out;
  }

  tr:hover > td > .label-list .add-label {
    opacity: 1;
  }

  .icons-cell {
    width: 20px;
    text-align: center;

    .loader-icon {
      width: 20px;
      height: 20px;
    }
  }

  tr {
    .icon-trash {
      opacity: 0;

      &:hover {
        cursor: pointer;
        color: $red;
      }
    }

    &:hover {
      .icon-trash {
        opacity: 1;
      }
    }
  }

  tr.inactive {
    .url {
      color: $medium-grey;
    }
  }
}

.ellipsized-table-url {
  max-width: 50vw;
}
