@use "../../../_common/style/core/variables";

// Class shared between RequestRemediation, CreateIntegration and CreateQuestionaire flows
.steps-with-sections {
  .multi-steps,
  .section {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

  .section-step:not(.nobackground) {
    box-shadow: variables.$box-shadow-4dp;
    border-radius: variables.$card-border-radius;
  }

  .section-step {
    background-color: variables.$white;
    margin-top: 20px;
    padding: 20px;

    h3 {
      display: block;
    }

    &.nobackground {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
      background-color: transparent !important;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 50px;

    .next {
      margin-left: auto;
    }
  }

  .action-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100px;
    background-color: variables.$white;
    z-index: 10;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    box-shadow: 0 -2px 23px 0 rgba(69, 69, 69, 0.1);

    .container {
      display: flex;
      flex-direction: row;
      max-width: variables.$max-page-width;

      .error-msg {
        width: fit-content;
        color: variables.$grey500;
      }

      .left {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        .prev {
          .icon-arrow {
            margin-right: 8px;
          }
        }

        .error-msg {
          margin-left: 20px;
        }
      }

      .right {
        display: flex;
        width: fit-content;
        flex-direction: row;
        align-items: center;

        .error-msg {
          margin-right: 20px;
        }

        .next {
          min-width: 180px !important;
          margin-right: 64px;
        }
      }
    }
  }

  .select-vendor {
    input {
      width: 100%;
      margin-top: 30px;
    }

    .vendor-list-selector {
      margin-left: -15px;
      margin-right: -15px;
      width: 100%;
    }
  }

  .section-step .review-items {
    margin-top: 20px;

    tr {
      td {
        padding-right: 10px;
        padding-bottom: 10px;
        vertical-align: top;
        line-height: 20px;
      }

      td:first-of-type {
        font-weight: variables.$font-weight-bold;
        letter-spacing: 1px;
        font-size: 11px;
        color: variables.$medium-grey;
        text-transform: uppercase;
      }
    }
  }
}

.multi-steps {
  display: flex;
  flex-direction: row;
  width: 100%;

  .step {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 56px;
    position: relative;

    color: variables.$medium-grey;
    background-color: variables.$white;
    border-top: 1px solid variables.$light-grey;
    border-bottom: 1px solid variables.$light-grey;

    &:first-child {
      border-left: 1px solid variables.$light-grey;
      border-top-left-radius: variables.$card-border-radius;
      border-bottom-left-radius: variables.$card-border-radius;
    }

    &:last-child {
      border-right: 1px solid variables.$light-grey;
      border-top-right-radius: variables.$card-border-radius;
      border-bottom-right-radius: variables.$card-border-radius;

      .step-right-arrow {
        display: none;
      }
    }

    .step-num {
      color: variables.$medium-grey;
      border: 1px solid variables.$medium-grey;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      font-size: 12px;

      .cr-icon-check {
        color: variables.$medium-grey;
      }
    }

    .step-right-arrow {
      position: absolute;
      top: -1px;
      bottom: 0;
      right: 0;
      transform: translateX(100%);
      z-index: 1;

      path {
        fill: variables.$white;
        stroke: variables.$light-grey;
      }
    }

    &.completed {
      background-color: variables.$blue50;
      border-color: variables.$blue !important;
      color: variables.$blue;

      .step-num {
        color: variables.$blue;
        border-color: variables.$blue;

        .cr-icon-check {
          color: variables.$blue;
        }
      }

      .step-right-arrow path {
        fill: variables.$blue50;
        stroke: variables.$blue;
      }
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        color: variables.$blue;

        .step-num {
          color: variables.$blue;
          border-color: variables.$blue;

          .cr-icon-check {
            color: variables.$blue;
          }
        }
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }
}
