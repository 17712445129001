@import "../../../_common/style/core/variables";

.upload-additional-evidence-modal-header {
  width: 600px;
}

.upload-additional-evidence-modal {
  width: 600px;

  .multi-steps {
    margin-bottom: 16px;
  }

  .help-text {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-right: 0.5em;
      color: $grey500;
    }

    p {
      margin-bottom: unset;
    }
  }

  .additional-evidence-upload {
    display: flex;
    flex-direction: row;
    border: 1px solid $grey100;
    border-radius: 8px;
    background-color: $grey100;
    padding: 24px;

    .text-section {
      display: flex;
      flex-direction: column;
      margin-left: 8px;

      .filename {
        font-size: 14px;
        line-height: 16px;
        color: $blue500;
        font-weight: $font-weight-bold;
        margin-bottom: 8px;
      }

      .date {
        font-size: 14px;
        line-height: 150%;
        color: $grey500;
      }
    }

    .icon-section {
      margin-left: auto;
    }
  }

  .document-details-form {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .text-field {
      margin-bottom: 0;
    }

    h3 {
      font-size: 14px;
      line-height: 16px;
      font-weight: $font-weight-bold;
      color: $grey900;
    }

    * + h3 {
      margin-top: 24px;
    }

    .text-field + h3 {
      margin-top: 8px;
    }

    h3 + * {
      margin-top: 16px;
    }

    .file-name {
      border: 1px solid $grey200;
      border-radius: 4px;
      padding: 6px 12px;
    }

    .ug-select {
      .ug-select__menu {
        z-index: 12;
      }
    }

    .comments-rte {
      max-width: 536px !important;
      max-height: 200px;

      .editor-inner {
        height: 159px;
        min-height: 159px;

        .content-editable {
          height: 135px;
          min-height: 135px;
        }
      }
    }
  }

  .add-risk-container {
    .additional-evidence-risk-selector {
      .available-risk-check {
        margin-bottom: 12px;
        align-items: flex-start;
        width: 100% !important;

        .available-risk-label {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin-left: 16px;
          width: 100% !important;

          .severity-icon {
            line-height: unset;
          }

          .available-risk-text {
            margin-left: 12px;
            width: 100% !important;
            max-width: 100% !important;

            .risk-name {
              font-size: 14px;
              line-height: 16px;
              font-weight: $font-weight-bold;
              color: $grey900;
              margin-bottom: 4px;
              white-space: normal !important;
            }

            .risk-subtext {
              margin-bottom: unset;
              color: $grey500;
              white-space: normal !important;
            }
          }
        }
      }

      .horz-line {
        border-top: 1px solid $grey200;
        margin-bottom: 24px;
      }

      .new-risk-check {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .new-risk-checkbox {
          flex-shrink: 2;
        }

        .new-risk-fields {
          min-width: 380px;
          margin-right: 32px;

          label:first-of-type {
            margin-top: 14px;
          }

          .text-field {
            margin-bottom: unset;
          }
        }
      }

      .new-risk-check + .new-risk-check {
        margin-top: 24px;
        border-top: 1px solid $grey100;
        padding-top: 24px;
      }
    }

    .risk-name {
      margin-bottom: 8px;
    }

    .selected-risk-info {
      border-top: 1px solid $grey100;
      padding-top: 24px;
      margin-top: 32px;
      display: flex;
      flex-direction: row;

      .severity-section,
      .impact-section,
      severity-selection {
        display: flex;
        flex-direction: column;

        h3 {
          margin-bottom: 12px;
        }
      }

      .severity-section {
        width: 124px;
        margin-right: 18px;

        .severity-icon {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
          }

          span {
            font-size: 14px;
            line-height: 16px;
            color: $grey500;
          }
        }
      }

      .severity-selection {
        width: 124px;
        margin-right: 18px;

        .severity-selector {
          width: 124px;

          .ug-select__control {
            min-width: unset;
          }

          .ug-select__indicator-separator {
            display: none;
          }

          .severity-icon {
            height: 18px;
            width: 18px;
          }
        }
      }

      .impact-section {
        .text-field {
          textarea {
            width: 330px;
            height: 120px;
            resize: none;
          }
        }
      }
    }
  }
}

.upload-additional-evidence-modal-footer {
  .btn {
    .btn-arrow {
      margin-left: 8px !important;
    }
  }
}
