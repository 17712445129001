@use "../../../../_common/style/core/variables";

.define-advanced-rules-step {
  min-width: 940px;

  .report-card {
    margin-top: 20px;
    padding: 0px;

    .header {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 32px !important;
      justify-content: center;
      margin-bottom: 0px;
      .header-inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        .header-left {
          flex: 1;
        }
        .header-right {
          flex: 0;
        }
      }
      .info-section {
        margin-top: 20px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: variables.$grey500;
      }
    }

    .top-section {
      padding: 30px;
      border-bottom: 1px solid variables.$grey200;
      .title-bar {
        display: flex;
        flex-direction: row;
        width: 100%;
        .help-link {
          flex: 0;
          display: flex;
          flex-direction: row;
          .excel-button {
            margin-right: 10px;
          }
        }
        h1 {
          flex: 1;
          font-weight: 500;
          font-size: 16px;
        }
      }
      p {
        font-weight: 400;
        font-size: 14px;
      }
      .survey-cell {
        width: 100%;
        .dropdown-v2 .popup-content {
          right: unset !important;
        }
      }
      .survey-data-cell {
        &:not(:last-of-type) {
          padding-right: 20px;
        }
        &:last-of-type {
          padding-right: 0px;
        }
        min-width: 120px;
      }
      .survey-results-cell {
        flex: 1;
        min-width: 600px;
        .results {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .error {
          width: 100%;
          display: flex;
          flex-directioon: column;
          color: variables.$red;
          padding-top: 5px;
          padding-bottom: 5px;
          span {
            width: 100%;
            color: inherit;
            white-space: normal;
          }
        }
      }
    }
    .bottom-section {
      padding: 30px;
      display: flex;
      flex-direction: column;

      .pane {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        background-color: variables.$blue50;
        border: 1px solid variables.$blue500;
        border-radius: 4px;
        padding: 30px;
        min-height: 200px;
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
        .left-column {
          width: 30%;
          min-width: 30%;
        }
        .right-column {
          width: 70%;
          min-width: 70%;
        }
        .column {
          display: flex;
          flex-direction: column;
          color: variables.$grey900;
          &:not(:last-of-type) {
            margin-right: 10px;
          }
          &:last-of-type {
            margin-left: 10px;
          }

          h2 {
            font-size: 16px;
            font-weight: 700;
            line-height: 20.8px;
          }
          .desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 18.2px;
          }
          .questions-box {
            background: variables.$white;
            width: 100%;
            border: 1px solid variables.$grey200;
            border-radius: 4px;
            margin-top: 10px;
            th {
              padding: 10px;
            }
            table,
            tbody,
            tr {
              width: 100%;
            }
            td {
              padding: 10px;
            }
            .qn-cell {
            }
            .ident-cell {
              max-width: 110px;
              overflow-wrap: break-word;
              padding-right: 0 !important;
            }
            .options-icons-cell {
              padding-left: 0 !important;
            }

            .popup-content {
              overflow-y: scroll;
              max-height: 400px;
            }
            .btn {
              height: unset !important;
              .btn-inner {
                white-space: unset !important;
                height: unset !important;
                text-align: left !important;
                padding-top: 5px;
                padding-bottom: 5px;
              }
            }
          }

          .ranges-table {
            td,
            th,
            tr {
              padding: 0px;
              margin: 0px;
              height: 16px !important;
              padding-top: 5px;
              width: unset;
            }
            tr:first-child td {
              padding-top: 10px;
            }

            .tier-cell {
            }
            .range-cell {
              white-space: nowrap;
            }
          }

          .tier-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            .name {
              white-space: nowrap;
              padding-right: 10px;
              max-width: 200px;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }
          }
          .range-bounds {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            margin: 0px;
            border: 1px solid variables.$black;
            width: fit-content;

            .dismissible-popup-container {
              width: calc(50% - 60px);
            }

            input {
              height: 32px;
              padding-left: 5px;
              border: none;
              border-radius: 0px;
              width: 100%;

              &:focus {
                box-shadow: none !important;
              }
            }
            .mapping-range-val {
              color: variables.$grey900;
            }
            .error {
              color: variables.$red;
              border: 1px solid variables.$red !important;
              outline: none !important;
            }
            .minmax {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 32px;
              min-width: 60px;
              width: 60px;
              background-color: variables.$blue200;
              padding-left: 5px;
            }
          }

          .field {
            width: 100%;
            .field-input {
              font-weight: 400;
              color: variables.$grey900;
              font-size: 1em;
              width: 100% !important;
              background-color: variables.$white;
            }
            .bordered {
              display: flex;
              flex-direction: row;
              border: 1px solid variables.$grey200;
              padding: 20px;
              margin-bottom: 10px;
              border-radius: 4px;
            }
            .left {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: 100%;

              .label {
                font-weight: 700;
                font-size: 15px;
              }
              .formula {
                margin-left: 32px;
                margin-top: 5px;
                font-weight: 400;
                font-style: italic;
                font-size: 12px;
                line-height: 15.6px;
              }
              .dismissible-popup-container {
                width: 100%;
              }
              textarea {
                width: 100%;
                max-width: 100%;
                background-color: variables.$white;
                padding-top: 10px !important;
                padding-bottom: 20px;
                resize: vertical;
                transition: background-color 100ms ease-in-out;
                line-height: 1.4;
                color: variables.$grey900;

                &:disabled {
                  background-color: variables.$canvas !important;
                  color: variables.$grey900;
                }
              }
              .error {
                border: 1px solid variables.$red !important;
                color: variables.$red !important;
              }
            }

            .bordered:not(:last-of-type) {
              margin-bottom: 10px;
            }
            .clickable {
              cursor: pointer;
            }
            .selected {
              background-color: variables.$blue100;
              border: 2px solid variables.$grey300;
            }
          }
          .example {
            border: 1px solid variables.$blue200;
            border-radius: 4px;
            margin-top: 30px;
            margin-right: 60px;
            padding: 20px;
            .txt {
              margin-bottom: 20px;
            }
            th {
              text-decoration: underline;
              text-align: left;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}
