@import "../../_common/style/core/variables";

.survey-viewer-automation-panel {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0px;
  padding: 32px 32px;
  overflow-y: auto;
  background-color: $black;
  color: $white;

  .title {
    color: inherit;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 16px;
  }
  .description {
    color: inherit;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 20px;
  }
  .automation-name {
    width: 100%;
    background-color: $white;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 30px;
    color: $grey900;
    padding-top: 16px;
    padding-bottom: 16px;

    .result-string {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        border-bottom: 1px solid $grey100 !important;
        padding-bottom: 16px !important;
        margin-bottom: 16px !important;
      }
      .result-title {
        font-weight: 600 !important;
      }
    }
    .result-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
    }
    .tier-result {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $blue50;
      border-radius: 4px;
      padding: 2px;
    }
  }
  .subheading {
    color: $grey300;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    padding-bottom: 5px;
  }
  .result {
    width: 100%;
    background-color: $white;
    border-radius: 6px;
    padding: 10px;
    padding-left: 10px;

    .tier-result,
    .portfolio-result,
    .label-result {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;
    }
    .tier-name {
      font-weight: 500;
      margin-left: 5px;
    }
    .log-item {
      display: flex;
      flex-direction: column;
      padding: 10px;
      padding-left: 10px;
      &:not(:last-child) {
        border-bottom: 1px solid $grey100;
        padding-bottom: 6px;
        margin-bottom: 10px;
      }
      .log-name {
        font-weight: 600;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      .subli {
        float: left;
      }
      li:not(:last-child) {
        margin-bottom: 5px;
      }
      li:first-child {
      }
    }
  }

  .withlist {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .ug-select {
    margin-bottom: 32px;
    .ug-select__control {
      height: 40px !important;
    }
  }
  .error {
    color: $red !important;
    div {
      color: inherit;
    }
  }
}
