@use "../../_common/style/core/variables";

.trust-page-content-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: variables.$space-xl;

  .report-card {
    width: 100%;
  }

  .left-col {
    min-width: 400px;
    max-width: 400px;
  }

  .right-col {
    flex: 1 1 auto;
  }
}

.questionnaires-card,
.documents-card {
  .report-card-content {
    padding: 0 !important;
  }
}
