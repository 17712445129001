@import "../core/variables";

.text-field-with-action {
  position: relative;

  .with-buttons {
    input[type="text"] {
      border-bottom-right-radius: 0;
    }

    textarea {
      border-bottom-right-radius: 0;
    }
  }

  .text-field {
    margin-bottom: 0;
  }

  textarea {
    resize: vertical;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .action-button {
      border: 1px solid $grey100;
      border-top: 0;
      height: 24px;
      min-width: 24px;
      background-color: white;

      .btn {
        margin-right: 0;
        height: 24px;
        padding: 4px;

        &.with-text {
          margin-right: 4px;
        }
      }

      span {
        font-size: 10px;
        font-weight: $font-weight-bold;
      }

      .hidden {
        visibility: hidden;
      }

      .btn-inner {
        position: relative;

        div {
          color: $grey900;
        }
        span {
          color: $grey900;
        }
      }

      &.disabled {
        .btn-inner {
          div {
            color: $grey300;
          }
          span {
            color: $grey300;
          }
        }
      }

      &:not(.disabled):hover {
        border-color: $blue300;
        background-color: $blue100;
        // ensures the colored border shows on hover
        // since we are using a margin-left -1 below
        z-index: 1;

        .btn-inner {
          div {
            color: $blue600;
          }
          span {
            color: $blue600;
          }
        }
      }

      .loader-icon.executing-action {
        fill-opacity: 0;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 2px;
        margin: 0 auto;

        path {
          color: $blue600;
          fill: $blue600;
        }
      }
    }

    div:first-of-type {
      border-bottom-left-radius: 4px;
    }

    div:not(:first-of-type) {
      border-left: 0;
    }

    div:last-of-type {
      border-bottom-right-radius: 4px;
    }
  }
}
