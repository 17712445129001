@use "../../_common/style/core/variables";

.threat-monitoring-feed {
  width: 100%;
  padding: 24px 24px;

  .empty-feed {
    background-color: variables.$grey50;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 64px !important;
    border-radius: 8px;
    margin-bottom: 32px;
    .btn {
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 24px;
      background-color: variables.$white;
      .cr-icon-graph {
        font-size: 20px;
      }
    }
    img {
      margin-bottom: 16px;
    }
    .primary {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    .sub {
      color: variables.$grey500;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .cr-icon-cal {
      font-size: 1.7em;
      color: inherit;
    }
    .contact-support {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      align-items: center;
      color: variables.$grey500;

      .text {
        color: inherit;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .cr-icon-arrow-right {
        font-size: 1.3em;
        color: inherit;
      }
    }
  }
  .info-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 16px;
    font-size: 14px;

    .counts {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 32px;
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: variables.$grey900;

      div {
        color: inherit;
      }

      .selected-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 4px;
        font-weight: 500;
        border: 1px solid transparent;

        .cr-icon-chevron {
          font-size: 12px;
          margin-left: 8px;
        }

        &:hover {
          div {
            color: variables.$blue500;
          }
          .cr-icon-chevron {
            color: variables.$blue500;
          }
          background-color: variables.$blue50;
          border-radius: 2px;
        }
      }

      .sort-option {
        padding: 4px;
        cursor: pointer;

        span {
          color: variables.$grey900;
          padding: 2px;
        }

        &:hover {
          background-color: variables.$blue100;
        }

        height: 30px;
        width: 170px;
        padding: 8px;
      }
    }

    .export {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .btn-icon {
        color: variables.$grey500;
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    span {
      font-weight: 400;
      color: variables.$grey500;
    }

    .value {
      font-weight: 900;
      color: variables.$grey500;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  .info-banner-line .message {
    width: 100% !important;
    margin-right: 0px;
  }

  .threat-monitoring-feed-table {
    tr {
      background-color: transparent !important;
    }

    tr:not(:last-child) td {
      padding-bottom: 16px !important;
    }

    td {
      padding: 0px !important;
    }

    th {
      display: none !important;
    }

    td.loading {
      min-height: 200px !important;
      height: 200px !important;
    }
  }

  .x-table table td {
    border-top: none;
  }
}

.threat-monitoring-feed.hidden {
  display: none;
}
