@use "../../_common/style/core/variables";

.security-links-card-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: variables.$space-md;

  .security-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1 1 auto;
    color: variables.$blue500;

    font-size: variables.$font-size;
    font-weight: variables.$font-weight-bold;
    line-height: 22px;

    &clickable {
      cursor: pointer;
    }

    &:hover {
      text-decoration: underline;
    }

    .actions-col {
      width: 30px;
      flex-grow: 0;

      .cr-icon-external-link {
        margin: auto;
        color: variables.$blue500;
        font-size: variables.$font-size-extra-large;
        font-weight: variables.$font-weight-bold;
      }
    }
  }
}
