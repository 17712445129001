@use "../../../../_common/style/core/variables";

.check-result-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: variables.$space-xl variables.$space-xl;
  gap: variables.$space-xl;

  .check-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .risk-name {
      &.waived {
        font-style: italic;
        color: variables.$grey400;
      }

      margin-right: variables.$space-sm;
    }

    .managed-status-pill,
    .citations-pill {
      margin: 0;
    }

    .managed-status-pill {
      margin-left: auto;
    }

    .citations-pill:only-child {
      margin-left: auto;
    }

    .pill-label + .pill-label {
      margin-left: variables.$space-xs;
    }
  }

  .cr-icon-chevron {
    margin-left: auto;
  }

  &:hover {
    background-color: variables.$blue100;
    cursor: pointer;
  }

  &.ignored {
    opacity: 0.2;
  }
}
