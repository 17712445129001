@import "../../../../_common/style/core/variables";

.add-btn-wrapper,
.change-question-btn-wrapper {
  position: relative;
  margin-top: 0;

  .add-btn {
    width: 24px;
    height: 24px;
    filter: drop-shadow(0px 1px 2px rgba(64, 70, 104, 0.06))
      drop-shadow(0px 1px 3px rgba(64, 70, 104, 0.1));
    cursor: pointer;
    background-color: $white;
    border-radius: 100%;

    &:hover:after {
      color: $blue500;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: $font-weight-bold;
      font-size: 18px;
      color: $grey300;
      content: "+";
    }
  }
}
