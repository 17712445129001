@use "sass:list";
@use "../../../_common/style/core/variables";

.pill-label {
  display: inline-flex;
  padding: 2px 10px;
  font-size: 10px;
  line-height: 16px;
  border-radius: 16px;
  font-weight: variables.$font-weight-bold;
  letter-spacing: 1px;
  white-space: nowrap;
  border: 1px solid transparent;
  align-items: center;
  position: relative;

  // adjust font size for icons
  // as required
  .cr-icon-info {
    font-size: variables.$font-size;
  }

  &.constrained {
    .pill-label-content {
      max-width: 249px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.align-center {
    .pill-label-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    [class^="cr-icon-"],
    [class*=" cr-icon-"] {
      display: block;
      margin: auto;
    }
  }

  &.large {
    padding: 4px 12px;
    font-size: 12px;
  }

  &.clickable {
    cursor: pointer;
    transition: background 100ms ease-in-out;
  }

  &.capitalized {
    text-transform: capitalize;
  }

  &.removeable {
    position: relative;

    .remove-x {
      font-weight: inherit;
      display: inline-block;
      padding: 0 0 0 8px;
      color: inherit;
      cursor: pointer;
      transition: color variables.$standard-transition;

      &:hover {
        color: variables.$black;
      }
    }
  }

  & + .pill-label {
    margin-left: 2px;
  }

  &.add-label {
    border-style: dashed !important;
    font-weight: variables.$font-weight-regular;
    font-style: italic;
  }

  // Loop over each color pair to generate the CSS for each
  @each $pair in variables.$label-colors {
    $name: list.nth($pair, 1);
    $color: list.nth($pair, 2);
    &.#{$name} {
      background: rgba($color, 0.05);

      &,
      * {
        color: $color;
      }

      &.clickable:hover {
        background: rgba($color, 0.2);
      }

      &.filled {
        background: $color;

        &,
        * {
          color: variables.$white;
        }
      }

      &.bordered {
        border-color: $color;
      }
    }
  }
}

/* When wrapped in a popup match the default positional styling is much as possible */
.pill-popup {
  display: inline-flex;

  .dismissable-popup-click-capture {
    display: inline-flex;
  }
}

.pill-popup-content {
  width: max-content !important;
  max-width: 150px;
}
