@use "../../core/variables";

.header-metadata-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;

  div.metadata-widget {
    display: flex;
    flex-direction: row;

    .header-icon {
      width: 24px;
      height: 24px;
      background-color: variables.$blue100;
      border-radius: 4px;
      position: relative;
      margin-right: 10px;

      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: variables.$blue500;
        font-size: 16px;
      }
    }

    .header-label {
      color: variables.$grey600;
      font-size: variables.$font-size;
      line-height: 150%;
      font-weight: variables.$font-weight-bold;
      white-space: nowrap;
    }
  }

  div.metadata-divider {
    margin-left: 16px;
    margin-right: 16px;
    width: 1px;
    background-color: variables.$grey200;
    align-self: stretch;
  }
}
