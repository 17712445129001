@use "../../_common/style/core/variables";

.modal-content-container:has(.add-questionnaires-modal) {
  .modal-header p,
  .modal-content p {
    margin-bottom: 0 !important;
  }

  .modal-content {
    padding: 0 variables.$space-xl variables.$space-lg !important;
  }
}

.add-questionnaires-modal {
  .horz-line {
    border-top: solid 1px variables.$grey100;
    width: 100%;
    margin: variables.$space-md 0;
  }

  h3 {
    color: variables.$grey900;
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-bold;
    line-height: variables.$line-height;
  }

  div.label {
    text-align: left;
    font-weight: variables.$font-weight-bold;
    margin-bottom: 4px;

    &.required:after {
      content: "*";
      color: variables.$red500;
    }
  }

  .questionnaire-type-selector {
    display: flex;
    flex-direction: column;
    gap: variables.$space-sm;
    padding-top: variables.$space-sm;
    padding-bottom: variables.$space-lg;
  }

  .import-questionnaire-details {
    display: flex;
    flex-direction: column;

    .text-field,
    .date-picker {
      > input,
      > textarea {
        max-width: unset !important;
      }
    }
  }
}
