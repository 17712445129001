@import "../../../../_common/style/core/variables";

.check-result-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $space-xl $space-xl;
  gap: $space-xl;

  .check-text {
    display: flex;
    flex-direction: row;
    align-items: center;

    .risk-name {
      &.waived {
        font-style: italic;
        color: $grey400;
      }
    }

    .managed-status-pill {
      margin: 0;
      margin-left: $space-md;
    }
  }

  .cr-icon-chevron {
    margin-left: auto;
  }

  &:hover {
    background-color: $blue100;
    cursor: pointer;
  }
}
