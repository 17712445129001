@import "../../../../_common/style/core/variables";

.status-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .status-count {
    margin-left: $space-sm;
    color: $grey500;
    text-align: left;
    width: 20px;
  }

  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    height: 24px;
    width: 24px;
    margin-right: $space-sm;

    div {
      color: $white;
      font-size: 11px;
    }

    &.passed {
      background-color: $green500;
    }

    &.partial {
      background-color: $orange400;
    }

    &.failed {
      background-color: $red500;
    }

    &.unknown {
      background-color: unset;
      border: 1px dashed $grey400;

      div {
        color: $grey400;
      }
    }

    /*TODO:NSS add to cyberrisk-icons and remove from here*/
    .cr-icon-cross {
      &:before {
        content: "\2715";
      }
    }

    /*TODO:NSS add to cyberrisk-icons and remove from here*/
    .cr-icon-dash {
      &:before {
        content: "\2501";
      }
    }
  }
}
