@use "../core/variables";

.breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: variables.$grey500;
  font-size: 12px;

  a,
  div {
    color: inherit !important;
    text-transform: none !important;
    letter-spacing: 1px;
    font-size: inherit;
  }

  .cr-icon-chevron {
    color: inherit;
    font-size: 10px;
  }

  > * + * {
    margin-left: 10px;
  }
}
