@use "../../../_common/style/core/variables";

.shared-profile {
  & > div + div {
    margin-top: 32px !important;
  }

  .header-with-subtext {
    line-height: 125%;
    flex: 1;
    padding-right: 16px;

    .subtext {
      font-size: 14px;
      color: variables.$grey500;
    }
  }

  .header button {
    white-space: nowrap;
  }
}
