@import "../../../_common/style/core/variables";

.severity-selector {
  .severity-opt {
    &.selected {
      color: $white;
    }

    .severity-icon {
      margin-right: 5px;
      img {
        height: 16px;
      }
    }
    text-transform: capitalize;
  }

  .ug-select__control {
    min-width: 260px;
  }
}
