@use "../../_common/style/core/variables";

.survey-viewer-document {
  display: flex;
  align-items: center;
  padding: 24px 20px 24px 24px;
  border: 1px solid variables.$grey100;
  border-radius: 8px;

  &.left {
    background-color: variables.$red50;
    border-color: variables.$red200;
  }

  &.right {
    background-color: variables.$green50;
    border-color: variables.$green500;
  }

  .type-icon {
    img {
      height: 24px;
    }
  }

  .filename-and-description {
    .filename {
      margin-left: 24px;
      margin-right: 24px;
      flex-grow: 1;
      font-size: 14px;
      line-height: 21px;

      &.downloadable {
        color: variables.$blue500;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .description {
      margin-top: 8px;
      margin-left: 24px;
      margin-right: 24px;

      span {
        font-size: 14px;
        line-height: 20px;
        color: variables.$grey600;

        &.red {
          background-color: rgba(variables.$red500, 0.3);
        }

        &.green {
          background-color: rgba(variables.$green500, 0.3);
        }
      }
    }

    .message-input {
      margin-bottom: 0px;
      margin-left: 24px;
      min-width: 300px;
      margin-top: 16px;
    }
  }

  .actions {
    display: inline-flex;
    margin-left: auto;
  }
}
