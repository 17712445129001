@use "../../../_common/style/core/variables";

.search-box-input-container + .custom-notification-config-card {
  margin-top: 16px;
}

.custom-notification-config-card {
  .header {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: auto;
    }

    .subtext {
      color: variables.$grey500;
      font-size: 14px;
    }
  }

  .header-with-subtext {
    line-height: 125%;
  }

  .notifications-table {
    table {
      table-layout: fixed;

      tr {
        td {
          padding-left: unset;
        }
      }
    }

    .trigger-cell {
      .severity-icon {
        margin-right: 0;

        & > img {
          height: 18px;
        }
      }
      .conditional-desc {
        margin-top: 8px;
        font-size: 12px;
        color: variables.$grey500;
      }
    }

    .custom-cell {
      width: 120px;
      max-width: 120px;

      .pill-popup + .pill-popup {
        margin-top: 8px;
      }
    }

    .conditions-header-cell {
      width: 120px;
      max-width: 120px;
    }

    .description-header-cell {
      max-width: 320px;
      width: 320px;
    }

    .description-cell {
      padding-left: 32px;
      max-width: 320px;
      width: 320px;
      vertical-align: top;

      .description-div {
        margin-top: 26px;
        max-width: 320px;

        h3 {
          color: variables.$grey900;
          font-size: 14px;
          font-weight: variables.$font-weight-bold;
          line-height: 16px;
        }

        p {
          color: variables.$grey500;
          font-size: 14px;
          font-weight: variables.$font-weight-bold;
          line-height: 16px;
        }
      }
    }

    .variable-highlight {
      font-weight: variables.$font-weight-bolder;
    }

    .options-icons-cell {
      width: 96px;
      max-width: 96px;
    }
  }
}
