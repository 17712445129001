@use "../../core/variables";

.header-main-section {
  display: flex;
  flex-direction: row;
  align-items: center;

  .name {
    font-weight: variables.$font-weight-bold;
    font-size: 16px;
    line-height: 32px; // Same as button height, so it's consistent with right-aligned buttons

    .domain {
      color: variables.$grey300;

      a {
        color: variables.$blue;
        text-transform: none;
      }
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .button-group:not(:last-child) {
      margin-right: 12px;
    }

    .btn-tertiary {
      .btn-inner,
      .cr-icon-chevron {
        color: variables.$grey300;
      }

      &:hover .btn-inner,
      &:hover .cr-icon-chevron {
        color: variables.$grey500;
      }

      & + * {
        margin-left: 10px;
      }

      .cr-icon-chevron {
        margin-left: 8px;
      }
    }

    .btn:not(.btn-tertiary):not(.btn-primary) {
      border-color: variables.$grey200;

      &:hover {
        background: none;

        .btn-inner,
        .btn-inner div {
          color: variables.$font-color;
        }
      }

      .btn-inner,
      .btn-inner div {
        color: variables.$grey500;
      }
    }

    .dot-menu {
      &.open {
        .popup-target {
          button {
            background-color: variables.$blue200;
            color: variables.$blue500;
          }
        }
      }

      .cr-icon-dots-menu {
        margin-right: 0;
        font-size: 20px;
      }

      .popup-content {
        width: 200px;
      }

      .dot-menu-section {
        display: flex;
        flex-direction: column;

        .dot-menu-item {
          padding: 15px;
          display: flex;
          align-items: center;
          color: variables.$grey900;
          cursor: pointer;

          &:not(.disabled):hover {
            background: #e8eef9;
            color: #1757c2;

            .menu-icon {
              color: #1757c2;
            }
          }

          .cr-icon-trash {
            font-size: 20px;
          }

          &.disabled {
            opacity: 0.5;
            cursor: default;
          }

          &:not(:last-of-type) {
            border-bottom: variables.$grey100 solid 1px;
          }

          .menu-icon {
            font-size: 16px;
            padding-right: 12px;

            &.cr-icon-trash {
              font-size: 22px;
              margin-right: -6px;
            }
          }
        }
      }
    }
  }

  .name-widget {
    display: flex;
    flex-direction: row;
    margin-left: 4px;
  }
}
