@use "../../../_common/style/core/variables";

.risk-assessment-comment-readonly {
  padding: 16px;
  border-radius: 4px;
  background: variables.$blue50;
  display: flex;
  flex-direction: column;

  .sub-title {
    color: variables.$grey500;
    font-size: variables.$font-size-extra-small;
    font-weight: variables.$font-weight-bold;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .comment {
    color: variables.$grey900;
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-regular;
    line-height: 22px;
    margin-top: 8px;
    white-space: pre-line;
  }
}

// need to annoyingly override the style on hover though the table class that this is in
.x-table {
  table {
    tbody tr {
      &.clickable {
        &:hover + .extra-content-row {
          .risk-assessment-comment-readonly {
            background: variables.$blue100;
          }
        }
      }
    }
  }
}

.risk-assessment-comment {
  display: flex;
  flex-direction: row;
  width: 100%;

  .right-side {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    width: 135px;
    text-align: right;

    .sub-title {
      color: variables.$grey500;
      font-size: variables.$font-size-extra-small;
      font-weight: variables.$font-weight-bold;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .dismissible-popup-container {
      width: min-content;
      margin-left: auto;
    }

    i {
      color: variables.$grey500;
    }
  }

  .text-entry {
    flex-grow: 1;
  }
}
