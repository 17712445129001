@import "../../_common/style/core/variables";

#new-org-modal {
  .paddedInputRow {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .radiolist {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: flex-start;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

#edit-org-modal {
  .paddedInputRow {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .radiolist {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: flex-start;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

#new-org-modal,
#edit-org-modal {
  form {
    text-align: left;
  }

  .radiolist {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;

    &.vert {
      flex-direction: column;
    }

    .input-action-container {
      .checkbox-container {
        min-width: 21px;
      }
    }
  }

  .warning {
    background-color: $red;
    color: $white;
    font-weight: $font-weight-bold;
    padding: 20px;
  }

  .disabled-org label {
    color: #dcdcdc;
  }
  .disabled-org input {
    color: #dcdcdc;
  }
  .disabled-org p {
    color: #dcdcdc;
  }
  .disabled-org span {
    color: #dcdcdc;
  }
  .disabled-org {
    color: #dcdcdc;
  }

  .disabled-org-no-display {
    display: none;
  }

  .spacer {
    width: 10px;
    min-width: 10px;
  }

  .vertspacer {
    height: 20px;
  }

  .red-text {
    color: $red;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }

  .trialexpired {
    color: $red;
    font-weight: $font-weight-bold;
  }

  .pill-column {
    padding-left: 5px;
    padding-right: 5px;
  }

  .left-align {
    text-align: left;
    margin: 10px 0px;
  }

  .radiolist {
    .color-checkbox {
      margin-right: 10px;
      width: unset;
    }
  }

  input + .color-checkbox {
    margin-top: 10px;
  }

  .user-limit-input {
    margin-top: 16px;
  }

  .internal-check {
    margin-top: 0;
    margin-bottom: 16px;
  }
}
