@import "../../../_common/style/core/variables";

.app-status {
  display: flex;
  flex-direction: row;
  align-items: center;

  .app-status-icon {
    margin-right: 5px;
  }
}

.partially-approved-container {
  display: flex;
  flex-direction: column;
  gap: $space-sm;

  .popup-section {
    display: flex;
    flex-direction: column;
    gap: $space-sm;
    padding-bottom: $space-md;

    &:not(:last-child) {
      border-bottom: 1px solid $grey100;
    }

    .popup-header {
      text-transform: uppercase;
      font-size: $font-size-extra-small;
      font-weight: $font-weight-bold;
      line-height: $line-height-xsmall;
      letter-spacing: 1px;
      color: $grey500;
    }

    .item-list > .item-pill {
      margin: 2px;
    }
  }
}
