@use "../../_common/style/core/variables";

.autosave-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .loader-icon {
    width: 16px;
    height: 16px;
  }

  .last-saved-text {
    font-size: 12px;
    color: variables.$grey500;
    white-space: nowrap;
  }
}
