@import "../../../_common/style/core/variables";

.remediation-add-user-modal {
  padding-left: 100px !important;
  padding-right: 100px !important;

  .btn-group {
    margin-top: 20px;
    display: flex;

    button {
      height: 35px;
    }

    button:not(:last-of-type) {
      margin-right: 20px;
    }
  }
}

.remediation-add-user-form {
  .user-rows {
    margin: 10px 0 20px 0;

    .user-row {
      height: 35px;
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 5px;

      &.invite {
        font-style: italic;
      }

      img,
      .img-placeholder {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      .avatar {
        width: 25px;
        height: 25px;
        overflow: hidden;
        border-radius: 50%;
        margin: 0px;
        margin-left: 1px;
        margin-right: 10px;
        color: #fff;
      }

      &.selectable:hover {
        background-color: lighten($blue, 50%);
        cursor: pointer;
      }

      &.selected {
        background-color: lighten($blue, 50%);
        box-shadow: inset 0px 0px 1px 0px $blue;
      }
    }
  }

  .popup-description {
    display: inline-block;
    position: relative;
    margin-left: 10px;

    .icon-info {
      color: $medium-grey;
      font-size: 16px;
    }

    .content {
      position: absolute;
      left: 0;
      top: 25px;
      background-color: $white;
      opacity: 0;
      transition: opacity $animation-timing ease-in-out;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      padding: 10px;
      pointer-events: none;
      font-size: 12px;
      line-height: 14px;
      color: $black !important;
      min-width: 250px;
      z-index: 1;
    }

    &:hover .content {
      opacity: 1;
    }
  }

  .new-user-form {
    margin-top: 10px;
  }

  .send-to-new {
    cursor: pointer;
    color: $blue;
    margin-top: 10px;
  }
}
