@use "../../_common/style/core/variables";

.qn-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  padding-bottom: 16px;

  > * {
    word-wrap: break-word;
  }

  .cr-icon-questionnaire {
    color: variables.$grey500;
    background-color: variables.$grey100;
  }

  .qn-details {
    flex: 1;
    line-height: 150%;
    color: variables.$grey900;
    font-size: variables.$font-size;

    .qn-last-updated {
      color: variables.$grey600;
      font-size: variables.$font-size-smaller;
    }

    .qn-description {
      margin-top: 16px;
      border-radius: 4px;
      border-left: 4px solid variables.$grey200;
      background: variables.$grey50;
      padding: 4px 12px;
      color: variables.$grey600;
      font-size: variables.$font-size-smaller;
      font-style: normal;
      line-height: variables.$line-height-small;
    }
  }

  .qn-btn {
    .cr-icon-lock {
      color: variables.$grey900;
    }
  }
}
