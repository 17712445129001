@import "../../../_common/style/core/variables";

.cloud-provider-filter {
  .cloud-provider-filter-value {
    .label {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: $space-xs;
      }
    }
  }
}
