@import "../../../_common/style/core/variables";

.vulns-slide-panel.vulns-slide-panel-new-risk-designs {
  div.vuln-summary {
    border: 0;
    padding: 0px 0px;
  }

  div.vuln-info-label {
    color: $grey500;
    font-size: $font-size-extra-small;
    width: 160px !important;
    letter-spacing: 1px;
  }

  div.vuln-info-card {
    padding-top: 0;
  }

  div.vuln-info-row {
    padding: 0 !important;
    height: 60px;
    border-top: 1px solid $grey100;
    align-items: center;
    flex-direction: row;
    display: flex;
  }

  .kev-info-banner p.subtext {
    margin-bottom: 0;
    margin-top: 4px;
  }

  .slide-panel-section {
    .section-header {
      color: $grey900;

      &:hover {
        color: $blue500;
      }

      span {
        color: inherit;
      }

      h3 {
        color: inherit;

        div.section-title {
          color: inherit;
        }
      }
    }

    .section-body > :not(:last-child) {
      margin-bottom: 0;
    }

    .section-body > div.reference {
      display: flex;
      border: 0;
      color: $blue500;
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
      max-width: unset;
      width: unset;
      margin-bottom: 10px;

      &:hover {
        text-decoration: none;
      }

      a,
      a:hover {
        color: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //flex-grow: 1;
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      .cr-icon-external-link {
        margin-left: 3px;
        color: inherit;
        font-size: $font-size-larger;
      }
    }

    .section-body > div.pill-label {
      margin-bottom: 8px !important;
    }
  }

  div.pill-label {
    margin-bottom: 0 !important;
  }

  .vuln-info-pills-value {
    display: flex;
    flex-direction: row;
  }

  div.severity-icon {
    img {
      display: block;
    }
  }
}

.vulns-slide-panel {
  .slide-panel {
    width: 700px !important;

    .title {
      padding-right: 40px;
      border-bottom: none !important;
    }
  }

  .slide-panel-section {
    border-top: solid 1px $grey100 !important;
  }

  .back-arrow {
    top: 25px;
    left: 40px;

    .popup {
      left: 30px;
      top: 0;
    }
  }

  .loading-overlay {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    bottom: -200px;
  }

  .vuln-title {
    position: relative;
    padding-bottom: 40px;
    padding-left: 40px;
    width: 658px;
    display: flex;

    .title-block {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3 {
        font-size: 24px !important;
        flex-grow: 1;
      }
      .pills {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
      }
    }

    .btn {
      max-height: 32px;

      .btn-inner {
        white-space: nowrap;
      }
    }
  }

  .vuln-info-card {
    border-top: 1px solid $grey50;
    padding: 32px 40px;

    .vuln-info-row {
      display: flex;

      &:not(:last-child) {
        padding-bottom: 20px;
      }

      .vuln-info-label {
        width: 200px;
        font-weight: $font-weight-bold;
      }

      .cvss-severity {
        text-transform: capitalize;
        position: relative;
        top: -4px;
      }
    }
  }

  .kev-info-banner {
    .subtext {
      margin-top: 10px;
      color: inherit;
      .link {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  .vuln-summary {
    padding: 32px 40px;
    line-height: 20px;
    color: $grey800;

    .exploit-removed {
      color: inherit;
      margin-top: 32px;
      .removed-title {
        text-transform: uppercase;
        color: $grey500;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }
      .removed-desc {
        color: $grey800;
      }
    }
  }

  .reference {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $grey500;
    border-bottom: 1px solid $grey500;
    width: fit-content;
    max-width: 400px;

    a,
    a:hover {
      color: $grey500;
      text-transform: none !important;
      text-decoration: none;
    }
  }

  .pill-label {
    margin-right: 5px;
    margin-bottom: 5px !important;
  }
}
