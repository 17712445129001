@use "../core/variables";

.date-range-slider {
  .range-slider .bg-line {
    background-color: variables.$grey200;
  }

  .date-displays {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    margin-top: 5px;

    .reset-link {
      position: absolute;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      color: variables.$blue;
    }

    .date-display {
      color: variables.$light-grey;

      .date-picker {
        font-size: 14px;
        max-width: 110px;
        text-align: center;
      }

      &.highlight {
        color: variables.$primary-black;
      }
    }
  }
}
