@use "../../_common/style/core/variables";

.add-btn-wrapper:has(.add-btn-popup) .add-btn-popup {
  position: absolute;
  top: 0;
  left: calc(100% + 5px);
}

.change-question-btn-wrapper:has(.add-btn-popup) .add-btn-popup {
  position: absolute;
  top: calc(100% + 5px);
  left: 12px;
}

.add-btn-popup {
  display: flex;
  width: fit-content;
  background-color: variables.$white;
  box-shadow:
    0px 2px 4px rgba(64, 70, 104, 0.06),
    0px 4px 6px rgba(64, 70, 104, 0.1);
  border-radius: 4px;
  z-index: 1;
  flex-direction: row;
  padding: 4px;

  .add-btn-popup-main {
    z-index: 1;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 20px 6px 8px;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background-color: variables.$blue50;
        color: variables.$blue;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  .node-type-icon {
    margin-right: 10px;
  }

  .add-btn-popup-help {
    .heading {
      font-weight: variables.$font-weight-bold;
      margin-bottom: 5px;
      color: variables.$font-color;
    }

    color: variables.$grey500;
    line-height: 150%;
    font-size: 12px;
    background-color: variables.$blue50;
    width: 200px;
    padding: 16px;
    border-radius: 4px;

    &.first-hover {
      border-top-left-radius: 0;
    }

    &.last-hover {
      border-bottom-left-radius: 0;
    }
  }
}
