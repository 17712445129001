@use "../../../_common/style/core/variables";

.userbase-dashboard-container {
  padding: variables.$space-lg variables.$space-xl;
  border-bottom: 1px solid variables.$lighter-grey;

  .dashboard-title {
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-bold;
    margin-bottom: variables.$space-md;
  }

  .dashboard-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
