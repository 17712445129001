@import "../../_common/style/core/variables";

.content-library-browser-dup-doc-modal-foot {
  .footer-left .cr-icon-external-link {
    font-size: 18px;
  }
}

.content-library-browser-modal {
  width: 1000px;
  padding: 0 !important;

  .content-library-browser-main {
    display: flex;
    flex-direction: column;

    .empty-state-circle {
      align-self: center;
      margin: 60px 0;
    }

    .search-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 32px;
      border-bottom: 1px solid $grey100;

      > * + * {
        margin-left: 12px;
      }

      .search-box-input-container {
        flex: 1;
      }
    }

    .content-library-table {
      .name-cell {
        .name-cell-inner {
          display: flex;
          flex-direction: row;
          align-items: center;

          .file-type {
            width: 32px;
            margin-right: 15px;
          }
        }
      }

      .add-btn {
        width: 90px;
      }
    }
  }

  .content-library-browser-upload {
    padding: 32px;
    display: flex;
    flex-direction: column;

    label {
      margin-top: 24px;
    }

    input {
      max-width: unset;
    }

    .dragdropupload {
      > div {
        min-height: unset !important;
      }
    }

    .actions-row {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.content-library-browser-modal-header {
  border-bottom: 1px solid $grey100;

  .header-tabs {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    line-height: 150%;

    .tab {
      padding: 8px 0 16px 0;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .tab-icn {
        font-size: 24px;
        margin-right: 8px;
      }

      &.active,
      &:hover {
        &,
        .tab-icn {
          color: $blue500;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: $blue500;
        }
      }

      & + .tab {
        margin-left: 24px;
      }
    }
  }
}
