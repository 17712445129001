@import "../../../_common/style/core/variables";

.evidence-document {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  border-radius: $card-border-radius;
  border: 1px solid $light-grey;
  height: 95px;
  width: 100%;

  img {
    width: 48px;
    max-width: 48px;
    margin-right: 4px;
    margin-top: -4px;
  }

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    max-width: 78%;

    .name-row {
      display: flex;
      flex-direction: row;
      font-weight: $font-weight-bold;
    }

    a {
      text-transform: unset;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-name {
      height: fit-content;
      color: $primary-black;
      font-size: 14px;
      margin-top: 10px;
      margin-left: 5px;
    }
    .uploaded-by {
      height: fit-content;
      color: $grey500;
      font-size: 14px;
      margin-top: 6px;
    }
  }

  .doc-name {
    width: 100%;
  }

  td {
    flex: 0;
    height: fit-content;
    color: $grey500;
    font-weight: $font-weight-bold;
    font-size: 11px;
  }
  table {
    margin-top: 5px;
    margin-left: -2px;
  }

  .icons {
    flex: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: $grey500;
    margin-left: 20px;

    transition-property: background-color;
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;

    .dismissible-popup-container {
      width: 32px;
      height: 32px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      &:hover:not(.disabled) {
        background-color: $blue50;

        [class^="cr-icon-"],
        [class*=" cr-icon-"],
        [class^="icon-"],
        [class*=" icon-"] {
          color: $blue500;
        }
      }
    }

    [class^="cr-icon-"],
    [class*=" cr-icon-"],
    [class^="icon-"],
    [class*=" icon-"] {
      color: $grey300;
      font-size: 18px;
    }

    .cr-icon-trash,
    .cr-icon-external-link,
    .icon-cycle {
      font-size: 22px;
    }

    .pill-label {
      margin-right: 10px;
      margin-left: 5px;
    }

    .scanning {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 16px;

      .loader-icon {
        height: 12px;
        width: 12px;
        min-height: 12px;
        min-width: 12px;
        margin-right: 5px;
        stroke: $grey500;
      }
    }
    div.dismissible-popup-container {
      border: none;
    }
  }
}
