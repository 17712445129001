@import "../../../../_common/style/core/variables";

.vendor-assessment-v3-summary {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: center;

  box-shadow:
    0px 4px 6px 0px rgba(64, 70, 104, 0.1),
    0px 2px 4px 0px rgba(64, 70, 104, 0.06);

  padding: 24px 32px;

  border: 1px solid $grey100;
  background: white;

  &.draft {
    border: 2px solid $orange500;
    background: $orange-dark50;
    padding: 16px 32px 16px 24px;
  }

  .top-section {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .summary-section {
      margin-right: 32px;

      .title {
        color: $grey500;
        font-size: $font-size-smaller;
        font-weight: $font-weight-bold;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      .content {
        .user-avatar-and-name {
          .user-name {
            font-weight: $font-weight-bold;
          }
        }
      }

      .date {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 32px;

        .date-text {
          color: $grey900;
          font-size: $font-size;
          font-weight: $font-weight-bold;
          line-height: 22px;
          margin-right: 8px;

          &.no-date {
            font-size: $font-size;
            font-weight: $font-weight-regular;
          }
        }

        .cr-icon-pencil {
          font-size: $font-size-larger;
        }

        .reassessment-date-inner {
          display: flex;
          flex-direction: row;
          align-items: center;

          &.red {
            color: $red;
          }

          &.overdue {
            margin-top: -3px;
          }

          .pill-label {
            margin-left: 8px;
            margin-right: 8px;
          }
        }
      }
    }

    .right-section {
      align-self: stretch;
      margin-left: auto;
      padding-left: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-left: 1px solid $grey200;

      &.empty {
        border-left: unset;
      }

      .cr-icon-timer {
        font-size: 16px;
      }

      > :not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .summary-info-bar {
    margin-top: 16px;
    margin-bottom: unset;
  }
}
