@use "../../_common/style/core/variables";

.survey-node {
  padding: 0;
  position: relative;

  .survey-node-contents {
    padding: 32px 32px 48px 16px;
    border-top: 1px solid transparent;

    &.focused {
      background-color: variables.$blue25;
      border-top-color: variables.$grey100; // When focused, this element overlaps separator lines. So add a line back.
    }

    .survey-node-contents {
      padding-right: 0;
    }

    .dismissible-popup-container {
      margin-left: 6px;
    }

    .node-type-input {
      min-width: 250px;
      margin-left: 6px;
    }

    & > * + * {
      margin-top: 16px;
    }

    .validation-error {
      margin-top: 6px;
    }
  }

  .node-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .node-header-left {
      flex: 1;
      min-width: 0;

      > * + * {
        margin-left: 10px;
      }
    }

    .node-header-right {
      > * + * {
        margin-left: 5px;
      }

      .cr-icon-chevron {
        color: variables.$grey900;
      }
    }
  }

  .node-header + * {
    margin-top: 16px;
  }

  .collapsed-section-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
  }

  .standard-input + * {
    margin-top: 16px;
  }

  .input-label {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 24px;
    margin-bottom: -10px;
  }

  .separator-line {
    height: 32px;
    width: 100%;
    position: relative;
    margin-top: -16px;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      top: 50%;
      background-color: variables.$grey100;
      z-index: -1;
    }

    .add-btn-wrapper {
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }

  .survey-node + .separator-line {
    margin-top: 0px;
  }

  .root-node {
    background-color: variables.$blue25;
    padding-top: 16px;
    border-top: none;
  }

  .tree-line-container {
    position: relative;
  }

  .tree-line {
    position: absolute;
    width: 1px;
    background-color: variables.$grey300;
    top: 0;
    bottom: -41px;
    pointer-events: none;
    z-index: 1;
  }

  .tree-line-connector {
    position: absolute;
    height: 10px;
    width: 10px;
    left: -15px;
    transform: translateY(-4px);
    border-width: 1px;
    border-style: solid;
    border-color: transparent transparent variables.$grey300 variables.$grey300;
    pointer-events: none;

    &.arc {
      border-radius: 0px 0px 0px 6px;
    }
    &.tall {
      height: 17px;
    }
  }

  .btn-tertiary {
    align-self: flex-start;
  }
}

// Set up our indentation rules. We'll support 6 levels of indentation.
@for $i from 0 through 6 {
  .indent-#{$i} {
    &.survey-node-contents {
      padding-left: 32px + ($i * 16px);
    }

    &.add-btn-wrapper {
      left: 32px + ($i * 16px);
    }

    &.tree-line {
      left: 33px + ($i * 16px);
    }
  }
}
