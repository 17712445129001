@use "../../core/variables";

.custom-dropdown {
  display: inline-block;
  position: relative;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .dropdown-text {
    outline: none;
    font-size: 0.8rem;
  }

  .icon-meatball {
    font-size: 1.2rem;
  }

  .dropdown-menu {
    box-shadow: variables.$box-shadow;
    transition: opacity linear variables.$animation-timing * 0.6;
    background: variables.$white;
    border-radius: variables.$border-radius;
    opacity: 0;
    padding: variables.$global-padding 0;
    position: absolute;
    text-align: left;
    visibility: hidden;
    white-space: nowrap;
    z-index: 1000;
    margin-top: 8px !important;

    &.open {
      opacity: 1;
      visibility: visible;
    }

    &:not(.right) {
      right: 0;
    }

    &.right {
      left: 0;
    }

    > * {
      display: block;
      padding: (variables.$global-padding * 0.5) variables.$global-padding;
      color: #808080;

      &.hover,
      &:hover {
        background: variables.$light-blue;
        color: #1757c2;
        cursor: pointer;
      }

      &.danger,
      &.danger * {
        color: variables.$red;
      }

      &.hover.danger,
      &:hover.danger {
        color: variables.$white;
        background: variables.$red;
        cursor: pointer;
      }

      &.hover.danger *,
      &:hover.danger * {
        color: variables.$white;
      }
    }
  }
}
