@import "../../_common/style/core/variables";

$sidebarWidth: 400px;
$commentsWidth: 400px;
$collapsedWidth: 48px;

.survey-viewer-container {
  height: 100vh;
  position: fixed;
  width: 100vw;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;

  .survey-viewer-top-message-bar {
    background-color: $blue;
    color: white;

    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: margin-right 250ms ease-in-out;
    z-index: 21;

    &.diff-mode {
      background-color: $changes-blue;
    }

    .dropdown-v2 {
      .btn {
        padding: 0 12px;
        margin: unset;
        border: 1px solid $white;
        border-radius: 4px;

        &:hover {
          background-color: $blue800;
        }
      }

      &.single {
        .popup-content {
          right: 30px;
        }

        .btn {
          margin-right: 24px;
        }
      }

      .cr-icon-chevron {
        transition: transform 250ms ease-out;
      }

      .dropdown-active {
        background-color: $blue;

        .cr-icon-chevron {
          transition: transform 250ms ease-out;
          transform: rotate(270deg);
        }
      }

      .dropdown-item {
        &.selected {
          color: $primary-black;
          background-color: $light-blue;
          border-left: 4px solid $blue500;
        }

        &:first-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        &:last-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }

    .version-selection-container {
      display: flex;
      flex-grow: 1;
      transition:
        margin-right 250ms ease-in-out,
        margin-left 250ms ease-in-out;

      .version-selection {
        display: flex;
        justify-content: space-around;
        flex-grow: 1;
        margin-left: 33%;
      }
    }

    &.left-panel-active .version-selection-container {
      margin-left: calc($sidebarWidth - 70px);
    }

    &.right-panel-active .version-selection-container {
      margin-right: calc($commentsWidth + 102px);
    }

    .btn {
      margin-left: 24px;

      .btn-inner {
        color: white;

        > * {
          color: white;
        }
      }

      &:hover {
        .btn-inner {
          color: $blue100;

          > * {
            color: $blue100;
          }
        }
      }
    }

    .content-div {
      flex-grow: 1;
      display: flex;
      justify-content: center;

      .message-content {
        color: white;
        font-weight: $font-weight-bold;
        line-height: 21px;
      }
    }

    &.has-content {
      min-height: 48px;
    }
  }

  .survey-viewer {
    flex-grow: 1;
    display: flex;
    min-height: 1px; // For flex to work correctly

    .survey-viewer-sidebar {
      max-width: $sidebarWidth;
      min-width: $sidebarWidth;
      height: 100%;
      overflow: auto;
      background-color: white;
      z-index: 11;
      border-right: 1px solid $grey100;
      position: relative;
      transition: all 250ms ease-in-out;

      &.disabled {
        pointer-events: none;
      }

      .sidebar-collapsed {
        display: none;
      }

      .sidebar-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 16px 24px;
        position: sticky;
        top: 0;
        z-index: 5;
        background-color: #fff;
        border-bottom: 1px solid $grey100;

        .header-title {
          margin-right: auto;
          color: $grey600;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;
        }

        .btn-icon {
          &,
          & > * {
            color: $grey500;
          }

          &:hover:not(.disabled) {
            &,
            & > * {
              color: $blue500;
            }
          }
        }

        .toggle-dropdown {
          z-index: 1;
          .popup-content {
            z-index: 20;
            padding: 20px;
            white-space: nowrap;
          }
        }
      }

      .sidebar-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 8px 24px;
        position: sticky;
        top: 65px;
        z-index: 1 !important;
        background-color: #fff;
        border-bottom: 1px solid $grey100;

        .header-filter {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 0px;
          padding-left: 0px;
          width: 100%;
          color: $grey600;
          font-size: 12px;
          z-index: 1;

          .filter-button {
            letter-spacing: 1px;
            padding: 4px 8px;

            &:focus {
              color: $blue500;
              background-color: $blue100;
              border-color: $blue500;
              border-radius: 4px;
            }
          }

          .dropdown-v2 {
            right: unset;
            .filter-option {
              width: 220px;
              position: relative;
            }
          }

          .filter-buttons {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            height: 32px;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 1px;

            .counts {
              padding-left: 8px;
            }

            .sep {
              display: inline-block;
              width: 1px;
              height: 18px;
              vertical-align: -4px;
              margin-left: 10px;
              margin-right: 5px;
              background-color: $grey500;
            }
            .nav-btn {
              margin-left: 2px;
              margin-right: 2px;
              padding-left: 4px;
              padding-right: 4px;
              width: 24px;
              height: 24px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &:hover {
                background-color: $blue50 !important;
                color: $blue500 !important;
                border-radius: 4px;
              }
              &.disabled {
                cursor: not-allowed;
                &:hover {
                  background-color: unset !important;
                }
              }
            }
            i {
              padding: 0px;
              cursor: pointer;
              &.icon-close {
                margin: 0px;
              }
              &.rotate-270 {
                margin: 0px;
              }
              &.disabled {
                cursor: not-allowed;
                color: $grey300;
              }
            }
          }
          .category-selector {
            width: 200px;
            .ug-select__control {
              min-width: auto;
            }

            .ug-select__menu {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }

      .sidebar-item-container {
        z-index: 11;
        margin-top: 10px;

        .sidebar-item {
          padding: 8px;
          margin-left: 24px;
          margin-right: 16px;
          margin-bottom: 8px;
          border-radius: 8px;
          cursor: pointer;
          transition: all 250ms ease-in-out;
          display: flex;
          align-items: center;
          min-height: 48px;
          overflow: hidden;
          border: 2px solid transparent;

          .survey-viewer-icon {
            align-self: flex-start;
            margin-right: 12px;
            min-height: 32px;
          }

          .node-column {
            display: flex;
            flex-direction: column;
          }

          .node-info {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .changed-label {
            margin-top: 4px;
            color: $grey600;
            font-size: $font-size-smaller;
            line-height: 150%;
            display: flex;
            flex-direction: row;
            align-items: center;

            .cr-dot {
              margin-right: 8px;
            }
          }

          .node-name {
            margin-right: 8px;
            color: $grey600;
            font-size: 14px;
            line-height: 21px;
            transition: all 250ms ease-in-out;

            // Limit to 2 lines, despite the browser-specific extensions, should work in all major browsers
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 42px;

            // If node name has HTML inside it, provide some default styling
            p {
              color: $grey600;
            }

            a {
              text-decoration: underline;
              text-transform: none;
            }

            p:first-child {
              margin-top: 0px;
            }
          }

          .sidebar-item-right-content {
            margin-left: auto;
            display: inline-flex;
            align-self: flex-start;

            .expander {
              margin-left: 4px;

              .btn-icon {
                .icon-chevron {
                  transition: all 250ms ease-in-out;
                  font-size: 19px;
                  color: $grey300;
                }
              }
            }

            .pill-label {
              height: 24px;
              align-self: center;
            }

            .check-mark {
              margin: 5px 6px 0px 6px;
            }

            .cr-icon-question-alert {
              color: $orange500;
              font-size: 24px;
              line-height: 42px;
              transition: all 250ms ease-in-out;
            }
          }

          &.section {
            font-weight: $font-weight-bold;
          }

          &.has-suggestion {
            .survey-viewer-icon {
              background-color: $orange20;
              .icon {
                i {
                  color: $orange600;
                }
              }

              .display-id {
                color: $orange600;
              }
            }
          }

          &.answered {
            .survey-viewer-icon {
              .icon {
                i {
                  color: $grey300;
                }
              }

              .display-id {
                color: $grey300;
              }
            }

            .changed-label {
              color: $grey300;
            }

            .node-name {
              color: $grey300;

              // If node name has HTML inside it, provide some default styling
              p {
                color: $grey300;
              }

              a {
                text-decoration: underline;
                text-transform: none;
              }
            }

            .sidebar-item-right-content {
              .pill-label {
                opacity: 0.5;
              }

              .check-mark.checked {
                > .cr-icon-check {
                  color: $grey300;
                }
              }
            }
          }

          &.active {
            border-color: $blue500;
          }

          &:hover,
          &.active {
            .survey-viewer-icon {
              background-color: $blue50;

              .icon {
                i {
                  color: $blue500;
                }
              }

              .display-id {
                color: $blue500;
              }
            }

            .changed-label {
              color: $blue500;
            }

            .node-name {
              color: $blue500;

              // If node name has HTML inside it, provide some default styling
              p {
                color: $blue500;
              }

              p:first-child {
                margin-top: 0px;
              }

              a {
                text-decoration: underline;
                text-transform: none;
              }
            }

            .sidebar-item-right-content {
              .pill-label {
                background-color: $blue50;
                mix-blend-mode: multiply;

                .pill-label-content {
                  color: $blue500;
                }
              }
            }
          }

          &.active,
          &:hover {
            background-color: $blue50;

            .sidebar-item-right-content {
              .cr-icon-question-alert {
                color: $blue500;
              }

              .expander {
                .icon-chevron {
                  color: $blue500;
                }
              }
            }
          }
          &.filtered {
            background-color: $blue50 !important;
          }
          &.not-filtered {
            pointer-events: none;
          }
        }
      }

      .sidebar-item-container + .sidebar-item-container {
        margin-top: 0;
      }

      &.hidden {
        min-width: $collapsedWidth;
        max-width: $collapsedWidth;
        display: unset !important;

        .sidebar-main {
          display: none;
        }

        .sidebar-collapsed {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 16px;

          .contents {
            margin-top: 12px;
            writing-mode: vertical-lr;
            text-transform: uppercase;
            transform: rotate(-180deg);
            font-weight: $font-weight-bold;
            font-size: 12px;
            line-height: 20px;
            color: $grey600;
            letter-spacing: 1px;
          }

          .cr-icon-chevron {
            color: $grey600;
          }
        }

        &:hover {
          background-color: $blue100;
          cursor: pointer;

          .sidebar-collapsed {
            color: $blue500;
            .contents {
              color: $blue500;
            }
            .cr-icon-chevron {
              color: $blue500;
            }
          }
        }
      }
    }

    .questionnaire-content-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-width: 480px;

      .questionnaire-content {
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
        background-color: white;

        .empty-change-card {
          padding-top: 200px;
        }

        &.loading {
          filter: blur(10px);
        }

        &.use-scroll-margin {
          // make sure the scroll bar appears inside the boarder when in changes view
          width: calc(100% - $changes-box-shadow-width);
        }

        &.disable {
          overscroll-behavior: contain;
          pointer-events: none;
        }

        .info-banner {
          margin: 24px;
          width: unset;
        }

        .intro-container {
          background-color: $grey50;
          // center the .intro-container content
          display: flex;
          align-items: center;
          justify-content: center;
          // fill height of parent (.questionnaire-content)
          // .questionnaire-content uses flex-grow / flex-shrink
          // to accommodate the .survey-viewer-action-bar height
          min-height: 100%;
          width: auto;

          .survey-viewer-intro {
            max-width: 740px;
            margin: auto;
          }
        }

        .survey-viewer-extra-attachments {
          .attachment-groups-container {
            .attachment-groups {
              .attachment-group {
                margin-bottom: 32px;

                .group-header {
                  display: flex;
                  cursor: pointer;

                  .group-name {
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                    line-height: 21px;
                    margin-bottom: 8px;
                  }

                  .expand-toggle {
                    margin-right: 6px;
                    margin-left: auto;

                    .icon-chevron {
                      font-size: 20px;
                      transition: transform 250ms ease-out;
                    }
                  }
                }

                .attachments {
                  .survey-viewer-document {
                    margin-top: 16px;
                    margin-bottom: 16px;
                  }
                }
              }

              :last-of-type(.attachment-group) {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }

    .survey-viewer-right-panel {
      min-width: 0;
      max-width: 0;

      transition: all 250ms ease-in-out;

      &.active {
        min-width: $commentsWidth;
        max-width: $commentsWidth;

        overflow: auto;
      }

      /* Set all children width to reduce animation jank */
      > * {
        width: $commentsWidth;
      }
    }

    .questionnaire-end-block {
      height: calc(70vh);
      display: flex;
      align-items: center;
      justify-content: center;

      .empty-subtext {
        color: $grey600;
      }
    }

    .survey-viewer-controls {
      position: fixed;
      left: 450px;
      bottom: 116px;
      padding-left: 32px;
      display: flex;

      &.sidebar-collapsed {
        left: calc(450px - $sidebarWidth + $collapsedWidth);
      }

      .btn {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        background-color: white;
        box-shadow:
          0px 1px 2px rgba(64, 70, 104, 0.06),
          0px 1px 3px rgba(64, 70, 104, 0.1);
        margin-right: 16px;
        transition: all 250ms ease-in-out;

        i {
          font-size: 22px;
          margin: 0px;
        }

        &:hover {
          background-color: $blue50;
        }
      }

      .saved-text,
      .saving-text {
        display: flex;
        align-items: center;

        .cr-icon-accepted,
        .cr-icon-redo {
          margin-right: 8px;
        }
      }

      .saved-text {
        > * {
          color: $green600;
        }
      }

      .saving-text {
        > * {
          color: $grey600;
        }
      }
    }

    &.hide-toc {
      .questionnaire-content {
        width: 100%;
      }

      .survey-viewer-controls {
        left: 0px;
      }
    }
  }

  .gpt-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    z-index: 12;
  }

  &.v2-autofill .gpt-overlay {
    z-index: 20;
  }
}

.survey-viewer-action-bar {
  position: unset !important;
  padding-left: 32px;
  padding-right: 100px;
  transition: padding-right 250ms ease-in-out;
  z-index: 13;

  &.is-right-panel-active {
    padding-right: 32px;
    .btn:last-of-type {
      margin-right: 0 !important;
    }
  }

  .container {
    .action-bar-inner {
      display: flex;
      justify-content: space-between;

      .progress-bar {
        .right-text {
          margin-left: 48px;
        }
      }

      .changed-toggle {
        display: flex;
        flex-direction: row;

        .toggle-switch {
          margin-right: 8px;
        }
      }

      .left-content {
        margin-right: 10px;
        display: flex;
        align-items: center;

        .left-buttons {
          display: flex;
          flex-direction: row;
        }

        .btn {
          margin: 6px 0 6px 12px;
        }

        .autofill-progress-bar {
          width: 172px;

          .right-text {
            margin-left: auto;
          }
        }

        .survey-viewer-autofill-btn-container {
          margin-left: 20px;
        }

        .survey-progress-bar {
          margin-right: 24px;
        }

        .upload-button-container {
          margin-left: 12px;

          .upload-btn {
            .btn-icon {
              width: auto;
              padding: 0 6px;

              &:hover:not(.disabled) {
                [class^="cr-icon-"],
                [class*=" cr-icon-"] {
                  color: $blue500;
                }
              }

              [class^="cr-icon-"],
              [class*=" cr-icon-"] {
                color: $grey500;
              }
            }
          }

          .cr-dot {
            margin-left: 6px;
          }
        }
      }

      .autofill-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 3;
        margin-left: 24px;

        .coffee-cup {
          height: 48px;
          width: 48px;
          background-color: $blue100;
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 32px;
            width: 32px;
          }
        }

        .autofill-text {
          display: flex;
          flex-direction: column;
          margin-left: 24px;
          line-height: 22px;

          .header {
            font-weight: $font-weight-bold;
            color: $grey900;
          }

          .body {
            color: $grey600;
            font-weight: $font-weight-regular;
          }
        }

        .btn {
          margin-left: auto;
        }
      }

      .middle-content {
        display: flex;
      }

      .right-content {
        margin-left: 10px;
        display: flex;
        flex-wrap: nowrap;

        .btn {
          margin: 6px 0 6px 12px;
        }

        .locked-container {
          display: flex;
          align-items: center;

          .info-banner {
            margin-right: 16px;
            margin-bottom: 0px;
            padding: 12px;
          }
        }
      }

      .cr-icon-chat-messages {
        font-size: 16px;
      }

      .cr-icon-compare {
        font-size: 16px;
      }

      .cr-icon-play {
        font-size: 20px;
      }
    }

    .progress-bar {
      .right-text {
        color: $grey600;
      }
    }

    &.diff-mode {
      padding: 0 102px 0 52px;

      .container-outer {
        height: 100%;

        .container {
          justify-content: unset;
          margin: unset;
          height: 100%;

          div[class*="-content"] {
            flex-grow: 1;
            width: 33%;
            min-width: 33%;
            max-width: 33%;
            height: 100%;
            align-items: center;
            margin: unset;
          }

          .middle-content,
          .right-content {
            justify-content: center;

            .summary {
              border-right: 0px solid $white !important;
            }
          }

          > :not(:first-child) {
            padding-left: 36px;
          }

          :not(:last-child) {
            border-right: 1px solid $grey100;
          }
        }
      }
    }
  }
}

/* Specific styles for survey viewer print layout */
@media print {
  .survey-viewer {
    height: unset;
    position: inherit;
    width: 100%;
    display: block;

    .survey-viewer-sidebar,
    .survey-viewer-action-bar {
      display: none;
    }

    .questionnaire-content {
      height: 100%;
      width: 100%;

      .info-banner {
        display: none;
      }

      .question-answer-node {
        break-inside: avoid;

        &.section-node {
          break-before: page;
        }

        /* Only break if it is the last section node in a sequence */
        &.section-node + .section-node {
          break-before: auto;
        }

        .text-field {
          input::placeholder,
          textarea::placeholder {
            opacity: 0 !important;
          }
        }
      }

      .questionnaire-end-block {
        display: none;
      }
    }
  }
}
