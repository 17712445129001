@use "../../_common/style/core/variables";

.threat-monitoring-event-time-series-chart-container {
  &.hidden {
    display: none;
  }

  height: 144px;

  .error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .no-results-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .loading-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .chart-container {
    position: relative;
    height: 100%;
    padding: 0px;

    & > canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
