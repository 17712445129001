@import "../../../_common/style/core/variables";

.userbase-permission-users-overview {
  .header {
    justify-content: space-between;
  }
  .content {
    padding: 20px 32px;
  }
}
