@import "../../_common/style/core/variables";

.modal-content.edit-account-auth-modal {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
  }

  .label {
    display: flex;
    flex-direction: column;
    height: 40px;
    align-items: flex-start;
    justify-content: center;
    padding-right: 20px;
  }

  .in {
    width: 300px;
    resize: vertical;
  }

  .disabled {
    color: $light-grey;
  }

  .warning {
    color: $blue;
    background-color: $lighter-grey;
    text-align: center;
    .warning-cell {
      padding: 20px;
    }
  }

  .padded {
    margin-top: 20px;
  }
}
