@use "../core/variables";

$user-assignment-width: 320px;

.user-assignment {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: $user-assignment-width;

  .assignee {
    display: flex;
    flex-direction: row;
    align-items: center;

    .edit-btn {
      font-size: 24px;
      margin-left: 13px;
    }
  }

  .no-assignee {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: variables.$font-size-smaller;
    letter-spacing: 1px;
    color: variables.$grey500;
    padding: 4px;
    border-radius: variables.$card-border-radius;

    > svg {
      stroke: variables.$grey500;
      margin-right: 8px;
    }

    &.editable:hover {
      cursor: pointer;
      color: variables.$blue500;
      background-color: variables.$blue50;

      > svg {
        stroke: variables.$blue500;
      }
    }
  }

  .assignee-select {
    .assignee-select-option {
      display: flex;
      flex-direction: row;
      align-items: center;

      .user-avatar {
        margin-right: 8px;
      }
    }

    .invite-more-link {
      color: variables.$blue500;
    }

    .ug-select__multi-value .assignee-select-option {
      .user-avatar {
        display: none;
      }
    }

    .ug-select__control {
      min-width: unset;
      width: $user-assignment-width;
    }
  }
}
