@use "../../../_common/style/core/variables";

.userbase-monitored-users-list {
  .users-filters {
    padding: variables.$space-lg variables.$space-xl 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: variables.$space-xl;

    .search-box-input-container {
      flex: 1;
    }
  }

  .score-container {
    display: flex;
    align-items: center;

    .color-grade {
      margin-right: 10px;
    }

    .score {
      white-space: nowrap;

      .cstar-text {
        .score-text {
          min-width: 0;
        }
      }
    }
  }

  .dropdown-item {
    height: 66px;

    &.unmonitor-meatball {
      display: flex;
      flex-direction: column;
      gap: 7px;

      height: 100%;

      .unmonitor-label {
        display: flex;
        flex-direction: row;

        width: 100%;
        color: variables.$grey900;
        font-size: 14px;
      }

      .unmonitor-sublabel {
        width: 100%;
        color: variables.$grey600;
        font-size: 14px;
        padding-left: 30px;
      }
    }
  }
}
