@use "../../_common/style/core/variables";

.survey-progress-bar {
  .top-text {
    border-right: 0px solid variables.$white !important;
    div {
      border-right: 0px solid variables.$white !important;
    }
  }

  .right-text {
    //margin-left: auto !important;
  }
  .progress-right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .summary {
      margin-right: 8px;

      .summary-popup {
        padding: 12px;
        width: 172px;
      }
    }

    .count {
      color: variables.$grey600;
      width: max-content;
    }
  }
}

.survey-progress-summary {
  display: flex;
  flex-direction: column;

  .title {
    color: variables.$grey500;
    font-size: variables.$font-size-extra-small;
    font-weight: variables.$font-weight-bold;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .summary-line {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: variables.$grey900;
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-bold;
    line-height: 22px;

    .cr-dot {
      height: 10px;
      width: 10px;
      margin-right: 8px;
    }
  }
}
