@import "../../../_common/style/core/variables";

.ub-user-apps-for-specific-panel {
  // Userbase specific style to remove bottom border from report card
  box-shadow: unset;

  &.report-card {
    border: unset;
  }

  .title-and-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 $space-xxl $space-lg;

    .saas-user-title {
      margin-right: auto;
      font-size: $font-size-extra-large;
      font-weight: $font-weight-bold;
    }

    .saas-user-title-buttons {
      margin-left: auto;
    }
  }

  .content {
    .app-list {
      th:first-of-type,
      td:first-of-type {
        padding-left: 0;
      }

      .app-name-col {
        width: 60%;
      }

      .security-rating-col {
        width: 30%;
      }
      .user-status-col {
        width: 10%;
      }
    }
  }
}
