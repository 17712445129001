@import "../core/variables";

.beta-label {
  font-size: $font-size;

  .beta-label-inner {
    padding: 2px 6px;
    border-radius: $component-border-radius;
  }
}
