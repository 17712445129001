@import "../../core/variables";

.expandable-navigation-container {
  display: flex;
  flex-direction: column;
  position: relative;

  .navigation-bottom {
    background-color: transparent;
    display: flex;
    padding: 8px;
    pointer-events: none;

    position: absolute;
    bottom: 0;
    width: 100%;

    &:not(.open) {
      justify-content: center;
    }

    &.open {
      justify-content: flex-end;
    }

    .navigation-expander {
      border-radius: 8px;
      height: 32px;
      width: 32px;
      pointer-events: auto;
    }
  }

  &:not(:hover) {
    .navigation-expander {
      visibility: hidden;
    }
  }
}

.navigation-expander-tooltip-popup.dismissable-popup.right {
  padding-top: $space-sm;
  padding-bottom: $space-sm;

  // Smaller triangle for nav menu tooltips
  &:after {
    width: 10px;
    height: 10px;
  }
}
