@use "../../../_common/style/core/variables";

.shared-profile-surveys-table {
  tr {
    height: unset !important;
    vertical-align: top;
  }

  td:not(.expanded-content-cell) {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  td.options-icons-cell {
    padding-top: 20px !important;
  }

  td.expand-cell {
    padding-top: 28px !important;
  }

  .fortypx-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .q-cell {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    > * + * {
      margin-left: 16px;
    }

    .toggle-switch {
      height: 40px;
      align-items: center;
    }

    .q-cell-main {
      &.no-desc {
        align-self: center;
      }

      > * + * {
        margin-top: 16px;
      }
    }

    .q-description {
      margin-top: 16px;
      color: variables.$grey500;
      font-style: italic;
    }

    .q-unsavedchanges {
      border-top: 1px solid variables.$grey100;
      padding-top: 16px;

      h3 {
        font-size: variables.$font-size;
      }

      p {
        color: variables.$grey500;
        max-width: 600px;
      }

      .btn-group {
        margin-top: 20px;

        > * + * {
          margin-left: 20px;
        }
      }
    }
  }

  .date-completed-cell {
    width: 200px;
  }

  .status-cell {
    white-space: nowrap;
    width: 1px;
  }

  .expanded-content-cell.expanded {
    background-color: variables.$grey25;
    padding: 0;
  }

  .attachment-row {
    background-color: variables.$grey25;
  }

  .doc-cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    > * + * {
      margin-left: 16px;
    }

    .toggle-placeholder {
      width: 32px;
      min-width: 32px;
    }

    .file-type-icon {
      width: 40px;
      height: 40px;
    }

    .name-and-desc {
      font-weight: variables.$font-weight-bold;
      line-height: 150%;

      .desc {
        font-weight: variables.$font-weight-regular;
        color: variables.$grey500;
      }
    }
  }

  .attachments-subtable {
    $table-left-padding: 88px;
    $table-left-padding-own-shared-profile: 136px;

    &.own-shared-profile {
      thead th:first-child {
        padding-left: $table-left-padding-own-shared-profile;
      }

      .question-and-filename {
        padding-left: $table-left-padding-own-shared-profile;
      }
    }

    thead th {
      padding-top: 16px;

      &:first-child {
        padding-left: $table-left-padding;
      }
    }

    tbody tr {
      height: 78px !important;
    }

    .question-and-filename {
      display: inline-flex;
      flex-direction: column;
      padding-left: $table-left-padding;

      > * + * {
        margin-top: 8px;
      }

      .question-name {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &.clickable {
          cursor: pointer;

          .truncate-text {
            //color: $blue;
          }

          &:hover {
            text-decoration: none;

            .truncate-text {
              text-decoration: underline;
              color: variables.$blue;
            }
          }
        }

        > * + * {
          margin-left: 8px;
        }

        .truncate-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .filename {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.clickable:hover {
          color: variables.$blue;
          text-decoration: underline;
        }
      }
    }

    .desc-cell {
      width: 35%;
      padding-top: 20px !important;
    }
  }
}
