@use "../../../../_common/style/core/variables";

.list-questionnaire-automation-v2 {
  padding-left: 0px;
  padding-right: 0px;

  .empty-card-with-action {
    justify-content: center;
  }

  .automation-table {
    width: 100%;

    .disabled {
      color: variables.$grey200;
      div {
        color: inherit !important;
      }
    }
    .row {
      width: 100%;
    }
    td,
    th {
      padding-left: 5px;
      padding-right: 5px;
      &:first-of-type {
        padding-left: 32px;
      }
      &:last-of-type {
        padding-right: 32px;
      }
    }
    td {
      padding-top: 24px;
      padding-bottom: 20px;
      vertical-align: top;
    }
    .td-enabled {
      .loader {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
    .td-recipe {
      width: 46%;
      .title {
        font-weight: 500;
        padding-bottom: 12px;
      }
      .recipe {
        padding-bottom: 12px;
      }
      .pill-label:not(:last-of-type) {
        margin-right: 8px;
      }
    }
    .td-author {
      .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        .name {
          margin-left: 5px;
          white-space: nowrap;
        }
      }
      .date {
        padding-left: 32px;
        font-weight: 400;
        font-size: 12px;
        color: variables.$grey400;
      }
    }
    .td-icons {
      .icons {
        display: flex;
        .btn-icon-popup:first-of-type {
          margin-left: auto;
        }
        .btn-icon-popup:not(:last-of-type) {
          margin-right: 8px;
        }
      }
    }
    .td-drag {
      width: 32px;
      div {
        color: variables.$grey300;
      }
      display: flex;
      flex-direction: row;
      padding-top: 24px;
      margin-right: 10px;
    }
    .tr-drop-target {
      padding: 0px;
      background-color: variables.$grey50;
    }
    .td-drop-target {
      padding: 2px;
      .drop-target {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: dashed 2px variables.$blue500 !important;
        color: variables.$blue500;
        border-radius: 8px;
        height: 64px;
        margin: 2px;
        background-color: variables.$blue50;
      }
    }
  }
}
