@import "../../_common/style/core/variables";

.keywords-table {
  min-width: 800px;
  color: $grey900;
  line-height: 22px;
  border-top: 1px solid $grey100;

  .actions-header {
    padding-left: 6px;
  }

  table th {
    padding-top: 16px;
  }

  tbody .keyword-row:not(.expanded-content-row) {
    height: 48px;
  }

  .circled-icon {
    background-color: $white;
    min-width: 30px;
    width: 30px;
    margin-right: 6px;

    div {
      color: $grey900;
      border-color: $grey900;
      border: 1px;
      border-radius: 50%;
      border-style: solid;
      padding: 3px;
    }
  }

  .keyword-cell {
    .pill-label {
      background-color: $blue50;

      .pill-label-content {
        color: $blue500;
      }
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 48px;

    .ellipsized-text-container {
      min-width: 0;
      overflow: hidden;
    }
  }

  .sources-cell {
    width: 80px;
  }

  .added-by-cell {
    .added-by-content {
      display: flex;
      align-items: center;
    }
  }

  .actions-cell {
    padding-top: 6px;
    width: 60px;
    align-items: center;

    .actions-wrapper {
      display: flex;
    }

    .unarchive-button {
      div:hover {
        color: $green500;
      }
    }

    .btn-icon {
      div {
        color: $grey500;
      }
    }
  }

  .criticality-cell {
    .criticality-content {
      display: flex;
      align-items: center;

      &.dot-green:before {
        color: $green400;
        background-color: $green400;
      }

      &.dot-orange:before {
        color: $orange400;
        background-color: $orange400;
      }

      &.dot-red:before {
        color: $red500;
        background-color: $red500;
      }

      &.dot-white:before {
        color: $white;
        background-color: $white;
      }

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid;
        margin-right: 4px;
      }
    }
  }
}
