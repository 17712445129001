@use "../../_common/style/core/variables";

.trust-page-card {
  .report-card {
    border-radius: 16px;

    &.new-styles {
      .header {
        padding: variables.$space-md variables.$space-xl;
        min-height: unset;
      }
    }
  }

  .header {
    justify-content: space-between;
    color: variables.$grey900;
  }

  .report-card-content {
    padding: variables.$space-md variables.$space-xl;
  }
}
