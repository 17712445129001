@import "../../../_common/style/core/variables";

.contact-selector {
  .form-section-input {
    display: flex;
    flex-direction: column;

    .color-checkbox {
      width: unset;
      margin-top: 4px;
    }

    h3 {
      margin-bottom: 24px;
    }

    .new-contacts-section {
      display: flex;
      flex-direction: column;
    }
  }

  .existing-contacts-section + .new-contacts-section {
    margin-top: 16px;
  }

  .new-contact-form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .form-contents {
      flex: 1;
      max-width: 500px;

      .text-field {
        width: 100%;
        margin-top: 0;
      }

      .half-input {
        display: inline-block;
        width: calc(50% - 5px);

        + .half-input {
          margin-left: 10px;
        }
      }
    }

    & + * {
      margin-top: 15px;
    }

    .controls {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .loader-icon {
        margin-bottom: 26px;
      }
    }
  }

  form + * {
    margin-top: 15px;
  }

  .btn-tertiary {
    .btn-inner {
      justify-content: flex-start;
      color: $blue;
    }

    &:hover .btn-inner {
      color: $blue800;
    }
  }
}

.selectable-user {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &:hover .box {
    background-color: rgba($blue, 0.05);
    box-shadow: inset 0 0 0 1px $blue;
  }

  &.selection-disabled {
    .user-avatar,
    .name-and-email {
      opacity: 0.5;
    }
  }

  .name-and-email {
    margin-left: 10px;
    color: $grey500;

    span {
      color: $font-color;
      line-height: 150%;
    }
  }

  .color-checkbox,
  .user-avatar {
    margin: auto 0;
  }

  & + * {
    margin-top: 15px;
  }

  & + .selectable-user {
    margin-bottom: 15px;
  }

  .loader-icon {
    align-self: center;
    margin-left: 10px;
  }
}
