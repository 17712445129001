@import "../core/variables";

.survey-details-history-card {
  .btn-icon {
    i {
      color: $grey500;
    }
  }

  .x-table {
    table {
      tbody {
        tr {
          height: 48px;

          td {
            border-top: unset;

            .cr-icon-chevron {
              color: $grey500;
            }

            .pill-label {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
