@import "../../../../_common/style/core/variables";

.versions-slide-panel-3 {
  .assessment-version {
    border-bottom: 1px solid $grey100;

    display: flex;
    flex-direction: row;
    padding: 16px 12px;

    .dismissible-popup-container {
      height: min-content;
    }

    &.selected {
      background-color: rgba($blue, 0.05);
    }

    .center-col {
      display: flex;
      flex-direction: column;

      margin-left: 12px;

      .assessment-name {
        color: $grey900;
        font-size: $font-size;
        font-weight: $font-weight-bold;
        line-height: 22px;
      }

      .date {
        color: $grey700;
        font-size: $font-size-smaller;
        font-weight: $font-weight-regular;
        line-height: 18px;
      }

      .author-name {
        margin-top: 8px;
        color: $grey600;
        font-size: $font-size-smaller;
        font-weight: $font-weight-regular;
        line-height: 18px;
      }
    }

    .delete-button {
      margin-left: auto;
    }
  }
}
