@use "../../_common/style/core/variables";

.risk-question-icon {
  display: flex;
  flex-direction: row;
  align-items: center;

  .severity-icon,
  img {
    height: 18px;
  }
}

.risk-question {
  .risk-title {
    color: variables.$grey900;
  }

  p {
    color: variables.$grey500;
    line-height: 150%;
    margin-top: 0;
  }
}
