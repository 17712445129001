@use "../../../_common/style/core/variables";

#create_integration {
  $text-grey: #a3a3a3;
  $mid-blue: #d6e3f5;

  & > div.multi-steps {
    max-width: variables.$max-page-width;
  }

  & > div.section {
    max-width: variables.$max-page-width;
    margin: auto !important;
  }

  .section-step {
    padding: 0px;
    width: 100%;
  }

  .info-banner {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }

  .ug-select__menu {
    z-index: 11;
  }

  .action-bar {
    .loader-icon {
      margin-right: 30px;
      height: 24px;
    }
  }

  textarea {
    background-color: variables.$black;
    font-size: 10pt;
    color: variables.$green;
    min-width: 300px;
    width: 835px;
    height: 260px;
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE */
    font-family: courier;
    line-height: 1.2em;
  }

  pre {
    background-color: variables.$black;
    font-size: 10pt;
    color: variables.$green;
    min-width: 300px;
    width: 835px;
    height: 260px;
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE */
    font-family: courier;
    line-height: 1.2em;
  }

  label {
    margin-top: 30px;
    margin-bottom: 10px;
    cursor: default;
    color: variables.$primary-black;
    font-size: 16px;
  }

  .description {
    color: variables.$medium-grey;
    margin-bottom: 7px;
    font-style: italic;
  }

  input {
    width: 100%;
    max-width: 600px;
  }

  .integration-type-selector {
    .color-checkbox {
      margin: 15px 0;
    }

    .more-options {
      margin-top: 20px;
      color: grey;
    }
  }

  .instructions {
    line-height: 2.5;
    border: 1px solid variables.$dark-grey;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: variables.$light-grey;
  }

  .prev {
    cursor: pointer;
    display: flex;
    line-height: 16px;
    letter-spacing: 1px;

    & > span {
      color: variables.$grey500;
    }

    & > div {
      color: variables.$grey500;
      margin-right: 8px;
      line-height: 16px;
    }
  }

  .cancel {
    cursor: pointer;
    font-weight: 500;
    color: variables.$grey500;
    padding-right: 16px;
  }

  .clickable-step {
    cursor: pointer;
  }

  .attributes-group {
    .attribute-row {
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      position: relative;

      .second {
        margin-left: 10px;
      }

      .btn-icon {
        cursor: pointer;
        position: absolute;
        right: -40px;
        top: -2px;
      }

      & + .attribute-row {
        margin-top: 10px;
      }

      .name,
      .value {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
      }

      span {
        color: variables.$red500;
        padding-top: 3px;
        font-weight: 500;
        font-size: 10px;

        .cr-icon-exclamation {
          border-radius: 1em;
          border: 1px solid variables.$red500;
          margin-right: 4px;
          margin-left: 4px;
          font-size: 0.65em;
          line-height: 1.2em;
          display: inline-block;
          width: 1.3em;
          height: 1.3em;

          &:before {
            font-size: 0.7em;
            position: relative;
            bottom: 1px;
            left: 1px;
          }
        }
      }

      .invalid {
        border-color: variables.$red500;
      }
    }

    .add-link {
      color: variables.$blue;
      cursor: pointer;
      margin-top: 10px;
      margin-left: 10px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .input-with-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .refresh-button {
      width: 32px;
      max-width: 32px;
      min-width: 32px;
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .vert-spacer {
    height: 20px;
  }

  .zapier-connected {
    background-color: variables.$light-grey;
    height: 60px;
    font-size: 16px;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 1px;

    p {
      color: variables.$white !important;
      align-text: center;
    }
  }

  .connected {
    background-color: variables.$green !important;
  }

  .disconnected {
    background-color: variables.$red !important;
  }

  .toggle-switch label {
    margin: 0;
  }

  .title-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid variables.$light-grey;
    padding: 28px 32px;

    .header {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 18px;
      line-height: 24px;

      .text {
        flex: 1;
        cursor: pointer;
      }

      .subtext {
        font-size: 12px;
        color: variables.$grey500;
      }
    }

    .accordion {
      flex: 0;
      cursor: pointer;
    }
  }

  .triggers-column {
    padding-left: 14px !important;
  }

  .loader-icon {
    width: 25px;
    height: 25px;
  }

  .section-title {
    display: flex;
    flex-direction: row;
    height-min: 40px;
    height-max: 40px;
    height: 50px;
    border-bottom: variables.$light-grey solid 1px;
    width: 100%;
    align-content: flex-end;
    justify-content: center;
    align-items: center;
    padding-left: 18px;

    .title {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-start;
      font-size: 14px;
      letter-spacing: 0;
      font-weight: variables.$font-weight-bold;
      padding-left: 0px;
    }

    .main-title {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: variables.$font-weight-bold;
    }
  }

  .body {
    padding-left: 32px;
    padding-right: 0px;
  }

  .field {
    display: flex;
    flex-direction: row;
    padding: 30px 0;

    & + .field {
      border-top: 1px solid variables.$grey100;
    }

    .field-desc {
      padding-top: 8px;
      padding-right: 30px;
      width: 30%;
      font-weight: 500;
      color: variables.$grey900;

      p {
        color: variables.$grey500;
        line-height: 150%;
        font-weight: 400;
      }
    }

    .field-input {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-right: 32px;

      .var-copy-cell {
        cursor: pointer;
      }

      .under-select {
        .list-warning {
          color: variables.$red;
          font-size: 13px;
          margin-left: 20px;
        }
      }

      .message-channel {
        font-family: monospace;
        white-space: pre;
        background-color: variables.$grey100;
        color: variables.$grey500;
        width: 600px;
        max-height: 40px !important;
        resize: none;
        padding: 10px;
        border-radius: 4px;
      }

      .text-field {
        margin: 0;
        width: 100%;
      }

      .small-info {
        margin-top: 20px;
        width: 600px;
      }

      .channel-select {
        width: 600px;
      }

      .loading-spinner {
        width: 12px;
        height: 12px;
        background-color: transparent !important;
        fill: transparent !important;
        margin-right: 6px;
      }

      .color-checkbox {
        &:not(:first-of-type) {
          margin-top: 8px;
        }
      }
    }
  }

  .bottom-line {
    margin-bottom: 32px;
    border-bottom: 1px solid variables.$light-grey !important;
  }

  .noline {
    border-bottom: none !important;
  }

  .noline-top {
    border-top: none !important;
  }

  .nospacing {
    margin-bottom: 0px !important;
  }

  .delimiter {
    border-top: variables.$light-grey solid 1px;
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #triggers {
    .search-container {
      padding-top: 24px;
      padding-right: 32px;

      input {
        width: 100%;
        max-width: unset;
      }
    }

    .triggers-table {
      th {
        padding-top: 10px;

        &:last-of-type {
          text-align: right;
        }
      }

      td {
        padding-left: 0;
      }

      .enable-cell {
        .toggle-with-label {
          justify-content: end;
        }
      }

      .trigger-cell {
        line-height: 150%;
        font-weight: variables.$font-weight-bold;
        padding-left: 32px;

        p {
          margin: 0;
          font-weight: variables.$font-weight-regular;
        }
      }
    }
  }

  .sample-data {
    width: 100%;
    font-family: monospace;
    white-space: pre;
    background-color: variables.$blue50;
    color: variables.$grey800;
    padding: 10px;
    min-height: 100px;
    height: 300px;
    overflow: scroll;
    resize: vertical;
    border-radius: 4px;
  }

  #payload {
    background-color: variables.$white;
    border-radius: 8px;
    box-shadow: 0 0 0 1px variables.$light-grey;

    .selected {
      border-left: variables.$blue solid 3px !important;
      background-color: variables.$lighter-blue !important;
      padding-left: 17px !important;
    }

    .clickable {
      cursor: pointer !important;
    }

    .last {
      border-bottom: transparent solid 0px !important;
    }

    .delimiter {
      margin-top: 30px;
    }

    .body {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: 100px;
      background-color: inherit;
      border-radius: inherit;
      padding-left: 0;

      .no-triggers {
        padding: 20px;
        text-align: center;

        & > span {
          color: variables.$medium-grey;
          font-style: italic;
          font-size: 16px;
          display: inline-block;
        }
      }

      .trigger-list {
        display: flex;
        flex-direction: column;
        border-right: 1px solid variables.$light-grey;
        width: 300px;

        .trigger-row {
          display: flex;
          flex-direction: row;
          height: fit-content;
          border-bottom: variables.$light-grey solid 1px;
          width: 100% !important;
          align-content: center;
          justify-content: flex-start;
          padding-left: 32px !important;
          padding-right: 30px !important;
          padding-top: 20px;
          padding-bottom: 20px;

          .nameval {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;
          }
        }
      }

      .no-left-padding {
        padding-left: 0px !important;
      }

      .left-padding {
        padding-left: 12px !important;
      }

      .section-left-padding {
        padding-left: 30px !important;
      }

      .top-padding {
        padding-top: 10px !important;
      }

      .payload-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-left: 32px;
        margin-bottom: 20px;

        .webhook-url {
          width: 100%;
          font-family: monospace;
          white-space: pre;
          background-color: variables.$grey25;
          color: variables.$grey500;
          overflow-y: auto;
          max-height: 40px !important;
          resize: none;
          padding: 10px;
          border-radius: 4px;
        }

        .message-channel {
          width: 100%;
          font-family: monospace;
          white-space: pre;
          background-color: variables.$grey100;
          color: variables.$grey500;
          overflow-y: auto;
          max-height: 40px !important;
          resize: none;
          padding: 10px;
          border-radius: 4px;
        }

        .payload-line,
        .var-table {
          .var-sample-cell {
            color: variables.$grey500;
            padding-right: 20px;
          }

          .var-name-cell {
            padding-right: 30px;
          }

          .liquid-example {
            font-family: monospace;
            background-color: variables.$blue50;
            color: variables.$grey800;
            padding: 10px;
            margin: 10px;
          }

          .array-example {
            background-color: inherit;
            color: variables.$grey500;
            padding: 10px;
            margin: 10px;
          }

          .icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 32px;

            &:hover {
              cursor: pointer;
            }
          }

          .split-line {
            display: flex;
            flex-direction: row;
            width: 100%;

            .cr-icon-message {
              margin-right: 5px;
            }

            .btn {
              width: 200px !important;
              margin-left: 10px;
              padding-left: 0px;
              padding-right: 0px;
              margin-top: -30px;
              height: 35px;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid variables.$grey100;
          }
        }

        .payload-line {
          max-width: 45vw;
          padding-right: 20px;
          padding-bottom: 32px;
          margin-left: 32px;

          .liquid-example {
            white-space: pre;
            background-color: variables.$blue50;
          }

          .array-example {
            white-space: pre;
          }
        }

        .var-table {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .var-sample-cell {
            flex-grow: 1;
          }

          .var-path-cell {
            padding-right: 0px;
          }

          .liquid-example {
            white-space: normal;
            max-width: inherit;
          }

          .array-example {
            white-space: normal;
            max-width: inherit;
          }
        }

        .instruction-text {
          width: 100%;
          color: $text-grey;
          line-height: 150%;
          margin-bottom: 20px;
        }

        .slack-data {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          background-color: #191d21;
          padding: 20px;
          min-height: 100px;
          border-radius: 4px;

          .slack-snippet {
            display: flex;
            flex-direction: row;
            margin: 20px;
            width: 600px;
            background-color: #191d21;
            color: variables.$white;
            padding: 20px;
            border-radius: 8px;
            background-color: variables.$white;

            pre {
              background-color: variables.$grey100;
              border: 1px solid variables.$grey200;
              padding: 5px;
              width: 100%;
              height: unset;
            }

            code {
              background-color: unset;
              font-size: 10pt;
              font-family: courier;
              line-height: 1.2em;
            }

            .icon {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              margin-right: 5px;
            }

            .ug-logo {
              width: 32px;
              height: 32px;
              background-color: transparent;
              flex: 0;
            }

            .error-logo {
              width: 16px;
              height: 16px;
              margin-left: 5px;
              background-color: transparent;
              flex: 0;
            }

            .slack-text {
              display: flex;
              flex-direction: column;
              color: variables.$black;
              white-space: pre-wrap;
              flex: 1;

              .error {
                color: variables.$red500;
                font-weight: 500;
              }

              .title-row {
                display: flex;
                flex-direction: row;
                align-items: center;

                margin-bottom: 2px;

                .title {
                  font-weight: 600;
                  margin-right: 5px;
                  font-size: 14px;
                  color: variables.$black;
                }

                .app-badge {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  font-size: 10px;
                  font-weight: 500;
                  color: variables.$grey500;
                  background-color: variables.$grey100;
                  margin-right: 5px;
                  padding: 2px;
                  padding-left: 3px;
                  padding-right: 3px;
                  height: 12px;
                }

                .date {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  font-size: 11px;
                  color: variables.$grey500;
                }
              }

              code {
                background-color: variables.$grey100 !important;
              }

              blockquote {
                background: transparent;
                border-left: 5px solid #ccc;
                margin: 1.5em 0px;
                padding: 0.5em 10px;
              }

              blockquote:before {
                color: #ccc;
                font-size: 4em;
                line-height: 0.1em;
                margin-right: 0.25em;
                vertical-align: -0.4em;
              }

              blockquote p {
                display: inline;
              }
            }
          }
        }

        .response {
          max-height: 100px;
        }

        .button-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: $text-grey;
          margin-top: 5px;
          cursor: pointer;
        }

        .terminal {
          flex: 1;
          background-color: transparent;
          padding-bottom: 24px;
          border-radius: 4px;

          textarea {
            width: 100%;
            background-color: variables.$black;
            font-size: 10pt;
            color: variables.$green;
            height: 300px;
            white-space: pre-wrap; /* CSS3 */
            white-space: -moz-pre-wrap; /* Firefox */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* IE */
            font-family: courier;
            line-height: 1.2em;
            resize: vertical;
          }
        }

        .messageedit {
          flex: 1;
          background-color: transparent;
          padding-bottom: 24px;
          border-radius: 4px;

          textarea {
            width: 100%;
            background-color: variables.$white;
            font-size: 10pt;
            font-family: inherit;
            color: variables.$grey900;
            height: 100px;
            white-space: pre-wrap; /* CSS3 */
            white-space: -moz-pre-wrap; /* Firefox */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* IE */
            font-family: courier;
            line-height: 1.2em;
            resize: vertical;
          }
        }

        .jirafieldentry {
          width: 100%;

          .ug-select__menu {
            z-index: 20;
          }

          input {
            max-width: unset;
            width: 100%;
            background-color: variables.$white;
            font-size: 10pt;
            color: variables.$grey900;
            line-height: 1.2em;
          }

          textarea {
            width: 100%;
            background-color: variables.$white;
            font-size: 10pt;
            font-family: inherit;
            color: variables.$grey900;
            height: 100px;
            white-space: pre-wrap; /* CSS3 */
            white-space: -moz-pre-wrap; /* Firefox */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* IE */
            line-height: 1.2em;
            resize: vertical;
          }
        }
      }
    }
  }

  .integration-status {
    display: flex;
    flex-direction: row !important;
    padding-top: 10px;
    align-items: center;

    .background-busy {
      margin-right: 14px;
    }

    .label {
      color: variables.$grey500;

      .enabled {
        color: variables.$green500;
        font-weight: 500;
      }

      .disabled {
        color: variables.$red500;
        font-weight: 500;
      }

      .greyed {
        color: variables.$grey500;
        font-weight: 500;
      }
    }

    .label-greyed {
      color: variables.$grey500;
    }
  }

  .next .loader-icon {
    margin: 0px !important;
    padding: 0px !important;
  }

  .next .icon-arrow {
    margin-left: 10px;
  }

  .email-preview {
    background: variables.$grey100;
    padding: 28px 64px;

    .email-preview-container {
      background: variables.$white;
      padding: 32px 48px;

      .logo {
        margin-bottom: 32px;

        img {
          max-height: 36px;
        }
      }

      .message {
        font-weight: variables.$font-weight-bold;
        font-size: 14px;
        line-height: 150%;
        color: black;
        margin-bottom: 16px;
      }

      .sub-message {
        font-size: 12px;
        line-height: 150%;
        font-weight: variables.$font-weight-regular;
        color: variables.$grey500;
        margin-bottom: 24px;
      }

      .line {
        border-bottom: 1px solid variables.$grey200;
        margin-bottom: 24px;
      }

      .disclaimer {
        p {
          font-size: 12px;
          line-height: 150%;
          font-weight: variables.$font-weight-regular;
          color: variables.$grey500;
          margin: unset;
        }
      }
    }
  }
}
