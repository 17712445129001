@use "../../_common/style/core/variables";

.badge-card {
  display: flex;
  position: relative;
  align-items: center;
  gap: variables.$space-sm;

  .badge-icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: variables.$component-border-radius;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  > span {
    color: variables.$grey900;
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-bold;
    line-height: variables.$line-height;
    text-wrap: wrap;
  }
}
