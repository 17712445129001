@use "../../../_common/style/core/variables";

.product-slide-panel {
  .slide-panel {
    width: 700px !important;

    .title {
      padding-right: 40px;
      border-bottom: none !important;
    }
  }

  .slide-panel-section {
    border-top: solid 1px variables.$grey100 !important;
  }

  .back-arrow {
    top: 25px;
    left: 40px;

    .popup {
      left: 30px;
      top: 0;
    }
  }

  .loading-overlay {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    bottom: -200px;
  }

  .domains-table {
    padding: 40px;

    .title-header {
      padding: 12px 0px 16px 0px;
      font-size: 16px;
      color: variables.$grey900;
      font-weight: variables.$font-weight-bold;
      line-height: 24px;
      width: 184px;
    }

    .column-header {
      padding: 12px 0px 16px 0px;
      font-weight: variables.$font-weight-bold;
      line-height: 20px;
      color: variables.$grey500;
      width: 184px;
    }

    .domain-cell {
      padding: 0px;
    }

    .chevron-cell {
      width: 24px;
      span {
        color: variables.$grey300;
      }
    }
  }

  .product-info-card {
    h2 {
      font-weight: variables.$font-weight-bold;
    }

    border-top: 1px solid variables.$grey50;
    padding: 32px 40px 0px;

    .product-info-row {
      display: flex;
      border-bottom: solid 1px variables.$grey100 !important;
      padding-bottom: 20px;
      align-items: center;

      &:not(:first-child) {
        padding-top: 20px;
      }

      .product-info-label {
        width: 300px;
        font-weight: variables.$font-weight-regular;
      }

      .product-info-value {
        width: 300px;
        font-weight: variables.$font-weight-bold;
        word-wrap: break-word;
      }

      .vendor-value {
        display: flex;
        flex-direction: row;
        align-items: center;

        .vendor-hostname {
          color: variables.$blue500;
          font-size: 16px;
          font-weight: variables.$font-weight-regular;
          line-height: 24px;
        }
        .clickable {
          cursor: pointer;
        }

        .score-grade {
          align-items: center;
          justify-content: left;
          margin: 0 8px;
          height: variables.$small-color-grade-size;
        }

        .score {
          font-weight: variables.$font-weight-bold;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
