@use "../../../_common/style/core/variables";

.create-vendor-risk-waiver {
  .report-card {
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  .select-risk-filters {
    display: flex;
    justify-content: space-between;
    padding: 20px 32px;
  }

  .action-bar {
    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        margin-left: 10px;
      }
    }
  }
}
