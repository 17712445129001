@use "../../_common/style/core/variables";

.move-question-modal {
  padding: 16px 0 0 0 !important;
}

.move-question {
  padding: 16px 32px 32px 32px;
  height: auto;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 700px;

  .tree-node-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
  }

  .tree-node {
  }

  .node-children {
    display: flex;
    flex-direction: row;
  }

  .node-item {
    display: flex;
    flex-direction: row;
    margin-top: -1px;
    position: relative;
  }

  .draggable-node {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
    padding: 14px 14px 14px 7px;
    position: relative;
    cursor: grab;
    margin-left: -7px;
    border-radius: 4px;

    &:hover {
      background-color: variables.$blue50;

      .cr-icon-drag-handle {
        color: variables.$blue;
      }
    }

    &.dragging {
      cursor: grabbing;
    }

    &.highlight .truncate-text {
      color: variables.$blue;
    }

    .cr-icon-arrow-right {
      margin-left: -5px;
      margin-right: 5px;
      color: variables.$grey500;
    }

    .node-type-icon {
      margin-right: 10px;
      flex-shrink: 0;

      &.node-moved {
        background-color: variables.$grey50;
        color: variables.$grey500;
      }
    }

    .truncate-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 24px;
      padding-right: 3px;
      margin-right: 10px;
    }

    .cr-icon-drag-handle {
      margin-left: auto;
      color: variables.$grey300;
    }
  }

  .sep-line {
    $sep-line-height: 40px;
    position: relative;
    width: 100%;
    height: 40px;
    margin-top: -$sep-line-height * 0.5;
    margin-bottom: -$sep-line-height * 0.5;
    z-index: 1;
    pointer-events: none;

    @for $i from 0 through 6 {
      &.indent-#{$i} {
        z-index: 1 + $i;
      }
    }

    &.active {
      z-index: 20;

      .sep-line-inner {
        background-color: variables.$blue100;

        &:after {
          background-color: variables.$blue;
        }
      }
    }

    .sep-line-inner {
      position: absolute;
      left: -6px;
      right: -6px;
      top: ($sep-line-height * 0.5) - 4px;
      bottom: ($sep-line-height * 0.5) - 4px;
      border-radius: 6px;

      &:after {
        position: absolute;
        content: "";
        top: 50%;
        height: 1px;
        left: 6px;
        right: 6px;
        background-color: variables.$grey100;
      }
    }
  }

  &.dragging .sep-line {
    pointer-events: unset;
  }

  .node-indent {
    $indent-size: 24px;
    width: $indent-size;
    min-width: $indent-size;
  }
}
