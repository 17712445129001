.rating {
  display: flex;
  align-items: center;
  gap: 10px;

  .score {
    white-space: nowrap;

    .cstar-text {
      .score-text {
        min-width: 0;
      }
    }
  }
}
