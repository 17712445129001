@use "variables";

@font-face {
  // sass-lint:disable-block indentation
  font-family: "ug_icons";
  src: url("#{variables.$icon-font-path}/ug_icons.eot?n5khyh");
  src:
    url("#{variables.$icon-font-path}/ug_icons.eot?n5khyh#iefix")
      format("embedded-opentype"),
    url("#{variables.$icon-font-path}/ug_icons.ttf?n5khyh") format("truetype"),
    url("#{variables.$icon-font-path}/ug_icons.woff?n5khyh") format("woff"),
    url("#{variables.$icon-font-path}/ug_icons.svg?n5khyh#ug_icons")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  display: inline-block;
  font-family: "ug_icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cr-icon-pencil:before {
  content: "\e934";
}
.cr-icon-upload:before {
  content: "\e933";
}
.cr-icon-document:before {
  content: "\e931";
}
.cr-icon-redo:before {
  content: "\e92f";
}
.cr-icon-message:before {
  content: "\e930";
}
.icon-add:before {
  content: "\e900";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-checkmark:before {
  content: "\e902";
}
.icon-chevron:before {
  content: "\e903";
}
.icon-clone:before {
  content: "\e904";
}
.icon-edit:before,
.icon-pencil:before {
  content: "\e905";
}
.icon-email:before {
  content: "\e906";
}
.icon-gear:before {
  content: "\e907";
}
.icon-info:before {
  content: "\e908";
}
.icon-list:before {
  content: "\e909";
}
.icon-meatball:before {
  content: "\e90a";
}
.icon-trash:before {
  content: "\e90b";
}
.icon-user:before {
  content: "\e90c";
}
.icon-window:before {
  content: "\e90d";
}
.icon-close:before,
.icon-x:before {
  content: "\e90e";
}
.icon-clock:before {
  content: "\e90f";
}
.icon-loading:before,
.icon-refresh:before,
.icon-cycle:before {
  content: "\e910";
}
.icon-bars:before {
  content: "\e911";
}
.icon-line:before {
  content: "\e912";
}
.icon-stack:before {
  content: "\e913";
}
.icon-thumbs-up:before {
  content: "\e914";
}
.icon-thumbs-down:before {
  content: "\e915";
}

.icon-pass {
  color: variables.$green;
}

.icon-fail {
  color: variables.$red;
}

[class*="icon-"] {
  &[class*="rotate-"] {
    display: inline-block;
  }

  &.rotate-270 {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  &.rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  &.rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &.rotate-Infinity {
    -webkit-animation: rotate-infinite 2s linear infinite;
    -moz-animation: rotate-infinite 2s linear infinite;
    -ms-animation: rotate-infinite 2s linear infinite;
    -o-animation: rotate-infinite 2s linear infinite;
    animation: rotate-infinite 2s linear infinite;
  }

  &.rotate--Infinity {
    -webkit-animation: rotate-infinite-backwards 2s linear infinite;
    -moz-animation: rotate-infinite-backwards 2s linear infinite;
    -ms-animation: rotate-infinite-backwards 2s linear infinite;
    -o-animation: rotate-infinite-backwards 2s linear infinite;
    animation: rotate-infinite-backwards 2s linear infinite;
  }
}
