@import "../../../../_common/style/core/variables";

.scheduled-reports {
  .x-table.report-schedule-list {
    td.expanded-content-cell {
      background: $grey25;
      padding: unset;
    }

    tbody tr .expand-cell:hover {
      background-color: $blue50;
    }
  }

  .report-schedule-list {
    h3 {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
    }

    .report-cell {
      div {
        margin: 14px 0;
        display: flex;
        align-items: center;

        img {
          width: 32px;
          max-width: 32px;
          margin-right: 8px;
        }
      }
    }

    .last-cell {
      color: $blue500;
      text-decoration: underline;

      span {
        color: $blue500;
      }
    }

    .report-schedule-sub-list {
      td:first-of-type {
        padding-left: 100px;
      }

      th:first-of-type {
        padding-left: 100px;
      }

      .scheduled-filename {
        display: flex;
        flex-direction: row;
        align-items: center;

        > * + * {
          margin-left: 10px;
        }

        a {
          color: $primary_black;
          text-decoration: none;
          cursor: default;
        }

        &.active {
          a {
            color: $blue;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    .empty-card-with-action {
      background-color: $blue50;
      padding: 16px 30px 24px;

      .empty-text {
        color: $mid-blue;
      }

      .empty-subtext {
        max-width: 480px;
        color: $mid-blue;
      }
    }
  }
}
