@use "../../_common/style/core/variables";

.edit-keyword-modal-header {
  border-bottom: 1px solid variables.$light-grey;
}

.edit-keyword-modal-footer {
  .previous-keyword-step {
    margin-right: auto;
  }
}

.edit-keyword-modal {
  width: 600px;

  .input-separated {
    margin-top: 20px;
  }

  .loading {
    height: 126px;
    padding: 32px;
    align-content: center;

    p {
      padding-top: 8px;
      text-align: center;
      color: variables.$grey900;
    }
  }

  .keyword-results {
    margin-top: 16px;
    text-align: left;

    .keyword-results-info {
      width: 100%;
      background: variables.$blue50;
      border-radius: 8px;
      padding: 8px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 10px;

      .cr-icon-check {
        color: variables.$green;
        border: 1.5px solid variables.$green;
        border-radius: 50%;
        height: 50%;
        padding: 3px;
        margin-left: 3px;
      }
    }

    .warning-banner {
      div {
        color: variables.$orange600;
      }
      .warning-message {
        font-weight: 400;
      }
    }
  }

  .label {
    text-align: left;
    font-weight: variables.$font-weight-bold;
    margin-bottom: 4px;

    &:not(:first-of-type) {
      padding-top: 8px;
    }

    &.no-margin {
      margin-bottom: 0px;
    }
  }

  .caption {
    color: variables.$grey500;
  }

  .input-field-wrapper {
    padding-bottom: 16px;
    &:not(:last-of-type) {
      border-bottom: 1px solid variables.$light-grey;
    }

    .input-disabled {
      cursor: not-allowed;

      // this is disgusting but I cannot figure out why some of the disabled
      // field's text is cut off without it
      .ug-select--is-disabled
        .ug-select__control--is-disabled
        .ug-select__value-container--has-value
        div:nth-child(2) {
        margin: 0px;
      }
    }

    .input-field {
      margin-bottom: 0px;

      input[type="text"]:disabled {
        background-color: variables.$grey50 !important;
        color: #999999 !important;
      }
    }

    .error-text {
      color: variables.$red500;
      font-style: normal;
      font-size: 11px;
      margin-top: 4px;
      display: flex;

      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0;

      .icon-info {
        font-size: 14px;
        margin-right: 2px;
      }
    }
  }
}

.keyword-details {
  .steps {
    margin-bottom: 8px;
  }
  .ug-select__option--is-selected .criticality-selection {
    color: #fff;
    &.dot-white:before {
      color: variables.$blue500;
      background-color: variables.$blue500;
    }
  }

  .ug-select__option--is-focused .criticality-selection {
    &.dot-white:before:not(.ug-select__option--is-selected) {
      color: variables.$blue50;
      background-color: variables.$blue50;
    }
  }

  .criticality-selection {
    display: flex;
    align-items: center;

    &.dot-green:before {
      color: variables.$green400;
      background-color: variables.$green400;
    }

    &.dot-orange:before {
      color: variables.$orange400;
      background-color: variables.$orange400;
    }

    &.dot-red:before {
      color: variables.$red500;
      background-color: variables.$red500;
    }

    &.dot-white:before {
      color: variables.$white;
      background-color: variables.$white;
    }

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid;
      margin-right: 4px;
    }
  }
}
