@import "../../_common/style/core/variables";
@import "../../_common/style/core/cr_icons_constants";

$severity-width: $space-xs;

.threat-monitoring-feed-item {
  background-color: $grey50;
  border: 1px solid $grey100;
  border-radius: 8px;
  position: relative;
  min-height: 100px;
  padding: 0 0 0 $severity-width;

  span.secondary {
    color: $grey500;
  }

  &::before {
    content: "";
    width: $severity-width;
    height: 100%;
    position: absolute;
    display: block;
    left: 0;
    border: 1px;
    border-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &.sev-low::before {
    background-color: #fdc106;
  }
  &.sev-medium::before {
    background-color: #ff9901;
  }
  &.sev-high::before {
    background-color: #ff6b01;
  }
  &.sev-critical::before {
    background-color: #e02424;
  }

  .container {
    padding: $space-md;
  }

  .title-row {
    display: grid;
    grid-template-columns: auto minmax(0px, 1fr) auto;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    overflow: unset;

    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: $blue;
      cursor: pointer;
      overflow: hidden;
    }

    .icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .dropdown-item {
        height: unset;
        margin: 8px;
        border-radius: 4px;
        padding: 8px;

        &:first-of-type {
          padding-bottom: 0px;
          padding-top: 0px;
        }

        &:last-of-type {
          padding-top: 0px;
          margin-top: 0px;
        }
        .dropdown-button-dropdown-item-content {
          padding: 4px;
          line-height: 22px;
          font-size: 14px;
          text-wrap-mode: wrap;

          .header-text {
            color: $grey900;
            font-weight: $font-weight-bold;
            margin-bottom: 4px;
          }

          .description {
            color: $grey600;
            margin-top: 4px;
            width: 200px;
          }
        }

        &:hover {
          .dropdown-button-dropdown-item-content {
            .header-text {
              color: $blue500;
            }
          }
        }
      }
    }
    .icons > div {
      font-size: 18px;
      margin-left: 8px;
    }
    .icons > span {
      font-size: 12px;
      margin-left: 8px;
      color: $grey500;
      margin-left: 4px;
    }
    .cr-icon-spanner-outline,
    .cr-icon-cancel,
    .cr-icon-add-bookmark,
    .cr-icon-chat-messages {
      color: $grey500;
    }
    .loading-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 26px;
      padding: 6px;
    }
    .cr-icon-carousel-dot {
      font-size: 8px !important;
      color: $red500 !important;
      margin-right: -4px;
    }
    .cr-icon-border {
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: $blue50;
        color: $blue500;
      }
    }

    .dropdown-button {
      &.dropdown-active {
        .btn-icon {
          background-color: unset;
          div {
            background-color: $blue50;
            color: $blue500;
          }
        }
      }
    }

    .btn-icon {
      width: 18px;
      mix-blend-mode: unset;

      &:hover {
        background-color: $blue50;
      }

      div {
        font-size: 18px;
        font-weight: 400;
      }
    }
    .grayed {
      color: $grey200 !important;
      cursor: default !important;
    }
    .chevron-container {
      min-width: 28px !important;
      min-height: 28px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: $grey500;

      .cr-icon-chevron {
        font-size: 14px !important;
        align-self: center;
        color: inherit;
      }
    }

    .label {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $blue500;
    }
  }
  .body {
    display: flex;
    flex-direction: row;
    background-color: $grey50;
    min-height: 100px;
    border-radius: 4px;
    width: 100%;

    .lhs {
      flex: 1;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 100px;

      padding-right: 16px;
      margin-right: 16px;
      border-right: 1px solid $grey100;

      .severity {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .lhs,
    .rhs {
      .row {
        display: grid;
        grid-template-columns: 100px minmax(0px, 1fr);
        gap: $space-sm;
        align-items: center;
        width: 100%;
        padding-top: $space-xs;
        padding-bottom: $space-xs;
        min-height: 24px;
      }

      .column1 {
        color: $grey900;
        font-size: 14px;
        font-weight: 500;
      }
      .column2 {
        min-width: 0px;
        width: 100%;

        display: flex;
        flex-direction: row;

        .severity-icon.is-label {
          display: flex;
          flex-direction: row;
          align-items: center;
          span {
            color: $grey900;
          }
        }

        .sources {
          display: flex;
          flex-direction: row;
          align-items: baseline;

          .icon {
            align-self: center;
          }
          .source {
            font-size: 14px;
            color: $grey900;
          }
          .module {
            font-size: 12px;
            color: $grey500;
            padding-left: 8px;
          }
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .time {
          font-size: 12px;
          color: $grey500;
          padding-left: 8px;
        }

        .password-revealer {
          flex-shrink: 1;
        }
      }
    }
    .rhs {
      flex: 1;
      width: 50%;
      display: flex;
      flex-direction: column;
      background-color: transparent;

      &.with-snippet {
        height: 180px;
      }

      // matches .snippet-container .snippet
      .tm-thread-entry {
        background-color: $blue100;
        border-radius: 4px;
        width: 100%;
        height: 180px;
        padding: 16px 16px;

        overflow-y: hidden;
        overflow-wrap: break-word;
        word-wrap: anywhere !important;
      }

      .snippet-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 180px;
        padding: 16px 16px;
        background-color: $blue100;
        border-radius: 4px;
      }
      .snippet {
        flex: 1;
        background-color: $blue100;
        flex-direction: column;
        overflow-y: hidden;
        overflow-wrap: break-word;
        font-family: "Courier";
        font-size: 12px;
        word-wrap: break-word;

        position: relative;

        div {
          word-wrap: anywhere !important;
          color: $grey900;
        }
        mark {
          background-color: rgba(255, 219, 0, 0.4);
          font-weight: 600;
        }
      }
    }
  }
}
