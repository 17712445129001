@use "../../../_common/style/core/variables";

.shared-profile-supporting-documentation-card {
  .dragdropupload {
    padding: 32px 32px 16px 32px;
  }

  .empty-card-with-action .btn-container:empty {
    display: none;
  }
}

.shared-profile-supporting-documentation-table {
  tr {
    height: unset !important;
  }

  td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .doc-cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    .file-type-icon {
      min-width: 40px;
      width: 40px;
      margin-right: 20px;
    }

    .name-and-desc {
      font-weight: variables.$font-weight-bold;
      line-height: 150%;

      .desc {
        font-weight: variables.$font-weight-regular;
        color: variables.$grey500;
      }
    }
  }
}
