@use "../../../../_common/style/core/variables";

.contact-select-v2-container {
  .custom-select {
    .options-container {
      .contacts-header {
        .title {
          color: variables.$grey500;
          font-size: variables.$font-size-extra-small;
          font-weight: variables.$font-weight-regular;
          line-height: 16px;
        }
      }
    }

    .new-button {
      border-top: 1px solid variables.$grey100;
    }
  }

  .selected-contacts {
    .contact-grid {
      max-width: 100%;
      margin: 16px 8px 8px 8px;
      display: grid;
      grid-template-columns: auto auto 30px;

      &.detailed {
        grid-template-columns: auto auto auto 30px;
      }

      row-gap: 16px;
      column-gap: 16px;

      & > div,
      .name-fields,
      .name-fields > div,
      .user-fields > div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .user-name-avatar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .user-avatar {
          margin-right: 16px;
        }

        .user-name {
          color: variables.$grey900;
          font-size: variables.$font-size;
          font-weight: variables.$font-weight-bold;
          line-height: 22px;
        }
      }

      .light {
        color: variables.$grey600;
        font-size: variables.$font-size;
        font-weight: variables.$font-weight-regular;
        line-height: 22px;
      }

      .btn-icon-popup {
        margin-left: auto;
      }
    }

    .user-display-v2 {
      margin-top: 8px;

      &:not(:last-of-type) {
        border-bottom: 1px solid variables.$grey100;
        padding-bottom: 16px;
      }
    }
  }
}
