@import "../../../_common/style/core/variables";

$light-green: #10da2f;
$light-grey: #eaeaeb;

#manage-labels-modal {
  margin-top: 50px;

  margin: 80px auto 80px auto;
  max-width: 700px;
  padding: 30px 20px;
  text-align: center;

  table {
    margin-top: 20px;
    thead {
      display: none;
    }

    .heading-row {
      font-size: 16px;
      height: auto;

      td {
        padding-top: 30px;
        padding-bottom: 10px;
      }
    }
  }

  .padded-div {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .left-align {
    align-content: left;
    align-items: left;
  }
  .selectable {
    cursor: pointer;
  }
  .icon-chevron {
    margin: 0px;
    margin-left: 1px;
    margin-right: 1px;
    border-radius: 100%;
    color: $black;
    font-size: 16px;
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    width: 25px;
    line-height: 25px;
    background-color: $white;
    transform: rotate(180deg);
  }
  .selected-type {
    font-size: 14px;
  }
  .max-width {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .center-horizontal {
    align-content: left;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: left;
  }
  .padded-loading {
    margin-left: 20px;
  }
  .padded-prompts {
    font-size: 0.7rem;
    margin-left: 20px;
    text-transform: uppercase;
    font-weight: $font-weight-regular;
    letter-spacing: 1px;
  }
  .text-edit {
    width: 350px;
  }
  .text-edit-add {
    width: 450px;
    margin-left: 20px;
  }
  .grayed {
    color: $light-grey;
  }
  .text-struck {
    text-decoration: line-through;
  }
  .confirm-text {
    color: $red;
  }
  .header {
    align-content: left;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    h2 {
      padding-bottom: 0;
    }
  }
  .heading-left {
    align-self: flex-start;
  }

  .buttons-right {
    margin-left: auto;

    button {
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }

  .text-only {
    margin-top: 45px;
    margin-bottom: 45px;
    color: $medium-grey;
    font-style: italic;
    font-size: 1.2rem;
    font-weight: $font-weight-regular;
    letter-spacing: 0px;
    font-family: Inter, "Inter-Fallback", Helvetica, Arial, sans-serif;
  }

  .circle {
    min-width: 14px;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: 2px solid $medium-grey;
    margin: 5px 10px 5px 0;
    text-align: center;
    line-height: 14px;
    font-size: 14px;
    font-weight: $font-weight-bold;

    // Loop over each color pair to generate the CSS for each
    @each $pair in $label-colors {
      $name: nth($pair, 1);
      $color: nth($pair, 2);
      &.#{$name} {
        background-color: $color;
        color: $white;
        border: none;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }
}
