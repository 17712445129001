@import "../core/variables";

.trial-account-reverted-modal {
  max-width: 500px !important;

  & > div > p {
    color: $font-color !important;
    margin: 16px 0 !important;
  }

  .info-banner {
    margin-top: 24px;
    margin-bottom: 0;
  }
}
