@import "../../_common/style/core/variables";

.survey-viewer-comments-messages-panel {
  flex: 1;
  overflow: auto;

  .message-groups {
    overflow: auto;
  }
}
