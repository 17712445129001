@import "../core/variables";

.survey-details-risks-card {
  .tabs-and-search {
    padding: 20px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .tab-buttons-wrapper {
      flex: 1;
    }
  }

  .infobanner-container {
    padding: 0 32px;
    padding-top: 24px;
  }

  .checkbox-right {
    margin-left: auto;
    width: auto;
    letter-spacing: 0;
    font-weight: 400;
    font-size: 14px;
  }

  .sev-cell {
    text-transform: capitalize;
  }

  .waived-row td {
    color: $grey300;
  }

  .passed {
    opacity: 0.5;
  }

  .expanded-content-cell {
    background-color: $grey25;
  }

  .risk-expand-content {
    padding: 0 32px;

    .content-header {
      color: $grey400;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 1px;
    }

    * + .content-header {
      margin-top: 32px;
    }

    .content-header + * {
      margin-top: 16px;
    }

    .overview-desc {
      color: $grey500;
      line-height: 150%;

      p,
      ol,
      li,
      span {
        color: inherit;
      }
    }

    .info-banner {
      margin-top: 16px;
      margin-bottom: 0;

      .info-banner-line {
        min-height: 0;
      }
    }
  }
}
