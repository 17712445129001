@use "../core/variables";

$custom-select-option-padding: variables.$space-md variables.$space-lg;

.custom-select {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  position: relative;

  .input-container {
    position: relative;
    cursor: pointer;
    display: flex;

    input {
      flex-grow: 1;
    }

    .selected-option {
      position: absolute;
      top: 6px;
      left: 10px;
    }

    i {
      position: absolute;
      transform: rotate(90deg);
      right: 12px;
      top: 11px;
      color: variables.$grey900;
      font-size: 11px;

      &.rotate-270 {
        transform: rotate(270deg);
      }

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    .icon-x {
      right: 32px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .options-container {
    :first-child {
      border-top: none !important; // Clear border if a divider is the first child
    }

    .option,
    .divider {
      padding: $custom-select-option-padding;
    }

    .option {
      cursor: pointer;
      transition: all 100ms ease-in-out;

      > * {
        transition: background-color 100ms ease-in-out;
      }

      &:hover {
        background-color: variables.$blue50;

        > * {
          background-color: variables.$blue50;
        }
      }
    }

    .divider {
      padding-left: 0;
      margin-left: 24px;
      border-top: 1px solid variables.$grey200;
      padding-bottom: 0;

      .title {
        color: variables.$grey400;
        font-weight: variables.$font-weight-bold;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .description {
        margin-top: 4px;
        color: variables.$grey700;
        font-size: 12px;
        line-height: 150%;
      }
    }

    .search-empty-card {
      max-width: 300px;
    }

    .empty {
      padding: 24px;
      text-align: center;
      font-weight: variables.$font-weight-bold;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .error-text {
    margin-top: 6px;
    font-weight: variables.$font-weight-bold;
    font-size: variables.$font-size-smaller;
    color: variables.$red500;
    font-style: normal;

    .icon-info {
      margin-right: 4px;
    }
  }
}
