@use "../../_common/style/core/variables";

.select-existing-questionnaires {
  display: flex;
  flex-direction: column;
  padding-top: variables.$space-md;
  border-top: 1px solid variables.$grey100;

  p {
    line-height: 22px !important;
    padding: variables.$space-md 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-existing-questionnaires-table {
    th {
      padding-top: variables.$space-md;
    }

    td:first-of-type,
    th:first-of-type {
      padding-left: unset;
    }

    td:last-of-type,
    th:last-of-type {
      padding-right: unset;
    }

    // Last updated is fixed width
    td:nth-of-type(3) {
      width: 150px;
    }

    th:nth-of-type(3) {
      width: 150px;
    }

    .q-row {
      td {
        border-top: 1px solid variables.$grey100;
        padding-top: variables.$space-md;
        padding-bottom: variables.$space-md;
      }
    }

    .cell-top-content {
      min-height: 40px;
      padding-top: 10px;
    }

    .q-cell {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      > * + * {
        margin-left: 16px;
      }

      .q-cell-main {
        flex-grow: 1;
        .survey-name-and-type {
          .survey-type {
            margin-top: unset;
          }
        }
      }

      .qn-description {
        margin-top: variables.$space-md;
        border-radius: variables.$component-border-radius;
        border-left: 4px solid variables.$grey200;
        background: variables.$grey50;
        padding: variables.$space-xs 12px;
        color: variables.$grey600;
        font-size: variables.$font-size-smaller;
        line-height: variables.$line-height-small;
      }
    }
    .options-icons-cell {
      display: none;
    }
  }
}
