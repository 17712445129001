@import "../../core/variables";

.survey-details-messages-panel {
  .slide-panel {
    width: 600px !important;

    .title {
      padding: 24px 32px;
    }

    .title-content {
      font-weight: $font-weight-regular;
      font-size: 14px;

      .group-back {
        display: flex;
        align-items: center;

        button {
          font-size: 14px;
          line-height: 150%;
          padding: 0;
          height: unset;

          .btn-inner {
            letter-spacing: 0;
            i {
              margin-right: 8px;
            }
          }
        }
      }

      .group-title {
        .btn-inner {
          font-size: 14px;
          line-height: 133%;

          > div {
            // Limit to 2 lines, despite the browser-specific extensions, should work in all major browsers
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 42px;
            letter-spacing: initial;

            // If node name has HTML inside it, provide some default styling
            p {
              color: $grey600;
            }

            a {
              text-decoration: underline;
              text-transform: none;
            }

            p:first-child {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .question-link-btn {
    .btn-inner {
      white-space: unset;
      text-align: left;
      justify-content: flex-start;
    }
  }
}
