@use "../../../_common/style/core/variables";

.entra-app-link {
  .domain {
    color: variables.$grey300;

    a {
      color: variables.$blue;
      text-transform: none;
    }
  }
}
