@use "../../../../_common/style/core/variables";

.public-risk-waivers-panel {
  .expires-soon {
    display: inline-block;
    margin-left: 10px;

    .cr-icon-clock {
      display: inline-block;
      color: variables.$red;
      vertical-align: -1px;

      &:after {
        display: none;
      }

      &.bouncing {
        animation-name: clock-anim;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        @keyframes clock-anim {
          from {
            transform: scale(1);
          }

          to {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
