@import "../core/variables";

#nav-wrapper {
  margin-top: -40px;
  display: flex;
  flex-direction: row;

  #side-navigation,
  .content-pusher {
    width: $nav-width-closed;
    min-width: $nav-width-closed;
    max-width: $nav-width-closed;
  }

  .trial-warning {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;

    background-color: $red;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 0px;

    span {
      color: $white;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
    }
  }

  .trial-button-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 30px;
    padding-right: 30px;
    color: $white;
    background-color: $grey700;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 0px;

    span {
      color: $white;
      margin-bottom: 8px;
      font-size: 13px;
      line-height: 150%;
    }

    .btn {
      font-size: 10px;

      img {
        height: 18px;
        margin-right: 3px;
      }
    }
  }

  .trial-space {
    height: 10px;
  }

  .red-circle-count {
    position: relative;
    font-size: 0.8em;
    color: $white;
    border-radius: 4px;
    background-color: $red;
    height: 20px;
    width: 20px;
    text-align: center;
    top: -10px;
    left: -18px;
    margin-right: -15px;
  }

  .composite-nav-link {
    display: flex;
    flex-direction: row;
    background-color: $blue;
  }

  .action-bar {
    left: $nav-width-closed !important;
  }

  .full-page-modal .action-bar {
    left: 0 !important;
  }

  .vendor-overlay-wrapper {
    .vendor-watched-loading-overlay,
    .vendor-overlay {
      left: $nav-width-closed !important;
    }
  }

  .btn-dropdown .btn:hover {
    transform: none;
  }

  &.nav-open {
    #side-navigation,
    .content-pusher {
      width: $nav-width;
      min-width: $nav-width;
      max-width: $nav-width;
    }

    #side-navigation .nav-content {
      display: flex;
    }

    .action-bar,
    #filter_view_frame {
      left: $nav-width !important;
    }

    .full-page-modal .action-bar {
      left: 0 !important;
    }

    .vendor-overlay-wrapper {
      .vendor-watched-loading-overlay,
      .vendor-overlay {
        left: $nav-width !important;
      }
    }
  }

  #main-content-area {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    padding: 30px 40px 60px 40px;
    background-color: $grey50;

    #main-content-area-inner {
      flex: 1;
      max-width: $max-page-width;
    }

    &:has(.horizontally-scrollable-x-table) {
      // Regression task:
      // https://app.asana.com/0/1201142709862962/1208349597365840/f
      // Fix HorizontallyScrollableXTable such that when there are many
      // columns, wider than the viewport, the overall page / card will
      // keep the viewport width, and the table will scroll within the
      // bounds of its container.
      min-width: 0;

      #main-content-area-inner {
        min-width: 0;
      }
    }

    // Old sub-pages should have a margin that reverses the padding of the main content area
    .breach-sight-view,
    .analyst-main-view {
      margin: -30px -40px -60px -40px;
    }

    .details-page > .section {
      padding: 0;
    }

    // Give cards a nicer box shadow to keep with the new design
    .card-shadow,
    .card {
      box-shadow: $box-shadow-4dp;
    }
  }
}

#side-navigation {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(68.94deg, #16191c 0%, $primary-black 100%);
  z-index: $side-navigation-z-index;

  .nav-content {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 25px 0;
    height: 100%;
  }

  .height-adjustable {
    position: relative;
    overflow: hidden;
    height: 100%;

    // Hax to make any scrollbars invisible
    .height-adjustable-inner {
      height: 100%;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */

      overflow-y: scroll;

      &::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0;
      }
    }

    .height-adjustable-watcher {
      height: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
    }

    .height-adjustable-bottom {
      margin-top: auto;
      padding-bottom: 20px;
    }
  }

  .ug-logo {
    width: 90px;
    margin-bottom: 15px;
    margin-left: 30px;
  }

  .scroll-fader {
    position: absolute;
    left: 0;
    right: 0;
    height: 80px;
    z-index: 1;
    pointer-events: none;

    &.top-fader {
      top: 0;
      background: linear-gradient(
        to top,
        rgba($primary-black, 0) 0%,
        darken($primary-black, 7) 100%
      );
    }

    &.bottom-fader {
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba($primary-black, 0) 0%,
        darken($primary-black, 5) 100%
      );
    }
  }

  .bottom {
    hr:not(.dropdown-separator) {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .subtitle {
    opacity: 0.5;
    color: $white;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0 30px;
    margin-top: 20px;
    margin-bottom: 5px;
    line-height: 18px;
  }

  hr:not(.dropdown-separator) {
    border-bottom: 1px solid $white;
    margin: 20px 30px;
    opacity: 0.2;

    & + .subtitle {
      margin-top: 0;
    }
  }

  .header-section-name {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    transition: opacity $animation-timing ease-in-out;
    margin-top: 5px;
    padding-left: 58px;

    .vendor-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      align-content: center;
      margin: 0px;
      padding: 0px;
      word-break: break-word;

      .shifted {
        margin-left: 5px;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 1;

      .icon-x {
        opacity: 1 !important;
      }
    }

    &.active {
      opacity: 1;

      .icon-x {
        opacity: 0.35;
      }
    }

    .icon-x {
      color: $white;
      margin-top: 3px;
    }
  }

  .nav-sub-section {
    position: relative;
    padding: 10px 0;
    background: rgba(255, 255, 255, 0.05);
    margin: 5px 0;

    .nav-link {
      padding-left: 58px;
    }
  }

  .bottom-dropdown {
    display: block;

    &.open .icon-chevron {
      opacity: 1;
    }

    & > .popup-content {
      top: unset;
      right: unset;
      left: calc(100% - 15px);
      bottom: 5px;
    }

    .sub-menu-popup {
      top: unset;
      bottom: 0;

      .new-release-item {
        display: block;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .help-support-btn,
  .user-details {
    padding: 7px 30px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &:hover,
    &.active {
      .icon-chevron {
        opacity: 1;
      }
    }

    .icon-chevron {
      color: $white;
      margin-left: auto;
      font-size: 16px;
      opacity: 0.2;
      transition: opacity 100ms ease-in-out;
    }
  }

  .help-support-btn {
    .cr-icon-question {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background-color: $blue500;
      border-radius: 50%;
      position: relative;

      &:before {
        color: $white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .help-txt {
      color: $white;
      font-size: 16px;
    }
  }

  .user-details {
    //margin-top: 20px;
    .avatar {
      width: 30px;
      height: 30px;
      background-size: cover;
      border-radius: 50%;
      margin-right: 10px;
    }

    .name-and-org {
      max-width: 120px;

      div {
        color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .name {
        font-size: 16px;
        margin-bottom: 1px;
      }

      .org {
        font-size: 11px;
        opacity: 0.5;
      }
    }
  }

  .free-text {
    color: white;
    font-size: 14px;
    line-height: 21px;
    padding-left: 32px;
    padding-right: 16px;
  }

  .upsell-nav-content-container {
    margin: auto 16px 24px 32px;
  }

  // Add a icon check against the current account
  .user-menu-dropdown {
    .user-list-dropdown-item {
      .active-check {
        margin-left: auto;
        padding-left: $space-md;

        .cr-icon-check {
          margin-right: 0;
          font-size: $font-size;
        }
      }
    }
  }
}

.report-exports-nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    color: #c0c1c3;
    transition: color 100ms ease-in-out;
  }

  &.active,
  &:hover {
    & > div:not(.icon-x) {
      color: $white;
    }
  }

  .exports-popup {
    position: fixed;
    background: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    z-index: 1000;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0;
    transform: translate(150px, -28px);

    transition-property: opacity, background-color, color, transform;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;

    &.visible {
      opacity: 1;
      transform: translate(170px, -28px);

      .icon-x {
        pointer-events: all;
      }

      .body {
        cursor: pointer;
        pointer-events: all;
      }
    }

    // Left pointing triangle
    &.left-triangle:before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $white;
      left: 0;
      transform: translateX(-100%);
      transition: border-right-color 250ms ease-in-out;
    }

    .body {
      display: flex;
      flex-direction: column;

      &:hover {
        color: $blue;
      }

      .msg {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        color: inherit;

        .message {
          color: inherit;
        }

        .loader-icon {
          margin-left: 5px;
          height: 14px;
        }

        .icon-x {
          padding-left: 10px;
        }
      }

      .sub-msg {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        padding-bottom: 5px;
        color: inherit;

        .icon-arrow {
          padding: 0px;
          margin-top: -3px;
          padding-left: 5px;
          font-size: 10px;
          color: inherit !important;
        }
      }
    }

    .loader-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    .icon-checkmark {
      margin-right: 5px;
      color: $white !important;
    }

    &.inverted {
      height: 60px;
      background-color: $blue;
      color: $white;
      transform: translate(170px, -38px);

      &:before {
        border-right-color: $blue;
      }

      &,
      & > div {
        color: $white !important;
      }

      .msg {
        color: $white;

        div {
          color: $white;
        }
      }

      .body {
        color: $white;
      }

      .icon-x {
        color: $white !important;
      }
    }

    .icon-x {
      cursor: pointer;
      margin-left: 5px;
      color: inherit !important;
    }
  }
}
