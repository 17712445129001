@import "core/normalize";
@import "core/variables";
@import "core/typography";
@import "core/icons";

@import "core/printvariables";
@import "core/transitions";
@import "core/cr_icons";
@import "core/_cr_icons_overrides.scss";

body,
#body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-weight: $font-weight-regular;
  min-width: $boundary-min-width !important;
  // We want all things to be relative to this element.
  position: relative;
  background: $canvas;

  // Disable overscroll effect
  overscroll-behavior: none;
}

body .react-root {
  flex-grow: 1;
}

// Hide scrollbars on body if it makes sense (e.g. showing a modal)
body.no-scroll,
body.no-scroll-modal,
body.no-scroll-modal-v2,
body.no-scroll-slide-panel {
  overflow-y: hidden;
}

.page_cyber_risk {
  // Stop the ping pong effect of the header navigation pushing down the page content
  #head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .custom-dropdown {
      .dropdown-text {
        div {
          color: $medium-grey;
          font-size: 11px;
          font-weight: $font-weight-bold;
        }
      }

      .dropdown-menu {
        background-color: $white;
        border: none;
        padding: $global-padding;
        margin-top: $global-padding * 2;

        & > div {
          &:hover {
            background-color: $canvas;
            cursor: pointer;
          }

          color: $medium-grey;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
  }

  // Make width calculations more predictable by making everything border-box
  *,
  *:before,
  *:after {
    box-sizing: border-box !important;
  }

  .react-view {
    .subnav-open {
      padding-top: 35px;
    }
  }

  font-size: $font-size;

  .page-header-layer,
  .section.no-header {
    padding-top: 30px;
  }

  #header-inner {
    min-height: 40px;
  }

  .whisper-fullpage-container {
    z-index: 1000005;
  }

  .card {
    flex-basis: 0;
    flex-grow: 1;
    min-height: 200px;
    flex-direction: column;

    & > div {
      flex-grow: 1;
      height: auto;
    }

    .card-content {
      flex-grow: 1;

      &.bare .sparkline-container {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.padded {
        padding: $global-padding;
      }
    }

    .cstar {
      display: flex;
      align-items: center;
    }

    .stats-with-chart,
    .sparkline-container {
      flex-grow: 1;
      flex-basis: 0;
    }

    .help-text {
      letter-spacing: 0;
    }

    .header span:not(.help-text) {
      margin-left: $global-padding;
      font-size: $font-size;
      vertical-align: bottom;
      color: $dark-grey;
      cursor: pointer;

      &:hover {
        color: $medium-grey;
      }
    }

    .header a {
      text-transform: uppercase;
    }
  }

  .table-card .card-content {
    & > div {
      padding: 0;
    }
  }

  .stats-with-chart {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0 !important;

    & > .label-text {
      margin-top: 0;
    }

    .sparkline-container {
      margin-bottom: $global-padding;
    }

    .single-stat {
      @media screen and (max-width: 1200px) {
        flex-basis: 50%;
      }

      flex-basis: 25%;
    }
  }

  .cstar-text,
  .cstar-arc,
  .cstar-icon {
    &.low,
    &.low .score-text {
      fill: $cstar-low;
      color: $cstar-low;
    }

    &.average,
    &.average .score-text {
      fill: $cstar-average;
      color: $cstar-average;
    }

    &.fair,
    &.fair .score-text {
      fill: $cstar-fair;
      color: $cstar-fair;
    }

    &.good,
    &.good .score-text {
      fill: $cstar-good;
      color: $cstar-good;
    }

    &.excellent,
    &.excellent .score-text {
      fill: $cstar-excellent;
      color: $cstar-excellent;
    }

    &.unknown,
    &.unknown .score-text {
      fill: $cstar-unknown;
      color: $cstar-unknown;
    }
  }

  .clickable {
    cursor: pointer !important;
  }

  [class*="icon-"].rotating {
    animation: spin 2s infinite linear;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  h2 {
    padding-bottom: 20px;
  }

  .page-header-layer h2 {
    a {
      color: $medium-grey;
      text-decoration: none;

      &:hover {
        color: $blue;
        text-decoration: none;
      }
    }
  }

  .page-header-left {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      display: flex;
      align-items: center;
      min-height: 48px;
      padding-bottom: $global-padding;

      h2 {
        padding: 0;
        margin-left: 0;
        transition: margin-left $animation-timing ease-in-out;
      }

      div + h2 {
        margin-left: 40px !important;
      }
    }
  }

  .page-header-left .backarrow {
    position: absolute;
  }

  .page-header-right {
    .icon-info {
      cursor: pointer;
      font-size: 1.2rem;
      color: $dark-grey;
      margin-left: $global-padding * 2;

      &:hover {
        color: $black;
      }
    }
  }

  // Hidden tab containers
  .tab-switcher {
    .tab-container,
    .tab-container-chartjs {
      &.show {
        display: block;

        &.section {
          margin-top: $global-padding * 2;
          width: 100%;
        }
      }
    }

    .tab-container-chartjs {
      // Why not use display: none and display: block here? The charts won't render if they're mounted in
      // a hidden div. And we can't unmount/remount tabs as they are hidden and shown as we want a pure CSS
      // solution for printing reports.
      // Absolute positioned divs fix the chart issue but create problems with the height of the page.
      // Setting height to 0 as shown below seems to fix both cases...
      height: 0;
      overflow: hidden;

      @media print {
        height: auto;
        overflow: visible;
      }

      &.show {
        height: auto;
        overflow: visible;
      }
    }

    .tab-container {
      display: none;
      margin-top: 0;

      @media print {
        display: block;
      }
    }
  }

  // Maintaining consistency among label-text style text elements
  .card .header a,
  .breadcrumb a {
    font-size: 11px !important;
    letter-spacing: 1px !important;
    font-weight: $font-weight-bold !important;
  }

  .webscan-section-header h2 {
    padding: $global-padding 0;
  }

  .webscan-section-header,
  .vendor-section-header {
    .page-header-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .vendor-link,
    .hostname-link {
      text-transform: none;
    }

    .hostname-link {
      color: $black !important;

      &:hover {
        color: $blue !important;
      }
    }
  }

  h3.outside-card-header {
    height: 0px;
    overflow: hidden !important;

    @media print {
      height: auto !important;
      overflow: visible !important;
      margin-top: $global-padding * 1.5 !important;
      margin-bottom: $global-padding !important;
      margin-left: 0px !important;
      font-size: 1.5rem !important;
      font-weight: $font-weight-bold !important;
      color: black;
    }
  }

  #cstar_card {
    // Remove bottom margin to accommodate business info
    margin-bottom: 0;
  }

  #full_page_error {
    min-height: 0;
  }

  .btn {
    .loader-icon {
      $icon-size: $global-padding * 2;
      width: $icon-size;
      height: $icon-size;
    }
  }
}

@media print {
  /* Best-effort: show pseudo elements correctly when printing */
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  @page {
    size: auto; /* auto is the initial value */
    margin-top: 25mm;
    margin-bottom: 25mm;
    margin-left: 15mm;
    margin-right: 15mm;
    padding-top: 25mm;
    padding-bottom: 0mm;
    padding-left: 0mm;
    padding-right: 0mm;
  }
}

@media print {
  #head,
  #footer-inner {
    display: none !important;
  }
}

.manage-users-view {
  .page-header-layer {
    padding-bottom: 30px;
  }
}

.whisper-notification {
  div,
  p,
  span {
    color: $white !important;
  }

  a {
    text-decoration: underline !important;
    text-transform: none !important;
    color: $white !important;
  }
}

.unsupported-browser {
  max-width: 800px;
  margin: 60px auto;
  text-align: center;

  h1 {
    font-size: 20px;
  }

  p {
    line-height: 20px;

    a {
      text-transform: none !important;
    }
  }
}

label {
  user-select: none;
  color: $label-color;
  display: block;
  cursor: pointer;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  &::placeholder {
    font-family: $font-family;
    font-weight: $font-weight-regular !important;
    color: $grey300 !important;
    opacity: 1 !important;
  }

  border: 1px solid $grey200;
  background: $white;
  border-radius: $component-border-radius;
  font-family: $font-family;
  outline: none;
  padding: 6px $global-padding;
  position: relative;
  height: 32px;
  color: $grey900;

  transition-property: background-color, color, border-color, box-shadow;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;

  &.success,
  &.focus,
  &:focus {
    box-shadow: 0 0 0 3px $blue100;
    border-color: $blue300;
  }

  &.error {
    border-color: $red500;

    &:focus {
      box-shadow: 0 0 0 3px $red100;
    }
  }

  &.italic:not(:focus) {
    font-style: italic;
  }

  &:disabled {
    border-color: $grey100;
    background: transparent !important;
    cursor: not-allowed;
    color: $grey200;
  }
}

textarea {
  height: 66px;
  line-height: 21px;
}

form {
  position: relative;
}

form input {
  box-sizing: border-box;
  width: 100%;
  max-width: $form-input-max-width;
}

form input + label {
  margin-top: $global-padding * 3;
}

form input + input {
  margin-top: $global-padding;
}

.semibold {
  font-weight: $font-weight-bold;
}

.icon-plus {
  &:before {
    content: "+";
    position: absolute;
    top: -3px;
    left: 4px;
    font-size: 15px;
  }

  position: relative;
  display: inline-block;
  height: 18px;
  width: 18px;
  border: 1px solid $blue;
  border-radius: 9px;
}

/* Default <button> styling */
button {
  border: none;
  background-color: unset;
  cursor: pointer;
}
