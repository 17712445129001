@use "../core/variables";

.grade-with-score-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  min-width: 245px;
  width: 100%;
  border-radius: 8px;

  > * {
    height: 128px;
    width: 100%;
    padding: variables.$space-lg;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
    gap: variables.$space-md;
  }

  > div + div {
    height: 116px;
  }

  .score-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    .score {
      .cstar-text {
        font-size: 48px;
        line-height: normal;

        .score-out-of {
          color: variables.$grey500;
        }
      }
    }
  }

  .color-grade {
    margin-right: 5px;
  }

  .score-inner-ind-avg {
    .color-grade.medium {
      width: 36px;
      height: 36px;
    }

    &.score {
      .cstar-text {
        font-size: 36px;
        line-height: normal;

        .score-out-of {
          display: none;
        }
      }
    }
  }
}

.score-subheader {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: variables.$grey500;
  text-align: center;
  font-feature-settings: "liga" off;
  font-size: variables.$font-size-extra-small;
  font-style: normal;
  font-weight: variables.$font-weight-bold;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
