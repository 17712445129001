@use "../../../../_common/style/core/variables";

.risk-domain-table {
  .domain-selection-not-allowed {
    .color-checkbox .color-checkbox-inner.clickable {
      cursor: not-allowed !important;
    }
  }

  table {
    tr,
    td {
      height: unset;
      border: unset;
    }

    td.selection-box {
      padding-left: 129px !important;
      padding-right: 0px;
      vertical-align: middle;
    }

    div.color-checkbox-inner {
      margin-right: 6px;
    }

    tbody {
      tr:not(.expanded-content-row) {
        height: 30px;
      }

      tr.clickable:hover {
        td {
          border: 0;
        }
      }

      tr.selected td {
        border: 0;
      }

      tr.selected:not(.expanded) td {
        border: 0;
      }
    }
  }

  .asset-container {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      display: inline;
    }

    .pill-label {
      margin-left: 4px;
      // padding: 0;
    }
  }

  padding-bottom: 14px;
  min-height: 60px;
}
