@use "../../../_common/style/core/variables";

.app-using-ai {
  display: flex;
  .icon-display {
    .icon {
      width: variables.$space-lg;
      height: variables.$space-lg;
    }
  }
}
