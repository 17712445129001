@use "../../_common/style/core/variables";

.about-card {
  --cover-image-height: 120px;
  --vendor-logo-height: 64px;
  overflow: hidden;

  .about-card-cover {
    position: absolute;
    height: var(--cover-image-height);
    width: 100%;
    top: 0;
    overflow: hidden;

    .cover-image {
      height: var(--cover-image-height);
      width: 100%;
      object-fit: cover;
    }

    .edit-cover-icon {
      position: absolute;
      top: variables.$space-md;
      right: variables.$space-md;
      background-color: #ffffff;
      border-radius: variables.$component-border-radius;
    }
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(var(--cover-image-height) + var(--vendor-logo-height));
    margin-top: calc(-1 * var(--vendor-logo-height) / 2);
    padding: 0 variables.$space-lg;

    .trust-page-logo-card {
      height: var(--vendor-logo-height);
    }
  }

  .about-card-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    gap: variables.$space-lg;
    min-width: 300px;

    padding: variables.$space-lg variables.$space-xl variables.$space-xl;

    .section {
      display: flex;
      flex-direction: column;
      gap: variables.$space-sm;
      width: 100%;

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: variables.$grey900;
        font-weight: variables.$font-weight-bold;
        line-height: variables.$line-height;
      }

      .section-content {
        display: block;
        color: variables.$grey500;
        line-height: variables.$line-height;
        text-align: justify;

        .editor-inner {
          min-height: unset;
          .content-editable {
            min-height: unset;
          }
        }

        &.greyed {
          opacity: 0.3;
          filter: grayscale(100%);
        }
      }
    }

    > :last-child {
      padding-top: variables.$space-lg;
      border-top: 1px solid variables.$grey100;
    }

    > :only-child {
      padding-top: 0;
      border-top: none;
    }
  }
}
