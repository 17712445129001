@import "../../../_common/style/core/variables";

.full-page-modal.v2 {
  .modal-content-container {
    .request-remediation-modal {
      padding-top: 0px;
    }
  }
}

.request-remediation-modal {
  width: 650px;

  .remediation-modal-step-1 {
    &.existing {
      .risk-mini {
        .risk-header {
          margin-top: 0;
        }
      }
    }
  }

  .asset-selection {
    .mini-header {
      font-weight: $font-weight-bold;
      font-size: $font-size-extra-small;
      line-height: 20px;
      letter-spacing: 1px;
      color: $grey500;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .asset-box {
      border-radius: 6px;
      border: 1px solid $grey100;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      max-height: 330px;
      overflow: auto;

      .color-checkbox {
        .label {
          max-width: 450px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .configure-step {
    margin-top: $space-lg;

    .desc {
      font-size: $font-size;
      font-weight: $font-weight-regular;
      line-height: 22px;
      color: $grey600;
      margin-bottom: 16px;
    }

    .schedule {
      .due-date-picker {
        margin-top: 14px;
      }

      .reminder {
        margin-top: 24px;
        .color-checkbox:not(:last-of-type) {
          margin-bottom: 8px;
        }

        .reminder-picker {
          margin-top: 16px;
          margin-left: 30px;
        }
      }

      p {
        margin-top: 8px !important;
        margin-bottom: 16px !important;
      }

      .indent {
        margin-left: 30px;
      }

      &.colapsed {
        p {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .review-step {
    .recipients {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .recipient-email {
        margin-top: 5px;
        color: $grey500;
      }
    }

    .risks-and-assets {
      margin-top: $space-lg;
      margin-bottom: $space-lg;

      .title {
        font-size: $font-size;
        font-weight: $font-weight-bold;
        line-height: 22px;
        margin-bottom: 8px;
      }

      padding-bottom: 24px;
      border-bottom: 1px solid $grey100;
    }

    .rich-text-edit-v2 {
      .editor-inner {
        padding: 0;
        min-height: 0;

        .content-editable {
          min-height: 0;
        }
      }
    }

    .risk-summary {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 8px;
      border: 1px solid $grey100;

      .risk-header {
        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .risk-title {
          font-weight: $font-weight-bold;
        }
        .severity-icon img {
          height: 24px;
        }
        .risk-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          line-height: 150%;
          .risk-description {
            color: $grey600;
          }
        }
      }
      .expandable-item {
        width: 100%;
        font-size: 12px;
        .expandable-item-header {
          border-top: 1px solid $grey100;
          padding: 8px 16px;
          .expandable-item-header-link {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 4px;
            color: $grey600;
            line-height: 150%;
            font-size: 12px;
            .icon-chevron {
              color: $grey600;
            }
          }
        }
        .expandable-item-content {
          ul {
            border-top: 1px solid $grey100;
            margin-block-start: 0;
            margin-block-end: 0;
            padding: 8px 16px 8px 36px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            color: $grey600;
            line-height: 150%;
          }
        }
      }
    }
  }
}
