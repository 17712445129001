@use "../styles/variables";

.feed-content.closed-feed {
  .x-table.threat-monitoring-table table tr {
    height: 56px;
  }

  th.severity {
    width: calc(
      28px + variables.$tm-feedtable-inner-padding +
        variables.$tm-feedtable-outer-padding
    );
  }

  th.status {
    width: calc(120px + variables.$tm-feedtable-inner-padding);
  }

  th.date {
    width: calc(140px + variables.$tm-feedtable-inner-padding);
  }
}

.confirm-reopen-threat-modal {
  &.cr-icon-undo1 {
    font-size: 20px;
  }
}
