@use "../../_common/style/core/variables";

.threat-preview-control {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  //padding: 16px;

  gap: 8px;

  .search {
    width: 100%;

    display: flex;
    flex-direction: row;

    .left {
      flex: 1;

      display: flex;
      flex-direction: row;

      .search-box-input-container {
        flex: 1;
      }
      .highlight-controls {
        flex-shrink: 1;

        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 8px;

        .counts {
          border-right: 1px solid variables.$grey100;
          padding: 0 8px;
        }
        .arrow {
          padding: 0 8px;
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;
            * {
              color: variables.$grey200;
            }
          }
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .snippet-hidden {
    color: variables.$grey500;
  }

  .thread {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .snippet-preview {
    flex: 1;
    padding: 24px;
    //overflow-x: hidden;
    overflow-y: auto;
    white-space: pre-wrap;

    font-size: variables.$font-size;
    background-color: variables.$blue50;
  }

  .snippet {
    div {
      word-wrap: anywhere !important;
      color: variables.$grey900;
    }
    mark {
      background-color: rgba(255, 219, 0, 0.4);
      font-weight: 600;
    }
  }
}
