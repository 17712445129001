@use "../../../../_common/style/core/variables";

.survey-progress {
  display: flex;
  align-items: center;
  margin-right: 8px;

  .progress-bar {
    flex-grow: 1;
  }

  .progress-percentage {
    margin-left: 6px;
    font-size: 12px;
    color: variables.$grey500;
  }
}

.survey-status-display {
  .pill-label-content:has(.info-tooltip) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: variables.$space-xs;

    .cr-icon-info {
      float: right;
    }
  }
}

.large-survey-status-display {
  display: flex;

  .display-item {
    .display-header {
      color: variables.$grey500;
      font-size: 12px;
      font-weight: variables.$font-weight-bold;
      line-height: 20px;
      letter-spacing: 1px;
    }

    .display-content {
      margin-top: 4px;
      color: variables.$grey900;
      font-size: 14px;
      font-weight: variables.$font-weight-bold;
      line-height: 150%;

      .survey-progress {
        min-width: 100px;
      }
    }

    & + .display-item {
      margin-left: 32px;
    }
  }
}

.autofill-progress {
  .progress-top-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: variables.$grey600 !important;
    .icon-info {
      margin-right: 5px;
    }
    .top-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .progress-bottom-line {
    color: variables.$grey400 !important;
  }

  .orange {
    .bar-inner {
      background-color: variables.$orange400 !important;
    }
  }

  .popup {
    .title {
      color: variables.$red !important;
      text-transform: uppercase;
      font-size: 10px;
    }
  }
}
