@import "../../../_common/style/core/variables";

.bulk-send-survey-modal {
  .bulk-results {
    width: 600px;
    .x-table {
      table {
        padding-left: $global-padding;
        border-collapse: collapse;

        th {
          border-bottom: 1px solid $light-grey;
        }

        td {
          border-top: 1px solid $lighter-grey;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          vertical-align: inherit !important;
        }

        th,
        td {
          text-align: left !important;
        }

        tr {
          height: 72px !important;
        }

        td:not(:last-of-type) {
          padding-right: 10px !important;
        }

        td:first-of-type {
          padding-left: 32px !important;
        }
      }
    }
  }
}
