@use "../../_common/style/core/variables";

.validation-error {
  display: flex;
  flex-direction: row;
  align-items: center;

  &,
  .cr-icon-risk {
    color: variables.$red;
  }

  .cr-icon-risk {
    margin-right: 5px;
  }
}
