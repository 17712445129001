@use "../core/variables";

.survey-status-card {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0 !important;

    .pill-label {
      margin-left: 10px;
    }
  }

  .main-section {
    padding: 20px !important;

    .pill-label {
      margin-bottom: 10px;
      margin-left: -5px;
    }

    .main-section-text {
      line-height: 20px;

      &.italics {
        font-style: italic;
      }
    }

    .risk-list {
      list-style: none;
      padding-left: 20px;
      margin-top: 10px;
      color: variables.$dark-grey;
    }
  }

  .user-list {
    .title {
      display: none;
    }

    .avatar {
      margin-left: 0;
      margin-right: 2px;
    }
  }
}
