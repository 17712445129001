@import "../../_common/style/core/variables";

.survey-viewer-icon-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: 20px;

  & + .survey-viewer-icon-button-container {
    margin-left: 5px;
  }

  .btn-icon > * {
    font-size: 18px !important;
  }

  .survey-viewer-icon-button {
    padding-left: 4px;
    padding-right: 4px;

    [class^="cr-icon-"],
    [class*=" cr-icon-"] {
      color: $grey300;
      font-size: 18px;
      margin-right: 0;
    }

    [class^="cr-icon-book-"],
    [class*=" cr-icon-book-"] {
      font-size: 23px;
    }

    &:hover {
      background-color: $blue50;
      mix-blend-mode: multiply;

      [class^="cr-icon-"],
      [class*=" cr-icon-"] {
        color: $blue !important;
      }
    }

    &.highlight {
      background-color: $blue50;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &.dropdown {
      &:hover {
        mix-blend-mode: unset;
      }
    }
  }
}
