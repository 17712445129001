@use "../core/variables";

.action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 100px; // 100px padding to give room for the intercom widget
  min-height: 100px;
  background-color: variables.$white;
  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-shadow: 0 -2px 23px 0 rgba(69, 69, 69, 0.1);

  .container-outer {
    display: flex;
    flex-grow: 1;
    max-width: variables.$max-page-width;
  }

  &.no-max-width .container-outer {
    max-width: unset;
  }

  .container {
    margin: 20px 0;
    flex-grow: 1;
  }

  &.new-styles {
    .container {
      display: flex;
      justify-content: space-between;

      .left-content {
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
          margin-right: 20px;
        }
      }

      .right-content {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
          margin-left: 20px;
        }
      }
    }
  }
}
