@import "../../../_common/style/core/variables";

.request-remediation-v2 {
  .info-banner {
    margin: 0 auto 24px;
  }

  .main-content {
    display: flex;
    flex-direction: row;
    margin: 24px auto;
    width: 100%;

    .header {
      font-size: 18px;
      line-height: 24px;
      color: $grey900;
    }

    .select-risk-step {
      .search-and-select {
        margin: 24px 32px;
        display: flex;
        flex-direction: row;

        .select {
          margin-right: auto;
        }
      }

      .risk-domain-table {
        tr.selected {
          background-color: transparent;
        }
      }

      .assets-col {
        text-align: right;
      }

      .assets-cell {
        text-decoration-line: underline;
        text-align: right;
        color: $grey900;
        font-size: 14px;
        line-height: 150%;
      }

      .risk-cell {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .risk-title {
        color: $grey900;
        font-size: 14px;
        line-height: 16px;
        font-weight: $font-weight-bold;
      }

      .risk-subtitle {
        color: $grey500;
        font-size: 14px;
        line-height: 150%;
      }

      td.expanded-content-cell {
        //padding-left: 90px;
        //padding-right: 126px;
        padding-top: 0;
        padding-bottom: 0;

        .x-table table thead tr {
          height: 52px;

          th {
            padding-top: 12px;
          }
        }

        .x-table table tbody {
          td:first-of-type {
            padding-left: 125px;
          }
        }
      }

      .x-table table.selectable .selection-box {
        width: 77px;
      }

      .questionnaire-selection {
        margin-top: 32px;

        .color-checkbox {
          .label {
            color: $grey900;
          }
        }

        .sent-by-text {
          margin-left: 30px;
        }

        .compensating-control-info-container {
          margin-left: 30px;
        }
      }

      .view-risk-information-container {
        padding-left: 131px;
        padding-top: 8px;
        padding-bottom: 8px;

        .view-risk-information-link {
          color: $grey500;
          display: inline-flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            color: $blue500;
          }

          span {
            color: inherit;
          }

          span.icon-chevron {
            font-size: $font-size-larger;
          }
        }
      }
    }

    .step {
      flex: 1;
    }

    .remediation-type-select {
      display: flex;
      flex-direction: column;
      margin-top: 26px;

      .remediation-type {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 24px;

        p {
          color: $grey500;
          font-size: 14px;
          line-height: 150%;
        }

        .underline {
          text-decoration-line: underline;
        }

        .left-side {
          display: flex;
          flex-direction: column;
          margin-left: 32px;

          p {
            margin-left: 30px;
            margin-top: 10px;
          }
        }
      }

      .select-survey-table {
        margin-left: 32px;
        margin-right: 32px;
        border-top: 1px solid $grey100;
      }

      .questionnaire-details {
        background-color: $grey25;
        margin-left: 66px;
        margin-right: 66px;
        padding: 32px;
        width: available;

        .questionnaire-type {
          font-size: 14px;
          line-height: 16px;
          font-weight: $font-weight-bold;
          color: $grey900;
          margin-bottom: 8px;
        }

        p {
          font-size: 14px;
          line-height: 150%;
          color: $grey500;
        }

        .num-risks {
          text-transform: uppercase;
          margin-top: 32px;
          margin-bottom: 20px;
          font-size: 12px;
          line-height: 16px;
          font-weight: $font-weight-bold;
          color: $grey500;
        }

        .answer-risk-row:not(:last-of-type) {
          margin-bottom: 8px;
        }

        .answer-risk-row {
          color: $grey900;
          font-size: 14px;
          line-height: 16px;
          font-weight: $font-weight-bold;

          .pill-label {
            margin-left: 8px;
          }
        }
      }
    }

    .score-projection-request-remediation {
      position: sticky;
      top: 40px;
      alignment: right;
    }

    .settings-card {
      p,
      .description {
        font-size: 14px;
        font-weight: $font-weight-regular;
        line-height: 150%;
        color: $grey500;
      }

      .description-editor {
        width: 100%;
      }

      .request-name-section {
        padding-bottom: 16px;
        .text-field {
          width: 100%;
        }
      }

      .form-section {
        padding: 0;
      }

      .schedule-section {
        .indent {
          margin-top: 8px;
          margin-left: 30px;
        }

        .due-date {
          + * {
            margin-top: 8px;
          }

          .due-date-picker {
            margin-top: 14px;
          }
        }

        .reminder-inner {
          .color-checkbox:not(:last-of-type) {
            margin-bottom: 8px;
          }

          .reminder-picker {
            margin-top: 28px;
            margin-left: 30px;
          }
        }
      }

      .contact-select {
        width: 100%;
      }
    }

    .add-documents-card {
      .report-card-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        line-height: 150%;
      }
    }

    .edit-existing-suggestion {
      .message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .info-header {
          font-weight: $font-weight-bolder;
          padding-bottom: 4px;
        }

        div {
          color: $blue500;
        }

        a {
          padding-top: 8px;
          display: flex;
          flex-direction: row;

          div {
            padding-top: 3px;
          }
        }
      }
    }
  }

  .expanded-content-row > * .cve-mini-list {
    padding: 24px 24px 24px 32px;
  }

  .action-bar {
    .left-side {
      margin-right: auto;
    }

    .right-side {
      .btn:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }

  .loading-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
  }
}
