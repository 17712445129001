.tm-investigator-assignment.user-assignment {
  width: 100%;
  min-width: 0px;
  overflow: hidden;

  .assignee {
    width: 100%;
    min-width: 0px;
    flex: 1 1 0;
    overflow: hidden;

    .tm-user-and-avatar {
      flex: 1 1 max-content;
      min-width: 0px;
      overflow: hidden;
    }

    .btn-icon .icon-x {
      font-size: 16px;
    }
  }

  .no-assignee {
    padding: 0px;
    img {
      margin-right: 7px;
    }
  }

  .assignee-select {
    width: 100%;
    .ug-select {
      width: 100%;
      min-width: unset;
      max-width: unset;
    }
    .ug-select__control {
      width: 100%;
    }
    z-index: 999999999;
  }
}

.tm-investigator-assignment-assignee-select-option {
  display: flex;
  align-items: center;
  gap: 8px;
}
