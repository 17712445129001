@use "../../_common/style/core/variables";

.prefilled-survey-details-overview-card {
  .content {
    padding: variables.$space-xl;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: variables.$space-xxl;

    .info-table-row {
      .row-value {
        .text-field,
        .date-picker {
          flex: 1;
        }
      }
    }

    .survey-progress {
      display: flex;
      flex-direction: column;

      .progress-bar {
        margin-bottom: 10px;
      }
    }

    .unanswered-question-link {
      color: variables.$blue500;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
