@import "../../../../_common/style/core/variables";

.manage-settings-card {
  .header-right {
    font-size: $font-size;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .card-content {
    padding: 24px 32px;

    p {
      margin: 0;
      color: $grey500;
      line-height: 150%;

      & + p,
      & + button {
        margin-top: 16px;
      }
    }
  }

  .half-grid {
    display: grid;
    grid-template-columns: 1fr 700px;
    grid-column-gap: 32px;
  }

  input[type="text"]:disabled {
    border-color: $grey50;
    background-color: $grey50 !important;
    color: $font-color;
  }

  .edit-grid {
    display: flex;
    flex-direction: column;

    $first-col-width: 60px;
    $actions-col-width: 150px;

    .header-row {
      margin-bottom: 8px;
      .input-col {
        text-transform: uppercase;
        color: $grey400;
        font-weight: $font-weight-bold;
        font-size: $font-size-smaller;
      }
    }

    .add-button {
      margin-left: 0;
      width: 100%;
      background-color: #fff;
      border: 1.5px dashed $grey200;
    }

    .edit-grid-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      & + .edit-grid-row {
        margin-top: 20px;
      }

      & > div + div {
        margin-left: 16px;
      }

      .first-col {
        width: $first-col-width;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }

      .input-col {
        flex: 1;

        .text-field {
          margin-bottom: 0;
        }
      }

      .actions-col {
        width: $actions-col-width;
        display: flex;
        flex-direction: row;
        align-items: center;

        .actions-icon-container {
          width: 32px;
          margin-left: -12px;
        }

        .right-meta {
          margin-left: auto;
        }
      }
    }

    .add-btn {
      margin-top: 16px;
      margin-left: $first-col-width + 16px;
      align-self: flex-start;

      &:not(:disabled) {
        .btn-inner {
          color: $blue;
        }

        &:hover .btn-inner {
          color: $blue800;
        }
      }
    }

    .info-banner {
      margin-top: 24px;
      margin-left: $first-col-width + 16px;
      width: unset;
    }
  }
}
