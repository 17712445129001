@import "../../_common/style/core/variables";

.message-icon {
  .count {
    margin-right: 3px;
  }

  &.has-messages {
    .cr-icon-chat-messages {
      color: $grey500;
    }
  }
}
