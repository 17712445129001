@import "../core/variables";

#info-bar {
  top: 0;
  left: 100px;
  right: 0;
  margin-left: -40px;
  margin-right: -40px;
  margin-top: -30px;
  margin-bottom: 30px;
  padding: 0px;

  height: 20px;
  background-color: $blue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: 0 1px 0 0 $blue;
  overflow: hidden;
  transition: transform $animation-timing ease-in-out;

  .message-text {
    color: $white;
    transition: transform 100ms ease-in-out;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
