@import "../../../_common/style/core/variables";

.userbase-permission-apps-overview {
  .header {
    justify-content: space-between;
  }
  .content {
    min-height: 270px;
    padding: 20px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
