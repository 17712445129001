.checkbox-list {
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  li {
    min-height: 24px;
    display: flex;
    align-items: center;
  }

  li + li {
    margin-top: 2px;
  }
}
