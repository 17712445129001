@import "../../../_common/style/core/variables";

.send-survey-modal-header {
  width: 600px;
}

.send-survey-modal {
  width: 600px;
  max-width: 600px;

  .multi-steps {
    margin-bottom: 16px;
  }

  .select-survey-step {
    .color-checkbox {
      padding-top: 8px;
    }
  }

  .new-survey-select {
    p {
      margin-bottom: 16px;
      line-height: 150%;
      a {
        .btn {
          font: inherit;
          letter-spacing: inherit;
        }
      }
    }
  }

  .survey-sections {
    margin-top: 16px;
    background-color: $grey50;

    .name-preview {
      display: flex;
      flex-direction: row;
      align-items: center;

      .btn {
        margin-left: auto;
      }
    }

    &.no-sections p {
      margin-bottom: unset;
    }

    .horz-line {
      border-top: solid 1px $grey100;
      width: 100%;
      margin: 18px 0;
    }

    .color-checkbox-inner {
      flex-grow: 1;
    }

    .survey-section {
      display: flex;
      flex-direction: row;
      //align-content: center;
      align-items: center;

      .btn {
        margin-left: auto;
      }
    }
  }

  .resend-survey-select {
    .color-checkbox-inner {
      flex-grow: 1;
      .box {
        margin-top: 2px;
        margin-bottom: auto;
      }
    }

    .resendable-survey {
      display: flex;
      flex-direction: row;

      .resend-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
      }

      h3 {
        margin-bottom: unset;
      }

      .btn {
        margin-left: auto;
      }

      p {
        margin-bottom: unset;
      }
    }
  }

  .recipients-box {
    .modal-form {
      .form-section {
        padding-bottom: unset;
      }
    }
  }

  .title-box {
    h3 {
      margin-bottom: 16px;
    }
  }

  .survey-due-date {
    p {
      margin-bottom: 16px;
    }

    .color-checkbox {
      align-items: flex-start;

      .color-checkbox-inner {
        .box {
          margin-top: 2px;
          margin-bottom: auto;
        }

        .label {
          white-space: unset;
        }
      }
    }

    .date-picker {
      width: 100%;

      & + * {
        margin-top: 24px;
      }
    }

    .date-picker-indent {
      margin-left: 30px;
      width: calc(100% - 30px);
    }
  }
}

.send-survey-modal-footer {
  .btn .btn-inner .btn-arrow {
    margin-left: 8px !important;
  }
}
