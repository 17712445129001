@import "../../_common/style/core/variables";

.content-library-suggested-documents {
  padding: 24px;
  background-color: $grey50;
  border: 1px solid $grey100;
  border-radius: $card-border-radius;
  width: 100%;
  display: flex;
  flex-direction: column;

  * + & {
    margin-top: 24px;
  }

  &:empty {
    visibility: hidden;
    padding: 0;
  }

  h5 {
    color: $grey500;
    font-size: $font-size-smaller;
    line-height: 150%;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .doc-grid {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-column-gap: 16px;
    grid-row-gap: 24px;
    margin-top: 24px;

    .doc-name {
      font-weight: $font-weight-bold;
      color: $blue500;
      cursor: pointer;
      line-height: 150%;
    }

    .doc-updated {
      font-size: $font-size-smaller;
      color: $grey400;
      line-height: 150%;
    }

    .doc-icons {
      display: flex;
      flex-direction: row;
      align-items: center;

      > * + * {
        margin-left: 8px;
      }
    }
  }
}
