@import "../../../_common/style/core/variables";

.generic-add-risk-modal {
  width: $modal-width;

  .manual-risk-modal {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .option {
      .option-heading {
        color: $grey900;
        font-size: $font-size;
        font-weight: $font-weight-bold;
        line-height: 22px;
        margin-bottom: 2px;
        display: flex;
        flex-direction: row;

        i {
          margin-left: 4px;
        }
      }
    }

    input {
      max-width: unset;
    }
  }
}
