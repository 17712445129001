@import "../../../_common/style/core/variables";

.vendor-tier-badge-container {
  min-height: 32px;
  min-width: 32px;
  display: inline-block;
}

.vendor-tier-badge-display {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  min-width: 32px;

  &.interact {
    border-radius: 4px;
    transition: all 100ms ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: $blue50;
      mix-blend-mode: multiply;

      .name {
        color: $blue500;
      }
    }
  }

  img {
    height: 24px;
    width: 24px;

    &.large {
      height: 32px;
      width: 32px;
    }
  }

  .vendor-tier-badge {
    min-height: 24px;
    min-width: 24px;
    background-color: $blue500;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    &.untiered {
      background-color: $blue100;

      > i {
        color: $blue500;
        font-size: 16px;
      }

      &.large {
        > i {
          font-size: 20px;
        }
      }
    }

    &.large {
      font-size: 15px;
      min-height: 32px;
      min-width: 32px;
      border-radius: 4px;
    }

    > .badge {
      color: white;
    }
  }

  .name {
    margin-left: 8px;

    &.truncate {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
