@use "../../_common/style/core/variables";

.threat-monitoring-new-threats-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &.info {
    color: variables.$blue;
    * {
      color: variables.$blue;
    }
  }
  &.success {
    color: variables.$green600;
    * {
      color: variables.$green600;
    }
  }

  * {
    font-weight: variables.$font-weight-regular;
  }
  strong {
    font-weight: variables.$font-weight-bolder;
  }

  .message {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 16px;

    .message-lines {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: inherit;

    width: max-content;
    white-space: nowrap;

    justify-content: flex-end;
    cursor: pointer;

    .cr-icon-redo {
      color: inherit;
      margin-right: 8px;
      &:hover {
        color: variables.$blue;
      }
    }
  }
}
