@import "../../core/variables";

.btn {
  display: inline-block;
  font-weight: $font-weight-bold;
  background-color: $white;
  border: 1px solid $blue;
  border-radius: $component-border-radius;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  padding: 4px 12px;
  height: 32px;
  position: relative;

  outline: none;

  &.tight {
    padding: 0 4px;
    font-size: $font-size-extra-small;
    height: 24px;
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px $blue100;
    border-color: $blue300;
    border-radius: 2px;
  }

  .btn-inner,
  .btn-inner * {
    font-weight: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue;
    fill: $blue;

    .btn-arrow {
      margin-right: 0px;
      margin-left: 6px;
      font-size: 14px;
    }

    .btn-arrow-left {
      margin-top: -2px;
      margin-right: 6px;
      margin-left: 0px;
      font-size: 14px;
    }
  }

  .btn-inner {
    white-space: nowrap;
  }

  transition: all 100ms ease-in-out;

  &:hover {
    background-color: $blue50;
  }

  &.btn-active {
    background-color: $blue50;
  }

  .sep {
    display: inline-block;
    width: 1px;
    height: 18px;
    vertical-align: -4px;
    margin-left: 10px;
    margin-right: 5px;
    background-color: $blue;
  }

  .loading-mask {
    display: none;
  }

  &.loading {
    pointer-events: none;
    cursor: default;
    position: relative;

    .btn-inner {
      visibility: hidden;
    }

    .loading-mask {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .loader-icon {
        $icon-size: $global-padding * 2;
        width: $icon-size;
        height: $icon-size;
      }
    }
  }

  .progress-bg {
    display: none;
  }

  &.progress {
    background-color: transparent !important;
    position: relative;

    .progress-bg {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: rgba($blue, 0.05);
      transition: width $animation-timing ease-in-out;
    }
  }

  &.btn-primary {
    background-color: $blue500;

    .btn-inner,
    .btn-inner * {
      color: #ffffff;
    }

    &:disabled {
      background-color: $blue300;
      border-color: $blue300;

      .btn-inner,
      .btn-inner * {
        color: #fff;
        fill: #fff;
      }
    }

    &:hover {
      background-color: $blue600;
    }
  }

  &.btn-filled-secondary {
    background-color: $blue50;
    border-color: $blue50;

    .btn-inner,
    .btn-inner * {
      color: $blue500;
    }
  }

  &.btn-danger {
    .btn-inner,
    .btn-inner * {
      color: $red500;
      fill: $red500;
    }

    border-color: $red500;

    &:hover {
      background-color: $red100;
    }

    .sep {
      background-color: $red500;
    }

    &.btn-primary {
      background-color: $red50;

      &:hover {
        background-color: $red100;
      }
    }
  }

  &.btn-filled-danger {
    color: white;
    background-color: $red500;
    border-color: $red500;

    .btn-inner,
    .btn-inner * {
      color: white;
      fill: white;
    }

    &:hover {
      background-color: $red700;
      border-color: $red700;
    }
  }

  &.btn-tertiary {
    .btn-inner,
    .btn-inner * {
      color: $grey500;
      fill: $grey500;
    }

    border-color: transparent;
    background: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 6px;
    padding-bottom: 6px;

    .loading-mask {
      background: none;
    }

    &:hover {
      background: none;

      .btn-inner,
      .btn-inner * {
        color: $blue500;
        fill: $blue500;
      }
    }
  }

  &.btn-link {
    .btn-inner,
    .btn-inner * {
      color: $blue500;
      fill: $blue500;
    }

    border: none transparent;
    background: none;
    padding-left: 0;
    padding-right: 0;

    .loading-mask {
      background: none;
    }

    &:hover {
      background: none;

      .btn-inner,
      .btn-inner * {
        color: $blue800;
        fill: $blue800;
      }
    }
  }

  &.btn-green {
    .btn-inner,
    .btn-inner * {
      color: $green600;
      fill: $green600;
    }

    border: 1px solid $green600;
    background: $green25;

    .loading-mask {
      background: none;
    }

    &:hover {
      background-color: $green100;
    }
  }

  &:disabled {
    .btn-inner,
    .btn-inner * {
      color: $grey200;
      fill: $grey200;
    }

    border-color: $grey200;
    background-color: transparent;
    cursor: default;
    pointer-events: none;

    &.btn-tertiary {
      border-color: transparent;

      //.btn-inner, .btn-inner * {
      //  color: $light-grey;
      //  fill: $light-grey;
      //}
    }

    .sep {
      background-color: $grey200;
    }
  }

  &.btn-add {
    border-style: dashed;
    &:disabled {
      background-color: $grey50;
    }
  }

  [class^="cr-icon-"],
  [class*=" cr-icon-"],
  [class^="icon-"],
  [class*=" icon-"] {
    font-size: 12px;
    line-height: 16px;
    display: inline;
    margin-right: 4px;
  }

  &.btn-icon-only {
    [class^="cr-icon-"],
    [class*=" cr-icon-"],
    [class^="icon-"],
    [class*=" icon-"] {
      margin-right: 0;
    }
  }

  .cr-icon-trash,
  .cr-icon-eye,
  .cr-icon-noeye,
  .cr-icon-lock,
  .cr-icon-save,
  .cr-icon-plus,
  .cr-icon-arrow-right,
  .cr-icon-help,
  .cr-icon-lightning,
  .cr-icon-lock,
  .cr-icon-share,
  .cr-icon-export-thin {
    font-size: 18px;
  }

  .cr-icon-tool,
  .cr-icon-bulb,
  .cr-icon-archive {
    font-size: 20px;
  }

  .cr-icon-arrow-right {
    margin-right: 0;
    margin-left: 6px;
  }

  .sep + [class^="cr-icon-"],
  .sep + [class*=" cr-icon-"],
  .sep + [class^="icon-"],
  .sep + [class*=" icon-"] {
    margin-right: 0px;
    margin-left: 4px;
  }

  .svg-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  strong {
    margin-left: 5px;
  }

  .unread-circle {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $red;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transform: translate(50%, -50%);

    .count {
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 11px;
      transform: translate(-50%, -50%);
    }
  }

  i.expander {
    transition: all 100ms ease-in-out;
  }
}

.rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tooltip-button {
  display: inline-block;
}
