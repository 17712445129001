@import "../core/variables";

.multi-selection-button {
  position: relative;

  .cr-icon-chevron {
    margin-left: 9px;
  }

  .color-checkbox {
    .label {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .options {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 10;
    background-color: #fff;
    border: $grey100 1px solid;
    box-shadow:
      0px 10px 15px rgba(64, 70, 104, 0.1),
      0px 4px 6px rgba(64, 70, 104, 0.05);
    border-radius: 8px;
    padding: 24px;
    font-size: $font-size-larger;
    width: 240px;

    .option-column {
      min-width: 192px;
      vertical-align: top;
      &:not(:first-of-type) {
        padding-left: 24px;
      }

      .option-group-header {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1px;
        text-align: left;
        color: $grey400;
      }
    }

    .reset-button {
      color: $blue500;
      font-size: $font-size;
      font-weight: $font-weight-bold;
      cursor: pointer;
      margin-top: 0;
    }

    .toggle {
      font-size: $font-size;
      font-weight: $font-weight-regular;
      border-bottom: 1px solid $grey200;
      padding-bottom: 20px;
      margin-bottom: 10px;
      .toggle-label {
        color: $grey900;
      }
    }

    .color-checkbox-inner {
      padding-top: 8px;
      padding-bottom: 8px;
      width: 100%;
      margin-right: 0;

      .dismissible-popup-container {
        width: 100%;

        .dismissable-popup-click-capture {
          display: flex;
          align-items: center;
        }
      }
    }

    .new-group {
      border-top: $grey200 1px solid;

      &:not(.empty) {
        padding-top: 16px;
        margin-top: 6px;
      }
    }
  }

  &.auto-width {
    .options {
      width: unset;
    }
  }
}
