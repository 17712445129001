@use "../../../_common/style/core/variables";

.user-permissions {
  .user-permissions-cell-with-subtext {
    line-height: 1.3;

    .user-permission-maintext {
      font-size: variables.$font-size;
    }

    .user-permission-subtext {
      font-size: variables.$font-size-smaller;
      color: variables.$grey500;
    }
  }
  // Modify the padding on the expanded cell so we don't have
  // a gap between the expanded content
  .report-card {
    td.expanded-content-cell {
      padding: 0;
    }
  }

  .user-permissions-slide-panel {
    .slide-panel.new-styles .title {
      border-bottom: none;
    }

    .slide-panel {
      // Important is required to enforce the width
      width: 700px !important;
    }
  }
}
