@use "../../../_common/style/core/variables";
@use "../../../_common/style/core/typography";

.recently-detected-apps {
  // Override the normal dashboard container padding
  .userbase-dashboard-container {
    padding: unset;

    .dashboard-title {
      padding: variables.$space-lg variables.$space-xl variables.$space-sm;
      margin-bottom: unset;
    }
  }

  // Reduce the header on the table to make it more compact
  .x-table table th {
    padding-top: variables.$space-md;
  }

  // Grow the content to fill the available space
  .content {
    width: 100%;
  }

  .app_name_col {
    width: 70%;
  }

  .security_rating_col {
    width: 15%;
  }

  .exposure_level_col {
    width: 15%;
  }

  .no-recent-apps-inner {
    display: flex;
    padding: variables.$space-xxxl variables.$space-xl;
    flex-direction: column;
    align-items: center;
    gap: variables.$space-sm;
    align-self: stretch;

    .no-recent-apps-icon {
      display: flex;
      width: variables.$space-xxxl;
      height: variables.$space-xxxl;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background: variables.$blue100;

      .cr-icon-grid {
        width: variables.$space-lg;
        height: variables.$space-lg;
        color: variables.$blue500;
        font-size: variables.$font-size-xx-large;
      }
    }

    .no-recent-apps-text {
      color: variables.$grey500;
      font-family: typography.$font-family;
      font-size: variables.$font-size;
      font-style: normal;
      font-weight: variables.$font-weight-regular;
      line-height: variables.$line-height;
    }
  }
}
