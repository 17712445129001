.date-selector {
  .date-selector-type-selection {
    .color-checkbox {
      margin-bottom: 10px;
    }
  }

  .date-selector-custom-date {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
    .date-picker {
      max-width: 48%;
    }
  }
}
