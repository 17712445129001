@import "../../../_common/style/core/variables";

.full-page-modal.v2 {
  .modal-content-container {
    .in-review-app-desc {
      padding: 0 $space-xl $space-lg;
      p {
        margin-bottom: 0;
      }
    }
  }
}
