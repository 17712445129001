@import "../../../../_common/style/core/variables";

.rating-chart {
  position: relative;
  margin-top: 5px;

  .rating-chart-tooltip {
    position: absolute;
    background: $white;
    padding: 10px;
    box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.05);
    transform: translateX(-50%);
    pointer-events: none;
    border-radius: 4px;
    z-index: 2;

    .x-value {
      color: $medium-grey;
      font-size: 11px;
      margin-bottom: 5px;
    }

    .label-and-score {
      display: flex;
      flex-direction: row;
      align-items: center;

      .label {
        white-space: nowrap;
        font-size: 11px;
        margin-right: 10px;
      }

      & + .label-and-score {
        margin-top: 10px;
      }

      &.multiple .label {
        min-width: 100px;
      }
    }

    .color-circle {
      width: 10px;
      min-width: 10px;
      height: 10px;
      min-height: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }

    .industry-avg-line {
      width: 10px;
      min-width: 10px;
      height: 10px;
      min-height: 10px;
      margin-right: 5px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        border-bottom: 1.5px #5d89d4 dotted;
        top: calc(50% - 1px);
        width: 100%;
      }
    }

    .score-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;

      .color-grade {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        font-size: 10px;
      }

      .score {
        white-space: nowrap;

        .cstar-text {
          font-size: 14px;

          .score-text {
            min-width: 0;
            transition: none;
          }

          .score-out-of {
            display: none;
          }
        }
      }
    }
  }

  // these are the default animation settings used by Chart.js
  // copying these ensures our scoring change indicator moves smoothly with our data
  // when the chart period is changed
  $chart-js-animation-duration: 1s;
  $chart-js-ease-function: cubic-bezier(
    0.25,
    1,
    0.5,
    1
  ); // https://easings.net/#easeOutQuart

  .scoring-changes {
    position: absolute;
    top: 6px;
    left: 35px;
    right: 20px;
    bottom: 28px;
    pointer-events: none;

    .scoring-change-label {
      position: absolute;
      top: -35px;
      transform: translate(-50%, 0);
      transition:
        left $chart-js-animation-duration $chart-js-ease-function,
        opacity 750ms $chart-js-ease-function;

      .label-text {
        font-size: 9px;
        letter-spacing: normal;
        color: $blue;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
      }
    }

    .scoring-change-line {
      position: absolute;
      top: -5px;
      bottom: 0;
      width: 12px;
      z-index: 1;
      transition:
        left $chart-js-animation-duration $chart-js-ease-function,
        opacity 750ms $chart-js-ease-function;
      transform: translate(-50%, 0);

      .scoring-change-caret {
        border-top: 6px solid $blue;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;

        &:before {
          content: "";

          position: absolute;
          top: 2px;
          bottom: 1px;

          width: 1px;
          transform: translate(-50%, 0);
          border-left: 1px dashed $blue;
        }
      }
    }
  }
}
