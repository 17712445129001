@import "../../../_common/style/core/variables";

.comment-display {
  display: flex;
  background-color: white;
  border: 1px solid $grey100;
  border-radius: 8px;

  size: 14px;
  line-height: 21px;

  .avatar-container {
    margin: 16px;

    .user-avatar {
      width: 27px;
      height: 25px;
    }
  }

  .detail-container {
    margin: 18px 18px 18px 0px;
    display: flex;
    flex-direction: column;

    .comment-header {
      display: flex;
      align-items: flex-end;

      .name {
        color: $grey900;
      }

      .date {
        color: $grey300;
      }

      .name + .date {
        margin-left: 8px;
      }
    }

    .comment {
      color: $grey500;
      margin-top: 4px;
      max-height: 84px; // Multiples of line height
      overflow: hidden;
      transition: max-height $animation-timing ease-in-out;

      &.expanded {
        max-height: 8000px; // Needs to be a legit value for transition to work...
      }

      &.placeholder {
        color: $grey200;
        font-style: italic;
      }
    }

    .more {
      .btn-inner {
        justify-content: left;
      }
    }
  }

  &.shaded {
    background-color: $grey50;

    .detail-container {
      .name {
        font-weight: $font-weight-bold;
      }

      .date {
        color: $grey600;
        font-size: 12px;
        font-weight: $font-weight-regular;
        line-height: 150%;
      }
    }

    .comment {
      color: $grey700;
      font-weight: $font-weight-regular;
    }
  }
}
