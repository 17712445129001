@use "../../../_common/style/core/variables";

.domain-ip-action-bar {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-group {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > * + * {
        margin-left: 20px;
      }
    }
  }
}
