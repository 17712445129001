@import "../../../_common/style/core/variables";

.userbase-user-risks-overview {
  .header {
    justify-content: space-between;
  }

  .content-no-risks {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    min-height: 230px; // match the height of the users breakdown card beside it
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 32px;
    gap: 16px;

    min-height: 230px; // match the height of the users breakdown card beside it

    .subtitle {
      font-weight: $font-weight-bold;
    }

    .breakdown {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .risk-severity {
        display: flex;
        padding-bottom: 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid $grey100;

        color: $grey900;
        text-align: right;
        font-size: $font-size;
        font-weight: $font-weight-regular;
        line-height: $line-height;

        .severity-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          img {
            width: 24px;
            height: 24px;
          }
          span {
            color: $grey900;
            font-size: $font-size;
            font-weight: $font-weight-bold;
            line-height: $line-height;
          }
        }
      }
    }
  }
}
