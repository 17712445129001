@use "../../_common/style/core/variables";

.edit-questionnaire-modal {
  padding: 0 variables.$space-xl variables.$space-lg variables.$space-xl !important;

  > p {
    margin-bottom: unset !important;
  }

  div.label {
    text-align: left;
    font-weight: variables.$font-weight-bold;
    margin-bottom: 4px;

    &.required:after {
      content: "*";
      color: variables.$red500;
    }
  }

  .edit-questionnaire-details {
    display: flex;
    flex-direction: column;
    gap: variables.$space-md;
  }
}
