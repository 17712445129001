@use "../core/variables";

.tab-buttons-wrapper {
  height: 32px;
  overflow: hidden;
  position: relative;

  .tab-buttons-scroller {
    overflow-x: scroll;
    //width: 100%;
    height: 32px;
    position: relative;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }

    scrollbar-width: none; /* Firefox only */

    -ms-overflow-style: none; /* IE / Edge */
  }

  .tab-buttons {
    z-index: 0;
    position: absolute;
  }

  .scroll-indicator {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 80px;

    pointer-events: none;

    .arrow-hitbox {
      pointer-events: all;
      width: 40px;
      cursor: pointer;

      &:hover {
        .cr-icon-arrow-right {
          color: variables.$blue;
        }
      }
    }

    .cr-icon-arrow-right {
      display: block;
      font-size: 24px;
      color: variables.$grey300;
    }

    &.left {
      left: 0;

      &.bg-white {
        background: linear-gradient(
          to right,
          rgba(variables.$white, 1),
          rgba(variables.$white, 0)
        );
      }

      &.bg-grey {
        background: linear-gradient(
          to right,
          rgba(variables.$canvas, 1),
          rgba(variables.$canvas, 0)
        );
      }
    }

    &.right {
      right: 0;
      justify-content: flex-end;

      &.bg-white {
        background: linear-gradient(
          to left,
          rgba(variables.$white, 1),
          rgba(variables.$white, 0)
        );
      }

      &.bg-grey {
        background: linear-gradient(
          to left,
          rgba(variables.$canvas, 1),
          rgba(variables.$canvas, 0)
        );
      }
    }
  }

  $new-scroll-btns-width: 77px;
  &.with-new-scroller {
    .tab-buttons {
      padding-right: $new-scroll-btns-width - 1px;
    }

    &.tabs-hidden {
      .tab-buttons > div:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .new-scroll-indicator {
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    width: $new-scroll-btns-width;

    .btn {
      border-color: variables.$grey300;

      .cr-icon-chevron {
        color: variables.$grey300;
      }

      &:hover {
        .cr-icon-chevron {
          color: variables.$blue500;
        }
      }
    }

    .btn:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.tab-buttons.styling-original {
  white-space: nowrap;
  display: flex;

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: variables.$font-weight-bold;
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    padding: 6px 16px;
    position: relative;
    min-width: variables.$min-button-width;
    text-align: center;
    height: 32px;

    background-color: variables.$white;
    color: variables.$grey300;
    border: 1px solid variables.$grey100;

    &:not(:first-of-type) {
      margin-left: -1px;
    }

    &:first-of-type {
      border-top-left-radius: variables.$component-border-radius;
      border-bottom-left-radius: variables.$component-border-radius;
    }

    &:last-of-type {
      border-top-right-radius: variables.$component-border-radius;
      border-bottom-right-radius: variables.$component-border-radius;
    }

    &.active,
    &:hover {
      color: variables.$blue500;
      border-color: variables.$blue500;
      z-index: 1;
    }

    &.active {
      background-color: variables.$blue50;
      cursor: default;
    }

    &:hover {
      background-color: variables.$blue50;
    }

    &.disabled {
      pointer-events: none;
      color: variables.$light-grey;
    }

    transition-property: color, background-color, border-color;
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;
  }

  .tab-popup-wrapper {
    &:not(:first-of-type) .tab {
      margin-left: -1px;
    }

    &:first-of-type .tab {
      border-top-left-radius: variables.$component-border-radius;
      border-top-right-radius: 0;
      border-bottom-left-radius: variables.$component-border-radius;
      border-bottom-right-radius: 0;
    }

    &:last-of-type .tab {
      border-top-left-radius: 0;
      border-top-right-radius: variables.$component-border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: variables.$component-border-radius;
    }
  }

  &.tab-icons {
    .tab {
      min-width: unset;
      color: variables.$grey500;

      &.active {
        color: variables.$blue500;
      }

      [class^="cr-icon-"],
      [class*=" cr-icon-"] {
        font-size: 18px;
        color: inherit;
      }
    }
  }
}

.tab-buttons.tab-buttons-banner {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background-color: variables.$white;
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid variables.$light-grey;
  overflow: hidden;

  .tab {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    position: relative;
    font-size: 14px;
    font-weight: variables.$font-weight-bold;

    &:first-of-type {
      border-right: 1px solid variables.$light-grey;
    }

    &:last-of-type {
      border-left: 1px solid variables.$light-grey;
    }

    &:hover {
      cursor: pointer;
      background-color: variables.$lighter-blue;
    }

    &.active:after {
      position: absolute;
      content: "";
      background-color: variables.$blue;
      bottom: 0;
      left: 0;
      right: 0;
      height: 5px;
    }

    .tab-text {
      font-size: 24px;
      margin-bottom: 5px;
    }

    .tab-subtext {
      font-size: 12px;
      color: variables.$medium-grey;
    }
  }
}

.tab-buttons.styling-flat {
  display: flex;
  width: 100%;
  height: 32px;
  border-bottom: 1px solid variables.$grey100;

  .tab {
    padding: 0px 16px;
    font-size: 14px;
    line-height: 22px;
    color: variables.$grey700;
    cursor: pointer;
    height: 32px;

    &.active {
      color: variables.$grey900;
      font-weight: variables.$font-weight-bold;
      border-bottom: 2px solid variables.$grey900;
    }
  }
}
