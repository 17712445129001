@use "../../_common/style/core/variables";

.compliance-badges-card {
  .compliance-badges-card-inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
    gap: variables.$space-md;
    justify-content: start;

    .badge-card {
      max-width: 300px;
    }
  }

  .empty-card-no-action {
    padding: 0;
  }
}
