@use "../../../_common/style/core/variables";

.app-permission-users-slide-panel {
  // Adjust the report card header to unset the bottom border and adjust padding
  // for this specific component. new-styles is required as this is what report card
  // component is setting
  &.new-styles {
    .header {
      justify-content: space-between;
      border-bottom: unset;
      padding: 0 variables.$space-xl variables.$space-lg variables.$space-xl;
    }
  }

  .slide-panel {
    // Important is required to enforce the width
    width: 700px !important;
  }
  .title {
    border-bottom: unset !important;
  }
  .userbase-app-permissions-users-panel {
    // Userbase specific style to remove bottom border from report card
    border: unset;
    box-shadow: unset;
    // TODO: Why's the above different from PermissionAppUsersPanel?
    .header {
      width: 700px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  .loading-icon-container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .scope-source {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 100%;
  }

  .scope-description {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 100%;
  }

  .content {
    .score-container {
      display: flex;
      flex-direction: row;
      gap: variables.$space-sm;
      align-items: center;
    }
  }

  .permission-list {
    th:first-of-type,
    td:first-of-type {
      padding-left: 0;
    }

    .permission-row {
      .risk-level {
        width: 30%;
      }

      .description-with-subtext {
        line-height: 1.3;

        .description-main-text {
          font-size: variables.$font-size;
        }

        .description-subtext {
          font-size: variables.$font-size-smaller;
          color: variables.$grey500;
        }
      }
    }
  }

  .section-title {
    font-size: variables.$font-size-larger;
  }
}
