@use "../../_common/style/core/variables";

.impersonate-user-select {
  // Place the input using absolute positioning so that
  // we can use the value container to size the input
  // without an exact height
  .ug-select__input {
    position: absolute;
    top: 50%;
    left: variables.$space-sm;
    transform: translateY(-50%);
  }

  .ug-select__value-container {
    min-height: 30px;
  }

  .ug-select__single-value {
    position: unset;
    transform: unset;
    top: unset;
  }

  .ug-select__option--is-selected .user-display .detail-container {
    .name {
      color: variables.$white;
    }

    .email {
      color: variables.$grey100;
    }
  }

  .user-display {
    background-color: inherit;
  }
  .user-display .detail-container {
    align-items: flex-start;
  }
}
