@use "../../../_common/style/core/variables";

.userbase-user-permissions-overview {
  .header {
    justify-content: space-between;
  }

  .content {
    min-height: 270px;
    padding: 20px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .subtitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      div:first-child {
        font-weight: variables.$font-weight-bold;
      }

      .exposure-level {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: variables.$grey900;
        font-size: variables.$font-size;
        font-weight: variables.$font-weight-bold;
        line-height: variables.$line-height;

        .dismissible-popup-container {
          margin-left: 4px;
        }

        .scope-risk-level-display {
          margin-left: 8px;
        }
      }
    }
  }
}
