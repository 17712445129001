@use "../core/variables";

$banner-height: 48px;

#app-content-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;

  #navigation-container {
    display: flex;
    z-index: variables.$side-navigation-z-index;

    .nav-expander-button {
      color: variables.$grey400;
    }

    .top-level-navigation-container {
      width: variables.$left-hand-navigation-width-closed;
      min-width: variables.$left-hand-navigation-width-closed;
      max-width: variables.$left-hand-navigation-width-closed;

      &.open {
        width: variables.$left-hand-navigation-width;
        min-width: variables.$left-hand-navigation-width;
        max-width: variables.$left-hand-navigation-width;
      }
    }

    .product-specific-navigation-container {
      width: variables.$product-specific-navigation-width-closed;
      min-width: variables.$product-specific-navigation-width-closed;
      max-width: variables.$product-specific-navigation-width-closed;

      &.open {
        width: variables.$product-specific-navigation-width;
        min-width: variables.$product-specific-navigation-width;
        max-width: variables.$product-specific-navigation-width;
      }
    }

    // Expandable container for the left-hand top level navigation
    .top-level-navigation-container {
      background: variables.$grey900;

      .navigation-expander {
        .btn-icon {
          &:hover:not(.disabled) {
            background-color: variables.$grey800;
            color: variables.$white;
            mix-blend-mode: unset;

            > * {
              background-color: variables.$grey800;
              color: variables.$white;
            }
          }
        }
      }
    }

    // Expandable container for the product-specific navigation
    .product-specific-navigation-container {
      background-color: variables.$grey100;

      .navigation-expander {
        .btn-icon {
          &:hover:not(.disabled) {
            background-color: rgba(variables.$blue500, 0.05);

            .nav-expander-button {
              color: variables.$blue500;
            }
          }
        }
      }

      &.clickable {
        &:hover {
          > * {
            background-color: rgba(variables.$blue500, 0.05);
          }

          .btn-icon:hover:not(.disabled) {
            background-color: transparent;
          }

          .navigation-bottom {
            background-color: transparent;

            .nav-expander-button {
              color: variables.$blue500;
            }
          }
        }
      }
    }
  }

  #user-actions-bar {
    align-items: center;
    background-color: variables.$grey100;
    display: flex;
    font-size: 24px;
    gap: 8px;
    height: variables.$user-action-bar-height;
    justify-content: flex-end;
    max-height: variables.$user-action-bar-height;
    min-height: variables.$user-action-bar-height;
    padding-right: 24px;
    z-index: variables.$user-action-bar-z-index;

    .user-actions-bar-icon {
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 4px;

      // Don't underline links on hover
      > a:hover {
        text-decoration: unset;
      }

      & + .user-actions-bar-icon {
        margin-left: 8px;
      }

      &:hover,
      &.open {
        background-color: rgba(variables.$blue500, 0.05);

        // User Action Bar - set icon to grey700 when hovered/control is open
        [class^="cr-icon-"],
        [class*=" cr-icon-"] {
          color: variables.$grey700;
        }
      }

      // Make sure the btn-icon hover style doesn't override
      .btn-icon:hover:not(.disabled) {
        background-color: transparent;
      }

      .help-and-support-dropdown-tooltip {
        .dismissable-popup-click-capture {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .help-and-support-dropdown-header {
        color: variables.$grey700;
        font-size: variables.$font-size-smaller;
        font-weight: variables.$font-weight-regular;
        letter-spacing: unset;
        line-height: 24px;
        text-transform: unset;
      }
    }

    .help-support-btn,
    .btn-icon-popup {
      cursor: pointer;
      font-size: variables.$font-size-xx-large;

      display: flex;
      align-items: center;
      justify-content: center;

      height: 32px;
      width: 32px;

      [class^="cr-icon-"],
      [class*=" cr-icon-"] {
        color: variables.$grey500;
      }
    }

    .user-actions-divider {
      height: 40px;
      width: 1px;
      background-color: variables.$grey200;
    }

    .user-menu-dropdown {
      border-radius: 8px;
      width: 204px;

      .multi-product-user-menu {
        max-height: 80vh;

        h3 {
          color: variables.$grey700;
          font-size: variables.$font-size-smaller;
          font-weight: variables.$font-weight-regular;
          letter-spacing: unset;
          line-height: 24px;
          text-transform: unset;
        }
      }

      &:hover,
      &.open {
        background-color: rgba(variables.$blue500, 0.05);
      }

      .user-details {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 8px;
        padding: 4px 8px;

        .name-and-org {
          max-width: 160px;

          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            color: variables.$grey700;
          }

          .name {
            font-size: variables.$font-size-smaller;
            font-weight: variables.$font-weight-regular;
            line-height: variables.$line-height-small;
          }

          .org {
            font-size: variables.$font-size;
            font-weight: variables.$font-weight-bold;
            line-height: variables.$line-height;
          }
        }
      }

      .user-menu-dropdown-chevron {
        &.open {
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }

      > .popup-content {
        top: unset;
        z-index: 5; // Needs to be higher than the ug-page-header
        width: 250px;
      }
    }

    .trial-expiry-banner {
      display: flex;
      height: 32px;
      border-radius: 24px;

      justify-content: center;
      align-items: center;
      gap: 12px;
      padding-left: 0px;
      padding-right: variables.$space-md;

      .trial-expiry-banner-icon {
        font-size: 16px;
      }

      .trial-expiry-banner-text {
        display: flex;
        gap: 4px;
        padding-right: 2px;

        color: variables.$grey800;
        font-size: variables.$font-size-smaller;
        font-style: normal;
        font-weight: variables.$font-weight-bold;
        line-height: variables.$line-height-smaller;
        letter-spacing: 1px;
      }

      .trial-expiry-upgrade-text {
        display: flex;

        color: variables.$grey600;
        font-size: variables.$font-size-smaller;
        font-style: normal;
        font-weight: variables.$font-weight-bold;
        line-height: variables.$line-height-smaller;
        letter-spacing: 1px;
      }

      [class^="cr-icon-"] {
        margin-left: 4px;
      }
    }
  }

  #app-content {
    background-color: variables.$grey100;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.nav-shown {
      // Set the left position of positioned elements that are affected by the navigation width
      .multi-product-nav-content-left {
        left: variables.$left-hand-navigation-width-closed;
      }
    }

    // Some components set a max-width on their content, based on the width of the viewport + navigation
    // The navigation width is variable now, so we'll set the max width for a class here
    // Downstream components should be assigned this class to respect the navigation width
    // The width is:
    // the width of the viewport
    // minus the navigation width (combination of top level and product specific nav widths)
    // minus 40px for padding on the left and right hand side of the main-content-area
    // minus 15px for a scrollbar on the main-content-area
    .multi-product-nav-content-max-width {
      max-width: calc(
        100vw - variables.$left-hand-navigation-width-closed - 95px
      );
    }

    .multi-product-nav-content-left {
      left: variables.$left-hand-navigation-width-closed;
    }

    // Top level nav open, product nav not shown
    &.top-level-nav-open {
      .multi-product-nav-content-max-width {
        max-width: calc(100vw - variables.$left-hand-navigation-width - 95px);
      }

      .multi-product-nav-content-left {
        left: variables.$left-hand-navigation-width;
      }
    }

    // Top level nav closed, product nav closed
    &.product-level-nav-shown {
      .multi-product-nav-content-max-width {
        max-width: calc(
          100vw - variables.$left-hand-navigation-width-closed -
            variables.$product-specific-navigation-width-closed - 95px
        );
      }

      .multi-product-nav-content-left {
        left: variables.$left-hand-navigation-width-closed +
          variables.$product-specific-navigation-width-closed;
      }
    }

    // Top level nav open, product nav closed
    &.top-level-nav-open.product-level-nav-shown {
      .multi-product-nav-content-max-width {
        max-width: calc(
          100vw - variables.$left-hand-navigation-width -
            variables.$product-specific-navigation-width-closed - 95px
        );
      }

      .multi-product-nav-content-left {
        left: variables.$left-hand-navigation-width +
          variables.$product-specific-navigation-width-closed;
      }
    }

    // Top level nav closed, product nav open
    &.product-level-nav-open {
      .multi-product-nav-content-max-width {
        max-width: calc(
          100vw - variables.$left-hand-navigation-width-closed -
            variables.$product-specific-navigation-width - 95px
        );
      }

      .multi-product-nav-content-left {
        left: variables.$left-hand-navigation-width-closed +
          variables.$product-specific-navigation-width;
      }
    }

    // Top level nav open, product nav open
    &.top-level-nav-open.product-level-nav-open {
      .multi-product-nav-content-max-width {
        max-width: calc(
          100vw - variables.$left-hand-navigation-width -
            variables.$product-specific-navigation-width - 95px
        );
      }

      .multi-product-nav-content-left {
        left: variables.$left-hand-navigation-width +
          variables.$product-specific-navigation-width;
      }
    }

    #main-content-area {
      background-color: variables.$white;
      border-top-left-radius: 16px;
      display: flex;
      flex: 1;
      overflow: auto;
      overscroll-behavior: none;
      padding: 0 40px 0 40px;
      position: relative;

      #foot {
        margin-top: 60px;
      }
    }

    #main-content-area-inner {
      flex: 1;
      margin-left: auto;
      margin-right: auto;
      max-width: variables.$max-page-width;
      padding-top: 30px;
      padding-bottom: 60px;

      .main-content-area-inner-bottom-padding {
        min-height: 112px; // Height of old padding (60px) and old footer (52px)
      }
    }

    &:has(.horizontally-scrollable-x-table) {
      // Regression task:
      // https://app.asana.com/0/1201142709862962/1208349597365840/f
      // Fix HorizontallyScrollableXTable such that when there are many
      // columns, wider than the viewport, the overall page / card will
      // keep the viewport width, and the table will scroll within the
      // bounds of its container.
      min-width: 0;

      #main-content-area-inner {
        min-width: 0;
      }
    }

    .card-shadow,
    .card {
      box-shadow: variables.$box-shadow-4dp;
    }
  }

  // Centrally manage all the filter and changes offsets
  // including .banner-open and .nav-shown scenarios
  #app-content.nav-shown {
    // nav shown, banner closed, changes or filters shown individually
    #filter_view_frame,
    #changes_view_frame .changes-view-frame {
      top: variables.$user-action-bar-height;
      border-top-left-radius: 16px;
    }

    // nav shown, banner closed, changes and filters shown together
    #filter_view_frame.active + #changes_view_frame .changes-view-frame {
      top: variables.$user-action-bar-height + variables.$filter-bar-height;
    }

    // animate the filter bar when it shows up
    #main-content-area {
      transition: padding-top variables.$animation-timing ease-in-out;
    }
    #main-content-area:has(#filter_view_frame.active) {
      padding-top: variables.$filter-bar-height;
    }
  }

  &.banner-open {
    max-height: calc(100vh - $banner-height);

    #app-content.nav-shown {
      // nav shown, banner closed, changes or filters shown individually
      #filter_view_frame,
      #changes_view_frame .changes-view-frame {
        top: $banner-height + variables.$user-action-bar-height;
      }

      // nav shown, banner closed, changes and filters shown together
      #filter_view_frame.active + #changes_view_frame .changes-view-frame {
        top: $banner-height + variables.$user-action-bar-height +
          variables.$filter-bar-height;
      }
    }
  }
}

$banner-right-padding: 24px;

.banner {
  flex: 1 0 100%;
  text-align: center;
  display: flex;
  height: $banner-height;
  background: variables.$blue500;
  padding: 8px 24px 8px 96px;
  justify-content: space-between;
  width: 100vw;

  .details {
    width: calc(100% - $banner-right-padding);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: variables.$space-xs;
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-regular;
    line-height: variables.$line-height;

    .link {
      color: variables.$blue100;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: auto;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      white-space: nowrap;
    }

    .link:visited {
      color: variables.$blue100;
    }

    .link:hover {
      color: variables.$white;
    }

    .title {
      color: variables.$white;
      font-weight: variables.$font-weight-bold;
      white-space: nowrap;
    }

    .text {
      color: variables.$blue100;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 1350px) {
        display: none;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: variables.$space-xs;

    .button {
      display: flex;
      width: 32px;
      height: 32px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      border-radius: 4px;

      .icon {
        color: variables.$grey100;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        align-content: center;
      }
    }
  }
}
