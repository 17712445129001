@import "../../../_common/style/core/variables";

.checkbox-group {
  .checkbox-group-children {
    margin-left: 22px;
  }

  .checkbox-with-desc {
    align-items: flex-start;

    &.clickable:hover .title-desc .title {
      color: $blue;
    }

    .title-desc {
      line-height: 20px;

      .desc {
        font-size: $font-size-smaller;
        color: $grey500;
      }
    }
  }
}
