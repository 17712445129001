@import "../../../_common/style/core/variables";

.app-permissions-score-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .score {
    white-space: nowrap;

    .cstar-text {
      .score-text {
        min-width: 0;
      }
    }
  }
}

.application-permissions-expanded-table {
  background-color: $grey50;
  .app-permissions-user-list {
    th:first-of-type,
    td:first-of-type {
      padding-left: 32px;
    }
  }
}
