@import "../core/variables";

$avatar-size: 25px;
$private-yellow: rgb(245, 231, 140);

.correspondence {
  background-color: $white;
  @keyframes flash-in {
    0% {
      background-color: $light-grey;
    }
    100% {
      background-color: none;
    }
  }

  &.highlight {
    animation: ease-in-out flash-in 3s;
  }
  .message {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    .avatar-col {
      flex-grow: 0;

      .avatar {
        position: relative;
        display: block;
        width: $avatar-size;
        min-width: $avatar-size;
        height: $avatar-size;
        min-height: $avatar-size;
        border-radius: 50%;
        background-size: contain;
        margin-right: 10px;
        z-index: 2;
      }
    }

    .content-col {
      flex-grow: 1;

      .message-header-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: $avatar-size;

        .header-actions {
          * + * {
            margin-left: 10px;
          }
        }

        .hover-action {
          display: inline; // Change to display: none to only show on hover
          color: $blue;
          font-size: 11px;
          text-transform: uppercase;
          cursor: pointer;
        }

        .btn-icon-popup {
          display: none;
        }
      }

      .message-header {
        span {
          margin-right: 8px;
        }

        .user-name {
          font-weight: $font-weight-bold;
        }

        .time-ago {
          color: $medium-grey;
          font-size: 11px;
          text-transform: uppercase;
        }
      }

      .message-input {
        margin-bottom: 0;
      }

      & > .message-input {
        margin-top: 20px;
      }

      .content {
        line-height: 1.4;

        &,
        & > div {
          white-space: pre-line;
        }

        .edited-text {
          margin-left: 5px;
          color: $medium-grey;
        }
      }

      & > .correspondence {
        margin-top: 20px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: $avatar-size + 5px;
      bottom: 0;
      left: $avatar-size * 0.5;
      transform: translateX(-50%);
      width: 1px;
      background-color: $light-grey;
      z-index: 1;
    }

    &:hover {
      &
        > .content-col
        > .message-header-wrapper
        > .header-actions
        > .hover-action {
        display: inline !important;
      }
    }

    &.system-generated {
      background-color: $grey50;

      .content-col-inner {
        border: none;
      }

      .avatar,
      .header-actions,
      .message-header-wrapper {
        display: none !important;
      }

      .content {
        text-align: center;
        font-style: italic;

        p,
        & > div {
          margin-top: 0;
        }
      }
    }
  }

  .message-private {
    border-radius: 5px;
    padding: 5px;
    margin: -5px;
    box-shadow: inset 0 0 0 1px $private-yellow;
    @extend .message;

    &:before {
      left: 17.5px;
    }

    // Don't apply the special styles to any nested private messages. Only top level ones.
    .message-private {
      box-shadow: none;
      padding: 0;
      margin: 0;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &:before {
        left: 12.5px;
      }
    }
  }

  .unread-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    vertical-align: -1px;
    background-color: $blue;
    border-radius: 50%;
  }

  &.new-styles {
    background: none;

    &,
    .correspondence {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;

      & > .message,
      & > .message-private {
        &:not(:last-of-type) {
          margin-bottom: 0;
        }

        margin-top: 20px;
      }
    }

    .message {
      &:before {
        display: none;
      }
    }

    .message-private {
      box-shadow: none;

      & > .content-col > .content-col-inner {
        border-color: $private-yellow;
        background-color: lighten($private-yellow, 20%);
      }
    }

    .content-col > .correspondence {
      margin-top: 0;
    }

    .avatar-col .avatar {
      width: 32px;
      min-width: 32px;
      height: 32px;
      min-height: 32px;
      margin-top: 12px;
    }

    .content-col-inner {
      border: 1px solid $grey100;
      border-radius: 6px;
      padding: 16px;
      z-index: 1;
      position: relative;
    }

    .message-header {
      line-height: 150%;
      color: $grey900;

      .time-ago {
        font-size: 11px !important;
        color: $grey500 !important;
        text-transform: none !important;
        line-height: 100% !important;
      }

      .unread-circle {
        width: 6px;
        height: 6px;
        margin-right: 7px;
        line-height: 14px;
        vertical-align: 2px;
      }
    }

    .message-header-wrapper {
      .header-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
      }

      .hover-action {
        display: none !important;
      }

      .btn-icon-popup {
        display: block !important;
      }
    }

    .content {
      color: $grey500;

      div,
      p {
        margin-bottom: 0;
      }

      .message-input {
        margin-bottom: -16px;
      }
    }

    .content-col > .message-input {
      padding-left: 48px;
    }

    .text-field {
      margin-top: 8px;
      margin-bottom: 8px !important;
    }
  }
}
