@import "../../../../_common/style/core/variables";

.domain-portfolio-selection-modal {
  width: 600px !important;

  .create-portfolio-btn {
    margin-top: 16px !important;
    align-self: flex-start;

    .btn-inner {
      color: $blue;
    }

    &:hover .btn-inner {
      color: $blue700;
    }
  }

  .form-grid {
    grid-template-columns: 150px auto;

    h3 {
      margin-bottom: 0;
      line-height: 32px;
    }

    .grid-line {
      border-top: 1px solid $grey100;
      grid-column: 1 / 3;
    }

    .color-checkbox {
      height: 32px;
    }

    .dismissible-popup-container.left {
      padding-right: 0;
    }
  }
}

.domain-portfolio-selection-modal-foot {
  .btn-align-left {
    margin-right: auto;
  }
}
