@import "../../_common/style/core/variables";

.gpt-right-panel {
  background-color: white;
  overflow-y: auto;

  .top-section {
    padding: 24px;
  }

  .gpt-panel-header {
    display: flex;
    flex-direction: row;

    .question-title {
      display: flex;
      padding: 4px 8px;
      align-items: flex-end;
      gap: 8px;
      border-radius: 4px;
      background: $blue100;

      color: $blue500;
      font-size: $font-size;
      font-weight: $font-weight-bold;
      line-height: 150%;

      i {
        color: $blue500;
        font-size: $font-size;
        font-weight: $font-weight-bold;
        line-height: 150%;
      }
    }

    .close-button {
      margin-left: auto;
      padding-left: 8px;
      border-left: solid 1px $grey100;
      border-radius: 0;

      span {
        font-size: 24px;
      }
    }
  }

  .use-button {
    margin-left: auto;
    border: 1px solid $grey100;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 4px;
    height: 24px;

    &:hover {
      border-color: $blue300;
      background-color: $blue100;
      // ensures the colored border shows on hover
      // since we are using a margin-left -1 below
      z-index: 1;

      .btn-inner {
        color: $blue600;
      }
    }
  }

  .letter {
    margin-right: 8px;
    text-transform: uppercase;
    color: $grey400;
    font-size: $font-size;
    font-weight: $font-weight-bold;
    line-height: 22px;
  }

  .question {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    line-height: 22px;

    .main-text {
      color: $grey900;
      font-size: $font-size;
      font-weight: $font-weight-bold;
      line-height: 22px;
    }
  }

  .sub-text {
    margin-top: 8px;
    color: $grey500;
    font-size: $font-size;
    font-weight: $font-weight-regular;
    line-height: 22px;
  }

  .autofill-gpt-panel {
    overflow: auto;

    .sub-title {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      color: $grey500;
      line-height: 16px;
      letter-spacing: 1px;
      font-size: $font-size-smaller;
    }

    .suggestion-container {
      margin: 0 24px 24px 24px;
      display: flex;
      flex-direction: column;

      .suggestion {
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px 4px 0 4px;
        border: 1px solid $grey100;
        background: $grey50;

        .suggestion-heading {
          display: flex;
          flex-direction: row;
          gap: 8px;

          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;

          .buttons {
            flex-grow: 1;
            margin-left: auto;

            .btn:not(:last-of-type) {
              margin-right: 8px;
            }
          }
        }
      }

      .suggestion-text {
        padding: 4px 0;
      }
    }

    .sources {
      padding: 28px;
      background-color: $blue50;
      height: auto; // TODO

      .sub-title {
        text-align: center;
        margin-bottom: 24px;
      }

      .gpt-source-box {
        padding: 16px;
        border-radius: 8px;
        background-color: white;
        display: flex;
        flex-direction: column;

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }

        .header {
          display: flex;
          flex-direction: row;
          margin-bottom: 4px;
          align-items: center;

          .title {
            font-size: $font-size-larger;
            color: $grey900;
            font-weight: $font-weight-bold;
            line-height: 22px;

            &.external-link {
              color: $blue500;
              &:hover {
                cursor: pointer;
              }
            }
          }

          .link {
            margin-left: auto;
          }
        }

        .sub {
          font-size: $font-size-smaller;
          line-height: 18px;
          color: $grey600;
        }

        .context {
          margin-top: 16px;

          .question {
            padding: 8px;
          }

          .answer {
            padding: 8px;
            border-radius: 4px 4px 0 4px;
            border: 1px solid $grey100;
            background: $grey50;
          }
        }
      }
    }
  }
}
