@import "../../../../_common/style/core/variables";

.survey-name-and-type {
  display: flex;
  flex-direction: column;

  .survey-type {
    margin-top: 4px;
    color: $grey500;
    font-size: 12px;
  }
}
