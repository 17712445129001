@use "../../../../_common/style/core/variables";

.autofill-process-header {
  .header {
    display: flex;
    flex-direction: row;
    align-content: center;

    .header-part {
      align-self: center;
      .heading {
        color: variables.$black;
        font-size: variables.$font-size-extra-large;
        font-weight: variables.$font-weight-bold;
        line-height: 28px;
      }

      .sub-heading {
        color: variables.$grey800;
        font-size: variables.$font-size;
        font-weight: variables.$font-weight-regular;
        line-height: 22px;
      }
    }

    .filled-icon {
      height: 48px;
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      margin-right: 16px;
      background-color: variables.$green100;
      .cr-icon-check {
        color: variables.$green600;
      }
    }

    .coffee-cup {
      height: 48px;
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      background-color: variables.$blue100;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      img {
        height: 32px;
        width: 32px;
      }
    }
  }
}

.autofill-process-footer {
  .footer-left {
    max-width: 400px;
    margin-right: auto;
  }
}

.autofill-process-modal {
  .scroll-area {
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow: hidden;
    width: 100%;

    .loader-icon {
      margin: auto;
    }

    &.has-nodes:before {
      content: "";
      z-index: 100;
      width: 95%;
      height: 40%;
      display: block;
      position: absolute;
      background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }

    .filler {
      min-height: 300px;
    }

    .nodes-area {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex-grow: 1;

      .question-box {
        display: flex;
        flex-direction: row;
        padding: 8px 16px;
        gap: 16px;
        align-items: center;

        border-radius: 8px;
        background: variables.$grey50;

        .pill-label {
          margin-left: auto;
        }
      }
    }
  }
}
