@use "../core/variables";

.full-page-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: variables.$canvas;
  z-index: 999998;
  overflow: scroll;

  &.v2 {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .modal-content-container {
      position: relative;
      margin: 70px auto 20px auto;
      background-color: variables.$white;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid #ecedf3;
      box-sizing: border-box;
      box-shadow: 0 4px 14px rgba(44, 50, 56, 0.1);
      max-width: 1000px;
      min-width: 400px;

      &.scroll-content {
        margin: 70px auto;
        overflow: hidden;

        > form {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 140px);
        }

        .modal-header {
          border-bottom: 1px solid variables.$grey100;
        }

        .modal-content {
          overflow-x: auto;
        }
      }

      &.width-600 {
        width: 600px;
      }
      &.width-800 {
        width: 800px;
      }
      &.width-1000 {
        width: 1000px;
      }

      &.vertical-center {
        margin: auto;
      }

      .modal-close {
        top: 28px;
      }

      .modal-header {
        padding: 32px 32px 0 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2 {
          font-size: 18px;
          line-height: 24px;
          padding-bottom: 0;
          margin-bottom: 20px;
          font-weight: variables.$font-weight-bold;
        }

        h2 + p {
          margin-top: -12px;
        }

        p {
          margin-bottom: 0;
          line-height: 150%;
          color: variables.$grey600;
        }
      }

      &.closable {
        .modal-header {
          h2 {
            margin-right: 64px;
          }
        }
      }

      // When we only have a header and no modal-content, render it a little differently
      &.empty-content {
        .modal-header {
          h2 {
            font-size: 16px;
            font-weight: variables.$font-weight-regular;
            line-height: 150%;
            margin-bottom: 30px;
          }
        }
      }

      .modal-content {
        margin: 0px;
        max-width: inherit;
        padding: 24px 32px;
        line-height: 150%;

        &.no-footer {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding-bottom: 32px;
        }

        // Override RTE default styling when in modals
        .rich-text-edit-v2 {
          max-width: 516px;

          .editor-inner {
            max-height: 250px;
          }
        }

        p {
          margin-top: 0px;
          font-size: 14px;
          line-height: 21px;
          font-style: normal;
        }

        .info-banner-block p {
          color: inherit;
          margin-bottom: 0;
        }

        .modal-section-box {
          border: 1px solid variables.$grey100;
          border-radius: 8px;
          padding: 24px;

          &:not(:last-of-type) {
            margin-bottom: 16px;
          }

          h3 {
            font-size: 14px;
            line-height: 150%;
            font-weight: variables.$font-weight-bold;
            color: variables.$grey900;
          }
        }

        &:empty {
          padding: 0;
        }
      }

      &.compact {
        border: 0;
        .modal-content {
          padding: 0;
        }
      }

      .modal-footer {
        border-top: 1px solid #ecedf3;
        background-color: #f9fbfd;
        padding: 32px;
        padding-top: 24px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;
        justify-content: flex-end;

        button {
          margin-top: 0;

          [class^="cr-icon-"],
          [class*=" cr-icon-"],
          [class^="icon-"],
          [class*=" icon-"] {
            margin-right: 5px;
          }

          & + button,
          & + .tooltip-button {
            margin-left: 20px;
          }
        }

        .validation-error {
          align-self: center;
          margin-right: 20px;
          color: variables.$red;
        }

        .footer-left {
          flex: 1;
          justify-content: flex-start;
        }

        .left-button {
          margin-right: auto;
        }
      }

      /* Move these styles into header,content,footer after design consolidated */
      label {
        font-weight: variables.$font-weight-bold;
        font-size: 14px;
        line-height: 16px;
        color: variables.$grey900;
      }

      .text-field + label {
        margin-top: 12px;
      }

      .btn-group {
        justify-content: flex-end;
      }

      h2 {
        text-align: left;
      }

      form {
        text-align: left;

        button {
          margin-top: 0;
        }
      }

      :not(.x-table):not(.cr-datagrid) {
        > table:not(.info-table) {
          width: 100%;
          border-collapse: collapse;

          tbody {
            tr {
              padding-top: 5px;
              padding-bottom: 5px;

              td {
                vertical-align: top;
                padding: 24px 0px;
                border-bottom: 1px solid #ecedf3;

                &:first-child {
                  width: 220px;
                  padding-right: 20px;
                }
              }
            }

            tr:first-child {
              td {
                padding-top: 0px;
              }
            }

            tr:last-child {
              td {
                border-bottom: none;
                padding-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 1;

    span.icon-x {
      margin: 0px !important;
    }

    span {
      font-size: 1.3rem;
    }
  }

  .modal-content {
    margin: 70px auto 0 auto;
    max-width: 700px;
    background-color: variables.$white;
    display: flex;
    flex-direction: column;
    padding: 60px 150px;

    h2 {
      font-size: 1.7rem;
      text-align: center;
    }

    p {
      font-style: italic;
      font-weight: variables.$font-weight-regular;
      color: variables.$grey500;
      margin-bottom: variables.$global-padding * 3;
      line-height: 1.4rem;
    }

    p,
    label {
      font-size: 1rem;
    }

    .label-detail {
      text-align: left;
      font-size: 0.9rem;
    }
  }

  form {
    text-align: center;

    label {
      text-align: left;
      cursor: default;
      margin-bottom: variables.$global-padding * 0.5;
    }

    input,
    textarea {
      display: block;
      width: 100%;
    }

    .text-field {
      margin-bottom: 8px;
    }

    textarea + label {
      margin-top: variables.$global-padding * 3;
    }

    button {
      margin-top: variables.$global-padding * 3;

      & + button {
        margin-left: 16px;
      }
    }

    .btn-group {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: variables.$global-padding * 0.5;

      &.left {
        justify-content: flex-start !important;
        flex: 1;
      }

      &.no-margin button {
        margin-top: 0;
      }
    }

    .input-action-container label {
      cursor: pointer;
      font-size: 0.875rem;
    }

    .checkbox-container label {
      margin-top: 0;
      cursor: pointer;
    }

    fieldset {
      border: none;
      margin: variables.$global-padding 0 0 0;
      padding: 0;
      width: 100%;

      table {
        width: 100%;

        tr td:last-of-type {
          text-align: right;
        }

        a {
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 11px;
        }
      }

      legend {
        text-align: left;
      }
    }
  }

  .form-grid {
    display: grid;
    grid-template-columns: 300px auto;
    grid-row-gap: 24px;

    .left {
      padding-right: 80px;
    }

    .double {
      grid-column: 1 / span 2;

      p {
        margin-bottom: 16px;
      }
    }

    .grid-section {
      padding-bottom: 32px;
      border-bottom: 1px solid variables.$grey100;
    }

    .grid-section:nth-last-of-type(-n + 2) {
      padding-bottom: unset;
      border-bottom: unset;
    }

    h3 {
      font-size: 14px;
      font-style: normal;
      font-weight: variables.$font-weight-bold;
      line-height: 16px;
      text-align: left;
      margin-bottom: 8px;
    }
  }
}
