@use "../core/variables";

.text-field {
  margin-bottom: 4px;

  input,
  textarea {
    width: 100%;
  }

  &.orange {
    input,
    textarea {
      background-color: variables.$orange20;
    }
  }

  textarea {
    resize: vertical;
  }

  .text-field-additional {
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    line-height: 14px;
    min-height: 18px; // Minimise jank for error messages etc

    .error-text {
      color: variables.$red500;
      font-style: normal;

      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0;

      .icon-info {
        font-size: 14px;
        margin-right: 2px;
      }
    }

    .info-text {
      color: variables.$grey500;
    }

    .messages {
      .error-text,
      .info-text {
        display: flex;
        align-items: center;
        margin-top: 4px;

        .icon-info {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }

    .char-count {
      margin-top: 4px;
      color: variables.$grey500;
      font-weight: variables.$font-weight-regular;

      span {
        color: variables.$grey500;

        transition: color ease-in-out 250ms;

        &.error-text {
          color: variables.$red500;
        }
      }
    }
  }

  &.static-editable {
    position: relative;

    &:hover {
      input,
      textarea {
        &:not(:focus) {
          background-color: variables.$blue50;
          border-radius: variables.$component-border-radius;
        }
      }
    }

    input,
    textarea {
      height: unset;
      background: none;
      border-color: transparent;
      line-height: 150%;
      padding: 6px 25px 6px 10px;
      margin: -6px -25px -6px -10px;

      &:focus,
      &.error {
        border-color: variables.$grey200;
        background-color: white;
        text-decoration: none;

        & ~ .static-editable-edit {
          display: none;
        }
      }
    }

    textarea {
      resize: none !important;
    }

    .text-field-additional {
      display: none;
    }

    .static-editable-edit {
      position: absolute;
      top: 1px;
      right: 14px;
      color: variables.$grey300;
      font-size: 20px;
      line-height: inherit;
      pointer-events: none;
    }
  }

  &.with-suffix {
    input[type="text"] {
      padding-right: 45px;
    }

    .suffix-label {
      position: relative;
      display: block;
    }

    .suffix-label::after {
      content: attr(data-value);
      position: absolute;
      top: 8px;
      right: 10px;
      display: block;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
