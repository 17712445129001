@import "../../../../_common/style/core/variables";

.vendor-assessment-surveys-table {
  .name-cell {
    width: 40%;
    max-width: 40%;
    .status-pill {
      margin-left: 8px;
    }
  }

  .access-cell {
    min-width: 120px;
  }

  .grade-with-score {
    display: flex;
    flex-direction: row;

    .color-grade {
      margin-right: 8px;
    }
  }
}
