@import "../../../_common/style/core/variables";

.complete-vendor-assessment-modal-header {
  max-width: 710px;
  width: 710px;
}
.complete-vendor-assessment-modal-footer {
  .cr-icon-check {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
}
