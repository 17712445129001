@use "../../../_common/style/core/variables";

.vendor-assessment-v3 {
  .ug-page-header {
    .page-title-right {
      [class^="cr-icon-"] {
        font-size: 16px;
      }
    }

    .sub-title {
      color: variables.$grey600;
      font-size: variables.$font-size;
      font-weight: variables.$font-weight-regular;
      line-height: 22px;
    }
  }

  .vendor-assessment-v3-summary {
    margin-bottom: 32px;
  }

  .intro-section {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 32px;

    .intro-text {
      color: variables.$grey500;
      font-size: variables.$font-size-larger;
      line-height: 24px;
    }
    .autofill-section {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 30%;

      .autofill {
        width: 135px;
        &.running {
          width: 160px;
        }
      }

      .autofill-progress {
        width: 160px;
      }
    }
  }

  .vendor-assessment-bar {
    .right-content {
      display: flex;
    }

    .saving-section {
      display: flex;
      flex-direction: row;
      align-items: center;

      font-weight: variables.$font-weight-bold;
      line-height: 22px;

      .cr-icon-check {
        color: variables.$green500;
        margin-left: 8px;
      }

      .loader-icon {
        margin-left: 8px;
      }
    }
  }

  .vendor-assessment-v3-risk-card {
    margin-bottom: 48px;
  }

  .vendor-assessment-text-card:last-of-type {
    margin-bottom: 48px;
  }
}
