.alerts {
  .alerts-top,
  .alerts-bottom {
    position: fixed;
    z-index: 10000000000; // Needs to be above modal
    pointer-events: none; // Pass through click events

    > * {
      pointer-events: auto;
    }
  }

  .alerts-top {
    top: 20px;
    right: 30px;
  }

  .alerts-bottom {
    bottom: 40px;
    right: 30px;
  }

  .alert-banner {
    min-width: 640px;
    width: 640px;
    max-width: 640px;

    .info-banner {
      box-shadow: 0px 10px 15px 0px #4046681a;
    }
  }
}
