@import "../../../_common/style/core/variables";

.userbase-identity-breaches-header {
  .label-list {
    // Remove left border and padding from label list for data classes
    border-left: unset;
    padding-left: unset;
  }

  .header-label {
    padding-left: $space-sm;
  }
}
