@import "../../../_common/style/core/variables";

.domains-container {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $white;

  .x-table table thead tr th {
    padding-left: 0px;
    position: unset !important;
  }

  .x-table tbody > tr:last-child > td {
    border-bottom: 0px !important;
  }

  .x-table table tbody tr:last-of-type {
    border-bottom: 0px !important;
  }

  .x-table table {
    border-bottom: 0px !important;
  }

  .x-table table tbody {
    border-bottom: 0px !important;
  }

  .x-table table th:last-of-type,
  .x-table table td:last-of-type {
    padding-right: 0px !important;
  }

  .x-table table tbody tr:not(.expanded-content-row) {
    height: 48px;
  }

  .x-table th {
    padding-top: 0;
    vertical-align: middle;
  }

  .clickable:not(.pill-label) {
    padding-left: 0px !important;
  }

  .status-cell {
    width: 0;
    white-space: nowrap;

    .seperator-div {
      margin: 0 10px;
      vertical-align: middle;
      width: 1px;
      border-left: 1px solid $grey100;
      height: 25px;
    }

    .seperator-div,
    .btn {
      display: inline-block;
    }
  }

  .domain-cell {
    color: $blue500;
    text-decoration: underline;

    &:hover {
      color: $blue !important;
      text-decoration: none;
    }

    &.waived {
      color: $grey400;
      font-style: normal;

      &:hover {
        color: $grey400 !important;
      }
    }
  }

  .detected-cell {
    width: 0;
    white-space: nowrap;
    padding-right: 30px !important;

    &.waived {
      color: $grey400;
      font-style: normal;
    }
  }

  .right {
    margin-left: 20px;
  }

  .domain {
    min-height: 24px;
    display: flex;
    align-items: center;

    span {
      padding-right: 8px;
      text-decoration: underline;
    }

    &:hover {
      span {
        color: $blue;
      }

      cursor: pointer;
    }
  }

  .all-waived {
    font-style: normal;
  }
}
