@import "../../_common/style/core/variables";

.org-config {
  margin-top: 20px;
  text-align: left;

  .product-family {
    margin-top: 10px;
  }

  .vert-spacer {
    height: 20px;
  }

  .indent {
    margin-left: 2px;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-left: 1px solid #dddddd;

    label {
      font-size: 0.875rem;
    }
  }

  .left-align {
    text-align: left;
    margin: 0px;
    margin-top: 5px;
  }

  .radiolist {
    margin-top: 5px;
    margin-bottom: 30px;

    .color-checkbox {
      margin-top: 0 !important;
      width: unset;
    }
  }

  .inputlist {
    margin-top: 5px;
    margin-bottom: 30px;

    input {
      display: block;
      width: 100%;
    }
  }

  .one-time {
    margin-top: 5px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    .halfspacer {
      width: 10px;
    }
    .one-time-edit {
      width: 150px;
    }
    .monthly-report-edit {
      width: 200px;
    }
  }

  .label-with-info {
    margin: 0;
  }

  input + .input-action-container {
    margin-top: 30px;
  }

  .vendor-removal-warning {
    background-color: $red;
    color: $white;
    text-align: left;
    width: 400px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  input + .color-checkbox {
    margin-top: 10px;
  }

  .color-checkbox + .color-checkbox {
    margin-top: 10px;
  }
}
