@use "../../../_common/style/core/variables";

.org-sender-email-settings-card {
  .blurb-and-settings {
    display: flex;

    > div {
      width: 50%;
      padding: 20px;
    }
  }

  label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
  }

  .color-checkbox {
    margin-bottom: 12px;
  }

  .default-email-address {
    display: flex;
    width: 100%;

    .text-field {
      width: 66%;

      input:disabled {
        border: 1px solid variables.$grey200 !important;
        background-color: variables.$grey100 !important;
        color: variables.$grey500 !important;
      }
    }

    .text-field + .text-field {
      width: 34%;
      margin-left: 8px;
    }
  }

  .email-domain-verification-display {
    td {
      overflow-wrap: break-word;
    }
  }

  button {
    margin-top: 20px;
  }

  button + button {
    margin-left: 10px;
  }

  .email-domain-verification-display {
    button {
      margin-top: 0px;
    }

    td {
      padding-top: 24px;
      padding-bottom: 24px;
      vertical-align: top;
    }

    .setting-type {
      font-weight: variables.$font-weight-bold;
    }

    .verification {
      text-align: center;
    }

    .with-action {
      display: flex;

      .btn-icon-popup {
        height: 32px;
        margin-left: 4px;
      }
    }

    .record-name-and-value-display {
      max-width: 500px;

      .record-name-and-value {
        &:first-of-type {
          margin-bottom: 32px;
        }

        .desc {
          color: variables.$blue500;
        }

        .val-container {
          display: flex;
          align-items: center;

          .val {
            white-space: pre-wrap;
            overflow-wrap: break-word;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.update-return-path-modal-header {
  width: 600px;
}

.update-return-path-modal {
  width: 600px;

  .modal-form .form-section .form-section-input {
    display: flex;
    flex-direction: row;

    .text-field {
      width: 66%;

      input:disabled {
        border: 1px solid variables.$grey200 !important;
        background-color: variables.$grey100 !important;
        color: variables.$grey500 !important;
      }
    }

    .text-field + .text-field {
      width: 34%;
      margin-left: 8px;
    }
  }
}
