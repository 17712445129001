@use "../../../_common/style/core/variables";

.book-expert-modal-header {
  h2 {
    margin-bottom: 0 !important; // override base margin
  }
}

.book-expert-modal {
  .body-content {
    font-weight: variables.$font-weight-regular;
    font-size: variables.$font-size;
    line-height: 22px;
    color: variables.$grey600;
    height: 100%;
    width: 100%;
    text-align: left;

    ul {
      margin: 0px;
    }
  }

  .calendar-container {
    margin-top: 12px;
    height: 513px;
    width: 100%;
    text-align: center;
    border-radius: variables.$card-border-radius;
    overflow: hidden;

    iframe {
      height: 100%;
      width: 100%;
      border: 0;
      overflow: hidden;
    }
  }
}
