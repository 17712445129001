@use "../../_common/style/core/variables";

.answer-option-container {
  margin-bottom: 8px;

  .color-checkbox-inner {
    align-items: flex-start;
    flex-grow: 1;
    .box {
      margin-top: 10px;
      margin-right: 12px;
    }
    .label {
      white-space: normal;
    }

    &.disabled {
      opacity: unset;
      pointer-events: unset;
    }
  }

  &:hover {
    .answer-option:not(.disabled) {
      background-color: variables.$blue100;
      border: 1px solid variables.$blue500;

      .id-container {
        border: 1px solid variables.$blue500;
        .id {
          color: variables.$blue500;
        }
      }

      .text {
        color: variables.$blue500;
      }
    }
  }

  .answer-option {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    border: 1px solid variables.$grey200;
    border-radius: 4px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    background-color: white;

    &.selected {
      background-color: variables.$blue100;
      border: 1px solid variables.$blue500;

      .id-container {
        border: 1px solid variables.$blue500;
        .id {
          color: variables.$blue500;
        }
      }

      .text {
        color: variables.$blue500;
      }

      &.green {
        background-color: variables.$green50;
        border: 1px solid variables.$green500;

        .text {
          color: variables.$green500;
        }

        .id-container {
          border: 1px solid variables.$green500;
          .id {
            color: variables.$green500;
          }
        }
      }

      &.red {
        background-color: variables.$red50;
        border: 1px solid variables.$red500;

        .text {
          color: variables.$red500;
        }

        .id-container {
          border: 1px solid variables.$red500;
          .id {
            color: variables.$red500;
          }
        }
      }
    }

    &.orange {
      background-color: variables.$orange20;
    }

    &.disabled {
      cursor: not-allowed;
    }

    .id-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid variables.$grey200;
      border-radius: 4px;
      background-color: white;
      min-width: 24px;
      min-height: 24px;
      margin-right: 12px;
      transition: all 250ms ease-in-out;

      .id {
        color: variables.$grey300;
        font-size: 12px;
        line-height: 20px;
        transition: all 250ms ease-in-out;
      }
    }

    .text {
      color: variables.$grey600;
      font-size: 14px;
      line-height: 21px;
      transition: all 250ms ease-in-out;
    }
  }
}
