@use "../../../_common/style/core/variables";

.userbase-create-remediation-request {
  .report-card {
    margin-top: 24px;
  }

  .settings-card {
    .request-name-section {
      .text-field {
        width: 100%;
      }
    }

    .contact-select {
      width: 100%;
    }

    .description-editor {
      width: 100%;
    }
  }

  .action-bar {
    .left-side {
      margin-right: auto;
    }

    .right-side {
      .btn:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }
}
