@use "../../core/variables";

.survey-details-invite-collaborator {
  .user-section {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    border-radius: 8px;
    background: variables.$blue50;
    padding: 8px;

    .text-field {
      width: 400px;
      margin-bottom: 0;
    }

    .pill-label {
      margin-left: auto;
    }

    .btn-icon {
      i {
        font-size: 20px;
      }
    }
  }

  .avatar-and-pill {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    .pill-label {
      margin-left: auto;
      height: 22px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .user-avatar-and-name .user-email {
    max-width: 400px;
  }

  input {
    max-width: unset;
  }

  .label {
    color: variables.$grey900;
    font-size: variables.$font-size;
    font-weight: variables.$font-weight-bold;
    line-height: 22px;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
  }

  .dismissible-popup-container {
    width: min-content;
  }

  .avatar-with-pill {
    display: flex;
    flex-direction: row;

    .pill-label {
      margin-left: auto;
      height: 22px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .share-btn {
    width: 84px;
  }

  .divider {
    margin: 24px 0;
    border-bottom: 1px solid var(--grey-grey-100, #ecedf3);
  }

  .shared-with-list {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    > * + * {
      margin-top: 16px;
    }

    .cr-icon-info {
      color: variables.$grey400;
    }

    .user-avatar-and-name {
      &:last-of-type {
        margin-bottom: 16px;
      }
    }
  }
}
