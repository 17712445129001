@use "../../../../_common/style/core/variables";

.recipe-autosave-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .loader-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  .last-saved-text {
    width: 100%;
    font-size: 12px;
    color: variables.$grey500;
    white-space: nowrap;
  }
  .error {
    color: variables.$red;
    white-space: break-spaces;
  }
}
