@use "sass:string";
$icomoon-font-family: "cyberrisk-icons" !default;
$icomoon-font-path: "fonts" !default;

$cr-icon-list-protected: string.unquote('"\\e9b5"');
$cr-icon-shield-protected-circle: string.unquote('"\\e9b4"');
$cr-icon-hourglass: string.unquote('"\\e9b3"');
$cr-icon-link-2: string.unquote('"\\e9b1"');
$cr-icon-account-circle: string.unquote('"\\e9b0"');
$cr-icon-envelope: string.unquote('"\\e9af"');
$cr-icon-cal: string.unquote('"\\e9ae"');
$cr-icon-resize: string.unquote('"\\e9ad"');
$cr-icon-users-one: string.unquote('"\\e9ac"');
$cr-icon-graph: string.unquote('"\\e9ab"');
$cr-icon-add-bookmark: string.unquote('"\\e9aa"');
$cr-icon-bookmark: string.unquote('"\\e9a9"');
$cr-icon-carousel-dot: string.unquote('"\\e913"');
$cr-icon-partialaccepted: string.unquote('"\\e9a8"');
$cr-icon-expander: string.unquote('"\\e9a7"');
$cr-icon-threat-monitoring: string.unquote('"\\e9a6"');
$cr-icon-incidents-and-news-outline: string.unquote('"\\e992"');
$cr-icon-message-question-checkmark: string.unquote('"\\e993"');
$cr-icon-settings: string.unquote('"\\e994"');
$cr-icon-notification: string.unquote('"\\e995"');
$cr-icon-single-user-add-plus: string.unquote('"\\e996"');
$cr-icon-home-fill: string.unquote('"\\e997"');
$cr-icon-breachsight-fill: string.unquote('"\\e998"');
$cr-icon-vendor-risk-outline: string.unquote('"\\e999"');
$cr-icon-user-risk-outline: string.unquote('"\\e99a"');
$cr-icon-reports-fill: string.unquote('"\\e99b"');
$cr-icon-incidents-and-news-fill: string.unquote('"\\e99c"');
$cr-icon-trust-center-outline: string.unquote('"\\e99d"');
$cr-icon-reports-outline: string.unquote('"\\e99e"');
$cr-icon-breachsight-outline: string.unquote('"\\e99f"');
$cr-icon-user-risk-fill: string.unquote('"\\e9a0"');
$cr-icon-vendor-risk-fill: string.unquote('"\\e9a1"');
$cr-icon-analyst-portal-outline: string.unquote('"\\e9a2"');
$cr-icon-trust-center-fill: string.unquote('"\\e9a3"');
$cr-icon-analyst-portal-fill: string.unquote('"\\e9a4"');
$cr-icon-home-outline: string.unquote('"\\e9a5"');
$cr-icon-code2cloud: string.unquote('"\\e991"');
$cr-icon-users-empty: string.unquote('"\\e990"');
$cr-icon-add-risk: string.unquote('"\\e98f"');
$cr-icon-managed-assessment: string.unquote('"\\e900"');
$cr-icon-logo-outline: string.unquote('"\\e98d"');
$cr-icon-full-assessment: string.unquote('"\\e98a"');
$cr-icon-partial-assessment: string.unquote('"\\e98b"');
$cr-icon-rename: string.unquote('"\\e98c"');
$cr-icon-file-search: string.unquote('"\\e988"');
$cr-icon-upload-cloud: string.unquote('"\\e989"');
$cr-icon-cancel: string.unquote('"\\e987"');
$cr-icon-search: string.unquote('"\\e985"');
$cr-icon-radar: string.unquote('"\\e984"');
$cr-icon-radar1: string.unquote('"\\e986"');
$cr-icon-shortlist-active: string.unquote('"\\e982"');
$cr-icon-shortlist: string.unquote('"\\e983"');
$cr-icon-list-bullet: string.unquote('"\\e97a"');
$cr-icon-link-broken: string.unquote('"\\e97b"');
$cr-icon-link: string.unquote('"\\e97c"');
$cr-icon-list-number: string.unquote('"\\e97d"');
$cr-icon-undo1: string.unquote('"\\e97e"');
$cr-icon-italic: string.unquote('"\\e97f"');
$cr-icon-bold: string.unquote('"\\e980"');
$cr-icon-equals: string.unquote('"\\e901"');
$cr-icon-notequal: string.unquote('"\\e902"');
$cr-icon-prefix: string.unquote('"\\e903"');
$cr-icon-suffix: string.unquote('"\\e904"');
$cr-icon-contains: string.unquote('"\\e905"');
$cr-icon-export-thin: string.unquote('"\\e906"');
$cr-icon-coffee: string.unquote('"\\e907"');
$cr-icon-autofill: string.unquote('"\\e970"');
$cr-icon-question-alert: string.unquote('"\\e971"');
$cr-icon-tool: string.unquote('"\\e972"');
$cr-icon-bulb: string.unquote('"\\e96f"');
$cr-icon-star-filled: string.unquote('"\\e96d"');
$cr-icon-star-outline: string.unquote('"\\e96e"');
$cr-icon-grid: string.unquote('"\\e96b"');
$cr-icon-list: string.unquote('"\\e96c"');
$cr-icon-stars: string.unquote('"\\e96a"');
$cr-icon-convert: string.unquote('"\\e969"');
$cr-icon-autofill-processing: string.unquote('"\\e968"');
$cr-icon-undo: string.unquote('"\\e967"');
$cr-icon-magic-wand: string.unquote('"\\e966"');
$cr-icon-external-link: string.unquote('"\\e908"');
$cr-icon-compare: string.unquote('"\\e964"');
$cr-icon-shared-assessment-no-pad: string.unquote('"\\e963"');
$cr-icon-share: string.unquote('"\\e962"');
$cr-icon-timer: string.unquote('"\\e961"');
$cr-icon-mail: string.unquote('"\\e95e"');
$cr-icon-documents: string.unquote('"\\e95d"');
$cr-icon-wireframe-globe: string.unquote('"\\e95f"');
$cr-icon-tools: string.unquote('"\\e960"');
$cr-icon-expand-collapse: string.unquote('"\\e95c"');
$cr-icon-book-checkmark: string.unquote('"\\e958"');
$cr-icon-book-question: string.unquote('"\\e959"');
$cr-icon-book-sparkle: string.unquote('"\\e95a"');
$cr-icon-book-x: string.unquote('"\\e95b"');
$cr-icon-folder: string.unquote('"\\e957"');
$cr-icon-chat-messages: string.unquote('"\\e956"');
$cr-icon-q-builder-document: string.unquote('"\\e955"');
$cr-icon-dots-menu: string.unquote('"\\e954"');
$cr-icon-lightning: string.unquote('"\\e953"');
$cr-icon-help: string.unquote('"\\e952"');
$cr-icon-play: string.unquote('"\\e951"');
$cr-icon-archive: string.unquote('"\\e950"');
$cr-icon-trash-2: string.unquote('"\\e94f"');
$cr-icon-questionnaire: string.unquote('"\\e94e"');
$cr-icon-broken-link: string.unquote('"\\e94c"');
$cr-icon-plus: string.unquote('"\\e94d"');
$cr-icon-reply: string.unquote('"\\e94b"');
$cr-icon-minus-circle: string.unquote('"\\e94a"');
$cr-icon-home: string.unquote('"\\e948"');
$cr-icon-cog2: string.unquote('"\\e949"');
$cr-icon-cyberrisk: string.unquote('"\\e947"');
$cr-icon-save: string.unquote('"\\e946"');
$cr-icon-copy: string.unquote('"\\e945"');
$cr-icon-sort: string.unquote('"\\e944"');
$cr-icon-drag-handle: string.unquote('"\\e943"');
$cr-icon-q-builder-attachment: string.unquote('"\\e93e"');
$cr-icon-q-builder-checkbox: string.unquote('"\\e93f"');
$cr-icon-q-builder-document-attachment: string.unquote('"\\e940"');
$cr-icon-q-builder-radio: string.unquote('"\\e941"');
$cr-icon-q-builder-flag: string.unquote('"\\e942"');
$cr-icon-subsidiaries: string.unquote('"\\e93d"');
$cr-icon-audit-log: string.unquote('"\\e93c"');
$cr-icon-noeye: string.unquote('"\\e938"');
$cr-icon-edit-doc: string.unquote('"\\e939"');
$cr-icon-eye: string.unquote('"\\e93a"');
$cr-icon-key: string.unquote('"\\e93b"');
$cr-icon-chevron: string.unquote('"\\e936"');
$cr-icon-magnifying-glass: string.unquote('"\\e937"');
$cr-icon-info: string.unquote('"\\e935"');
$cr-icon-pencil: string.unquote('"\\e934"');
$cr-icon-pencil-2: string.unquote('"\\e981"');
$cr-icon-upload: string.unquote('"\\e933"');
$cr-icon-archive-old: string.unquote('"\\e932"');
$cr-icon-document: string.unquote('"\\e931"');
$cr-icon-redo: string.unquote('"\\e92f"');
$cr-icon-message: string.unquote('"\\e930"');
$cr-icon-trash: string.unquote('"\\e92e"');
$cr-icon-export: string.unquote('"\\e92d"');
$cr-icon-risk-assessment: string.unquote('"\\e92c"');
$cr-icon-risk-waivers: string.unquote('"\\e92b"');
$cr-icon-openexternal: string.unquote('"\\e92a"');
$cr-icon-paperclip: string.unquote('"\\e929"');
$cr-icon-chat1: string.unquote('"\\e926"');
$cr-icon-arrow-right: string.unquote('"\\e927"');
$cr-icon-padlock: string.unquote('"\\e928"');
$cr-icon-download: string.unquote('"\\e925"');
$cr-icon-reporting: string.unquote('"\\e924"');
$cr-icon-vendor-security-profile: string.unquote('"\\e923"');
$cr-icon-vendor-summary: string.unquote('"\\e923"');
$cr-icon-upgrade: string.unquote('"\\e922"');
$cr-icon-shared-assessment: string.unquote('"\\e921"');
$cr-icon-vendor-risk: string.unquote('"\\e920"');
$cr-icon-concentration-risk: string.unquote('"\\e909"');
$cr-icon-analytics: string.unquote('"\\e90a"');
$cr-icon-chat: string.unquote('"\\e90b"');
$cr-icon-checklist: string.unquote('"\\e90c"');
$cr-icon-code: string.unquote('"\\e90d"');
$cr-icon-cog: string.unquote('"\\e90e"');
$cr-icon-focus: string.unquote('"\\e90f"');
$cr-icon-hyperlink: string.unquote('"\\e910"');
$cr-icon-paragraph: string.unquote('"\\e911"');
$cr-icon-people: string.unquote('"\\e912"');
$cr-icon-warning: string.unquote('"\\e913"');
$cr-icon-breachsight: string.unquote('"\\e914"');
$cr-icon-email: string.unquote('"\\e915"');
$cr-icon-filter: string.unquote('"\\e916"');
$cr-icon-globe: string.unquote('"\\e917"');
$cr-icon-spanner-outline: string.unquote('"\\e918"');
$cr-icon-spanner: string.unquote('"\\e919"');
$cr-icon-accepted: string.unquote('"\\e91a"');
$cr-icon-owned: string.unquote('"\\e91b"');
$cr-icon-risk: string.unquote('"\\e91c"');
$cr-icon-typosquatting-1: string.unquote('"\\e91d"');
$cr-icon-unclassified: string.unquote('"\\e91e"');
$cr-icon-check: string.unquote('"\\e91f"');
$cr-icon-exclamation: string.unquote('"\\e965"');
$cr-icon-lock: string.unquote('"\\e973"');
$cr-icon-question: string.unquote('"\\e974"');
$cr-icon-typosquatting: string.unquote('"\\e975"');
$cr-icon-clock: string.unquote('"\\e976"');
$cr-icon-bug: string.unquote('"\\e977"');
$cr-icon-table: string.unquote('"\\e978"');
$cr-icon-tree: string.unquote('"\\e979"');
$cr-icon-upguard-logo-pick: string.unquote('"\\e98e"');
