@import "../../../_common/style/core/variables";

.selectable-document {
  width: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid $grey100;

  & > div,
  .risk-count {
    width: 100%;
  }
  .filename {
    padding: 12px 24px;
  }
  .expandable-item-header {
    padding: 8px 24px;
  }
  .filename,
  .expandable-item-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .filename {
    border-bottom: 1px solid $grey100;
    gap: 12px;
    .color-checkbox {
      width: 32px;
    }
    .severity-icon img {
      height: 20px;
    }
    .filename-link {
      display: flex;
      align-items: center;
      gap: 2px;
      flex-grow: 1;
    }
  }

  .expandable-item-header-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4px;
    .expandable-item-header-icon {
      color: $blue500;
    }
  }
  .risk-count {
    font-size: 12px;
    color: $grey600;
  }
  .expandable-item-content {
    border-top: 1px solid $grey100;
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .document-risk {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;

    .risk-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      .risk-title,
      .risk-description {
        line-height: 150%;
        font-size: 12px;
      }
      .risk-description {
        color: $grey600;
      }
    }
  }

  cursor: pointer;

  &.selected,
  &:hover,
  &:focus {
    border: 1px solid $blue500;
    background: $blue50;
    &.selected:hover,
    &.selected:focus {
      background: $blue100;
      .expandable-item-content {
        background: $blue200;
      }
    }
    .filename {
      border-bottom: 1px solid $blue200;
    }
    .expandable-item-content {
      border-radius: 0 0 8px 8px;
      background: $blue100;
    }
  }
}
