@use "../core/variables";
@use "../core/cr_icons_constants";

#main-content-area .ug-page-header-outer {
  margin-top: -30px;
}

.ug-page-header {
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .vendor-section {
    width: 100%;
    background-color: variables.$white;
    border-bottom: 1px solid variables.$grey100;
    display: flex;
    flex-direction: column;
    align-items: center;

    .vendor-section-inner {
      width: 100%;
      max-width: variables.$max-page-width + 80px;
      padding: 16px 40px;
      display: flex;
      flex-direction: column;
    }
  }

  .vendor-name-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    .vendor-name {
      font-weight: variables.$font-weight-bold;
      font-size: 16px;
      line-height: 32px; // Same as button height, so it's consistent with right-aligned buttons
      display: flex;
      flex-direction: row;
      gap: variables.$space-xs;

      .primary-domain {
        color: variables.$grey300;

        a {
          color: variables.$blue;
          text-transform: none;
        }
      }
    }

    .grade-with-score {
      margin-left: variables.$space-sm;
    }

    .verified-badge {
      margin-left: 8px;
    }

    .vendor-actions {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      .button-group:not(:last-child) {
        margin-right: 12px;
      }

      .btn-tertiary {
        .btn-inner,
        .cr-icon-chevron {
          color: variables.$grey300;
        }

        &:hover .btn-inner,
        &:hover .cr-icon-chevron {
          color: variables.$grey500;
        }

        & + * {
          margin-left: 10px;
        }

        .cr-icon-chevron {
          margin-left: 8px;
        }
      }

      .btn:not(.btn-tertiary) {
        border-color: variables.$grey200;

        &:hover {
          background: none;

          .btn-inner,
          .btn-inner div {
            color: variables.$font-color;
          }
        }

        .btn-inner,
        .btn-inner div {
          color: variables.$grey500;
        }
      }

      .dot-menu {
        &.open {
          .popup-target {
            button {
              background-color: variables.$blue200;
              color: variables.$blue500;
            }
          }
        }

        .cr-icon-dots-menu {
          margin-right: 0;
          font-size: 20px;
        }

        .popup-content {
          width: 200px;
        }

        .menu-vendor-section {
          display: flex;
          flex-direction: column;

          .menu-vendor-item {
            padding: 15px;
            display: flex;
            align-items: center;
            color: variables.$grey900;
            cursor: pointer;

            &:not(.disabled):hover {
              background: #e8eef9;
              color: #1757c2;

              .menu-icon {
                color: #1757c2;
              }
            }

            .cr-icon-trash {
              font-size: 20px;
            }

            &.disabled {
              opacity: 0.5;
              cursor: default;
            }

            &:not(:last-of-type) {
              border-bottom: variables.$grey100 solid 1px;
            }

            .menu-icon {
              font-size: 16px;
              padding-right: 12px;

              &.cr-icon-trash {
                font-size: 22px;
                margin-right: -6px;
              }
            }
          }
        }
      }
    }
  }

  .portfolio-metadata-row,
  .vendor-metadata-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
  }

  .portfolio-metadata-row > span {
    color: variables.$grey400 !important;
    padding-right: 8px;
  }

  .vendor-metadata-row {
    .portfolio-badge {
      .header-label {
        white-space: nowrap;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .vendor-tier-badge-container {
      margin: -4px;

      .vendor-tier-badge-display {
        padding: 0 4px;

        .name {
          font-weight: variables.$font-weight-bold;
          font-size: variables.$font-size;
          line-height: 150%;
          color: variables.$grey600;
          white-space: nowrap;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:not(:hover) .name {
          color: variables.$grey600;
        }
      }
    }

    & > * + * {
      margin-left: 16px !important;
    }
  }

  .label-list {
    margin-top: 0;
    padding-left: 16px;
    border-left: variables.$grey200 solid 1px;

    .label-rest-container {
      margin-top: 0;
    }

    .pill-label {
      margin-top: 0;
    }

    .add-label {
      color: variables.$grey400;
      background-color: #fff;
      border: variables.$grey200 dashed 1.5px;
      font-style: normal;
      position: relative;

      &:after {
        content: "+";
        font-size: 16px;
        color: variables.$grey400;
        line-height: 100%;
        position: absolute;
        left: 55%;
        top: calc(50% - 1px);
        transform: translate(-50%, -50%);
      }

      &:hover {
        background-color: variables.$blue50;
      }
    }
  }

  .info-bar-section {
    width: 100%;
    background-color: variables.$blue500;

    .info-bar-section-inner {
      width: 100%;
      margin: auto;
      max-width: variables.$max-page-width;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .info-bar,
      * {
        font-size: 14px;
        line-height: 20px;
        font-weight: variables.$font-weight-bold;
        color: variables.$white;
        text-align: center;
      }
    }

    &.info {
      background-color: variables.$blue500;
    }

    &.warning {
      background-color: variables.$orange-mid500;
    }

    &.error {
      background-color: variables.$red500;
    }

    &.success {
      background-color: variables.$green500;
    }
  }
  .separator {
    align-self: stretch;
    border-bottom: 1px solid variables.$grey100;
    margin: 0 40px;
  }

  .page-header-section {
    width: 100%;
    max-width: variables.$max-page-width + 80px;
    padding: 24px 40px;
    display: flex;
    flex-direction: column;

    .breadcrumbs {
      margin-bottom: 10px;
    }

    .page-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      h2 {
        padding: 0 !important;
        font-weight: variables.$font-weight-bold;
        font-size: 24px;
        line-height: 32px; // Same as button height, so it's consistent with right-aligned buttons
      }

      .alternate-page-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: variables.$space-sm;

        .info-button {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 24px;
          height: 24px;
          padding: 3px;

          .info-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: variables.$grey400;
          }
        }
      }

      .page-title-right {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;

        & > * + * {
          margin-left: 16px;
        }
      }

      .btn-separator {
        height: 32px;
        width: 0;
        border-left: 1px solid variables.$grey200;
        margin-right: -1px;
      }

      * + .btn-separator {
        margin-left: 16px;
      }

      .btn-separator + * {
        margin-left: 16px;
      }

      .info-button {
        padding-left: 8px;
        padding-right: 8px;

        .cr-icon-info {
          margin-right: 0;
          font-size: 18px;
        }
      }
    }

    .info-section {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding-bottom: 16px;
      border-bottom: 1px solid variables.$grey100;

      .info-desc {
        &,
        p {
          color: variables.$grey500;
          line-height: 150%;
        }
      }

      .btns-and-control {
        display: flex;
        flex-direction: row;
        align-items: center;

        > * + * {
          margin-left: 16px;
        }

        .collapse-control {
          margin-left: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          &,
          .cr-icon-chevron {
            color: variables.$grey300;
          }

          &:hover {
            &,
            .cr-icon-chevron {
              color: variables.$grey500;
            }
          }

          .cr-icon-chevron {
            margin-left: 16px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .back-btn {
    width: 24px;
    height: 24px;
    position: relative;
    border: 1px solid variables.$grey200;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      border-color: variables.$grey500;
    }

    &:after {
      content: cr_icons_constants.$cr-icon-arrow-right;
      font-family: cr_icons_constants.$icomoon-font-family;
      color: variables.$grey500;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotateY(180deg);
      height: 15px; // Centre
    }
  }

  .header-plus-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: -4px;
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: variables.$blue50;
      mix-blend-mode: multiply;

      .label {
        color: variables.$blue;
      }

      .plus {
        background-color: variables.$blue50;
      }
    }

    .plus {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: #fff;
      border: variables.$grey200 dashed 1.5px;
      position: relative;
      margin-right: 10px;

      &:after {
        content: "+";
        font-size: 16px;
        color: variables.$blue500;
        line-height: 100%;
        position: absolute;
        left: 50%;
        top: calc(50% - 1px);
        transform: translate(-50%, -50%);
      }
    }

    .label {
      font-weight: variables.$font-weight-bold;
      font-size: variables.$font-size;
      line-height: 150%;
      color: variables.$grey600;
    }
  }

  .add-label-button {
    padding-left: 16px;
    border-left: variables.$grey200 solid 1.5px;
  }

  .header-icon-item {
    & > div,
    .dismissable-popup-click-capture > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: -4px;
      padding: 4px;
      border-radius: 4px;

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: variables.$grey50;
        }
      }
    }

    .header-icon {
      width: 24px;
      height: 24px;
      background-color: variables.$blue100;
      border-radius: 4px;
      position: relative;
      margin-right: 10px;

      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: variables.$blue500;
        font-size: 16px;
      }
    }

    .cr-icon-shared-assessment:before {
      font-size: 20px;
      top: calc(50%);
      left: calc(50% + 1px);
    }

    .header-label {
      color: variables.$grey600;
      font-size: variables.$font-size;
      line-height: 150%;
      font-weight: variables.$font-weight-bold;
      white-space: nowrap;
    }
  }

  .portfolio-filter-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    &.active,
    &:hover {
      color: variables.$blue500;

      .cr-icon-chevron {
        color: variables.$blue500 !important;
      }
    }

    .portfolio-filter-btn-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: variables.$blue100;
      padding: 5px 10px;
      margin: -5px 0;
      border-radius: variables.$component-border-radius;
      color: inherit;
    }

    .cr-icon-chevron {
      color: variables.$grey500 !important;
      margin-left: 12px;
      font-size: 12px;
    }
  }
}
