@import "../../_common/style/core/variables";

.gpt-autofill-configure-source-modal {
  width: 1000px;
  padding: 0 32px !important;
}

.gpt-autofill-modal-excel-configure-step {
  margin-top: 12px;

  .selector-tabs {
    .title {
      color: black;
      font-weight: $font-weight-bold;
      margin-bottom: 4px;
      line-height: 22px;
    }

    .sheet-tab-label {
      color: inherit;
      display: flex;
      flex-direction: row;
      align-items: center;

      .cr-icon-accepted {
        margin-left: 8px;
        font-size: 16px;
      }
    }
  }

  .sheet-config {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 24px;

    .known {
      margin-bottom: 16px;
    }

    .sheet-name {
      margin-top: 16px;
      font-size: $font-size-larger;
      font-weight: $font-weight-bold;
    }

    .selectors {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 280px;
      margin-right: 24px;

      .selector-inputs {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        &.disabled {
          opacity: 0.4;
        }

        &.single-question-answer {
          > div + div {
            margin-top: 16px;
          }
        }

        .label {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: $font-weight-bold;
          line-height: 150%;

          .cr-icon-info {
            margin-left: 8px;
            line-height: 22px;
          }
        }

        .column-selector {
          .label {
            margin-bottom: 8px;
          }

          &.question-column-selector {
            mark {
              background-color: rgba($purple500, 0.2);
            }
          }
          &.answer-column-selector {
            mark {
              background-color: rgba($orange500, 0.2);
            }
          }
          &.heading-column-selector {
            mark {
              background-color: rgba($green500, 0.2);
            }
          }

          .label-list {
            margin-top: 2px;
            margin-bottom: 8px;
          }
        }

        .offset-selector {
          & + div {
            margin-top: 10px !important;
          }
        }
      }
    }

    .preview-area {
      flex: 1;
      max-height: 500px;
      overflow: auto;
      border-bottom: 1px solid $grey200;

      .x-table {
        .loading-spinner {
          display: none; // Fixes an issue with scrollbars, and we never show the loading spinner on this table anyway
        }

        table {
          border-spacing: unset;

          tr {
            height: unset;
          }

          tbody tr:last-child td {
            border-bottom: none;
          }

          .preview-cell {
            border: 1px solid $grey200;
            padding: 8px 4px;
            font-size: 12px;
            line-height: 125%;
          }

          .header {
            background-color: $grey50;
            color: $grey600;
            text-align: center;
            padding: 2px;
            border-top: 1px solid $grey200;
            border-bottom: 1px solid $grey200;

            // Hax to get borders looking right on sticky table headers
            &:after {
              content: "";
              position: absolute;
              background: transparent;
              top: -1px;
              height: 100%;
              left: 0;
              right: 0;
              border-top: 1px solid $grey200;
              border-bottom: 1px solid $grey200;
            }
          }

          .row-header {
            background-color: $grey50;
            max-width: 40px;
            text-align: center;
          }

          .answer-selected {
            background-color: rgba($orange500, 0.2);
          }

          .question-selected {
            background-color: rgba($purple500, 0.2);
          }

          .heading-selected {
            background-color: rgba($green500, 0.2);
          }

          .is-question-number {
            background-color: #eefaff;
          }
        }
      }
    }
  }
}
