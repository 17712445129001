@import "../core/variables";
@import "~flatpickr/dist/themes/light.css";

.date-picker {
  position: relative;

  &.inline .form-control {
    display: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  & > input {
    width: 100%;
  }

  .calendar-icn {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    width: 24px;
  }

  .clear-btn {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: $grey300;

    &:hover {
      color: $blue;
    }
  }

  &.static-editable {
    position: relative;

    &:hover {
      input {
        &:not(:focus) {
          background-color: $blue50;
          border-radius: $component-border-radius;
        }
      }
    }

    input {
      height: unset;
      background: none;
      border-color: transparent;
      line-height: 150%;
      padding: 6px 20px 6px 10px;
      margin: -6px -20px -6px -10px;

      &:focus {
        border-color: $grey200;
        background-color: white;
        text-decoration: none;

        & ~ .static-editable-edit {
          display: none;
        }

        & ~ .clear-btn {
          display: block;
        }
      }
    }

    .static-editable-edit {
      position: absolute;
      top: 1px;
      right: 14px;
      color: $grey300;
      font-size: 20px;
      line-height: inherit;
      pointer-events: none;
    }

    .clear-btn {
      display: none;
      right: 16px;
      z-index: 1;
    }
  }
}

.flatpickr-calendar {
  &.open {
    z-index: 1999999;
  }
}
