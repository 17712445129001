@use "../../../../_common/style/core/variables";

.vendor-assessment-autofill-modal-header {
  p {
    padding-top: 12px;
    padding-bottom: 24px;
  }
}

.vendor-assessment-autofill-modal {
  border-top: 1px solid variables.$grey100;
  width: 750px;

  .heading {
    font-size: variables.$font-size-larger;
    font-weight: variables.$font-weight-bold;
    line-height: 24px;
    text-align: left;
  }

  .autofill-scope {
    border-bottom: 1px solid variables.$grey100;
    padding-bottom: 24px;

    .label {
      font-size: variables.$font-size;
      font-weight: variables.$font-weight-regular;
      line-height: 22px;
    }

    .color-checkbox {
      margin-top: 12px;
    }
  }

  .selectable-evidence {
    padding-top: 24px;

    .table {
      min-height: 50px;
      max-height: 325px;
      overflow-y: scroll;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 12px;

      .name-cell {
        .name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          max-width: 390px;
          .icon {
            width: 28px;
          }
          .label {
            padding-left: 6px !important;
            min-width: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .selection-box {
        padding-left: 0px !important;
      }
    }
  }
}
