@use "../core/variables";

.full-page-modal.v2 .modal-content-container {
  .score-badge-modal {
    width: 700px;
    padding-top: 0;

    .description {
      margin-bottom: 32px;

      p {
        margin-bottom: 5px;
        color: variables.$grey500;

        a {
          color: variables.$grey500;
          text-decoration: underline;
        }
      }
    }

    .score-badge-content {
      display: flex;
      flex-direction: column;

      .score-badge-themes {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;

        .label {
          font-weight: variables.$font-weight-bold;
          color: variables.$grey900;
          margin-right: 12px;
        }

        .theme {
          height: 20px;
          width: 20px;
          border-radius: 10px;
          margin-right: 12px;

          &:hover {
            outline: variables.$blue700 solid 1px;
            outline-offset: 2px;
          }
        }

        .light {
          background-color: variables.$grey50;
          border: 1px solid variables.$grey200;
        }

        .dark {
          background-color: variables.$grey800;
          border: 1px solid variables.$grey900;
        }

        .active {
          outline: variables.$blue500 solid 1px;
          outline-offset: 2px;
        }
      }

      .score-badge-preview {
        width: 100%;
        background-color: variables.$grey100;
        border-radius: 4px;
        height: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
      }

      .score-badge-code {
        width: 100%;
        background-color: variables.$grey50;
        border-radius: 4px;
        padding: 16px 12px;

        pre {
          margin: 0;
          color: variables.$grey500;
          white-space: pre-wrap;
        }
      }
    }
  }
}

.full-page-modal.v2 .modal-content-container .score-badge-modal-footer {
  .cr-icon-copy {
    font-size: 18px;
  }
}
