@import "../../_common/style/core/variables";

.answer-option-container {
  margin-bottom: 8px;

  .color-checkbox-inner {
    align-items: flex-start;
    flex-grow: 1;
    .box {
      margin-top: 10px;
      margin-right: 12px;
    }
    .label {
      white-space: normal;
    }

    &.disabled {
      opacity: unset;
      pointer-events: unset;
    }
  }

  &:hover {
    .answer-option:not(.disabled) {
      background-color: $blue100;
      border: 1px solid $blue500;

      .id-container {
        border: 1px solid $blue500;
        .id {
          color: $blue500;
        }
      }

      .text {
        color: $blue500;
      }
    }
  }

  .answer-option {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    border: 1px solid $grey200;
    border-radius: 4px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    background-color: white;

    &.selected {
      background-color: $blue100;
      border: 1px solid $blue500;

      .id-container {
        border: 1px solid $blue500;
        .id {
          color: $blue500;
        }
      }

      .text {
        color: $blue500;
      }

      &.green {
        background-color: $green50;
        border: 1px solid $green500;

        .text {
          color: $green500;
        }

        .id-container {
          border: 1px solid $green500;
          .id {
            color: $green500;
          }
        }
      }

      &.red {
        background-color: $red50;
        border: 1px solid $red500;

        .text {
          color: $red500;
        }

        .id-container {
          border: 1px solid $red500;
          .id {
            color: $red500;
          }
        }
      }
    }

    &.orange {
      background-color: $orange20;
    }

    &.disabled {
      cursor: not-allowed;
    }

    .id-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $grey200;
      border-radius: 4px;
      background-color: white;
      min-width: 24px;
      min-height: 24px;
      margin-right: 12px;
      transition: all 250ms ease-in-out;

      .id {
        color: $grey300;
        font-size: 12px;
        line-height: 20px;
        transition: all 250ms ease-in-out;
      }
    }

    .text {
      color: $grey600;
      font-size: 14px;
      line-height: 21px;
      transition: all 250ms ease-in-out;
    }
  }
}
