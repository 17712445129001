@import "../../../../_common/style/core/variables";

.merge-tags-modal {
  width: 800px;
  max-height: 65vh;

  overflow-y: scroll;
  overflow-x: hidden;

  .tag-cell {
    font-family: monospace;
    width: 1px !important;
    overflow-wrap: break-word;
    max-width: 378px;
  }

  .value-cell {
    max-width: 358px;
    overflow-wrap: break-word;
  }

  .copy-popup-container {
    margin-left: 8px;
    vertical-align: -5px;
  }

  .cr-icon-copy {
    cursor: pointer;
    font-size: 20px;
    color: $grey300;

    &:hover {
      color: $grey500;
    }
  }
}
