@import "../../../../_common/style/core/variables";

.autofill-modal-v2 {
  .tip {
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: $grey50;
    gap: 8px;
    margin-bottom: 16px;

    color: $grey700;
    font-size: $font-size;
    font-weight: $font-weight-bold;
    line-height: 22px;

    .cr-icon-bulb {
      font-size: 22px;
    }

    .right {
      margin-left: auto;
    }
  }

  .autofill-dropzone {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid $grey100;
    flex-grow: 1;
    cursor: pointer;

    &.slim {
      height: 72px;
    }

    &.loading {
      cursor: unset;
    }

    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;

      .top-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $grey600;

        i {
          font-size: 24px;
          color: $blue500;
          margin-right: 8px;
        }

        .blue {
          color: $blue500;
          margin-right: 4px;
        }
      }

      .bottom-line {
        color: $grey500;
        font-size: $font-size-smaller;
      }
    }
  }

  .documents-section {
    margin-top: 16px;

    .search-and-filter {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding-bottom: 8px;

      .search-box-input-container {
        width: 100%;
      }
    }

    .selection-info-box {
      //margin: 8px 0;
      padding: 8px 16px;
      background: $grey50;

      display: flex;
      flex-direction: row;

      .left {
        margin-right: auto;
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        .text {
          color: $grey600;
          font-size: $font-size;
          font-weight: $font-weight-regular;
          line-height: 22px;
        }
      }

      .divider {
        color: $grey200;
      }

      input + label {
        margin-top: 6px;
      }

      .toggle-label {
        .toggle-label-inner {
          display: flex;
          flex-direction: row;
          align-content: center;

          .dismissible-popup-container {
            height: 14px;
            margin-top: 2px;
          }

          i {
            margin-left: 4px;
            color: $grey300;
          }
        }
      }
    }

    .document-filter-panel {
      margin-top: 8px;
      right: 32px;
    }

    .docs-table {
      table thead th {
        padding-top: 8px;
      }

      .name-cell {
        max-width: 300px;
        .name-cell-inner {
          display: flex;
          flex-direction: row;
          align-items: center;

          .name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .survey-icon {
            font-size: 24px;
            color: $grey500;
            margin-right: 15px;
          }

          .file-type {
            width: 24px;
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.autofill-modal-v2-footer {
  .footer-left {
    margin-right: auto;
    flex: unset !important;
  }

  .toggle-with-label {
    input + label {
      margin: unset;
    }

    .toggle-label {
      .toggle-label-inner {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .dismissible-popup-container {
          height: 14px;
          margin-top: 2px;
        }

        i {
          margin-left: 4px;
          color: $grey300;
        }
      }
    }
  }
}

.autofill-modal-v2-header {
  .title {
    margin-bottom: unset !important;
  }
}
