@use "../core/variables";

.file-dropzone {
  position: relative;

  .dropzone-overlay {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(variables.$blue500, 0.2);
    border: 1px solid variables.$blue500;
    border-radius: variables.$card-border-radius;

    .drop-files-msg {
      position: absolute;
      left: 50%;
      bottom: 5%;
      transform: translate(-50%, -50%);
      background-color: variables.$blue500;
      color: variables.$white;
      padding: 15px;
      border-radius: 30px;
      font-size: 18px;
      line-height: 150%;
      min-width: 300px;
      width: 30%;
      text-align: center;
    }

    .loading-tight {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.visible {
    .dropzone-overlay {
      display: block;
    }
  }

  &.uploading {
    pointer-events: none;
  }
}
