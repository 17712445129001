@use "../../_common/style/core/variables" as core-variables;
@use "../styles/variables";

.threat-monitoring-view {
  .page-title .cr-icon-settings {
    font-size: 22px;
  }
  .report-card {
    background-color: core-variables.$white !important;
    box-shadow: none !important;

    display: flex;
    flex-direction: column;
    border: 1px solid core-variables.$grey100;
    border-radius: variables.$threat-monitoring-view-report-card-border-radius;

    .feed-container {
      flex-grow: 1;

      .feed-content {
        display: flex;
        flex-direction: row;
      }

      .content-separator {
        border-bottom: 1px solid core-variables.$grey100;
        margin: 16px 0;
      }
    }
  }
  .debug-menu .content {
    .set-dl-date {
      display: flex;
      flex-direction: column;

      div {
        padding: 6px;
      }
    }
  }
}
