@import "../../../../_common/style/core/variables";

.reports-library {
  .search-filter {
    padding: 20px 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * + * {
      margin-left: 16px;
    }

    .search-box-input-container {
      flex: 1;
    }
  }
}
