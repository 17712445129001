@import "../../../../_common/style/core/variables";

.reports-library-grid {
  display: grid;
  padding: 0 32px 42px 32px;
  gap: 20px;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  align-items: stretch;

  // This fits as many columns as possible with a minimum width of 247px each,
  // stretching columns equally until there is space for another column.
  grid-template-columns: repeat(auto-fill, minmax(247px, 1fr));

  .report-grid-item {
    display: flex;
    flex-direction: column;

    &:hover {
      .item-top .item-overlay {
        opacity: 1;
      }

      .item-top .star .cr-icon-star-outline {
        color: $grey300;
      }

      .item-bottom {
        //border-color: $grey100;
      }
    }

    .item-top {
      position: relative;
      overflow: hidden;
      height: 247px;
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;

      .star {
        position: absolute;
        left: 10px;
        top: 10px;

        .cr-icon-star-outline {
          transition: color 100ms ease-in-out;
          color: $white;
        }

        .cr-icon-star-filled {
          color: $blue500;
        }
      }

      .actions-dropdown {
        position: absolute;
        right: 10px;
        top: 10px;

        .btn-icon {
          background-color: rgba(255, 255, 255, 0.4);

          .cr-icon-dots-menu {
            color: $grey400;
          }

          &:hover:not(.disabled) {
            background-color: rgba(255, 255, 255, 0.7);
            mix-blend-mode: normal;

            .cr-icon-dots-menu {
              color: $grey600;
            }
          }
        }
      }

      .item-overlay {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: $card-border-radius;
        background-color: rgba($blue50, 0.9);
        padding: 60px 16px 16px 16px;
        flex-direction: column;
        opacity: 0;

        transition: opacity 100ms ease-in-out;

        .item-desc {
          line-height: 125%;

          // Clamp the description text to 5 lines then ellipsis
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        .btn {
          margin-top: auto;

          & + .btn {
            margin-top: 10px;
          }
        }
      }

      .item-bg {
        position: absolute;
        width: 66.6%;
        top: 58px;
        left: 50%;
        transform: translateX(-50%);

        &.landscape {
          width: 80%;
          top: 80px;
        }
      }
    }

    .item-bottom {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 16px;
      border-bottom-left-radius: $card-border-radius;
      border-bottom-right-radius: $card-border-radius;
      border: 1px solid transparent;
      border-top: none;

      .report-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $grey800;
        font-size: $font-size-smaller;

        .module-container {
          width: 20px;

          .module-i {
            font-size: 13px;
          }
        }

        .report-type-type {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .report-name {
        margin-top: 8px;
        display: flex;
        flex-direction: row;

        .dot-container {
          width: 20px;
          position: relative;

          .cr-dot {
            position: absolute;
            top: 8px;
            left: 3px;
          }
        }

        .report-name-name {
          line-height: 150%;
          font-weight: $font-weight-bold;

          // Clamp the text
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
