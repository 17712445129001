@import "../core/variables";

.free-trial-upsell-nav-content-container {
  margin: auto 8px 0px 8px;
  background-color: $blue100;
  border: 1px solid $blue200;
  border-radius: 8px;
  padding: 16px 16px 16px;

  .container-header {
    color: $grey900;
    font-size: 14px;
    line-height: 22px;
    font-weight: $font-weight-bold;
    display: flex;
    flex-direction: row;

    .header-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .container-content {
    color: $grey900;
    font-weight: $font-weight-bold;
    font-size: 12px;
    line-height: 18px;

    .btn {
      background-color: $blue500;
      border-radius: 4px;
      width: 100%;

      .btn-inner {
        color: white;
        line-height: 16px;
      }
    }
  }
}
