@import "../../../_common/style/core/variables";

#additional_evidence_details_view {
  .ug-select__menu {
    z-index: 11;
  }

  h3 {
    font-weight: $font-weight-bold;
    padding: 0;
    margin-left: 0;
    font-size: 18px;
    line-height: 18px;
  }

  .red {
    background-color: $red50;
  }

  .highlight {
    background-color: $orange-mid50;
    border-left: solid $orange50 4px;
  }

  .historical {
    color: inherit;
  }

  .evidence-details-card {
    border-radius: $card-border-radius;
    border: 1px solid $light-grey;
    box-shadow: $box-shadow-4dp;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $grey500;
      font-size: 14px;

      .auto-save {
        display: flex;
        flex-direction: row;
        width: 100px;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        margin: 0px;
        height: 20px;

        .loader-icon {
          height: 12px;
          width: 12px;
          margin-right: 10px;
          margin-left: 5px;
        }
      }

      .title {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;

        .pill-label {
          margin-left: 10px;
        }
      }

      .include-toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .label {
          font-size: 14px;
          line-height: 150%;
          margin-left: 10px;
          color: $blue;
        }

        .greyed {
          color: $grey500;
        }

        margin-right: 15px;
      }

      .cancel {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 150%;
        margin-right: 20px;
        cursor: pointer;

        .icon-x {
          color: $grey500;
          margin-right: 5px;
        }
      }
    }

    .msg-body {
      width: 100%;
      padding: 30px;
      padding-bottom: 10px;

      .add-risks-archived {
        background-color: $grey50;
        border-color: $grey500;
        color: $grey500;
      }
    }

    .additional-evidence-details-card-footer {
      border-top: 1px solid #eaeaeb;

      .expandable-item {
        .expandable-item-header {
          padding-bottom: 16px;
          padding: 16px 32px 16px 32px;

          .expandable-item-header-link {
            display: flex;

            .additional-evidence-history-expandable-header {
              flex-grow: 1;
              color: $grey500;

              .additional-evidence-history-expandable-header-icon {
                color: $grey500;
              }
            }

            .expandable-item-header-icon {
              flex-grow: 0;
              font-size: 18px;
              color: $grey500;
            }
          }
        }
      }

      .additional-evidence-history-table-row {
        background-color: $grey50;
        border-bottom: 1px solid $grey100;

        .additional-evidence-history-table-row-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 100%;
          width: 100%;
          align-content: space-between;

          .additional-evidence-history-table-row-detail {
            display: flex;
            align-items: center;
            width: 50%;
          }

          .additional-evidence-history-table-row-document {
            display: flex;
            height: 48px;
            padding: 8px 24px;
            align-items: center;
            width: 50%;
            border-radius: 8px;
            border: 1px solid $grey100;
            background-color: #ffffff;

            &.disabled {
              background-color: $grey50;
            }

            .additional-evidence-history-table-row-document-icon {
              margin-left: auto;
            }
          }
        }
      }
    }

    .body {
      display: flex;
      flex-direction: row;
      min-height: 300px;

      .panel {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: flex-start;
        padding: 30px;

        .document-type-container {
          display: flex;
          flex-direction: column;

          .btn {
            margin-left: 16px;
          }

          .new-type-div {
            font-size: $font-size-smaller;
            color: $grey500;
          }
        }

        .section-title {
          flex: 0;
          height: fit-content;
          color: $primary-black;
          font-weight: $font-weight-bold;
        }

        .section-spacer {
          height: 10px;
        }

        .section-description {
          flex: 0;
          height: fit-content;
          margin-top: 20px;
          color: $grey500;
          line-height: 150%;
          font-weight: $font-weight-regular;
        }

        .section-subheading {
          flex: 0;
          height: fit-content;
          margin-top: 20px;
          color: $grey500;
          font-size: 11px;
          line-height: 150%;
        }

        table {
          margin-top: 14px;
          width: 100% !important;
          border-collapse: collapse;
        }

        td {
          padding: 5px;
        }

        .field {
          width: 166px;
          height: 40px;
          padding-left: 0;
          vertical-align: top;

          .dismissible-popup-container {
            padding-left: 4px;
            display: inline-flex;

            .cr-icon-info {
              width: 16px;
            }
          }

          .side-popup-popup-highlight {
            text-decoration: underline;
          }
        }

        .info-text {
          font-size: 14px;
          font-style: italic;
          font-weight: $font-weight-regular;
          color: $grey500;
        }

        .value {
          vertical-align: top;

          div.select {
            width: 200px;
            min-width: 50px;
            max-width: 300px;
          }

          &.datepicker-cell {
            display: flex;
            align-items: center;
            gap: 4px;
            .date-picker {
              flex-grow: 1;
            }
          }

          .recipients-list {
            display: flex;
            flex-direction: column;

            .user-avatar-and-name + .user-avatar-and-name {
              margin-top: 10px;
            }
          }
        }

        .comment-value {
          .rich-text-edit-v2.readonly {
            .editor-inner {
              padding: 0;
              min-height: unset;
              .content-editable {
                min-height: unset;
              }
            }
          }
        }

        .value-score {
          display: flex;
          flex-direction: row;
          align-items: center;

          .color-grade {
            width: 28px;
            height: 28px;
          }

          .score {
            height: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;

            .cstar-text {
              display: flex;
              flex-direction: row;
              align-items: center;

              .score-text {
                font-size: 16px;
              }

              .score-out-of {
                display: none;
                font-size: 16px;
              }
            }
          }
        }

        .commentary {
          width: 100%;
          max-width: 700px;
          height: 200px;
        }

        .commentary-field {
          width: 150px;
          height: 40px;
          padding-top: 8px;
          padding-left: 0;
          vertical-align: top;
        }

        .comment-value {
          vertical-align: top;
        }

        .gap {
          height: 10px;
        }

        .divider {
          height: 1px;
          border-bottom: 1px solid $grey100;
          margin-bottom: 10px;
        }

        input[type="text"] {
          width: 100%;
        }

        textarea {
          width: 100%;
          height: 100px;
          border: 0.25px solid $grey100;
          border-radius: 4px;
          font-weight: $font-weight-regular;
        }

        textarea:disabled {
          border: 0.25px solid $grey50;
          border-radius: 4px;
          color: #2c3238;
        }
      }

      .panel.left:not(:only-child) {
        border-right: 1px solid $grey100;
      }
    }

    .upload-working {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
      height: 88px;
      align-items: center;
      justify-content: center;
      cursor: default;
      border: 2px dashed $blue !important;
      border-radius: $card-border-radius !important;

      & > .loader-icon {
        width: 30px;
        height: 30px;
      }
    }

    .upload-target {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        color: $grey500;
      }

      .blue {
        color: $blue;
        margin-right: 5px;
      }

      .cr-icon-paperclip {
        margin-right: 5px;
      }
    }

    .ug-select {
      z-index: 11;
    }
  }

  .evidence-document {
    margin-top: 10px;
    margin-bottom: 5px;
    height: fit-content;

    .icons {
      .dismissible-popup-container {
        border: none;
      }
    }
  }

  .empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: $font-weight-regular;
    color: #95989b;
    font-style: italic;
    font-size: 1.2rem;
    line-height: 1.7rem;
    padding: 30px;

    .text {
      margin-bottom: 30px;
    }
  }

  .evidence-details-card-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $card-border-radius;
    border: 1px solid $light-grey;
    box-shadow: $box-shadow-4dp;
    min-height: 500px;
  }

  .ug-select {
    width: 100%;
    margin: 5px 0;
  }

  .avatar-name {
    display: flex;
    flex-direction: row;
    align-items: center;

    .user-avatar {
      margin-right: 5px;
    }

    & + .avatar-name {
      margin-top: 10px;
    }
  }

  .set-expiry {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 8px;

      i {
        font-size: 18px;
      }
    }
  }

  .scanning {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 16px;
    color: $grey500;

    .loader-icon {
      height: 12px;
      width: 12px;
      min-height: 12px;
      min-width: 12px;
      margin-right: 5px;
    }
  }
}
