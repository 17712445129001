@use "../../_common/style/core/variables";

.max-height-expandable {
  overflow: hidden;
  position: relative;

  .expand-collapse {
    position: absolute;
    bottom: 0;
    right: -4px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    color: variables.$blue500;
    font-style: italic;
    letter-spacing: 1px;
    font-size: 12px;
    background: radial-gradient(
      ellipse,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 57%,
      rgba(255, 255, 255, 0) 90%
    );
    cursor: pointer;
    padding: 10px;

    &:hover {
      color: variables.$blue700;
    }
  }
}
