@import "../../../../_common/style/core/variables";

#report_exports {
  .header {
    justify-content: space-between;

    .header-text {
      font-size: 18px;
    }

    .search-box-input-container {
      display: flex;
      flex-direction: row;
      padding: 0px 0px 0 0px;
      align-items: center;
      justify-content: center;
      margin: 0px;
      width: 300px;
    }
  }

  #tab-generated {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .pill-label {
      margin-left: 10px;
      .loader-icon {
        margin-right: 5px;
      }
    }
  }

  .header-tabs {
    div.tab {
      height: 62px !important;
      padding: 16px 24px;
      font-weight: 500;
      font-size: 16px;
      flex-basis: 0;
    }

    .gen-reports-row {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 8px;

      .loader-icon {
        margin-right: 4px;
      }

      .pill-label {
        background-color: $teal500;

        .pill-label-content {
          color: $white;
        }
      }
    }
  }

  .secondary-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0 0 0;

    &.search-only {
      display: block;
    }

    .tab-buttons-wrapper {
      flex: 1;
    }

    .search-box-input-container {
      padding: 0 32px 0 32px;
    }
  }

  .report-type-badge {
    margin: 0px;
  }

  .no-results-scheduled {
    padding-top: 40px;

    .empty-title {
      margin-top: 24px;
    }

    .empty-text {
      margin-top: 16px;
      width: 320px;
      line-height: 21px;
    }

    .empty-button {
      margin-top: 24px;
    }
  }

  & > .tab-buttons-wrapper {
    margin: 0 0 20px;
  }

  .active-tabs {
    margin-left: 32px;
  }

  .load-more-card {
    text-align: center;
    color: $blue;
    cursor: pointer;
    font-size: 11px;
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 30px;

    &:hover {
      background-color: rgba(76, 199, 238, 0.1);
    }
  }

  .action-bar {
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .action-buttons {
        button {
          margin-left: 16px;
        }

        .cr-icon-arrow-right {
          margin-left: -4px !important;
          margin-right: 4px !important;
        }

        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .un-archive-btn {
        .svg-icon {
          margin-right: 8px;
        }
      }
    }
  }

  .scheduled-reports {
    .search-box-input-container {
      display: flex;
      flex-direction: row;
      padding: 0px 0px 0 0px;
      align-items: center;
      justify-content: center;
      margin-left: 32px;
      margin-right: 32px;
      margin-top: 16px;
    }
    .header {
      display: flex;
      flex-direction: red;
      align-items: center;
      .search-box-input-container {
        display: flex;
        flex-direction: row;
        padding: 0px 0px 0 0px;
        align-items: center;
        justify-content: center;
        margin: 0px;
        width: 300px;
      }
    }
  }

  .library-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $grey500;
    margin-top: 20px;
  }

  .create-custom {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-bold;

    &:hover {
      cursor: pointer;
      background-color: $blue25;
    }

    .plus-circle {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $blue100;
      position: relative;
      margin-bottom: 16px;

      &:after {
        display: block;
        position: absolute;
        content: "+";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        color: $blue500;
        font-weight: $font-weight-regular;
      }
    }
  }
}

.full-page-modal.v2 .modal-content-container .report-delete {
  min-width: 500px;
}
