@import "../core/variables";

.locked-text {
  display: flex;
  flex-direction: row;
  padding: 5px 12px;
  border-radius: 4px;
  background-color: $grey50;
  justify-content: space-between;
  align-items: center;
  color: $grey900;

  .cr-icon-lock {
    margin-left: 10px;
  }
}
