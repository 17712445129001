@import "../core/variables";

$progress-circle-size: 24px;

.vertical-progress {
  display: flex;
  flex-direction: column;

  .progress-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 30px;
    position: relative;

    &:not(:last-child):before {
      content: "";
      position: absolute;
      width: 1.5px;
      background-color: $grey100;
      top: $progress-circle-size + 5px;
      left: $progress-circle-size * 0.5 - 1px;
      bottom: 5px;
    }

    &.filled-line:before {
      background-color: $blue;
    }

    > div + div {
      margin-left: 16px;
    }
  }

  .progress-circle {
    width: $progress-circle-size;
    height: $progress-circle-size;
    border-radius: 50%;
    background-color: $grey100;
    position: relative;

    &.highlight {
      background-color: $blue100;
    }

    .progress-circle-circle {
      position: absolute;
      top: 33.3%;
      left: 33.3%;
      bottom: 33.3%;
      right: 33.3%;
      border-radius: 50%;
      background-color: $blue;
    }

    .cr-icon-check {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $blue;
      font-size: 14px;
    }
  }

  .step-name {
    line-height: 150%;
    flex: 1;

    .step-description {
      color: $grey500;
      line-height: inherit;

      .btn {
        display: block;
        margin-top: 16px;
      }

      .btn-group {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .btn {
          margin-top: 0;

          & + .btn {
            margin-left: 16px;
          }
        }
      }
    }
  }

  &.show-right-content {
    .step-name {
      width: 150px;
      flex: unset;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .right-content {
    flex: 1;
    line-height: 150%;
  }
}
