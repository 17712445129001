@import "../../../_common/style/core/variables";

.email-exposure-modal {
  min-width: 800px;

  .label-list {
    display: block;
    margin-top: 5px;
  }
}
