@use "../../../_common/style/core/variables";

$cveWidth: 260px;

.mini-cve {
  display: inline-flex;
  width: $cveWidth;
  &.selectable {
    width: $cveWidth + 40px;
  }
  height: 63px;
  border: 1px solid variables.$grey100;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 16px;
  margin-right: 16px;
  padding: 10px;
  line-height: 1.5;

  .mini-cve-body {
    width: 100%;
    .mini-cve-name {
      color: variables.$blue500;
      font-weight: variables.$font-weight-bold;
      text-decoration: underline;
    }
    .mini-cvss-label {
      text-transform: capitalize;
    }
  }
  .mini-cve-pills {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.clickable:hover,
  &.selected {
    cursor: pointer;
    background-color: variables.$blue50;
    border: 1px solid variables.$blue;
  }

  .color-checkbox {
    width: 40px;
    align-items: baseline;
  }
}

.cve-mini-list {
  min-width: 200px;
  max-width: 1600px;
  display: flex;

  .loading-tight {
    margin-bottom: 16px;
  }

  .cve-select-all {
    min-width: 140px;
  }

  .show-more {
    margin-bottom: 16px;
    border-radius: 6px;
    background-color: variables.$blue50;

    &:hover {
      background-color: variables.$light-blue;
    }

    text-align: center;
    padding: 10px;
    color: variables.$blue;
    font-size: 12px;
    cursor: pointer;

    span {
      font-size: 11px;
      color: variables.$blue;
      margin-right: 5px;
    }
  }
}
