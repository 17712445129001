@use "../../_common/style/core/variables";

.clibrary-document-history {
  .doc-history-table {
    > table {
      tr {
        height: 60px !important;

        td {
          vertical-align: top;
          padding-top: 20px;
          padding-bottom: 20px;

          &.user-cell {
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }
      }
    }

    .date-cell {
      width: 20%;
      min-width: 250px;
    }

    .user-cell {
      width: 15%;
      min-width: 200px;
    }

    .updated-fields {
      padding: 0;

      tr {
        height: unset !important;

        td {
          padding: 0;
          border-top: none;
          vertical-align: top;

          &:first-child {
            width: 200px;
          }

          &:last-child {
            // Clamp the text
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }

        + tr td {
          padding-top: 8px;
        }
      }
    }
  }
}
