@import "../core/variables";

.text-field-performant {
  position: relative;
  width: 100%;

  textarea,
  input {
    width: 100%;
    position: absolute;
    resize: none;

    &.orange {
      background-color: $orange20;
    }
  }
}
