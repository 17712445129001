@use "../../../_common/style/core/variables";

.vendor-risk-waivers-card {
  .toggle-label {
    color: variables.$grey900;
    font-size: 14px;
  }

  .vendor-risk-waivers-filters {
    padding: 20px 32px;
  }

  .vendor-risk-waivers-table {
    .risk-name-cell {
      .risk-name-cell-title {
        font-weight: variables.$font-weight-bold;
      }
      .risk-name-cell-desc {
        margin-top: 8px;
        color: variables.$grey500;
        font-weight: variables.$font-weight-regular;
      }
    }

    .status-cell {
      .pill-label {
        margin: 4px;
      }
    }
  }

  .view-vendor-risk-waiver-panel,
  .public-risk-waivers-panel {
    .row-value .questionnaire-selection {
      width: 100%;
    }

    .title-container {
      padding: 0 40px 24px 40px;
      display: flex;
      flex-direction: row;
      min-height: 40px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      h2.title {
        font-size: 18px;
        line-height: 24px;
        padding: 0;
        max-width: 340px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 24px;
        min-height: unset;
        line-height: 1.32em;
      }

      .button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        min-width: 270px;
        width: 270px;
        margin: 0px;
        margin-right: 5px;
        height: 20px;
        position: relative;

        .btn {
          margin-left: 8px;
        }
      }
    }

    .slide-panel {
      max-width: 700px;
      width: 700px;
    }

    .title {
      border-bottom: 0 !important;

      .btn-group {
        display: flex;

        .btn {
          margin-right: 10px;
        }

        .cr-icon-trash-2,
        .cr-icon-minus-circle {
          font-size: 18px;
        }
        .cr-icon-pencil,
        .cr-icon-check {
          font-size: 14px;
        }
      }
    }

    .risk-desc {
      margin: 16px 0px;
      color: variables.$grey500;
      font-weight: variables.$font-weight-regular;
      line-height: 1.5;
    }

    .info-table.bordered {
      .row-value-cell {
        padding-right: 0;
      }

      .row-value {
        color: variables.$grey900;
      }

      .row-value p {
        margin: 0;
        color: inherit;
      }

      .row-value span {
        color: inherit;
      }

      .comment-display {
        width: 100%;
      }

      .row-label {
        width: 120px;
        max-width: 120px;
        text-wrap: wrap;
        font-weight: variables.$font-weight-regular;
      }
    }

    .user-display {
      .email {
        max-width: 370px;
      }
    }

    .waived-items {
      .waived-item + .waived-item {
        margin-top: 8px;
      }
    }

    .compensating-items {
      .compensating-item + .compensating-item {
        margin-top: 8px;
      }
    }

    .compensating-control-info-container {
      .info-banner {
        margin-bottom: 0;
      }
    }

    .info-table {
      table-layout: fixed;
      max-width: 100%;
    }

    .expandable-item {
      max-width: 100%;
    }

    .expandable-item-content {
      ul.website-list {
        padding-left: 20px;

        li {
          text-overflow: ellipsis;
          overflow: hidden;
          list-style-position: inside;
          text-wrap: nowrap;
        }
      }
    }

    div.expandable-item-header {
      border-radius: 4px;
      padding: 0 4px;
      width: fit-content;

      color: variables.$primary-black;

      &:hover {
        color: variables.$blue500;
        background-color: variables.$blue50;
      }

      a {
        color: inherit;
      }
    }
  }
}
