@use "../../_common/style/core/variables";

.analyst-findings {
  .top-bar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 20px;

    .transfer-div {
      margin: 0px;
      margin-left: auto;
      padding: 0px;
    }
    .dropdown-menu {
      margin: 0px;
      padding: 0px;
    }
  }

  .findings {
    .finding {
      padding: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:hover,
      &.selected {
        background-color: variables.$canvas;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid variables.$light-grey;
      }

      .finding-data-bar {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        min-width: 0;
      }

      .finding-header {
        display: flex;
        flex-direction: row;
        align-items: left;
        overflow: hidden;
        margin-right: 10px;
      }

      a {
        text-transform: none;
        color: #0f54f0 !important;

        &:hover {
          color: variables.$blue !important;
          text-decoration: underline !important;
        }
      }

      .finding-title {
        color: variables.$black;
        margin-bottom: 10px;
        margin-top: 0px;
        font-size: 16px;
      }

      .severity-icon img {
        margin-right: 20px;

        img {
          height: 25px;
          width: auto;
        }
      }
      .finding-url {
        color: variables.$blue;
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 15px;
        white-space: nowrap;
      }
      .finding-layout {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 30px;
        min-height: 100px;
        display: flex;
        flex-direction: row;
        align-items: left;
      }
      .finding-image {
        padding: 0px;
        max-height: 300px;
        width: 300px;

        &.empty {
          background-color: variables.$light-grey;
          height: 200px;
          width: 300px;
          text-align: center;
          vertical-align: middle;
          line-height: 200px;
        }
      }
      .finding-attribute-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        min-height: 20px;
        margin-left: 20px;
        margin-right: 5px;

        label {
          color: variables.$dark-grey;
          cursor: default;
          text-transform: uppercase;
          margin-bottom: 5px;
          margin-top: 5px;
        }
        .text {
          color: variables.$black;
          margin-bottom: 20px;
          margin-top: 5px;
          font-size: 15px;
        }
      }
      .finding-buttonbar {
        width: 300px;
        padding-top: 10px;

        .btn {
          margin-right: 8px;
        }

        .transfer-div {
          margin: 0px;
          margin-left: auto;
          padding: 0px;

          .dropdown-menu {
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }
  }
}
