@import "../../../../_common/style/core/variables";

.vendor-assessment-additional-table {
  .name-cell {
    width: 40%;
    max-width: 40%;

    .pill-label {
      margin-left: 8px;
    }
  }

  .access-cell {
    min-width: 120px;
  }
}
