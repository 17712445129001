@use "../../_common/style/core/variables";

body:has(.public-trust-page) {
  min-width: 400px !important;
}

.public-trust-page {
  --trust-page-max-width: 1440px;
  --header-container-height: 362px;
  --cover-img-height: 250px;

  min-width: 0 !important;
  margin-inline: auto;

  // hide scrollbars
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 640px) {
    --cover-img-height: 180px;
  }

  .cover-img {
    height: var(--cover-img-height);
    width: 100%;
    overflow: hidden;
    border-bottom-left-radius: calc(var(--header-container-height) / 2);
    object-fit: cover;
    object-position: center;

    @media all and (max-width: 640px) {
      border-bottom-left-radius: calc(var(--header-container-height) / 3);
    }
  }

  .public-trust-page-wrapper {
    max-width: var(--trust-page-max-width);
    margin: 0 auto;
    padding: 0 calc(2 * variables.$space-lg);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media all and (max-width: 800px) {
      padding: 0 variables.$space-md;
    }

    .empty-content,
    .not-published-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 1 auto;
      margin-top: variables.$space-xl;
      gap: variables.$space-xl;
      min-height: 45vh;

      > span {
        color: variables.$grey600;
        font-size: variables.$font-size-larger;
      }

      > i {
        color: variables.$grey600;
        font-size: 48px;
      }
    }
  }
}
