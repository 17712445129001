@import "../../../_common/style/core/variables";

.org-integrations-config {
  display: flex;
  flex-direction: column;

  .red {
    color: $red;

    .url {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: inherit;

      .icon-info {
        padding-top: 2px;
        margin-left: 5px;
        color: $red;
      }
    }
  }

  .integrations-header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    .actions {
      flex: 2 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      opacity: 1;

      .loader-icon {
        width: 20px;
        height: 20px;
      }

      .action-link {
        color: $blue;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }

    .save-button {
      margin-left: 5px;
      width: 150px;
      height: 34px;
    }
  }

  .integrations-list-card {
    .search-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 32px 32px 0px 32px;

      .tabs {
        flex: 0;
        min-width: 320px;
      }

      .search-box {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        .search-box-input-container {
          max-width: 450px;
          width: 450px;
        }
      }
    }

    .header-right {
      span {
        color: $blue500;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
      }

      .settings-icon {
        width: 16px;
        height: 16px;
        margin-right: 4px !important;
      }
    }

    .info-banner-container {
      padding: 20px 32px 0 32px;
      display: flex;
      flex-direction: column;
    }

    .buttons-container {
      padding: 20px 32px 0 32px;
      display: flex;
      flex-direction: row;

      .report-card {
        flex-grow: 1;
        width: 30%;
        margin-top: 0;
        padding: 20px;
        box-shadow:
          0 1px 2px rgba(64, 70, 104, 0.06),
          0 1px 3px rgba(64, 70, 104, 0.1);
        display: flex;
        flex-direction: row;

        img {
          height: 43px;
          width: 43px;
          flex-grow: 0;
        }

        .button {
          flex-grow: 0;
        }

        &:not(:last-child) {
          margin-right: 16px;
        }

        .button-text {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          margin-left: 17px;
          margin-right: 10px;

          .title {
            font-size: 14px;
            line-height: 150%;
            color: $grey900;
            font-weight: 500;
          }

          .label {
            font-size: 14px;
            line-height: 150%;
            font-weight: normal;
            margin-top: 4px;
            color: $grey500;

            .workspace-name {
              padding-left: 5px;
              color: $blue400;
              font-weight: 600;

              &:hover {
                color: $blue;
                cursor: pointer;
              }
            }

            .disconnected {
              color: $red500 !important;

              &:hover {
                color: $red !important;
                cursor: pointer;
              }
            }
          }
        }

        .buttons-lhs {
          display: flex;
          flex-direction: column;
          margin: 0px;
          padding: 0px;
          align-items: center;

          .settings {
            font-size: 12px;
            color: $grey500;
            font-weight: 500;
            line-height: 150%;
            margin-top: 5px;

            &:hover {
              color: $blue;
              cursor: pointer;
            }
          }
        }
      }
    }

    .integrations-blank-state {
      padding: 20px;

      .left-p {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        p {
          text-align: left;
          padding: 15px;
        }

        h3 {
          text-align: left;
          font-size: 20px;
          margin-bottom: 20px;
          font-style: normal;
          display: block;
        }

        button {
          font-style: normal;
          font-weight: $font-weight-regular;
          margin-bottom: 20px;
        }

        img {
          max-width: $max-page-width;
          width: 100%;
          margin: 40px 0;
          display: block;
        }
      }
    }

    .status-toggle {
      width: 50px;
    }

    .btn {
      &.add-button {
        .icon-x {
          margin-right: 5px;
          margin-left: 0px;
          transform: rotate(45deg);
        }
      }
    }

    .integration-row {
      .options-icons-cell {
        div:last-child {
          .btn-icon:hover {
            background-color: unset;
          }
        }
      }

      .description {
        .description-text {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          font-weight: 500;

          .text {
            color: $grey900;
          }

          .red {
            color: $red;
          }

          .icon-info {
            color: $red;
            padding-top: 2px;
            padding-left: 5px;
          }
        }

        .description-subtext {
          margin-top: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          font-size: 12px;
          font-weight: 400;

          .text {
            color: $grey500;
          }

          .red {
            color: $red;
          }
        }
      }

      .icon-chevron {
        color: $grey900;
        padding-bottom: 24px;
        font-size: 15px;
      }
    }
  }
}

.oauth2-callback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .working {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 60px;
  }

  .error {
    color: $red;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $red;
    fill: $red500;
    margin-top: 20px;
    padding: 30px;
  }
}

.zapier-application-directory-modal {
  width: 1000px;
}

.new-integration-modal {
  .buttons-container {
    padding: 0px 0px 0px 0px;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    width: 440px;

    .greyed {
      color: $grey200 !important;
    }

    .report-card {
      width: 440px;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 16px;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      transition: all ease-in-out 100ms;

      &:hover {
        background-color: $blue50;
        border-color: $blue500;
      }

      img {
        height: 48px;
        width: 48px;
        flex-grow: 0;
        margin-right: 16px;
      }

      .chevron {
        font-size: 16px;
        font-weight: 600;
        color: $grey800;
      }

      .button-text {
        width: 450px;

        .title {
          font-size: 16px;
          line-height: 24px;
          color: $grey800;
          font-weight: 500;
        }

        .label {
          font-size: 14px;
          line-height: 21px;
          font-weight: normal;
          color: $grey500;
          font-weight: 400;
        }
      }
    }
  }
}

.settings-modal {
  .buttons-container {
    padding: 0px 0px 0px 0px;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    width: 440px;

    .report-card {
      width: 440px;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 16px;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      align-items: center;

      .add-button {
        width: 120px;
      }

      img {
        height: 48px;
        width: 48px;
        flex-grow: 0;
        margin-right: 16px;
      }

      .chevron {
        font-size: 16px;
        font-weight: 600;
        color: $grey800;
      }

      .button-text {
        width: 450px;

        .title {
          font-size: 16px;
          line-height: 24px;
          color: $grey800;
          font-weight: 500;
        }

        .label {
          font-size: 14px;
          line-height: 21px;
          font-weight: normal;
          color: $grey500;
          font-weight: 400;
        }
      }
    }
  }
}
