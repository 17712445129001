@import "../../../_common/style/core/variables";

.org-permissions-table {
  .search-box-input-container {
    padding: 20px 32px;
  }

  .description-with-subtext {
    line-height: 1.3;

    .description-maintext {
      font-size: $font-size;
    }

    .description-subtext {
      font-size: $font-size-smaller;
      color: $grey500;
    }
  }

  // Modify the padding on the expanded cell so we don't have
  // a gap between the expanded content
  .report-card {
    td.expanded-content-cell {
      padding: 0;
    }
  }
}
