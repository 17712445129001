@import "../core/variables";

.rich-text-edit-v2 {
  border-radius: 4px;
  color: $grey900;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;

  transition-property: background-color, color, border-color, box-shadow;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;

  &:not(.readonly) {
    background: #fff;
    border: 1px solid $grey200;

    &:focus-within {
      box-shadow: 0 0 0 3px $blue100;
      border-color: $blue300;
    }
  }

  &.readonly {
    .editor-inner {
      padding: 0;
      .content-editable {
        .editor-link {
          pointer-events: auto;
        }
      }
    }
  }

  .rick-text-edit-v2-toolbar {
    padding: 8px 16px;
    border-bottom: 1px solid $grey200;
    display: flex;
    align-items: center;

    .vertical-divider {
      display: inline-block;
      width: 1px;
      height: 24px;
      margin-left: 12px;
      margin-right: 11px;
      border-right: 1px solid $grey200;
    }

    .btn-icon-popup + .btn-icon-popup {
      margin-left: 8px;
    }

    .btn-icon {
      width: 24px;
      height: 24px;

      > * {
        margin-top: 2px; // wtf why doesn't this line up automajically :'(
        font-size: 20px;
      }

      &:not(.disabled):not(.active) {
        > * {
          color: $grey600;

          &:hover {
            color: $blue500;
          }
        }
      }

      &.disabled {
        opacity: 1;
        color: $grey200;
      }
    }

    .link-surface {
      min-width: 300px;
      padding: 24px;

      .link-surface-actions {
        display: flex;
        justify-content: flex-end;

        .btn + .btn {
          margin-left: 8px;
        }
      }
    }
  }

  .editor-inner {
    min-height: 250px;
    position: relative;
    padding: 16px 16px 8px 16px;
    outline: none;
    overflow-y: auto;

    .content-editable {
      min-height: 220px;
    }

    .editor-input {
      resize: none;
      font-size: 15px;
      position: relative;
      tab-size: 1;
      outline: 0;
      padding: 15px 10px;
      caret-color: #444;
    }

    .editor-placeholder {
      color: $grey200;
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      top: 16px;
      left: 18px;
      font-size: 15px;
      user-select: none;
      display: inline-block;
      pointer-events: none;
    }

    /* Editor tag theme styles */

    .editor-text-bold {
      font-weight: bold;
    }

    .editor-text-italic {
      font-style: italic;
    }

    .editor-text-underline {
      text-decoration: underline;
    }

    .editor-text-strikethrough {
      text-decoration: line-through;
    }

    .editor-text-underlineStrikethrough {
      text-decoration: underline line-through;
    }

    .editor-text-code {
      background-color: rgb(240, 242, 245);
      padding: 1px 0.25rem;
      font-family: Menlo, Consolas, Monaco, monospace;
      font-size: 94%;
    }

    .editor-link {
      color: $blue500;
      text-decoration: underline;
      pointer-events: none;

      > * {
        color: $blue500;
        text-decoration: underline;
      }
    }

    .tree-view-output {
      display: block;
      background: #222;
      color: #fff;
      padding: 5px;
      font-size: 12px;
      white-space: pre-wrap;
      margin: 1px auto 10px auto;
      max-height: 250px;
      position: relative;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: auto;
      line-height: 14px;
    }

    .editor-code {
      background-color: rgb(240, 242, 245);
      font-family: Menlo, Consolas, Monaco, monospace;
      display: block;
      padding: 8px 8px 8px 52px;
      line-height: 1.53;
      font-size: 13px;
      margin: 0;
      margin-top: 8px;
      margin-bottom: 8px;
      tab-size: 2;
      /* white-space: pre; */
      overflow-x: auto;
      position: relative;
    }

    .editor-code:before {
      content: attr(data-gutter);
      position: absolute;
      background-color: #eee;
      left: 0;
      top: 0;
      border-right: 1px solid #ccc;
      padding: 8px;
      color: #777;
      white-space: pre-wrap;
      text-align: right;
      min-width: 25px;
    }

    .editor-code:after {
      content: attr(data-highlight-language);
      top: 0;
      right: 3px;
      padding: 3px;
      font-size: 10px;
      text-transform: uppercase;
      position: absolute;
      color: rgba(0, 0, 0, 0.5);
    }

    .editor-tokenComment {
      color: slategray;
    }

    .editor-tokenPunctuation {
      color: #999;
    }

    .editor-tokenProperty {
      color: #905;
    }

    .editor-tokenSelector {
      color: #690;
    }

    .editor-tokenOperator {
      color: #9a6e3a;
    }

    .editor-tokenAttr {
      color: #07a;
    }

    .editor-tokenVariable {
      color: #e90;
    }

    .editor-tokenFunction {
      color: #dd4a68;
    }

    p.editor-paragraph {
      margin: 0;
      margin-bottom: 8px !important;
      position: relative;
      color: $grey900 !important;
    }

    .editor-heading-h1 {
      font-size: 24px;
      color: rgb(5, 5, 5);
      font-weight: 400;
      margin: 0;
      margin-bottom: 12px;
      padding: 0;
    }

    .editor-heading-h2 {
      font-size: 15px;
      color: rgb(101, 103, 107);
      font-weight: 700;
      margin: 0;
      margin-top: 10px;
      padding: 0;
      text-transform: uppercase;
    }

    .editor-quote {
      margin: 0;
      margin-left: 20px;
      font-size: 15px;
      color: rgb(101, 103, 107);
      border-left-color: rgb(206, 208, 212);
      border-left-width: 4px;
      border-left-style: solid;
      padding-left: 16px;
    }

    .editor-list-ol {
      padding: 0;
      margin: 0;
      margin-left: 16px;
    }

    .editor-list-ul {
      padding: 0;
      margin: 0;
      margin-left: 16px;
    }

    .editor-listitem {
      margin: 8px 32px 8px 32px;
    }

    .editor-nested-listitem {
      list-style-type: none;
    }

    /* Editor tag theme styles */
  }
}
