@use "../../_common/style/core/variables";

.vsaq-loader {
  position: relative;

  .vsaq-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 20;
    padding: 16px;
    background-color: variables.$white;
    border-bottom: 1px solid variables.$grey50;

    & > input[type="file"] {
      display: none;
    }

    & > .ug-select {
      width: 200px;
      margin-right: 20px;

      .ug-select__control {
        min-width: 0;
      }
    }
  }
}
