@use "../../../../_common/style/core/variables";

.check-item {
  .slide-panel-section {
    border-top: 1px solid variables.$grey100;

    .section-header {
      padding-top: 24px;
      padding-bottom: 24px;
      color: variables.$grey900;

      &:hover {
        color: variables.$blue500;
      }

      span {
        color: inherit;
      }

      h3 {
        font-size: variables.$font-size-larger;
        font-weight: variables.$font-weight-bold;
        color: inherit;

        div.section-title {
          color: inherit;
        }

        .severity-icon {
          margin-right: 16px;
        }
      }
    }
  }
}

.slide-panel-section {
  &:not(:first-child) {
    border-top: 1px solid variables.$grey50;
  }

  .section-header {
    padding: 24px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .icon-chevron {
      margin-right: 8px;
      transition: transform 150ms ease-out;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .section-body {
    padding: 0 40px 32px 40px;

    > :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .ug-select {
    z-index: unset;
  }
}
