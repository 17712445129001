@import "../../../../_common/style/core/variables";

.new-assessment-menu {
  .dropdown-item {
    width: 300px;
    height: auto;
  }

  .dropdown-with-text {
    width: 100%;
    text-align: left;
    white-space: normal;

    .dropdown-title {
      margin-bottom: $space-sm;
      font-weight: $font-weight-bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $space-sm;
    }

    .dropdown-text {
      color: $grey500;
    }
  }
}
