.update-labels-modal {
  min-width: 600px;
  padding-bottom: 0px !important;

  .search-box-input-container {
    margin-bottom: 20px;
  }

  .label-sets {
    max-height: 600px;
    overflow: auto;
    padding-bottom: 24px;

    .label-set {
      margin-bottom: 10px;
    }

    .label-header {
      font-size: 10px;
      color: #5d6598;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }
  }

  .add-label-btn {
    margin-bottom: 20px;
  }
}
