@use "../../../../_common/style/core/variables";

.questionnaire-selection {
  .comment-display,
  .compensating-info-missing {
    margin-top: 12px;
  }

  .color-checkbox {
    .label {
      color: variables.$blue500;
    }
  }

  .questionnaire-item {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid variables.$grey100;

    .shared-questionnaire {
      margin-left: 10px;
    }

    &.is-editing {
      .sent-by-text,
      .compensating-control-info-container {
        margin-left: 30px;
      }
    }

    .questionnaire-item-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .color-checkbox {
        max-width: 60%;

        .label {
          font-weight: variables.$font-weight-bold;
          white-space: normal;
        }

        .box {
          margin-top: 1px;
          align-self: flex-start;
        }
      }

      .questionnaire-title {
        color: variables.$blue500;
        line-height: 21px;
        margin-bottom: 4px;
        font-weight: variables.$font-weight-bold;
        display: inline-block;

        .pill-label {
          margin-left: 4px;
        }
      }

      .questionnaire-link {
        color: variables.$grey500;
        min-width: 130px;
        line-height: 21px;

        &:hover {
          color: variables.$grey300;
        }
      }

      .questionnaire-link-button {
        padding: 0px;
        line-height: 21px;
        height: 21px;
      }
    }

    .sent-by-text {
      font-size: 14px;
      color: variables.$grey500;
      line-height: 21px;
      margin-bottom: 24px;
    }

    .compensating-control-info-container {
      .compensating-control-info-title {
        color: variables.$grey300;
        font-size: 10px;
        line-height: 10px;
        margin-bottom: 8px;
        text-transform: uppercase;
      }
    }
  }

  .questionnaire-item:last-child {
    border-bottom: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}
