@import "../../../../_common/style/core/variables";

.vendor-assessment-v3-evidence-step {
  .evidence-card {
    margin-top: 24px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-radius: 8px;
    border: 2.5px solid transparent;

    &:last-of-type {
      margin-bottom: 48px;
    }

    &.small {
      display: grid;
      grid-template-columns: 1.7fr 1fr 1fr;
      padding: 24px 32px;

      .btn {
        margin-left: auto;
      }

      .sub-text {
        margin-top: 8px;
        margin-left: 30px;
        color: $grey600;
        font-size: $font-size;
        font-weight: $font-weight-regular;
        line-height: 22px;
        display: grid;
        grid-column-start: 1;
        grid-column-end: 3;
      }

      .text {
        white-space: nowrap;
      }

      .chevron-div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .chevron-label {
          color: $grey500;
          font-weight: $font-weight-regular;
          line-height: 22px;
          font-size: $font-size;
        }
      }
    }

    &.selected {
      border-radius: 8px;
      border: 2.5px solid $blue500;
    }

    .check-box {
      display: flex;
      flex-direction: row;
      align-items: center;

      .color-checkbox {
        width: unset;
      }

      .title {
        color: $grey900;
        font-size: $font-size-larger;
        font-weight: $font-weight-bold;
        line-height: 24px;
      }
    }

    .text {
      color: $grey900;
      font-size: $font-size;
      font-weight: $font-weight-regular;
      line-height: 22px;
    }

    .x-table {
      tr {
        height: 60px;
      }
    }
  }

  .evidence-header {
    width: 100%;
    padding: 24px 32px 0 32px;
    .btn {
      margin-left: auto;
    }

    .top {
      display: flex;
      flex-direction: row;
    }

    .sub-text {
      margin-top: 8px;
      margin-left: 30px;
      color: $grey600;
      font-size: $font-size;
      font-weight: $font-weight-regular;
      line-height: 22px;
    }
  }
}
