@import "../../../_common/style/core/variables";

.add-vip-emails-modal-header {
  display: flex;
  flex-direction: column;
  max-width: 640px;

  & > h2 {
    padding-bottom: 16px;
  }

  & > p {
    color: $grey500;
  }
}

.add-vip-emails-modal {
  .emails-label {
    color: $grey900;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .textarea-with-pills {
    .pill-label-content {
      font-size: 1.2em;
    }
  }
}
