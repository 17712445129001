@use "../../../../_common/style/core/variables";

.portfolio-selection {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .p-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;

    input {
      flex: 1;
    }

    .btn-icon-popup {
      margin-left: 10px;
    }

    & + .p-row {
      margin-top: 8px;
    }
  }

  .color-checkbox {
    width: unset;

    .label {
      display: flex;
      flex-direction: row;
      align-items: center;

      .pill-label {
        margin-left: 16px;
      }
    }
  }

  .add-portfolio-btn-wrapper {
    margin-top: 16px;
    align-self: stretch;
  }

  .add-portfolio {
    width: 100%;
    margin-top: 8px;

    &:not(.empty) {
      background-color: #fff;
      border: 1.5px dashed variables.$grey200;
    }
  }
}
