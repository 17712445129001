@import "../core/variables";

.q-shared-list-modal {
  min-width: 500px;

  h3 + * {
    margin-top: 10px;
  }

  * + h3 {
    margin-top: 30px;
  }

  .shared-with-user {
    display: flex;
    flex-direction: row;
    padding: 10px 0;

    &.invite {
      align-items: center;
    }

    & + .shared-with-user {
      border-top: 1px solid $grey50;
    }
  }
}
