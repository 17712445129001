@import "../../_common/style/core/variables";

.incomplete-submission-modal {
  width: 1000px;

  .progress-bar {
    max-width: 50%;
  }

  .question-table {
    margin-top: 24px;

    tbody {
      td {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
      }
    }

    .unanswered-question-row {
      padding: 16px 0;

      cursor: pointer;

      &:hover {
        background-color: $blue50;
      }

      .item-icon-cell {
        width: min-content !important;
        padding-right: 10px !important;
      }

      td {
        width: unset !important;
        vertical-align: middle !important;
      }

      border-top: 1px solid $grey50;

      .survey-viewer-icon {
        display: inline-flex;
        margin-right: 12px;
        background-color: $blue50;
        mix-blend-mode: multiply;
        align-self: flex-start;

        .icon {
          i {
            color: $blue500;
          }
        }

        .display-id {
          color: $blue500;
        }
      }

      .node-name-cell {
        width: 100% !important;
        .node-name {
          flex-grow: 1;

          // If node name has HTML inside it, provide some default styling
          p {
            color: $grey600;
          }

          a {
            text-decoration: underline;
          }

          p:first-of-type {
            margin-top: 0px;
          }
        }
      }

      i.cr-icon-chevron {
        margin-left: 24px;
        margin-right: 24px;
      }
    }

    :last-of-type(.unanswered-question-row) {
      border-bottom: 1px solid $grey50;
    }
  }

  .extra-question-count {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
    margin-left: 40px;
  }
}
