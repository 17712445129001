@import "../../_common/style/core/variables";

.add-content-library-doc-modal {
  width: 600px;

  .dragdropupload + * {
    margin-top: 24px !important;
  }

  * + .info-banner {
    margin-top: 24px;
  }
}
