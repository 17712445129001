// The following overflow, text-overflow and white-space CSS rules really control where
// the ellipsis will be applied. This is the crux of the styling side of the component.
.ellipsized-text > *, // default implementation
.ellipsized-text, // handle simple string content
*:has(> .ellipsized-text-container) {
  // handle usage inside flex parent
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  min-width: 0px;
}

.ellipsized-text-popup {
  // if there are no word breaks, super long content really needs to wrap
  // so that it doesn't look broken. maxWidth helps address that issue.
  // This is just a sensible default value but components can override it
  // by supplying their own popupClassName which will generally be given
  // precedence over this one (but both will be applied)
  max-width: 100%;
}
