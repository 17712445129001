@use "../../../../_common/style/core/variables";

$vendor_summary_report: #81c3d8;
$vendor_detailed_report: #bce7ce;
$vendor_assessment_report: #8babe1;
$vendor_custom_report: #93caed;
$breachsight_custom_report: #f7abab;
$breachsight_summary_report: #ffbfbf;
$breachsight_detailed_report: #ffe69a;
$board_summary_report: #cccfe0;
$vendor_risk_executive_summary_report: #5d6598;
$vendor_comparison_report: #3f68ac;
$subsidiaries_summary_report: #fbd8b8;
$custom_report: variables.$green200;
$disabled: variables.$white;

.report-type-badge-container {
  &,
  > .dismissable-popup-click-capture {
    display: flex;
    width: 100%;
  }
}

.report-type-badge {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px;
  border: solid 2px variables.$grey100;
  border-radius: 8px;
  background-color: variables.$white;
  margin-top: 8px;
  margin-bottom: 8px;
  flex-grow: 1;
  transition: all 250ms ease-in-out;

  .busy {
    position: absolute;
    padding-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .disabled-overlay {
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    background-opacity: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
      background-color: variables.$grey200;
      color: variables.$grey800;
      padding: 2px 10px;
      border: 0.5px solid variables.$grey300;
      border-radius: 3px !important;
    }
  }

  .lhs {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: transparent;
    flex: 1;

    .report-title {
      color: variables.$grey900;
      font-size: 14px;
      line-height: 150%;
      font-weight: 500;
      margin-bottom: 12px;
    }
    .report-subtext {
      color: variables.$grey500;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      margin-bottom: 30px;
      flex: 1;
      .link {
        color: variables.$blue400;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: variables.$blue;
        }
      }
    }
    .disabled {
      color: variables.$grey200 !important;
      border: 0px;
    }

    .module {
      font-size: 12px;
      font-weight: 500;
      color: variables.$grey500;
      margin-bottom: 10px;
      .cr-icon-vendor-risk {
        margin-right: 8px;
        color: variables.$black;
      }
      .cr-icon-breachsight {
        margin-right: 8px;
      }
      .cr-icon-upguard-logo-pick {
        margin-right: 8px;
        color: variables.$black;
      }
    }
    .disabled {
      color: variables.$grey200;
    }
  }
  .rhs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 260px;
    position: relative;

    img {
      height: 220px;
    }

    &.short img {
      height: unset;
      width: 220px;
    }
  }
  .disabled {
    background-color: variables.$grey50 !important;
    border: solid 0px variables.$grey200;
    border-left: solid 1px variables.$grey200;
    cursor: default;
    img {
      opacity: 40%;
    }
  }
  .vsr {
    background-color: $vendor_summary_report;
    img {
      margin-bottom: -10px;
    }
  }
  .vdr {
    background-color: $vendor_detailed_report;
  }
  .var {
    background-color: $vendor_assessment_report;
  }
  .vcur {
    background-color: $vendor_custom_report;
  }
  .bcur {
    background-color: $breachsight_custom_report;
  }
  .bsr {
    background-color: $breachsight_summary_report;
    img {
      margin-bottom: -10px;
    }
  }
  .bdr {
    background-color: $breachsight_detailed_report;
  }
  .bor {
    background-color: $board_summary_report;
  }
  .bop {
    background-color: $board_summary_report;
  }
  .ves {
    background-color: $vendor_risk_executive_summary_report;
  }
  .vcr {
    background-color: $vendor_comparison_report;
  }
  .ssr {
    background-color: $subsidiaries_summary_report;
  }
  .custom {
    background-color: $custom_report;
  }

  .disabled {
    background-color: $disabled;
  }

  .customize-export-btn {
    margin-top: 5px;
    margin-bottom: -15px;
  }
}

.report-type-badge-hoverable {
  cursor: pointer;
  &:hover {
    border: solid 2px variables.$blue500;
  }
}

.report-type-badge-selected {
  background-color: #f3f7fc !important;
  border: solid 2px variables.$blue500;
  cursor: default;
}

.report-type-badge-disabled {
  background-color: variables.$grey50 !important;
  border: solid 1px variables.$grey200;
  cursor: default;
}
