@import "../../../_common/style/core/variables";

.org-vendor-search-modal-header {
  .description {
    max-width: 700px;
    font-size: 14px;
    line-height: 150%;
    color: $grey500;
  }
}

.full-page-modal.v2 .modal-content-container .org-vendor-search-modal {
  max-width: 880px;
  padding-left: 0;
  padding-right: 0;
  width: 60vw;
  min-width: 400px;

  .search-bar {
    padding-left: 32px;
    padding-right: 32px;
  }

  .x-table {
    td:first-of-type {
      padding-left: 32px;
    }

    td:last-of-type {
      padding-right: 32px;
    }

    td {
      vertical-align: middle;
    }

    .score-cell {
      display: flex;
      align-items: center;
      height: 100%;
      flex-direction: row;

      .score {
        margin-left: 8px;
        .cstar-text {
          margin-bottom: 0px;
        }
      }
    }

    .action-cell {
      width: 0.1%;
      button {
        white-space: nowrap;
      }
    }
  }
}
