@use "../../../_common/style/core/variables";

.contact-us-modal {
  width: 533px;
}

.contact-us-modal-header {
  font-weight: variables.$font-weight-bold;
  font-size: 16px;
  text-align: center;

  .circled-icon {
    width: 50px;
    height: 50px;
    align-self: center;
    margin-bottom: 24px;
  }
}

.contact-us-modal-footer {
  .send-button {
    line-height: 20px;

    .btn-inner {
      line-height: 20px;
      font-weight: variables.$font-weight-bold;
    }
  }
}

.contact-us-body-content {
  text-align: center;
  font-weight: variables.$font-weight-regular;
  line-height: 22px;
  word-wrap: break-word;
  width: 325px;
  margin: auto;
}
