@use "../../../_common/style/core/variables";

.userbase-header {
  .name {
    // Add space after the header icon before the name
    .header-icon {
      margin-right: 4px;

      // Force the company logo to be 24px
      .circle-logo {
        width: 24px;
        min-width: 24px;
        height: 24px;
      }

      // Reduce the font-size of company name
      // as we have shrunk the logo circle
      .company-name {
        font-size: 0.4rem;
      }
    }
    .domain {
      margin-left: 4px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .name-widget {
    margin-left: 16px;

    div.score {
      margin-left: 8px;
    }
  }

  .header-metadata-section {
    .metadata-widget {
      align-items: center;

      .header-icon {
        margin-right: 8px;
      }

      .label-list {
        border-left: none;

        .pill-label {
          margin-top: unset;
        }
      }

      .scope-risk-level-display {
        margin-right: 8px;
      }
      .header-text {
        padding-right: 4px;
        font-weight: variables.$font-weight-bold;
        color: variables.$grey600;
        line-height: variables.$line-height;
      }

      .approved-teams {
        color: variables.$grey600;
        font-weight: variables.$font-weight-bold;
        margin-left: 4px;
        .label-list {
          border-left: unset;
          padding-left: unset;
        }
      }
    }
  }
}
