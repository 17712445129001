@import "../../../_common/style/core/variables";

.expanded-app-list-style {
  // Since this list is an expanded list we modify report card
  // to be grey, with no radius and no box shadow
  background-color: $grey50;
  border: 1px solid $light-grey;
  table {
    th:first-of-type,
    td:first-of-type {
      padding-left: 32px;
    }
  }

  .app-name-col {
    width: 40%;
  }

  .security-rating-col {
    width: 15%;
  }

  .user-exposure-col {
    width: 15%;
  }

  .first-detected-col {
    width: 15%;
  }

  .user-status-col {
    width: 15%;
  }
}
