@import "../../_common/style/core/variables";

.document-type-selector {
  .new-type-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grey500;

    .cr-icon-plus {
      font-size: 18px;
      color: $grey500;
      margin-right: 6px;
    }
  }
}
