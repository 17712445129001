@use "sass:color";
@use "../../../_common/style/core/variables";

#shared-profile-embed-modal {
  padding: 30px !important;

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    color: variables.$font-color;
    margin-bottom: 0;

    pre {
      display: inline;
      color: variables.$font-color;
      font-size: 14px;
    }
  }

  h4 {
    margin-top: 30px;
    font-size: 18px;
  }

  .preformatted {
    font-family: monospace;
    resize: none;
    margin-top: 10px;
  }

  .upguard-btn-sample {
    display: inline-block;
    background-color: variables.$blue;
    white-space: nowrap;
    color: white;
    padding: 0 5px;
    border-radius: 2px;
    line-height: 18px;
    font-size: 10px;
    font-family: "Inter", "Open Sans", Helvetica, Arial, sans-serif;
    text-decoration: none;
    cursor: pointer;

    img {
      height: 12px;
      vertical-align: middle;
      display: inline-block;
      margin-right: 5px;
    }

    .ug-divider {
      display: inline-block;
      height: 12px;
      width: 1px;
      background: color.adjust(variables.$blue, $lightness: 40%);
      margin-right: 5px;
      vertical-align: middle;
    }

    &:hover {
      background: variables.$light-blue;
      box-shadow: 0 2px 4px rgba(18, 67, 150, 0.13);
    }
  }

  .with-copy-link {
    position: relative;

    textarea {
      width: 100%;
    }

    .copy-link {
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: variables.$blue;
      cursor: pointer;
      letter-spacing: 1px;
      font-size: 11px;
      text-transform: uppercase;

      &.copied {
        color: variables.$medium-grey;
        cursor: default;
      }
    }
  }
}
