@import "../../../_common/style/core/variables";

.vendor-overlay-wrapper {
  .vendor-watched-loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9; // One less than the side nav z-index
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0;
    pointer-events: none;

    .loading-large {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 50px;
    }
  }

  &.loading .vendor-watched-loading-overlay {
    opacity: 1;
    pointer-events: all;
  }

  .vendor-overlay-component-wrapper.restricted {
    filter: blur(2px);
    pointer-events: none;
    max-height: 85vh;

    .tab-switcher {
      filter: blur(1px);
    }

    .changes-view-frame {
      display: none;
    }
  }
}
