@use "../../_common/style/core/variables";

.doc-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: variables.$space-sm;
  width: 100%;
  padding-bottom: 16px;

  > * {
    word-wrap: break-word;
  }

  .cr-icon-document {
    color: variables.$grey500;
    background-color: variables.$grey100;
  }

  .doc-details {
    flex: 1;
    line-height: 150%;
    color: variables.$grey900;
    font-size: variables.$font-size;

    .doc-last-updated {
      color: variables.$grey600;
      font-size: variables.$font-size-smaller;
    }
  }

  .doc-request-access-btn {
    .cr-icon-lock {
      color: variables.$grey900;
    }
  }
}
