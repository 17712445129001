@import "../../../_common/style/core/variables";

.stop-monitoring-modal {
  width: 500px;
  max-width: 500px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    width: 100%;
  }

  .info-banner {
    margin-top: 16px;
  }

  a {
    margin-bottom: 20px;
    text-transform: none !important;
  }
}
