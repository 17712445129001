@import "../../../../_common/style/core/variables";

.simple-logic-rules-step {
  .report-card {
    margin-top: 20px;

    .header {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 32px !important;
      justify-content: center;
      .info-section {
        margin-top: 12px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: $grey500;
      }
    }
    .body {
      padding: 30px;
    }

    .new-action {
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: transparent;
      border: dashed 1px $grey200;
      border-radius: 4px;
      margin-right: 26px;
      div {
        color: $grey600;
      }
      .cr-icon-plus {
        margin-right: 10px;
      }
      &:hover {
        background-color: $blue200;
      }
    }
    .wider {
      margin-right: 0px;
    }

    .rule-outer {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 12px;

      .drag-handle {
        margin-right: 10px;
        margin-top: 5px;
      }
    }
    .trash {
      margin-left: 5px;
      .cr-icon-trash-2 {
        font-size: 24px;
        color: $grey200;
      }
    }
    .clickable {
      cursor: pointer;
      .cr-icon-trash-2 {
        color: $grey400;
      }
      &:hover {
        .cr-icon-trash-2 {
          color: $red;
        }
      }
    }
    .error {
      border: solid 1px $red !important;
    }
    .select-error {
      border: solid 1px $red !important;
    }
    .question-select,
    .answer-select,
    .op-select {
      .ug-select__control {
        min-width: unset !important;
      }
      .op-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        div {
          margin-right: 7px;
          font-size: 16px;
        }
        .cr-icon-equals,
        .cr-icon-notequal,
        .cr-icon-contains,
        .cr-icon-prefix,
        .cr-icon-suffix {
          font-size: 18px !important;
          margin-left: -2px;
          margin-top: -1px;
        }
      }
      .selected {
        color: $white !important;
      }
    }

    .question-select,
    .answer-select {
      .ug-select__menu {
        z-index: 20;
      }
    }

    .op-select {
      .ug-select__menu {
        z-index: 20;
        min-width: 130px !important;
      }
    }

    .drop-target {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: dashed 1px $blue200;
      border-radius: 4px;
      background: transparent;
      height: 60px;
      margin: 10px;
      margin-left: 32px;
      margin-right: 42px;
      div {
        color: $grey400;
      }
    }
    .invisible {
      display: none;
    }
    .rule {
      flex: 1;
      padding: 20px;
      background-color: $blue50;
      color: $blue200;
      border: solid 1px $blue200;
      border-radius: 8px;

      .rule-index {
        color: $grey600;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        height: 22px;
        position: absolute;
      }

      .heading {
        color: $grey600;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        height: 22px;
      }

      .trunc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
      }

      .inner {
        width: 100%;
        display: grid;
        grid-template-columns: 80px 3fr 1fr;
        position: relative;

        .rule-operator {
          display: flex;
          flex-direction: row;
          position: relative;
          padding: 0px;
          width: 80px;
          margin-top: 20px;
          .half {
            flex: 1;
          }
          .box {
            flex: 1;
            border: 1px solid $blue200;
            border-right: none;
          }
        }
        .error-text {
          margin-top: 6px;
          font-weight: 500;
          font-size: 12px;
          color: #e02424;
          font-style: normal;
          .icon-info {
            margin-right: 3px;
          }
        }
        .rule-operator-sub {
          margin-top: 0px;
        }
        .rule-operator-overlay {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: absolute;
          .pill-label {
            background-color: $blue100 !important;
            text-transform: uppercase !important;
          }
        }
        .dismissible-popup-container {
          width: 100%;
        }

        .tier-name .cr-icon-minus-circle {
          width: unset;
        }

        .rows {
          flex: 1;
          width: 100%;
          display: flex;
          flex-direction: column;
          .row {
            width: 100%;
            display: grid;
            grid-template-columns: 32px 1fr 72px 1fr 42px;
          }
          .subrow {
            width: 100%;
            display: grid;
            grid-template-columns: 32px 1fr 72px 1fr 42px;
          }
          .branchrow {
            padding-top: 0px;
          }
          .drop-target-branch {
          }

          .col1 {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-bottom: 0px;
            padding-top: 8px;
            justify-content: center;
            grid-column-start: 1;
            grid-column-end: 1;
          }
          .col2 {
            grid-column-start: 2;
            grid-column-end: 2;
            margin-right: 10px;
          }
          .col3 {
            grid-column-start: 3;
            grid-column-end: 3;
            margin-right: 10px;
          }
          .col4 {
            grid-column-start: 4;
            grid-column-end: 4;
          }
          .col5 {
            grid-column-start: 5;
            grid-column-end: 5;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-top: 8px;
          }
          .all-cols {
            padding-top: 15px;
            grid-column-start: 2;
            grid-column-end: 5;
          }
          .col1-branch {
            padding-top: 20px;
            align-items: flex-start;
          }

          .normal {
            padding-bottom: 12px;
          }
          .noline {
            border-right: none;
            padding-right: 0px !important;
          }
          .withline {
            border-right: 1px solid $blue200 !important;
            padding-right: 20px;
          }
          .lowered {
            align-items: flex-start;
            padding-top: 16px !important;
          }
          .input-container {
            position: relative;
            width: 100%;
            align-items: center;
            min-height: 32px;
            min-width: 130px !important;
            span {
              padding-left: 10px;
              display: flex;
              height: 32px;
              align-items: center;
              color: $grey300;
            }
            .answer-input {
              position: absolute;
              width: 100%;
              height: 32px;
              border: solid 1px $grey100;
              border-radius: 4px;
              color: $grey900;
              padding-left: 10px;
              padding-right: 10px;
            }
            .noanswer-check {
              position: absolute;
              left: 100%;
              top: -5px;
              transform: translate(-110%, 6px);
              span {
                padding-left: 0px;
              }
            }
            .noanswer-check-readonly {
              left: 0px !important;
              transform: none;
            }
            .text-field {
              width: 100%;
              margin-bottom: 0px;
              .text-field-additional {
                min-height: 0px;
              }
            }
          }
          .input-container .color-checkbox {
            background-color: $yellow;
            padding: 0px !important;
          }

          .trash {
            width: 16px;
            padding-right: 36px;
            .cr-icon-trash-2 {
              font-size: 18px;
              color: $grey100;
            }
          }
          .clickable {
            cursor: pointer;
            .cr-icon-trash-2 {
              color: $grey400;
            }
            &:hover {
              .cr-icon-trash-2 {
                color: $red;
              }
            }
          }
        }
        .rowsline {
          border-right: 1px solid $blue200 !important;
        }
      }
      .buttons {
        position: absolute;
        display: flex;
        flex-direction: row;
        margin-top: 8px;
        margin-left: -10px;
        .button {
          border: 1px solid $blue200;
          width: 24px;
          height: 24px;
          border-radius: 50% !important;
          background-color: $white;
          padding: 0px !important;
          display: flex !important;
          flex-direction: row !important;
          align-items: center !important;
          justify-content: center !important;
          cursor: pointer;
          color: $grey300;
          &:hover {
            color: $blue500;
            .cr-icon-trash {
              color: $blue500;
            }
          }
        }
        .button:not(last) {
          margin-left: 5px;
        }
      }
      .action-extra {
        margin-top: 32px;
        border-top: solid 1px $blue200;
        padding-top: 20px;
      }
    }
    .rule-loading-overlay {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 5;
      background-color: transparent;
      opacity: 0.8;
    }

    .tier-dropdown {
      width: 100%;
      color: $grey900;
      .popup-content {
        width: 100%;
        right: unset !important;
        z-index: 20;
      }

      .popup-target .drop-button {
        width: 100%;
        text-align: left;
        border: solid 1px $grey100;
        color: inherit !important;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 2px;
        padding-right: 10px;
        .cr-icon-chevron {
          font-size: 12px;
          color: inherit;
        }
        &:hover {
          background-color: $blue100;
        }
      }
      .tier-name {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 32px;
        .name {
          color: $grey900;
          white-space: nowrap;
          padding-right: 10px;
          max-width: 400px;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
        .cr-icon-minus-circle {
          margin: 0px !important;
        }
      }
    }

    .tier-dropdown {
      background-color: $white;
      border-radius: 4px;
      .drop-button {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
    }

    .subrule {
      border: dashed 1px $blue200;
    }
    .subrule-inner {
      grid-template-columns: 80px 4fr !important;
    }
    .action {
      width: 100%;
      padding-left: 20px;

      .text-field {
        min-width: 280px;
      }

      .ug-select__menu {
        z-index: 20 !important;
      }

      .radioed-target {
        .icon-info {
          margin-left: 5px;
          color: $grey300;
        }
        .color-checkbox {
          margin-bottom: 5px;
        }
        .question-select,
        .portfolio-select {
          margin-top: 5px;
          margin-bottom: 5px;
          margin-left: 32px;
          min-width: 280px;
        }
        .sub-text {
          margin-left: 32px;
          color: $grey600;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
        .qn-help {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .error-text {
          margin-left: 32px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          .icon-info {
            color: $red;
          }
        }
        .text-field {
          margin-left: 32px;
          .error-text {
            margin-left: 0px;
          }
        }
      }
    }

    .rule:not(last) {
      margin-bottom: 20px;
    }
  }
}
