@import "../../../_common/style/core/variables";

.edit-vendor-contact-details {
  .input-separated {
    margin-top: 20px;
  }

  .label {
    text-align: left;
    font-weight: $font-weight-bold;
    margin-bottom: 4px;

    &.required:after {
      content: "*";
      color: $red500;
    }
  }

  .text-field {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}

.managed-vendor-contact-container {
  margin-top: $global-padding * 3;
  margin-bottom: 4px;
  width: 500px;

  .label-detail {
    padding-left: 30px;
    margin: 4px 0;

    .label-extra-detail {
      color: $grey300;
    }
  }

  .color-checkbox.blue {
    .label {
      color: $blue;
    }
  }
}
