@use "../../_common/style/core/variables";

.survey-viewer-intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h3 {
    font-weight: variables.$font-weight-bold;
    font-size: 28px;
    line-height: 38px;
    color: variables.$grey900;
    margin-bottom: 32px;
  }

  .logo {
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    img {
      max-height: 80px;
    }
  }

  h2 {
    font-weight: variables.$font-weight-bold;
    font-size: 28px;
    line-height: 38px;
    color: variables.$grey900;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    color: variables.$grey700;
  }

  .btn {
    margin-top: 8px;
  }

  .start-btn {
    margin-top: 24px;
  }

  .info-banner {
    width: 100% !important;
    text-align: left;
    margin: 0 0px 24px 0px !important;

    .info-banner-block {
      > p {
        max-height: 500px;
        overflow: auto;
      }
    }
  }

  .green-pulse {
    width: 24px;
    height: 24px;
    background: variables.$green500;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 24px;
    animation: animate-pulse 3s linear infinite;
    cursor: pointer;
  }
  @keyframes animate-pulse {
    0% {
      box-shadow:
        0 0 0 0 rgba(variables.$green500, 0.7),
        0 0 0 0 rgba(variables.$green500, 0.7);
    }
    40% {
      box-shadow:
        0 0 0 20px rgba(variables.$green500, 0),
        0 0 0 0 rgba(variables.$green500, 0.7);
    }
    80% {
      box-shadow:
        0 0 0 20px rgba(variables.$green500, 0),
        0 0 0 10px rgba(variables.$green500, 0);
    }
    100% {
      box-shadow:
        0 0 0 0 rgba(variables.$green500, 0),
        0 0 0 10px rgba(variables.$green500, 0);
    }
  }

  .autofill-available-banner {
    background: variables.$blue50;
    border-radius: variables.$card-border-radius;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 0;
    cursor: pointer;
    border: 1px solid transparent;
    width: 100%;

    &:hover {
      border-color: variables.$blue500;
    }

    & > div + div {
    }

    .banner-icon {
      display: flex;
      flex-direction: row;
      align-items: center;

      .cr-icon-book-sparkle {
        font-size: 24px;
        color: variables.$grey500;
      }
    }

    .banner-text {
      font-size: 12px;
      color: variables.$grey600;
      line-height: 175%;
      margin-left: 16px;
      margin-right: auto;

      .banner-text-header {
        font-size: 14px;
        color: variables.$grey900;
        font-weight: variables.$font-weight-bold;
        display: flex;
        flex-direction: row;
        align-items: center;

        .beta-label {
          margin-left: 10px;
        }
      }
    }

    .banner-chevron {
      //justify-self: flex-end;
      .cr-icon-chevron {
        color: variables.$grey500;
      }
    }
  }

  .import-banner-large {
    display: flex;
    flex-direction: row;
    padding: 16px 28px;
    border-radius: variables.$card-border-radius;
    background: variables.$blue50;
    cursor: pointer;
    border: 1px solid transparent;
    align-items: center;
    align-self: stretch;

    &:hover {
      border-color: variables.$blue500;
      border-radius: 4px;
    }

    .text {
      margin-left: 16px;
      color: variables.$grey900;
      //align-self: stretch;
    }

    .cr-icon-chevron,
    .cr-icon-upload {
      color: variables.$grey500;
    }

    .beta-label {
      margin-left: 12px;
    }

    .cr-icon-chevron {
      margin-left: auto;
    }
  }

  .import-banner-small {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;

    .btn {
      margin-top: 0;
      margin-left: 8px;
    }
  }

  .intro-banner {
    padding: 16px 24px;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;

    border-radius: 8px;
    /* shadow/level 1 */
    box-shadow:
      0px 4px 6px 0px rgba(64, 70, 104, 0.1),
      0px 2px 4px 0px rgba(64, 70, 104, 0.06);

    .outer {
      display: flex;
      flex-direction: column;

      .top-row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .right-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
    }

    &.recommended {
      border-left: 4px solid variables.$green400;
    }

    .text {
      margin-right: 8px;
    }

    &.warning {
      border-left: 4px solid variables.$orange500;
    }

    &:not(.disabled):hover {
      cursor: pointer;
      background-color: variables.$grey50;
    }

    &.disabled {
      opacity: 0.7;
    }

    [class^="cr-icon-"],
    [class*=" cr-icon-"] {
      font-size: 24px;
    }

    .text {
      margin-left: 24px;
      font-weight: variables.$font-weight-bold;
      line-height: 22px;
      color: variables.$grey900;
    }

    .chevron {
      font-size: variables.$font-size;
      margin-left: 8px;
    }

    .sub-text {
      margin-top: 8px;
      margin-left: 24px;
      line-height: 22px;
      color: variables.$grey900;
    }
  }

  .continue-btn {
    margin: auto;
  }
}
