@use "../../_common/style/core/variables";

.edit-logo-and-cover-modal {
  padding: variables.$space-lg variables.$space-xl variables.$space-xl !important;

  .edit-logo-and-cover-modal-inner {
    display: flex;
    flex-direction: column;
    gap: variables.$space-xl;

    > hr {
      width: 100%;
      border: 1px solid variables.$grey100;
    }

    .update-image-tile {
      display: grid;
      grid-template-columns: 250px 1fr;
      gap: variables.$space-xl;

      .image-description {
        display: flex;
        flex-direction: column;
        gap: variables.$space-sm;

        .header {
          font-size: variables.$font-size;
          font-weight: variables.$font-weight-bold;
        }

        .description,
        span {
          color: variables.$grey600;
          font-style: normal;
          font-weight: variables.$font-weight-regular;
          line-height: variables.$line-height;

          &.highlighted {
            font-weight: 700;
          }
        }

        .note {
          display: grid;
          grid-template-columns: auto 1fr;
          margin-top: variables.$space-sm;
          padding: variables.$space-sm;
          background: variables.$blue50;
          color: variables.$blue500;
          border-radius: variables.$card-border-radius;
          gap: variables.$space-xs;
          align-items: start;

          > div {
            color: variables.$blue500;
            font-size: variables.$font-size-smaller;
            line-height: variables.$line-height-small;

            > a {
              text-decoration: underline;
            }
          }

          > i {
            color: variables.$blue500;
            font-size: variables.$font-size-larger;
            padding: 2px;
          }
        }
      }

      .image-container {
        .preview-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: variables.$space-lg;
          gap: variables.$space-lg;

          max-height: 100px;

          border: 1px solid variables.$grey100;
          border-radius: variables.$card-border-radius;

          .trust-page-logo-card {
            height: 100%;
            width: 100%;
          }

          .file-details {
            flex-grow: 1;
          }

          .file-name {
            color: variables.$blue500;
            font-weight: variables.$font-weight-bold;
            word-wrap: break-word;
          }

          .file-uploaded-date {
            font-size: variables.$font-size-smaller;
          }

          .cover-image {
            height: 50px; // height and width based on design ration 2000 X 600 -> 10 /3
            width: 167px;
            border-radius: variables.$component-border-radius;
            box-shadow: variables.$box-shadow-4dp;
            overflow: hidden;

            > img {
              width: 100%;
              height: 100%;
              aspect-ratio: 10/3;
              object-fit: cover;
            }
          }
        }

        .upload-container {
          > span {
            color: variables.$grey500;

            font-size: variables.$font-size-extra-small;
            font-style: normal;
            font-weight: variables.$font-weight-regular;
            line-height: variables.$line-height-smaller;
          }
        }
      }
    }
  }
}
