@use "../../../_common/style/core/variables";

.correspondence-icon-btn {
  .message-count {
    color: variables.$grey400;
    font-size: 12px;
    font-weight: 500;
    min-width: 15px;
    text-align: left;
  }
}
