@use "../../_common/style/core/variables";

.trust-page-footer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 64px 0;
  text-wrap: wrap;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  .ug-logo {
    height: 22px;
  }
}
