@use "../../../../_common/style/core/variables";

.vip-card {
  .card-title-row {
    border-bottom: none !important;
    padding-bottom: 8px;
  }

  .card-subtitle-row {
    border-bottom: 1px solid variables.$light-grey;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 24px;

    .description {
      color: variables.$grey500;
      line-height: 150%;
    }
  }

  .notification-banner {
    margin-top: 24px;
    margin-bottom: unset;

    .info-banner-line {
      div {
        align-self: center;
      }

      .message {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 0;

        div {
          color: variables.$blue;
        }

        .banner-button {
          margin-right: 16px;
        }
      }

      .info-banner-actions {
        margin-left: 0;
      }
    }
  }
}
