@use "../../../_common/style/core/variables";

.tree-table {
  $row-height: 72px;

  .tree-row.child-content {
    border-bottom: none !important;
  }

  & > tr & td {
    height: $row-height;
  }

  // Add some overrides for when we don't want to fix the height of rows.
  &.variable-height-rows {
    /* NOTE:
        We don't want these styles to apply to a regular XTable within a TreeTable
     */
    & > table > tbody > tr {
      height: unset !important;
    }

    & > table > tbody > tr > td {
      height: unset !important;
      vertical-align: top;
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: 20px;

      & > .indent,
      & > div > .indent {
        height: unset;
      }

      &.tree-toggle-child-content-cell {
        padding-right: 0;
        border-top: none !important;
      }

      &.options-icons-cell {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    .tree-toggle-cell,
    .tree-toggle-child-content-cell {
      position: relative;
      padding-right: 30px;

      > .line-container {
        position: absolute;
        height: unset;
        bottom: -2px;

        .upper-line {
          bottom: calc(100% - 30px);
          height: 30px;
        }

        .lower-line {
          top: 30px;
          height: calc(100% - 30px);
        }
      }

      .toggle-circle {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 20px;
        height: unset;
        padding: 20px 0 0 0;
        margin: 0 0 0 0;

        .line-container {
          .lower-line {
            top: 30px;
            height: calc(100% - 30px);
          }
        }
      }
    }

    .sub-table-row > td {
      border-top: 0;
    }
  }

  &.sticky table thead tr th {
    z-index: 6;
  }

  .indent {
    display: inline-block;
    width: 20px;
    min-width: 20px;
  }

  td > .indent,
  td > div > .indent {
    height: $row-height;
    vertical-align: middle;
    //border-left: 1px solid $light-grey;
    border-right: 1px solid variables.$light-grey;
    margin-left: -19px;
    margin-right: 19px;
    background-color: variables.$canvas;
  }

  td.clickable .show-hide-children-cell:hover {
    color: variables.$blue;
  }

  $circle-width: 20px;

  .indent-blank,
  .indent-line {
    display: inline-block;
    width: $circle-width;
    height: $circle-width;

    &.half {
      width: $circle-width * 0.5;
    }
  }
  .child-content {
    width: 100%;
    display: inline-block;
  }

  .selection-box {
    min-width: 66px;
  }

  .indent-line {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      border-bottom: 1px solid variables.$grey100;
      width: 100%;
      top: 50%;
    }
  }

  .toggle-circle {
    position: relative;
    z-index: 1;
    display: inline-block;

    $clickable-area-size: 15px;
    width: $circle-width + ($clickable-area-size * 2);
    height: $circle-width + ($clickable-area-size * 2);
    padding: $clickable-area-size;
    margin: -$clickable-area-size;

    &.clickable:hover {
      cursor: pointer;

      .toggle-circle-circle {
        transform: scale(1.2);
        border-color: variables.$blue;
        color: variables.$blue;
      }

      .toggle-circle-text {
        transition: color 100ms ease-in-out;
        color: variables.$blue;
      }
    }

    .toggle-circle-circle {
      position: absolute;
      width: $circle-width;
      height: $circle-width;
      border-radius: 50%;
      border: 1px solid variables.$grey500;
      background-color: variables.$white;
      z-index: 1;
      pointer-events: none;

      transition-property: transform, border-color, color;
      transition-duration: 100ms;
      transition-timing-function: ease-in-out;
    }

    .toggle-circle-text {
      position: absolute;
      top: calc(50% - 1px); // Shave off 1px to account for the border
      left: 50%;
      transform: translate(-50%, -50%);
      color: variables.$grey500;
    }

    .line-container {
      left: 50%;
    }
  }

  .tree-toggle-cell > .line-container {
    position: relative;
    display: inline-block;
    height: $circle-width;
  }

  .tree-toggle-child-content-cell > .line-container {
    position: relative;
    display: inline-block;
    height: 100%;
  }

  .tree-toggle-child-content-cell > .line-container > .upper-line {
    position: absolute;
    width: 1px;
    background-color: variables.$grey100;
    left: 50%;
    z-index: 0;
    height: 50%;
  }

  .tree-toggle-child-content-cell > .line-container > .lower-line {
    position: absolute;
    width: 1px;
    background-color: variables.$grey100;
    left: 50%;
    z-index: 0;
    height: 50%;
  }

  .line-container {
    position: absolute;
    top: 0;
    bottom: 0;

    .upper-line,
    .lower-line {
      position: absolute;
      width: 1px;
      background-color: variables.$grey100;
      left: 50%;
      z-index: 0;
      height: ($row-height * 0.5) + 2px;
    }

    .upper-line {
      bottom: 50%;
    }

    .lower-line {
      top: 50%;
    }
  }

  .show-hide-children-cell {
    position: relative;
    width: 100%;
    height: $row-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .show-hide-children {
    position: absolute;
    left: 0px;
    top: calc(100% + 1px);
    pointer-events: none;

    .show-hide-children-inner {
      display: inline-block;
      font-size: 11px;
      text-transform: uppercase;
      padding: 2px 5px;
      color: variables.$medium-grey;
      transform: translateY(-100%);
      border: 1px solid variables.$light-grey;
      pointer-events: all;

      .icon-chevron {
        margin-right: 5px;
      }

      &:hover {
        cursor: pointer;
        &,
        .icon-chevron {
          color: variables.$blue;
        }
      }
    }
  }

  .sub-table-header {
    margin-top: -$row-height - 1px;
    position: sticky;
    background-color: variables.$white;

    tbody > tr > td {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        right: 0;
        border-bottom: 1px solid variables.$lighter-grey;
      }
      // border-bottom: 1px solid $light-grey !important;
    }

    &.indent-0 {
      top: 60px + ($row-height * 0);
      z-index: 5;
    }

    &.indent-1 {
      top: 60px + ($row-height * 1);
      z-index: 4;
    }

    &.indent-2 {
      top: 60px + ($row-height * 2);
      z-index: 3;
    }

    &.indent-3 {
      top: 60px + ($row-height * 3);
      z-index: 2;
    }

    &.indent-4 {
      top: 60px + ($row-height * 4);
      z-index: 1;
    }
  }

  &.include-tree-diagram .sub-table-header tbody > tr > td {
    // border-bottom: none !important;
  }

  .sub-table-row {
    & > .meatball-cell,
    & > .options-icons-cell,
    & > .selection-box {
      display: none;
    }
  }

  .sub-table-row tbody > tr:last-of-type > td {
    border-bottom: none;
  }

  .tree-toggle-cell {
    width: 1px;
    white-space: nowrap;
  }

  .tree-toggle-child-content-cell {
    width: 1px;
    white-space: nowrap;
  }

  .tree-child-content-cell {
    display: table-cell;
    width: auto;
    border-top: none !important;
  }

  // Make icon-option widths predictable for when rows and opened/closed
  .icon-option {
    [class^="cr-icon-"],
    [class*=" cr-icon-"] {
      width: 15px;
      display: inline-block;
    }
  }

  &.children-selection {
    .selection-box {
      min-width: 80px;
    }
  }
}
