@import "../../../_common/style/core/variables";

.modal-content-container:has(.add-document-modal) {
  .modal-header p,
  .modal-content p {
    margin-bottom: 0 !important;
  }

  .modal-content {
    padding: 0 $space-xl $space-lg !important;
  }
}
