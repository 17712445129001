@use "../../_common/style/core/variables";

.checkbox-multi-select-ub {
  .checkbox-option {
    padding: 3px 12px;
    width: 100%;
    user-select: none;
    cursor: default;

    .label {
      color: variables.$grey900;
    }

    &.no-value-option {
      color: variables.$blue500;
      font-style: normal;
      line-height: 24px;
      padding-left: 15px;
    }

    &:hover {
      background-color: variables.$blue50;
    }
  }

  .ug-select__value-container {
    min-width: 0;

    input {
      width: 1px;
    }

    .pill-label {
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }

  .ug_multi_value {
    padding-left: 8px;
  }

  .ug-select__multi-value {
    background-color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    min-height: 15px;
    flex-grow: 0;
    flex-basis: auto;

    &:not(:last-of-type) {
      flex-shrink: 0;
    }
  }

  .ug-select__control {
    min-width: 280px;
  }

  .ug-select__menu {
    min-width: 280px;
  }
}
