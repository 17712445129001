@use "../core/variables";

#report-vendor-monitor {
  .heading {
    font-weight: 600;
    margin-bottom: 12px;
  }
  p {
    color: variables.$grey600;
    margin-bottom: 20px !important;
  }
  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: variables.$grey500;

    .cr-icon-arrow-right {
      font-size: 18px;
      margin-left: 3px;
      color: variables.$grey500;
    }
    a {
      color: variables.$grey500;
      font-weight: 500 !important;
    }

    &:hover {
      text-decoration: none;
      a {
        color: variables.$blue;
        text-decoration: none;
      }
      .cr-icon-arrow-right {
        color: variables.$blue !important;
      }
    }
  }
}
