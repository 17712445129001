@use "../../_common/style/core/variables";

.excel-upload-modal {
  width: 600px;

  .multi-steps {
    margin-bottom: 24px;
  }

  .download-step {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .info {
      color: variables.$grey600;
      font-weight: variables.$font-weight-regular;
      font-size: 14px;
      line-height: 22px;
      margin-right: 12px;
    }

    .btn {
      .cr-icon-export {
        margin-left: 8px;
      }
    }
  }

  .upload-step {
    .upload-text {
      color: variables.$grey600;
      line-height: 22px;
    }

    .info-banner {
      margin-top: 24px;
    }
  }

  .success-step {
    display: flex;
    flex-direction: column;

    .cr-icon-check {
      color: variables.$blue500;
      border: 2px solid variables.$blue500;
      font-size: 22px;
      border-radius: 30px;
      padding: 12px;
      align-self: center;
      margin-bottom: 24px;
    }

    .body {
      color: variables.$grey600;
      line-height: 22px;

      ul {
        padding: 0 0 0 12px;

        li + li {
          margin-top: 8px;
        }
      }
    }
  }
}

.full-page-modal.v2
  .modal-content-container
  .excel-upload-modal-header
  .excel-upload-header {
  display: flex;
  flex-direction: row;
  align-content: center;

  .title {
    margin-right: 10px;
    padding: unset;
  }
}
