@import "../../../_common/style/core/variables";

.ip-panel-content {
  padding: 0 40px 24px 40px;

  .ip-address {
    font-size: 32px;
    margin-bottom: 40px;
  }

  .section-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .info-banner {
    margin-bottom: 40px;
  }

  .cloud-connection-row {
    .row-value-cell {
      padding-left: 10px; // To match host list
    }
    .provider-logo {
      margin-right: $space-xs;
    }
  }
}

.host-score-list {
  margin-bottom: 40px;

  .min-height-wrapper {
    min-height: 550px;
  }

  .host-score {
    display: flex;
    border-top: 1px solid $grey100;
    align-items: center;
    height: 55px;

    &:hover {
      background-color: $grey50;
      cursor: pointer;
    }

    .host-source {
      flex-grow: 1;
      display: flex;

      .host {
        width: 150px;
      }
    }

    .host {
      color: $blue;
      padding-left: 10px;
      line-height: 22px;
    }

    .host-only {
      flex-grow: 1;
    }

    .grade-score {
      display: flex;
      align-items: center;

      .color-grade {
        margin-right: 10px;
      }

      .cstar-text {
        font-size: 14px;

        .score-text {
          min-width: 30px;
        }
      }
    }

    i {
      font-size: 10px;
      color: $grey300;
      margin: 0 20px;
    }
  }

  .ug-paginate {
    margin-top: 15px;
  }
}
