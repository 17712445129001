@import "../../../_common/style/core/variables";

.empty-card {
  padding: 30px;
  background-color: $white;
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  display: flex;
  flex-direction: column;

  .empty-card-text {
    text-align: center;
    font-weight: $font-weight-regular;
    color: $medium-grey;
    font-style: italic;
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  .empty-card-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .btn + .btn {
      margin-left: 20px;
    }
  }
}
