@import "../../../_common/style/core/variables";

.slack-workspace-modal {
  width: 600px;

  .report-card {
    flex-grow: 1;
    margin-top: 0;
    padding: 0px;
    box-shadow:
      0 1px 2px rgba(64, 70, 104, 0.06),
      0 1px 3px rgba(64, 70, 104, 0.1);
    display: flex;
    flex-direction: row;

    .buttons-container {
      flex-grow: 1;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        height: 43px;
        width: 43px;
        flex-grow: 0;
      }
      .button-text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-left: 17px;
        justify-content: center;
        margin-right: 30px;

        .title {
          font-size: 16px;
          line-height: 150%;
          color: $grey900;
          font-weight: 500;
        }
      }
    }
  }
}

.jira-account-modal {
  width: 600px;

  .report-card {
    flex-grow: 1;
    margin-top: 0;
    padding: 0px;
    box-shadow:
      0 1px 2px rgba(64, 70, 104, 0.06),
      0 1px 3px rgba(64, 70, 104, 0.1);
    display: flex;
    flex-direction: row;

    .buttons-container {
      flex-grow: 1;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        height: 43px;
        width: 43px;
        flex-grow: 0;
      }
      .button-text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-left: 17px;
        justify-content: center;
        margin-right: 30px;

        .title {
          font-size: 16px;
          line-height: 150%;
          color: $grey900;
          font-weight: 500;
        }
      }
    }
  }
}

.jira-sites-selector-modal {
  width: 600px;
  .options {
    display: flex;
    flex-direction: column;
    width: fit-content;
    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      justify-content: flex-start;
      .color-checkbox {
        flex: 0;
      }
      .label {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
