@use "../core/variables";

#survey_details {
  .view-answers-btn {
    .cr-icon-arrow-right {
      margin-left: 4px;
      margin-right: 0;
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    .pill-label-content {
      display: flex;
      flex-direction: row;
      i {
        margin-left: 2px;
      }
    }
  }

  .tabs-n-toggle {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .tab-buttons-wrapper {
      flex: 1;
    }

    .btn {
      i {
        font-size: 20px;
      }
    }
  }

  .two-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    & > div + div {
      margin-top: 0;
    }

    & + div {
      margin-top: 20px;
    }

    &.no-stretch {
      align-items: flex-start;
    }
  }
}
