@use "../../_common/style/core/variables";

.threat-detail-card {
  display: flex;
  flex-direction: row;

  gap: 8px;

  .info-hover {
    display: inline;
    padding: 0 4px;
  }

  .reveal {
    cursor: pointer;
    color: variables.$blue;
  }

  span.secondary {
    color: variables.$grey500;
  }

  .two-column-display {
    gap: 8px;

    .two-column-display-row {
      padding: 0;
      padding-right: 16px;
      border: none;

      &.origin > div + div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .label-container .label .label-content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &.flex-row {
        div + div {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      > div + div {
        min-width: 0px;
        width: 100%;
        .ellipsised-url {
        }
      }
    }
  }

  .left,
  .right {
    width: 50%;
    max-width: 50%;
  }

  .left {
    border-right: 1px solid variables.$grey100;
  }
  .right {
    .two-column-display {
      padding-left: 16px;
    }
  }
}
