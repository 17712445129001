@use "../core/variables";

.survey-details-documents-card {
  tr:not(.pagination-row) {
    height: unset !important;

    td {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .question-cell {
    width: 35%;

    > div {
      left: 32px;
    }

    .node-type-icon {
      display: inline-flex;
      margin-right: 5px;
      vertical-align: -1.5px;
    }

    .question-text {
      display: inline;
      line-height: 24px;
    }
  }

  .description-cell {
    width: 25%;
  }
}
