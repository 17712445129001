.grade-with-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  .color-grade {
    margin-right: 5px;
  }

  .score.small {
    line-height: 21px;
  }
}
