.full-page-modal .modal-content.s3-objects-modal {
  max-width: 900px;
  padding: 50px 100px;

  img {
    align-self: center;
  }
  .rc-tree {
    width: 700px;
  }

  .rc-tree li .rc-tree-node-content-wrapper {
    width: 100%;
  }

  .leaf {
    position: relative;
    width: 100%;
    display: inline-block;
  }

  .leafSize {
    position: absolute;
    right: 100px;
  }

  .leafModified {
    position: absolute;
    right: 0px;
  }
}
