@import "../../../_common/style/core/variables";

.edit-questionnaire-automation {
  .actions {
    .action-bar {
      display: flex;
      flex-direction: row;
      align-items: center;

      .cr-icon-play {
        font-size: 24px;
        margin-top: 2px;
      }

      .left {
        flex: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        justify-content: flex-start;

        .go-back-link {
          margin: auto 8px;
          white-space: nowrap;
          .icon-arrow {
            color: $blue;
          }
        }
      }
      .middle {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        white-space: break-spaces;
      }
      .right {
        flex: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .color-checkbox {
          margin-left: 10px;
        }

        .cancel-link {
          margin-left: auto;
          margin-top: auto;
          margin-bottom: auto;
          white-space: nowrap;
          color: $grey500;
        }
        .next {
          margin-left: 16px;
          .icon-arrow {
            margin-left: 4px;
          }
        }
        .prev {
          margin-right: 16px;
          .icon-arrow {
            margin-right: 4px;
          }
        }
        .save-as {
          margin-left: 16px;
          .icon-save {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
