@import "../../../_common/style/core/variables";

.typosquat-ignore-modal {
  width: 700px;

  .cols {
    display: flex;
    flex-direction: row;

    .update-radio {
      width: 400px;

      .color-checkbox {
        margin-bottom: 20px;
        align-items: flex-start;
        display: flex;
        flex-direction: row;

        .label {
          display: block;
          word-wrap: break-word;
          white-space: normal;
          text-align: start;
          margin-left: 10px;
        }
      }

      .pill-desc {
        margin-top: 5px;
      }

      .apply-button {
        width: 100px;
      }
    }
  }

  .cr-icon-noeye {
    font-size: 20px !important;
    font-weight: $font-weight-regular !important;
    letter-spacing: 1px;
  }

  .desc-text {
    color: $grey500;
    padding: 30px;
    padding-top: 10px;
  }

  .desc-body {
    display: flex;
    flex-direction: row;
    border-top: 1px solid $grey100;
    height: 150px;
    padding: 30px;

    .desc-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      .heading {
        font-weight: $font-weight-bold !important;
        color: $primary-black;
        font-size: 1.1em;
        margin-bottom: 10px;
      }
      .body {
        font-weight: $font-weight-regular !important;
        color: $grey500;
      }
    }
    .desc-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      .option {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        .color-checkbox {
          width: 40px !important;
        }
        .label {
          padding-right: 10px;
          cursor: pointer;
        }
        .cr-icon-info {
          font-weight: $font-weight-regular !important;
          letter-spacing: 1px;
        }
      }
    }
  }

  .modal-footer {
    padding: 30px !important;
    padding-top: 10px !important;
  }

  .center-text {
    text-align: center;
  }

  button {
    margin-top: 20px !important;
  }

  .button-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 100%;

    .button-cell {
      width: 180px !important;
      min-width: 180px !important;
      max-width: 180px !important;
    }

    .desc-cell {
      margin-left: 10px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: start;
    }
  }
}
