@import "../../../../_common/style/core/variables";

.time-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;

  & > div {
    padding: 6px 16px;
    color: $grey500;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    border: 1px solid $grey200;
    margin: 0;
    cursor: pointer;
    white-space: nowrap;
    letter-spacing: 1px;
    font-feature-settings: "liga" off;

    &:not(:first-of-type) {
      transform: translateX(-1px);
    }

    &:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      transform: translateX(-3px);
    }

    &.active,
    &:hover {
      color: $blue500;
      border-color: $blue500;
      z-index: 1;
      background-color: $blue50;
    }
  }
}
