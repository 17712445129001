@import "../../_common/style/core/variables";
@import "../styles/variables";

.threat-monitoring-filter-panel {
  border-right: 1px solid $grey100;
  flex: none;

  &.expanded {
    width: 280px;
  }

  &.collapsed {
    width: 49px;
  }

  .header {
    min-height: unset !important;
    height: $threat-monitoring-view-header-height + 1;

    // Add 1 to align with table headerer in feed content

    &.expanded {
      border-bottom: 1px solid $grey100;
      padding: 8px 16px 0 24px;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &.collapsed {
      border-bottom-left-radius: $threat-monitoring-view-report-card-border-radius;
      padding: 16px 8px 0 8px;
      border: 0;

      display: flex;
      flex-direction: column;
      align-items: center;

      height: 100%;

      cursor: pointer;

      &:hover {
        background-color: $blue100;
        box-shadow: 0px 0px 0 1px $blue100;

        .btn-icon [class^="cr-icon-"] {
          color: $blue500;
        }

        .collapsed-title {
          color: $blue500;
        }
      }
    }

    .title {
      height: 50px;

      align-items: center;
      display: flex;

      h5 {
        color: $grey900;
        font-size: $font-size-larger;
        font-weight: $font-weight-bold;
      }

      .cr-icon-filter {
        color: $grey900;
        font-size: $font-size-larger;
        margin-right: 10px;
      }
    }

    .collapsed-title {
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      color: $grey500;
      font-size: $font-size-smaller;

      margin-top: 8px;
    }

    .dismissible-popup-container {
      margin-left: auto;

      .btn-icon:hover:not(.disabled) > * {
        color: $blue500;
      }

      .btn-icon [class^="cr-icon-"] {
        color: $grey500;
      }
    }

    .active-filters-circle {
      margin-top: 8px;

      width: 8px;
      height: 8px;

      background-color: #ff9901;
      border-radius: 50%;

      flex: none;
    }
  }

  .content {
    padding: 8px 24px 24px 24px;

    .refresh-filters-button {
      * {
        color: inherit;
      }

      *:hover {
        color: inherit;
      }

      &:hover {
        color: $blue500;
      }

      color: $grey500;
      margin-bottom: 16px;

      .btn {
        height: unset;
        padding: 0;

        &:disabled {
          color: $grey200;
        }
      }

      .btn-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .icon-refresh {
          margin-right: 8px;
          font-size: $font-size-larger;
        }
      }
    }

    .filters-container {
      padding-top: 8px;

      .filter-container {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }

        .title,
        .clickable-title {
          color: $grey900;
          font-size: $font-size;
          font-weight: $font-weight-bold;
          margin-bottom: 8px;
        }

        .clickable-title {
          display: flex;
          flex-direction: row;
          cursor: pointer;

          &:hover {
            color: $blue500;
          }

          * {
            color: inherit;
          }

          *:hover {
            color: inherit;
          }

          .icon-chevron {
            margin-left: auto;
            font-size: $font-size-larger;
          }
        }

        .color-checkbox {
          padding: 4px;

          .label {
            display: flex;
            flex-direction: row;
            justify-content: left;
            width: 180px;
            color: $grey900;

            &:hover {
              color: $blue500;
            }

            span {
              color: inherit;
            }

            .dismissible-popup-container {
              width: 100%;
            }

            .keyword-label {
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              text-align: left;
            }
          }

          .severity-icon {
            height: 16px;

            img {
              height: 100%;
            }
          }

          .icon-chevron {
            margin-left: 12px;
          }
        }

        .nested-filter-option {
          .section-body {
            display: flex;
            flex-direction: row;
          }

          .nested-filter-option-spacer {
            width: 16px;
            flex: none;
          }
        }

        .loading-filter-option {
          background-color: $grey100;
          width: 180px;
          height: 20px;

          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

          @keyframes pulse {
            0%,
            100% {
              opacity: 1;
            }
            50% {
              opacity: 0.5;
            }
          }
        }

        .avatar-and-label-option {
          display: flex;
          flex-direction: row;
          align-items: center;

          .label {
            margin-left: 8px;
            color: $grey900;
          }

          svg {
            stroke: $grey500;
            color: $grey500;
          }

          .circled-icon {
            background-color: $white;

            width: 24px;
            min-width: 24px;

            height: 24px;
            min-height: 24px;
            max-height: 24px;
            margin: 0;
            margin-left: 2px;

            div {
              width: 24px;
              height: 24px;
              font-size: 17px;

              color: $grey900;
              border-color: $grey900;
              border: 1px;
              border-radius: 50%;
              border-style: solid;
              padding: 3px;
            }
          }
        }

        .text-filter-label {
          color: black;
          font-size: $font-size-smaller;
          margin-bottom: 4px;
        }

        .text-field {
          margin-bottom: 16px;
        }
      }

      .filter-separator {
        border-bottom: 1px solid $grey100;
        margin: 16px 0;
      }
    }
  }

  .ug-select .ug-select__control {
    min-width: unset;
    width: 100%;
  }

  .no-display {
    display: none !important;
  }
}
