@use "../../../_common/style/core/variables";

.user-base-user-risk-profile {
  table {
    th {
      &.category-col {
        width: 200px;
      }
      &.detected-cell {
        width: 124px;
      }
      &.sites-col {
        width: 128px;
      }
      &.status-col {
        width: 120px;
      }
      &.actions-col {
        width: 140px;
      }
    }
    td {
      &.category-cell {
        width: 200px;
      }
    }
  }
}
