@import "../core/variables";

#report-vendor-selector {
  .search {
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    .search-box-input-container {
      flex: 1;
      margin-right: 5px;
    }
    .show-button {
      width: 150px;
    }
  }

  .showing {
    margin-left: 30px;
    margin-top: 32px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
  }
  .scroller {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    overflow-y: auto;
    height: 400px;
    max-width: 700px;
  }

  table tbody tr.pagination-row {
    .disabled {
      border: 0px;
      background-color: transparent !important;
    }
  }

  .vendor-cell {
    width: 100%;
    .name {
      color: $grey900;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 3px;
      overflow-wrap: break-word;
    }
    .domain {
      color: $grey500;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      overflow-wrap: anywhere;
    }
  }

  .score-cell {
    max-width: 100px;
    min-width: 100px;
    img {
      margin-left: 0px !important;
    }
  }

  .labels-cell {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .score-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    .cstar-text {
      margin: 0px !important;
    }

    .color-grade {
      align-self: center;
      margin: 8px;
    }
  }
}
