@use "sass:color";
@use "../core/variables";

$avatar-size: 25px;
$private-yellow: rgb(245, 231, 140);

.message-input {
  position: relative;
  margin-bottom: 20px;

  textarea {
    width: 100%;
    max-width: 100%;
    background-color: variables.$white;
    transition: background-color 100ms ease-in-out;
    resize: none;
    line-height: 1.4;
    padding-bottom: 24px;

    &:disabled {
      background-color: variables.$canvas;
    }

    &.private {
      box-shadow: inset 0 0 0 1px $private-yellow !important;
      background-color: color.adjust($private-yellow, $lightness: 20%);
    }
  }

  .message-input-actions {
    position: absolute;
    bottom: 12px;
    right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    &.active div {
      transform: translateY(0);
    }

    div {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 11px;
      color: variables.$blue;
      cursor: pointer;
      transition: transform variables.$animation-timing ease-in-out;
      transform: translateY(20px);

      &:hover {
        color: color.adjust(variables.$blue, $lightness: -10%);
      }
    }

    div:not(:last-of-type) {
      margin-right: 20px;
    }

    .secondary {
      color: variables.$medium-grey;
    }

    .disabled {
      pointer-events: none;
      cursor: default;
    }
  }

  .private-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 1;
    transform: translateY(1px);

    + .text-field {
      margin-top: 1px;
    }

    .option {
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-top: 1px solid variables.$light-grey;
      padding: 5px;
      cursor: pointer;
      color: variables.$light-grey;

      &.first {
        border-left: 1px solid variables.$light-grey;
        border-right: 1px solid transparent;
        border-top-left-radius: 5px;
      }

      &.second {
        border-right: 1px solid variables.$light-grey;
        border-left: 1px solid transparent;
        border-top-right-radius: 5px;
        transform: translateX(-1px);
      }

      &:hover,
      &.selected {
        color: variables.$primary-black;

        &.first {
          border-right: 1px solid variables.$light-grey;
        }

        &.second {
          background-color: $private-yellow;
          border-left-color: $private-yellow;
          border-top-color: $private-yellow;
          border-right-color: $private-yellow;

          .question-mark {
            box-shadow: inset 0 0 0 1px variables.$medium-grey;
          }
        }
      }

      .question-mark {
        width: 15px;
        height: 15px;
        line-height: 15px;
        font-size: 11px;
        box-shadow: inset 0 0 0 1px variables.$light-grey;
        border-radius: 50%;
        text-align: center;
        color: variables.$medium-grey;

        &:hover {
          box-shadow: inset 0 0 0 1px variables.$medium-grey;
        }
      }
    }
  }

  &.new-styles {
    .message-input-actions {
      display: none;
    }

    .message-input-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 50px;
      padding-bottom: 12px;

      button + button {
        margin-left: 20px;
      }
    }

    .tab-buttons-wrapper {
      margin-bottom: 12px;
    }
  }
}
