@use "../../_common/style/core/variables";

.trust-page-header {
  --logo-size: 220px;
  --company-details-spacing-horizontal: #{variables.$space-lg};
  --header-spacing-vertical: 0;

  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;

  .trust-page-logo-card {
    width: var(--logo-size);
    height: var(--logo-size);
    margin-top: calc(-1 * var(--logo-size) / 2);
    align-self: start;
  }

  @media all and (max-width: 1200px) {
    --header-spacing-vertical: #{variables.$space-lg};
    --company-details-spacing-horizontal: 0;

    // move company info under the logo tile
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .company-details-wrapper {
      align-self: start;
      width: 100%;
    }
  }

  // mobile screens - change layout
  @media screen and (max-width: 800px) {
    --logo-size: 140px;

    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .trust-page-logo-card,
    .company-details-wrapper {
      align-self: unset;
    }
  }

  // mobile screens - change layout
  @media screen and (max-width: 640px) {
    --logo-size: 140px;
  }

  .company-details-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-left: var(--company-details-spacing-horizontal);
    margin-top: var(--header-spacing-vertical);
    align-self: end; // bottom of the grid

    .vendor-title {
      padding-bottom: variables.$space-sm;
      > span,
      .trust-page {
        color: variables.$grey900;
        font-size: 36px;
        line-height: 150%;
        letter-spacing: -0.396px;
        text-align: left;
      }

      .trust-page {
        text-wrap: nowrap;
        color: variables.$grey400;
      }

      .trust-page::before {
        content: "|";
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    .inner-content {
      display: flex;
      justify-content: center;
      align-items: center;

      .contact-details {
        display: flex;
        gap: variables.$space-sm;
        color: variables.$grey600;
        flex-wrap: wrap;

        > * {
          margin: 0 variables.$space-sm;

          > i {
            padding-right: variables.$space-sm;
          }
        }
        a,
        i {
          color: variables.$grey600;
          text-decoration: none;

          span:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .request-access-btn {
      margin-top: auto;
      margin-left: auto;
      background-color: variables.$grey900;
      border: 1px solid variables.$grey900;

      &:hover {
        background-color: variables.$grey800;
        border: 1px solid variables.$grey800;
      }
    }

    @media all and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;

      .vendor-title {
        font-size: variables.$font-size-xx-large;
        margin: 0 auto;
        text-align: center;

        > span {
          font-size: 24px;
        }

        .trust-page {
          display: block;
          margin: 0 auto;
          text-align: center;
          font-size: variables.$font-size-extra-large;
          font-weight: variables.$font-weight-regular;
          line-height: 150%;
          letter-spacing: -0.176px;
        }

        .trust-page::before {
          content: "";
          margin: unset;
        }
      }

      .inner-content,
      .contact-details {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
      }

      .request-access-btn {
        margin-top: var(--header-spacing-vertical);
        width: 100%;
        height: 50px;
      }
    }
  }
}
