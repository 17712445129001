@use "../../../_common/style/core/variables";

.score {
  p {
    margin: 0;
  }

  .cstar-text {
    font-size: 2rem;

    .score-text {
      display: inline-block;
      min-width: 56px;
      transition: color variables.$animation-timing ease;
    }

    .score-out-of {
      font-size: 0.3em;
      color: variables.$medium-grey;
    }
  }
}

.large .score,
.score.large {
  .cstar-text {
    font-size: 4rem;
    .score-text {
      min-width: 110px;
    }
  }
}

.small .score,
.score.small {
  display: flex;
  align-items: center;

  .cstar-text {
    font-size: 14px;
    .score-text {
      min-width: 26px;
    }
    .score-out-of {
      font-size: 14px;
    }
  }
}
