@import "../core/variables";

.search-box-input-container {
  .search-box-input {
    position: relative;
    display: flex;
    flex: 1;

    .search-icon {
      position: absolute;
      left: 8px;
      top: 6px;
    }

    input {
      padding-left: 36px;
      padding-right: 24px;
      border-radius: $component-border-radius;
      height: 32px;
      display: flex;
      flex: 1;
      max-width: unset;
    }

    .btn {
      position: absolute;
      right: 0px;
      z-index: 1;

      .icon-close {
        font-size: 16px;
      }
    }
  }
}
