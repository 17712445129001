@use "../../../_common/style/core/variables";

.remediation-details-export-options {
  .risks-subsection {
    margin-left: 32px;
  }

  .seperator {
    width: 80%;
    border-top: 1px solid variables.$grey200;
    margin-bottom: 12px;
  }
}
