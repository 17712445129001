@use "../../../../_common/style/core/variables";

.check-panel {
  .slide-panel {
    width: 700px !important;

    .title {
      padding-right: variables.$space-lg;
      border-bottom: none !important;
    }
  }

  .check-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: variables.$space-xl;

    .title {
      font-weight: normal;
      font-size: variables.$font-size-extra-large !important;
    }

    .manage-risk-text {
      margin-right: variables.$space-sm;
    }
  }

  .check-item {
    .slide-panel-section {
      border-top: 0;
      margin-left: 0;

      .section-header {
        padding-top: variables.$space-md;
        padding-bottom: variables.$space-md;
      }

      .section-body {
        margin-top: 0;
        padding-bottom: 0;
        padding: variables.$space-sm;
        margin-left: 0;
      }

      p {
        margin-top: 0;
        margin-left: variables.$space-xl;
        margin-right: variables.$space-xl;
        line-height: variables.$line-height;
      }
    }
  }

  .detail-table {
    margin-top: 0;
    margin-bottom: variables.$space-lg;
    margin-left: variables.$space-lg;
    margin-right: variables.$space-lg;

    .detail-row {
      border-bottom: 1px solid variables.$grey100;
    }

    .row-label {
      font-size: variables.$font-size-extra-small;
      font-weight: variables.$font-weight-regular;
      color: variables.$grey500;
      line-height: variables.$line-height;
    }

    .row-value {
      margin-left: variables.$space-xl;
      font-weight: variables.$font-weight-regular;
      line-height: variables.$line-height;
      color: variables.$grey900;
    }

    td {
      vertical-align: middle;
      padding-left: variables.$space-sm;
      padding-right: 0;
      padding-top: variables.$space-md;
      padding-bottom: variables.$space-md;
    }
  }

  .domains-container {
    padding: 0 variables.$space-xl;
  }

  .back-arrow {
    top: 25px;
    left: 40px;

    .popup {
      left: 30px;
      top: 0;
    }
  }

  .loading-overlay {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    bottom: -200px;
  }

  .risk-assessment-comment {
    padding: 0 variables.$space-xl;
  }

  &.ignored {
    .check-header {
      .title {
        opacity: 0.2;
      }
    }

    .check-item {
      opacity: 0.2;
    }
  }
}

.compensating-controls-container {
  margin: 0 variables.$space-lg;
}

.citations-table {
  line-height: variables.$line-height;

  .citation {
    padding-top: variables.$space-md;
    padding-bottom: variables.$space-md;

    .document {
      .source {
        font-size: variables.$font-size-smaller;
        color: variables.$grey500;
      }

      a.name {
        border-bottom: 1px solid;

        &:hover {
          text-decoration: none;
        }

        &:visited {
          color: inherit;
        }
      }
    }

    .citation-content {
      padding: variables.$space-sm;
      margin-top: variables.$space-sm;
      margin-bottom: variables.$space-sm;
    }

    .extract,
    .answer {
      @extend .citation-content;
      background-color: variables.$grey50;
      border: 1px solid variables.$grey100;
    }

    .question {
      @extend .citation-content;
      display: flex;
    }

    .answer,
    .question {
      display: flex;
    }

    .label {
      flex-grow: 0;
      margin-right: variables.$space-sm;
    }

    .text {
      flex-grow: 1;
    }
  }

  .dropdown-item {
    width: 200px;
    height: auto;
  }

  .dropdown-with-text {
    width: 100%;
    text-align: left;
    white-space: normal;

    .dropdown-title {
      margin-bottom: variables.$space-sm;
      font-weight: variables.$font-weight-bold;
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        color: variables.$grey900;
      }
    }

    .dropdown-text {
      color: variables.$grey500;
    }
  }
}
