@use "../../../_common/style/core/variables";

.edit-shared-profile-modal {
  .scores {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;

    > div + div {
      margin-left: 40px;
    }

    > div.greyed {
      opacity: 0.3;
      filter: grayscale(100%);
    }

    .score-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;

      .cstar-text {
        margin-left: 8px;
        font-size: 48px !important;
        line-height: 1.15 !important;
      }

      .score {
        .label-text {
          margin-top: 0 !important;
        }
      }
    }

    .score-subheader {
      color: variables.$grey900;
      font-weight: variables.$font-weight-bold;
      padding-bottom: 12px;
    }
  }

  .custom-logo {
    margin-left: 30px;
    margin-top: 18px;

    .loading-tight {
      height: 84px;
    }

    .dragdropupload {
      height: 84px;
    }

    .custom-logo-desc {
      margin-top: 4px;
      color: variables.$grey500;

      .cr-icon-info {
        color: inherit;
        margin-right: 4px;
      }
    }

    .preview {
      width: 100%;
      height: 84px;
      border: 1px solid #ecedf3;
      border-radius: 8px !important;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .delete-image {
        margin-right: 21px;
      }

      .png-preview {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        background-color: variables.$white;
        background-size: contain;
        background-position: center;
        display: block;
        background-repeat: no-repeat;

        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -17.5px;
        margin-top: -17.5px;
      }
    }
  }
}

.full-page-modal.v2 .modal-content-container .edit-shared-profile-modal {
  width: 920px;
}
