@use "../core/variables";

$_size2dp: 2px 10px;
$_size4dp: 4px 14px;
$_size8dp: 8px 16px;
$_size16dp: 16px 32px;
$_size24dp: 24px 48px;

$_alpha2dp: 0.07;
$_alpha4dp: 0.1;
$_alpha8dp: 0.13;
$_alpha16dp: 0.16;
$_alpha24dp: 0.19;

@function _shadow($size, $color, $alpha) {
  @return 0 $size rgba($color, $alpha);
}

// Grey
$box-shadow-grey-2dp: _shadow($_size2dp, variables.$grey100, $_alpha2dp);

$box-shadow-grey-4dp: _shadow($_size4dp, variables.$grey100, $_alpha4dp);

$box-shadow-grey-8dp: _shadow($_size8dp, variables.$grey100, $_alpha8dp);

$box-shadow-grey-16dp: _shadow($_size16dp, variables.$grey100, $_alpha16dp);

$box-shadow-grey-24dp: _shadow($_size24dp, variables.$grey100, $_alpha24dp);

// Blue
$box-shadow-blue-2dp: _shadow($_size2dp, variables.$blue100, $_alpha2dp);

$box-shadow-blue-4dp: _shadow($_size4dp, variables.$blue100, $_alpha4dp);

$box-shadow-blue-8dp: _shadow($_size8dp, variables.$blue100, $_alpha8dp);

$box-shadow-blue-16dp: _shadow($_size16dp, variables.$blue100, $_alpha16dp);

$box-shadow-blue-24dp: _shadow($_size24dp, variables.$blue100, $_alpha24dp);

// Red
$box-shadow-red-2dp: _shadow($_size2dp, variables.$red, $_alpha2dp);

$box-shadow-red-4dp: _shadow($_size4dp, variables.$red, $_alpha4dp);

$box-shadow-red-8dp: _shadow($_size8dp, variables.$red, $_alpha8dp);

$box-shadow-red-16dp: _shadow($_size16dp, variables.$red, $_alpha16dp);

$box-shadow-red-24dp: _shadow($_size24dp, variables.$red, $_alpha24dp);

// Green
$box-shadow-green-2dp: _shadow($_size2dp, variables.$green, $_alpha2dp);

$box-shadow-green-4dp: _shadow($_size4dp, variables.$green, $_alpha4dp);

$box-shadow-green-8dp: _shadow($_size8dp, variables.$green, $_alpha8dp);

$box-shadow-green-16dp: _shadow($_size16dp, variables.$green, $_alpha16dp);

$box-shadow-green-24dp: _shadow($_size24dp, variables.$green, $_alpha24dp);
