@use "../core/variables";

.dropdown-button {
  display: block;

  .dropdown-item {
    height: unset;
    margin: 8px;
    border-radius: 4px;
    padding: 4px 8px;

    .dropdown-button-dropdown-item-content {
      padding: 4px;
      line-height: 22px;
      font-size: 14px;

      .header-text {
        color: variables.$grey900;
        font-weight: variables.$font-weight-bold;
        margin-bottom: 4px;
      }

      .description {
        color: variables.$grey600;
        margin-top: 4px;
      }
    }

    &:hover {
      .dropdown-button-dropdown-item-content {
        .header-text {
          color: variables.$blue500;
        }
      }
    }
  }
}
