@use "../../../_common/style/core/variables";

.saas-user-risk-panel {
  .title-and-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 variables.$space-xxl variables.$space-lg;

    .saas-app-title {
      margin-right: auto;
      font-size: variables.$font-size-extra-large;
      font-weight: variables.$font-weight-bold;
    }

    .saas-user-title-buttons {
      margin-left: auto;
    }
  }

  .score-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  .saas-user-risk-panel-apps-table {
    table th:first-of-type,
    table td:first-of-type {
      padding-left: 0px;
    }
  }
}
