@import "../../core/variables";

$toggle-switch-bit-size: 12px;
$toggle-switch-height: 16px;
$toggle-switch-spacing: 1px;
$toggle-switch-width: 32px;

.toggle-switch {
  display: flex;
  position: relative;
  min-width: $toggle-switch-width;
  border-radius: 10px;
  outline: none;

  &.disabled {
    pointer-events: none;

    & > label {
      pointer-events: none;
      background: $grey50 !important;
      border-color: $grey100 !important;
    }

    .toggle-bit {
      background: $grey200 !important;
    }
  }

  &:focus-visible {
    > label {
      box-shadow: 0 0 0 2px $blue200;
    }
  }

  & > label {
    border-radius: 10px;
    cursor: pointer !important;
    height: $toggle-switch-height;
    position: relative;
    width: $toggle-switch-width;
    min-width: $toggle-switch-width;
    background: $white;
    border: 1px solid $grey200;
    outline: none;

    transition: all $animation-timing ease-in-out;
  }

  &:hover > label {
    border-color: $blue;
  }

  & > [type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  & > label > .toggle-bit {
    transition: all ease-in-out $animation-timing;
    background: $blue;
    height: $toggle-switch-bit-size;
    width: $toggle-switch-bit-size;
    border-radius: $toggle-switch-bit-size * 0.5;
    left: $toggle-switch-spacing * 2;
    position: absolute;
    top: $toggle-switch-spacing;
    z-index: 1;
  }

  & > [type="checkbox"] {
    background-color: red;
    &:focus-visible {
      opacity: 1;
    }
  }

  & > [type="checkbox"]:checked + label {
    background: $blue;
    border-color: $blue;

    &:hover {
      background: $blue700;
      border-color: $blue700;
    }
  }
  & > [type="checkbox"]:checked + label > .toggle-bit {
    background: white;
    left: $toggle-switch-width -
      ($toggle-switch-bit-size + ($toggle-switch-spacing * 3));
  }
}
