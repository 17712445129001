.nda-signing-modal {
  min-width: 500px;
  max-width: 900px !important;

  .rich-text-edit-v2 {
    max-width: unset !important;

    .editor-inner {
      max-height: 400px !important;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.nda-signing-modal-footer {
  justify-content: space-between !important;

  .qualifier {
    max-width: 500px;
    margin: 0 36px 0 0;
    font-size: 10px;
    line-height: 150%;
  }
}
