@use "../../../_common/style/core/variables";

.shared-profile-free-org-user-list {
  .user-cell {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    .pill-label {
      margin-left: 16px;
    }
  }

  .ug-select .ug-select__control {
    min-width: 120px;
  }
}

.shared-profile-free-org-invite-user-modal {
  .invites-list {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .text-field {
        flex: 1;
      }

      .ug-select {
        margin-left: 16px;
      }
    }

    .ug-select .ug-select__control {
      min-width: 140px;
      width: 140px;
    }
  }
}
