@use "../../../_common/style/core/variables";

.my-shared-profile-settings {
  .report-card {
    .header {
      justify-content: space-between;

      .toggle-with-label {
        display: flex;
        align-items: center;

        .toggle-label {
          font-weight: variables.$font-weight-bold;
          font-size: 14px;
          line-height: 21px;
          color: black;
          min-width: 28px;
        }
      }
    }

    .report-card-content {
      .nda-preview-button-container {
        margin-top: 22px;
        margin-bottom: 22px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          margin-left: 24px;
        }
      }

      p {
        color: variables.$grey600;
        margin: 0;
        line-height: 150%;

        &.qualifier {
          margin-top: 10px;
          font-size: 10px;
        }
      }

      .report-card-section {
        border-bottom: 1px solid variables.$grey100;

        &:last-of-type {
          border-bottom: none;
        }

        p {
          margin: variables.$space-sm 0 0 38px;
        }
        padding-bottom: 24px;

        &.controls {
          display: flex;
          justify-content: right;
          padding-bottom: 0;

          .tooltip-button {
            margin-left: variables.$space-md;
          }
        }
      }

      .report-card-section + .report-card-section {
        margin-top: variables.$space-lg;
      }
    }
  }
}
