@use "../core/variables";

.btn-icon-popup {
  display: inline-block;
  vertical-align: middle;
}

.btn-icon {
  background: none;
  //color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  //cursor: pointer;
  outline: inherit;
  position: relative;

  height: 32px;
  width: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: variables.$grey300;
  border-radius: 4px;
  cursor: pointer;

  transition-property: background-color;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;

  &.has-text {
    width: unset;
    padding: 0 4px;
  }

  > * {
    fill: variables.$grey300;
    color: variables.$grey300;
    cursor: pointer;

    transition-property: color;
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;

    transition: transform 200ms ease-in;

    font-size: 24px;
  }

  .btn-text {
    font-size: 14px;
    margin-left: 6px;
    font-family: Inter, "Inter-Fallback", Helvetica, Arial, sans-serif;
  }

  &:hover:not(.disabled),
  &.active:not(.disabled) {
    background-color: variables.$blue50;
    mix-blend-mode: multiply;

    > * {
      fill: variables.$blue500;
      color: variables.$blue500;
    }
  }

  &.active:not(.disabled):hover {
    background-color: variables.$blue100;
    mix-blend-mode: multiply;
  }

  .svg-icon {
    width: 16px;
    height: 14px;
    vertical-align: bottom;
  }

  [class^="cr-icon-"],
  [class*=" cr-icon-"],
  [class^="icon-"],
  [class*=" icon-"] {
    color: variables.$grey300;
  }

  &.primary {
    [class^="cr-icon-"],
    [class*=" cr-icon-"],
    [class^="icon-"],
    [class*=" icon-"] {
      color: variables.$blue500;
    }

    &:hover {
      [class^="cr-icon-"],
      [class*=" cr-icon-"],
      [class^="icon-"],
      [class*=" icon-"] {
        color: variables.$blue800;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.disabled,
  &.loading {
    pointer-events: none;
    cursor: default;
  }

  &.hover-red:hover {
    [class^="cr-icon-"],
    [class*=" cr-icon-"],
    [class^="icon-"],
    [class*=" icon-"] {
      color: variables.$red;
    }
  }

  &.hover-green:hover {
    [class^="cr-icon-"],
    [class*=" cr-icon-"],
    [class^="icon-"],
    [class*=" icon-"] {
      color: variables.$green;
    }
  }

  &.hover-blue:hover {
    [class^="cr-icon-"],
    [class*=" cr-icon-"],
    [class^="icon-"],
    [class*=" icon-"] {
      color: variables.$blue;
    }
  }

  // Some icons have different spacing around them in the icon font.
  //.cr-icon-sort,
  //.cr-icon-q-builder-attachment,
  //.cr-icon-q-builder-checkbox,
  //.cr-icon-q-builder-document,
  //.cr-icon-q-builder-radio,
  //.cr-icon-q-builder-flag,
  //.cr-icon-subsidiaries,
  //.cr-icon-trash
  //{
  //  font-size: 24px;
  //}

  // Some icons do not have the correct spacing around them in the icon font.
  // Correct them here
  .cr-icon-pencil,
  .cr-icon-risk,
  .cr-icon-upload,
  .cr-icon-export,
  .cr-icon-download,
  .cr-icon-redo,
  .cr-icon-accepted,
  .cr-icon-exclamation {
    font-size: 18px;
  }

  .cr-icon-external-link {
    font-size: 22px;
  }

  .cr-icon-chevron {
    font-size: 12px;
  }

  .attention-icon {
    border: 5px solid variables.$red400;
    height: 10px;
    width: 10px;
    border-radius: 5px;

    &.attention-icon-left {
      margin-right: 8px;
      margin-left: 1px;
    }
    &.attention-icon-right {
      position: absolute;
      left: 18px;
      top: 18px;
    }
  }

  // If rendering a link as icon button content hide default hover styling
  a:hover {
    text-decoration: none !important;
  }

  .not-visible {
    visibility: hidden;
  }
}
