@use "../core/variables";

.survey-details-invite-users-modal {
  max-width: 700px !important;

  .email-list {
    .pill-label {
      margin-bottom: 10px;
    }
  }

  .first-last-name {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;

    input + input {
      margin-top: 0;
    }
  }

  .email-input {
    width: 100%;
    margin-top: 16px;
    max-width: unset;
  }

  .recipient-form + .recipient-form {
    margin-top: 32px;
  }

  .add-another-btn {
    margin-top: 16px !important;
    align-self: flex-start;
  }
}
