@use "../../../_common/style/core/variables";

.trust-page-custom-domain-dns-display {
  .dns-records-table {
    border: 1px solid variables.$grey100;

    tr {
      border-top: 1px solid variables.$grey100;
    }

    td,
    th {
      padding: variables.$space-sm !important; // Modal styles are overriding
    }

    th:first-of-type {
      width: 20%;
    }
  }
}
