@use "../../../_common/style/core/variables";

.full-page-modal.v2 .modal-content-container {
  .monitoring-rules-modal {
    width: 900px;
    padding: 0 variables.$space-xl variables.$space-lg;
    gap: variables.$space-lg;
    max-width: inherit;
    margin: 0;
    line-height: 150%;

    .description {
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: variables.$grey500;

      p {
        margin-bottom: 0;
      }
    }

    .rules {
      padding: variables.$space-xl;
      border-radius: variables.$card-border-radius;
      background-color: variables.$blue25;
      display: flex;
      flex-direction: column;
      gap: variables.$space-lg;

      .rules-inner {
        display: flex;
        flex-direction: row;

        .rules-list {
          display: flex;
          position: relative;
          flex-direction: column;
          gap: 10px;
          align-self: stretch;
          flex: 1;

          .monitoring-rule-wrapper {
            display: flex;
            flex-direction: row;
            gap: variables.$space-sm;

            .monitoring-rule {
              padding: variables.$space-md;
              border-radius: variables.$card-border-radius;
              border: 1px solid variables.$blue200;
              background-color: variables.$blue50;
              display: flex;
              flex-direction: row;
              gap: variables.$space-md;
              flex: 1 0 0;

              .rule-definition {
                display: flex;
                gap: variables.$space-lg;
                flex: 1 0 0;

                & > div {
                  flex: 1;
                }

                .header {
                  color: variables.$grey700;
                  font-size: variables.$font-size-smaller;
                  font-weight: variables.$font-weight-bold;
                }

                .rule-type {
                  .error {
                    border: solid 1px variables.$red;
                  }

                  .error-text {
                    display: flex;
                    align-items: center;
                    margin-top: variables.$space-xs;
                    font-weight: variables.$font-weight-bold;
                    line-height: 14px;
                    font-size: 11px;
                    color: variables.$red500;
                    font-style: normal;
                    letter-spacing: normal;
                    .icon-info {
                      font-size: variables.$font-size;
                      margin-right: 3px;
                    }
                  }
                }
              }
            }

            .delete-button {
              .cr-icon-trash-2 {
                font-size: variables.$font-size-xx-large;
                color: variables.$grey400;
                cursor: pointer;
              }
            }
          }

          .add-btn-wrapper {
            position: absolute;
            left: 0.5 * variables.$space-xl;
            bottom: -0.5 * variables.$space-xl;

            .add-btn {
              width: variables.$space-xl;
              height: variables.$space-xl;
            }

            .add-btn:after {
              top: 48%;
              left: 51%;
            }
          }
        }

        .rules-operator {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 28px;

          .horizontal-connector {
            border-bottom: 1px solid variables.$blue200;

            &.top {
              margin-top: 40px;
            }

            &.bottom {
              margin-bottom: 40px;
            }
          }

          .vertical-connector {
            flex: 1;
            border-left: 1px solid variables.$blue200;
          }

          .pill-label {
            justify-content: center;
            width: 42px;
            margin-left: -21px;
          }
        }
      }
    }

    .info-banner {
      margin-bottom: 0;
      padding: variables.$space-sm 12px;

      .message {
        display: flex;
        flex-direction: row;
        gap: variables.$space-sm;

        & > div {
          color: inherit;
        }
      }
    }
  }
}

.full-page-modal.v2 .modal-content-container .monitoring-rules-modal {
  .btn-align-left {
    margin-right: auto;
  }
}
