@import "../../../_common/style/core/variables";

.add-evidence-page-modal {
  &.modal-content {
    padding-top: 0 !important;
  }

  .description {
    max-width: 536px;

    .description-text {
      color: $grey600;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      margin-bottom: 8px;
    }

    .description-link {
      color: $grey500;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-align: left;
    }
  }

  .content {
    max-width: 536px;

    .input-header {
      width: 100%;
      color: $grey900;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      padding-top: 20px;
      padding-bottom: 5px;
    }

    .comments {
      display: flex;
      column-gap: 5px;
    }
  }

  .evidence-page-comments-rte {
    max-width: 536px !important;
    max-height: 200px;

    .editor-inner {
      height: 159px;
      min-height: 159px;

      .content-editable {
        height: 135px;
        min-height: 135px;
      }
    }
  }
}
