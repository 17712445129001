@use "../../../_common/style/core/variables";

.shared-profile-about-card {
  .header {
    .header-company-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
      flex: 1;
      width: 0;
    }
  }

  > div + div {
    margin-top: 0;
  }

  .header .toggle-label {
    font-size: variables.$font-size;
  }

  .score-card {
    padding: 24px 32px 24px 32px;
    transition: opacity variables.$animation-timing ease-in-out;

    &.greyed {
      opacity: 0.3;
      filter: grayscale(100%);
    }

    .two-scores {
      display: flex;
      flex-direction: row;
      align-items: center;

      > div + div {
        margin-left: 32px;
      }

      > div.greyed {
        opacity: 0.3;
        filter: grayscale(100%);
      }
    }

    .score-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
    }

    .score-subheader {
      font-size: 12px;
      line-height: 150%;
      color: variables.$grey500;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .dismissible-popup-container {
        margin-left: 5px;
      }

      .cr-icon-help {
        color: variables.$grey500;
        font-size: 16px;
      }

      .cr-icon-info {
        color: variables.$grey500;
        font-size: 14px;
      }
    }

    .cstar-text {
      margin-left: 16px;
      font-size: 48px;
    }

    .collapsible-header {
      margin-top: 28px;
      color: variables.$blue500;
      font-weight: variables.$font-weight-bold;
      text-decoration: underline;
      cursor: pointer;

      .icon-chevron {
        color: variables.$blue500;
        margin-left: 6px;
      }
    }

    .collapsible-text {
      line-height: 150%;
      color: variables.$grey500;
      margin-top: 16px;

      a {
        text-transform: none;
        text-decoration: underline;
        color: inherit;
      }
    }
  }

  .overview {
    padding: 24px 32px 24px 32px;
    display: flex;
    flex-direction: row;

    .contact {
      margin-right: 120px;
    }

    .overview-header {
      font-weight: variables.$font-weight-bold;
      margin-bottom: 8px;
    }

    .overview-content {
      font-weight: variables.$font-weight-regular;
      line-height: 150%;
      color: variables.$grey500;
      white-space: pre-wrap;
      min-width: 200px;

      a {
        color: variables.$grey500;
        text-decoration: underline;
        text-transform: none;

        &:hover {
          color: variables.$grey400;
        }
      }

      .set-data-link {
        color: variables.$grey500;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-style: dotted;

        &:hover {
          color: variables.$grey400;
        }
      }
    }
  }
}
