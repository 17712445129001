@use "../../../_common/style/core/variables";

.full-page-modal.v2 {
  .modal-content-container {
    .in-review-app-desc {
      padding: 0 variables.$space-xl variables.$space-lg;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.manage-app-button {
  .dropdown-button-dropdown-item-content {
    flex-direction: row;
    display: flex;
  }
}
