@import "../core/variables";

.survey-details-options-card {
  .options-content {
    padding: 20px 32px;
  }

  .option-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;

    & + .option-toggle {
      margin-top: 16px;
    }

    .toggle-switch {
      margin-right: 10px;
    }

    a {
      text-decoration: none !important;
      color: $grey500;
      margin-left: 5px;

      &:hover {
        color: $font-color;
      }

      .cr-icon-info {
        color: inherit;
      }
    }
  }
}
