@use "../../../_common/style/core/variables";

.create-api-key-modal {
  .spacer {
    height: 5px;
    min-height: 5px;
  }

  .btn {
    margin-top: 25px;
  }

  p {
    margin-bottom: 15px !important;
  }
}
