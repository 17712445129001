@import "../../../_common/style/core/variables";

.user-permissions {
  .user-permissions-cell-with-subtext {
    line-height: 1.3;

    .user-permission-maintext {
      font-size: $font-size;
    }

    .user-permission-subtext {
      font-size: $font-size-smaller;
      color: $grey500;
    }
  }
  // Modify the padding on the expanded cell so we don't have
  // a gap between the expanded content
  .report-card {
    td.expanded-content-cell {
      padding: 0;
    }
  }
}
