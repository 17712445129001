@use "../../../../_common/style/core/variables";

.status-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .status-count {
    margin-left: variables.$space-sm;
    color: variables.$grey500;
    text-align: left;
    width: 20px;
  }

  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    height: 24px;
    width: 24px;
    margin-right: variables.$space-sm;

    div {
      color: variables.$white;
      font-size: 12px;
    }

    &.passed {
      background-color: variables.$green500;
    }

    &.partial {
      background-color: variables.$orange400;
    }

    &.failed {
      background-color: variables.$red500;
    }

    &.unknown {
      background-color: unset;
      border: 1px dashed variables.$grey400;

      div {
        color: variables.$grey400;
      }
    }

    /*TODO:NSS add to cyberrisk-icons and remove from here*/
    .cr-icon-cross {
      &:before {
        content: "\2715";
      }
    }

    /*TODO:NSS add to cyberrisk-icons and remove from here*/
    .cr-icon-dash {
      &:before {
        content: "\2501";
      }
    }
  }
}
