@use "../../../_common/style/core/variables";

.shared-profile-evidence-pages-card {
  .empty-card-with-action .btn-container:empty {
    display: none;
  }
}

.shared-profile-evidence-pages-table {
  thead {
    .category {
      text-transform: none;
    }
  }

  tr {
    height: 73px;
  }

  td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
}
