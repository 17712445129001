@use "../../../../_common/style/core/variables";

.new-assessment-menu {
  .dropdown-item {
    width: 300px;
    height: auto;
  }

  .dropdown-with-text {
    width: 100%;
    text-align: left;
    white-space: normal;

    .dropdown-title {
      margin-bottom: variables.$space-sm;
      font-weight: variables.$font-weight-bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: variables.$space-sm;
    }

    .dropdown-text {
      color: variables.$grey500;
    }
  }
}
