@use "../../core/variables";

.mark-risk-remediated-modal {
  width: 600px;

  .input-label {
    font-weight: variables.$font-weight-bold;
    margin-bottom: 8px;
  }
}
