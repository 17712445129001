@use "../../_common/style/core/variables";

#main-content-area-inner:has(> .threat-monitoring-detail-view) {
  //border: 1px solid red;
  overflow: hidden;
}

.confirm-reopen-threat-modal {
  &.cr-icon-undo1 {
    font-size: 20px;
  }
}

.threat-monitoring-detail-view {
  //border: 1px solid blue;

  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 16px;

  .page-header-lite {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      font-weight: variables.$font-weight-bold;
      font-size: variables.$font-size-xx-large;
      line-height: 32px;
    }

    .page-title-right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      & > * + * {
        margin-left: 16px;
      }
    }
  }

  .threat-monitoring-tabs {
    flex: 0;
    border-bottom: 1px solid variables.$grey100;
    padding-left: 0px;
    .tab {
      height: 40px;
      cursor: pointer;
    }
  }

  .info-banner .message {
    font-weight: variables.$font-weight-regular;
  }

  .remediated-banner {
    box-shadow: 0px 4px 6px 0px #4046681a;
    margin-bottom: 0px;

    .message {
      display: block;

      &:first-of-type {
        padding-bottom: 4px;
      }
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 16px;

    .buttonny {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .icon {
        font-size: 20px;
      }
    }

    .icon {
      font-size: 20px;
    }

    .investigating {
      color: variables.$grey200;
    }
  }

  .pivot-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .pivot-tab-panel {
      &:not(.active) {
        display: none;
      }

      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }

  .report-card .header-container {
    * {
      color: variables.$grey900;
      font-weight: variables.$font-weight-bold;
    }
    &:hover {
      * {
        color: variables.$blue500;
      }
    }
  }

  // both expanded
  &:has(.report-card.threat-preview.expanded):has(
      .report-card.threat-detail.expanded
    ) {
    .report-card.threat-detail {
      flex: 0 1 max-content;
    }
    .report-card.threat-preview {
      flex: 1;
    }
  }
  // detail expanded, preview not expanded
  &:has(.report-card.threat-detail.expanded):has(
      .report-card.threat-preview:not(.expanded)
    ) {
    .report-card.threat-detail {
      flex: 1 0 max-content;
    }
    .report-card.threat-preview {
      flex: 0 1 max-content;
    }
  }
  // detail not expanded, preview not expanded
  &:has(.report-card.threat-detail:not(.expanded)):has(
      .report-card.threat-preview:not(.expanded)
    ) {
    .report-card.threat-detail {
      flex: 0 1 max-content;
    }
    .report-card.threat-preview {
      flex: 0 1 max-content;
    }
  }

  .report-card.threat-detail {
    background-color: variables.$grey50;
    box-shadow: none;
    border: 1px solid variables.$grey100;

    gap: 16px;
    padding: 16px;
    .header-container {
      border-bottom: none;
      .header {
        padding: 4px 0;
        min-height: 32px;
      }
    }
  }

  .report-card.threat-preview {
    flex: 1;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    box-shadow: none;

    padding: 16px;
    gap: 16px;

    .header-container {
      border-bottom: none;
      .header {
        padding: 4px 0;
        min-height: 32px;
      }
    }

    //border: 1px solid green;

    .expanded-content {
      flex: 1;
      display: flex;
      overflow: hidden;
    }
  }

  .action-bar-offset {
    flex: none;
    height: 8px;
  }

  .action-bar {
    height: 64px;
    min-height: 64px;

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .arrow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 40px;

        * {
          color: variables.$grey500;
        }
        &.disabled {
          cursor: revert;
          * {
            color: variables.$grey200;
          }
        }
      }

      .total-open {
        font-size: variables.$font-size-smaller;
        color: variables.$grey400;
      }
    }
  }
  .remediation-requested-info {
    margin-bottom: 0px;
  }

  .comments-panel-container {
    .slide-panel {
      width: 600px;

      .container {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }

    .date-detected {
      color: variables.$grey500;
      font-size: variables.$font-size-smaller;
    }
  }
}
