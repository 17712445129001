@import "../core/variables";

.severity-icon {
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;

  img {
    height: 24px;
    width: auto;
  }

  span {
    font-size: 1em;
    color: $black;
    text-transform: capitalize;
  }

  &.is-label {
    width: auto;
    text-align: left;

    img {
      height: 13px;
      margin-right: 5px;
      vertical-align: -1px;
    }
  }
}

.adjusted-severity-icon {
  position: relative;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;

  .single-severity {
    position: relative;
    margin-right: 2px;
  }

  .orig-severity {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .adjusted-severity {
    position: relative;
    margin-right: 8px;
  }

  .severity-change-display {
    margin-left: 6px;
  }
}

.severity-change-display {
  display: flex;
  align-items: center;
  line-height: 22px;
  text-transform: capitalize;

  .severity-icon {
    display: flex;
    align-items: center;
  }

  .cr-icon-arrow-right {
    margin: 0 4px;
    font-size: 16px;
  }
}
