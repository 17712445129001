@import "../../../_common/style/core/variables";

.displaying-users {
  display: flex;
  flex-direction: row;
  gap: $space-sm;
  color: $grey400;
  line-height: $line-height;

  .cr-icon-users-empty {
    color: inherit;
    line-height: inherit;
    font-size: $line-height;
  }
}
